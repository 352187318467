import BaseService from './baseService';

class MemberSubCountersHistoryService extends BaseService {
  constructor(investmentId, premisesId, mainCounterId) {
    super();
    this.url = `/api/user/investment/${investmentId}/premises/${premisesId}/mainCounters/${mainCounterId}/subCounters/history`;
  }
}

export default MemberSubCountersHistoryService;
