import React, { useEffect } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import Footer from '../../../../../../helpers/Footer';
import Input from '../../../../../../components/InputComponent';
import MessagePopup from '../../../../../../components/MessagePopup';
import settlementTemplatesCostsService from '../../../../../../services/settlementTemplatesCostsService';

const { InputNumberV2wNegative, Select } = Input;
const { Option } = Select;

const CostsModal = (props) => {
  const { afterCancel, isModalVisible, editingCost } = props;
  const [form] = Form.useForm();
  const { id, groupId } = useParams();

  useEffect(() => {
    if (editingCost?.mainCounterId) {
      setUseCounterName(editingCost.name);
      setUseCounterUnit(editingCost.unit);
    }

    if (editingCost) {
      const tempData = {
        name: editingCost.name,
        price: editingCost.price,
        type: editingCost.type,
      };

      form.setFieldsValue(tempData);
    }
  }, [editingCost]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const SettlementTemplatesCostsService =
          new settlementTemplatesCostsService(id, groupId);

        const tempValues = {
          settlementTemplateGroupId: groupId,
          name: values.name,
          price: parseFloat(values.price.replace(',', '.')),
          type: values.type,
        };

        tempValues.unit =
          values.type === 'PERSON'
            ? 'osoba'
            : values.type === 'PREMISES'
            ? 'm2'
            : values.type === 'FIXED_PRICE'
            ? 'Opłata stała'
            : null;

        if (editingCost) {
          SettlementTemplatesCostsService.update(editingCost.id, tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          SettlementTemplatesCostsService.create(tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={
          editingCost ? (
            <span>
              Edytujesz: <span className='modal-title'>{editingCost.name}</span>
            </span>
          ) : (
            'Dodaj pozycję'
          )
        }
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={
          <Footer onReset={onReset} onFinish={onFinish} isNew={!editingCost} />
        }
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
          initialValues={{ counterId: 0 }}
        >
          <Row gutter={20}>
            <Col xs={24} lg={12}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size='large' placeholder='Nazwa' />
              </Form.Item>
              <Form.Item hidden name='unit'></Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Form.Item
                name='type'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select size='large' placeholder='Jednostka'>
                  <Option value='PERSON'>osoba</Option>
                  <Option value='PREMISES'>m2</Option>
                  <Option value='FIXED_PRICE'>opłata stała</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name='price'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2wNegative
                  size='large'
                  placeholder='Kwota jednostkowa'
                  addonAfter={'zł brutto'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CostsModal;
