import { Modal } from 'antd';

const { confirm } = Modal;

const deleteConfirm = (handleOk) => {
  confirm({
    title: 'Pomyślnie zmieniono subskrypcje',
    okText: 'Ok',
    okType: 'primary',
    icon: false,
    width: 320,
    className: 'delete-confirm-modal',
    cancelButtonProps: { className: 'button-secondary', size: 'large', style: { display: 'none' } },
    okButtonProps: { size: 'large' },
    onOk() {
      handleOk();
    },
  });
};

export default deleteConfirm;
