import BaseService from './baseService';

class ResolutionsDetailsService extends BaseService {
  constructor(investmentId, resolutionId) {
    super();

    this.url = `/api/community/investment/${investmentId}/resolutions/${resolutionId}/votes`;
  }
}

export default ResolutionsDetailsService;
