import BaseService from './baseService';

class MetersService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/meter`;
  }
}

export default MetersService;
