import BaseService from './baseService';
import httpService from '../helpers/httpService';

const AdminUserService = new BaseService('/api/admin/user');
AdminUserService.changePackage = async (
  userId,
  packageId,
  packageExpirationDate
) =>
  httpService.put(
    `${AdminUserService.url}/${userId}/packages/${packageId}`,
    {
      packageExpirationDate,
    },
    true
  );

export default AdminUserService;
