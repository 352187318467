import BaseService from './baseService';

class TenantSubCountersHistoryService extends BaseService {
  constructor(investmentId, premisesId, mainCounterId) {
    super();
    this.url = `/api/tenant/investment/${investmentId}/premises/${premisesId}/mainCounters/${mainCounterId}/subCounters/history`;
  }
}

export default TenantSubCountersHistoryService;
