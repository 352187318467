import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import Icon from '../../../../../components/Icon';
import Input from '../../../../../components/InputComponent';
import MessagePopup from '../../../../../components/MessagePopup';
import settlementTemplatesGroupsService from '../../../../../services/settlementTemplatesGroupsService';
import { CostsList } from './components/CostsList';
import moment from 'moment';

const { Select, DatePicker, Switch } = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const { id, groupId } = useParams();
  const [form] = Form.useForm();
  const [withoutExpireDate, setWithoutExpireDate] = useState(false);
  const [costsGroup, setCostsGroup] = useState({});

  const query = useQuery();

  useEffect(() => {
    const queryStatus = query.get('status');

    if (queryStatus != null) {
      form.setFieldsValue({
        status: queryStatus,
      });
    }
  }, []);

  useEffect(() => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.getOne(groupId).then((data) => {
      setCostsGroup(data);

      const tempData = { ...data };
      if (data.dateFrom) {
        tempData.dateFrom = moment(data.dateFrom, 'YYYY-MM-DD');
      }
      if (data.dateTo) {
        tempData.dateTo = moment(data.dateTo, 'YYYY-MM-DD');
      }
      if (data.withoutExpireDate) {
        tempData.withoutExpireDate = true;
      }

      setWithoutExpireDate(data.withoutExpireDate);

      form.setFieldsValue(tempData);
    });
  }, []);

  const onFinish = (values) => {
    const tempValues = { ...costsGroup, ...values, type: 'GROUP' };

    tempValues.withoutExpireDate = withoutExpireDate;

    if (values.dateFrom) {
      tempValues.dateFrom = values.dateFrom.format('YYYY-MM-DD');
    }
    if (values.dateTo) {
      tempValues.dateTo = values.dateTo.format('YYYY-MM-DD');
    }

    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.update(groupId, tempValues)
      .then((response) => {
        history.replace(
          `/investment/${id}/housingSettlements/edit?tab=costsGroups`
        );
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    // history.push(`/investment/${id}/counters`);
    history.replace(
      `/investment/${id}/housingSettlements/edit?tab=costsGroups`
    );
  };

  const HeaderTitle = () => {
    if (!costsGroup.name) {
      return <span>Edytujesz</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{costsGroup.name}</span>
      </span>
    );
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() =>
          history.replace(
            `/investment/${id}/housingSettlements/edit?tab=costsGroups`
          )
        }
        title={<HeaderTitle />}
      >
        <Form
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          form={form}
        >
          <Card>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 4, span: 16 }}>
                  <div className='form-section'>
                    <h2>Podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24}>
                        <Form.Item
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Nazwa' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name='repeatability'
                          rules={[
                            {
                              required: true,
                              message: 'Wybierz co ile powtarzać koszt',
                            },
                          ]}
                        >
                          <Select size='large' placeholder='Powtarzaj'>
                            <Option value='MONTH'>Co 1 miesiąc</Option>
                            <Option value='TWO_MONTHS'>Co 2 miesiące</Option>
                            <Option value='THREE_MONTHS'>Co 3 miesiące</Option>
                            <Option value='HALF_YEAR'>Co 6 miesięcy</Option>
                            <Option value='YEAR'>Co 12 miesięcy</Option>
                            {/* <Option value='TWO_YEAR'>Co 2 lata</Option> */}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name='dateFrom'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <DatePicker
                            size='large'
                            placeholder='Data od'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name='dateTo'
                          rules={
                            !withoutExpireDate && [
                              {
                                required: true,
                                message: 'To pole jest wymagane',
                              },
                            ]
                          }
                        >
                          <DatePicker
                            size='large'
                            placeholder='Data do'
                            style={{ width: '100%' }}
                            disabled={withoutExpireDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12}>
                        <Form.Item
                          name='withoutExpireDate'
                          valuePropName='checked'
                        >
                          <span
                            style={{
                              marginLeft: 20,
                              marginRight: 50,
                              fontSize: 16,
                            }}
                          >
                            Do odwołania
                          </span>
                          <Switch
                            checked={withoutExpireDate}
                            onChange={(checked) => {
                              setWithoutExpireDate(checked);
                              if (checked) {
                                form.resetFields(['dateTo']);
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-section'>
                    <h2>Odbiorcy</h2>
                    <Row gutter={20}>
                      <Col xs={24} lg={12} key='premisesTypes'>
                        <Form.Item
                          name='premisesTypes'
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Select
                            placeholder='Typ lokalu'
                            size='large'
                            mode='multiple'
                          >
                            <Option value='RENT'>Mieszkanie</Option>
                            <Option value='STORAGE'>Komórka lokatorska</Option>
                            <Option value='PARKING'>Miejsce postojowe</Option>
                            <Option value='SERVICE'>Lokal usługowy</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-section' style={{ marginTop: 80 }}>
                    <CostsList />
                    {/* <SmallList
                      data={[]}
                      columns={[]}
                      title='Pozycje'
                      customAddButton={<CustomAddButton />}
                      hideEdit
                    /> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='create-form-button'
                >
                  Zapisz
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
