/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Dropdown, Menu, Modal, message,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';

import Icon from '../../components/Icon';
import Empty from '../../components/Empty';
import Spin from '../../components/Spin';

const { confirm } = Modal;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const List = () => {
  const [investments, setInvestments] = useState([]);
  const [filters, setFilters] = useState(['SALE', 'RENT']);
  const [showFilters, setShowFilters] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canSeePrices, setCanSeePrices] = useState(false);
  const [canSeeStats, setCanSeeStats] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const history = useHistory();

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: filters.join(','),
      sortField: 'position',
      sortOrder: 'ascend',
    }).then((data) => {
      setInvestments(data.content);
    });

    userService.getInfo().then((response) => {
      setUserData(response);
      if (response.roles.indexOf('ROLE_DEVELOPER') !== -1 && response.roles.indexOf('ROLE_TENANT') !== -1) {
        setShowFilters(true);
      }

      if (response.roles.includes('ROLE_WORKER') && response.ownerRoles.indexOf('ROLE_DEVELOPER') !== -1 && response.ownerRoles.indexOf('ROLE_TENANT') !== -1) {
        setShowFilters(true);
      }

      if (response.roles.includes('ROLE_WORKER') && response.ownerRoles.includes('ROLE_DEVELOPER')) {
        if (response.workerPermissions.includes('INVESTMENT_FULL') && response.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanEdit(true);
          setCanSeePrices(true);
        } else if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanSeePrices(false);
          setCanEdit(false);
        } else if (response.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanSeePrices(true);
          setCanEdit(false);
        } else {
          setCanSeePrices(false);
          setCanEdit(false);
        }

        if (response.workerPermissions.includes('STATS_FULL')) {
          setCanSeeStats(true);
        }
      } else if (response.roles.includes('ROLE_WORKER') && response.ownerRoles.includes('ROLE_TENANT')) {
        if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
        if (response.workerPermissions.includes('STATS_FULL')) {
          setCanSeeStats(true);
        }
        setCanSeePrices(true);
      } else {
        setCanEdit(true);
        setCanSeePrices(true);
        setCanSeeStats(true);
      }

      setLoading(false);
    });
  }, [filters]);

  const deleteAction = (id) => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.delete(id).then(() => history.go(0)).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        deleteAction(record.id);
      },
    });
  };

  const investmentType = (type) => (type === 'SALE' ? 'Sprzedaż' : 'Wynajem');

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const items = reorder(
      investments,
      result.oldIndex,
      result.newIndex,
    );

    setInvestments(items);
    items.forEach((item, key) => {
      const InvestmentsService = new investmentsService();
      InvestmentsService.updateFields(item.id, { position: key });
    });
  };

  const DragHandle = SortableHandle(() => (
    <div className="investments-list-item-drag">
      <Icon name="drag-n-drop" />
    </div>
  ));

  const SortableItem = SortableElement(({ value }) => {
    const {
      id, name, thumbnailFile, type,
    } = value;
    return (
      <Col
        xs={24}
        sm={12}
        md={8}
        xl={6}
      >
        <div className="investments-list-item">
          <div className="investments-list-item-logo">
            <Link to={`investments/${id}/premises`}>
              <img src={`${baseUrl}${thumbnailFile}`} alt={name} />
            </Link>
            <DragHandle />
          </div>
          <div className="investments-list-item-menu">
            <Row>
              <Col xs={20}>
                <Link className="investments-list-item-menu-name" to={`investments/${id}/show`}>
                  <span>{name}</span>
                </Link>
                <div className="investments-list-item-menu-type">{investmentType(type)}</div>
              </Col>
              <Col xs={4} style={{ textAlign: 'right' }}>
                <Options record={value} />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    );
  });

  const canShowMenu = (requiredPermission) => {
    if (userData.roles.includes('ROLE_WORKER')) {
      return userData.workerPermissions.includes(requiredPermission);
    }

    return true;
  };

  const menu = (record) => (
    <Menu>
      {canEdit && (
        <Menu.Item key="1" icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
          <Link to={`/investments/${record.id}/edit`} className="premises-custom-create-link">
            Edytuj
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="2" icon={<Icon name="more-info" style={{ marginRight: '5px' }} />}>
        <Link to={`/investments/${record.id}/show`} className="premises-custom-create-link">
          Informacje
        </Link>
      </Menu.Item>
      {canSeePrices && canSeeStats && (
        <Menu.Item key="3" icon={<Icon name="stats" style={{ marginRight: '5px' }} />}>
          <Link to={`/investments/stats?id=${record.id}`} className="premises-custom-create-link">
            Statystyki
          </Link>
        </Menu.Item>
      )}
      {record.type === 'RENT' && (
      <Menu.Item key="4" icon={<Icon name="payments" style={{ marginRight: '5px' }} />}>
        <Link to={`/investments/${record.id}/payments`} className="premises-custom-create-link">
          Operacje płatnicze
        </Link>
      </Menu.Item>
      )}
      {record.type === 'SALE' && canShowMenu('PAYMENTS_FULL') && (
      <Menu.Item key="5" icon={<Icon name="percent" style={{ marginRight: '5px' }} />}>
        <Link to={`/investments/${record.id}/settlements`} className="premises-custom-create-link">
          Rozliczenia
        </Link>
      </Menu.Item>
      )}
      {canEdit && (
        <Menu.Item key="6" onClick={() => showDeleteConfirm(record)} style={{ color: '#EC434C' }} icon={<Icon name="delete" style={{ marginRight: '5px' }} />}>
          Usuń
        </Menu.Item>
      )}
    </Menu>
  );

  const Options = ({ record }) => (
    <Dropdown overlay={() => menu(record)} trigger="click" placement="bottomCenter">
      <Button className="button-no-border">
        <Icon name="more-vertical" style={{ transform: 'rotate(45deg)' }} />
      </Button>
    </Dropdown>
  );

  const changeFilters = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  const SortableList = SortableContainer(({ items }) => (
    <Row
      gutter={20}
    >
      {items.map((singleInvestment, index) => (
        <SortableItem key={`item-${singleInvestment.id}`} index={index} value={singleInvestment} />
      ))}
    </Row>
  ));

  return (
    <div style={{ padding: '16px 24px' }}>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <>
          <Row justify="space-between" style={{ marginBottom: '20px' }}>
            <Col sm={24}>
              <h1 style={{ fontSize: '24px' }}>Inwestycje</h1>
            </Col>
            <Col sm={24} style={{ textAlign: 'right' }}>
              {showFilters && (
              <span className="button-group" style={{ marginRight: '10px' }}>
                <Button onClick={() => changeFilters(['SALE', 'RENT'])} className={`button-secondary ${filters.length === 2 ? 'active' : ''}`}>Wszystkie</Button>
                <Button onClick={() => changeFilters(['SALE'])} className={`button-secondary ${filters.length === 1 && filters.indexOf('SALE') !== -1 ? 'active' : ''}`}>Sprzedaż</Button>
                <Button onClick={() => changeFilters(['RENT'])} className={`button-secondary ${filters.length === 1 && filters.indexOf('RENT') !== -1 ? 'active' : ''}`}>Wynajem</Button>
              </span>
              )}
              {canEdit && (
              <Link to="/investments/create">
                <Button type="primary">
                  Dodaj
                  {' '}
                  <Icon name="plus" style={{ marginLeft: '5px' }} />
                </Button>
              </Link>
              )}
            </Col>
          </Row>
          <div className="investments-list">
            {investments.length === 0 && (
            <Empty width="100%" />
            )}
            <SortableList items={investments} onSortEnd={onDragEnd} useDragHandle axis="xy" />
          </div>
        </>
      )}
    </div>
  );
};

export default List;
