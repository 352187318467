import React, { useEffect, useState } from 'react';
import Datagrid from '../../components/Datagrid';
import workersService from '../../services/workersService';
import userService from '../../services/userService';
import Spin from '../../components/Spin';

const columns = [
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    visible: true,
    filterEnabled: false,
    key: 'lastName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    visible: true,
    filterEnabled: false,
    key: 'firstName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Nazwisko, imię',
    dataIndex: 'firstOrLastName',
    visible: false,
    filterEnabled: true,
    key: 'firstOrLastName',
    sorter: false,
    export: false,
  },
  {
    title: 'Alerty',
    dataIndex: 'notifications',
    visible: false,
    key: 'notifications',
    sorter: false,
    export: false,
    filterEnabled: true,
    filterType: 'select',
    filterSelectMultiple: true,
    filterOptionsObject: true,
    filterOptions: [
      { value: 'email', label: 'E-mail' },
      { value: 'sms', label: 'SMS' },
    ],
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    key: 'mobile',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`tel:${text}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    key: 'email',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`mailto:${text}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Alerty',
    dataIndex: 'notifications',
    visible: true,
    key: 'notifications',
    sorter: true,
    render: (text, source) => {
      if (text.sms && text.email) {
        return 'SMS, E-mail';
      } else if (text.sms) {
        return 'SMS';
      } else if (text.email) {
        return 'E-mail';
      }

      return '';
    },
    exportRender: (text, source) => {
      return `E-mail: ${text.email ? 'TAK' : 'NIE'}, SMS: ${
        text.sms ? 'TAK' : 'NIE'
      }`;
    },
  },
];

const List = () => {
  const [workerId, setWorkerId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setWorkerId(data.id);
      setLoading(false);
    });
  }, []);

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title='Lista pracowników'
            resource='housingWorkers'
            dataProvider={workersService}
            columns={columns}
            filters={{
              role: 'ROLE_WORKER',
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            workerId={workerId}
            isAdmin={false}
          />
        </div>
      )}
    </div>
  );
};

export default List;
