import BaseService from './baseService';

class UserTenantChangesService extends BaseService {
  constructor(userId) {
    super();
    this.url = `/api/vendor/user/${userId}/tenantChangeGroup`;
  }
}

export default UserTenantChangesService;
