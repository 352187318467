/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col, Tooltip, Tag, Alert } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import faultsService from '../../services/faultsService';
import faultGroupTagsService from '../../services/faultGroupTagsService';
import housingAssociationsService from '../../services/housingAssociationsService';
import memberAssociationsService from '../../services/memberAssociationsService';
import userService from '../../services/userService';
import TagsService from '../../services/tagsService';
import { Icon, Price, SmallList, Datagrid, Spin } from '../../components';
import faultsStatusMap from '../../helpers/faultsStatusMap';
import faultGroupStatusMap from '../../helpers/faultGroupStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';

const FaultDot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const FaultGroupDot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  if (status === 'IN_PROGRESS') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultGroupStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const InfoBox = ({ faultGroup, investment }) => {
  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Informacje
      </h2>
      <div className='content'>
        <Row gutter={20}>
          <Col xs={24}>
            <Row className='entry'>
              <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                {investment?.type === 'HOUSING_ASSOCIATION'
                  ? 'Spółdzielnia:'
                  : investment?.type === 'HOUSING_COMMUNITY'
                  ? 'Wspólnota:'
                  : 'Inwestycja:'}
              </Col>
              <Col xs={24} sm={10} className='value'>
                {faultGroup.investmentName}
              </Col>
            </Row>
            <Row className='entry' style={{ marginBottom: 20 }}>
              <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                Lokal:
              </Col>
              <Col xs={24} sm={10} className='value'>
                {faultGroup.premisesName
                  ? `${premiseTypeMap(faultGroup.premisesType)} ${
                      faultGroup.premisesName
                    }`
                  : 'Część wspólna'}
              </Col>
            </Row>
            {faultGroup.addDate && (
              <Row className='entry'>
                <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                  Data dodania:
                </Col>
                <Col xs={24} sm={10} className='value'>
                  {faultGroup.addDate}
                </Col>
              </Row>
            )}

            {faultGroup.fixDate && (
              <Row className='entry'>
                <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                  Usunięto:
                </Col>
                <Col xs={24} sm={10} className='value'>
                  {faultGroup.fixDate}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const WorkersBox = ({ faultGroup }) => {
  if (!faultGroup?.responsibleWorkersNames) {
    return null;
  }

  if (Object.keys(faultGroup.responsibleWorkersNames).length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='worker' />
        Osoba odpowiedzialna
      </h2>
      <div className='content'>
        {faultGroup.responsibleWorkers.map((workerId) => {
          const worker = faultGroup.responsibleWorkersNames[workerId];

          return (
            <Row gutter={20}>
              <Col xs={24}>
                <div className='owner-box-content'>
                  <div className='item'>
                    {worker.firstName} {worker.lastName}
                  </div>
                  <div className='item'>
                    <a
                      className='datagrid-link'
                      href={`tel:${worker.mobile}`}
                      style={{ color: '#3B4C60' }}
                    >
                      {worker.mobile}
                    </a>
                  </div>
                  <div className='item'>
                    <a
                      className='datagrid-link'
                      href={`mailto:${worker.email}`}
                      style={{ color: '#3B4C60' }}
                    >
                      {worker.email}
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
};

const Faults = (props) => {
  const history = useHistory();
  const { investmentId, faultGroupId } = useParams();
  const { userData, faultGroup, investment } = props;

  const workerFieldMapper = (worker) => {
    if (worker?.nameDeveloper) {
      return worker.nameDeveloper;
    }

    return `${worker?.firstName || ''} ${worker?.lastName || ''}`;
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
    },
    {
      title: 'Osoba odpowiedzialna',
      dataIndex: 'responsibleWorkers',
      visible: true,
      key: 'responsibleWorkers',
      sorter: false,
      render: (text, record) => {
        if (!record.responsibleWorkers) {
          return '';
        }
        const workersMap = record.responsibleWorkers.map((worker) =>
          workerFieldMapper(faultGroup.responsibleWorkersNames[worker])
        );
        return workersMap.join(', ');
      },
      exportRender: (text, record) => {
        if (!record.responsibleWorkers) {
          return '';
        }
        const workersMap = record.responsibleWorkers.map((worker) =>
          workerFieldMapper(faultGroup.responsibleWorkersNames[worker])
        );
        return workersMap.join(', ');
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => <FaultDot status={record.status} />,
      exportRender: (text, record) => faultsStatusMap(record.status),
    },
    {
      title: 'Data wystąpienia',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    {
      title: 'Usunięto',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    {
      title: 'Koszt (brutto)',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      summary: true,
      render: (text, record) => <Price value={record.amount} showEmpty />,
    },
  ];

  const customBaseUrl = (record) =>
    `housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}`;

  const CustomCreate = ({ disabled }) => (
    <div style={{ marginBottom: 20 }}>
      <Button
        type='primary'
        onClick={() => {
          if (!disabled) {
            return history.push(
              `/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/create`
            );
          }
        }}
        style={
          disabled
            ? {
                backgroundColor: '#ececf7',
                borderColor: '#ececf7',
                cursor: 'not-allowed',
                color: 'rgba(83, 83, 244, 0.3)',
                fontWeight: 700,
              }
            : {}
        }
      >
        Zgłoszenie
        <Icon name='plus' style={{ marginLeft: 10 }} />
      </Button>
    </div>
  );

  return (
    <Datagrid
      title='Lista zgłoszeń'
      resource='faultGroup'
      dataProvider={new faultsService(investmentId, faultGroupId)}
      columns={columns}
      directResponse
      hideColumns
      hideFilters
      // disableSelection
      calculateBaseUrl={customBaseUrl}
      disablePagination
      customCreate={<CustomCreate disabled={investment.isFaultsDisabled} />}
      hideEdit
      customWrapperStyles={{ paddingLeft: 0, paddingRight: 0 }}
      hideReset
      hideSms
      hideEmail
      customRowActions={[
        (record) => {
          if (
            (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
            !record.isDeveloperNotified &&
            record?.status !== 'DONE' &&
            (record?.status === 'TO_FIX' || userData?.id === record?.reporterId)
          ) {
            return (
              <Link
                to={`/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}/edit`}
              >
                <Button className='button-no-border'>
                  <Icon name='edit' />
                </Button>
              </Link>
            );
          }
          if (
            userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') &&
            !record.isAdminNotified &&
            !record.isDeveloperNotified
          ) {
            return (
              <Link
                to={`/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}/edit`}
              >
                <Button className='button-no-border'>
                  <Icon name='edit' />
                </Button>
              </Link>
            );
          }

          return null;
        },
      ]}
    />
  );
};

const Show = () => {
  const { investmentId, faultGroupId } = useParams();
  const history = useHistory();
  const [faultGroup, setFaultGroup] = useState({});
  const [investment, setInvestment] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      const promises = [];

      const FaultGroupService = new faultGroupService(investmentId, null);
      setUserData(data);

      promises.push(
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        })
      );

      Promise.all(promises).then(() => setLoading(false));
    });
  }, [faultGroupId, investmentId]);

  useEffect(() => {
    if (faultGroup.investmentId) {
      if (
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        const InvestmentService = new housingAssociationsService();
        InvestmentService.getOne(faultGroup.investmentId).then((data) => {
          setInvestment(data);
        });
      } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        const InvestmentService = new memberAssociationsService();
        InvestmentService.getOne(faultGroup.investmentId).then((data) => {
          setInvestment(data);
        });
      }
    }
  }, [faultGroup]);

  const handleBack = () => {
    return history.goBack();
  };

  const TagFilters = () => {
    const [tagsIds, setTagsIds] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const FaultGroupTagsService = new faultGroupTagsService();
      FaultGroupTagsService.getFaultGroupTags(investmentId, faultGroupId).then(
        (data) => {
          setTagsIds(data);
        }
      );
    }, []);

    useEffect(() => {
      if (tagsIds.length > 0) {
        const promises = [];
        const tempTags = [];
        for (let tagId of tagsIds) {
          promises.push(
            TagsService.getOne(tagId).then((data) => {
              tempTags.push(data);
            })
          );
        }

        Promise.all(promises).then(() => {
          setTags(tempTags);
        });
      }
    }, [tagsIds]);

    return tags.map((tag) => (
      <Link to={`/housingFaultGroup?tag=${tag.id}`}>
        <Tag>{`#${tag.name}`}</Tag>
      </Link>
    ));
  };
  const canEdit =
    (!userData?.roles?.includes('ROLE_CLIENT_TENANT') &&
      faultGroup?.status !== 'DONE') ||
    false;

  let shoudRenderClientNotification = false;
  if (
    !loading &&
    (userData?.roles?.includes('ROLE_CLIENT_TENANT') ||
      userData?.roles?.includes('ROLE_CLIENT'))
  ) {
    if (faultGroup.isConfirmed === null && faultGroup.status === 'DONE') {
      shoudRenderClientNotification = true;
    }
  }

  const CustomAlert = () => {
    const FaultGroupService = new faultGroupService(investmentId, null);
    const confirm = () => {
      FaultGroupService.updateConfirmation(faultGroupId, true).then(() => {
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        });
      });
    };

    const cancel = () => {
      FaultGroupService.updateConfirmation(faultGroupId, false).then(() => {
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        });
      });
    };
    const ownerName = userData?.roles?.includes('ROLE_CLIENT_TENANT')
      ? 'Zarządca'
      : 'Deweloper';
    return (
      <Alert
        style={{ width: '100%' }}
        type='info'
        message={
          <>
            <span className='font-bold'>{ownerName}</span> zakończył te usterkę.
            Potwierdź odbiór aby zakończyć.
          </>
        }
        action={[
          <Button
            type='primary'
            className='confirm-fault-button'
            onClick={() => confirm()}
          >
            Potwierdzam odbiór
          </Button>,
          <Button
            className='button-secondary'
            type='text'
            onClick={() => cancel()}
          >
            Odrzuć
          </Button>,
        ]}
      />
    );
  };

  return (
    <div className='show-layout tenantChanges-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Wróć'
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            !userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') && (
              <Link
                key='2'
                to={`/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/history`}
              >
                <Button className='button-secondary'>
                  Historia
                  <Icon name='history-list' />
                </Button>
              </Link>
            ),
            canEdit && (
              <Link
                key='2'
                to={`/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/edit`}
              >
                <Button type='primary'>
                  Edytuj <Icon name='edit' />
                </Button>
              </Link>
            ),
          ]}
        >
          <div className='premises-show'>
            <div className='premises-show-header'>
              <Row justify='space-between'>
                <Col xs={24} sm={24}>
                  <h1>
                    Usterka:{' '}
                    <span style={{ color: '#5353f4' }}>{faultGroup.name}</span>
                    <FaultGroupDot {...faultGroup} />
                  </h1>
                </Col>
              </Row>
            </div>
            <Row gutter={20}>
              {shoudRenderClientNotification && (
                <div className='faults-notification'>
                  <Col xs={24}>
                    <CustomAlert />
                  </Col>
                </div>
              )}
              {!userData?.roles?.includes('ROLE_CLIENT') &&
                !userData?.roles?.includes('ROLE_CLIENT_TENANT') && (
                  <Col xs={24} className='tags-filter'>
                    <TagFilters />
                  </Col>
                )}
              <Col xs={24} lg={12}>
                <InfoBox faultGroup={faultGroup} investment={investment} />
              </Col>
              <Col xs={24} lg={12}>
                <WorkersBox faultGroup={faultGroup} />
              </Col>
            </Row>
            <Faults
              userData={userData}
              faultGroup={faultGroup}
              investment={investment}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
