import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, message } from 'antd';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import WorkersInvestmentModal from './WorkersInvestmentModal';
import housingAssociationsService from '../../../services/housingAssociationsService';
import WorkersService from '../../../services/workersService';

export const WorkersInvestment = ({ setEditInvestment }) => {
  const { id } = useParams();
  const [investment, setInvestment] = useState({});
  const [investmentWorkers, setInvestmentWorkers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const refresh = () => {
    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.getOne(id)
      .then((response) => {
        setInvestment(response);
        setEditInvestment(response);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  useEffect(() => {
    refresh();
  }, [showModal]);

  useEffect(() => {
    setInvestmentWorkers([]);

    if (investment?.workersIds) {
      for (let workerId of investment?.workersIds) {
        WorkersService.getOne(workerId)
          .then((data) => {
            setInvestmentWorkers((curr) => [...curr, data]);
          })
          .catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
      }
    }
  }, [investment]);

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: ['firstName', 'lastName'],
      editable: false,
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'Stanowisko',
      dataIndex: 'workerPosition',
      editable: false,
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      editable: false,
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      editable: false,
    },
  ];

  const deleteAction = (entityId) => {
    const tempWorkerIds = [];

    if (investment?.workersIds) {
      for (let id of investment.workersIds) {
        if (id !== entityId) {
          tempWorkerIds.push(id);
        }
      }
    }

    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.updateFields(id, { workersIds: tempWorkerIds })
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setShowModal(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <WorkersInvestmentModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
        }}
        investment={investment}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            tableClassName={investmentWorkers.length !== 0 && 'contacts-table'}
            data={investmentWorkers}
            columns={columns}
            title='Lista pracowników'
            deleteAction={deleteAction}
            customAddButton={<CustomAddButton />}
            hideEdit
          />
        </Col>
      </Row>
    </div>
  );
};
