import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../services/authService';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      exact
      render={({ location }) => (authService.isLogged() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default PrivateRoute;
