import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import FaultsList from '../faultGroup/List';

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const FaultsService = new faultGroupService(null, null, id);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    clientsService.getOne(id).then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, [id]);

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Usterki</span>;
    }

    return (
      <span>
        Usterki:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };
  const redirectState = { redirectFrom: 'ClientFaultGroupList', userId: id };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/clients/${id}/show`)}
          title={<HeaderTitle />}
        >
          <FaultsList
            customDataService={FaultsService}
            isCustomRendered
            redirectState={redirectState}
          />
        </PageHeader>
      )}
    </div>
  );
};

export default List;
