import httpService from '../helpers/httpService';

class BaseService {
  constructor(url) {
    this.url = url;
  }

  async getList(params = {}) {
    const { pagination, sortField, sortOrder, ...queryParams } = params;

    if (pagination) {
      if (pagination.current) {
        queryParams.page = pagination.current - 1;
      }

      if (pagination.pageSize) {
        queryParams.size = pagination.pageSize;
      }
    }

    if (sortField && sortOrder) {
      const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
      queryParams.sort = `${sortField},${order}`;
    } else {
      queryParams.sort = 'id,DESC';
    }

    return httpService.get(this.url, queryParams, true);
  }

  async create(data, config = {}) {
    return httpService.post(this.url, data, true, {}, config);
  }

  async getOne(id) {
    return httpService.get(`${this.url}/${id}`, null, true);
  }

  async delete(id) {
    return httpService.delete(`${this.url}/${id}`, null, true);
  }

  async deleteMany(ids) {
    const promises = ids.map(async (id) =>
      httpService.delete(`${this.url}/${id}`, null, true)
    );
    return Promise.all(promises);
  }

  async update(id, data) {
    return httpService.put(`${this.url}/${id}`, data, true);
  }

  async updateFields(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }

  async uploadAvatar(id, data, config = {}) {
    return httpService.post(
      `${this.url}/${id}/avatar`,
      data,
      true,
      {
        'content-type': 'multipart/form-data',
      },
      config
    );
  }

  async sendPassword(id, data) {
    return httpService.put(`${this.url}/${id}/password`, data, true);
  }

  async getBalance(id) {
    return httpService.get(`${this.url}/${id}/payments/balance`, null, true);
  }
}

export default BaseService;
