import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Datagrid } from '../../../components';
import Spin from '../../../components/Spin';
import { premiseTypeMap } from '../../../helpers';
import memberAssociationsService from '../../../services/memberAssociationsService';
import memberInvestmentPremisesService from '../../../services/memberInvestmentPremisesService';

const columns = [
  {
    title: 'Lokal',
    dataIndex: 'name',
    visible: true,
    filterEnabled: false,
    key: 'premise',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => `${premiseTypeMap(record.type)} ${record.name}`,
    exportRender: (text, record) =>
      `${premiseTypeMap(record.type)} ${record.name}`,
  },
  {
    title: 'Lokal',
    dataIndex: 'name',
    visible: false,
    filterEnabled: true,
    key: 'name',
    sorter: false,
    sortDirections: ['descend', 'ascend'],
    export: false,
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: false,
    filterEnabled: true,
    filterType: 'select',
    filterOptions: [
      { value: 'RENT', label: 'Mieszkanie' },
      { value: 'STORAGE', label: 'Komórka lokatorska' },
      { value: 'PARKING', label: 'Miejsce postojowe' },
      { value: 'SERVICE', label: 'Lokal usługowy' },
    ],
    key: 'type',
    sorter: false,
    sortDirections: ['descend', 'ascend'],
    export: false,
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span>
        {text} m<sup>2</sup>
      </span>
    ),
  },
  {
    title: 'Powierzchnia od',
    dataIndex: 'totalSurfaceFrom',
    visible: false,
    key: 'totalSurfaceFrom',
    sorter: false,
    export: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
  },
  {
    title: 'Powierzchnia do',
    dataIndex: 'totalSurfaceTo',
    visible: false,
    key: 'totalSurfaceTo',
    sorter: false,
    export: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
  },
  {
    title: 'Udział',
    dataIndex: ['participation', 'percent'],
    visible: true,
    key: 'participationPercent',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => {
      if (text) {
        return `${text.toFixed(2)}%`;
      } else {
        return null;
      }
    },
  },
  {
    title: 'Najemca',
    dataIndex: 'tenantsNames',
    visible: true,
    key: 'tenantsNames',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      if (
        record.communityMembersTenants[0]?.firstName &&
        record.communityMembersTenants[0]?.lastName
      ) {
        return `${record.communityMembersTenants[0]?.firstName} ${record.communityMembersTenants[0]?.lastName}`;
      } else if (record.communityMembersTenants[0]?.firstName) {
        return `${record.communityMembersTenants[0]?.firstName}`;
      } else if (record.communityMembersTenants[0]?.lastName) {
        return `${record.communityMembersTenants[0]?.lastName}`;
      } else {
        return null;
      }
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    visible: true,
    key: 'dateFrom',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return record.communityMembersTenants[0]?.dateFrom;
    },
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    visible: true,
    key: 'dateTo',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      if (record.communityMembersTenants[0]?.withoutExpireDate) {
        return <span style={{ color: '#B1CADE' }}>bezterminowo</span>;
      } else {
        return record.communityMembersTenants[0]?.dateTo;
      }
    },
  },
];

const List = () => {
  const { id } = useParams();
  const [investmentId, setInvestmentId] = useState();
  const [baseUrlKey, setBaseUrlKey] = useState();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);

  let MemberInvestmentPremisesService = new memberInvestmentPremisesService(
    investmentId
  );

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');

    if (choosenInvestmentId) {
      setInvestmentId(JSON.parse(choosenInvestmentId));
    }
  }, []);

  useEffect(() => {
    MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );

    setLoading(false);
  }, [investmentId]);

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();

    MemberAssociationsService.getDetails(investmentId).then((data) => {
      setInvestment(data);

      if (data.type === 'HOUSING_ASSOCIATION') {
        setBaseUrlKey('memberAssociations');
      } else if (data.type === 'HOUSING_COMMUNITY') {
        setBaseUrlKey('memberCommunities');
      }
    });
  }, [investmentId]);

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Lokale</span>;
    }
    return (
      <span>
        Lokale: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Datagrid
          title={
            <PageHeader
              style={{ padding: 0 }}
              ghost={false}
              title={<HeaderTitle />}
            />
          }
          exportTitle={'Lokale'}
          resource={`${baseUrlKey}/${investmentId}/premises`}
          customBaseUrlKey={baseUrlKey}
          dataProvider={MemberInvestmentPremisesService}
          columns={columns}
          sort={{
            sortField: 'id',
            sortOrder: 'descend',
          }}
          filters={{
            communityMemberTenantId: id,
          }}
          hideDelete
          hideReset
          hideSms
          hideEmail
        />
      )}
    </div>
  );
};

export default List;
