import { Button } from 'antd';

export const SendReceivedSwitch = ({
  showSend,
  setShowSend,
  showReceived,
  setShowReceived,
}) => {
  return (
    <span>
      <Button
        className={`button-secondary all-members-btn ${
          showReceived && 'show-all-members-btn'
        }`}
        style={{ marginLeft: 0 }}
        onClick={() => {
          setShowReceived(true);
          setShowSend(false);
        }}
      >
        Odebrane
      </Button>
      <Button
        className={`button-secondary all-members-btn ${
          showSend && 'show-all-members-btn'
        }`}
        onClick={() => {
          setShowReceived(false);
          setShowSend(true);
        }}
      >
        Wysłane
      </Button>
      <Button
        className={`button-secondary all-members-btn ${
          !showSend && !showReceived && 'show-all-members-btn'
        }`}
        onClick={() => {
          setShowReceived(false);
          setShowSend(false);
        }}
      >
        Wszystkie
      </Button>
    </span>
  );
};
