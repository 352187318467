import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import memberInvestmentPremisesService from '../../../../services/memberInvestmentPremisesService';
import ContactsModal from './ContactsModal';
import MessagePopup from '../../../../components/MessagePopup';

export const Contacts = ({ investmentId, premisesId }) => {
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingContact, setEditingContact] = useState();

  useEffect(() => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.getContacts(investmentId, premisesId).then(
      (data) => {
        setContacts(data);
      }
    );
  }, [investmentId, premisesId, showModal]);

  const refresh = () => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.getContacts(investmentId, premisesId).then(
      (data) => {
        setContacts(data);
      }
    );
  };

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: ['firstName', 'lastName'],
      editable: false,
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      editable: true,
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      editable: true,
    },
  ];

  const deleteAction = (entityId) => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.deleteContact(
      investmentId,
      premisesId,
      entityId
    )
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const updateAction = (entityId, data) => {
    const contact = contacts.find((el) => el.id === entityId);

    const tempData = { ...contact };

    if (data.phone) {
      tempData.phone = data.phone;
    }
    if (data.email) {
      tempData.email = data.email;
    }

    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.updateContact(
      investmentId,
      premisesId,
      entityId,
      tempData
    )
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setShowModal(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ContactsModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
          setEditingContact(null);
        }}
        editingContact={editingContact}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            tableClassName={contacts.length !== 0 && 'contacts-table'}
            data={contacts}
            columns={columns}
            title='Lista kontaktów'
            deleteAction={deleteAction}
            updateAction={updateAction}
            editingAction={(record) => {
              setEditingContact(record);
              setShowModal(true);
            }}
            customAddButton={<CustomAddButton />}
            hideEdit
          />
        </Col>
      </Row>
    </div>
  );
};
