import React from 'react';
import { Row, Col, Carousel } from 'antd';
import { Route } from 'react-router-dom';
import Login from '../routes/login/Login';
import ResetPassword from '../routes/resetPassword/ResetPassword';
import slide from '../static/images/slide1.png';
import slide2 from '../static/images/slide2.png';
import slide3 from '../static/images/slide3.png';
import logo2 from '../static/images/logo_manage.png';
import logoDemo from '../static/images/manage-logo-demo.png';
// import logo from '../static/images/logo_manage_animated.gif';

const UnloggedLayout = () => {
  const isDemo = process.env.REACT_APP_DEMO_MODE === 'true';
  return (
    <>
      <Row>
        <Col
          xs={{ span: 24, order: 2 }}
          md={{ span: 12, order: 1 }}
          xl={{ span: 8, order: 1 }}
        >
          <div
            className='aside'
            style={{
              minHeight: '100vh',
              height: '100%',
              backgroundColor: '#252E38',
            }}
          >
            <div className='logo'>
              {isDemo ? (
                <img
                  src={logoDemo}
                  alt='manage'
                  style={{ maxWidth: '170px' }}
                />
              ) : (
                <img src={logo2} alt='manage' style={{ maxWidth: '170px' }} />
              )}
            </div>
            <div className='carousel'>
              <Carousel effect='fade'>
                <div className='slide'>
                  <h1>
                    Twój nowy
                    <br />
                    system
                  </h1>
                  <p>do zarządzania nieruchomościami</p>
                  <img src={slide} alt='slide-img' />
                </div>
                <div className='slide'>
                  <h1>
                    Oszczędzaj czas
                    <br />i pieniądze
                  </h1>
                  <p>Jedna aplikacja, nieskończone możliwości</p>
                  <img src={slide2} alt='slide-img' />
                </div>
                <div className='slide'>
                  <h1>
                    W domu,
                    <br />w podrózy
                  </h1>
                  <p>Niezależnie od miejsca, zawsze pod ręką</p>
                  <img src={slide3} alt='slide-img' />
                </div>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col
          className='login-form-row'
          xs={{ span: 24, order: 1 }}
          md={{ span: 12, order: 2 }}
          xl={{ span: 16, order: 2 }}
        >
          <Route path='/login'>
            <Login />
          </Route>
          <Route path='/resetPassword'>
            <ResetPassword />
          </Route>
        </Col>
      </Row>
    </>
  );
};
export default UnloggedLayout;
