import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import tenantChangeGroups from '../../../../services/tenantChangeGroups';
import Icon from '../../../../components/Icon';
import Price from '../../../../components/Price';
import SmallList from '../../../../components/SmallList';
import tenantChangesStatusMap from '../../../../helpers/tenantChangesStatusMap';

export const TenatChangesBox = ({ premise, investment }) => {
  const { id, premisesId } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const DataService = new tenantChangeGroups(id, premisesId);
    DataService.getList().then((res) => {
      setData(res.content);
    });
  }, [id, premisesId]);

  if (!data.length) {
    return '';
  }

  if (investment.type === 'SALE' && premise.status === 'FREE') {
    return '';
  }

  const Dot = ({ status }) => {
    let color = 'red';
    if (status === 'DONE') {
      color = 'green';
    }

    return (
      <Tooltip title={tenantChangesStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };
  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      dataIndex: 'paymentExpiryDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      dataIndex: 'amount',
      render: (text, record) => <Price showEmpty value={record.amount} />,
    },
  ];
  return (
    <div className="premises-show-box tranches-box">
      <h2>
        <Icon name="flat-changes" />
        Zmiany lokatorskie
      </h2>
      <div className="content">
        <SmallList data={data} columns={columns} showHeader={false} />
      </div>
    </div>
  );
};
