import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';

export const DescriptionBox = ({
  fault,
  title = 'Opis zgłoszenia',
  valueKey = 'description',
}) => {
  if (!fault[valueKey]) {
    return '';
  }
  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='description' />
        {title}
      </h2>
      <div className='content'>
        <Row className='entry'>
          <Col xs={24} sm={{ span: 8, offset: 1 }}>
            {fault[valueKey]}
          </Col>
        </Row>
      </div>
    </div>
  );
};
