import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultGroupService extends BaseService {
  constructor(investmentId, type = null, userId = null) {
    super();
    const roles = localStorage.getItem('roles');
    const useQuery = () => new URLSearchParams(window.location.search);
    const query = useQuery();

    const urlInvestmentId = query.get('investmentId') || null;

    const choosenInvestmentId =
      urlInvestmentId ??
      JSON.parse(localStorage.getItem('choosenInvestmentId'));

    if (userId) {
      this.url = `/api/vendor/user/${userId}/faultGroup`;
    } else if (type) {
      this.url = `/api${type}`;
    } else {
      this.url = `/api/vendor/investment/${investmentId}/faultGroup`;

      if (
        roles.includes('ROLE_CLIENT_TENANT') ||
        roles.includes('ROLE_CLIENT')
      ) {
        this.url = '/api/user/faultGroup';
      }

      if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
        if (investmentId) {
          this.url = `/api/community/investments/${investmentId}/faultGroups`;
        } else if (choosenInvestmentId) {
          this.url = `/api/community/investments/${choosenInvestmentId}/faultGroups`;
        }
      }
    }
  }

  async getAssignedFaults(premiseId) {
    return httpService.get(`${this.url}/premises/${premiseId}`, null, true);
  }

  async updateConfirmation(faultGroupId, isConfirmed) {
    return httpService.patch(
      `${this.url}/${faultGroupId}`,
      {
        isConfirmed,
      },
      true
    );
  }

  async canCloseFaultGroup(faultGroupId) {
    return httpService.get(
      `${this.url}/${faultGroupId}/verifyFaults`,
      null,
      true
    );
  }

  async checkIfNameIsUsed(name, faultGroupId) {
    return httpService.get(
      `${this.url}/verifyName`,
      {
        name,
        faultGroupId,
      },
      true
    );
  }
}

export default FaultGroupService;
