import React from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Tooltip } from 'antd';
import { Datagrid } from '../../../components';
import Icon from '../../../components/Icon';
import housingTenantSettlemetsService from '../../../services/housingTenantSettlemetsService';

const getRowClassName = (record, index) => {
  if (record.status === 'NEW') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const RenderStatus = (status) => {
  if (status === 'NEW') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const List = () => {
  const { id } = useParams();

  const columns = [
    {
      title: 'Najemca',
      dataIndex: 'owner',
      visible: true,
      filterEnabled: false,
      key: 'owner',
      sorter: true,
      export: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        record.owner
          ? [record.owner.firstName, record.owner.lastName]
              .filter((e) => !!e)
              .join(' ') || ''
          : '',
      exportRender: (text, record) =>
        record.owner
          ? [record.owner.firstName, record.owner.lastName]
              .filter((e) => !!e)
              .join(' ') || ''
          : '',
    },
    {
      title: 'Dodano',
      dataIndex: 'createdDate',
      visible: true,
      filterEnabled: false,
      key: 'createdDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: false,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
      render: (text) => (text ? RenderStatus(text) : ''),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      visible: true,
      filterEnabled: false,
      key: 'expirationDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'amount',
      visible: true,
      filterEnabled: false,
      key: 'amount',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            onBack={() => window.history.back()}
            backIcon={<Icon name='arrow-simply-left' />}
            title={'Rozliczenia'}
          />
        }
        exportTitle='Rozliczenia'
        dataProvider={new housingTenantSettlemetsService(id)}
        columns={columns}
        filters={{
          types: 'COMMUNITY_SETTLEMENT',
        }}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        customRowClass={getRowClassName}
        hideCreate
        hideDelete
        hideReset
        hideSms
        hideEmail
        hideFilters
        hideEdit
        hideShow
      />
    </div>
  );
};

export default List;
