import React, { useState } from 'react';
import { Table, Button, Row, Col, Dropdown, Menu, Modal, Form } from 'antd';
import Icon from './Icon';
import EditableCell from './smallist/EditableCell';

const { confirm } = Modal;

const SmallList = ({ showHeader = true, ...props }) => {
  const {
    addAction,
    deleteAction,
    updateAction,
    editingAction,
    icon,
    columns,
    title,
    customAddButton,
    data,
    inlineEditLabel = 'Edytuj',
    hideDelete = false,
    hideEdit,
    tableClassName,
    customRowAction,
  } = props;

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const hasEditableColumn = columns.some((col) => col.editable);

  const handleEdit = (record) => {
    setEditingKey(record.id);
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      const index = data.findIndex((item) => key === item.id);

      if (index > -1) {
        setEditingKey('');
        updateAction(key, row);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        props.deleteAction(record.id);
      },
    });
  };

  const menu = (record) => (
    <Menu>
      {props.customMenuActions && props.customMenuActions(record)}
      {!hideEdit && (
        <Menu.Item
          key='97'
          onClick={() => props.addAction(record)}
          icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
        >
          Edytuj
        </Menu.Item>
      )}
      {hasEditableColumn && (
        <Menu.Item
          key='98'
          onClick={() => {
            editingAction ? editingAction(record) : handleEdit(record);
          }}
          icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
        >
          {inlineEditLabel}
        </Menu.Item>
      )}
      {!hideDelete && (
        <Menu.Item
          key='99'
          onClick={() => showDeleteConfirm(record)}
          style={{ color: '#EC434C' }}
          icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
        >
          Usuń
        </Menu.Item>
      )}
    </Menu>
  );

  const shouldShowActions =
    addAction || deleteAction || props.customMenuActions;

  const columnsWithActions = [];

  // if (icon) {
  //   columnsWithActions.push({
  //     key: 'icon',
  //     align: 'left',
  //     width: 30,
  //     render: () => (
  //       <Icon
  //         name={icon}
  //         className='smalllist-wrapper-row-icon'
  //         style={{ marginLeft: '5px' }}
  //       />
  //     ),
  //   });
  // }

  columnsWithActions.push(...columns);

  if (customRowAction) {
    columnsWithActions.push(customRowAction);
  }

  if (shouldShowActions) {
    columnsWithActions.push({
      key: 'action',
      align: 'right',
      width: customRowAction ? 76 : 140,
      render: (text, record) => {
        const showEditActions = isEditing(record);
        return !showEditActions ? (
          <Dropdown
            overlay={() => menu(record)}
            trigger='click'
            placement='bottom'
            className='smalllist-wrapper-row-action'
          >
            <Button className='button-no-border'>
              <Icon name='more-vertical' />
            </Button>
          </Dropdown>
        ) : (
          <span className='smalllist-wrapper-row-action'>
            <Button
              className='button-no-border smalllist-wrapper-row-action'
              onClick={() => setEditingKey('')}
            >
              <Icon
                name='error-circle'
                style={{ color: '#3B4C60', fontSize: '20px' }}
              />
            </Button>
            <Button
              className='button-no-border smalllist-wrapper-row-action'
              onClick={() => handleSave(record.id)}
            >
              <Icon
                name='ok-circle'
                style={{ color: '#3B4C60', fontSize: '20px' }}
              />
            </Button>
          </span>
        );
      },
    });
  }

  const mergedColumns = columnsWithActions.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className='smalllist-wrapper'>
      <Row>
        <Col xs={12}>{title && <h2>{title}</h2>}</Col>
        <Col xs={12} style={{ textAlign: 'right' }}>
          {addAction && (
            <Button
              className='button-secondary'
              onClick={addAction}
              style={{ width: '45px', height: '45px' }}
            >
              <Icon name='plus' />
            </Button>
          )}
          {customAddButton}
        </Col>
      </Row>
      <Form form={form} component={false}>
        <Table
          className={tableClassName}
          columns={mergedColumns}
          dataSource={data}
          pagination={false}
          summary={() => props.summary}
          showHeader={showHeader && data.length !== 0}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    </div>
  );
};

export default SmallList;
