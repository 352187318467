import BaseService from './baseService';

class FaultGroupHistoryService extends BaseService {
  constructor(investmentId, faultGroupId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/faultGroup/${faultGroupId}/history`;
  }
}

export default FaultGroupHistoryService;
