import React, { useState, useEffect } from 'react';
import {
  PageHeader, Row, Col, Card, Button, message,
} from 'antd';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import packagesService from '../../services/packagesService';
import userService from '../../services/userService';
import packageConfirm from '../../components/packageConfirm';
import packageSuccess from '../../components/packageSuccess';

const MyPackage = () => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      const packagesFilter = {
        status: 'ACTIVE',
        availableForUser: true,
      };
      if (data.roles.includes('ROLE_DEVELOPER')) {
        packagesFilter.type = 'DEVELOPER';
      } else {
        packagesFilter.type = 'RENT';
      }
      packagesService.getList(packagesFilter).then((response) => {
        setPackages(response.content);
        setLoading(false);
      });
    });
  }, []);

  const SinglePackage = ({ packageData }) => {
    const handlePackage = () => {
      userService.changePackage(packageData.id).then(() => {
        packageSuccess(() => {
          setLoading(true);
          window.location.reload();
        });
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }

        if (err?.response?.status === 406) {
          message.error('Wybrany pakiet jest niewystarczający do obsługi obecnej ilości danych');
        }
      });
    };

    const packageColor = packageData.color ? packageData.color : 'inherit';
    const packageType = packageData.type === 'RENT' ? 'N' : 'S';

    const PackageLimitRenderer = ({ value }) => {
      if (value !== -1) {
        return `max ${value}`;
      }

      return '∞';
    };
    return (
      <Col xs={24} sm={12} md={8}>
        <Card className="single-package">
          <h2 className="single-package-header" style={{ color: packageColor }}>
            {packageData.name}
            {' '}
            <sup className="single-package-header-sup">{packageType}</sup>
          </h2>
          {packageData.type !== 'RENT' && (
            packageData.limits.investments === -1 ? (
              <div className="single-package-investments">∞ inwestycji</div>
            ) : (
              <div className="single-package-investments">{`do ${packageData.limits.investments} inwestycji`}</div>
            )
          )}

          <div className="single-package-price">{`${packageData.price} zł`}</div>
          <div className="single-package-addnotation">Kwota netto + 23% VAT</div>
          <div className="single-package-limits">
            {packageData.type !== 'RENT' ? (
              <>
                <div className="single-package-limits-single">
                  <Row>
                    <Col className="single-package-limits-single-label" xs={16}>Lokale mieszkalne</Col>
                    <Col className="single-package-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.premises} /></Col>
                  </Row>
                </div>
                <div className="single-package-limits-single">
                  <Row>
                    <Col className="single-package-limits-single-label" xs={16}>Lokale usługowe</Col>
                    <Col className="single-package-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.service} /></Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className="single-package-limits-single">
                <Row>
                  <Col className="single-package-limits-single-label" xs={16}>Lokale mieszkalne i usługowe</Col>
                  <Col className="single-package-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.premisesRent} /></Col>
                </Row>
              </div>
            )}
            <div className="single-package-limits-single">
              <Row>
                <Col className="single-package-limits-single-label" xs={16}>Miejsca postojowe</Col>
                <Col className="single-package-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.parking} /></Col>
              </Row>
            </div>
            <div className="single-package-limits-single">
              <Row>
                <Col className="single-package-limits-single-label" xs={16}>Komórki lokatorskie</Col>
                <Col className="single-package-limits-single-value" xs={8}><PackageLimitRenderer value={packageData.limits.storage} /></Col>
              </Row>
            </div>
            {packageData.type !== 'RENT' && (
              <div className="single-package-limits-addnotation">
                / na 1 inwestycje
              </div>
            )}
          </div>
          <div className="single-package-action">
            <Button
              type="primary"
              size="large"
              className="create-form-button"
              disabled={userData.packageId === packageData.id}
              style={{
                backgroundColor: packageColor,
                borderColor: packageColor,
              }}
              onClick={() => packageConfirm(handlePackage)}
            >
              Wybierz
            </Button>
          </div>
        </Card>
      </Col>
    );
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => window.history.back()}
        title="Wróć"
      >
        {loading ? (
          <div className="loading"><Spin /></div>
        ) : (
          <div>
            <Row gutter={20}>
              {packages.map((packageData) => (
                <SinglePackage
                  key={packageData.id}
                  packageData={packageData}
                />
              ))}
            </Row>
            <div className="packages-help">
              <p style={{ opacity: '0.5' }}>* Zmiana pakietu wpływa na jego termin ważności</p>
              <p>Masz pytania, bądź potrzebujesz innego pakietu?</p>
              <a href="mailto:support@manage.com.pl">
                Napisz do nas
                {' '}
                <Icon name="arrow-simply-right" />
              </a>
            </div>
          </div>
        )}
      </PageHeader>
    </div>
  );
};

export default MyPackage;
