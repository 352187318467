import React, { useState, useEffect } from 'react';
import { Upload, Modal, Row, Col, Dropdown, Button, Menu, message } from 'antd';
import resolutionsImageService from '../../../services/resolutionsImageService';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import ProgressModal from '../../../components/ProgressModal';

const { confirm } = Modal;

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const Gallery = ({ investmentId, resolutionId }) => {
  const [images, setImages] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    const ResolutionsImageService = new resolutionsImageService(
      investmentId,
      resolutionId
    );
    ResolutionsImageService.getList().then((data) => {
      setImages(data);
    });
  }, [investmentId, resolutionId]);

  const refresh = () => {
    const ResolutionsImageService = new resolutionsImageService(
      investmentId,
      resolutionId
    );
    ResolutionsImageService.getList().then((data) => {
      setImages(data);
    });
  };

  const handleDelete = (fileId) => {
    const ResolutionsImageService = new resolutionsImageService(
      investmentId,
      resolutionId
    );
    ResolutionsImageService.delete(fileId)
      .then(() => {
        ResolutionsImageService.getList().then((data) => {
          setImages(data);
        });
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      cancelButtonProps: { className: 'button-secondary' },
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDownload = (file) => {
    const a = document.createElement('a');
    a.href = `${baseUrl}${file.filename}`;
    a.download = file.filename;
    a.target = '_blank';
    a.click();
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 15MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key='1'
        onClick={() => handleDownload(record)}
        icon={<Icon name='download' style={{ marginRight: '5px' }} />}
      >
        Pobierz
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => showDeleteConfirm(record)}
        style={{ color: '#EC434C' }}
        icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
      >
        Usuń
      </Menu.Item>
    </Menu>
  );

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('image', data.file);
    const ResolutionsImageService = new resolutionsImageService(
      investmentId,
      resolutionId
    );

    setProgressModalVisible(true);

    ResolutionsImageService.create(formData, {
      onUploadProgress: (progressEvent) => {
        setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })
      .then((response) => {
        setProgressModalVisible(false);
        message.success(
          <MessagePopup type={'success'}>Pomyślnie dodano zdjęcie</MessagePopup>
        );

        if (response.id) {
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  return (
    <div className='gallery create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <h2>Zdjęcia w galerii</h2>
          <div className='gallery-wrapper'>
            <Row gutter={[20, 20]}>
              {images.map((image) => (
                <Col xs={24} md={12} xl={6} style={{ aspectRatio: '1/1' }}>
                  <div
                    className='gallery-wrapper-image'
                    style={{
                      maxWidth: 'none',
                      maxHeight: 'none',
                      height: '100%',
                      width: 'auto',
                    }}
                  >
                    <img
                      src={`${baseUrl}${image.filename}`}
                      alt={image.filename}
                      style={{ width: '100%' }}
                    />
                    <div className='gallery-wrapper-actions'>
                      <Dropdown
                        overlay={() => menu(image)}
                        trigger='click'
                        placement='bottomCenter'
                        className='gallery-wrapper-action'
                      >
                        <Button className='button-no-border'>
                          <Icon name='more-vertical' />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              ))}
              <Col xs={24} md={12} xl={6}>
                <div
                  className='gallery-wrapper-add'
                  style={{
                    maxWidth: 'none',
                    maxHeight: 'none',
                    height: '100%',
                    width: 'auto',
                  }}
                >
                  <Upload
                    name='file'
                    customRequest={addNewAction}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    <Button className='button-secondary'>
                      <Icon name='plus' />
                    </Button>
                  </Upload>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
