import BaseService from './baseService';

class DocumentsListService extends BaseService {
  constructor() {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles?.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/documents`;
    } else if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/documents`;
    }
  }
}

export default DocumentsListService;
