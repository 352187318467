import React from 'react';
import {
  Modal, Form, Row, Col, Checkbox, message,
} from 'antd';
import { useParams } from 'react-router-dom';
import RoomsServiceS from '../../../../services/roomsService';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';

const { InputNumberV2, PriceInput } = Input;

const RoomModal = (props) => {
  const {
    data, afterCancel, afterSave, isModalVisible, type,
  } = props;
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const RoomsService = new RoomsServiceS(id, premisesId);

  if (data?.id) {
    form.setFieldsValue(data);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (data?.id) {
          RoomsService.update(data.id, values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          RoomsService.create(values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? `Edytujesz: ${data.name}` : 'Nowy pokój';

  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size="large" placeholder="Nazwa" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="size"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2 size="large" placeholder="Rozmiar" />
              </Form.Item>
            </Col>
            {type === 'RENT' && (
              <>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="rent"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >
                    <PriceInput size="large" placeholder="Czynsz" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="charges"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >
                    <PriceInput size="large" placeholder="Opłaty" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="deposit"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >
                    <PriceInput size="large" placeholder="Kaucja" />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24}>
              <Form.Item
                name="noSplit"
                valuePropName="checked"
              >
                <Checkbox size="large">Mieszkanie bez podziału</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RoomModal;
