import BaseService from './baseService';
import httpService from '../helpers/httpService';

class DashboardService extends BaseService {
  constructor() {
    super();
    this.url = '/api/user/dashboard';
  }

  async getSaleFaults() {
    return httpService.get(`${this.url}/faultsGroups?investmentType=SALE`, null, true);
  }

  async getRentFaults() {
    return httpService.get(`${this.url}/faultsGroups?investmentType=RENT`, null, true);
  }

  async getTenantChanges() {
    return httpService.get(`${this.url}/tenantChangeGroups?investmentType=SALE`, null, true);
  }

  async getPayments() {
    return httpService.get(`${this.url}/payments`, null, true);
  }

  async getSettlements() {
    return httpService.get(`${this.url}/settlements`, null, true);
  }

  async getAgreements() {
    return httpService.get(`${this.url}/agreements`, null, true);
  }

  async getRentPremises() {
    return httpService.get(`${this.url}/premises/rent`, null, true);
  }

  async getSalePremises() {
    return httpService.get(`${this.url}/premises/sale`, null, true);
  }

  async getPremisesList() {
    return httpService.get(`${this.url}/premises/list`, null, true);
  }

  async getAgreementsList() {
    return httpService.get(`${this.url}/agreements/list`, null, true);
  }
}

export default DashboardService;
