import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import Price from '../../../../components/Price';

export const CostsBox = ({ premise, costs }) => {
  if (premise.status === 'FREE') {
    return '';
  }

  return (
    <div className="premises-show-box costs-box">
      <h2>
        <Icon name="more-info" />
        Saldo
      </h2>
      <div className="content">
        <Row gutter={20}>
          <Col sm={12}>
            <div className="value"><Price value={costs.balance} showEmpty /></div>
            <div className="title">Saldo</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
