import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Col, PageHeader } from 'antd';
import { Datagrid } from '../../../components';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import { premiseTypeMap } from '../../../helpers';
import memberTenantAssociationsService from '../../../services/memberTenantAssociationsService';
import tenantPremisesService from '../../../services/tenantPremisesService';

const { Select } = Input;
const { Option } = Select;

const columns = [
  // {
  //   title: 'Lokal',
  //   dataIndex: 'name',
  //   visible: true,
  //   filterEnabled: false,
  //   key: 'premise',
  //   sorter: true,
  //   sortDirections: ['descend', 'ascend'],
  //   render: (text, record) => `${premiseTypeMap(record.type)} ${record.name}`,
  //   exportRender: (text, record) =>
  //     `${premiseTypeMap(record.type)} ${record.name}`,
  // },
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    export: true,
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    filterEnabled: true,
    filterType: 'select',
    filterOptions: [
      { value: 'RENT', label: 'Mieszkanie' },
      { value: 'STORAGE', label: 'Komórka lokatorska' },
      { value: 'PARKING', label: 'Miejsce postojowe' },
      { value: 'SERVICE', label: 'Lokal usługowy' },
    ],
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    export: true,
    render: (text, record) => premiseTypeMap(record.type),
    exportRender: (text, record) => premiseTypeMap(record.type),
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span>
        {text} m<sup>2</sup>
      </span>
    ),
  },
  {
    title: 'Powierzchnia od',
    dataIndex: 'totalSurfaceFrom',
    visible: false,
    key: 'totalSurfaceFrom',
    sorter: false,
    filterEnabled: true,
    export: false,
  },
  {
    title: 'Powierzchnia do',
    dataIndex: 'totalSurfaceTo',
    visible: false,
    key: 'totalSurfaceTo',
    sorter: false,
    filterEnabled: true,
    export: false,
  },
];

const customFilters = [];

const List = () => {
  const { id } = useParams();
  const [baseUrlKey, setBaseUrlKey] = useState();
  const [investment, setInvestment] = useState({});

  const TenantPremisesService = new tenantPremisesService(id);

  useEffect(() => {
    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();

    MemberTenantAssociationsService.getDetails(id).then((data) => {
      setInvestment(data);

      if (data.type === 'HOUSING_ASSOCIATION') {
        setBaseUrlKey('tenantAssociations');
      } else if (data.type === 'HOUSING_COMMUNITY') {
        setBaseUrlKey('tenantCommunities');
      }
    });
  }, []);

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Lokale</span>;
    }
    return (
      <span>
        Lokale: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            backIcon={<Icon name='arrow-simply-left' />}
            onBack={() => window.history.back()}
            title={<HeaderTitle />}
          />
        }
        exportTitle={'Lokale'}
        resource={`${baseUrlKey}/${id}/premises`}
        customBaseUrlKey={baseUrlKey}
        dataProvider={TenantPremisesService}
        columns={columns}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        hideCreate
        hideEdit
      />
    </div>
  );
};

export default List;
