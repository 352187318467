import React from 'react';
import Datagrid from '../../components/Datagrid';
import communityAdminsService from '../../services/communityAdminsService';

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'NIP',
    dataIndex: 'nip',
    visible: true,
    filterEnabled: true,
    key: 'nip',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    filterEnabled: true,
    key: 'mobile',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`tel:${text}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    filterEnabled: true,
    key: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`mailto:${text}`}>
        {text}
      </a>
    ),
  },
];

const List = () => (
  <div>
    <Datagrid
      title='Lista administratorów nieruchomości'
      resource='communityAdmins'
      dataProvider={communityAdminsService}
      columns={columns}
      filters={{
        role: 'ROLE_COMMUNITY_ADMIN',
      }}
    />
  </div>
);

export default List;
