import React from 'react';
import Datagrid from '../../components/Datagrid';
import developersService from '../../services/developersService';

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'nameDeveloper',
    visible: true,
    filterEnabled: true,
    key: 'nameDeveloper',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'NIP',
    dataIndex: 'nip',
    visible: true,
    filterEnabled: true,
    key: 'nip',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    filterEnabled: true,
    key: 'mobile',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`tel:${text}`}>{text}</a>,
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    filterEnabled: true,
    key: 'email',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`mailto:${text}`}>{text}</a>,
  },
];

const List = () => (
  <div>
    <Datagrid
      title="Lista deweloperów"
      resource="developers"
      dataProvider={developersService}
      columns={columns}
      filters={{
        role: 'ROLE_DEVELOPER',
      }}
    />
  </div>
);

export default List;
