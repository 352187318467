import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import mainCountersService from '../../../services/mainCountersService';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import { deleteConfirm } from '../../../components';

const { Select } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const { id, counterId } = useParams();
  const [form] = Form.useForm();
  const [mainCounter, setMainCounter] = useState({});

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);

    MainCountersService.getOne(counterId).then((response) => {
      setMainCounter(response);
      form.setFieldsValue(response);
    });
  }, []);

  const onFinish = (values) => {
    const MainCountersService = new mainCountersService(id);

    MainCountersService.update(counterId, values)
      .then((response) => {
        // history.replace(`/investment/${id}/counters`);
        history.goBack();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const HeaderTitle = () => {
    if (!mainCounter.number) {
      return <span>Edytujesz licznik główny</span>;
    }
    return (
      <span>
        Edytujesz licznik główny:{' '}
        <span className='header-subtitle'>{mainCounter.number}</span>
      </span>
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const MainCountersService = new mainCountersService(id);

    MainCountersService.delete(counterId)
      // .then(() => history.push(`/investment/${id}/counters`))
      .then(() => history.goBack())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleCancel = () => {
    // history.push(`/investment/${id}/counters`);
    history.goBack();
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={<HeaderTitle />}
      >
        <Form
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          form={form}
        >
          <Card>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={16} offset={4}>
                  <div className='form-section'>
                    <h2>Podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='number'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Numer licznika' />
                        </Form.Item>
                      </Col>
                      <Col xs={0} sm={12} />
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Nazwa licznika' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='unit'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <Select placeholder='Jednostka' size='large'>
                            <Option value='GJ'>GJ</Option>
                            <Option value='kwh'>kwh</Option>
                            {/* <Option value='m'>m</Option> */}
                            <Option value='m3'>
                              m<sup>3</sup>
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <Button
              className='delete-btn'
              type='danger'
              onClick={() => deleteConfirm(handleOk)}
            >
              <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
            </Button>
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='create-form-button'
                >
                  Zapisz
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
