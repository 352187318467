import BaseService from './baseService';

class MainCountersService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/mainCounters`;
  }
}

export default MainCountersService;
