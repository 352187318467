import httpService from '../helpers/httpService';

const userService = {};

userService.getInfo = async () => httpService.get('/api/user', null, true);

userService.update = async (data) => httpService.put('/api/user', data, true);

userService.uploadAvatar = async (data, config = {}) =>
  httpService.post(
    '/api/user/avatar',
    data,
    true,
    {
      'content-type': 'multipart/form-data',
    },
    config
  );

userService.changePackage = async (id) =>
  httpService.put(`/api/user/packages/${id}`, {}, true);
export default userService;
