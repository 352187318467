import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import memberMessagesService from '../../../services/memberMessagesService';
import Icon from '../../../components/Icon';
import Spin from '../../../components/Spin';
import moment from 'moment';

const Show = () => {
  const { messageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [messageData, setMessageData] = useState({});

  useEffect(() => {
    const MemberMessagesService = new memberMessagesService();

    MemberMessagesService.getOne(messageId).then((data) => {
      setMessageData(data);

      setLoading(false);
    });
  }, [messageId]);

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          title={'Wiadomość'}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
          ]}
        >
          <Card style={{ paddingTop: 40, paddingBottom: 40 }}>
            <Row>
              <Col xs={2} />
              <Col xs={20}>
                <Row style={{ marginBottom: '20px' }}>
                  <Col xs={24} md={6} lg={4} xxl={3}>
                    <div style={{ color: '#A7A7FA' }}>Data wysłania:</div>
                  </Col>
                  <Col xs={24} md={18} lg={20} xxl={21}>
                    {messageData?.createDate
                      ? moment(messageData?.createDate).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )
                      : null}
                  </Col>
                </Row>

                <Row>
                  <div
                    style={{ fontSize: 24, marginTop: 30, marginBottom: 20 }}
                  >
                    {messageData?.title}
                  </div>
                </Row>
                <Row>
                  <div
                    className='content react-draft-wysiwyg-resolution-details'
                    dangerouslySetInnerHTML={{
                      __html: messageData?.content,
                    }}
                  />
                </Row>
              </Col>
              <Col xs={2} />
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
