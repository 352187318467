import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
} from 'antd';

import moment from 'moment';
import PremisesServiceS from '../../../services/premisesService';
import investmentsService from '../../../services/investmentsService';
import Icon from '../../../components/Icon';
import deleteConfirm from '../../../components/deleteConfirm';

import { Brokers } from './grids/Brokers';
import { Meters } from './grids/Meters';
import { Faults } from './grids/Faults';
import { Agreements } from './grids/Agreements';
import { Costs } from './grids/Costs';
import { Rooms } from './grids/Rooms';
import { Attachments } from './grids/Attachments';
import { Gallery } from './grids/Gallery';
import { Tranches } from './grids/Tranches';
import { TenantChanges } from './grids/TenantChanges';

import PriceForm from './editComponents/Price';

import Input from '../../../components/InputComponent';
import Tabs from '../../../components/Tabs';
import CountrySelect from '../../../components/CountrySelect';

const { Select, InputNumber, InputNumberV2, TextArea, DatePicker, Switch } =
  Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Edit = () => {
  const { id, premisesId } = useParams();

  const [premisesData, setPremisesData] = useState({});
  const [currentStatus, setCurrentStatus] = useState('');
  const [investment, setInvestment] = useState({});

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    const PremisesService = new PremisesServiceS(id);
    PremisesService.getOne(premisesId).then((data) => {
      const formData = data;
      if (data.soldStatusDate) {
        formData.soldStatusDate = moment(formData.soldStatusDate, 'YYYY-MM-DD');
      }
      setPremisesData(data);
      setCurrentStatus(data.status);
      if (data.geographicDirections === null) {
        formData.geographicDirections = undefined;
      }
      form.setFieldsValue(formData);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);
    });
  }, [id, form, premisesId]);

  const onFinish = (values) => {
    if (values?.soldStatusDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        soldStatusDate: values.soldStatusDate.format('YYYY-MM-DD'),
      };
    }

    const data = {
      ...values,
    };

    const PremisesService = new PremisesServiceS(id);
    PremisesService.update(premisesId, data)
      .then((response) => {
        if (response.id) {
          history.push(`/investments/${id}/premises/${response.id}/show`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const PremisesService = new PremisesServiceS(id);
    PremisesService.delete(premisesId)
      .then(() => history.push(`/investments/${id}/premises`))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const handleCancel = () => {
    history.push(`/investments/${id}/premises`);
  };

  const handleStatusChange = (status) => {
    setCurrentStatus(status);
  };

  const HeaderTitle = () => {
    if (!premisesData.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{premisesData.name}</span>
      </span>
    );
  };

  const onFieldsChange = (fieldChanged) => {
    const fieldChangedNames = fieldChanged
      .map((field) => field.name)
      .reduce((a, b) => a.concat(b));
    if (fieldChangedNames.includes('investmentAddress')) {
      const investmentAddress = form.getFieldValue('investmentAddress');
      if (investmentAddress) {
        form.setFieldsValue({
          address: investment.address,
        });
      } else {
        form.setFieldsValue({
          address: premisesData.address,
        });
      }
    }
  };

  const floorOptions = [];
  for (let index = -5; index <= 20; index += 1) {
    floorOptions.push(<Option value={index}>{index}</Option>);
  }

  const canShowLocal =
    premisesData.type !== 'STORAGE' && premisesData.type !== 'PARKING';

  return (
    <div className='form-create premises-edit'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => history.push(`/investments/${id}/premises`)}
        title={<HeaderTitle />}
        extra={[
          <Button type='danger' onClick={() => deleteConfirm(handleOk)}>
            <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Dane podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='name'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='floor'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Select placeholder='Piętro' size='large'>
                                  {floorOptions}
                                </Select>
                              </Form.Item>
                            </Col>
                            {premisesData.type !== 'STORAGE' &&
                              premisesData.type !== 'PARKING' && (
                                <Col xs={24} sm={12}>
                                  <Form.Item name='geographicDirections'>
                                    <Select
                                      placeholder='Strony świata'
                                      size='large'
                                      mode='multiple'
                                    >
                                      <Option value='Północ'>Północ</Option>
                                      <Option value='Południe'>Południe</Option>
                                      <Option value='Wschód'>Wschód</Option>
                                      <Option value='Zachód'>Zachód</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              )}
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Status</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item name='status'>
                                <Select
                                  placeholder='Wybierz status'
                                  size='large'
                                  onChange={(e) => handleStatusChange(e)}
                                >
                                  <Option value='FREE'>
                                    <span className='dot green' />
                                    Wolne
                                  </Option>
                                  {investment.type === 'SALE' && (
                                    <Option value='RESERVATION'>
                                      <span className='dot yellow' />
                                      Zarezerwowane
                                    </Option>
                                  )}
                                  {investment.type === 'SALE' && (
                                    <Option value='SOLD'>
                                      <span className='dot red' />
                                      Sprzedane
                                    </Option>
                                  )}
                                  {investment.type === 'RENT' && (
                                    <Option value='PARTIAL_RENT'>
                                      <span className='dot halfred' />
                                      Częściowo wynajęte
                                    </Option>
                                  )}
                                  {investment.type === 'RENT' && (
                                    <Option value='RENT'>
                                      <span className='dot red' />
                                      Wynajęte
                                    </Option>
                                  )}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.status !== current.status
                              }
                            >
                              {({ getFieldValue }) =>
                                [
                                  'SOLD',
                                  'RENT',
                                  'PARTIAL_RENT',
                                  'RESERVATION',
                                ].includes(getFieldValue('status')) && (
                                  <Col xs={24} sm={12}>
                                    <Form.Item
                                      name='soldStatusDate'
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <DatePicker
                                        size='large'
                                        placeholder='Data przekazania'
                                        style={{ width: '100%' }}
                                      />
                                    </Form.Item>
                                  </Col>
                                )
                              }
                            </Form.Item>
                          </Row>
                        </div>
                        {investment.type === 'SALE' && (
                          <div className='form-section'>
                            <h2>Numer rachunku powierniczego</h2>
                            <Row gutter={20}>
                              <Col xs={24}>
                                <Form.Item name='bankAccount'>
                                  <Input.MaskedInput
                                    mask='00 0000 0000 0000 0000 0000 0000'
                                    size='large'
                                    placeholder='00 0000 0000 0000 0000 0000 0000'
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {premisesData.type !== 'STORAGE' &&
                          premisesData.type !== 'PARKING' && (
                            <div className='form-section'>
                              <Rooms
                                investmentId={id}
                                premisesId={premisesId}
                                type={investment.type}
                              />
                            </div>
                          )}
                        <div className='form-section'>
                          <h2>Powierzchnia użytkowa</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='totalSurface'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <InputNumberV2
                                  size='large'
                                  placeholder='Powierzchnia (w m2)'
                                />
                              </Form.Item>
                              {premisesData.type !== 'STORAGE' &&
                                premisesData.type !== 'PARKING' && (
                                  <span>
                                    W tym: pokoje, kuchnia, korytarz, toaleta,
                                    itp.
                                  </span>
                                )}
                            </Col>
                            {premisesData.type !== 'STORAGE' &&
                              premisesData.type !== 'PARKING' && (
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name='roomsCount'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Pole wymagane',
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      size='large'
                                      placeholder='Liczba pokoi'
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Adres</h2>
                          <div className='premise-investment-address'>
                            <Row gutter={20}>
                              <Col xs={12}>Pobierz adres z inwestycji</Col>
                              <Col xs={12} style={{ textAlign: 'right' }}>
                                <Form.Item
                                  name='investmentAddress'
                                  valuePropName='checked'
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={canShowLocal ? 6 : 12}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            {canShowLocal && (
                              <Col xs={24} sm={6}>
                                <Form.Item name={['address', 'local']}>
                                  <Input size='large' placeholder='Lokal' />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Notatka</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item name='comment'>
                                <TextArea rows={4} placeholder='Notatka' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        {investment.type === 'RENT' && (
                          <div className='form-section'>
                            <Meters
                              investmentId={id}
                              premisesId={premisesId}
                              heatSource={investment.heatSource}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Galeria' key='gallery'>
                  <div className='gallery create-form-wrapper'>
                    <Gallery investmentId={id} premisesId={premisesId} />
                  </div>
                </TabPane>
                <TabPane tab='Załączniki' key='attachments'>
                  <Attachments investmentId={id} premisesId={premisesId} />
                </TabPane>
                {investment.type === 'SALE' && (
                  <TabPane tab='Cena' key='price' forceRender>
                    <PriceForm premisesData={premisesData} form={form} />
                  </TabPane>
                )}
                {investment.type === 'RENT' && (
                  <TabPane tab='Koszty najmu' key='costs'>
                    <Costs investmentId={id} premisesId={premisesId} />
                  </TabPane>
                )}
                <TabPane tab='Umowy' key='agreements'>
                  <Agreements
                    investmentId={id}
                    premisesId={premisesId}
                    type={investment.type}
                    status={currentStatus}
                  />
                </TabPane>
                {investment.type === 'SALE' && (
                  <TabPane tab='Harmonogram płatności' key='tranches'>
                    <Tranches investmentId={id} premisesId={premisesId} />
                  </TabPane>
                )}
                {investment.type === 'SALE' && (
                  <TabPane tab='Pośrednik' key='brokers'>
                    <Brokers investmentId={id} premisesId={premisesId} />
                  </TabPane>
                )}
                <TabPane tab='Usterki' key='faults'>
                  <Faults investmentId={id} premisesId={premisesId} />
                </TabPane>
                {investment.type === 'SALE' && (
                  <TabPane tab='Zmiany lokatorskie' key='tenantChanges'>
                    <TenantChanges investmentId={id} premisesId={premisesId} />
                  </TabPane>
                )}
              </Tabs>
            </Card>
            <Divider />
            <Row className='form-actions' justify='space-between'>
              <Col xs={11} sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col xs={11} sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
