import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, Card } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom';
import investmentsService from '../../../services/investmentsService';
import Icon from '../../../components/Icon';
import Spin from '../../../components/Spin';
import { ImportsList } from './grids/ImportsList';

const Import = () => {
  const history = useHistory();
  const { id } = useParams();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);
      setLoading(false);
    });
  }, [id]);

  const handleCancel = () => {
    history.push(`/housingAssociations/${id}/premises`);
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => window.history.back()}
          title={
            <div>
              Importuj lokale:{'  '}
              <Link
                to={`/housingAssociations/${id}`}
                style={{ color: '#5353F4' }}
              >
                {investment.name}
              </Link>
            </div>
          }
        >
          <Card>
            <ImportsList />
          </Card>
        </PageHeader>
      )}
    </div>
  );
};
export default Import;
