import React, { useEffect } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import investmentPremisesContactService from '../../../../services/investmentPremisesContactService';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';
import MessagePopup from '../../../../components/MessagePopup';

const { Mobile } = Input;

const ContactsModal = (props) => {
  const { afterCancel, isModalVisible, editingContact } = props;
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const InvestmentPremisesContactService = new investmentPremisesContactService(
    id,
    premisesId
  );

  useEffect(() => {
    if (editingContact) {
      form.setFieldsValue(editingContact);
    }
  }, [editingContact]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingContact?.id) {
          InvestmentPremisesContactService.update(editingContact.id, values)
            .then((response) => {
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type='error'>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          InvestmentPremisesContactService.create(values)
            .then((response) => {
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type='error'>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={editingContact ? 'Edytuj kontakt' : 'Dodaj kontakt'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={410}
        footer={
          <Footer
            onReset={onReset}
            onFinish={onFinish}
            isNew={!editingContact?.id}
          />
        }
      >
        <Form
          form={form}
          name='filterDeveloper'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name='firstName'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size='large' placeholder='Imię' />
              </Form.Item>
              <Form.Item
                name='lastName'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size='large' placeholder='Nazwisko' />
              </Form.Item>
              <Form.Item
                name={'phone'}
                rules={[
                  {
                    required: true,
                    message: 'Pole wymagane',
                  },
                ]}
              >
                <Mobile size='large' placeholder='Telefon' />
              </Form.Item>
              <Form.Item
                name={'email'}
                rules={[
                  {
                    required: false,
                    message: 'Proszę uzupełnić adres email',
                  },
                  {
                    type: 'email',
                    message: 'Niepoprawny adres email',
                  },
                ]}
              >
                <Input size='large' placeholder='Adres e-mail' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ContactsModal;
