import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';
import settlementPaymentsService from '../../../services/settlementPaymentsService';
import HousingTenantsService from '../../../services/housingTenantsService';
import memberInvestmentPremisesService from '../../../services/memberInvestmentPremisesService';
import MessagePopup from '../../../components/MessagePopup';

const { DatePicker, InputNumberV2 } = Input;

const PaymentEditModal = (props) => {
  const { afterCancel, isModalVisible, refresh, editingPayment } = props;
  const [form] = Form.useForm();
  const { id, premisesId, settlementId } = useParams();

  useEffect(() => {
    if (editingPayment) {
      const tempData = editingPayment;
      if (editingPayment.expirationDate) {
        tempData.expirationDate = moment(
          editingPayment.expirationDate,
          'YYYY-MM-DD'
        );
      }
      if (editingPayment.paymentDate) {
        tempData.paymentDate = moment(editingPayment.paymentDate, 'YYYY-MM-DD');
      }
      form.setFieldsValue(tempData);
    }
  }, [editingPayment]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const data = { ...values };

        if (values.paid) {
          data.paid = parseFloat(values.paid);
        }
        if (values.expirationDate) {
          data.expirationDate = values.expirationDate.format('YYYY-MM-DD');
        }
        if (values.paymentDate) {
          data.paymentDate = values.paymentDate.format('YYYY-MM-DD');
        }

        const SettlementPaymentsService = new settlementPaymentsService(
          id,
          premisesId,
          settlementId
        );

        SettlementPaymentsService.editPayment(editingPayment.id, data)
          .then((response) => {
            refresh();
            afterCancel();
          })
          .catch((err) => {
            if (err.response.status === 409) {
              message.error(
                <MessagePopup
                  type={'error'}
                  annotation={'Najemca jest już przypisany do lokalu'}
                >
                  Nie można przypisać najemcy
                </MessagePopup>
              );
            }
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type={'error'}>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={'Edytujesz płatność'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} />}
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name='expirationDate'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker
                  placeholder='Termin płatności'
                  size='large'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name='paymentDate'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker
                  placeholder='Data opłacenia'
                  size='large'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name='paid'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Zapłacono'
                  addonAfter={'zł brutto'}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name='amount'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Kwota'
                  addonAfter={'zł brutto'}
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentEditModal;
