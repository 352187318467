import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import brokersService from '../../../../services/brokersService';
import workersService from '../../../../services/workersService';
import Icon from '../../../../components/Icon';
import SmallList from '../../../../components/SmallList';

export const BrokersBox = ({ premise, investment }) => {
  const { id, premisesId } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const BrokersService = new brokersService(id, premisesId);
    BrokersService.getList().then((response) => {
      const workerPromises = response.map((broker) => workersService.getOne(broker.workerId));
      Promise.all(workerPromises).then((results) => {
        const temp = response.map((broker) => {
          const worker = results.find((obj) => obj.id === broker.workerId);
          return {
            ...broker,
            name: `${worker.firstName} ${worker.lastName}`,
            mobile: worker.mobile,
          };
        });
        setData(temp);
      });
    });
  }, [id, premisesId]);
  if (!data.length) {
    return '';
  }
  if (investment.type === 'SALE' && premise.status === 'FREE') {
    return '';
  }

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      render: (text, record) => (<span className="premises-broker-phone">{record.mobile}</span>),
    },
    {
      title: 'Procent zysku',
      dataIndex: 'percent',
      render: (text, record) => (`${record.percent} %`),
    },
  ];
  return (
    <div className="premises-show-box brokers-box">
      <h2>
        <Icon name="percent" />
        Pośrednik
      </h2>
      <div className="content">
        <SmallList data={data} columns={columns} showHeader={false} icon="percent" />
      </div>
    </div>
  );
};
