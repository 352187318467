import BaseService from './baseService';
import httpService from '../helpers/httpService';

class HousingSettlementsService extends BaseService {
  constructor() {
    super();
    this.url = `/api/community/settlements`;
  }

  async getMemberBalance(userId) {
    return httpService.get(
      `/api/community/user/${userId}/settlements/balance`,
      null,
      true
    );
  }

  async getSettlementTemplateCosts(investmentId, queryParams) {
    return httpService.get(
      `/api/community/investment/${investmentId}/settlementTemplates/preview`,
      queryParams,
      true
    );
  }

  async delete(investmentId, premisesId, communitySettlementId) {
    return httpService.delete(
      `/api/community/investment/${investmentId}/premises/${premisesId}/settlements/${communitySettlementId}`,
      null,
      true
    );
  }
}

export default new HousingSettlementsService();
