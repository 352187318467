import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Tooltip, Button } from 'antd';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import faultsService from '../../services/faultsService';
import faultsStatusMap from '../../helpers/faultsStatusMap';

const Dot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const List = ({ record: faultGroup, userData, redirectState }) => {
  const history = useHistory();

  const getWidth = (columnKey) => {
    try {
      return document
        .querySelectorAll(
          `[data-row-key="${faultGroup.id}"] td.datagrid-column-${columnKey}`
        )
        .item(0).offsetWidth;
    } catch (error) {
      return undefined;
    }
  };

  const getSelectionWidth = () => {
    try {
      return `${
        document
          .querySelectorAll(
            `[data-row-key="${faultGroup.id}"] td.ant-table-selection-column`
          )
          .item(0).offsetWidth
      }px`;
    } catch (error) {
      return undefined;
    }
  };
  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
      width: getWidth('name'),
    },
    ...(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
    (userData?.roles?.includes('ROLE_WORKER') &&
      userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ? [
          {
            dataIndex: 'hide',
            visible: true,
            key: 'hide',
            width: getWidth('responsibleWorkers'),
          },
        ]
      : []),
    // {
    //   dataIndex: 'premisesType',
    //   visible: true,
    //   key: 'premisesType',
    //   sorter: false,
    //   width: getWidth('premisesType'),
    // },
    // {
    //   dataIndex: 'premisesName',
    //   visible: true,
    //   key: 'premisesName',
    //   sorter: false,
    //   width: getWidth('premisesName'),
    // },
    {
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: false,
      width: getWidth('premisesName'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => <Dot status={record.status} />,
      exportRender: (text, record) => faultsStatusMap(record.status),
      width: getWidth('status'),
    },
    {
      title: 'Data dodania',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
      width: getWidth('addDate'),
    },
    {
      title: 'Usunięto',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
      width:
        !userData?.roles?.includes('ROLE_CLIENT_TENANT') &&
        !userData?.roles?.includes('ROLE_CLIENT')
          ? getWidth('fixDate')
          : undefined,
    },
    ...(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
    (userData?.roles?.includes('ROLE_WORKER') &&
      userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ? [
          {
            title: 'Kwota',
            dataIndex: 'amount',
            visible: true,
            key: 'amount',
            sorter: false,
            // summary: true,
            render: (text, record) => <Price value={record.amount} showEmpty />,
          },
        ]
      : []),
    {
      dataIndex: 'filler',
      visible: true,
      key: 'filler',
      sorter: false,
      width: 76,
    },
  ];

  const customBaseUrl = (record) =>
    `housingFaultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault/${record.id}`;

  return (
    <div
      className='expanded-datagrid-wrapper'
      style={{ paddingLeft: getSelectionWidth() }}
    >
      <Datagrid
        resource={`housingFaultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault`}
        dataProvider={new faultsService(faultGroup.investmentId, faultGroup.id)}
        columns={columns}
        hideAllActions
        directResponse
        disableSelection
        calculateBaseUrl={customBaseUrl}
        disablePagination
        showHeader={false}
        redirectState={redirectState}
        hideEdit
        hideCreate
        customRowActions={[
          (record) => {
            if (
              (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
              !record.isDeveloperNotified &&
              record?.status !== 'DONE' &&
              (record?.status === 'TO_FIX' ||
                userData?.id === record.reporterId)
            ) {
              return (
                <Link
                  to={`/housingFaultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault/${record.id}/edit`}
                >
                  <Button className='button-no-border'>
                    <Icon name='edit' />
                  </Button>
                </Link>
              );
            }
            if (
              userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') &&
              !record.isAdminNotified &&
              !record.isDeveloperNotified
            ) {
              return (
                <Link
                  to={`/housingFaultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault/${record.id}/edit`}
                >
                  <Button className='button-no-border'>
                    <Icon name='edit' />
                  </Button>
                </Link>
              );
            }

            return null;
          },
        ]}
      />
    </div>
  );
};

export default List;
