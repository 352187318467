import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import FaultsServiceS from '../../../services/faultsService';
import userService from '../../../services/userService';
import { Icon, Spin, Input } from '../../../components';

const { DatePicker } = Input;
const { TextArea } = Input;

const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { faultGroupId, investmentId } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, []);

  const onFinish = (values) => {
    if (values?.createDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        createDate: values.createDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.fixDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        fixDate: values.fixDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.doneDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        doneDate: values.doneDate.format('YYYY-MM-DD'),
      };
    }
    const FaultsService = new FaultsServiceS(investmentId, faultGroupId);
    FaultsService.create(values)
      .then((response) => {
        if (response.id) {
          history.push(
            `/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${response.id}/edit`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleBack = () => window.history.back();

  const isClient = userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') || false;

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => handleBack()}
          title='Dodaj zgłoszenie'
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            initialValues={{
              investmentId,
              addDate: moment(),
              status: 'REPORTED',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <>
              <Card className='form-create-main-card'>
                <div className='create-form-wrapper'>
                  <Row>
                    <Col xs={20} offset={2}>
                      <div className='form-section'>
                        <h2>Podstawowe</h2>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='name'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <Input size='large' placeholder='Nazwa' />
                            </Form.Item>
                          </Col>
                          {/* {!isClient && (
                            <Col xs={24} sm={12}>
                              <Form.Item name='amount'>
                                <InputNumberV2
                                  size='large'
                                  placeholder='Kwota jednostkowa'
                                  addonAfter={'zł brutto'}
                                />
                              </Form.Item>
                            </Col>
                          )} */}
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='addDate'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <DatePicker
                                size='large'
                                placeholder='Data wystąpienia'
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                          {!isClient && (
                            <Col xs={24} sm={12}>
                              <Form.Item name='fixDate'>
                                <DatePicker
                                  size='large'
                                  placeholder='Usunięto'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      </div>
                      <div className='form-section'>
                        <h2>Szczegóły</h2>
                        {/* {!isClient && (
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item name='responsibleWorkers'>
                                <SearchBox
                                  placeholder='Osoba odpowiedzialna'
                                  size='large'
                                  resource={workersService}
                                  filters={{ role: 'ROLE_WORKER' }}
                                  resourceField='firstOrLastName'
                                  optionValue={(e) => getUserOptionLabel(e)}
                                  mode='multiple'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )} */}

                        <Row gutter={20}>
                          <Col xs={24}>
                            <Form.Item name='description'>
                              <TextArea
                                rows={4}
                                placeholder='Opis zgłoszenia'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
              <Divider />
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleBack}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      Dodaj
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
