import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';

const LoggedMenu = ({ shouldShowMenu }) => {
  const location = useLocation();
  const { pathname } = location;

  const getSelectedMenu = () => {
    if (pathname.startsWith('/agreements')) {
      return ['12'];
    }

    if (pathname.startsWith('/developers')) {
      return ['2'];
    }

    if (pathname.startsWith('/clientsTenants')) {
      return ['5'];
    }

    if (pathname.startsWith('/clients')) {
      return ['4'];
    }

    if (pathname.startsWith('/investments')) {
      return ['7'];
    }

    if (pathname.startsWith('/tenantChanges')) {
      return ['11'];
    }

    if (pathname.startsWith('/tenants')) {
      return ['3'];
    }

    if (pathname.startsWith('/workers')) {
      return ['6'];
    }

    if (pathname.startsWith('/payments')) {
      return ['8'];
    }

    if (pathname.startsWith('/packagePayments')) {
      return ['14'];
    }

    if (pathname.startsWith('/settlements')) {
      return ['9'];
    }

    if (pathname.startsWith('/faultGroup')) {
      return ['10'];
    }

    if (pathname.startsWith('/premises')) {
      return ['20'];
    }

    if (pathname.startsWith('/communityAdmins')) {
      return ['40'];
    }

    if (pathname.startsWith('/housingAssociations')) {
      return ['41'];
    }

    if (pathname.startsWith('/housingCommunities')) {
      return ['42'];
    }

    if (pathname.startsWith('/housingPremises')) {
      return ['43'];
    }

    if (pathname.startsWith('/members')) {
      return ['44'];
    }

    if (pathname.startsWith('/housingWorkers')) {
      return ['45'];
    }

    if (pathname.startsWith('/memberAssociations')) {
      return ['46'];
    }

    if (pathname.startsWith('/memberCommunities')) {
      return ['47'];
    }

    if (pathname.startsWith('/memberPremises')) {
      return ['48'];
    }

    if (pathname.startsWith('/housingTenants')) {
      return ['49'];
    }

    if (pathname.startsWith('/tenantAssociations')) {
      return ['50'];
    }

    if (pathname.startsWith('/tenantCommunities')) {
      return ['51'];
    }

    if (pathname.startsWith('/tenantPremises')) {
      return ['52'];
    }

    if (pathname.startsWith('/housingSettlements')) {
      return ['53'];
    }

    if (pathname.startsWith('/memberSettlements')) {
      return ['54'];
    }

    if (pathname.startsWith('/tenantSettlements')) {
      return ['55'];
    }

    if (pathname.startsWith('/housingFaultGroup')) {
      return ['56'];
    }

    if (pathname.startsWith('/resolutions')) {
      return ['57'];
    }

    if (pathname.startsWith('/documents')) {
      return ['58'];
    }

    if (pathname.startsWith('/packages')) {
      return ['13'];
    }

    if (pathname.startsWith('/receipts')) {
      return ['21'];
    }

    if (pathname.startsWith('/tags')) {
      return ['22'];
    }

    if (pathname.startsWith('/receivables')) {
      return ['30'];
    }

    return ['1'];
  };

  const toggleChat = () => {
    // eslint-disable-next-line no-undef
    $czaterMethods.showChat();
  };

  return (
    <Menu theme='dark' mode='inline' defaultSelectedKeys={getSelectedMenu()}>
      <Menu.Item key='1' icon={<Icon name='dashboard' />}>
        <Link to='/dashboard'>Dashboard</Link>
      </Menu.Item>
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT']) && (
        <Menu.Item key='7' icon={<Icon name='investment' />}>
          <Link to='/investments'>Inwestycje</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'INVESTMENT_FULL', 'ROLE_TENANT') ||
        shouldShowMenu(
          ['ROLE_WORKER'],
          'INVESTMENT_FULL',
          'ROLE_DEVELOPER'
        )) && (
        <Menu.Item key='7' icon={<Icon name='investment' />}>
          <Link to='/investments'>Inwestycje</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_TENANT', 'ROLE_CLIENT'],
        'INVESTMENT_FULL',
        'ROLE_DEVELOPER'
      ) && (
        <Menu.Item key='20' icon={<Icon name='apartments' />}>
          <Link to='/premises'>Lokale</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'INVESTMENT_FULL', 'ROLE_TENANT') ||
        shouldShowMenu(
          ['ROLE_WORKER'],
          'INVESTMENT_FULL',
          'ROLE_DEVELOPER'
        )) && (
        <Menu.Item key='20' icon={<Icon name='apartments' />}>
          <Link to='/premises'>Lokale</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_TENANT', 'ROLE_WORKER'],
        'CLIENTS_FULL',
        'ROLE_TENANT'
      ) && (
        <Menu.Item key='5' icon={<Icon name='tenant' />}>
          <Link to='/clientsTenants'>Najemcy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_DEVELOPER', 'ROLE_WORKER'],
        'CLIENTS_FULL',
        'ROLE_DEVELOPER'
      ) && (
        <Menu.Item key='4' icon={<Icon name='client' />}>
          <Link to='/clients'>Klienci</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_DEVELOPER', 'ROLE_TENANT'],
        'WORKERS_FULL',
        'ROLE_DEVELOPER'
      ) && (
        <Menu.Item key='6' icon={<Icon name='worker' />}>
          <Link to='/workers'>Pracownicy</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'WORKERS_FULL', 'ROLE_TENANT') ||
        shouldShowMenu(['ROLE_WORKER'], 'WORKERS_FULL', 'ROLE_DEVELOPER')) && (
        <Menu.Item key='6' icon={<Icon name='worker' />}>
          <Link to='/workers'>Pracownicy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_DEVELOPER', 'ROLE_TENANT', 'ROLE_CLIENT_TENANT', 'ROLE_CLIENT'],
        'FAULTS_FULL'
      ) && (
        <Menu.Item key='10' icon={<Icon name='fault' />}>
          <Link to='/faultGroup'>Usterki</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'FAULTS_FULL', 'ROLE_DEVELOPER') ||
        shouldShowMenu(['ROLE_WORKER'], 'FAULTS_FULL', 'ROLE_TENANT')) && (
        <Menu.Item key='10' icon={<Icon name='fault' />}>
          <Link to='/faultGroup'>Usterki</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_DEVELOPER', 'ROLE_WORKER', 'ROLE_CLIENT'],
        'TENANTS_CHANGES_FULL'
      ) && (
        <Menu.Item key='11' icon={<Icon name='flat-changes' />}>
          <Link to='/tenantChangeGroup'>Zmiany lokatorskie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER']) && (
        <Menu.Item key='9' icon={<Icon name='payments' />}>
          <Link to='/settlements'>Rozliczenia</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'PAYMENTS_FULL', 'ROLE_TENANT') ||
        shouldShowMenu(['ROLE_WORKER'], 'PAYMENTS_FULL', 'ROLE_DEVELOPER')) && (
        <Menu.Item key='9' icon={<Icon name='payments' />}>
          <Link to='/settlements'>Rozliczenia</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER'], 'RECEIPTS_FULL') && (
        <Menu.Item key='21' icon={<Icon name='dolar' />}>
          <Link to='/receipts'>Wpływy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_WORKER'], 'RECEIPTS_FULL', 'ROLE_DEVELOPER') && (
        <Menu.Item key='21' icon={<Icon name='dolar' />}>
          <Link to='/receipts'>Wpływy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_TENANT', 'ROLE_WORKER', 'ROLE_CLIENT_TENANT'],
        'PAYMENTS_FULL',
        'ROLE_TENANT'
      ) && (
        <Menu.Item key='8' icon={<Icon name='card' />}>
          <Link to='/payments'>Płatności</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_CLIENT']) && (
        <Menu.Item key='30' icon={<Icon name='card' />}>
          <Link to='/receivables'>Płatności</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT']) && (
        <Menu.Item key='14' icon={<Icon name='package' />}>
          <Link to='/packagePayments'>Płatności za pakiety</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_CLIENT_TENANT', 'ROLE_CLIENT']) && (
        <Menu.Item key='12' icon={<Icon name='file' />}>
          <Link to='/agreements'>Umowy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
        <Menu.Item key='2' icon={<Icon name='developer' />}>
          <Link to='/developers'>Deweloperzy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
        <Menu.Item key='3' icon={<Icon name='rental-managers' />}>
          <Link to='/tenants'>Zarządcy najmem</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_ADMIN']) && (
        <Menu.Item key='40' icon={<Icon name='administrator-nieruchomosci' />}>
          <Link to='/communityAdmins'>Administratorzy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN'], 'HOUSING_ASSOCIATION') && (
        <Menu.Item key='41' icon={<Icon name='spoldzielnia' />}>
          <Link to='/housingAssociations'>Spółdzielnie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN'], 'HOUSING_COMMUNITY') && (
        <Menu.Item key='42' icon={<Icon name='wspolnota' />}>
          <Link to='/housingCommunities'>Wspólnoty</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(
        ['ROLE_COMMUNITY_ADMIN'],
        'HOUSING_ASSOCIATION',
        null,
        null,
        true
      ) && (
        <Menu.Item key='43' icon={<Icon name='apartments' />}>
          <Link to='/housingPremises'>Lokale</Link>
        </Menu.Item>
      )) ||
        (shouldShowMenu(
          ['ROLE_COMMUNITY_ADMIN'],
          'HOUSING_COMMUNITY',
          null,
          null,
          true
        ) && (
          <Menu.Item key='43' icon={<Icon name='apartments' />}>
            <Link to='/housingPremises'>Lokale</Link>
          </Menu.Item>
        ))}
      {(shouldShowMenu(['ROLE_COMMUNITY_ADMIN'], 'HOUSING_ASSOCIATION') && (
        <Menu.Item key='44' icon={<Icon name='client' />}>
          <Link to='/members'>Członkowie</Link>
        </Menu.Item>
      )) ||
        (shouldShowMenu(['ROLE_COMMUNITY_ADMIN'], 'HOUSING_COMMUNITY') && (
          <Menu.Item key='44' icon={<Icon name='client' />}>
            <Link to='/members'>Członkowie</Link>
          </Menu.Item>
        ))}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN']) && (
        <Menu.Item key='45' icon={<Icon name='worker' />}>
          <Link to='/housingWorkers'>Pracownicy</Link>
        </Menu.Item>
      )}

      {shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER'],
        null,
        null,
        null,
        false,
        'HOUSING_ASSOCIATION'
      ) && (
        <Menu.Item key='46' icon={<Icon name='spoldzielnia' />}>
          <Link to='/memberAssociations'>Spółdzielnie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER'],
        null,
        null,
        null,
        false,
        'HOUSING_COMMUNITY'
      ) && (
        <Menu.Item key='47' icon={<Icon name='wspolnota' />}>
          <Link to='/memberCommunities'>Wspólnoty</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER'],
        null,
        null,
        null,
        false,
        'HOUSING_ASSOCIATION'
      ) && (
        <Menu.Item key='48' icon={<Icon name='apartments' />}>
          <Link to='/memberPremises'>Lokale</Link>
        </Menu.Item>
      )) ||
        (shouldShowMenu(
          ['ROLE_COMMUNITY_MEMBER'],
          null,
          null,
          null,
          false,
          'HOUSING_COMMUNITY'
        ) && (
          <Menu.Item key='48' icon={<Icon name='apartments' />}>
            <Link to='/memberPremises'>Lokale</Link>
          </Menu.Item>
        ))}
      {(shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER'],
        null,
        null,
        null,
        false,
        'HOUSING_ASSOCIATION'
      ) && (
        <Menu.Item key='49' icon={<Icon name='rental-managers' />}>
          <Link to='/housingTenants'>Najemcy</Link>
        </Menu.Item>
      )) ||
        (shouldShowMenu(
          ['ROLE_COMMUNITY_MEMBER'],
          null,
          null,
          null,
          false,
          'HOUSING_COMMUNITY'
        ) && (
          <Menu.Item key='49' icon={<Icon name='rental-managers' />}>
            <Link to='/housingTenants'>Najemcy</Link>
          </Menu.Item>
        ))}
      {shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER_TENANT'],
        null,
        null,
        null,
        false,
        false,
        'HOUSING_ASSOCIATION'
      ) && (
        <Menu.Item key='50' icon={<Icon name='spoldzielnia' />}>
          <Link to='/tenantAssociations'>Spółdzielnie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER_TENANT'],
        null,
        null,
        null,
        false,
        false,
        'HOUSING_COMMUNITY'
      ) && (
        <Menu.Item key='51' icon={<Icon name='wspolnota' />}>
          <Link to='/tenantCommunities'>Wspólnoty</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(
        ['ROLE_COMMUNITY_MEMBER_TENANT'],
        null,
        null,
        null,
        false,
        false,
        'HOUSING_ASSOCIATION'
      ) && (
        <Menu.Item key='52' icon={<Icon name='apartments' />}>
          <Link to='/tenantPremises'>Lokale</Link>
        </Menu.Item>
      )) ||
        (shouldShowMenu(
          ['ROLE_COMMUNITY_MEMBER_TENANT'],
          null,
          null,
          null,
          false,
          false,
          'HOUSING_COMMUNITY'
        ) && (
          <Menu.Item key='52' icon={<Icon name='apartments' />}>
            <Link to='/tenantPremises'>Lokale</Link>
          </Menu.Item>
        ))}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'INVESTMENT_READ',
        'ROLE_COMMUNITY_ADMIN',
        ['housingAssociation', 'housingAssociationIds']
      ) && (
        <Menu.Item key='41' icon={<Icon name='spoldzielnia' />}>
          <Link to='/housingAssociations'>Spółdzielnie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'INVESTMENT_READ',
        'ROLE_COMMUNITY_ADMIN',
        ['housingCommunity', 'housingCommunityIds']
      ) && (
        <Menu.Item key='42' icon={<Icon name='wspolnota' />}>
          <Link to='/housingCommunities'>Wspólnoty</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'INVESTMENT_READ',
        'ROLE_COMMUNITY_ADMIN',
        [
          'housingAssociation',
          'housingAssociationIds',
          'housingCommunity',
          'housingCommunityIds',
        ]
      ) && (
        <Menu.Item key='43' icon={<Icon name='apartments' />}>
          <Link to='/housingPremises'>Lokale</Link>
        </Menu.Item>
      )}

      {shouldShowMenu(
        ['ROLE_WORKER'],
        'CLIENTS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='44' icon={<Icon name='client' />}>
          <Link to='/members'>Członkowie</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'WORKERS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='45' icon={<Icon name='worker' />}>
          <Link to='/housingWorkers'>Pracownicy</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN', 'ROLE_COMMUNITY_MEMBER']) && (
        <Menu.Item key='57' icon={<Icon name='uchwaly' />}>
          <Link to='/resolutions'>Uchwały</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'RESOLUTIONS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='57' icon={<Icon name='uchwaly' />}>
          <Link to='/resolutions'>Uchwały</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN', 'ROLE_COMMUNITY_MEMBER']) && (
        <Menu.Item key='56' icon={<Icon name='fault' />}>
          <Link to='/housingFaultGroup'>Usterki</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'FAULTS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='56' icon={<Icon name='fault' />}>
          <Link to='/housingFaultGroup'>Usterki</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN']) && (
        <Menu.Item key='53' icon={<Icon name='payments' />}>
          <Link to='/housingSettlements'>Rozliczenia</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'PAYMENTS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='53' icon={<Icon name='payments' />}>
          <Link to='/housingSettlements'>Rozliczenia</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_MEMBER']) && (
        <Menu.Item key='54' icon={<Icon name='payments' />}>
          <Link to='/memberSettlements'>Rozliczenia</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_MEMBER_TENANT']) && (
        <Menu.Item key='55' icon={<Icon name='payments' />}>
          <Link to='/tenantSettlements'>Rozliczenia</Link>
        </Menu.Item>
      )}

      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN', 'ROLE_COMMUNITY_MEMBER']) && (
        <Menu.Item key='58' icon={<Icon name='file' />}>
          <Link to='/documents'>Dokumenty</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'DOCUMENTS_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='58' icon={<Icon name='file' />}>
          <Link to='/documents'>Dokumenty</Link>
        </Menu.Item>
      )}

      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN']) && (
        <Menu.Item key='59' icon={<Icon name='email' />}>
          <Link to='/messages'>Wiadomości</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(
        ['ROLE_WORKER'],
        'MESSAGES_FULL',
        'ROLE_COMMUNITY_ADMIN'
      ) && (
        <Menu.Item key='59' icon={<Icon name='email' />}>
          <Link to='/messages'>Wiadomości</Link>
        </Menu.Item>
      )}

      {shouldShowMenu(['ROLE_ADMIN']) && (
        <Menu.Item key='13' icon={<Icon name='flat-changes' />}>
          <Link to='/packages'>Pakiety</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT'], 'TAGS_FULL') && (
        <Menu.Item key='22' icon={<Icon name='flat-changes' />}>
          <Link to='/tags'>Tagi</Link>
        </Menu.Item>
      )}
      {shouldShowMenu(['ROLE_COMMUNITY_ADMIN']) && (
        <Menu.Item key='22' icon={<Icon name='flat-changes' />}>
          <Link to='/tags'>Tagi</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu(['ROLE_WORKER'], 'TAGS_FULL', 'ROLE_DEVELOPER') ||
        shouldShowMenu(['ROLE_WORKER'], 'TAGS_FULL', 'ROLE_TENANT') ||
        shouldShowMenu(
          ['ROLE_WORKER'],
          'TAGS_FULL',
          'ROLE_COMMUNITY_ADMIN'
        )) && (
        <Menu.Item key='22' icon={<Icon name='flat-changes' />}>
          <Link to='/tags'>Tagi</Link>
        </Menu.Item>
      )}
      {(shouldShowMenu([
        'ROLE_ADMIN',
        'ROLE_DEVELOPER',
        'ROLE_TENANT',
        'ROLE_CLIENT',
        'ROLE_CLIENT_TENANT',
      ]) ||
        shouldShowMenu(['ROLE_WORKER'], null, 'ROLE_TENANT') ||
        shouldShowMenu(['ROLE_WORKER'], null, 'ROLE_DEVELOPER')) && (
        <Menu.Item key='98' className='help' icon={<Icon name='samouczek' />}>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://manage.com.pl/samouczek.pdf'
          >
            Samouczek
          </a>
        </Menu.Item>
      )}

      <Menu.Item
        key='99'
        className='help'
        icon={<Icon name='help' />}
        style={{ marginTop: 0 }}
      >
        <a href='mailto:support@manage.com.pl'>Zgłoś problem</a>
      </Menu.Item>
      {/* <Menu.Item
        key='100'
        className='help'
        icon={<Icon name='email' />}
        onClick={() => toggleChat()}
        style={{ marginTop: 0 }}
      >
        Czat
      </Menu.Item> */}
      {shouldShowMenu(['ROLE_CLIENT_TENANT', 'ROLE_CLIENT']) && (
        <Menu.Item
          key='101'
          className='help'
          icon={<Icon name='rodo' />}
          style={{ marginTop: 0 }}
        >
          <Link to='/rodo'>Rodo</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default LoggedMenu;
