import httpService from '../helpers/httpService';

const premisesImportsService = {};

premisesImportsService.getImports = async (investmentId) =>
  httpService.get(`/api/vendor/investment/${investmentId}/import`, null, true);

premisesImportsService.import = async (investmentId, data, config = {}) =>
  httpService.post(
    `/api/vendor/investment/${investmentId}/import`,
    data,
    true,
    {
      'content-type': 'multipart/form-data',
    },
    config
  );

export default premisesImportsService;
