import BaseService from './baseService';
import httpService from '../helpers/httpService';

class StatsService extends BaseService {
  constructor() {
    super();
    this.url = '/api/vendor/investment/statistics';
  }

  async getPremises(id = null, customParams = {}) {
    let params = {
      ...customParams,
    };
    if (id) {
      params = {
        investmentId: id,
        ...customParams,
      };
    }
    return httpService.get(`${this.url}/premises`, params, true);
  }

  async getTranche(id = null, customParams = {}) {
    let params = {
      ...customParams,
    };
    if (id) {
      params = {
        investmentId: id,
        ...customParams,
      };
    }
    return httpService.get(`${this.url}/tranche`, params, true);
  }

  async getTrancheGrowth(id = null, customParams = {}) {
    let params = {
      ...customParams,
    };
    if (id) {
      params = {
        investmentId: id,
        ...customParams,
      };
    }
    return httpService.get(`${this.url}/tranche/growth`, params, true);
  }

  async getPayment(id = null, customParams = {}) {
    let params = {
      ...customParams,
    };
    if (id) {
      params = {
        investmentId: id,
        ...customParams,
      };
    }
    return httpService.get(`${this.url}/payment`, params, true);
  }

  async getPaymentGrowth(id = null, customParams = {}) {
    let params = {
      ...customParams,
    };
    if (id) {
      params = {
        investmentId: id,
        ...customParams,
      };
    }
    return httpService.get(`${this.url}/payment/growth`, params, true);
  }
}

export default StatsService;
