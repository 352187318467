import BaseService from './baseService';
import httpService from '../helpers/httpService';

class CommunityAdminDashboardService extends BaseService {
  constructor() {
    super();
    this.url = '/api/community/admin/dashboard';
  }

  async getDashboardData() {
    return httpService.get(`${this.url}`, null, true);
  }
}

export default CommunityAdminDashboardService;
