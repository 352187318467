import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';

const InfoBox = ({ premise }) => (
  <div className="premises-show-box info-box">
    <h2>
      <Icon name="more-info" />
      Informacje
    </h2>
    <div className="content">
      <Row className="entry">
        <Col xs={{ span: 8, offset: 1 }} className="label">Powierzchnia:</Col>
        <Col xs={10} className="value">
          {premise.totalSurface}
          {' '}
          m
          <sup>2</sup>
        </Col>
      </Row>
      <Row className="entry">
        <Col xs={{ span: 8, offset: 1 }} className="label">Liczba pokoi:</Col>
        <Col xs={10} className="value">{premise.roomsCount}</Col>
      </Row>
      <Row className="entry">
        <Col xs={{ span: 8, offset: 1 }} className="label">Piętro:</Col>
        <Col xs={10} className="value">{premise.floor}</Col>
      </Row>
      {premise.type !== 'STORAGE' && premise.type !== 'PARKING' && (
        <Row className="entry">
          <Col xs={{ span: 8, offset: 1 }} className="label">Strona świata:</Col>
          <Col xs={10} className="value">{premise.geographicDirections && premise.geographicDirections.join(', ')}</Col>
        </Row>
      )}
      <Row className="entry" style={{ marginTop: '20px' }}>
        <Col xs={{ span: 8, offset: 1 }} className="label">Adres:</Col>
        <Col xs={10} className="value">
          <div>
            {premise.address.street}
            {' '}
            {premise.address.number}
            {premise.address.local ? `/${premise.address.local}` : ''}
          </div>
          <div>
            {premise.address.zip}
            {' '}
            {premise.address.city}
          </div>
        </Col>
      </Row>
      {premise.comment && (
        <Row className="entry">
          <Col xs={{ span: 8, offset: 1 }} className="label">Notatka:</Col>
          <Col xs={10} className="value" style={{ whiteSpace: 'pre-wrap' }}>{premise.comment}</Col>
        </Row>
      )}
    </div>
  </div>
);

export default InfoBox;
