import BaseService from './baseService';
import httpService from '../helpers/httpService';

class SettlementTemplatesService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/community/investment/${investmentId}/settlementTemplates`;
  }

  async update(data) {
    return httpService.put(`${this.url}`, data, true);
  }

  async delete() {
    return httpService.delete(`${this.url}`, null, true);
  }
}

export default SettlementTemplatesService;
