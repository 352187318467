import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card, message, Modal } from 'antd';
import messagesService from '../../services/messagesService';
import housingAssociationsService from '../../services/housingAssociationsService';
import premisesService from '../../services/premisesService';
import ClientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import getPremiseType from '../../helpers/premiseTypeMap';
import moment from 'moment';
import MessagePopup from '../../components/MessagePopup';

const { confirm } = Modal;

const getSendingMethod = (method) => {
  if (method === 'NOTIFICATION_AND_EMAIL') return 'Przez system i e-mail';
  else if (method === 'SMS') return 'SMS';
};

const Show = () => {
  const { investmentId, messageId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [investmentData, setInvestmentData] = useState({});
  const [messageData, setMessageData] = useState({});
  const [receivers, setReceivers] = useState([]);

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.getOne(investmentId).then((data) => {
      setInvestmentData(data);
    });

    const MessagesService = new messagesService(investmentId);

    MessagesService.getOne(messageId).then((data) => {
      setMessageData(data);

      if (data.sendToType === 'PREMISES' || data.sendToType === 'FLOOR') {
        const PremisesService = new premisesService(investmentId);

        PremisesService.getList({ ids: data.sendToIds }).then((response) => {
          setReceivers(response?.content);
        });
      } else if (data.sendToType === 'MEMBERS') {
        ClientsService.getList({ ids: data.sendToIds }).then((response) => {
          setReceivers(response?.content);
        });
      }

      setLoading(false);
    });
  }, [investmentId, messageId]);

  const handleSendNow = (record) => {
    confirm({
      title: 'Ta wiadomość jest zaplanowana!',
      content:
        'Czy na pewno chcesz wysłać wiadomość przed zaplanowanym terminem?',
      okText: 'Wyślij teraz',
      okType: 'primary',
      cancelText: 'Nie wysyłaj',
      icon: false,
      width: 450,
      className: 'start-resolution-modal',
      cancelButtonProps: {
        className: 'ant-btn-dangerous',
        size: 'large',
      },
      okButtonProps: { size: 'large' },
      onOk() {
        const MessagesService = new messagesService(record.investmentId);
        MessagesService.updateFields(record.id, {
          scheduledSendDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        }).then(() => {
          datagridRef.current.refresh();
        });
      },
    });
  };

  const sendFromTemplateHandler = useCallback(() => {
    confirm({
      title: 'Tworzenie wiadomości z szablonu!',
      content:
        'Czy na pewno chcesz utworzyć wiadomość korzystając z tego szablonu?',
      okText: 'Utwórz',
      okType: 'primary',
      cancelText: 'Anuluj',
      icon: false,
      width: 400,
      className: 'start-resolution-modal',
      cancelButtonProps: {
        className: 'ant-btn-dangerous',
        size: 'large',
      },
      okButtonProps: { size: 'large' },
      onOk() {
        const tempValues = {
          content: messageData?.content,
          sendToIds: messageData?.sendToIds,
          sendToType: messageData.sendToType,
          sendingMethod: messageData.sendingMethod,
          title: messageData.title,
          isTemplate: false,
          createMessage: true,
          templateName: messageData?.templateName,
          templateId: messageData?.id,
          scheduledSendDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        };

        const MessagesService = new messagesService(investmentId);
        MessagesService.create(tempValues)
          .then((response) => {
            message.success(
              <MessagePopup type='success'>
                Wiadomość została utworzona
              </MessagePopup>
            );
            history.replace(`/messages?investmentId=${investmentId}`);
          })
          .catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
              );
            }
          });
      },
    });
  }, [messageData, messageId, investmentId]);

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.goBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title={'Wróć'}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            (messageData?.isTemplate || messageData?.status === 'AWAITING') && (
              <Button
                key='2'
                type='primary counters-edit-btn'
                onClick={() =>
                  history.replace(
                    `/investment/${investmentId}/messages/${messageId}/edit`
                  )
                }
              >
                Edytuj <Icon name='edit' />
              </Button>
            ),
            messageData?.isTemplate && (
              <Button key='2' type='primary' onClick={sendFromTemplateHandler}>
                Użyj szablonu
                <Icon name='template' style={{ color: '#ffffff' }} />
              </Button>
            ),
          ]}
        >
          {/* <Card> */}
          <Row>
            <Col xs={0} xl={2} xxl={4} />
            <Col xs={24} xl={20} xxl={16}>
              <div className='right'>
                <Card
                  title={
                    <h2 className='show-layout-card-header'>
                      <Icon name='send' />
                      Wysyłka
                    </h2>
                  }
                  className='informations'
                >
                  {!messageData.isTemplate && (
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} md={6}>
                        <div className='title'>Data wysyłki:</div>
                      </Col>
                      <Col xs={24} md={18}>
                        <Row>
                          {messageData?.sentDate ? (
                            messageData?.sentDate
                              ?.replace('T', ', ')
                              .slice(0, -4)
                          ) : (
                            <>
                              <Col style={{ marginRight: 10 }}>
                                {messageData?.scheduledSendDate?.replace(
                                  'T',
                                  ', '
                                )}
                              </Col>
                              <Col>
                                <span
                                  className='message-scheduled-info'
                                  style={{}}
                                >
                                  <Icon
                                    className='danger-icon'
                                    name='important'
                                    size='large'
                                    style={{
                                      fontSize: 16,
                                      marginRight: 5,
                                    }}
                                  />
                                  Zaplanowano wysłanie
                                </span>
                              </Col>
                            </>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  )}
                  <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24} md={6}>
                      <div className='title'>Sposób wysłania:</div>
                    </Col>
                    <Col xs={24} md={18}>
                      <div>{getSendingMethod(messageData.sendingMethod)}</div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24} md={6}>
                      <div className='title'>Szablon:</div>
                    </Col>
                    <Col xs={24} md={18}>
                      <div>{messageData.title}</div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24} md={6}>
                      <div className='title'>Odbiorcy:</div>
                    </Col>
                    <Col xs={24} md={18}>
                      <div>
                        {receivers.map((receiver, idx) => {
                          if (
                            messageData?.sendToType === 'PREMISES' ||
                            messageData?.sendToType === 'FLOOR'
                          ) {
                            return (
                              <Link
                                to={
                                  investmentData.type === 'HOUSING_ASSOCIATION'
                                    ? `/housingAssociations/${investmentId}/premises/${receiver.id}/show`
                                    : `/housingCommunities/${investmentId}/premises/${receiver.id}/show`
                                }
                                style={{
                                  color: '#3B4C60',
                                  textDecoration: 'underline',
                                }}
                              >
                                {idx !== 0 && ', '}
                                {[getPremiseType(receiver.type), receiver.name]
                                  .filter((el) => !!el)
                                  .join(' ')}
                              </Link>
                            );
                          } else if (messageData?.sendToType === 'MEMBERS') {
                            return (
                              <Link
                                to={`/investment/${investmentId}/members/${receiver.id}/show`}
                                style={{
                                  color: '#3B4C60',
                                  textDecoration: 'underline',
                                }}
                              >
                                {idx !== 0 && ', '}
                                {[receiver.firstName, receiver.lastName]
                                  .filter((el) => !!el)
                                  .join(' ')}
                              </Link>
                            );
                          }
                        })}
                        {messageData?.sendToType === 'ALL' && (
                          <span style={{ textDecoration: 'underline' }}>
                            Wszyscy
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className='right' style={{ marginTop: 20 }}>
                <Card
                  title={
                    <h2 className='show-layout-card-header'>
                      <Icon name='email' />
                      Wiadomość
                    </h2>
                  }
                  className='informations'
                >
                  <Row style={{ marginBottom: '20px' }}>
                    <Col xs={24}>
                      <div
                        className='content react-draft-wysiwyg-resolution-details'
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                        dangerouslySetInnerHTML={{
                          __html: messageData?.content,
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col xs={0} xl={2} xxl={4} />
          </Row>
          {/* </Card> */}
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
