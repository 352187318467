import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import premisesService from '../../../services/premisesService';
import { Spin } from '../../../components';
import RentPremiseEdit from './rent/Edit';
import ServicePremiseEdit from './service/Edit';
import ParkingPremiseEdit from './parking/Edit';
import StoragePremiseEdit from './storage/Edit';

const Edit = () => {
  const { id, premisesId } = useParams();
  const [loading, setLoading] = useState(true);
  const [premise, setPremise] = useState({});

  useEffect(() => {
    const PremisesService = new premisesService(id);
    PremisesService.getOne(premisesId).then((data) => {
      setPremise(data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className='show-layout investments-show'>
        <div className='loading'>
          <Spin />
        </div>
      </div>
    );
  } else {
    if (premise.type === 'RENT') {
      return <RentPremiseEdit />;
    } else if (premise.type === 'SERVICE') {
      return <ServicePremiseEdit />;
    } else if (premise.type === 'PARKING') {
      return <ParkingPremiseEdit />;
    } else if (premise.type === 'STORAGE') {
      return <StoragePremiseEdit />;
    }

    return <></>;
  }
};

export default Edit;
