import React, { useEffect, useState } from 'react';
import { PageHeader, Button } from 'antd';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Icon, Datagrid } from '../../components';
import investmentsService from '../../services/investmentsService';
import documentsListService from '../../services/documentsListService';
import documentsService from '../../services/documentsService';
import { ChoosenAllSwitch } from './components/ChoosenAllSwitch';

import Spin from '../../components/Spin';
import MemberAssociationsService from '../../services/memberAssociationsService';

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = ({ userData }) => {
  const history = useHistory();
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState();
  const [showAll, setShowAll] = useState(false);
  const [id, setId] = useState();
  const [investments, setInvestments] = useState([]);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'filename',
      visible: true,
      filterEnabled: true,
      filterFullWidth: !showAll,
      key: 'filename',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    ...(showAll
      ? [
          {
            title: 'Nieruchomość',
            dataIndex: 'investmentId',
            visible: false,
            key: 'investmentId',
            sorter: false,
            filterEnabled: true,
            filterType: 'select',
            filterOptions: investments.map((investment) => ({
              value: investment.id,
              label: investment.name,
            })),
            export: false,
          },
        ]
      : []),
    {
      title: 'Data dodania',
      dataIndex: 'addDate',
      visible: true,
      filterEnabled: false,
      key: 'addDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data dodania od',
      dataIndex: 'addDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'addDateFrom',
      export: false,
    },
    {
      title: 'Data dodania do',
      dataIndex: 'addDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'addDateTo',
      export: false,
    },
    ...(showAll
      ? [
          {
            title: 'Nieruchomość',
            dataIndex: 'investmentName',
            visible: true,
            filterEnabled: false,
            key: 'investmentName',
            export: true,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      let InvestmentsService = new investmentsService();

      InvestmentsService.getList().then((data) => {
        setInvestments(data.content);
      });
    }

    if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
      const InvestmentsService = new MemberAssociationsService();

      InvestmentsService.getList({
        types: ['HOUSING_ASSOCIATION', 'HOUSING_COMMUNITY'],
      }).then((data) => {
        setInvestments(data.content);
      });
    }
  }, [userData]);

  useEffect(() => {
    if (id) {
      if (
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        const InvestmentsService = new investmentsService();
        InvestmentsService.getDetails(id).then((data) => {
          setInvestment(data.investment);
        });
      } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        const InvestmentsService = new MemberAssociationsService();
        InvestmentsService.getDetails(id).then((data) => {
          setInvestment(data);
        });
      }
    }
    setLoading(false);
  }, [id, userData]);

  useEffect(() => {
    const investmentId = query.get('investmentId') || null;

    if (investmentId) {
      setId(investmentId);
    } else {
      const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
      if (choosenInvestmentId) {
        setId(JSON.parse(choosenInvestmentId));
      }
    }
  }, []);

  const handleDownload = (record) => {
    const DocumentsService = new documentsService(record.investmentId);

    DocumentsService.getOne(record.id)
      .then((data) => {
        const a = document.createElement('a');
        a.href = `data:application/octet-stream;base64,${data.content}`;
        a.download = data.filename;
        a.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CustomCreate = () => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type='primary'
            onClick={() => {
              if (showAll) {
                return history.push(`/documents/create`);
              }
              return history.push(`/documents/create?investmentId=${id}`);
            }}
          >
            Dodaj <Icon name='plus' style={{ marginLeft: 10 }} />
          </Button>
        </div>
      );
    }

    return null;
  };

  const HeaderTitle = () => {
    if (!investment?.name || showAll) {
      return <span>Dokumenty</span>;
    }
    return (
      <span>
        Dokumenty: <span className='header-subtitle'>{investment?.name}</span>
      </span>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title={
              query.get('investmentId') ? (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  backIcon={<Icon name='arrow-simply-left' />}
                  onBack={() => window.history.back()}
                  title={<HeaderTitle />}
                />
              ) : (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  title={<HeaderTitle />}
                />
              )
            }
            exportTitle={'Dokumenty'}
            resource={'documents'}
            customBaseUrlKey={'documents'}
            dataProvider={new documentsListService()}
            columns={columns}
            filters={{
              ...(!showAll && { investmentId: id }),
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            showAlert={showAlert}
            customRowActions={[
              ...(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
                ? [
                    (record) => (
                      <Link
                        to={`/investment/${record.investmentId}/documents/${record.id}/edit`}
                        key='1'
                      >
                        <Button className='button-no-border'>
                          <Icon name='edit' />
                        </Button>
                      </Link>
                    ),
                  ]
                : []),
              (record) => (
                <Button
                  className='button-show'
                  onClick={() => handleDownload(record)}
                >
                  <Icon name='download' />
                </Button>
              ),
            ]}
            customCreate={<CustomCreate />}
            customShowAllMembers={
              <ChoosenAllSwitch showAll={showAll} setShowAll={setShowAll} />
            }
            hideEdit
            hideShow
            hideReset
            hideSms
            hideEmail
            hideDelete={
              !(
                userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
              )
            }
            showDownload={true}
          />
        </div>
      )}
    </>
  );
};

export default List;
