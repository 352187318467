import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import CommunityAdminUserService from '../../services/communityAdminsService';
import PackageService from '../../services/packagesService';

const SmsLimitModal = ({
  isModalVisible,
  setShowModal,
  packageData,
  setPackageData,
  setInputedPackageData,
}) => {
  const changeLimit = () => {
    CommunityAdminUserService.changeSmsLimit(packageData.id)
      .then(() => {
        PackageService.getOne(packageData.id).then((data) => {
          setPackageData((prevData) => ({
            ...prevData,
            limits: { ...prevData.limits, sms: data?.limits?.sms },
          }));
          setInputedPackageData((prevData) => ({
            ...prevData,
            limits: { ...prevData.limits, sms: data?.limits?.sms },
          }));
        });
        onReset();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onReset = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        title={null}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={450}
        footer={null}
      >
        <Row gutter={20}>
          <Col xs={24}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: '#3B4C60',
                textAlign: 'center',
                marginTop: 40,
              }}
            >
              Chcesz zwiększyć limit wysyłki SMS o dodatkowe 500 w tym miesiącu?
            </div>
          </Col>

          <Col xs={24}>
            <div
              style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
              }}
            >
              <Button
                type='primary'
                style={{
                  backgroundColor: '#EC434C1A',
                  border: 0,
                  color: '#EC434C',
                  fontWeight: 700,
                }}
                onClick={() => onReset()}
              >
                Nie zwiększaj
              </Button>
              <Button
                type='primary'
                onClick={() => changeLimit()}
                style={{ fontWeight: 700 }}
              >
                Tak, zwiększ limit
              </Button>
            </div>
          </Col>
          <Col
            xs={24}
            style={{
              fontSize: 10,
              fontWeight: 400,
              color: '#B1CADE',
              textAlign: 'center',
              marginTop: 60,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div>
              * Twój podstawowy pakiet wysyłki SMS na miesiąc odnowi się
              pierwszego dnia kolejnego miesiąca.
            </div>
            <div>
              ** Zwiększenie limitu o dodatkowe 500 SMS na miesiąc (koszt 100 zł
              netto) wiąże się z dodatkowymi opłatami, które zostaną
              uwzględnione w Twojej następnej fakturze.
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SmsLimitModal;
