/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col, Tooltip, Modal } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import resolutionsService from '../../services/resolutionsService';
import userService from '../../services/userService';
import housingAssociationsService from '../../services/housingAssociationsService';
import memberAssociationsService from '../../services/memberAssociationsService';
import faultsStatusMap from '../../helpers/faultsStatusMap';
import { ImagesBox } from './showComponents/ImagesBox';
import { DescriptionBox } from './showComponents/DescriptionBox';
import { InfoBox } from './showComponents/InfoBox';
import { AttachmentsBox } from './showComponents/AttachmentsBox';
import { Icon, Spin } from '../../components';
import VoteModal from './showComponents/VoteModal';
import { ResultsBox } from './showComponents/ResultsBox';

const { confirm } = Modal;

const Dot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'STARTED') {
    color = 'green';
  }

  if (status === 'AWAITING') {
    color = 'yellow';
  }

  if (status === 'FINISHED') {
    color = 'grey';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const Show = () => {
  const { investmentId, resolutionId } = useParams();
  const history = useHistory();
  const [resolution, setResolution] = useState({});
  const [userData, setUserData] = useState({});
  const [investmentData, setInvestmentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showVoteModal, setShowVoteModal] = useState(false);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);

      const ResolutionsService = new resolutionsService(investmentId);
      ResolutionsService.getOne(resolutionId).then((resolutionData) => {
        setResolution(resolutionData);

        if (resolutionData?.investmentId) {
          if (
            data?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
            (data?.roles?.includes('ROLE_WORKER') &&
              data?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
          ) {
            const HousingAssociationsService = new housingAssociationsService();

            HousingAssociationsService.getOne(resolutionData.investmentId).then(
              (response) => {
                setInvestmentData(response);
              }
            );
          } else if (data?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
            const MemberAssociationsService = new memberAssociationsService();

            MemberAssociationsService.getOne(resolutionData.investmentId).then(
              (response) => {
                setInvestmentData(response);
              }
            );
          }
        }

        setLoading(false);
      });
    });
  }, [investmentId, resolutionId]);

  const startResolution = () => {
    confirm({
      title: 'Czy na pewno chcesz rozpocząć głosowanie wcześniej?',
      content:
        'Planowane rozpoczęcie głosowania wyznaczone jest na inny termin.',
      okText: 'Tak, rozpocznij teraz',
      okType: 'primary',
      cancelText: 'Anuluj',
      icon: false,
      width: 450,
      className: 'start-resolution-modal',
      cancelButtonProps: {
        className: 'button-secondary',
        size: 'large',
      },
      okButtonProps: { size: 'large' },
      onOk() {
        const ResolutionsService = new resolutionsService(investmentId);
        ResolutionsService.changeStatus(resolutionId, 'STARTED').then(
          (resolutionData) => {
            ResolutionsService.getOne(resolutionData.id).then((response) => {
              setResolution(response);
            });
          }
        );
      },
    });
  };

  const endResolution = () => {
    confirm({
      title: 'Czy na pewno chcesz zakończyć głosowanie wcześniej?',
      content:
        'Planowane zakończenie głosowania wyznaczone jest na inny termin.',
      okText: 'Tak, zakończ teraz',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 450,
      className: 'end-resolution-modal',
      cancelButtonProps: {
        className: 'button-secondary',
        size: 'large',
      },
      okButtonProps: { size: 'large' },
      onOk() {
        const ResolutionsService = new resolutionsService(investmentId);
        ResolutionsService.changeStatus(resolutionId, 'FINISHED').then(
          (resolutionData) => {
            ResolutionsService.getOne(resolutionData.id).then((response) => {
              setResolution(response);
            });
          }
        );
      },
    });
  };

  const vote = () => {
    setShowVoteModal(true);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className='show-layout faults-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Wróć'
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            ...(resolution && [
              (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
                resolution?.status === 'AWAITING' && (
                  <>
                    <Link
                      key='2'
                      to={`/investment/${investmentId}/resolutions/${resolutionId}/edit`}
                    >
                      <Button type='primary' className='counters-edit-btn'>
                        Edytuj <Icon name='edit' />
                      </Button>
                    </Link>
                    <Button type='primary' onClick={() => startResolution()}>
                      Rozpocznij głosowanie
                      <Icon name='vote' />
                    </Button>
                  </>
                ),
              (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
                resolution?.status === 'STARTED' && (
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#ec434c', border: 0 }}
                    onClick={() => endResolution()}
                  >
                    Zakończ głosowanie <Icon name='vote' />
                  </Button>
                ),
              resolution?.status === 'FINISHED' &&
                (resolution?.votes?.winningType ? (
                  <Button
                    className='button-secondary'
                    style={{
                      backgroundColor: '#ececf7',
                      borderColor: '#ececf7',
                      cursor: 'not-allowed',
                    }}
                  >
                    <span style={{ opacity: 0.3 }}>
                      Zakończono <Icon name='vote' />
                    </span>
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: '#EC434C',
                      backgroundColor: '#FDECED',
                      height: 48,
                      border: 0,
                      fontWeight: 700,
                      cursor: 'not-allowed',
                    }}
                  >
                    <span>Głosowanie nieważne</span>
                    <Icon name='vote' />
                  </Button>
                )),
              userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') &&
                resolution?.status === 'STARTED' &&
                !resolution?.vote && (
                  <Button
                    type='primary'
                    style={{ backgroundColor: '#ec434c', border: 0 }}
                    onClick={() => vote()}
                  >
                    Oddaj głos <Icon name='vote' />
                  </Button>
                ),
            ]),
          ]}
        >
          <VoteModal
            isModalVisible={showVoteModal}
            setShowModal={setShowVoteModal}
            resolution={resolution}
            setResolution={setResolution}
          />
          <div className='premises-show'>
            <div className='premises-show-header'>
              <Row justify='space-between'>
                <Col sm={24}>
                  <h1>
                    {resolution?.name}
                    <Dot {...resolution} />
                  </h1>
                </Col>
              </Row>
            </div>
            <div className='premises-show-slider'>
              <ImagesBox />
            </div>
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <InfoBox
                  resolution={resolution}
                  investmentData={investmentData}
                />
                <AttachmentsBox />
              </Col>
              <Col xs={24} lg={12}>
                <ResultsBox resolution={resolution} userData={userData} />
              </Col>
              <Col xs={24}>
                <DescriptionBox resolution={resolution} />
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
