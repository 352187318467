import httpService from '../helpers/httpService';

const massCountersService = {};

massCountersService.getMassActionsCounters = async (
  investmentId,
  premisesIds = []
) =>
  httpService.get(
    `/api/vendor/investment/${investmentId}/massActions/counters`,
    { premisesIds: premisesIds },
    true
  );

export default massCountersService;
