import BaseService from './baseService';

class BrokersService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/broker`;
  }
}

export default BrokersService;
