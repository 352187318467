import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card, Menu, message } from 'antd';
import communityAdminsService from '../../services/communityAdminsService';
import packagesService from '../../services/packagesService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';
import Subscription from '../../components/Subscription';
import PackagePricesService from '../../services/packagePricesService';

import MessagePopup from '../../components/MessagePopup';

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [packageData, setPackageData] = useState();
  const [packagePrice, setPackagePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (customUserData) {
      sessionStorage.setItem(
        'packageExpireDate',
        customUserData.packageExpireDate
      );
      setUserData(customUserData);
      packagesService
        .getOne(customUserData.packageId)
        .then((data) => {
          setPackageData(data);
        })
        .then(() => {
          const PackagePriceService = new PackagePricesService(
            customUserData.packageId
          );
          PackagePriceService.getPackagePrices()
            .then((pricesResponse) => {
              const sortedResponse = pricesResponse.sort(
                (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
              );
              setPackagePrice(sortedResponse);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    } else {
      communityAdminsService.getOne(id).then((data) => {
        setUserData(data);

        if (data.packageId) {
          packagesService.getOne(data.packageId).then((data) => {
            setPackageData(data);
            setLoading(false);
          });
        }
      });
    }
  }, [id, customUserData]);

  const createPayment = (days) => {
    const PackagePriceService = new PackagePricesService(userData.packageId);
    const body = {
      days,
    };
    PackagePriceService.createPayment(body)
      .then((paymentResponse) => {
        if (paymentResponse.id) {
          sessionStorage.setItem('packageAmount', paymentResponse.amount);
          sessionStorage.setItem('packageDays', paymentResponse.packageDays);
          sessionStorage.setItem(
            'expirationDate',
            paymentResponse.expirationDate
          );
          history.push(
            `/payments/null/premises/null/agreement/null/payment/${paymentResponse.id}/show`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const menu =
    packagePrice.length === 0 ? null : (
      <Menu>
        {packagePrice.map((price) => (
          <Menu.Item onClick={() => createPayment(price.days)}>
            {`${price.days} dni - ${price.amount} zł`}
          </Menu.Item>
        ))}
      </Menu>
    );

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/communityAdmins')}
          backIcon={customTitle ? '' : <Icon name='arrow-simply-left' />}
          title={customTitle || 'Wróć'}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            <Link
              key='2'
              to={
                customTitle
                  ? '/myAccount/edit'
                  : `/communityAdmins/${userData.id}/edit`
              }
            >
              <Button type='primary'>
                Edytuj <Icon name='edit' />
              </Button>
            </Link>,
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className='left'>
                  <div className='logo'>
                    <img
                      src={`${baseUrl}${userData.avatar}`}
                      alt={userData.name}
                      style={{ borderRadius: '50%' }}
                    />
                  </div>
                  <div className='name'>{userData.nameDeveloper}</div>
                  <div className='buttons'>
                    {userData.mobile && (
                      <div className='call'>
                        <a href={`tel:${userData.mobile}`}>
                          <Button
                            type='primary'
                            size='large'
                            className='call-btn'
                          >
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>
                    )}
                    <div className='write'>
                      <a href={`mailto:${userData.email}`}>
                        <Button
                          type='primary'
                          size='large'
                          className='email-btn'
                        >
                          <Icon name='email' /> Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className='right'>
                  {packageData && (
                    <Subscription
                      packageData={packageData}
                      packageExpireDate={userData.packageExpireDate}
                      editUrl={
                        location.pathname !== '/myAccount'
                          ? `/communityAdmins/${id}/edit`
                          : '/myAccount/edit'
                      }
                      defaultPane={'packages'}
                      menu={menu}
                    />
                  )}
                  <Card
                    title={
                      <h2 className='show-layout-card-header'>
                        <Icon name='user' />
                        Informacje
                      </h2>
                    }
                    className='informations'
                  >
                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Dane firmowe:</div>
                        <div className='idNumber'>
                          <div>{userData.name}</div>
                          <div>NIP: {userData.nip}</div>
                        </div>
                      </Col>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Dane kontaktowe:</div>
                        <div className='email'>{userData.email}</div>
                        <div className='phone'>{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <Address
                          title='Adres zamieszkania'
                          address={userData.address}
                        />
                      </Col>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <Address
                          title='Adres korespondencyjny'
                          address={
                            userData.correspondenceAddress
                              ? userData.correspondenceAddress
                              : {}
                          }
                        />
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
