import BaseService from './baseService';

class MembersPremisesService extends BaseService {
  constructor(userId, investmentId) {
    super();
    this.url = `/api/vendor/user/${userId}/investment/${investmentId}/premises/community`;
  }
}

export default MembersPremisesService;
