import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageHeader } from 'antd';
import Icon from '../../components/Icon';
import memberTenantAssociationsService from '../../services/memberTenantAssociationsService';
import Spin from '../../components/Spin';

const Address = ({ address }) => (
  <>
    {!!address && (
      <div className='address'>
        {`ul. ${address.street} ${address.number || ''}${
          address.local ? `/${address.local}` : ','
        } ${address.zip} ${address.city}`}
      </div>
    )}
  </>
);

const Tile = ({ name, icon, url }) => (
  <div className='tile-wrapper'>
    <Link to={url}>
      <div className='tile-content'>
        <div className='tile-icon'>
          <Icon name={icon} />
        </div>
        <div className='tile-name'>{name}</div>
      </div>
    </Link>
  </div>
);

const Options = () => {
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();
    MemberTenantAssociationsService.getDetails(id).then((data) => {
      setInvestment(data);
      setLoading(false);
    });
  }, [id]);

  return (
    <div className='show-layout investments-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          // onBack={() => history.push('/tenantCommunities')}
          onBack={() => history.goBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title={'Wróć'}
          className='page-header-extra-2'
        >
          <div className='options-investment-name'>{investment.name}</div>
          <div className='options-investment-address'>
            <Address address={investment.address} />
          </div>

          <div className='tiles-wrapper'>
            <Tile
              name={'Informacje'}
              icon={'more-info'}
              url={`/tenantCommunities/${id}/show`}
            />
            <Tile
              name={'Lokale'}
              icon={'apartments'}
              url={`/tenantCommunities/${id}/premises`}
            />
            <Tile
              name={'Rozliczenia'}
              icon={'payments'}
              url={`/investment/${id}/tenantSettlements`}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Options;
