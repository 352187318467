import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Form, Col, Button, PageHeader, Tooltip,
} from 'antd';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import paymentsService from '../../services/paymentsService';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import paymentsStatusMap from '../../helpers/paymentsStatusMap';
import Spin from '../../components/Spin';

const { Select, DatePicker } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'yellow';
  const { status } = props;
  if (status === 'PAID') {
    color = 'green';
  }

  if (status === 'FAIL') {
    color = 'red';
  }

  return (
    <Tooltip title={paymentsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const getDescription = (description) => {
  if (!description) {
    return '';
  }

  if (description.length < 50) {
    return description;
  }

  return `${description.slice(0, 50)}...`;
};

const columns = [
  {
    title: 'Identyfikator płatności',
    dataIndex: 'id',
    visible: true,
    key: 'id',
    filterEnabled: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Identyfikator Tpay',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Data utworzenia',
    dataIndex: 'createdDate',
    visible: true,
    key: 'createdDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Termin płatności',
    dataIndex: 'expirationDate',
    visible: true,
    key: 'expirationDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => paymentsStatusMap(record.status),
  },
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => <Link className="datagrid-link" to={`investments/${record.investmentId}/premises/${record.premisesId}/show`}>{text}</Link>,
  },
  {
    title: 'Umowa',
    dataIndex: 'agreementName',
    visible: true,
    key: 'agreementName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Opis',
    dataIndex: 'description',
    visible: true,
    key: 'description',
    sorter: false,
    render: (text, record) => getDescription(record.description),
  },
  {
    title: 'Kwota',
    dataIndex: 'amount',
    visible: true,
    key: 'amount',
    sorter: false,
    summary: true,
    render: (text, record) => (<Price value={record.amount} showEmpty />),
  },
];

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [serviceType, setServiceType] = useState('');
  const [investment, setInvestment] = useState({});
  const filters = {
    investmentId: id,
    type: 'AGREEMENT',
  };

  useEffect(() => {
    userService.getInfo().then((data) => {
      let type = '/payment';
      const { roles } = data;

      if (roles.includes('ROLE_WORKER') || roles.includes('ROLE_TENANT')) {
        type = '/vendor/payment';
      }
      setServiceType(type);

      const InvestmentsService = new investmentsService();
      InvestmentsService.getOne(id).then((investmentData) => {
        setInvestment(investmentData);
        setLoading(false);
      });
    });
  }, [id]);

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="createDate">
      <Form.Item
        name="createDate"
      >
        <DatePicker size="large" placeholder="Data" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="NEW">
            <span className="dot yellow" />
            Nowa
          </Option>
          <Option value="PAID">
            <span className="dot green" />
            Opłacona
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  const hideEdit = false;
  const hideShow = true;
  const disableMassSelect = false;
  const CustomCreate = () => (
    <Link to="/payments/create">
      <Button type="primary">
        Nowa
        {' '}
        <Icon name="plus" />
      </Button>
    </Link>
  );

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Płatności</span>;
    }
    return (
      <span>
        Płatności:
        {' '}
        <span className="header-subtitle">{investment.name}</span>
      </span>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push(`/investments/${id}/show`)}
          backIcon={<Icon name="arrow-simply-left" />}
          title={<HeaderTitle />}
        >
          <Datagrid
            resource="payments"
            dataProvider={new paymentsService(null, null, null, serviceType)}
            columns={columns}
            hideEdit={hideEdit}
            hideCreate={hideEdit}
            hideShow={hideShow}
            customFilters={customFilters}
            filters={filters}
            customCreate={<CustomCreate />}
            disableSelection={disableMassSelect}
            customShowIcon="dolar"
          />
        </PageHeader>
      )}
    </div>
  );
};

export default List;
