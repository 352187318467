import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Col, Divider, Table, DatePicker, message } from 'antd';
import { Icon } from '../../../components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopiedPopup from '../../../components/CopiedPopup';
import HousingSettlementsService from '../../../services/housingSettlementsService';
import HousingAssociationsService from '../../../services/housingAssociationsService';
import moment from 'moment';

const Status = () => (
  <span>
    <span className={`dot ${'red'}`} style={{ marginRight: 8 }} />
    Do zapłaty
  </span>
);

const SettlementTemplate = ({ settlementData }) => {
  const { id } = useParams();
  const [priceSum, setPriceSum] = useState(0);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [settlementGap, setSettlementGap] = useState(1);
  const [templateData, setTemplateData] = useState({});
  const [investmentData, setInvestmentData] = useState({});
  const [pickedDate, setPickedDate] = useState(
    moment().set('date', settlementData?.settlementSendDay).format('YYYY-MM-DD')
  );

  useEffect(() => {
    const investmentService = new HousingAssociationsService();

    investmentService.getDetails(id).then((response) => {
      setInvestmentData(response?.investment);
    });
  }, [id]);

  useEffect(() => {
    HousingSettlementsService.getSettlementTemplateCosts(id, {
      createDate: pickedDate,
    }).then((data) => {
      setTemplateData(data);
    });
  }, [pickedDate]);

  useEffect(() => {
    if (templateData?.template?.settlementType === 'MONTH') {
      setSettlementGap(1);
    } else if (templateData?.template?.settlementType === 'TWO_MONTHS') {
      setSettlementGap(2);
    } else if (templateData?.template?.settlementType === 'QUARTER') {
      setSettlementGap(3);
    } else if (templateData?.template?.settlementType === 'HALF_YEAR') {
      setSettlementGap(6);
    } else if (templateData?.template?.settlementType === 'YEAR') {
      setSettlementGap(12);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateData?.templateCosts) {
      let tempSum = 0;
      for (let cost of templateData?.templateCosts) {
        tempSum += cost.price;
      }

      setPriceSum(tempSum);
    }
  }, [templateData]);

  const columns = [
    {
      title: 'Pozycja',
      dataIndex: 'name',
    },
    {
      title: 'Ilość',
      dataIndex: 'quantity',
      render: () => 1,
    },
    {
      title: 'Jednostka',
      dataIndex: 'unit',
      render: (text, record) => {
        if (text === 'm3') {
          return (
            <span>
              m<sup>3</sup>
            </span>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: '',
      dataIndex: 'multiply',
      render: () => 'x',
    },
    {
      title: 'Cena jednostkowa (brutto)',
      dataIndex: 'price',
      render: (text, record) =>
        text ? `${text.toFixed(2).replace('.', ',')} zł` : '',
    },
  ];

  const tempMonth = moment(pickedDate, 'YYYY-MM-DD').format('MMMM');

  return (
    <div className='settlement-template-wrapper'>
      <div className='settlement-template-header'>
        <div className='settlement-template-title'>
          <span className='title'>Wzór rozliczenia:</span>
          <span className='settlement-template-period'>
            {tempMonth?.charAt(0).toUpperCase() + tempMonth?.slice(1)}{' '}
            {moment(pickedDate, 'YYYY-MM-DD').format('YYYY')}
          </span>
          <span className='settlement-template-issue-date'>
            (wystawiono: {pickedDate})
          </span>
        </div>
        <div>
          <Button
            className='settlement-template-archives-btn'
            onClick={() => setDatePickerOpen(true)}
          >
            <span style={{ marginRight: 5 }}>Archiwum</span>
            <Icon name='calendar' />
          </Button>
          <DatePicker
            className='settlement-archives-datepicker'
            open={datePickerOpen}
            onOpenChange={setDatePickerOpen}
            picker={'month'}
            popupStyle={{ paddingTop: 20 }}
            popupClassName={'settlement-archives-datepicker-popup'}
            disabledDate={(current) => {
              return current.month() % settlementGap;
            }}
            onChange={(date) => {
              setPickedDate(
                date
                  .set('date', settlementData?.settlementSendDay)
                  .format('YYYY-MM-DD')
              );
            }}
          />
        </div>
      </div>

      <div className='settlement-info-wrapper'>
        <div className='settlement-info-title'>Nadawca</div>
        <Row>
          <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
            <div className='settlement-recipient-info'>
              Dane{' '}
              {investmentData.type === 'HOUSING_ASSOCIATION'
                ? 'Spółdzielni'
                : 'Wspólnoty'}
              :
            </div>
            <div className='settlement-recipient-value'>
              {templateData?.template?.companyName}{' '}
              <span className='settlement-recipient-value-copy'>
                <CopyToClipboard
                  text={templateData?.template?.companyName}
                  onCopy={() =>
                    message.success({
                      content: <CopiedPopup />,
                      duration: 1,
                      className: 'message-copied',
                    })
                  }
                >
                  <Icon name={'copy'} />
                </CopyToClipboard>
              </span>
            </div>
            <div className='settlement-recipient-value'>
              {`ul. ${templateData?.template?.address.street} ${
                templateData?.template?.address.number || ''
              }${
                templateData?.template?.address.local
                  ? `/${templateData?.template?.address.local}`
                  : ''
              }`}
            </div>
            <div className='settlement-recipient-value'>
              {`${templateData?.template?.address.zip} ${templateData?.template?.address.city}, ${templateData?.template?.address.country}`}
            </div>

            <div
              className='settlement-recipient-value'
              style={{ marginTop: 15 }}
            >
              NIP: {investmentData.nip ?? '-'}
            </div>
            <div className='settlement-recipient-value'>
              Regon: {investmentData.regon ?? '-'}
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <Row style={{ marginBottom: 25 }}>
              <Col>
                <div className='settlement-recipient-info'>Tytuł przelewu:</div>
                <div className='settlement-recipient-value'>
                  {templateData?.template?.bankTransferTitle}
                  <span className='settlement-recipient-value-copy'>
                    <CopyToClipboard
                      text={templateData?.template?.bankTransferTitle}
                      onCopy={() =>
                        message.success({
                          content: <CopiedPopup />,
                          duration: 1,
                          className: 'message-copied',
                        })
                      }
                    >
                      <Icon name={'copy'} />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='settlement-recipient-info'>
                  Rachunek do wpłat:
                </div>
                <div className='settlement-recipient-value'>
                  {templateData?.template?.bankNumber}
                  <span className='settlement-recipient-value-copy'>
                    <CopyToClipboard
                      text={templateData?.template?.bankNumber}
                      onCopy={() =>
                        message.success({
                          content: <CopiedPopup />,
                          duration: 1,
                          className: 'message-copied',
                        })
                      }
                    >
                      <Icon name={'copy'} />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className='settlement-info-wrapper'>
        <div className='settlement-info-title' style={{ marginTop: 25 }}>
          Płatność
        </div>
        <Row>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Razem do zapłaty:</div>
            <div
              style={{
                color: '#fff',
                fontSize: 24,
                fontWeight: 600,
                marginBottom: 30,
              }}
            >
              {priceSum.toFixed(2).replace('.', ',')} zł
            </div>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Zapłacono:</div>
            <div style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}>
              0,00 zł
            </div>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Pozostało do zapłaty:</div>
            <div style={{ color: '#EC434C', fontSize: 24, marginBottom: 30 }}>
              {priceSum.toFixed(2).replace('.', ',')} zł
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Termin płatności:</div>
            <div style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}>
              {moment(pickedDate, 'YYYY-MM-DD')
                .add(templateData?.template?.daysToPayment, 'days')
                .format('YYYY-MM-DD')}
            </div>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Data opłacenia:</div>
            <div style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}>
              -
            </div>
          </Col>
          <Col xs={24} md={12} lg={8} xl={6}>
            <div className='settlement-payment-info'>Status:</div>
            <div style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}>
              <Status />
            </div>
          </Col>
        </Row>
      </div>

      <Divider style={{ borderTop: '1px solid #B1CADE' }} />

      <div className='settlement-info-wrapper'>
        <div className='settlement-info-title'>Tabela kosztów</div>
        <Table
          className='settlement-costs-table'
          columns={columns}
          dataSource={templateData?.templateCosts}
          pagination={false}
        />
      </div>

      <div className='settlement-summary'>
        <span className='settlement-summary-label'>Razem:</span>
        <span className='settlement-summary-value'>
          {priceSum.toFixed(2).replace('.', ',')} zł
        </span>
      </div>

      <div className='settlement-annotation'>
        * Symulacja kosztów na jeden lokal dla jednego członka / lokal / 1 m
        <sup>2</sup>
      </div>
    </div>
  );
};

export default SettlementTemplate;
