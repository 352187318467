import React, { useEffect, useState } from 'react';
import {
  Col, Form, PageHeader, Tooltip,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';

import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';

import userTenantChangesService from '../../services/userTenantChangesService';
import investmentsService from '../../services/investmentsService';
import clientsService from '../../services/clientsService';
import tenantChangesStatusMap from '../../helpers/tenantChangesStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';

const { Select } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    key: 'name',
    filterEnabled: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: false,
    render: (text, record) => premiseTypeMap(record.premisesType),
    exportRender: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: false,
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => tenantChangesStatusMap(record.status),
  },
  {
    title: 'Dodano',
    dataIndex: 'addDate',
    visible: true,
    key: 'addDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Zmieniono',
    dataIndex: 'changeDate',
    visible: true,
    key: 'changeDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Termin płatności',
    dataIndex: 'paymentExpiryDate',
    visible: true,
    key: 'paymentExpiryDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Opłacono',
    dataIndex: 'paymentDate',
    visible: true,
    key: 'paymentDate',
    sorter: false,
    render: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
    exportRender: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
  },
  {
    title: 'Kwota',
    dataIndex: 'name',
    visible: true,
    key: 'name',
    sorter: false,
    render: (text, record) => (<Price value={record.amount} showEmpty />),
  },
];

const List = () => {
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    clientsService.getOne(id).then((data) => {
      setUserData(data);
      setLoading(false);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: 'SALE',
      pageSize: 1000,
    }).then((data) => {
      setInvestments(data.content);
    });
  }, [id]);

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="investmentId">
      <Form.Item
        name="investmentId"
      >
        <Select
          placeholder="Inwestycja"
          allowClear
          size="large"
        >
          {investmentsOptions}
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="premisesName">
      <Form.Item
        name="premisesName"
      >
        <Input size="large" placeholder="Numer" />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="ACTIVE">
            <span className="dot red" />
            Do zmiany
          </Option>
          <Option value="DONE">
            <span className="dot green" />
            Zmienione
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  const CustomCreate = () => ('');

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Zmiany lokatorskie</span>;
    }
    return (
      <span>
        Zmiany lokatorskie:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  const customBaseUrl = (record) => `tenantChangeGroup/${record.investmentId}/premises/${record.premisesId}/tenantChangeGroup/${record.id}`;

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/clients/${id}/show`)}
          title={<HeaderTitle />}
        >
          <div className="faults-list">
            <Datagrid
              resource="tenantChanges"
              dataProvider={new userTenantChangesService(id)}
              customCreate={<CustomCreate />}
              columns={columns}
              customFilters={customFilters}
              calculateBaseUrl={customBaseUrl}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
