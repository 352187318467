import React, { useState, useEffect } from 'react';
import {
  Row, Col, Tooltip, message,
} from 'antd';
import SmallList from '../../../../components/SmallList';
import Price from '../../../../components/Price';
import TranchesModal from '../modals/TranchesModal';
import tranchesService from '../../../../services/tranchesService';
import tranchesStatusMap from '../../../../helpers/tranchesStatusMap';

export const Tranches = (props) => {
  const { investmentId, premisesId } = props;
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [tranches, setTranches] = useState([]);

  const Dot = (args) => {
    const { status } = args;
    let color = 'yellow';
    if (status === 'PAID') {
      color = 'green';
    }

    if (status === 'TO_PAY') {
      color = 'red';
    }

    return (
      <Tooltip title={tranchesStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  useEffect(() => {
    const TranchesService = new tranchesService(investmentId, premisesId);
    TranchesService.getList().then((data) => {
      setTranches(data);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const TranchesService = new tranchesService(investmentId, premisesId);
    TranchesService.getList().then((data) => {
      setTranches(data);
    });
  };

  const columns = [
    {
      title: 'Numer transzy',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
    {
      title: 'Do zapłaty',
      dataIndex: 'paid',
      render: (text, record) => (<Price value={record.amount - record.paid} showEmpty />),
    },
  ];
  const addNewAction = (data) => {
    setModalData(data);
    setModal(true);
  };
  const afterSave = () => {
    setModal(false);
    setModalData({});
    refresh();
  };
  const afterCancel = () => {
    setModal(false);
    setModalData({});
  };

  const deleteAction = (entityId) => {
    const TranchesService = new tranchesService(props.investmentId, props.premisesId);
    TranchesService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  return (
    <div className="tranches create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList icon="payments" data={tranches} columns={columns} title="Transze spłat" addAction={addNewAction} deleteAction={deleteAction} />
        </Col>
      </Row>
      {modal && (
      <TranchesModal
        isModalVisible={modal}
        afterSave={afterSave}
        afterCancel={afterCancel}
        data={modalData}
      />
      )}
    </div>
  );
};
