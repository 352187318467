import React from 'react';
import Datagrid from '../../components/Datagrid';
import packagesService from '../../services/packagesService';
import Price from '../../components/Price';
import packageTypeMap from '../../helpers/packageTypeMap';
import packageStatusMap from '../../helpers/packageStatusMap';

const AvailableForUserRenderer = ({ value }) => {
  if (value) {
    return 'TAK';
  }

  return 'NIE';
};

const LimitsRenderer = ({ value }) => {
  if (value === -1) {
    return '∞';
  }

  return value;
};

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    filterEnabled: false,
    key: 'type',
    sorter: false,
    render: (text, record) => packageTypeMap(record.type),
    exportRender: (text, record) => packageTypeMap(record.type),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    filterEnabled: false,
    key: 'status',
    sorter: false,
    render: (text, record) => packageStatusMap(record.status),
    exportRender: (text, record) => packageStatusMap(record.status),
  },
  {
    title: 'Mieszkania i lokale usługowe (najem)',
    dataIndex: ['limits', 'premisesRent'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'premisesRent'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.premisesRent} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.premisesRent} />),
  },
  {
    title: 'Inwestycje',
    dataIndex: ['limits', 'investments'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'investment'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.investments} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.investments} />),
  },
  {
    title: 'Mieszkania',
    dataIndex: ['limits', 'premises'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'premises'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.premises} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.premises} />),
  },
  {
    title: 'Lokale',
    dataIndex: ['limits', 'service'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'service'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.parking} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.parking} />),
  },
  {
    title: 'Miejsca postojowe',
    dataIndex: ['limits', 'parking'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'parking'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.parking} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.parking} />),
  },
  {
    title: 'Komórki lokatorskie',
    dataIndex: ['limits', 'storage'],
    visible: true,
    filterEnabled: false,
    key: ['limits', 'storage'],
    sorter: false,
    render: (text, record) => (<LimitsRenderer value={record.limits.storage} />),
    exportRender: (text, record) => (<LimitsRenderer value={record.limits.storage} />),
  },
  {
    title: 'Dostępny dla użytkowników',
    dataIndex: 'availableForUser',
    visible: true,
    filterEnabled: false,
    key: 'availableForUser',
    sorter: false,
    render: (text, record) => (<AvailableForUserRenderer value={record.availableForUser} />),
    exportRender: (text, record) => (<AvailableForUserRenderer value={record.availableForUser} />),
  },
  {
    title: 'Cena',
    dataIndex: 'price',
    visible: true,
    filterEnabled: false,
    key: 'price',
    sorter: false,
    render: (text, record) => (<Price value={record.price} />),
  },
];

const List = () => (
  <div>
    <Datagrid
      title="Lista pakietów"
      resource="packages"
      dataProvider={packagesService}
      columns={columns}
    />
  </div>
);

export default List;
