import BaseService from './baseService';

class HousingTenantsService extends BaseService {
  constructor(url = '/api/community/user') {
    super();

    this.url = url;
  }
}

export default new HousingTenantsService();
