import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Card,
  Form,
  Typography,
  message,
  Menu,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { Input, Icon, Spin } from '../../components';
import userService from '../../services/userService';
import clientsService from '../../services/clientsService';
import packagesService from '../../services/packagesService';
import rodoService from '../../services/rodoService';
import PackagePricesService from '../../services/packagePricesService';
import Subscription from '../../components/Subscription';
import logocolor from '../../static/images/manage_logo_color.png';
import welcomeAdmin from '../../static/images/welcomeAdmin.png';
import { generateRandomPassword } from '../../helpers/passwordHelpers';
import MessagePopup from '../../components/MessagePopup';

const { Password2, Switch } = Input;
const { Text } = Typography;

const WelcomeStep = ({ nextStep }) => {
  return (
    <>
      <img
        src={welcomeAdmin}
        alt='welcomeAdmin-img'
        className='firstLogin-welcome-welcomeAdmin-img'
      />
      <img
        src={logocolor}
        alt='logo-img'
        className='firstLogin-welcome-logo-img'
      />
      <div className='firstLogin-welcome-content'>
        <h2 className='firstLogin-welcome-header'>Witaj w Manage!</h2>
        <div
          className='firstLogin-welcome-message'
          style={{ textAlign: 'justify' }}
        >
          Manage to platforma nastawiona na kreatywne zarządzanie
          nieruchomościami. Wierzymy w innowacyjne podejście do zarządzania oraz
          najnowsze technologie, które pomagają zaoszczędzić czas oraz
          sprawiają, że życie staje się łatwiejsze.
          <br />
          <br />
          Klikając "Kontynuuj" akceptujesz{' '}
          <a target='_blank' href='http://manage.com.pl/regulations'>
            regulamin
          </a>
        </div>
        {/* </div>
      <div className='firstLogin-welcome-button-wrapper'> */}
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button
            type='primary'
            size='large'
            style={{
              fontWeight: 'bold',
            }}
            onClick={() => nextStep()}
          >
            Kontynuuj
          </Button>
        </div>
      </div>
    </>
  );
};

const RodoStep = ({
  nextStep,
  prevStep,
  setUserData,
  rodoContent,
  rodoAccepted,
  setRodoAccepted,
}) => {
  const nextStepHandler = () => {
    if (rodoAccepted) {
      setUserData((currUserData) => ({
        ...currUserData,
        isRodoAccepted: rodoAccepted,
      }));
      nextStep();
    }
  };

  if (!rodoContent) {
    return null;
  }

  return (
    <>
      <div>
        <Form>
          <Card className='firstLogin-rodo-card'>
            <Row>
              <Col xs={24}>
                <div className='form-section'>
                  <h2 style={{ marginBottom: 20 }}>
                    Klauzula informacyjna RODO
                  </h2>
                  <div
                    style={{
                      marginBottom: 20,
                      color: '#587999',
                      fontSize: 14,
                    }}
                  >
                    {rodoContent}
                  </div>
                  <Form.Item
                    name='isAccepted'
                    label={
                      <div style={{ color: '#3B4C60', fontSize: 16 }}>
                        Akceptuję
                      </div>
                    }
                    valuePropName='checked'
                    className='firstLogin-rodo-accept'
                  >
                    <Switch
                      size='large'
                      onChange={(checked) => setRodoAccepted(checked)}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          className='button-secondary'
          size='large'
          style={{
            fontWeight: 'bold',
            marginLeft: 0,
            marginRight: 'auto',
          }}
          onClick={() => prevStep()}
        >
          Wstecz
        </Button>
        <Button
          type='primary'
          size='large'
          style={{
            fontWeight: 'bold',
            marginLeft: 'auto',
            marginRight: 0,
            color: rodoAccepted
              ? `rgb(255, 255, 255)`
              : `rgba(255, 255, 255, 0.5)`,
          }}
          onClick={() => nextStepHandler()}
        >
          Dalej
        </Button>
      </div>
    </>
  );
};

const ChangePasswordStep = ({
  nextStep,
  prevStep,
  passwordChanged,
  setPasswordChanged,
  userData,
  setUserData,
  last,
}) => {
  const [form] = Form.useForm();

  const changePassword = (values) => {
    const data = { ...userData, password: values.password };
    userService
      .update(data)
      .then((data) => {
        userService.getInfo().then((response) => {
          setUserData(response);

          message.success(
            <MessagePopup type='success'>Hasło zostało zmienione</MessagePopup>
          );
        });
      })
      .catch((err) => {
        console.log(err);

        message.error(
          <MessagePopup type='error' annotation={'Coś poszło nie tak...'}>
            Wystąpił nieznany błąd
          </MessagePopup>
        );
      });

    setPasswordChanged(true);
  };

  const nextStepHandler = () => {
    if (passwordChanged) {
      nextStep();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const fillPasswordsHandler = () => {
    const randomPassword = generateRandomPassword(8);

    form.setFieldsValue({
      password: randomPassword,
      confirm: randomPassword,
    });
  };

  return (
    <>
      <div>
        <Form
          form={form}
          onFinish={changePassword}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Card className='create-form-password-card firstLogin-form-card'>
            <Row>
              <Col xs={24} sm={{ span: 12, offset: 6 }}>
                <div className='form-section'>
                  <h2>Zmień hasło</h2>
                  <Form.Item
                    name='password'
                    rules={[
                      { message: 'Pole wymagane' },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            /\d/.test(value) &&
                            /[A-Z]/.test(value) &&
                            /[a-z]/.test(value) &&
                            value.length >= 8
                          ) {
                            return Promise.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Hasło nieprawidłowe');
                        },
                      }),
                    ]}
                  >
                    <Password2 size='large' placeholder='Hasło' />
                  </Form.Item>
                  <Form.Item
                    name='confirm'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        message: 'Potwierdź hasło',
                      },
                      {
                        min: 8,
                        message: 'Hasło musi posiadać conajmniej 8 znaków.',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject('Hasło nieprawidłowe');
                        },
                      }),
                    ]}
                  >
                    <Password2 size='large' placeholder='Powtórz hasło' />
                  </Form.Item>
                  <div
                    className='account-subscription-details-limits-addnotation-details'
                    style={{ color: '#C0D6E5', marginBottom: 24 }}
                  >
                    * Min. 8 znaków, wielka i mała litera, jedna cyfra
                  </div>
                  <Form.Item>
                    <Text
                      type='secondary'
                      className='generate-password'
                      underline
                      onClick={fillPasswordsHandler}
                    >
                      Generuj hasło
                    </Text>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className='firstLogin-form-button-send-password'
                      size='large'
                      htmlType='submit'
                    >
                      <span>Zapisz</span>
                      <Icon style={{ color: '#fff' }} name='access' />
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
      <div style={{ display: 'flex' }}>
        <Button
          className='button-secondary'
          size='large'
          style={{
            fontWeight: 'bold',
            marginLeft: 0,
            marginRight: 'auto',
          }}
          onClick={() => prevStep()}
        >
          Wstecz
        </Button>
        <Button
          type='primary'
          size='large'
          style={{
            fontWeight: 'bold',
            marginLeft: 'auto',
            marginRight: 0,
            color: passwordChanged
              ? `rgb(255, 255, 255)`
              : `rgba(255, 255, 255, 0.5)`,
          }}
          onClick={() => nextStepHandler()}
        >
          {last ? 'Rozpocznij' : 'Dalej'}
        </Button>
      </div>
    </>
  );
};

const ChangePackageStep = ({ prevStep, onFinish, userData }) => {
  const [packageData, setPackageData] = useState();
  const [packagePrice, setPackagePrice] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (userData.packageId) {
      packagesService
        .getOne(userData.packageId)
        .then((data) => {
          setPackageData(data);
        })
        .then(() => {
          const PackagePriceService = new PackagePricesService(
            userData.packageId
          );
          PackagePriceService.getPackagePrices()
            .then((pricesResponse) => {
              const sortedResponse = pricesResponse.sort(
                (a, b) => parseFloat(a.amount) - parseFloat(b.amount)
              );
              setPackagePrice(sortedResponse);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    }
  }, [userData]);

  const createPayment = (days) => {
    const PackagePriceService = new PackagePricesService(userData.packageId);
    const body = {
      days,
    };
    PackagePriceService.createPayment(body)
      .then((paymentResponse) => {
        if (paymentResponse.id) {
          onFinish();
          history.push(
            `/payments/null/premises/null/agreement/null/payment/${paymentResponse.id}/show`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const menu =
    packagePrice.length === 0 ? null : (
      <Menu>
        {packagePrice.map((price) => (
          <Menu.Item onClick={() => createPayment(price.days)}>
            {`${price.days} dni - ${price.amount} zł`}
          </Menu.Item>
        ))}
      </Menu>
    );

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <div style={{ paddingBottom: 50 }}>
            <Subscription
              packageData={packageData}
              packageExpireDate={userData.packageExpireDate}
              editUrl={`/myAccount/edit`}
              onEditUrlClick={() => onFinish()}
              defaultPane={'packages'}
              menu={menu}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              className='button-secondary'
              size='large'
              style={{
                fontWeight: 'bold',
                marginLeft: 0,
                marginRight: 'auto',
              }}
              onClick={() => prevStep()}
            >
              Wstecz
            </Button>
            <Button
              type='primary'
              size='large'
              style={{
                fontWeight: 'bold',
                marginLeft: 'auto',
                marginRight: 0,
              }}
              onClick={() => onFinish()}
            >
              Rozpocznij
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const FirstLoginPopup = ({ userData, setUserData }) => {
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [actualStep, setActualStep] = useState(0);
  const [isCommunityAdmin, setIsCommunityAdmin] = useState(false);

  const [rodoAccepted, setRodoAccepted] = useState(false);
  const [rodoContent, setRodoContent] = useState('');

  useEffect(() => {
    if (userData.roles.includes('ROLE_COMMUNITY_ADMIN')) {
      setIsCommunityAdmin(true);
    }
  }, [userData]);

  useEffect(() => {
    rodoService.verify().then((data) => {
      setRodoContent(data.content);
      setRodoAccepted(data.isAccepted);
    });
  }, []);

  const nextStep = () => {
    if (actualStep + 1 < stepContent.length) {
      setActualStep((currStep) => currStep + 1);
    } else {
      setModalVisible(false);
    }
  };

  const prevStep = () => {
    if (actualStep > 0) {
      setActualStep((currStep) => currStep - 1);
    }
  };

  const onFinish = () => {
    setModalVisible(false);
  };

  const stepContent = [
    <WelcomeStep nextStep={nextStep} />,
    ...(!isCommunityAdmin && rodoContent
      ? [
          <RodoStep
            nextStep={nextStep}
            prevStep={prevStep}
            setUserData={setUserData}
            rodoContent={rodoContent}
            rodoAccepted={rodoAccepted}
            setRodoAccepted={setRodoAccepted}
          />,
        ]
      : []),
    <ChangePasswordStep
      nextStep={nextStep}
      prevStep={prevStep}
      passwordChanged={passwordChanged}
      setPasswordChanged={setPasswordChanged}
      userData={userData}
      setUserData={setUserData}
      last={!isCommunityAdmin}
      rodoContent={rodoContent}
    />,
    ...(isCommunityAdmin && rodoContent
      ? [
          <ChangePackageStep
            prevStep={prevStep}
            onFinish={onFinish}
            userData={userData}
          />,
        ]
      : []),
  ];

  const stepHeader = [
    false,
    ...(!isCommunityAdmin ? ['Zgody'] : []),
    'Logowanie',
    ...(isCommunityAdmin ? ['Twój plan'] : []),
  ];

  return (
    <Modal
      centered
      visible={modalVisible}
      width={actualStep === 0 ? 410 : 780}
      closable={false}
      footer={false}
    >
      <div
        className={actualStep === 0 ? 'firstLogin-welcome-modal-wrapper' : ''}
      >
        {actualStep !== 0 && (
          <h2
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              textAlign: 'left',
              marginBottom: '30px',
              color: '#3B4C60',
            }}
          >
            <div>{stepHeader[actualStep]}</div>
            <div>{`Krok ${actualStep}/${
              stepContent ? stepContent.length - 1 : null
            }`}</div>
          </h2>
        )}
        {stepContent ? stepContent[actualStep] : null}
      </div>
    </Modal>
  );
};

export default FirstLoginPopup;
