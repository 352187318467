import BaseService from './baseService';

class TenantChangeGroupHistoryService extends BaseService {
  constructor(investmentId, premisesId, tenantChangeGroupId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChangeHistory`;
  }
}

export default TenantChangeGroupHistoryService;
