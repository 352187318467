import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Form, Row, Col, message, Card, Button } from 'antd';
import packagesService from '../../services/packagesService';
import Input from '../InputComponent';
import Spin from '../Spin';
import CommunityAdminUserService from '../../services/communityAdminsService';
import SmsLimitModal from './SmsLimitModal';

const { DatePicker, InputNumberInt } = Input;

const CommunityAdminPackageForm = forwardRef(
  ({ userData, isAdmin = false }, ref) => {
    const [loading, setLoading] = useState(true);
    const [packageData, setPackageData] = useState({});
    const [inputedPackageData, setInputedPackageData] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      setLoading(true);
      if (userData.packageId) {
        packagesService.getOne(userData.packageId).then((data) => {
          const price = data?.price;
          const pricePerPremise = data?.limits?.premises
            ? parseInt(price / data?.limits?.premises)
            : 0;
          setPackageData({
            ...data,
            pricePerPremise,
            // packageExpirationDate: userData.packageExpireDate,
          });
          setInputedPackageData({
            ...data,
            pricePerPremise,
            // packageExpirationDate: userData.packageExpireDate,
          });
        });
      }
      setLoading(false);
    }, [userData]);

    useEffect(() => {
      setPackageData(inputedPackageData);
    }, [inputedPackageData]);

    const changePremisesHandler = (e) => {
      if (e >= 0) {
        const price = inputedPackageData.pricePerPremise * e;
        setInputedPackageData((currInputedPackageData) => ({
          ...currInputedPackageData,
          price,
          limits: { ...currInputedPackageData.limits, premises: e },
        }));
      }
    };

    const changePriceHandler = (enteredNumber, single) => {
      if (enteredNumber >= 0) {
        if (single) {
          const pricePerPremise = enteredNumber;
          const price = enteredNumber * packageData.limits.premises;
          setInputedPackageData({
            ...inputedPackageData,
            pricePerPremise,
            price,
          });
        } else {
          const price = enteredNumber;
          const pricePerPremise = parseInt(
            enteredNumber / packageData.limits.premises
          );
          setInputedPackageData({
            ...inputedPackageData,
            pricePerPremise,
            price,
          });
        }
      }
    };

    const changeSmsHandler = (smsNumber) => {
      if (smsNumber >= 0) {
        setInputedPackageData({
          ...inputedPackageData,
          limits: {
            ...inputedPackageData.limits,
            sms: smsNumber,
          },
        });
      }
    };

    const changeDiscountsHandler = (enteredNumber, days) => {
      if (enteredNumber >= 0 && enteredNumber <= 100) {
        if (days === 180) {
          setInputedPackageData({
            ...inputedPackageData,
            discounts: {
              ...inputedPackageData.discounts,
              days180: enteredNumber,
            },
          });
        } else if (days === 360) {
          setInputedPackageData({
            ...inputedPackageData,
            discounts: {
              ...inputedPackageData.discounts,
              days360: enteredNumber,
            },
          });
        }
      }
    };

    useImperativeHandle(ref, () => ({
      onPackageChangeFinish(packageExpirationDate) {
        if (isAdmin) {
          const data = {
            ...packageData,
            packageExpirationDate,
          };

          packagesService
            .updateAsAdmin(userData.id, userData.packageId, data)
            .then(() => {
              // message.success('Zmiana pakietu zakończona pomyślnie');
            })
            .catch((err) => err);
        } else {
          CommunityAdminUserService.packageUpdate(userData.packageId, {
            limits: { premises: packageData.limits.premises, investments: -1 },
          })
            .then(() => {
              // message.success('Zmiana pakietu zakończona pomyślnie');
            })
            .catch((err) => err);
        }
      },
    }));

    const EmailUs = () => (
      <div className='create-form-wrapper'>
        <div
          className='account-subscription'
          style={{
            backgroundColor: '#262D37',
            paddingTop: 30,
            paddingBottom: 30,
            borderRadius: 10,
          }}
        >
          <div className='email-us'>
            <div className='email-us-text'>Nie znalazłeś planu dla siebie?</div>
            <Button
              className='button-secondary'
              style={{
                backgroundColor: '#5353F4',
                color: '#fff',
                border: 0,
              }}
            >
              <a href='mailto:support@manage.com.pl'>Napisz do nas</a>
            </Button>
          </div>
        </div>
      </div>
    );

    return loading ? (
      <div className='loading'>
        <Spin />
      </div>
    ) : (
      <div className='create-form-wrapper' id='community-admin-package-form'>
        <SmsLimitModal
          isModalVisible={showModal}
          setShowModal={setShowModal}
          packageData={packageData}
          setPackageData={setPackageData}
          setInputedPackageData={setInputedPackageData}
        />
        <Row gutter={20}>
          <Col xs={20} offset={2}>
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <h2 className='subscription-community-admin-header'>
                  Twój plan
                </h2>
                <Input.InputNumber
                  placeholder='Ilość lokali mieszkalnych'
                  value={packageData?.limits?.premises}
                  onChange={changePremisesHandler}
                />
                <div
                  className='account-subscription-details-limits-addnotation-details'
                  style={{ color: '#C0D6E5', marginBottom: 48 }}
                >
                  * Ilość lokali mieszkalnych we wszystkich inwestycjach
                </div>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <Input.InputNumber
                  placeholder='Kwota per lokal'
                  value={packageData.pricePerPremise}
                  onChange={(e) => changePriceHandler(e, true)}
                  disabled={!isAdmin}
                  addonAfter='zł netto'
                />
              </Col>
              <Col xs={24} lg={12}>
                <Input.InputNumber
                  placeholder='Opłata miesięczna'
                  value={inputedPackageData.price}
                  onChange={(e) => changePriceHandler(e, false)}
                  disabled={!isAdmin}
                  addonAfter='zł netto'
                />
              </Col>
              <Col xs={24}>
                <div
                  className='account-subscription-details-limits-addnotation-details'
                  style={{ color: '#C0D6E5', marginBottom: 48 }}
                >
                  * Podane kwoty są kwotami netto +23% VAT
                </div>
              </Col>
              <Col xs={24} lg={12}>
                {isAdmin && (
                  <Form.Item
                    name='packageExpireDate'
                    rules={[
                      {
                        required: true,
                        message: 'Proszę datę ważności pakietu',
                      },
                    ]}
                  >
                    <DatePicker
                      size='large'
                      placeholder='Data ważności pakietu'
                      style={{ width: '100%' }}
                      picker='date'
                      disabled={!isAdmin}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>

            {isAdmin && (
              <Row gutter={20}>
                <Col xs={24}>
                  <h2
                    className='subscription-community-admin-header'
                    style={{ marginTop: 80 }}
                  >
                    Rabaty
                  </h2>
                </Col>
                <Col xs={24} lg={12}>
                  <Input.InputNumber
                    placeholder='Rabat na 180 dni'
                    value={packageData?.discounts?.days180}
                    onChange={(e) => changeDiscountsHandler(e, 180)}
                    disabled={!isAdmin}
                    addonAfter='%'
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <Input.InputNumber
                    placeholder='Rabat na 360 dni'
                    value={inputedPackageData?.discounts?.days360}
                    onChange={(e) => changeDiscountsHandler(e, 360)}
                    disabled={!isAdmin}
                    max={100}
                    addonAfter='%'
                  />
                </Col>
                <Col xs={24}>
                  <h2
                    className='subscription-community-admin-header'
                    style={{ marginTop: 80 }}
                  >
                    Wysyłka SMS
                  </h2>
                </Col>
                <Col xs={24} lg={12}>
                  <Input.InputNumber
                    placeholder='Limit'
                    value={packageData?.limits?.sms}
                    disabled={!isAdmin}
                    onChange={(e) => changeSmsHandler(e)}
                    addonAfter='/ na miesiąc'
                  />
                </Col>
                <Col xs={24}>
                  <div
                    className='account-subscription-details-limits-addnotation-details'
                    style={{ color: '#C0D6E5', marginBottom: 48 }}
                  >
                    * Wysłanych SMS w tym miesiącu:{' '}
                    <span
                      style={{
                        fontWeight: 700,
                        color:
                          packageData?.limits?.sms -
                            packageData?.limits?.smsSent <
                          100
                            ? '#EC434C'
                            : '#15D55E',
                      }}
                    >
                      {packageData?.limits?.smsSent}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
            {!isAdmin && (
              <>
                <EmailUs />
                <Row gutter={20}>
                  <Col xs={24}>
                    <h2
                      className='subscription-community-admin-header'
                      style={{ marginTop: 80 }}
                    >
                      Wysyłka SMS
                    </h2>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Input.InputNumber
                      placeholder='Limit'
                      value={packageData?.limits?.sms}
                      disabled={!isAdmin}
                      onChange={(e) => changeSmsHandler(e)}
                      addonAfter='/ na miesiąc'
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <Button
                      type='primary'
                      style={{ marginBottom: 12 }}
                      onClick={() => setShowModal(true)}
                    >
                      Zwiększ limit
                    </Button>
                  </Col>
                  <Col xs={24}>
                    <div
                      className='account-subscription-details-limits-addnotation-details'
                      style={{ color: '#C0D6E5', marginBottom: 48 }}
                    >
                      * Wysłanych SMS w tym miesiącu:{' '}
                      <span
                        style={{
                          fontWeight: 700,
                          color:
                            packageData?.limits?.sms -
                              packageData?.limits?.smsSent <
                            100
                              ? '#EC434C'
                              : '#15D55E',
                        }}
                      >
                        {packageData?.limits?.smsSent}
                      </span>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
);

export default CommunityAdminPackageForm;
