import React from 'react';
import { Col, Row } from 'antd';
import { StatsWrapper, SettlementsListComponent } from './ViewComponents';
import WorkerDashboard from './WorkerDashboard';

const WorkerBrokerDashboard = ({ workerPermissions, ownerRoles = [] }) => (
  <>
    <WorkerDashboard workerPermissions={workerPermissions} ownerRoles={ownerRoles} />
    {workerPermissions.includes('PAYMENTS_FULL') && (
      <Row gutter={20}>
        <Col xs={24} md={12}>
          <StatsWrapper title="Twoje rozliczenia" icon="file">
            <SettlementsListComponent />
          </StatsWrapper>
        </Col>
      </Row>
    )}
  </>
);

export default WorkerBrokerDashboard;
