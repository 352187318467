import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import Price from '../../../components/Price';
import Icon from '../../../components/Icon';

const Statisticts = ({
  type, premisesStats, saleStats, setFilters, datagridFilters, canSeePrices, canSeeStats,
}) => {
  const preTitle = type === 'RENT' ? 'Wynajmowanych' : 'Sprzedanych';
  const firstKey = type === 'RENT' ? 'RENT' : 'SALE';

  const data = {
    first: premisesStats[firstKey] || 0,
    second: premisesStats.SERVICE || 0,
    third: premisesStats.PARKING || 0,
    fourth: premisesStats.STORAGE || 0,
  };

  const isActive = (premiseType) => {
    if (!datagridFilters?.type || !datagridFilters?.status) {
      return '';
    }
    let status = '';
    if (type === 'RENT') {
      status = 'RENT';
    } else {
      status = 'SOLD';
    }

    if (premiseType === datagridFilters.type && status === datagridFilters.status) {
      return 'premises-stats-elem-active';
    }

    return '';
  };

  const setFiltersFromStats = (investmentType, premiseType) => {
    const filters = {
      type: premiseType,
    };
    if (investmentType === 'RENT') {
      filters.status = 'RENT';
    } else {
      filters.status = 'SOLD';
    }

    return setFilters(filters);
  };

  const StatsElem = ({
    title, value, investmentType, premiseType,
  }) => (
    <div className={`premises-stats-elem ${isActive(premiseType)}`} role="presentation" onClick={() => setFiltersFromStats(investmentType, premiseType)} style={{ cursor: 'pointer' }}>
      <div className="premises-stats-elem-value">{value}</div>
      <div className="premises-stats-elem-title">{title}</div>
    </div>
  );

  return (
    <>
      <div className={`premises-stats premises-stats-${type}`}>
        <Row gutter={20} style={{ marginBottom: '20px' }}>
          <Col xs={24} sm={6} style={{ marginBottom: '20px' }}>
            <StatsElem title={`${preTitle} mieszkań`} value={data.first} investmentType={type} premiseType={firstKey} />
          </Col>
          <Col xs={24} sm={6} style={{ marginBottom: '20px' }}>
            <StatsElem title={`${preTitle} lokali usługowych`} value={data.second} investmentType={type} premiseType="SERVICE" />
          </Col>
          <Col xs={24} sm={6} style={{ marginBottom: '20px' }}>
            <StatsElem title={`${preTitle} miejsc postojowych`} value={data.third} investmentType={type} premiseType="PARKING" />
          </Col>
          <Col xs={24} sm={6} style={{ marginBottom: '20px' }}>
            <StatsElem title={`${preTitle} komórek lokatorskich`} value={data.fourth} investmentType={type} premiseType="STORAGE" />
          </Col>
        </Row>
      </div>
      {type === 'SALE' && canSeePrices && canSeeStats && (
        <div className="premises-stats premises-stats-STATS">
          <Row gutter={20}>
            <Col xs={24} sm={18} style={{ marginBottom: '20px' }}>
              <div className="premises-stats-elem">
                <Row>
                  <Col xs={24} sm={12}>
                    <div className="premises-stats-elem-value"><Price value={saleStats.currentIncome} showEmpty /></div>
                    <div className="premises-stats-elem-title">
                      Bieżący przychód z inwestycji (BP)
                      <Tooltip title="Symulacja bieżącego przychodu brutto na podstawie lokali sprzedanych i zarezerwowanych">
                        <Icon name="more-info" style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className="premises-stats-elem-value"><Price value={saleStats.minimalIncome} showEmpty /></div>
                    <div className="premises-stats-elem-title">
                      Minimalny przychód z inwestycji (MPI)
                      <Tooltip title="Symulacja minimalnego przychodu brutto z inwestycji lokali wolnych">
                        <Icon name="more-info" style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={6} style={{ marginBottom: '20px' }}>
              <div className="premises-stats-elem simulation" style={{ paddingTop: '61px' }}>
                <div className="premises-stats-elem-value" style={{ color: '#587999' }}><Price value={saleStats.simulation} showEmpty /></div>
                <div className="premises-stats-elem-title" style={{ color: '#8FB3D1' }}>
                  Symulacja przychodu (SP)
                  <Tooltip title="Suma BP i MPI">
                    <Icon name="more-info" style={{ marginLeft: '5px' }} />
                  </Tooltip>

                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Statisticts;
