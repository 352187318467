import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Checkbox,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  Switch,
  message,
  Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import workersService from '../../services/workersService';
import userService from '../../services/userService';
import housingAssociationsService from '../../services/housingAssociationsService';
import housingCommunitiesService from '../../services/housingCommunitiesService';
import Icon from '../../components/Icon';
import sendPassword from '../../static/images/img-1.png';
import sendPassword2 from '../../static/images/img-m-2.png';
import sendPassword3 from '../../static/images/img-m-3.png';
import sendPassword4 from '../../static/images/img-m-4.png';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';

import Tabs from '../../components/Tabs';
import { generateRandomPassword } from '../../helpers/passwordHelpers';

const { InputNumber, Password2, Select } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

const Create = () => {
  const history = useHistory();
  const [adminData, setAdminData] = useState({});
  const [form] = Form.useForm();
  let image = sendPassword;
  const selectedTheme = localStorage.getItem('theme') || 'default';
  if (selectedTheme === 'sky') {
    image = sendPassword3;
  }
  if (selectedTheme === 'brick') {
    image = sendPassword2;
  }
  if (selectedTheme === 'mech') {
    image = sendPassword4;
  }
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const [housingAssociations, setHousingAssociations] = useState([]);
  const [housingCommunities, setHousingCommunities] = useState([]);

  const [showSelectedHousingAssociations, setShowSelectedHousingAssociations] =
    useState(false);
  const [showSelectedHousingCommunities, setShowSelectedHousingCommunities] =
    useState(false);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setAdminData(data);
    });
  }, []);

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.getList({
      types: 'HOUSING_ASSOCIATION',
      sortField: 'position',
      sortOrder: 'ascend',
    }).then((data) => {
      if (data.content && data.content.length !== 0) {
        const tempData = data.content.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setHousingAssociations(tempData);
      }
    });

    const HousingCommunitiesService = new housingCommunitiesService();
    HousingCommunitiesService.getList({
      types: 'HOUSING_COMMUNITY',
      sortField: 'position',
      sortOrder: 'ascend',
    }).then((data) => {
      if (data.content && data.content.length !== 0) {
        const tempData = data.content.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        setHousingCommunities(tempData);
      }
    });
  }, []);

  const onFinish = (values) => {
    const roles = ['ROLE_WORKER'];

    const workerPermissions = [];

    const workerCommunityPermissions = {
      housingAssociation: false,
      housingAssociationIds: [],
      housingCommunity: false,
      housingCommunityIds: [],
    };

    if (values.workerCommunityPermissions) {
      if (values.workerCommunityPermissions.housingAssociation) {
        workerCommunityPermissions.housingAssociation = true;
      }

      if (
        values.workerCommunityPermissions.housingAssociationIds?.length !== 0
      ) {
        workerCommunityPermissions.housingAssociationIds =
          values.workerCommunityPermissions.housingAssociationIds;
      }

      if (values.workerCommunityPermissions.housingCommunity) {
        workerCommunityPermissions.housingCommunity = true;
      }

      if (values.workerCommunityPermissions.housingCommunityIds?.length !== 0) {
        workerCommunityPermissions.housingCommunityIds =
          values.workerCommunityPermissions.housingCommunityIds;
      }
    }

    if (
      values.workerCommunityPermissions?.housingAssociation ||
      values.workerCommunityPermissions?.housingCommunity
    ) {
      workerPermissions.push('INVESTMENT_FULL');
      workerPermissions.push('INVESTMENT_READ');
    }

    if (values.permStats) {
      workerPermissions.push('STATS_FULL');
    }

    if (values.permMembers) {
      workerPermissions.push('CLIENTS_FULL');
      workerPermissions.push('CLIENTS_READ');
    }

    if (values.permWorkers) {
      workerPermissions.push('WORKERS_FULL');
    }

    if (values.permResolutions) {
      workerPermissions.push('RESOLUTIONS_FULL');
    }

    if (values.permFaults) {
      workerPermissions.push('FAULTS_FULL');
    }

    if (values.permReceipts) {
      workerPermissions.push('RECEIPTS_FULL');
    }

    if (values.permPayments) {
      workerPermissions.push('PAYMENTS_FULL');
    }

    if (values.permTags) {
      workerPermissions.push('TAGS_FULL');
    }

    let data = {
      ...values,
      roles,
      workerPermissions,
      workerCommunityPermissions,
      isPasswordChanged: false,
    };

    if (data?.identity?.idExpireDate) {
      data = {
        ...data,
        identity: {
          ...data.identity,
          idExpireDate: data.identity.idExpireDate.format('YYYY-MM-DD'),
        },
      };
    }
    workersService
      .create(data)
      .then((response) => {
        if (response.id) {
          history.replace(`/housingWorkers/${response.id}/show`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
        if (err?.response?.status === 409) {
          form.setFields([
            {
              name: 'username',
              errors: ['Podany login juz istnieje'],
            },
          ]);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const fillPasswordsHandler = () => {
    const randomPassword = generateRandomPassword(8);

    form.setFieldsValue({
      password: randomPassword,
      confirm: randomPassword,
    });
  };

  const handleCancel = () => {
    // history.push('/housingWorkers');
    history.goBack();
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        // onBack={() => history.push('/housingWorkers')}
        onBack={() => history.goBack()}
        title='Dodaj pracownika'
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          initialValues={{
            enabled: true,
            role: ['ROLE_WORKER'],
            address: { country: 'Polska' },
            correspondenceAddress: { country: 'Polska' },
            notifications: { email: true, sms: true },
            workerCommunityPermissions: {
              housingAssociation: true,
              housingCommunity: true,
            },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Rodzaj konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='isCompany'
                                label='Konto firmowe'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            !getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane osobowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item name='secondName'>
                                        <Input
                                          size='large'
                                          placeholder='Drugie imię (opcjonalne)'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) =>
                            prev.isCompany !== current.isCompany
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue('isCompany') && (
                              <>
                                <div className='form-section'>
                                  <h2>Dane firmowe</h2>
                                  <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='name'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwa firmy'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='nip'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input.MaskedInput
                                          mask='000-000-00-00'
                                          size='large'
                                          placeholder='NIP'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </>
                            )
                          }
                        </Form.Item>
                        <div className='form-section'>
                          <h2>Stanowisko</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='workerPosition'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Adres siedziby</h2>
                              ) : (
                                <h2>Adres zamieszkania</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Mieszkanie' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item
                              name='differentAddress'
                              valuePropName='checked'
                              noStyle
                            >
                              <Checkbox onChange={correspondenceShow}>
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size='large' placeholder='Ulica' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size='large' placeholder='Numer' />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Mieszkanie'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Miejscowość'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput
                                      mask='00-000'
                                      size='large'
                                      placeholder='Kod pocztowy'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className='form-section'>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) =>
                              prev.isCompany !== current.isCompany
                            }
                          >
                            {({ getFieldValue }) =>
                              getFieldValue('isCompany') ? (
                                <h2>Osoba do kontaktu</h2>
                              ) : (
                                <h2>Dane kontaktowe</h2>
                              )
                            }
                          </Form.Item>
                          <Row gutter={20}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.isCompany !== current.isCompany
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue('isCompany') && (
                                  <>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='firstName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Imię'
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                      <Form.Item
                                        name='lastName'
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Pole wymagane',
                                          },
                                        ]}
                                      >
                                        <Input
                                          size='large'
                                          placeholder='Nazwisko'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </>
                                )
                              }
                            </Form.Item>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='email'
                                rules={[
                                  {
                                    required: false,
                                    message: 'Proszę uzupełnić adres email',
                                  },
                                  {
                                    type: 'email',
                                    message: 'Niepoprawny adres email',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='E-Mail' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item name='mobile'>
                                <InputNumber
                                  size='large'
                                  placeholder='Telefon'
                                  className='hide-arrows'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Status konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='enabled'
                                label='Aktywne'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Card className='create-form-password-card'>
                      <Row>
                        <Col xs={24} sm={{ span: 10, offset: 2 }}>
                          <div className='form-section'>
                            <h2>Dane do logowania</h2>
                            <Form.Item
                              name='username'
                              rules={[
                                {
                                  required: true,
                                  message: 'Proszę uzupełnić login',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Login' />
                            </Form.Item>
                            <Form.Item
                              name='password'
                              rules={[
                                { message: 'Pole wymagane' },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      /\d/.test(value) &&
                                      /[A-Z]/.test(value) &&
                                      /[a-z]/.test(value) &&
                                      value.length >= 8
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2 size='large' placeholder='Hasło' />
                            </Form.Item>
                            <Form.Item
                              name='confirm'
                              dependencies={['password']}
                              hasFeedback
                              rules={[
                                {
                                  message: 'Potwierdź hasło',
                                },
                                {
                                  min: 8,
                                  message:
                                    'Hasło musi posiadać conajmniej 8 znaków.',
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue('password') === value
                                    ) {
                                      return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(
                                      'Hasło nieprawidłowe'
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Password2
                                size='large'
                                placeholder='Powtórz hasło'
                              />
                            </Form.Item>
                            <div
                              className='account-subscription-details-limits-addnotation-details'
                              style={{ color: '#C0D6E5', marginBottom: 24 }}
                            >
                              * Min. 8 znaków, wielka i mała litera, jedna cyfra
                            </div>
                            <Form.Item>
                              <Text
                                type='secondary'
                                className='generate-password'
                                underline
                                onClick={fillPasswordsHandler}
                              >
                                Generuj hasło
                              </Text>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={0} sm={10}>
                          <div className='send-password-image-wrapper'>
                            <img src={image} alt='send password icon' />
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </TabPane>
                <TabPane tab='Alerty' key='alerts' forceRender>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='permissions'>
                          <div className='form-section'>
                            <h2>Powiadomienia</h2>
                            <Form.Item
                              label='E-mail'
                              name={['notifications', 'email']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item
                              label='SMS'
                              name={['notifications', 'sms']}
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Uprawnienia' key='investments' forceRender>
                  <div className='investments'>
                    <div className='create-form-wrapper'>
                      <Row>
                        <Col xs={20} offset={2}>
                          <div className='permissions'>
                            <div className='form-section'>
                              <h2>Uprawnienia</h2>
                              {adminData?.communityAdminPermissions?.includes(
                                'HOUSING_ASSOCIATION'
                              ) && (
                                <Form.Item
                                  label='Spółdzielnie'
                                  name={[
                                    'workerCommunityPermissions',
                                    'housingAssociation',
                                  ]}
                                  valuePropName='checked'
                                >
                                  <Switch
                                    onChange={(checked) => {
                                      if (checked) {
                                        setShowSelectedHousingAssociations(
                                          false
                                        );
                                        if (checked) {
                                          form.setFieldsValue({
                                            selectedHousingAssociations: false,
                                            workerCommunityPermissions: {
                                              housingAssociationIds: [],
                                            },
                                          });
                                        }
                                      }
                                    }}
                                  />
                                </Form.Item>
                              )}
                              {adminData?.communityAdminPermissions?.includes(
                                'HOUSING_ASSOCIATION'
                              ) && (
                                <>
                                  <Form.Item
                                    label={
                                      <div>
                                        Spółdzielnie{' '}
                                        <span style={{ color: '#B1CADE' }}>
                                          (wybrane)
                                        </span>
                                      </div>
                                    }
                                    name='selectedHousingAssociations'
                                    valuePropName='checked'
                                  >
                                    <Switch
                                      onChange={(checked) => {
                                        setShowSelectedHousingAssociations(
                                          checked
                                        );

                                        if (checked) {
                                          form.setFieldsValue({
                                            workerCommunityPermissions: {
                                              housingAssociation: false,
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Item>

                                  {showSelectedHousingAssociations && (
                                    <Form.Item
                                      name={[
                                        'workerCommunityPermissions',
                                        'housingAssociationIds',
                                      ]}
                                    >
                                      <Select
                                        labelClass={'choosenInvestmentsLabel'}
                                        style={{ width: 250 }}
                                        mode={'multiple'}
                                        options={housingAssociations}
                                        placeholder={'Spółdzielnie'}
                                        allowClear
                                      />
                                    </Form.Item>
                                  )}
                                </>
                              )}

                              {adminData?.communityAdminPermissions?.includes(
                                'HOUSING_COMMUNITY'
                              ) && (
                                <Form.Item
                                  label='Wspólnoty'
                                  name={[
                                    'workerCommunityPermissions',
                                    'housingCommunity',
                                  ]}
                                  valuePropName='checked'
                                >
                                  <Switch
                                    onChange={(checked) => {
                                      if (checked) {
                                        setShowSelectedHousingCommunities(
                                          false
                                        );
                                        if (checked) {
                                          form.setFieldsValue({
                                            selectedHousingCommunities: false,
                                            workerCommunityPermissions: {
                                              housingCommunityIds: [],
                                            },
                                          });
                                        }
                                      }
                                    }}
                                  />
                                </Form.Item>
                              )}

                              {adminData?.communityAdminPermissions?.includes(
                                'HOUSING_COMMUNITY'
                              ) && (
                                <>
                                  <Form.Item
                                    label={
                                      <div>
                                        Wspólnoty{' '}
                                        <span style={{ color: '#B1CADE' }}>
                                          (wybrane)
                                        </span>
                                      </div>
                                    }
                                    name='selectedHousingCommunities'
                                    valuePropName='checked'
                                  >
                                    <Switch
                                      onChange={(checked) => {
                                        setShowSelectedHousingCommunities(
                                          checked
                                        );

                                        if (checked) {
                                          form.setFieldsValue({
                                            workerCommunityPermissions: {
                                              housingCommunity: false,
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  </Form.Item>

                                  {showSelectedHousingCommunities && (
                                    <Form.Item
                                      name={[
                                        'workerCommunityPermissions',
                                        'housingCommunityIds',
                                      ]}
                                    >
                                      <Select
                                        labelClass={'choosenInvestmentsLabel'}
                                        style={{ width: 250 }}
                                        mode={'multiple'}
                                        options={housingCommunities}
                                        placeholder={'Wspólnoty'}
                                        allowClear
                                      />
                                    </Form.Item>
                                  )}
                                </>
                              )}

                              <Form.Item
                                label='Statystyki'
                                name='permStats'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Członkowie'
                                name='permMembers'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Pracownicy'
                                name='permWorkers'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Uchwały'
                                name='permResolutions'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Usterki'
                                name='permFaults'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Rozliczenia'
                                name='permReceipts'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Płatności'
                                name='permPayments'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                              <Form.Item
                                label='Tagi'
                                name='permTags'
                                valuePropName='checked'
                              >
                                <Switch />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Dodaj
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
