import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import housingAssociationsService from '../../services/housingAssociationsService';
import resolutionsService from '../../services/resolutionsService';
import workersService from '../../services/workersService';
import userService from '../../services/userService';
import { Attachments } from './editComponents/Attachments';
import { Gallery } from './editComponents/Gallery';

import { Tabs, Input, Icon, deleteConfirm } from '../../components';

import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PublicFileService from "../../services/publicFileService";

const { DatePicker, Select, TextArea, SearchBox, PriceInput } = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit = () => {
  const { investmentId, resolutionId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [resolution, setResolution] = useState({});

  const [userData, setUserData] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
    });

    const ResolutionsService = new resolutionsService(investmentId);
    ResolutionsService.getOne(resolutionId).then((resolutionData) => {
      const tempData = {
        name: resolutionData.name,
        investmentId: resolutionData.investmentId,
        status: resolutionData.status,
        responsibleWorkers: resolutionData.responsibleWorkers
          ? resolutionData.responsibleWorkers
          : [],
        addDate: resolutionData.addDate ? moment(resolutionData.addDate) : null,
        startDate: resolutionData.startDate
          ? moment(resolutionData.startDate)
          : null,
        endDate: resolutionData.endDate ? moment(resolutionData.endDate) : null,
      };

      const contentState = convertFromRaw(
        JSON.parse(resolutionData.description)
      );

      setEditorState(EditorState.createWithContent(contentState));

      form.setFieldsValue(tempData);
      setResolution(resolutionData);
    });
  }, [investmentId, resolutionId, form]);

  const onFinish = (values) => {
    const tempValues = {
      name: values.name,
      investmentId: values.investmentId,
      responsibleWorkers: values.responsibleWorkers,
      status: resolution.status,
    };

    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.addDate = values.addDate.format('YYYY-MM-DD');
    }

    if (values?.startDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.startDate = values.startDate.format('YYYY-MM-DD');
      if (
        resolution.status === 'AWAITING' &&
        values.startDate.isSameOrBefore(moment(), 'days')
      ) {
        tempValues.status = 'STARTED';
      }
    }

    if (values?.endDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.endDate = values.endDate.format('YYYY-MM-DD');
      if (
        resolution.status === 'STARTED' &&
        values.endDate.isSameOrBefore(moment(), 'days')
      ) {
        tempValues.status = 'FINISHED';
      }
    }

    tempValues.description = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const ResolutionsService = new resolutionsService(values.investmentId);
    ResolutionsService.update(resolutionId, tempValues)
      .then((response) => {
        if (response.id) {
          history.replace(
            `/investment/${response.investmentId}/resolutions/${response.id}/show`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const ResolutionsService = new resolutionsService(investmentId);
    ResolutionsService.delete(resolutionId)
      .then(() => history.replace('/resolutions'))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const HeaderTitle = () => {
    if (!resolution.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{resolution.name}</span>
      </span>
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  const getUserOptionLabel = (e) => {
    const pesel = e.identity && e.identity.pesel ? `(${e.identity.pesel})` : '';
    return `${e.firstName} ${e.lastName} ${pesel}`;
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const uploadCallback = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const publicFilesService = new PublicFileService();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return publicFilesService.create(formData).then((response) => ({
      data: {
        link: `${baseUrl}${response.file}`,
      },
    })).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const editorConfig = {
    image: { uploadCallback },
  };

  return (
    <div className='form-create faults-edit'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => handleBack()}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Dane podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić nazwę',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='investmentId'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <SearchBox
                                  placeholder='Spółdzielnia/Wspólnota'
                                  size='large'
                                  resource={new housingAssociationsService()}
                                  filters={{
                                    types: [
                                      'HOUSING_ASSOCIATION',
                                      'HOUSING_COMMUNITY',
                                    ],
                                  }}
                                  resourceField='name'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Czas trwania</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='addDate'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data dodania'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={12}>
                              {/* <Form.Item
                                name='status'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                              >
                                <Select placeholder='Status' size='large'>
                                  <Option value='AWAITING'>
                                    <span className='dot yellow' />
                                    Oczekuje
                                  </Option>
                                  <Option value='STARTED'>
                                    <span className='dot green' />W toku
                                  </Option>
                                  <Option value='FINISHED'>
                                    <span className='dot grey' />
                                    Zakończona
                                  </Option>
                                </Select>
                              </Form.Item> */}
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='startDate'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                  {
                                    validator(rule, value) {
                                      return new Promise((resolve, reject) => {
                                        if (!value) {
                                          resolve();
                                        }

                                        const inputedEndDate =
                                          form.getFieldValue('endDate');

                                        if (value?.isBefore(moment(), 'day')) {
                                          reject(
                                            'Data rozpoczęcia nie może być wcześniej niż dziś'
                                          );
                                        } else if (
                                          inputedEndDate &&
                                          value.isSameOrAfter(
                                            inputedEndDate,
                                            'days'
                                          )
                                        ) {
                                          reject(
                                            'Data rozpoczęcia musi nastąpić min. 1 dzień przed datą zakończenia'
                                          );
                                        } else {
                                          resolve();
                                        }
                                      });
                                    },
                                  },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data rozpoczęcia'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='endDate'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                  {
                                    validator(rule, value) {
                                      return new Promise((resolve, reject) => {
                                        if (!value) {
                                          resolve();
                                        }

                                        const inputedStartDate =
                                          form.getFieldValue('startDate');

                                        if (!inputedStartDate) {
                                          resolve();
                                        }

                                        if (value?.isBefore(moment(), 'day')) {
                                          reject(
                                            'Data zakończenia nie może być wcześniej niż dziś'
                                          );
                                        } else if (
                                          value?.isSameOrBefore(
                                            inputedStartDate,
                                            'day'
                                          )
                                        ) {
                                          reject(
                                            'Data zakończenia musi nastąpić min. 1 dzień po dacie rozpoczęcia'
                                          );
                                        } else {
                                          resolve();
                                        }
                                      });
                                    },
                                  },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data zakończenia'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className='form-section'>
                          <h2>Osoba odpowiedzialna</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item name='responsibleWorkers'>
                                <SearchBox
                                  placeholder='Użytkownik'
                                  size='large'
                                  resource={workersService}
                                  filters={{ role: 'ROLE_WORKER' }}
                                  resourceField='firstOrLastName'
                                  optionValue={(e) => getUserOptionLabel(e)}
                                  mode='multiple'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Szczegóły</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Editor
                                localization={{ locale: 'pl' }}
                                editorState={editorState}
                                wrapperClassName='demo-wrapper'
                                editorClassName='demo-editor'
                                toolbar={editorConfig}
                                onEditorStateChange={onEditorStateChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Galeria' key='gallery'>
                  <Gallery
                    investmentId={investmentId}
                    resolutionId={resolutionId}
                  />
                </TabPane>
                <TabPane tab='Załączniki' key='attachments'>
                  <Attachments
                    investmentId={investmentId}
                    resolutionId={resolutionId}
                  />
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Divider />
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleBack}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Edit;
