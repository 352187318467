import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Spin } from '../../../components';
import communityAdminDashboardService from '../../../services/communityAdminDashboardService';
import userService from '../../../services/userService';
import {
  StatsWrapper,
  StatsElem,
  StatsElemParkingCustom,
} from './ViewComponents';

const Associations = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Spółdzielnie'
        icon='spoldzielnia'
        actions={[
          <Link key='housingAssociations' to='housingAssociations'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={24} md={8}>
            <StatsElem
              redirect='/housingAssociations'
              type='dashboard-elem-darkblue'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.count
              }
              topLower='Spółdzielnie'
            />
          </Col>
          <Col xs={12} md={16}>
            <StatsElemParkingCustom
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.parkingCount
              }
              topLower='Miejsca postojowe'
              bottomHigher='Przychód z inwestycji (BP)'
              // redirect='/housingPremises?type=PARKING'
              parkingGarageCount={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.parkingGarageCount
              }
              parkingGarageHallCount={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.parkingGarageHallCount
              }
              parkingOutsideCount={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.parkingOutsideCount
              }
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=RENT'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.premisesRentCount
              }
              topLower='Mieszkania'
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=SERVICE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.serviceCount
              }
              topLower='Lokale usługowe'
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=STORAGE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.investment?.storageCount
              }
              topLower='Komórki lokatorskie'
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const AssociationsSettlements = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Rozliczenia'
        icon='payments'
        actions={[
          <Link key='key' to='housingSettlements'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_ASSOCIATION&type=PAID'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.paidCount
              }
              topLower='Opłaconych'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.paidValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.paidCount && '#3B4C60'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.paidCount && '#fff'
              }
              bottomLowerColor={'#3B4C60'}
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_ASSOCIATION&type=TO_PAY'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.toPayCount
              }
              topLower='Do opłacenia'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.toPayValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.toPayCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.allSettlements?.toPayCount > 0
                  ? '#EC434C'
                  : '#3B4C60'
              }
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            xs={24}
            style={{
              marginTop: 20,
              marginBottom: 10,
              fontWeight: 700,
            }}
          >
            Rozliczenia w tym miesiącu
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_ASSOCIATION&type=PAID&this_month=TRUE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.paidCount
              }
              topLower='Opłaconych'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.paidValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount && '#3B4C60'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={'#3B4C60'}
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_ASSOCIATION&type=TO_PAY&this_month=TRUE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount
              }
              topLower='Do opłacenia'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.currentMonthSettlements?.toPayCount > 0
                  ? '#EC434C'
                  : '#3B4C60'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const AssociationsResolutions = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Uchwały'
        icon='uchwaly'
        actions={[
          <Link key='resolutions' to='resolutions'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_ASSOCIATION&type=STARTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.started
              }
              topLower='W toku'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.started && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.started && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_ASSOCIATION&type=AWAITING'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.awaiting
              }
              topLower='Oczekujące'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.awaiting && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.awaiting && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_ASSOCIATION&type=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.finished
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.finished && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.resolutions?.finished && '#fff'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const AssociationsFaults = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper title='Usterki' icon='fault'>
        <Row gutter={10}>
          <Col xs={24} style={{ marginBottom: 10, fontWeight: 700 }}>
            Odebrane
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=RECEIVED&status=REJECTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.rejectedCount
              }
              topLower='Odrzucone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.rejectedCount && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.rejectedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=RECEIVED&status=TO_FIX'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.forRepairCount
              }
              topLower='Do naprawy'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.forRepairCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.forRepairCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=RECEIVED&status=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.finishedCount
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.finishedCount && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.finishedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=RECEIVED&status=VERIFICATION'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.verificationCount
              }
              topLower='Oczekuje na zasadność'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.verificationCount && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.receivedFaults?.verificationCount && '#fff'
              }
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            xs={24}
            style={{
              marginBottom: 10,
              marginTop: 20,
              fontWeight: 700,
            }}
          >
            Wysłane
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=SEND&status=REJECTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.rejectedCount
              }
              topLower='Odrzucone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.rejectedCount && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.rejectedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=SEND&status=TO_FIX'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.forRepairCount
              }
              topLower='Do naprawy'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.forRepairCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.forRepairCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=SEND&status=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.finishedCount
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.finishedCount && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.finishedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_ASSOCIATION&type=SEND&status=VERIFICATION'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.verificationCount
              }
              topLower='Oczekuje na zasadność'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.verificationCount && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_ASSOCIATION
                  ?.sentFaults?.verificationCount && '#fff'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const Communities = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Wspólnoty'
        icon='wspolnota'
        actions={[
          <Link key='housingCommunities' to='housingCommunities'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={24} md={8}>
            <StatsElem
              redirect='/housingCommunities'
              type='dashboard-elem-darkblue'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.count
              }
              topLower='Wspólnoty'
            />
          </Col>
          <Col xs={12} md={16}>
            <StatsElemParkingCustom
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.parkingCount
              }
              topLower='Miejsca postojowe'
              bottomHigher='Przychód z inwestycji (BP)'
              // redirect='/housingPremises?type=PARKING'
              parkingGarageCount={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.parkingGarageCount
              }
              parkingGarageHallCount={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.parkingGarageHallCount
              }
              parkingOutsideCount={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.parkingOutsideCount
              }
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=RENT'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.premisesRentCount
              }
              topLower='Mieszkania'
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=SERVICE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.serviceCount
              }
              topLower='Lokale usługowe'
            />
          </Col>
          <Col xs={12} md={8}>
            <StatsElem
              // redirect='/housingPremises?type=STORAGE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.investment?.storageCount
              }
              topLower='Komórki lokatorskie'
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const CommunitiesSettlements = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Rozliczenia'
        icon='payments'
        actions={[
          <Link key='key' to='housingSettlements'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_COMMUNITY&type=PAID'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.paidCount
              }
              topLower='Opłaconych'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.paidValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.paidCount && '#3B4C60'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.paidCount && '#fff'
              }
              bottomLowerColor={'#3B4C60'}
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_COMMUNITY&type=TO_PAY'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.toPayCount
              }
              topLower='Do opłacenia'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.toPayValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.toPayCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.allSettlements?.toPayCount > 0
                  ? '#EC434C'
                  : '#3B4C60'
              }
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            xs={24}
            style={{
              marginTop: 20,
              marginBottom: 10,
              fontWeight: 700,
            }}
          >
            Rozliczenia w tym miesiącu
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_COMMUNITY&type=PAID&this_month=TRUE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.paidCount
              }
              topLower='Opłaconych'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.paidValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount && '#3B4C60'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={'#3B4C60'}
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/housingSettlements?investmentType=HOUSING_COMMUNITY&type=TO_PAY&this_month=TRUE'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount
              }
              topLower='Do opłacenia'
              bottomHigher='Na kwotę'
              bottomLower={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayValue
              }
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount && '#fff'
              }
              bottomLowerColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.currentMonthSettlements?.toPayCount > 0
                  ? '#EC434C'
                  : '#3B4C60'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const CommunitiesResolutions = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper
        title='Uchwały'
        icon='uchwaly'
        actions={[
          <Link key='resolutions' to='resolutions'>
            <Button size='small'>Pokaż</Button>
          </Link>,
        ]}
      >
        <Row gutter={10}>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_COMMUNITY&type=STARTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.started
              }
              topLower='W toku'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.started && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.started && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_COMMUNITY&type=AWAITING'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.awaiting
              }
              topLower='Oczekujące'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.awaiting && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.awaiting && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/resolutions?investmentType=HOUSING_COMMUNITY&type=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.finished
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.finished && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.resolutions?.finished && '#fff'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const CommunitiesFaults = ({ dashboardData }) => {
  return (
    <Col xs={24}>
      <StatsWrapper title='Usterki' icon='fault'>
        <Row gutter={10}>
          <Col xs={24} style={{ marginBottom: 10, fontWeight: 700 }}>
            Odebrane
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=RECEIVED&status=REJECTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.rejectedCount
              }
              topLower='Odrzucone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.rejectedCount && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.rejectedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=RECEIVED&status=TO_FIX'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.forRepairCount
              }
              topLower='Do naprawy'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.forRepairCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.forRepairCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=RECEIVED&status=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.finishedCount
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.finishedCount && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.finishedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=RECEIVED&status=VERIFICATION'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.verificationCount
              }
              topLower='Oczekuje na zasadność'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.verificationCount && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.receivedFaults?.verificationCount && '#fff'
              }
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col
            xs={24}
            style={{
              marginBottom: 10,
              marginTop: 20,
              fontWeight: 700,
            }}
          >
            Wysłane
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=SEND&status=REJECTED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.rejectedCount
              }
              topLower='Odrzucone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.rejectedCount && '#C0D6E5'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.rejectedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=SEND&status=TO_FIX'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.forRepairCount
              }
              topLower='Do naprawy'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.forRepairCount && '#EC434C'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.forRepairCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=SEND&status=FINISHED'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.finishedCount
              }
              topLower='Zakończone'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.finishedCount && '#15D55E'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.finishedCount && '#fff'
              }
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              // redirect='/housingFaultGroup?investmentType=HOUSING_COMMUNITY&type=SEND&status=VERIFICATION'
              type='dashboard-elem-empty'
              topHigher={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.verificationCount
              }
              topLower='Oczekuje na zasadność'
              topBackgroundColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.verificationCount && '#F8DF45'
              }
              topColor={
                dashboardData?.investmentsTypesData?.HOUSING_COMMUNITY
                  ?.sentFaults?.verificationCount && '#fff'
              }
            />
          </Col>
        </Row>
      </StatsWrapper>
    </Col>
  );
};

const CommunityAdminDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [SHOW_ASSOCIATIONS, SET_SHOW_ASSOCIATIONS] = useState(false);
  const [SHOW_COMMUNITIES, SET_SHOW_COMMUNITIES] = useState(false);

  useEffect(() => {
    const promises = [];

    const CommunityAdminDashboardService = new communityAdminDashboardService();

    promises.push(
      CommunityAdminDashboardService.getDashboardData().then((response) => {
        setDashboardData(response);
      })
    );

    promises.push(
      userService.getInfo().then((response) => {
        if (
          (response.roles?.includes('ROLE_COMMUNITY_ADMIN') &&
            response?.communityAdminPermissions?.includes(
              'HOUSING_ASSOCIATION'
            )) ||
          (response.roles?.includes('ROLE_WORKER') &&
            response?.workerCommunityPermissions?.housingAssociation)
        ) {
          SET_SHOW_ASSOCIATIONS(true);
        }

        if (
          (response.roles?.includes('ROLE_COMMUNITY_ADMIN') &&
            response?.communityAdminPermissions?.includes(
              'HOUSING_COMMUNITY'
            )) ||
          (response.roles?.includes('ROLE_WORKER') &&
            response?.workerCommunityPermissions?.housingCommunity)
        ) {
          SET_SHOW_COMMUNITIES(true);
        }
      })
    );

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={[20, 20]}>
          {SHOW_ASSOCIATIONS ? (
            SHOW_COMMUNITIES ? (
              <Col xs={24} xl={12}>
                <div
                  style={{
                    backgroundColor: '#F0F4F6',
                    borderRadius: 20,
                  }}
                >
                  <Row gutter={20}>
                    <Associations dashboardData={dashboardData} />
                    <AssociationsSettlements dashboardData={dashboardData} />
                    <AssociationsResolutions dashboardData={dashboardData} />
                    <AssociationsFaults dashboardData={dashboardData} />
                  </Row>
                </div>
              </Col>
            ) : (
              <>
                <Col xs={24} xl={12}>
                  <Row gutter={20}>
                    <Associations dashboardData={dashboardData} />
                    <AssociationsFaults dashboardData={dashboardData} />
                  </Row>
                </Col>
                <Col xs={24} xl={12}>
                  <Row gutter={20}>
                    <AssociationsSettlements dashboardData={dashboardData} />
                    <AssociationsResolutions dashboardData={dashboardData} />
                  </Row>
                </Col>
              </>
            )
          ) : null}

          {SHOW_COMMUNITIES ? (
            SHOW_ASSOCIATIONS ? (
              <Col xs={24} xl={12}>
                <div
                  style={{
                    backgroundColor: '#F0F4F6',
                    borderRadius: 20,
                  }}
                >
                  <Row gutter={20}>
                    <Communities dashboardData={dashboardData} />
                    <CommunitiesSettlements dashboardData={dashboardData} />
                    <CommunitiesResolutions dashboardData={dashboardData} />
                    <CommunitiesFaults dashboardData={dashboardData} />
                  </Row>
                </div>
              </Col>
            ) : (
              <>
                <Col xs={24} xl={12}>
                  <Row gutter={20}>
                    <Communities dashboardData={dashboardData} />
                    <CommunitiesFaults dashboardData={dashboardData} />
                  </Row>
                </Col>
                <Col xs={24} xl={12}>
                  <Row gutter={20}>
                    <CommunitiesSettlements dashboardData={dashboardData} />
                    <CommunitiesResolutions dashboardData={dashboardData} />
                  </Row>
                </Col>
              </>
            )
          ) : null}
        </Row>
      )}
    </>
  );
};

export default CommunityAdminDashboard;
