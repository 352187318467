import React, { useState, useEffect } from 'react';
import {
  Row, Col, Menu, Table, Typography, Tooltip, message,
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { SmallList, Icon, Price } from '../../../../components';
import faultsStatusMap from '../../../../helpers/faultGroupStatusMap';
import faultGroupService from '../../../../services/faultGroupService';

const { Text } = Typography;

const Dot = (props) => {
  const { status } = props;
  let color = 'grey';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

export const Faults = (props) => {
  const history = useHistory();
  const [faults, setFaults] = useState([]);
  const { investmentId, premisesId } = props;

  useEffect(() => {
    const FaultsService = new faultGroupService(investmentId);
    FaultsService.getAssignedFaults(premisesId).then((data) => {
      setFaults(data.content);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const FaultsService = new faultGroupService(investmentId);
    FaultsService.getAssignedFaults(premisesId).then((data) => {
      setFaults(data.content);
    });
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zakończono',
      dataIndex: 'fixDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const addNewAction = () => {
    history.push(`/faultGroup/create?premisesId=${premisesId}&investmentId=${investmentId}`);
  };

  const deleteAction = (entityId) => {
    const FaultsService = new faultGroupService(investmentId);
    FaultsService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const showAction = (
      <Menu.Item key="1" icon={<Icon name="eye-visible" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/faultGroup/${record.investmentId}/faultGroup/${record.id}/show`, state: 'createdFromInvestment' }}
          className="premises-custom-create-link"
        >
          Zobacz
        </Link>
      </Menu.Item>
    );
    tempArray.push(showAction);
    const editAction = (
      <Menu.Item key="3" icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/faultGroup/${record.investmentId}/faultGroup/${record.id}/edit`, state: 'createdFromInvestment' }}
          className="premises-custom-create-link"
        >
          Edytuj
        </Link>
      </Menu.Item>
    );
    tempArray.push(editAction);
    return tempArray;
  };

  const Summary = () => {
    if (faults.length === 0) {
      return '';
    }

    const total = faults.reduce((a, b) => a + (b.price || 0), 0);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <Text style={{ color: '#C0D5E4' }}>Suma</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text style={{ fontWeight: 'bold' }}><Price value={total} showEmpty /></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div className="faults create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList
            data={faults}
            columns={columns}
            title="Lista usterek"
            icon="fault"
            addAction={addNewAction}
            deleteAction={deleteAction}
            hideEdit
            customMenuActions={customMenuActions}
            summary={<Summary />}
          />
        </Col>
      </Row>
    </div>
  );
};
