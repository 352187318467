import React, { useState, useEffect } from 'react';
import { PageHeader, Tooltip } from 'antd';
import { Icon, Datagrid } from '../../components';
import housingTenantsService from '../../services/housingTenantsService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import memberAssociationsService from '../../services/memberAssociationsService';
import memberInvestmentPremisesService from '../../services/memberInvestmentPremisesService';
import { ChoosenAllSwitch } from '../../components/ChoosenAllSwitch';

const List = () => {
  const [loading, setLoading] = useState(true);
  const [showAllTenants, setShowAllTenants] = useState(false);
  const [investment, setInvestment] = useState();
  const [id, setId] = useState();
  const [premises, setPremises] = useState([]);
  const [filteredPremises, setFilteredPremises] = useState([]);

  const columns = [
    {
      title: 'Nazwisko',
      dataIndex: 'lastName',
      visible: true,
      filterEnabled: false,
      key: 'lastName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Imię',
      dataIndex: 'firstName',
      visible: true,
      filterEnabled: false,
      key: 'firstName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Nazwisko, imię',
      dataIndex: 'firstOrLastName',
      visible: false,
      filterEnabled: true,
      key: 'firstOrLastName',
      sorter: false,
      export: false,
    },
    {
      title: 'Alerty',
      dataIndex: 'notifications',
      visible: false,
      key: 'notifications',
      sorter: false,
      export: false,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptionsObject: true,
      filterOptions: [
        { value: 'email', label: 'E-mail' },
        { value: 'sms', label: 'SMS' },
      ],
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      visible: true,
      key: 'mobile',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <a className='datagrid-link' href={`tel:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      visible: true,
      key: 'email',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <a className='datagrid-link' href={`mailto:${text}`}>
          {text}
        </a>
      ),
    },
    {
      title: 'Spółdzielnia/Wspólnota',
      dataIndex: 'tenantInvestment',
      visible: true,
      key: 'tenantInvestment',
      sorter: true,
      filterEnabled: false,
      sortDirections: ['descend', 'ascend'],
      render: (text, source) => {
        if (source.tenantInvestments?.length !== 0) {
          const investments = source.tenantInvestments.map(
            (investment) => investment.name
          );

          const output = investments.join(', ');

          if (output.length > 35) {
            return (
              <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>
            );
          } else {
            return output;
          }
        }

        return '';
      },
      exportRender: (text, source) => {
        if (source.tenantInvestments?.length !== 0) {
          const investments = source.tenantInvestments.map(
            (investment) => investment.name
          );

          return investments.join(', ');
        }

        return '';
      },
    },
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesTypes',
      visible: false,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: [
        { value: 'RENT', label: 'Mieszkanie' },
        { value: 'STORAGE', label: 'Komórka lokatorska' },
        { value: 'PARKING', label: 'Miejsce postojowe' },
        { value: 'SERVICE', label: 'Lokal usługowy' },
      ],
      onFilterChange: (e) => {
        if (e?.length > 0) {
          setFilteredPremises(
            premises.filter((premise) => e.includes(premise.type))
          );
        } else {
          setFilteredPremises(premises);
        }
      },
      key: 'premisesTypes',
      sorter: false,
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesIds',
      visible: true,
      key: 'premisesIds',
      sorter: true,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: filteredPremises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      sortDirections: ['descend', 'ascend'],
      render: (text, source) => {
        if (source.tenantPremises?.length !== 0) {
          const premises = source.tenantPremises.map(
            (premise) =>
              `${premiseTypeMap(premise.type)} ${premise.name} (${
                premise.dateFrom
              } - ${
                premise.withoutExpireDate ? 'bezterminowo' : premise.dateTo
              })`
          );

          const output = premises.join(', ');

          if (output.length > 60) {
            return (
              <Tooltip title={output}>{output.slice(0, 60) + '...'}</Tooltip>
            );
          } else {
            return output;
          }
        }

        return '';
      },
      exportRender: (text, source) => {
        if (source.tenantPremises?.length !== 0) {
          const premises = source.tenantPremises.map(
            (premise) =>
              `${premiseTypeMap(premise.type)} ${premise.name} (${
                premise.dateFrom
              } - ${
                premise.withoutExpireDate ? 'bezterminowo' : premise.dateTo
              })`
          );

          return premises.join(', ');
        }

        return '';
      },
      requireIfSet: {
        field: 'premisesTypes',
        message: 'Jeśli wybrałeś typ lokalu wybierz lokale',
      },
    },
    // {
    //   title: 'Data od',
    //   dataIndex: 'tenantPremises',
    //   visible: true,
    //   key: 'dateFrom',
    //   sorter: true,
    //   filterEnabled: false,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, source) => {
    //     if (source.tenantPremises?.length !== 0) {
    //       const datesFrom = source.tenantPremises.map(
    //         (premise) => premise.dateFrom
    //       );

    //       const output = datesFrom.join(', ');

    //       if (output.length > 35) {
    //         return (
    //           <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>
    //         );
    //       } else {
    //         return output;
    //       }
    //     }

    //     return '';
    //   },
    //   exportRender: (text, source) => {
    //     if (source.tenantPremises?.length !== 0) {
    //       const datesFrom = source.tenantPremises.map(
    //         (premise) => premise.dateFrom
    //       );

    //       return datesFrom.join(', ');
    //     }

    //     return '';
    //   },
    // },
    {
      title: 'Data od',
      dataIndex: 'communityMemberTenantDateFrom',
      visible: false,
      key: 'communityMemberTenantDateFrom',
      sorter: false,
      filterEnabled: true,
      filterType: 'date',
      export: false,
    },
    // {
    //   title: 'Data do',
    //   dataIndex: 'tenantPremises',
    //   visible: true,
    //   key: 'dateTo',
    //   sorter: true,
    //   filterEnabled: false,
    //   filterType: 'date',
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, source) => {
    //     if (source.tenantPremises?.length !== 0) {
    //       const datesFrom = source.tenantPremises.map((premise) =>
    //         premise.withoutExpireDate ? 'bezterminowo' : premise.dateTo
    //       );

    //       const output = datesFrom.join(', ');

    //       if (output.length > 35) {
    //         return (
    //           <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>
    //         );
    //       } else {
    //         return output;
    //       }
    //     }

    //     return '';
    //   },
    //   exportRender: (text, source) => {
    //     if (source.tenantPremises?.length !== 0) {
    //       const datesFrom = source.tenantPremises.map((premise) =>
    //         premise.withoutExpireDate ? 'bezterminowo' : premise.dateTo
    //       );

    //       return datesFrom.join(', ');
    //     }

    //     return '';
    //   },
    // },
    {
      title: 'Data do',
      dataIndex: 'communityMemberTenantDateTo',
      visible: false,
      key: 'communityMemberTenantDateTo',
      sorter: false,
      filterEnabled: true,
      filterType: 'date',
      export: false,
    },
  ];

  useEffect(() => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      id
    );

    MemberInvestmentPremisesService.getList().then((data) => {
      setPremises(data.content);
      setFilteredPremises(data.content);
    });
  }, [id]);

  useEffect(() => {
    if (id) {
      const MemberAssociationsService = new memberAssociationsService();
      MemberAssociationsService.getDetails(id).then((data) => {
        setInvestment(data);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
    if (choosenInvestmentId) {
      setId(JSON.parse(choosenInvestmentId));
    }
  }, []);

  const HeaderTitle = () => {
    if (!investment?.name || showAllTenants) {
      return <span>Najemcy</span>;
    }
    return (
      <span>
        Najemcy: <span className='header-subtitle'>{investment?.name}</span>
      </span>
    );
  };

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            backIcon={<Icon name='arrow-simply-left' />}
            title={<HeaderTitle />}
          />
        }
        exportTitle={'Najemcy'}
        resource='housingTenants'
        dataProvider={housingTenantsService}
        columns={columns}
        filters={{
          role: 'ROLE_COMMUNITY_MEMBER_TENANT',
          ...(!showAllTenants && { investmentId: id }),
        }}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        customShowAllMembers={
          <ChoosenAllSwitch
            showAll={showAllTenants}
            setShowAll={setShowAllTenants}
          />
        }
        isAdmin={false}
        hideSms
        hideEmail
      />
    </div>
  );
};

export default List;
