import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import tenantPremisesService from '../../../services/tenantPremisesService';
import { Spin } from '../../../components';
import RentPremiseShow from './rent/Show';
import ServicePremiseShow from './service/Show';
import ParkingPremiseShow from './parking/Show';
import StoragePremiseShow from './storage/Show';

const Show = ({ userData }) => {
  const { id, premisesId } = useParams();
  const [loading, setLoading] = useState(true);
  const [premise, setPremise] = useState({});

  useEffect(() => {
    const TenantPremisesService = new tenantPremisesService(id);
    TenantPremisesService.getOne(premisesId).then((data) => {
      setPremise(data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className='show-layout investments-show'>
        <div className='loading'>
          <Spin />
        </div>
      </div>
    );
  } else {
    if (premise.type === 'RENT') {
      return <RentPremiseShow premise={premise} userData={userData} />;
    } else if (premise.type === 'SERVICE') {
      return <ServicePremiseShow premise={premise} userData={userData} />;
    } else if (premise.type === 'PARKING') {
      return <ParkingPremiseShow premise={premise} userData={userData} />;
    } else if (premise.type === 'STORAGE') {
      return <StoragePremiseShow premise={premise} userData={userData} />;
    }

    return <></>;
  }
};

export default Show;
