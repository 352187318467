import BaseService from './baseService';
import httpService from '../helpers/httpService';

class SettlementPaymentsService extends BaseService {
  constructor(investmentId, premisesId, settlementId) {
    super();
    this.url = `/api/user/community/investment/${investmentId}/premises/${premisesId}/settlements/${settlementId}/payments`;
  }

  async editPayment(paymentId, data) {
    return httpService.patch(`${this.url}/${paymentId}`, data, true, true);
  }
}

export default SettlementPaymentsService;
