import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '../../../components/Icon';
import tenantSettlementsService from '../../../services/tenantSettlementsService';

const BalanceBox = ({ balance, tenantBalance = false, showPay = true }) => {
  const history = useHistory();
  const { id } = useParams();

  const payTheBalance = () => {
    tenantSettlementsService.payBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/tenantSettlements/null/payment/${response.id}/show`
        );
      }
    });
  };

  return (
    <div
      className={`balance-box ${
        balance > 0 ? 'balance-box-red' : 'balance-box-green'
      }`}
    >
      <div className='content-wrapper'>
        <div className='value-wrapper'>
          {parseFloat(-balance).toFixed(2).replace('.', ',')} zł
        </div>
        <div className='title-wrapper'>
          {tenantBalance ? 'Saldo najemców' : 'Saldo'}
        </div>
      </div>
      <div className='buttons-wrapper'>
        <div>
          <Button
            className='history-button'
            onClick={() => {
              if (id) {
                history.push(`/housingTenants/${id}/settlements`);
              } else {
                history.push('/tenantSettlements');
              }
            }}
          >
            Historia transakcji
          </Button>
        </div>
        {showPay && balance > 0 && (
          <div className='bottom-button-wrapper'>
            <Button className='balance-button' onClick={() => payTheBalance()}>
              Opłać Saldo
              <Icon name='payments' style={{ marginLeft: 5 }} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceBox;
