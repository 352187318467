const repeatabilityMap = {
  MONTH: 'Co 1 miesiąc',
  TWO_MONTHS: 'Co 2 miesiące',
  THREE_MONTHS: 'Co 3 miesiące',
  HALF_YEAR: 'Co 6 miesięcy',
  YEAR: 'Co 12 miesięcy',
  // TWO_YEAR: 'Co 2 lata',
};

const getRepeatabilityStatus = (repeatability) =>
  repeatabilityMap[repeatability] || '';

export default getRepeatabilityStatus;
