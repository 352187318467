import BaseService from './baseService';
import httpService from '../helpers/httpService';

const PackageService = new BaseService('/api/packages');

PackageService.getList = async (params = {}) => {
  const { pagination, sortField, sortOrder, ...queryParams } = params;

  if (pagination) {
    if (pagination.current) {
      queryParams.page = pagination.current - 1;
    }

    if (pagination.pageSize) {
      queryParams.size = pagination.pageSize;
    }
  }

  if (sortField && sortOrder) {
    const order = sortOrder === 'descend' ? 'DESC' : 'ASC';
    queryParams.sort = `${sortField},${order}`;
  } else {
    queryParams.sort = 'id,DESC';
  }

  const response = await httpService.get(PackageService.url, queryParams, true);

  return response;
};

PackageService.getPremisesCommunityCount = async () =>
  httpService.get(
    '/api/vendor/investment/statistics/premisesCommunityCount',
    null,
    true
  );

PackageService.updateAsAdmin = async (userId, packageId, data) =>
  httpService.put(
    `/api/admin/user/${userId}/packages/${packageId}`,
    data,
    true
  );

export default PackageService;
