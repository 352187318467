import httpService from '../helpers/httpService';

const settlementsImportsService = {};

settlementsImportsService.getImports = async () =>
  httpService.get(`/api/community/settlements/import`, null, true);

settlementsImportsService.import = async (data, config = {}) =>
  httpService.post(
    `/api/community/settlements/import`,
    data,
    true,
    {
      'content-type': 'multipart/form-data',
    },
    config
  );

export default settlementsImportsService;
