import BaseService from './baseService';
import httpService from '../helpers/httpService';

class ResolutionsService extends BaseService {
  constructor(investmentId) {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles?.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/investment/${investmentId}/resolutions`;
    } else if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/investment/${investmentId}/resolutions`;
    }
  }

  async changeStatus(resolutionId, status) {
    return httpService.patch(`${this.url}/${resolutionId}`, { status }, true);
  }

  async vote(investmentId, resolutionId, voteType) {
    return httpService.post(
      `/api/community/investment/${investmentId}/resolutions/${resolutionId}/votes`,
      { voteType },
      true
    );
  }
}

export default ResolutionsService;
