import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Tooltip, Menu, Dropdown } from 'antd';
import Datagrid from '../../components/Datagrid';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import tenantSettlementsService from '../../services/tenantSettlementsService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import { ChoosenAllSwitch } from './components/ChoosenAllSwitch';
import TransferInfoModal from './components/TransferInfoModal';
import tenantPremisesSettlementsService from '../../services/tenantPremisesSettlementsService';
import tenantPremisesService from '../../services/tenantPremisesService';
import memberTenantAssociationsService from '../../services/memberTenantAssociationsService';

const RenderStatus = (status) => {
  if (status === 'TO_PAY') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const getRowClassName = (record, index) => {
  if (record.status === 'TO_PAY') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const List = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [id, setId] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [settlementData, setSettlementData] = useState({});
  const [recordData, setRecordData] = useState();
  const [premises, setPremises] = useState([]);
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filterInvestmentId, setFilterInvestmentId] = useState();

  useEffect(() => {
    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();
    MemberTenantAssociationsService.getList({
      types: ['HOUSING_ASSOCIATION', 'HOUSING_COMMUNITY'],
    }).then((data) => {
      setInvestments(data.content);
    });
  }, []);

  useEffect(() => {
    if (showAll) {
      if (filterInvestmentId) {
        const TenantPremisesService = new tenantPremisesService(
          filterInvestmentId
        );
        TenantPremisesService.getList().then((response) => {
          setInvestmentPremises(response.content);
          setPremises(response.content);
        });
      } else {
        setInvestmentPremises([]);
        setPremises([]);
      }
    } else {
      const TenantPremisesService = new tenantPremisesService(id);
      TenantPremisesService.getList().then((response) => {
        setInvestmentPremises(response.content);
        setPremises(response.content);
      });
    }

    setLoading(false);
  }, [id, filterInvestmentId, showAll]);

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
    if (choosenInvestmentId) {
      setId(JSON.parse(choosenInvestmentId));
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    tenantSettlementsService.getBalance().then((response) => {
      if (response) {
        if (response.balance) {
          setBalance(response.balance);
        }
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    tenantSettlementsService.getBalance().then((response) => {
      if (response) {
        if (response.balance) {
          setBalance(response.balance);
        }
      }
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (recordData) {
      const TenantPremisesSettlementsService =
        new tenantPremisesSettlementsService(
          recordData.investmentId,
          recordData.premisesId
        );

      TenantPremisesSettlementsService.getOne(recordData.id).then(
        (response) => {
          setSettlementData(response);
          setIsModalVisible(true);
          setLoading(false);
        }
      );
    }
  }, [recordData]);

  const columns = [
    {
      title: 'Nazwa rozliczenia',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      filterFullWidth: !showAll,
      key: 'name',
      sorter: true,
      export: true,
      sortDirections: ['descend', 'ascend'],
    },
    ...(showAll
      ? [
          {
            title: 'Nieruchomość',
            dataIndex: 'investmentIds',
            visible: true,
            key: 'investmentIds',
            sorter: false,
            filterEnabled: true,
            filterType: 'select',
            filterOptions: investments.map((investment) => ({
              value: investment.id,
              label: investment.name,
            })),
            onFilterChange: (e) => setFilterInvestmentId(e),
            export: true,
            render: (text, source) => {
              return source.investmentName;
            },
            exportRender: (text, source) => {
              return source.investmentName;
            },
          },
        ]
      : []),
    {
      title: 'Lokal',
      dataIndex: 'premises',
      visible: true,
      filterEnabled: false,
      key: 'premises',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
      render: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
    },
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesType',
      visible: false,
      key: 'premisesType',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'RENT',
          label: 'Mieszkanie',
        },
        {
          value: 'STORAGE',
          label: 'Komórka lokatorska',
        },
        {
          value: 'PARKING',
          label: 'Miejsce postojowe',
        },
        {
          value: 'SERVICE',
          label: 'Lokal usługowy',
        },
      ],
      onFilterChange: (e) => {
        setPremises(
          investmentPremises.filter((premise) => {
            if (!e) {
              return true;
            } else {
              return premise.type === e;
            }
          })
        );
      },
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesIds',
      visible: false,
      key: 'premisesIds',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: premises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      export: false,
      requireIfSet: {
        field: 'premisesType',
        message: 'Jeśli wybrałeś typ lokalu wybierz lokale',
      },
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      visible: true,
      filterEnabled: false,
      key: 'createDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterFullWidth: true,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'PAID',
          label: (
            <span>
              <span className={`dot green`} style={{ marginRight: 8 }} />
              Zapłacone
            </span>
          ),
        },
        {
          value: 'PARTIALLY_PAID',
          label: (
            <span>
              <span className={`dot yellow`} style={{ marginRight: 8 }} />
              Częściowo zapłacone
            </span>
          ),
        },
        {
          value: 'TO_PAY',
          label: (
            <span>
              <span className={`dot red`} style={{ marginRight: 8 }} />
              Do zapłaty
            </span>
          ),
        },
      ],
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
      render: (text) => (text ? RenderStatus(text) : ''),
    },
    {
      title: 'Data dodania od',
      dataIndex: 'createDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateFrom',
      export: false,
    },
    {
      title: 'Data dodania do',
      dataIndex: 'createDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateTo',
      export: false,
    },
    {
      title: 'Termin płatności',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paidDate',
      visible: false,
      filterEnabled: false,
      key: 'paidDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Termin płatności od',
      dataIndex: 'paymentDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateFrom',
      export: false,
    },
    {
      title: 'Termin płatności do',
      dataIndex: 'paymentDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateTo',
      export: false,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'price',
      visible: true,
      filterEnabled: false,
      key: 'price',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  const payTheBalance = () => {
    tenantSettlementsService.payBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/tenantSettlements/null/payment/${response.id}/show`
        );
      }
    });
  };

  const afterCancel = () => {
    setRecordData();
    setIsModalVisible(false);
    setSettlementData({});
  };

  const menu = (record) => (
    <Menu style={{ paddingTop: 8, paddingBottom: 8, width: 160 }}>
      <Menu.Item
        key='1'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
      >
        <Link
          to={`/investment/${record.investmentId}/premises/${record.premisesId}/tenantSettlements/${record.id}/payment/${record.paymentId}/show`}
          className='premises-custom-create-link'
        >
          Zapłać przez tPay
        </Link>
      </Menu.Item>

      <Menu.Item
        key='2'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
        onClick={() => {
          setRecordData(record);
          setIsModalVisible(true);
        }}
      >
        Tradycyjny przelew
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <TransferInfoModal
            settlementData={settlementData}
            isModalVisible={isModalVisible}
            afterCancel={afterCancel}
          />
          <Datagrid
            title='Lista rozliczeń'
            customBaseUrlKey={'tenantSettlements'}
            dataProvider={tenantSettlementsService}
            columns={columns}
            customShowAllMembers={
              <ChoosenAllSwitch showAll={showAll} setShowAll={setShowAll} />
            }
            customCreate={
              balance && (
                <Button
                  style={{
                    height: 48,
                    width: 245,
                  }}
                  type='danger'
                  size='large'
                  onClick={() => payTheBalance()}
                >
                  <Icon style={{ marginRight: 5 }} name='payments' />
                  Opłać saldo: - {parseFloat(balance).toFixed(2)} zł
                </Button>
              )
            }
            customRowActions={[
              (record) => {
                if (record.paid < record.price) {
                  return (
                    <Dropdown
                      overlay={menu(record)}
                      overlayClassName='header-menu-wrapper'
                      trigger='click'
                      placement='bottomRight'
                    >
                      <Icon
                        style={{ cursor: 'pointer' }}
                        name='dolar'
                        className='settlement-transaction-icon'
                      />
                    </Dropdown>
                  );
                }
              },
            ]}
            filters={{
              ...(!showAll && { investmentIds: id }),
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            customRowClass={getRowClassName}
            hideCreate
            hideEdit
            hideReset
            hideSms
            hideEmail
            hideDelete
          />
        </div>
      )}
    </div>
  );
};

export default List;
