import BaseService from './baseService';
import httpService from '../helpers/httpService';

class TPayService extends BaseService {
  constructor() {
    super();
    this.url = '/api/payment';
  }

  async getTpayResponse(paymentId, methodId) {
    return httpService.post(`${this.url}/${paymentId}/transaction/${methodId}`, {}, true);
  }
}

export default TPayService;
