/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Tabs as AntdTabs, Grid } from 'antd';

const Tabs = (props) => {
  const { useBreakpoint } = Grid;
  const [position, setPosition] = useState('left');

  const screens = useBreakpoint();
  useEffect(() => {
    if (screens.xs === true || screens.sm === true) {
      setPosition('top');
    }
    if (screens.xl === true) {
      setPosition('left');
    }
  }, [screens]);
  const { children, defaultActiveKey } = props;

  return (
    <AntdTabs defaultActiveKey={defaultActiveKey} tabPosition={position}>
      {children}
    </AntdTabs>
  );
};

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;
