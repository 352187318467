import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import moment from 'moment';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import memberInvestmentPremisesService from '../../../../services/memberInvestmentPremisesService';
import TenantsModal from './TenantsModal';
import MessagePopup from '../../../../components/MessagePopup';

export const Tenants = ({ investmentId, premisesId }) => {
  const [activeRents, setActiveRents] = useState([]);
  const [rents, setRents] = useState([]);
  const [endedRents, setEndedRents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingRent, setEditingRent] = useState();

  useEffect(() => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.getTenants(investmentId, premisesId).then(
      (data) => {
        setRents(data);

        const tempEndedRents = [];
        const tempActiveRents = [];

        if (data?.length !== 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].withoutExpireDate) {
              tempActiveRents.push(data[i]);
              continue;
            }

            if (data[i].dateTo) {
              if (
                moment(new Date(data[i].dateTo), 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                ) < moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
              ) {
                tempEndedRents.push(data[i]);
                continue;
              }
            }
            tempActiveRents.push(data[i]);
          }
        }

        setEndedRents(tempEndedRents);
        setActiveRents(tempActiveRents);
      }
    );
  }, [investmentId, premisesId, showModal]);

  const refresh = () => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );

    MemberInvestmentPremisesService.getTenants(investmentId, premisesId).then(
      (data) => {
        setRents(data);

        const tempEndedRents = [];
        const tempActiveRents = [];

        if (data?.length !== 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].withoutExpireDate) {
              tempActiveRents.push(data[i]);
              continue;
            }

            if (data[i].dateTo) {
              if (
                moment(new Date(data[i].dateTo), 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                ) < moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD')
              ) {
                tempEndedRents.push(data[i]);
                continue;
              }
            }
            tempActiveRents.push(data[i]);
          }
        }

        setEndedRents(tempEndedRents);
        setActiveRents(tempActiveRents);
      }
    );
  };

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: ['firstName', 'lastName'],
      editable: false,
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'Data od',
      dataIndex: 'dateFrom',
      editable: true,
    },
    {
      title: 'Data do',
      dataIndex: 'dateTo',
      editable: true,
      render: (text, record) => {
        if (record.withoutExpireDate) {
          return <span style={{ color: '#B1CADE' }}>bezterminowo</span>;
        }

        return text;
      },
    },
  ];

  const deleteAction = (entityId) => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.deleteTenant(
      investmentId,
      premisesId,
      entityId
    )
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const updateAction = (entityId, data) => {
    const tenant = rents.find((el) => el.id === entityId);

    const tempData = { ...tenant };

    if (data.dateFrom) {
      tempData.dateFrom = data.dateFrom;
    }
    if (data.dateTo) {
      if (data.dateTo === 'bezterminowo') {
        tempData.dateTo = null;
        tempData.withoutExpireDate = true;
      } else {
        tempData.dateTo = data.dateTo;
        tempData.withoutExpireDate = false;
      }
    }

    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );
    MemberInvestmentPremisesService.updateTenant(
      investmentId,
      premisesId,
      entityId,
      tempData
    )
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setShowModal(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <TenantsModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
          setEditingRent(null);
        }}
        editingRent={editingRent}
        refresh={refresh}
      />
      <Row>
        <Col xs={20} offset={2}>
          <div style={{ marginBottom: 50 }}>
            <SmallList
              tableClassName={activeRents.length !== 0 && 'contacts-table'}
              data={activeRents}
              columns={columns}
              title='Lista najemców'
              deleteAction={deleteAction}
              updateAction={updateAction}
              editingAction={(record) => {
                setEditingRent(record);
                setShowModal(true);
              }}
              customAddButton={<CustomAddButton />}
              hideEdit
            />
          </div>
          <div>
            <SmallList
              tableClassName={
                endedRents.length !== 0 && 'contacts-table white-table'
              }
              data={endedRents}
              columns={columns}
              title='Zakończone wynajmy'
              deleteAction={deleteAction}
              updateAction={updateAction}
              editingAction={(record) => {
                setEditingRent(record);
                setShowModal(true);
              }}
              hideEdit
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
