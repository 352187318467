import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import Icon from '../../components/Icon';
import memberAssociationsService from '../../services/memberAssociationsService';
import userService from '../../services/userService';
import investmentHeatSourceMap from '../../helpers/investmentHeatSourceMap';
import Spin from '../../components/Spin';
import { ContactsInvestmentBox } from './showComponents/ContactsInvestmentBox';
import { WorkersInvestmentBox } from './showComponents/WorkersInvestmentBox';

const Address = ({ title, address }) => (
  <>
    {!!address && (
      <div className='address'>
        <div className='title'>{title}:</div>
        <div className='place'>
          {`ul. ${address.street} ${address.number || ''}${
            address.local ? `/${address.local}` : ','
          }`}
        </div>
        <div className='city'>{`${address.zip} ${address.city}, ${address.country}`}</div>
      </div>
    )}
  </>
);

const Show = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [developer, setDeveloper] = useState(false);
  const [communityAdmin, setCommunityAdmin] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();
    MemberAssociationsService.getDetails(id).then((data) => {
      setInvestment(data);

      if (data.communityAdminId) {
        MemberAssociationsService.getCommunityAdmin(id).then((response) => {
          setCommunityAdmin(response);
        });
      }

      if (data.developer) {
        setDeveloper(data.developer);
      } else if (data.developerId) {
        MemberAssociationsService.getDeveloper(id).then((response) => {
          setDeveloper(response);
        });
      }
    });

    userService.getInfo().then((response) => {
      setUserData(response);

      setLoading(false);
    });
  }, [id]);

  return (
    <div className='show-layout investments-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <PageHeader
            ghost={false}
            onBack={() => history.push('/memberCommunities')}
            backIcon={<Icon name='arrow-simply-left' />}
            title={investment.name}
            className='page-header-extra-2'
            extra={[
              <Button
                key='1'
                className='button-secondary'
                style={{ minWidth: 0, maxWidth: 48 }}
                onClick={() => window.print()}
              >
                <Icon name='print' style={{ paddingLeft: 0 }} />
              </Button>,
              canEdit && (
                <Link key='2' to={`/memberCommunities/${investment.id}/edit`}>
                  <Button type='primary'>
                    Edytuj <Icon name='edit' />
                  </Button>
                </Link>
              ),
            ]}
          >
            <Card className='investment-show-card'>
              <Row justify={'center'} gutter={[20, 32]}>
                <Col sm={24} xl={12} style={{ width: '90%' }}>
                  <div className='left investments-show-left'>
                    <div className='logo'>
                      <img
                        src={`${baseUrl}${investment.logoFile}`}
                        alt={investment.name}
                        style={{ borderRadius: '50%' }}
                      />
                    </div>
                    <div className='investment-type-name'>Spółdzielnia</div>
                    <div className='name'>{investment.name}</div>
                    <div className='buttons'>
                      <div className='call'>
                        <a href={`tel:${communityAdmin.mobile}`}>
                          <Button type='primary' size='large'>
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>

                      <div className='write'>
                        <a href={`mailto:${communityAdmin.email}`}>
                          <Button type='primary' size='large'>
                            <Icon name='email' /> Napisz
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={24} xl={12}>
                  <div className='right investments-show-right'>
                    <Card
                      title={
                        <h2 className='show-layout-card-header'>
                          <Icon name='more-info' />
                          Informacje
                        </h2>
                      }
                      className='informations'
                    >
                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Nazwa:</div>
                          <div className='idNumber'>{investment.name}</div>
                        </Col>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Rok powstania:</div>
                          <div className='idNumber'>
                            {investment.createYear}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Powierzchnia całkowita:</div>
                          <div className='idNumber'>
                            {investment.size} m<sup>2</sup>
                          </div>
                        </Col>
                        {investment.heatSource && (
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <div className='title'>Źródło ciepła:</div>
                            <div>
                              {investmentHeatSourceMap(investment.heatSource)}
                            </div>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <Address
                            title='Adres inwestycji'
                            address={investment.address}
                          />
                        </Col>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Dane do rozliczeń:</div>
                          <div className='idNumber'>
                            NIP: {investment.nip ?? '-'}
                          </div>
                          <div className='idNumber'>
                            Regon: {investment.regon ?? '-'}
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  {communityAdmin && (
                    <div className='right investments-show-right'>
                      <Card
                        title={
                          <h2 className='show-layout-card-header'>
                            <Icon name='administrator-nieruchomosci' />
                            Administrator nieruchomości
                          </h2>
                        }
                        className='informations'
                      >
                        <Row>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <div className='title'>Dane firmowe:</div>
                            <div className='idNumber'>
                              {communityAdmin.name}
                            </div>
                            <div className='idNumber'>
                              NIP: {communityAdmin.nip}
                            </div>
                          </Col>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <div className='title'>Dane kontaktowe:</div>
                            <div className='idNumber'>
                              <a
                                className='show-link'
                                href={`mailto:${communityAdmin.email}`}
                              >
                                {communityAdmin.email}
                              </a>
                            </div>
                            <div className='idNumber'>
                              <a
                                className='show-link'
                                href={`tel:${communityAdmin.mobile}`}
                              >
                                {communityAdmin.mobile}
                              </a>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <Address
                              title='Adres siedziby'
                              address={communityAdmin.address}
                            />
                          </Col>
                          {communityAdmin.correspondenceAddress && (
                            <Col
                              xs={24}
                              sm={12}
                              style={{ marginBottom: '20px' }}
                            >
                              <Address
                                title='Adres korespondencyjny'
                                address={communityAdmin.correspondenceAddress}
                              />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </div>
                  )}
                  {developer && (
                    <div className='right investments-show-right'>
                      <Card
                        title={
                          <h2 className='show-layout-card-header'>
                            <Icon name='developer' />
                            Deweloper
                          </h2>
                        }
                        className='informations'
                      >
                        <Row>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <div className='title'>Dane firmowe:</div>
                            <div className='idNumber'>{developer.name}</div>
                            <div className='idNumber'>NIP: {developer.nip}</div>
                          </Col>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <div className='title'>Dane kontaktowe:</div>
                            <div className='idNumber'>
                              <a
                                className='show-link'
                                href={`mailto:${developer.email}`}
                              >
                                {developer.email}
                              </a>
                            </div>
                            {developer.mobile && (
                              <div className='idNumber'>
                                <a
                                  className='show-link'
                                  href={`tel:${developer.mobile}`}
                                >
                                  {developer.mobile}
                                </a>
                              </div>
                            )}
                            {developer.phone && (
                              <div className='idNumber'>
                                <a
                                  className='show-link'
                                  href={`tel:${developer.phone}`}
                                >
                                  {developer.phone}
                                </a>
                              </div>
                            )}
                            {developer.website && (
                              <div className='idNumber'>
                                {developer.website}
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <Address
                              title='Adres siedziby'
                              address={developer.address}
                            />
                          </Col>
                          {developer.correspondenceAddress && (
                            <Col
                              xs={24}
                              sm={12}
                              style={{ marginBottom: '20px' }}
                            >
                              <Address
                                title='Adres korespondencyjny'
                                address={developer.correspondenceAddress}
                              />
                            </Col>
                          )}
                        </Row>
                      </Card>
                    </div>
                  )}

                  <WorkersInvestmentBox investmentId={id} />
                  <ContactsInvestmentBox investmentId={id} />
                </Col>
              </Row>
            </Card>
          </PageHeader>
        </>
      )}
    </div>
  );
};

export default Show;
