import React from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import tagsService from '../../../services/tagsService';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';

const Popup = (props) => {
  const {
    data, afterSave, afterCancel,
  } = props;
  const [form] = Form.useForm();

  if (data?.id) {
    form.setFieldsValue(data);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const requestValues = {
          ...values,
          type: 'TENANT_CHANGE',
        };
        tagsService.create(requestValues).then((response) => {
          afterSave(response);
        }).catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = 'Dodaj nowy tag';

  const { isModalVisible } = props;
  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={400}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size="large" placeholder="Nazwa" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Popup;
