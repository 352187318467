import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, Card, message } from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import housingAssociationsService from '../../services/housingAssociationsService';
import documentsService from '../../services/documentsService';
import { Icon, Spin, Input } from '../../components';
import { Attachments } from './grids/Attachments';
import MessagePopup from '../../components/MessagePopup';
import ProgressModal from '../../components/ProgressModal';

const { DatePicker, SearchBox } = Input;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const investmentId = query.get('investmentId') || null;
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    setLoading(false);
    form.setFieldValue('investmentId', investmentId);
  }, []);

  const onFinish = (values) => {
    const formData = new FormData();

    if (documents.length) {
      formData.append('attachment', documents[0].originFileObj);

      const DocumentsService = new documentsService(values.investmentId);

      setProgressModalVisible(true);

      DocumentsService.create(formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
        .then((response) => {
          setProgressModalVisible(false);
          message.success(
            <MessagePopup type={'success'}>
              Pomyślnie dodano załącznik
            </MessagePopup>
          );

          if (response.id) {
            history.replace(
              `/investment/${response.investmentId}/documents/${response.id}/edit`
            );
          }
        })
        .catch((err) => {
          setProgressModalVisible(false);

          if (err && err.message === 'demo') {
            message.error(
              <MessagePopup type={'error'}>
                Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
              </MessagePopup>
            );
          }
        });
    } else {
      message.error(
        <MessagePopup type={'error'}>Nie załączono pliku</MessagePopup>
      );
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleBack = () => {
    return history.goBack();
  };

  return (
    <div className='form-create'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => handleBack()}
          title='Dodaj dokument'
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            initialValues={{
              addDate: moment(),
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <>
              <Card className='form-create-main-card'>
                <div className='create-form-wrapper'>
                  <Row>
                    <Col xs={20} offset={2}>
                      <div className='form-section'>
                        <Attachments
                          documents={documents}
                          setDocuments={setDocuments}
                        />
                      </div>

                      <div className='form-section'>
                        <Row gutter={20} style={{ marginTop: 80 }}>
                          <Col xs={24} sm={12}>
                            <Form.Item name='addDate'>
                              <DatePicker
                                size='large'
                                placeholder='Data dodania'
                                style={{ width: '100%' }}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='investmentId'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <SearchBox
                                placeholder='Spółdzielnia/Wspólnota'
                                size='large'
                                resource={new housingAssociationsService()}
                                filters={{
                                  types: [
                                    'HOUSING_ASSOCIATION',
                                    'HOUSING_COMMUNITY',
                                  ],
                                }}
                                resourceField='name'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleBack}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      Dodaj
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
