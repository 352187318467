import BaseService from './baseService';

class SubCountersService extends BaseService {
  constructor(investmentId, mainCounterId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/mainCounters/${mainCounterId}/subCounters`;
  }
}

export default SubCountersService;
