import React, { useState, useEffect, useRef } from 'react';
import { Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import Routes from './Routes';
import Address from './logged/Address';
import LoggedHeader from './logged/Header';
import LoggedMenu from './logged/Menu';
import userService from '../services/userService';
import rodoService from '../services/rodoService';
import ShouldDisableSite from './logged/ShouldDisableSite';
import AccountDisabled from './logged/AccountDisabled';
import RodoPopup from './logged/RodoPopup';
import FirstLoginPopup from './logged/FirstLoginPopup';
import housingAssociationsService from '../services/housingAssociationsService';
import memberAssociationsService from '../services/memberAssociationsService';
import memberTenantAssociationsService from '../services/memberTenantAssociationsService';

const { Sider, Content } = Layout;

const LoggedLayout = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const isDemo = process.env.REACT_APP_DEMO_MODE === 'true';
  const location = useLocation();
  const { pathname } = location;
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(0);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [accountDisabled, setAccountDisabled] = useState(false);
  const [showRodoPopup, setShowRodoPopup] = useState(false);
  const [rodoData, setRodoData] = useState(false);
  const [showFirstLoginPopup, setShowFirstLoginPopup] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [memberAssociations, setMemberAssociations] = useState([]);
  const [memberCommunities, setMemberCommunities] = useState([]);
  const [tenantAssociations, setTenantAssociations] = useState([]);
  const [tenantCommunities, setTenantCommunities] = useState([]);

  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  };

  const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  useEffect(() => {
    userService
      .getInfo()
      .then((data) => {
        setUserData(data);
        const { roles } = data;
        localStorage.setItem('roles', JSON.stringify(roles));
        const { testAccount } = data;
        localStorage.setItem('testAccount', JSON.stringify(testAccount));
        setLoading(false);
        if (
          data.roles.includes('ROLE_WORKER') ||
          data.roles.includes('ROLE_TENANT') ||
          data.roles.includes('ROLE_DEVELOPER')
        ) {
          setCookie('ignoreRodo', true, 1);
        }
        const ignoreRodo = getCookie('ignoreRodo');
        if (
          (data.roles.includes('ROLE_CLIENT') ||
            data.roles.includes('ROLE_CLIENT_TENANT')) &&
          !ignoreRodo
        ) {
          rodoService.verify().then((rodoResponse) => {
            if (rodoResponse.content === null) {
              return;
            }
            setRodoData(rodoResponse);
            if (rodoResponse.isAccepted === false) {
              setShowRodoPopup(true);
            }
          });
        }
        if (
          (data.roles.includes('ROLE_COMMUNITY_ADMIN') ||
            data.roles.includes('ROLE_COMMUNITY_MEMBER') ||
            (data.roles.includes('ROLE_WORKER') &&
              data.ownerRoles.includes('ROLE_COMMUNITY_ADMIN')) ||
            data.roles.includes('ROLE_COMMUNITY_MEMBER_TENANT')) &&
          data.isPasswordChanged === false
        ) {
          setShowFirstLoginPopup(true);
        }
      })
      .catch(() => {
        // account disabled
        setAccountDisabled(true);
      });
  }, []);

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.getList()
      .then((data) => {
        setInvestments(data.content);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();
    MemberAssociationsService.getList({
      types: 'HOUSING_ASSOCIATION',
    })
      .then((data) => {
        setMemberAssociations(data.content);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();
    MemberAssociationsService.getList({
      types: 'HOUSING_COMMUNITY',
    })
      .then((data) => {
        setMemberCommunities(data.content);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();
    MemberTenantAssociationsService.getList({
      types: 'HOUSING_ASSOCIATION',
    })
      .then((data) => {
        setTenantAssociations(data.content);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();
    MemberTenantAssociationsService.getList({
      types: 'HOUSING_COMMUNITY',
    })
      .then((data) => {
        setTenantCommunities(data.content);
      })
      .catch((err) => {});
  }, []);

  const shouldShowMenu = (
    availableRoles,
    additionalWorkerPermissions = null,
    ownerRole = null,
    workerCommunityPermissions = null,
    housingPremises = false,
    memberInvestment = false,
    tenantInvestment = false
  ) => {
    // If tenantInvestment check if al least one exist
    if (tenantInvestment) {
      if (tenantInvestment === 'HOUSING_ASSOCIATION') {
        if (!tenantAssociations?.length) {
          return false;
        }
      } else if (tenantInvestment === 'HOUSING_COMMUNITY') {
        if (!tenantCommunities?.length) {
          return false;
        }
      }
    }

    // If memberInvestment check if al least one exist
    if (memberInvestment) {
      if (memberInvestment === 'HOUSING_ASSOCIATION') {
        if (!memberAssociations?.length) {
          return false;
        }
      } else if (memberInvestment === 'HOUSING_COMMUNITY') {
        if (!memberCommunities?.length) {
          return false;
        }
      }
    }

    // If path is housingPremises, check if user has at least one investment
    if (housingPremises) {
      if (!investments.length) {
        return false;
      }
    }

    if (!userData.roles) {
      return false;
    }
    if (
      ownerRole &&
      // userData.ownerRoles &&
      userData.roles.includes('ROLE_WORKER')
    ) {
      if (!userData.ownerRoles) {
        return false;
      }

      const { ownerRoles } = userData;
      if (!ownerRoles.includes(ownerRole)) {
        return false;
      }
    }

    // Worker investments and choosen investments logic
    if (workerCommunityPermissions) {
      if (!userData.workerCommunityPermissions) {
        return false;
      }

      let shouldShow = false;

      for (let idx in workerCommunityPermissions) {
        const permission = workerCommunityPermissions[idx];

        if (Array.isArray(userData.workerCommunityPermissions[permission])) {
          if (userData.workerCommunityPermissions[permission].length !== 0) {
            return true;
          }
        } else if (userData.workerCommunityPermissions[permission]) {
          shouldShow = true;
        }
      }

      if (!shouldShow) {
        return false;
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const index in availableRoles) {
      if (userData.roles.includes(availableRoles[index])) {
        if (
          availableRoles[index] === 'ROLE_WORKER' &&
          additionalWorkerPermissions != null
        ) {
          if (userData.workerPermissions === null) {
            return false;
          }
          return userData.workerPermissions.includes(
            additionalWorkerPermissions
          );
        }

        if (
          availableRoles[index] === 'ROLE_COMMUNITY_ADMIN' &&
          additionalWorkerPermissions != null
        ) {
          if (userData.communityAdminPermissions === null) {
            return false;
          }
          return userData.communityAdminPermissions.includes(
            additionalWorkerPermissions
          );
        }

        return true;
      }
    }
    return false;
  };

  const handleBreakpoint = (broken) => {
    if (broken) {
      setCollapsedWidth(0);
      setCollapsed(true);
      return;
    }

    setCollapsedWidth(30);
  };

  const collapseOnMobile = () => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    collapseOnMobile();
  }, [pathname]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        try {
          const eventClassNames = event.target.className || '';
          if (
            ref.current &&
            !ref.current.contains(event.target) &&
            eventClassNames &&
            eventClassNames.indexOf('custom-menu-trigger-action') === -1
          ) {
            collapseOnMobile();
          }
        } catch (e) {
          console.log(e);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <Layout
        className={`logged-layout-container ${isDemo ? 'demo-mode' : ''}`}
      >
        <Sider
          width={236}
          collapsedWidth={collapsedWidth}
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint='md'
          onBreakpoint={(broken) => handleBreakpoint(broken)}
        >
          {!loading && (
            <div ref={wrapperRef}>
              <div className='menu-header'>
                <Link to='/myAccount' className='logo'>
                  <div className='border-wrapper'>
                    {!isDemo ? (
                      <div
                        className='image-wrapper'
                        style={{
                          backgroundImage: `url(${baseUrl}${userData.avatar})`,
                        }}
                      />
                    ) : (
                      <div className='image-wrapper' />
                    )}
                  </div>
                </Link>
                <div className='text'>
                  <div className='title'>
                    {userData.name
                      ? userData.name
                      : `${userData.firstName} ${userData.lastName}`}
                  </div>
                  <div className='phone'>{userData.phone}</div>
                  <div className='mail'>{userData.email}</div>
                  <Address address={userData.address} />
                </div>
              </div>

              {userData.testAccount ? (
                <div
                  style={{
                    marginBottom: 20,
                    textAlign: 'center',
                  }}
                >
                  <span
                    style={{
                      backgroundColor: 'rgb(236, 67, 76)',
                      color: '#ffffff',
                      padding: 5,
                      paddingLeft: 15,
                      paddingRight: 15,
                      borderRadius: 5,
                    }}
                  >
                    TEST MODE
                  </span>
                </div>
              ) : null}

              <LoggedMenu shouldShowMenu={shouldShowMenu} />
            </div>
          )}
        </Sider>
        <Layout className='site-layout'>
          <LoggedHeader
            shouldShowMenu={shouldShowMenu}
            userData={userData}
            setUserData={setUserData}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
          <Content
            className='site-layout-background'
            style={{
              minHeight: 280,
            }}
          >
            <Routes userData={userData} />
          </Content>
        </Layout>
      </Layout>
      <ShouldDisableSite userData={userData} />
      {showRodoPopup && (
        <RodoPopup rodoContent={rodoData} userData={userData} />
      )}
      {showFirstLoginPopup && (
        <FirstLoginPopup userData={userData} setUserData={setUserData} />
      )}
      {accountDisabled && <AccountDisabled />}
    </>
  );
};

export default LoggedLayout;
