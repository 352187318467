import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, message, Menu } from 'antd';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import GarageHallsInvestmentModal from './GarageHallsInvestmentModal';
import housingAssociationsService from '../../../services/housingAssociationsService';

export const GarageHallsInvestment = ({ setEditInvestment }) => {
  const { id } = useParams();
  const [investment, setInvestment] = useState({});
  const [investmentGarageHalls, setInvestmentGarageHalls] = useState([]);
  const [editingGarageHallId, setEditingGarageHallId] = useState();
  const [showModal, setShowModal] = useState(false);

  const refresh = () => {
    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.getOne(id)
      .then((response) => {
        setInvestment(response);
        setEditInvestment(response);
        const garageHalls = response?.garageHalls;

        if (garageHalls) {
          setInvestmentGarageHalls(garageHalls);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  useEffect(() => {
    refresh();
  }, [showModal]);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      editable: false,
    },
    {
      title: 'Powierzchnia całkowita',
      dataIndex: 'garageHallSize',
      editable: false,
      render: (text, record) => (
        <span>
          {text} m<sup>2</sup>
        </span>
      ),
    },
    {
      title: 'Suma powierzchni MP i KL*',
      dataIndex: 'parkingAndStorageSizeSum',
      editable: false,
      render: (text, record) => (
        <span>
          {text} m<sup>2</sup>
        </span>
      ),
    },
  ];

  const deleteAction = (entityId) => {
    const tempGarageHalls = [
      ...investment?.garageHalls.filter((el) => el.id !== entityId),
    ];

    const HousingAssociationsService = new housingAssociationsService();

    HousingAssociationsService.updateFields(id, {
      garageHalls: tempGarageHalls,
    })
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setShowModal(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <GarageHallsInvestmentModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
          setEditingGarageHallId();
        }}
        investment={investment}
        editingGarageHallId={editingGarageHallId}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            tableClassName={
              investmentGarageHalls.length !== 0 && 'contacts-table'
            }
            data={investmentGarageHalls}
            columns={columns}
            title='Podział'
            deleteAction={deleteAction}
            customMenuActions={(record) => (
              <Menu.Item
                key='1'
                onClick={() => {
                  setEditingGarageHallId(record.id);
                  setShowModal(true);
                }}
                icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
              >
                Edytuj
              </Menu.Item>
            )}
            customAddButton={<CustomAddButton />}
            hideEdit
          />
          <div
            className='attachmentsNote'
            style={{ fontSize: '10px', color: '#B1CADE' }}
          >
            * Miejsc postojowych i Komórek lokatorskich.
          </div>
        </Col>
      </Row>
    </div>
  );
};
