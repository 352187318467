import React, { useState, useEffect, useRef } from 'react';
import {
  Col, Form, Tooltip, Button,
} from 'antd';
import { Link } from 'react-router-dom';
import userService from '../../services/userService';
import investmentsService from '../../services/investmentsService';
import premisesService from '../../services/premisesService';
import premiseStatusMap from '../../helpers/premiseStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Statisticts from '../investments/premises/Statistics';

import {
  Spin, Icon, Price, Datagrid, Input,
} from '../../components';

const { Select, PriceInput } = Input;
const { Option } = Select;

const Dot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'FREE') {
    color = 'green';
  }
  if (status === 'PARTIAL_RENT') {
    color = 'halfred';
  }
  if (status === 'RESERVATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={premiseStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const SurfaceRenderer = ({ totalSurface }) => (
  <span>
    {totalSurface}
    {' '}
    m
    <sup>2</sup>
  </span>
);

const RoomsRenderer = ({ roomsCount, investmentType }) => (
  <span>
    {investmentType === 'STORAGE' || investmentType === 'PARKING' ? (
      '-'
    ) : (
      roomsCount
    )}
  </span>
);

const columns = [
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.type),
    exportRender: (text, record) => premiseTypeMap(record.type),
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<SurfaceRenderer totalSurface={record.totalSurface} />),
  },
  {
    title: 'Liczba pokoi',
    dataIndex: 'roomsCount',
    visible: false,
    key: 'roomsCount',
    sorter: false,
    render: (text, record) => (
      <RoomsRenderer
        roomsCount={record.roomsCount}
        investmentType={record.type}
      />
    ),
  },
];

const rentColumns = [
  ...columns,
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => premiseStatusMap(record.status),
  },
  {
    title: 'Koszt miesięczny (brutto)',
    dataIndex: 'rentPrice',
    visible: true,
    key: 'rentPrice',
    sorter: false,
    render: (text, record) => (<Price value={record.rentPrice} />),
  },
];

const clientColumns = [
  ...columns,
  {
    title: 'Cena zakupu (brutto)',
    dataIndex: 'priceSell',
    visible: true,
    key: 'priceSell',
    sorter: true,
    summary: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Price value={record.priceSell} />),
  },
];

const List = () => {
  const [premisesStats, setPremisesStats] = useState({});
  const [datagridFilters, setDatagridFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [userData, setUserData] = useState({});
  const [investments, setInvestments] = useState([]);
  const PremisesService = new premisesService(null, null);

  const datagridRef = useRef();

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getPremises().then((data) => {
      setPremisesStats(data);
    });

    InvestmentsService.getList({
      types: 'SALE',
      pageSize: 1000,
    }).then((data) => {
      setInvestments(data.content);
    });
    userService.getInfo().then((response) => {
      setUserData(response);
      if (response.roles.includes('ROLE_WORKER') && response.ownerRoles.includes('ROLE_TENANT')) {
        if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
      } else if (response.roles.includes('ROLE_CLIENT')) {
        setCanEdit(false);
      } else {
        setCanEdit(true);
      }
      setLoading(false);
    });
  }, []);

  const CustomCreate = () => {
    if (userData.roles.includes('ROLE_CLIENT')) {
      return '';
    }

    return (
      <Link to="/investments">
        <Button type="primary">
          Nowy
          {' '}
          <Icon name="plus" />
        </Button>
      </Link>
    );
  };

  const customFilters = [];
  if (!loading && !userData.roles.includes('ROLE_CLIENT')) {
    customFilters.push(
      <Col xs={24} sm={12} key="name">
        <Form.Item
          name="name"
        >
          <Input size="large" placeholder="Numer" />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="totalSurface">
        <Form.Item
          name="totalSurface"
        >
          <Input size="large" placeholder="Powierzchnia" />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="type">
        <Form.Item
          name="type"
        >
          <Select
            placeholder="Typ"
            allowClear
            size="large"
          >
            <Option value="RENT">Mieszkanie</Option>
            <Option value="STORAGE">Komórka lokatorska</Option>
            <Option value="PARKING">Miejsce postojowe</Option>
            <Option value="SERVICE">Lokal usługowy</Option>
          </Select>
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="status">
        <Form.Item
          name="status"
        >
          <Select
            placeholder="Status"
            allowClear
            size="large"
          >
            <Option value="FREE">
              <span className="dot green" />
              Wolne
            </Option>
            <Option value="PARTIAL_RENT">
              <span className="dot halfred" />
              Częściowo wynajęte
            </Option>
            <Option value="RENT">
              <span className="dot red" />
              Wynajęte
            </Option>
          </Select>
        </Form.Item>
      </Col>,
    );
  } else {
    const investmentsOptions = investments.map((investment) => (
      <Option value={investment.id} key={investment.id}>{investment.name}</Option>
    ));
    customFilters.push(
      <Col xs={24} sm={12} key="investmentId">
        <Form.Item
          name="investmentId"
        >
          <Select
            placeholder="Inwestycja"
            allowClear
            size="large"
          >
            {investmentsOptions}
          </Select>
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={0} sm={12} />,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="type">
        <Form.Item
          name="type"
        >
          <Select
            placeholder="Typ"
            allowClear
            size="large"
          >
            <Option value="RENT">Mieszkanie</Option>
            <Option value="STORAGE">Komórka lokatorska</Option>
            <Option value="PARKING">Miejsce postojowe</Option>
            <Option value="SERVICE">Lokal usługowy</Option>
          </Select>
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="name">
        <Form.Item
          name="name"
        >
          <Input size="large" placeholder="Numer" />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="priceSellFrom">
        <Form.Item
          name="priceSellFrom"
        >
          <PriceInput size="large" placeholder="Cena zakupu od" />
        </Form.Item>
      </Col>,
    );

    customFilters.push(
      <Col xs={24} sm={12} key="priceSellTo">
        <Form.Item
          name="priceSellTo"
        >
          <PriceInput size="large" placeholder="Cena zakupu do" />
        </Form.Item>
      </Col>,
    );
  }

  const setStatsFilters = (e) => {
    datagridRef.current.handleFilterChange(e, true);
  };

  const afterFilterChange = () => {
    setDatagridFilters(datagridRef.current.state.filters);
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <div className="premises-list">
          {!userData.roles.includes('ROLE_CLIENT') ? (
            <div className="datagrid-wrapper">
              <h1>Lokale</h1>
              <Statisticts
                type="RENT"
                premisesStats={premisesStats}
                setFilters={setStatsFilters}
                datagridFilters={datagridFilters}
              />
            </div>
          ) : (
            <div className="datagrid-wrapper">
              <h1>Lokale</h1>
            </div>
          )}

          <Datagrid
            resource="premisesAll"
            dataProvider={PremisesService}
            columns={userData.roles.includes('ROLE_CLIENT') ? clientColumns : rentColumns}
            customCreate={<CustomCreate />}
            customFilters={customFilters}
            customBaseUrlKey="premisesAll"
            sort={{
              sortField: 'name',
              sortOrder: 'ascend',
            }}
            hideEdit={!canEdit}
            canEdit={canEdit}
            ref={datagridRef}
            afterFilterChange={afterFilterChange}
          />
        </div>
      )}
    </div>
  );
};

export default List;
