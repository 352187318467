import React, { useEffect, useState } from 'react';
import { PageHeader, Tooltip, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Icon, Datagrid } from '../../../components';
import investmentsService from '../../../services/investmentsService';
import mainCountersService from '../../../services/mainCountersService';

import { renderUnit } from '../../../helpers/unitRender';

import Spin from '../../../components/Spin';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const percentRender = (percent) => {
  const value = parseFloat(percent);

  if (!value) {
    return `+0.00%`;
  } else if (value > 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const List = () => {
  const history = useHistory();
  const { id } = useParams();
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState();

  const columns = [
    {
      title: 'Numer licznika',
      dataIndex: 'number',
      visible: true,
      filterEnabled: true,
      key: 'number',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Nazwa licznika',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Stan licznika',
      dataIndex: 'countersValuesSum',
      visible: true,
      filterEnabled: false,
      key: 'countersValuesSum',
      sorter: false,
      render: (text, record) => (
        <span>
          <div
            style={{
              position: 'absolute',
              top: 15,
              color:
                record.mainCounterPercentChange < 0 ? '#EC434C' : '#15D55E',
              fontSize: 8,
              fontWeight: 700,
            }}
          >
            {percentRender(record.mainCounterPercentChange)}
          </div>

          <div>
            {record.mainCounterValue
              ? parseFloat(record.mainCounterValue).toFixed(2)
              : '0.00'}{' '}
            {renderUnit(record.unit)}
          </div>
        </span>
      ),
    },
    {
      title: 'Data ostatniego odczytu',
      dataIndex: 'mainCounterLastReadDate',
      visible: true,
      filterEnabled: false,
      key: 'mainCounterLastReadDate',
      sorter: false,
      render: (text, record) => (text ? text : '-'),
      exportRender: (text, record) => (text ? text : '-'),
    },
    {
      title: 'Podliczniki',
      dataIndex: 'subCountersSum',
      visible: true,
      filterEnabled: false,
      key: 'subCountersSum',
      sorter: false,
    },
  ];

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getDetails(id).then((data) => {
      setInvestment(data.investment);
      setLoading(false);
    });
  }, []);

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Liczniki główne</span>;
    }
    return (
      <span>
        Liczniki główne:{' '}
        <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title={
              <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                backIcon={<Icon name='arrow-simply-left' />}
                onBack={() => window.history.back()}
                title={<HeaderTitle />}
              />
            }
            exportTitle={'Liczniki główne'}
            resource={`investment/${id}/counters`}
            dataProvider={new mainCountersService(id)}
            columns={columns}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            showAlert={showAlert}
            hideReset={true}
            hideSms={true}
            hideEmail={true}
          />
        </div>
      )}
    </>
  );
};

export default List;
