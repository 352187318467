import React from 'react';
import Icon from '../../../components/Icon';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

export const DescriptionBox = ({ resolution }) => {
  if (!resolution) {
    return null;
  }

  const contentState = convertFromRaw(JSON.parse(resolution.description));

  const editorState = EditorState.createWithContent(contentState);

  const description = draftToHtml(
    convertToRaw(editorState.getCurrentContent())
  );

  if (contentState.getPlainText().length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='uchwaly' />
        Szczegóły
      </h2>
      <div
        className='content react-draft-wysiwyg-resolution-details'
        style={{ paddingLeft: 20, paddingRight: 20 }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};
