import BaseService from './baseService';
import httpService from '../helpers/httpService';

class AttachmentsService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/attachment`;
    const roles = localStorage.getItem('roles');
    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/premises/${premisesId}/attachment`;
    }
  }

  async updateData(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }
}

export default AttachmentsService;
