import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';
import HousingAssociationsService from '../../../../services/housingAssociationsService';
import MessagePopup from '../../../../components/MessagePopup';
import ownersService from '../../../../services/ownersService';
import moment from 'moment';

const { SearchBox, DatePicker, Switch } = Input;

const OwnerModal = (props) => {
  const { afterCancel, isModalVisible, editingOwner } = props;
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const [withoutExpireDate, setWithoutExpireDate] = useState(true);

  useEffect(() => {
    if (editingOwner) {
      const tempData = {
        userId: editingOwner.userId,
        dateFrom: moment(editingOwner.dateFrom, 'YYYY-MM-DD'),
      };

      if (editingOwner?.withoutExpireDate) {
        tempData.withoutExpireDate = true;
      } else {
        tempData.dateTo = moment(editingOwner.dateTo, 'YYYY-MM-DD');
      }

      setWithoutExpireDate(editingOwner?.withoutExpireDate);

      form.setFieldsValue(tempData);
    } else {
      setWithoutExpireDate(true);
    }
  }, [editingOwner]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const OwnersService = new ownersService(id, premisesId);

        const tempValues = {
          userId: values.userId,
          dateFrom: values.dateFrom.format('YYYY-MM-DD'),
        };

        if (withoutExpireDate) {
          tempValues.withoutExpireDate = true;
          tempValues.dateTo = null;
        } else {
          tempValues.dateTo = values.dateTo.format('YYYY-MM-DD');
          tempValues.withoutExpireDate = null;
        }

        if (editingOwner) {
          OwnersService.update(editingOwner.id, tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err?.response?.status === 409) {
                message.error(
                  <MessagePopup
                    type={'error'}
                    annotation={'Właściciel jest już przypisany do lokalu'}
                  >
                    Nie można dodać właściciela
                  </MessagePopup>
                );
              } else if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          OwnersService.create(tempValues)
            .then((response) => {
              form.resetFields();
              afterCancel();
            })
            .catch((err) => {
              if (err && err?.response?.status === 409) {
                message.error(
                  <MessagePopup
                    type={'error'}
                    annotation={'Właściciel jest już przypisany do lokalu'}
                  >
                    Nie można dodać właściciela
                  </MessagePopup>
                );
              } else if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
    setWithoutExpireDate(false);
  };

  return (
    <>
      <Modal
        title={editingOwner ? 'Edytujesz właściciela' : 'Dodaj właściciela'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={410}
        footer={<Footer onReset={onReset} onFinish={onFinish} />}
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name='userId'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <SearchBox
                  optionValue={(el) => {
                    if (el.isCompany) {
                      return el.name;
                    }
                    return `${el.firstName} ${el.lastName}`;
                  }}
                  placeholder='Użytkownik'
                  size='large'
                  resource={new HousingAssociationsService()}
                  methodName={'getTenantsList'}
                  filters={{ role: 'ROLE_COMMUNITY_MEMBER', size: 9999 }}
                  filterOption={(inputValue, option) => {
                    return option.key.indexOf(inputValue) !== -1;
                  }}
                  showSearch={false}
                />
              </Form.Item>
              <Form.Item
                name='dateFrom'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker
                  size='large'
                  placeholder='Data od'
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name='dateTo'
                rules={
                  !withoutExpireDate && [
                    { required: true, message: 'Pole wymagane' },
                    {
                      validator(rule, value) {
                        return new Promise((resolve, reject) => {
                          const inputedDateTo = form.getFieldValue('dateFrom');
                          if (value > inputedDateTo) {
                            resolve();
                          } else {
                            reject('Data do musi być później niż data od');
                          }
                        });
                      },
                    },
                  ]
                }
              >
                <DatePicker
                  size='large'
                  placeholder='Data do'
                  style={{ width: '100%' }}
                  disabled={withoutExpireDate}
                />
              </Form.Item>

              <Form.Item name='withoutExpireDate' valuePropName='checked'>
                <span style={{ marginRight: 10 }}>Do odwołania</span>
                <Switch
                  checked={withoutExpireDate}
                  onChange={(checked) => {
                    setWithoutExpireDate(checked);

                    if (checked) {
                      form.resetFields(['dateTo']);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OwnerModal;
