import React from 'react';
import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';

const ShouldDisableSite = ({ userData }) => {
  const location = useLocation();
  const { pathname } = location;

  const shouldDisableSite = () => {
    if (Object.keys(userData).length === 0) {
      return false;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (!userData.hasOwnProperty('isPackageExpired')) {
      return false;
    }
    if (
      !(
        userData.roles.includes('ROLE_DEVELOPER') ||
        userData.roles.includes('ROLE_TENANT') ||
        userData.roles.includes('ROLE_COMMUNITY_ADMIN')
      )
    ) {
      return false;
    }

    if (userData.isPackageExpired === false) {
      return false;
    }

    if (
      pathname.startsWith('/myAccount') ||
      pathname.startsWith('/myAccount/edit') ||
      pathname.startsWith('/changePackage') ||
      (pathname.startsWith('/payments') && pathname.endsWith('/show'))
    ) {
      return false;
    }
    return true;
  };

  return (
    shouldDisableSite() && (
      <div className='account-expired-wrapper'>
        <div className='account-expired-popup'>
          <div>
            Twój pakiet wygasł, aby go opłacić kliknij w poniższy przycisk
          </div>
          <div style={{ marginTop: '20px' }}>
            <Link
              to={
                userData.roles.includes('ROLE_COMMUNITY_ADMIN')
                  ? '/myAccount'
                  : '/myAccount/edit?package'
              }
            >
              <Button type='danger'>
                Opłać pakiet
                <Icon name='payments' style={{ marginLeft: '5px' }} />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default ShouldDisableSite;
