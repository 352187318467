import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import { Datagrid } from '../../../components';
import Icon from '../../../components/Icon';
import SharesBox from './components/SharesBox';
import { premiseTypeMap } from '../../../helpers';
import memberAssociationsService from '../../../services/memberAssociationsService';
import memberInvestmentPremisesService from '../../../services/memberInvestmentPremisesService';

const columns = [
  // {
  //   title: 'Lokal',
  //   dataIndex: 'name',
  //   visible: true,
  //   filterEnabled: false,
  //   key: 'premise',
  //   sorter: true,
  //   sortDirections: ['descend', 'ascend'],
  //   render: (text, record) => `${premiseTypeMap(record.type)} ${record.name}`,
  //   exportRender: (text, record) =>
  //     `${premiseTypeMap(record.type)} ${record.name}`,
  // },
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    export: true,
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    filterEnabled: true,
    filterType: 'select',
    filterOptions: [
      { value: 'RENT', label: 'Mieszkanie' },
      { value: 'STORAGE', label: 'Komórka lokatorska' },
      { value: 'PARKING', label: 'Miejsce postojowe' },
      { value: 'SERVICE', label: 'Lokal usługowy' },
    ],
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    export: true,
    render: (text, record) => premiseTypeMap(record.type),
    exportRender: (text, record) => premiseTypeMap(record.type),
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span>
        {text} m<sup>2</sup>
      </span>
    ),
  },
  {
    title: 'Powierzchnia od',
    dataIndex: 'totalSurfaceFrom',
    visible: false,
    key: 'totalSurfaceFrom',
    sorter: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
    export: false,
  },
  {
    title: 'Powierzchnia do',
    dataIndex: 'totalSurfaceTo',
    visible: false,
    key: 'totalSurfaceTo',
    sorter: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
    export: false,
  },
  {
    title: 'Udział',
    dataIndex: ['participation', 'percent'],
    visible: true,
    key: 'participationPercent',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => {
      if (text) {
        return `${text.toFixed(2)}%`;
      } else {
        return null;
      }
    },
  },
  {
    title: 'Najemca',
    dataIndex: 'tenantsNames',
    visible: true,
    key: 'tenantsNames',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      if (
        record.communityMembersTenants[0]?.firstName &&
        record.communityMembersTenants[0]?.lastName
      ) {
        return `${record.communityMembersTenants[0]?.firstName} ${record.communityMembersTenants[0]?.lastName}`;
      } else if (record.communityMembersTenants[0]?.firstName) {
        return `${record.communityMembersTenants[0]?.firstName}`;
      } else if (record.communityMembersTenants[0]?.lastName) {
        return `${record.communityMembersTenants[0]?.lastName}`;
      } else {
        return null;
      }
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    visible: true,
    key: 'dateFrom',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      return record.communityMembersTenants[0]?.dateFrom;
    },
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    visible: true,
    key: 'dateTo',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => {
      if (record.communityMembersTenants[0]?.withoutExpireDate) {
        return <span style={{ color: '#B1CADE' }}>bezterminowo</span>;
      } else {
        return record.communityMembersTenants[0]?.dateTo;
      }
    },
  },
];

const customFilters = [];

const List = () => {
  const { id } = useParams();
  const [baseUrlKey, setBaseUrlKey] = useState();
  const [investment, setInvestment] = useState({});

  const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
    id
  );

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();

    MemberAssociationsService.getDetails(id).then((data) => {
      setInvestment(data);

      if (data.type === 'HOUSING_ASSOCIATION') {
        setBaseUrlKey('memberAssociations');
      } else if (data.type === 'HOUSING_COMMUNITY') {
        setBaseUrlKey('memberCommunities');
      }
    });
  }, []);

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Lokale</span>;
    }
    return (
      <span>
        Lokale: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            backIcon={<Icon name='arrow-simply-left' />}
            onBack={() => window.history.back()}
            title={<HeaderTitle />}
          />
        }
        exportTitle={'Lokale'}
        resource={`${baseUrlKey}/${id}/premises`}
        customBaseUrlKey={baseUrlKey}
        dataProvider={MemberInvestmentPremisesService}
        columns={columns}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        customCreate={
          <div style={{ width: '100%' }}>
            <SharesBox premisesView={true} />
          </div>
        }
        hideDelete
        hideReset
        hideSms
        hideEmail
      />
    </div>
  );
};

export default List;
