import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import housingAssociationsService from '../../services/housingAssociationsService';
import documentsService from '../../services/documentsService';
import { Icon, Spin, Input } from '../../components';
import { Attachments } from './grids/Attachments';
import deleteConfirm from '../../components/deleteConfirm';

const { DatePicker, SearchBox } = Input;

const Edit = () => {
  const history = useHistory();
  const { investmentId, documentId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});

  useEffect(() => {
    const DocumentsService = new documentsService(investmentId);
    DocumentsService.getOne(documentId).then((response) => {
      setDocument(response);
      setDocuments([{ ...response, key: 1 }]);

      form.setFieldsValue({
        addDate: moment(response.addDate),
        investmentId: investmentId,
      });
    });

    setLoading(false);
  }, []);

  const onFinish = (values) => {
    const DocumentsService = new documentsService(investmentId);
    DocumentsService.updateFields(documentId, {
      filename: document.filename,
    })
      .then((response) => {
        if (response.id) {
          history.goBack();
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const HeaderTitle = () => {
    if (!document.filename) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:{' '}
        <span className='header-subtitle'>
          {document.filename.split('.').slice(0, -1).join('.')}
        </span>
      </span>
    );
  };

  const handleOk = () => {
    const DocumentsService = new documentsService(investmentId);
    DocumentsService.delete(documentId)
      .then(() => history.replace('/documents'))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleBack = () => {
    return history.goBack();
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => handleBack()}
          title={<HeaderTitle />}
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <>
              <Card className='form-create-main-card'>
                <div className='create-form-wrapper'>
                  <Row>
                    <Col xs={20} offset={2}>
                      <div className='form-section'>
                        <Attachments
                          documents={documents}
                          setDocuments={setDocuments}
                          setDocument={setDocument}
                          edit={true}
                        />
                      </div>

                      <div className='form-section'>
                        <Row gutter={20} style={{ marginTop: 80 }}>
                          <Col xs={24} sm={12}>
                            <Form.Item name='addDate'>
                              <DatePicker
                                size='large'
                                placeholder='Data dodania'
                                style={{ width: '100%' }}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item name='investmentId'>
                              <SearchBox
                                placeholder='Spółdzielnia/Wspólnota'
                                size='large'
                                resource={new housingAssociationsService()}
                                filters={{
                                  types: [
                                    'HOUSING_ASSOCIATION',
                                    'HOUSING_COMMUNITY',
                                  ],
                                }}
                                resourceField='name'
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Button
                  className='delete-btn'
                  type='danger'
                  onClick={() => deleteConfirm(handleOk)}
                  style={{ left: 40 }}
                >
                  <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
                </Button>
              </Card>
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleBack}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      Zapisz
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Edit;
