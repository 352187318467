const fieldMap = {
  name: 'Nazwa',
  description: 'Opis',
  status: 'Status',
  addDate: 'Data dodania',
  changeDate: 'Data zmiany',
  amount: 'Kwota',
  paid: 'Opłacono',
  responsibleWorker: 'Osoba odpowiedzialna',
  expirationDate: 'Termin płatności',
  paymentStatus: 'Status płatności',
  premisesName: 'Lokal',
  paymentDate: 'Data płatności',
  filename: 'Załączniki',
  tags: 'Tagi',
};
const getFieldName = (field) => fieldMap[field] || field;

export default getFieldName;
