import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  PageHeader, Button,
} from 'antd';

import Icon from '../../components/Icon';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import Spin from '../../components/Spin';
import PremisesChart from './charts/PremisesChart';
import TranchesGrowthChart from './charts/TranchesGrowthChart';
import TranchesChart from './charts/TranchesChart';

const useQuery = () => new URLSearchParams(useLocation().search);
const Stats = () => {
  const query = useQuery();
  const id = query.get('id') || null;
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState({});
  const [investmentType, setInvestmentType] = useState('');

  useEffect(() => {
    if (id) {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data.investment);
        setInvestmentType(data.investment.type);
        setLoading(false);
      });
    } else {
      userService.getInfo().then((userData) => {
        if (userData.roles.includes('ROLE_WORKER')) {
          if (userData.ownerRoles.includes('ROLE_TENANT')) {
            setInvestmentType('RENT');
          } else {
            setInvestmentType('SALE');
          }
        } else if (userData.roles.includes('ROLE_TENANT')) {
          setInvestmentType('RENT');
        } else {
          setInvestmentType('SALE');
        }
        setLoading(false);
      });
    }
  }, [id]);

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          backIcon={<Icon name="arrow-simply-left" />}
          title={id ? investment.name : 'Wszystkie inwestycje'}
          extra={[
            id && (
            <Link key="1" to="/investments/stats">
              <Button type="primary" className="all-investments-stats-button">
                Wszystkie inwestycje
                <Icon name="stats" />
              </Button>
            </Link>
            ),
          ]}
        >
          <div className="stats">
            <TranchesGrowthChart investmentType={investmentType} />
            <TranchesChart investmentType={investmentType} />
            <PremisesChart investmentType={investmentType} />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Stats;
