import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, Row, Col, message } from 'antd';
import moment from 'moment';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import mainCountersHistoryService from '../../../services/mainCountersHistoryService';
import SmallList from '../../../components/SmallList';

import { renderUnit } from '../../../helpers/unitRender';

const { InputNumberV2, DatePicker } = Input;

const SubCounterReadModal = (props) => {
  const {
    afterCancel,
    isModalVisible,
    allReads,
    mainCounterData,
    subCounterData,
    editingRead,
    refresh,
  } = props;
  const [form] = Form.useForm();
  const { id, counterId, subCounterId } = useParams();
  const [prevRead, setPrevRead] = useState();
  const [nextRead, setNextRead] = useState();

  useEffect(() => {
    if (editingRead) {
      const tempData = {
        value: editingRead.value,
      };

      if (editingRead.readDate) {
        const tempDate = moment(editingRead.readDate, 'YYYY-MM-DD');
        tempData.readDate = tempDate;
      }

      form.setFieldsValue(tempData);

      const currIdx = allReads.findIndex((read) => read.id === editingRead.id);

      if (currIdx > 0) {
        setNextRead(allReads[currIdx - 1]);
      } else {
        setNextRead(null);
      }

      if (currIdx < allReads.length) {
        setPrevRead(allReads[currIdx + 1]);
      } else {
        setPrevRead(null);
      }
    } else {
      form.setFieldsValue({ readDate: moment(new Date(), 'YYYY-MM-DD') });

      if (allReads?.length !== 0) {
        setPrevRead(allReads[0]);
      } else {
        setPrevRead(null);
      }
      setNextRead(null);
    }
  }, [editingRead, isModalVisible]);

  const columns = [
    {
      title: <span>Stan licznika ({renderUnit(mainCounterData?.unit)})</span>,
      dataIndex: 'value',
      editable: false,
    },
    {
      title: (
        <span>
          Różnica od ostatniego odczytu ({renderUnit(mainCounterData?.unit)})
        </span>
      ),
      dataIndex: 'mainCounterValue',
      editable: false,
    },
    {
      title: 'Data odczytu',
      dataIndex: 'readDate',
      editable: false,
      render: (text, record) => (text ? text : '-'),
    },
  ];

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const tempValues = {
          value: values.value,
          readDate: values.readDate.format('YYYY-MM-DD'),
        };

        const MainCountersHistoryService = new mainCountersHistoryService(
          id,
          counterId
        );

        if (editingRead) {
          MainCountersHistoryService.update(editingRead.id, tempValues)
            .then((response) => {
              refresh();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          MainCountersHistoryService.create(tempValues)
            .then((response) => {
              refresh();
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={780}
        footer={
          <Footer onReset={onReset} onFinish={onFinish} isNew={!editingRead} />
        }
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <div style={{ marginBottom: 20, fontSize: 20 }}>
            {editingRead ? (
              <span>
                Edytujesz odczyt z dnia:
                <span
                  style={{
                    color: '#5353F4',
                    marginLeft: 5,
                  }}
                >
                  {editingRead?.date}
                </span>
              </span>
            ) : (
              <span>
                Nowy stan licznika:
                <span
                  style={{
                    color: '#5353F4',
                    marginLeft: 5,
                  }}
                >
                  {subCounterData?.number}
                </span>
              </span>
            )}
          </div>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={'value'}
                rules={[
                  {
                    required: true,
                    message: 'Proszę uzupełnić odczyt',
                  },
                  () => ({
                    validator(_, val) {
                      if (prevRead) {
                        if (val < prevRead?.value) {
                          return Promise.reject(
                            'Odczyt musi być większy niż poprzedni'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                  () => ({
                    validator(_, val) {
                      if (nextRead) {
                        if (val > nextRead?.value) {
                          return Promise.reject(
                            'Odczyt musi być mniejszy niż następny'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Stan licznika'
                  addonAfter={renderUnit(mainCounterData?.unit)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={'readDate'}
                rules={[
                  {
                    required: true,
                    message: 'Proszę uzupełnić datę odczytu',
                  },
                  () => ({
                    validator(_, val) {
                      if (prevRead) {
                        if (
                          moment(val, 'YYYY-MM-DD') <=
                          moment(prevRead?.readDate, 'YYYY-MM-DD').add(
                            1,
                            'days'
                          )
                        ) {
                          return Promise.reject(
                            'Odczyt musi być później niż poprzedni'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                  () => ({
                    validator(_, val) {
                      if (nextRead) {
                        if (
                          moment(val, 'YYYY-MM-DD') >=
                          moment(nextRead?.readDate, 'YYYY-MM-DD').subtract(
                            1,
                            'days'
                          )
                        ) {
                          return Promise.reject(
                            'Odczyt musi być wcześniej niż następny'
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker
                  size='large'
                  placeholder='Data'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ marginTop: 30, fontSize: 20 }}>
            {editingRead ? 'Poprzedni stan licznika' : 'Aktualny stan licznika'}
          </div>

          <SmallList data={prevRead ? [prevRead] : []} columns={columns} />
        </Form>
      </Modal>
    </>
  );
};

export default SubCounterReadModal;
