import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Divider,
  Table,
  PageHeader,
  Dropdown,
  Menu,
  Tooltip,
  message,
} from 'antd';
import Datagrid from '../../../components/Datagrid';
import { Icon } from '../../../components';
import Spin from '../../../components/Spin';
import memberPremisesSettlementsService from '../../../services/memberPremisesSettlementsService';
import memberInvestmentPremisesService from '../../../services/memberInvestmentPremisesService';
import memberAssociationsService from '../../../services/memberAssociationsService';
import settlementPaymentsService from '../../../services/settlementPaymentsService';
import { premiseTypeMap } from '../../../helpers';
import TransferInfoModal from '../components/TransferInfoModal';
import PaymentEditModal from '../components/PaymentEditModal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import CopiedPopup from '../../../components/CopiedPopup';

const getRowClassName = (record, index) => {
  if (record.status === 'NEW') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const RenderStatus = (status) => {
  if (status === 'NEW') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const Status = ({ status }) => {
  if (status === 'PAID') {
    return (
      <span>
        <span className={`dot green`} style={{ marginRight: 8 }} />
        Zapłacone
      </span>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <span>
        <span className={`dot yellow`} style={{ marginRight: 8 }} />
        Częściowo zapłacone
      </span>
    );
  } else {
    return (
      <span>
        <span className={`dot red`} style={{ marginRight: 8 }} />
        Do zapłaty
      </span>
    );
  }
};

const Show = () => {
  const paymentsDatagridRef = useRef();
  const { id, premisesId, settlementId } = useParams();
  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState({});
  const [premisesData, setPremisesData] = useState({});
  const [settlementData, setSettlementData] = useState({});
  const [settlementCosts, setSettlementCosts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingPaymentVisible, setEditingPaymentVisible] = useState(false);
  const [editingPayment, setEditingPayment] = useState({});

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      id
    );
    const MemberPremisesSettlementsService =
      new memberPremisesSettlementsService(id, premisesId);

    const promises = [
      MemberAssociationsService.getDetails(id).then((data) => {
        setInvestment(data);
      }),
      MemberInvestmentPremisesService.getOne(premisesId).then((response) => {
        setPremisesData(response);
      }),
      MemberPremisesSettlementsService.getOne(settlementId).then((response) => {
        setSettlementData(response);
      }),
    ];

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const MemberPremisesSettlementsService =
      new memberPremisesSettlementsService(id, premisesId);

    MemberPremisesSettlementsService.getCosts(settlementId).then((response) => {
      setSettlementCosts(response);
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: 'Pozycja',
      dataIndex: 'name',
    },
    {
      title: 'Ilość',
      dataIndex: 'quantity',
    },
    {
      title: 'Jednostka',
      dataIndex: 'unit',
      render: (text, record) => {
        if (text === 'm3') {
          return (
            <span>
              m<sup>3</sup>
            </span>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: '',
      dataIndex: 'multiply',
      render: () => 'x',
    },
    {
      title: 'Cena jednostkowa (brutto)',
      dataIndex: 'unitPrice',
      render: (text, record) =>
        text ? `${text.toFixed(2).replace('.', ',')} zł` : '',
    },
    {
      title: 'Wartość (brutto)',
      dataIndex: 'price',
      render: (text, record) =>
        text ? `${text.toFixed(2).replace('.', ',')} zł` : '',
    },
  ];

  const paymentsColumns = [
    {
      title: 'Najemca',
      dataIndex: 'owner',
      visible: true,
      filterEnabled: false,
      key: 'owner',
      sorter: true,
      export: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        record.owner
          ? [record.owner.firstName, record.owner.lastName]
              .filter((e) => !!e)
              .join(' ') || ''
          : '',
    },
    {
      title: 'Dodano',
      dataIndex: 'createdDate',
      visible: true,
      filterEnabled: false,
      key: 'createdDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: false,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
      render: (text) => (text ? RenderStatus(text) : ''),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      visible: true,
      filterEnabled: false,
      key: 'expirationDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'amount',
      visible: true,
      filterEnabled: false,
      key: 'amount',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  const afterCancel = () => {
    setIsModalVisible(false);
  };

  const afterPaymentCancel = () => {
    setEditingPaymentVisible(false);
  };

  const paymentsDatagridRefresh = () => {
    paymentsDatagridRef.refresh();
  };

  const menu = () => (
    <Menu style={{ paddingTop: 8, paddingBottom: 8, width: 160 }}>
      <Menu.Item
        key='1'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
      >
        <Link
          to={`/investment/${id}/premises/${premisesId}/memberSettlements/${settlementId}/payment/${settlementData.paymentId}/show`}
          className='premises-custom-create-link'
        >
          Zapłać przez tPay
        </Link>
      </Menu.Item>

      <Menu.Item
        key='2'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
        onClick={() => setIsModalVisible(true)}
      >
        Tradycyjny przelew
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <TransferInfoModal
            settlementData={settlementData}
            isModalVisible={isModalVisible}
            afterCancel={afterCancel}
          />
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            backIcon={<Icon name='arrow-simply-left' />}
            title={'Rozliczenia'}
            className='page-header-extra-2'
          >
            <div className='settlement-template-wrapper'>
              <div className='settlement-template-header'>
                <div className='settlement-template-title'>
                  <span className='settlement-template-period'>
                    {moment(settlementData.createDate)
                      .locale('pl_PL')
                      .format('MMMM YYYY')
                      .toUpperCase()}
                  </span>
                  <span className='settlement-template-issue-date'>
                    (wystawiono:{' '}
                    {settlementData.createDate
                      ? settlementData.createDate
                      : '-'}
                    )
                  </span>
                </div>

                {settlementData.paymentId && (
                  <div>
                    <Dropdown
                      overlay={menu()}
                      overlayClassName='header-menu-wrapper'
                      trigger='click'
                      placement='bottomRight'
                    >
                      <Button
                        style={{
                          height: 48,
                          width: 120,
                          minWidth: 120,
                          marginBottom: 25,
                        }}
                        type='danger'
                        size='large'
                        onClick={() => console.log('HERE')}
                      >
                        <Icon style={{ marginRight: 5 }} name='payments' />
                        Zapłać
                      </Button>
                    </Dropdown>
                  </div>
                )}
              </div>

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Nadawca</div>
                <Row>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <div className='settlement-recipient-info'>
                      Dane{' '}
                      {investment?.type === 'HOUSING_ASSOCIATION'
                        ? 'Spółdzielni'
                        : 'Wspólnoty'}
                      :
                    </div>
                    <div className='settlement-recipient-value'>
                      {settlementData.companyName}{' '}
                      <span className='settlement-recipient-value-copy'>
                        <CopyToClipboard
                          text={settlementData.companyName}
                          onCopy={() =>
                            message.success({
                              content: <CopiedPopup />,
                              duration: 1,
                              className: 'message-copied',
                            })
                          }
                        >
                          <Icon name={'copy'} />
                        </CopyToClipboard>
                      </span>
                    </div>
                    <div className='settlement-recipient-value'>
                      {`ul. ${settlementData.address?.street} ${
                        settlementData.address?.number || ''
                      }${
                        settlementData.address?.local
                          ? `/${settlementData.address?.local}`
                          : ''
                      }`}
                    </div>
                    <div className='settlement-recipient-value'>
                      {`${settlementData.address?.zip} ${settlementData.address?.city}, ${settlementData.address?.country}`}
                    </div>

                    <div
                      className='settlement-recipient-value'
                      style={{ marginTop: 15 }}
                    >
                      NIP: {investment.nip ?? '-'}
                    </div>
                    <div className='settlement-recipient-value'>
                      Regon: {investment.regon ?? '-'}
                    </div>
                  </Col>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Tytuł przelewu:
                        </div>
                        <div className='settlement-recipient-value'>
                          {settlementData.name}
                          <span className='settlement-recipient-value-copy'>
                            <CopyToClipboard
                              text={settlementData.name}
                              onCopy={() =>
                                message.success({
                                  content: <CopiedPopup />,
                                  duration: 1,
                                  className: 'message-copied',
                                })
                              }
                            >
                              <Icon name={'copy'} />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Rachunek do wpłat:
                        </div>
                        <div className='settlement-recipient-value'>
                          {settlementData.bankNumber}
                          <span className='settlement-recipient-value-copy'>
                            <CopyToClipboard
                              text={settlementData.bankNumber}
                              onCopy={() =>
                                message.success({
                                  content: <CopiedPopup />,
                                  duration: 1,
                                  className: 'message-copied',
                                })
                              }
                            >
                              <Icon name={'copy'} />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='settlement-info-wrapper'>
                <div
                  className='settlement-info-title'
                  style={{ marginTop: 25 }}
                >
                  Płatność
                </div>
                <Row>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Razem do zapłaty:
                    </div>
                    <div
                      style={{
                        color: '#fff',
                        fontSize: 24,
                        fontWeight: 600,
                        marginBottom: 30,
                      }}
                    >
                      {settlementData.price
                        ? settlementData.price.toFixed(2).replace('.', ',')
                        : '0,00'}{' '}
                      zł
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>Zapłacono:</div>
                    <div
                      style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}
                    >
                      {settlementData.paid
                        ? settlementData.paid.toFixed(2).replace('.', ',')
                        : '0,00'}
                      zł
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Pozostało do zapłaty:
                    </div>
                    <div
                      style={{
                        color:
                          settlementData.price - settlementData.paid <= 0
                            ? '#15d55e'
                            : settlementData.paid
                            ? '#F9DF45'
                            : '#EC434C',
                        fontSize: 24,
                        marginBottom: 30,
                      }}
                    >
                      {settlementData.price
                        ? (settlementData.price - settlementData.paid)
                            .toFixed(2)
                            .replace('.', ',')
                            .replace('-', '+')
                        : '0,00'}{' '}
                      zł
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Termin płatności:
                    </div>
                    <div
                      style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}
                    >
                      {settlementData.paymentDate}
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Data opłacenia:
                    </div>
                    <div
                      style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}
                    >
                      {settlementData.paidDate ? settlementData.paidDate : '-'}
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>Status:</div>
                    <div
                      style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}
                    >
                      <Status status={settlementData.status} />
                    </div>
                  </Col>
                </Row>
              </div>

              <Divider style={{ borderTop: '1px solid #B1CADE' }} />

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Odbiorca</div>
                <Row>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Rozliczenie dla:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.firstName}{' '}
                          {premisesData.premisesOwner?.lastName}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`ul. ${premisesData.address?.street} ${
                            premisesData.address?.number || ''
                          }${
                            premisesData.address?.local
                              ? `/${premisesData.address?.local}`
                              : ''
                          }`}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`${premisesData.address?.zip} ${premisesData.address?.city}, ${premisesData.address?.country}`}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Dane kontaktowe:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.mobile}
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.email}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>Lokal:</div>
                        <div className='settlement-recipient-value'>
                          {premiseTypeMap(premisesData.type)}{' '}
                          {premisesData.name}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`ul. ${premisesData.address?.street} ${
                            premisesData.address?.number || ''
                          }${
                            premisesData.address?.local
                              ? `/${premisesData.address?.local}`
                              : ''
                          }`}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Powierzchnia:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.totalSurface} m<sup>2</sup>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <Divider style={{ borderTop: '1px solid #B1CADE' }} />

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Tabela kosztów</div>
                <Table
                  className='settlement-costs-table'
                  columns={columns}
                  dataSource={settlementCosts}
                  pagination={false}
                />
              </div>

              <div className='settlement-summary'>
                <span className='settlement-summary-label'>Razem:</span>
                <span className='settlement-summary-value'>
                  {settlementData.price
                    ? settlementData.price.toFixed(2).replace('.', ',')
                    : '0,00'}{' '}
                  zł
                </span>
              </div>
            </div>
          </PageHeader>
          {!settlementData.paymentId && (
            <>
              <Datagrid
                ref={paymentsDatagridRef}
                title={'Lista płatności'}
                dataProvider={
                  new settlementPaymentsService(id, premisesId, settlementId)
                }
                directResponse={true}
                columns={paymentsColumns}
                // filters={{
                //   investmentIds: [id],
                // }}
                sort={{
                  sortField: 'id',
                  sortOrder: 'descend',
                }}
                hideCreate
                hideEdit
                hideShow
                hideReset
                hideSms
                hideEmail
                hideDelete
                hideFilters
                customRowClass={getRowClassName}
              />

              <PaymentEditModal
                isModalVisible={editingPaymentVisible}
                afterCancel={afterPaymentCancel}
                editingPayment={editingPayment}
                refresh={paymentsDatagridRefresh}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Show;
