import React from 'react';
import { PageHeader } from 'antd';
import { useHistory } from 'react-router-dom';

import Icon from '../components/Icon';
import errorImg from '../static/images/404.png';

const NotFound = () => {
  const history = useHistory();
  return (
    <div className="not-found">
      <PageHeader
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.goBack()}
        title="Wróć"
      >
        <div className="error-wrapper">
          <img src={errorImg} alt="Not found" />
          <h1>Nie znaleziono strony</h1>
          <p>Spróbuj jeszcze raz</p>
        </div>
      </PageHeader>
    </div>
  );
};

export default NotFound;
