import React, { useState } from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import { useParams } from 'react-router-dom';
import BrokersServiceS from '../../../../services/brokersService';
import workersService from '../../../../services/workersService';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';

const { InputNumber, DebounceSelect } = Input;

const BrokerModal = (props) => {
  const {
    data, afterSave, afterCancel, isModalVisible,
  } = props;
  const [workers, setWorkers] = useState([]);
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const BrokersService = new BrokersServiceS(id, premisesId);

  if (data?.id) {
    form.setFieldsValue(data);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const tempValues = {
          ...values,
          workerId: values.workerId[0],
        };
        if (data?.id) {
          BrokersService.update(data.id, tempValues).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          BrokersService.create(tempValues).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? 'Edytujesz pośrednika' : 'Dodaj pośrednika';

  async function fetchUserList(username) {
    return workersService.getList(
      { role: 'ROLE_WORKER_BROKER', pagination: { pageSize: 1000 } },
    ).then((clientsData) => clientsData.content)
      .then((clientsData) => {
        const filteredResults = clientsData.filter((entry) => {
          const { firstName = '', lastName } = entry;
          if (!username) {
            return true;
          }
          const firstNameCheck = firstName.toUpperCase().search(username.toUpperCase());
          const lastNameCheck = lastName.toUpperCase().search(username.toUpperCase());

          return firstNameCheck !== -1 || lastNameCheck !== -1;
        });
        return filteredResults;
      })
      .then((clientsData) => clientsData.map((client) => {
        const pesel = client.identity && client.identity.pesel ? `(${client.identity.pesel})` : '';
        return {
          label: `${client.firstName} ${client.lastName} ${pesel}`,
          value: client.id,
        };
      }));
  }

  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name="workerId"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DebounceSelect
                  mode="multiple"
                  value={workers}
                  placeholder="Pośrednik"
                  size="large"
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => {
                    setWorkers(newValue.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="percent"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Procent zysku"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BrokerModal;
