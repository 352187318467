import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';
import moment from 'moment';
import pl from 'moment/locale/pl';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Empty from './components/Empty';

moment.locale('pl', pl);

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={plPL} renderEmpty={Empty}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
const currentTheme = localStorage.getItem('theme') || 'default';
document.body.classList.add(`theme-${currentTheme}`);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
