import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  PageHeader, Button, Row, Col, Card,
} from 'antd';
import clientsService from '../../services/clientsService';
import userService from '../../services/userService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [currentUser, serCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  useEffect(() => {
    if (customUserData) {
      setUserData(customUserData);
    } else {
      clientsService.getOne(id).then((data) => {
        setUserData(data);
      });
    }

    userService.getInfo().then((user) => {
      serCurrentUser(user);
      if (user.roles.includes('ROLE_WORKER')) {
        if (user.workerPermissions.includes('CLIENTS_FULL')) {
          setCanEdit(true);
        }
      } else {
        setCanEdit(true);
      }
      setLoading(false);
    });
  }, [id, customUserData]);

  const canShowMenu = (requiredPermission) => {
    if (currentUser.roles.includes('ROLE_WORKER')) {
      return currentUser.workerPermissions.includes(requiredPermission);
    }

    return true;
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/clients')}
          backIcon={customTitle ? '' : <Icon name="arrow-simply-left" />}
          title={customTitle || 'Wróć'}
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            !customTitle && canEdit && (
            <Link key="2" to={`/clients/${userData.id}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>
            ),
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className="left">
                  <div className="logo">
                    <img src={`${baseUrl}${userData.avatar}`} alt={userData.name} />
                  </div>
                  <div className="name">
                    {userData.firstName}
                    {' '}
                    {userData.lastName}
                  </div>
                  <div className="buttons">
                    {userData.mobile && (
                    <div className="call">
                      <a href={`tel:${userData}`}>
                        <Button type="primary" size="large">
                          <Icon name="phone" />
                          Zadzwoń
                        </Button>
                      </a>
                    </div>
                    )}
                    <div className="write">
                      <a href={`mailto:${userData.email}`}>
                        <Button type="primary" size="large">
                          <Icon name="email" />
                          {' '}
                          Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className="right">
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="user" />
                        Informacje
                      </h2>
                    )}
                    className="informations"
                  >
                    <Row>
                      {!userData.isCompany ? (
                        <Col xs={24} sm={12}>
                          <div className="title">Imię i nazwisko:</div>
                          <div>
                            {userData.firstName}
                            {' '}
                            {userData.lastName}
                          </div>
                        </Col>
                      ) : (
                        <Col xs={24} sm={12}>
                          <div className="title">Nazwa firmy:</div>
                          {userData.name}
                        </Col>
                      )}

                      <Col xs={24} sm={12}>
                        {userData.isCompany ? (
                          <Col xs={24} sm={12}>
                            <div className="title">Osoba do kontaktu:</div>
                            <div>
                              {userData.firstName}
                              {' '}
                              {userData.lastName}
                            </div>
                          </Col>
                        ) : (
                          <div className="title">Dane kontaktowe:</div>
                        )}
                        <div className="email">{userData.email}</div>
                        <div className="phone">{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12}>
                        <Address title="Adres zamieszkania" address={userData.address} />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Address title="Adres korespondencyjny" address={userData.correspondenceAddress ? userData.correspondenceAddress : {}} />
                      </Col>
                    </Row>
                  </Card>
                  <div className="investments-show-right-actions">
                    <Row gutter={20}>
                      <Col xs={24} sm={8}>
                        <Link className="investments-show-right-actions-action" to="premises">
                          <Icon name="premises" />
                          Lokale
                        </Link>
                      </Col>
                      {canShowMenu('FAULTS_FULL') && (
                        <Col xs={24} sm={8}>
                          <Link className="investments-show-right-actions-action" to="faultGroup">
                            <Icon name="fault" />
                            Usterki
                          </Link>
                        </Col>
                      )}
                      {canShowMenu('TENANTS_CHANGES_FULL') && (
                        <Col xs={24} sm={8}>
                          <Link className="investments-show-right-actions-action" to="tenantChangeGroups">
                            <Icon name="flat-changes" />
                            Zmiany lokatorskie
                          </Link>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
