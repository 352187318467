import React, { useEffect, useState } from 'react';
import memberAssociationsService from '../../../services/memberAssociationsService';
import Spin from '../../../components/Spin';

const SharesBox = ({ investmentId }) => {
  const [loading, setLoading] = useState(true);
  const [participation, setParticipation] = useState(false);

  useEffect(() => {
    if (investmentId) {
      setLoading(true);
      const MemberAssociationsService = new memberAssociationsService();
      MemberAssociationsService.getParticipation(investmentId).then((data) => {
        setParticipation(data);
      });
      setLoading(false);
    }
  }, [investmentId]);

  return (
    <>
      {!loading && !participation ? null : (
        <div
          className='premises-show-box info-box'
          style={{
            backgroundColor: '#5353F4',
            color: '#fff',
            padding: 25,
            marginTop: 12,
          }}
        >
          <h2 style={{ color: '#A7A7FA', fontSize: 10, textAlign: 'right' }}>
            <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
          </h2>
          {!participation ? (
            <Spin />
          ) : (
            <div className='content'>
              <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
                {participation?.percent
                  ?.toFixed(2)
                  .toString()
                  .replace('.', ',')}
                %
              </div>
              <div style={{ fontSize: 24, lineHeight: '32px' }}>
                <span style={{ fontWeight: 700 }}>
                  {participation?.premisesSize?.toFixed(2)}
                </span>
                {'  '}/ {participation?.investmentSize?.toFixed(2)}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SharesBox;
