import BaseService from './baseService';

class ResolutionsListService extends BaseService {
  constructor() {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/resolutions`;
    } else if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/resolutions`;
    }
  }
}

export default ResolutionsListService;
