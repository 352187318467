import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'antd';
import Icon from '../Icon';
import Input from '../InputComponent';

const { Select, DatePicker } = Input;

const FilterModal = (props) => {
  const { columns, handleFilterChange, customFilters } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        // eslint-disable-next-line no-param-reassign
        Object.keys(values).forEach(
          (key) =>
            (values[key] === undefined || values[key] === '') &&
            delete values[key]
        );

        Object.keys(values).forEach((key) => {
          const column = columns.find((column) => column.key === key);

          if (column?.filterOptionsObject && column?.filterSelectMultiple) {
            for (let i = 0; i < column.filterOptions.length; i++) {
              values[`${column.key}.${column.filterOptions[i].value}`] = false;
            }

            for (let j = 0; j < values[key].length; j++) {
              const tempKey = key;
              const tempValue = values[key][j];
              values[`${tempKey}.${tempValue}`] = true;
            }

            delete values[key];
          } else if (column?.filterOptionsObject) {
            for (let i = 0; i < column.filterOptions.length; i++) {
              values[`${column.key}.${column.filterOptions[i].value}`] = false;
            }

            const tempKey = key;
            const tempValue = values[key];

            values[`${tempKey}.${tempValue}`] = true;
            delete values[key];
          }
        });

        handleFilterChange(values);
        setModalVisible(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    handleFilterChange({});
    setModalVisible(false);
  };

  const filterInputs = columns
    .filter((column) => !!column.filterEnabled)
    .map((column) => {
      let input = (
        <Input
          placeholder={column.title}
          size='large'
          {...column.filterProps}
        />
      );
      if (column.filterType && column.filterType === 'select') {
        input = (
          <Select
            onChange={column.onFilterChange}
            mode={column.filterSelectMultiple ? 'multiple' : null}
            options={column.filterOptions}
            placeholder={column.title}
            allowClear
            showSearch={column.showSearch}
            size='large'
            {...column.filterProps}
          />
        );
      } else if (column.filterType && column.filterType === 'date') {
        input = (
          <DatePicker
            onChange={column.onFilterChange}
            placeholder={column.title}
            allowClear
            style={{ width: '100%' }}
            size='large'
            {...column.filterProps}
          />
        );
      }

      return (
        <>
          <Col xs={24} sm={12} key={column.key}>
            <Form.Item
              name={column.key}
              key={column.key}
              rules={
                column.requireIfSet && [
                  {
                    validator(rule, value) {
                      return new Promise((resolve, reject) => {
                        const inputedPremisesTypes = form.getFieldValue(
                          column.requireIfSet.field
                        );

                        if (
                          !inputedPremisesTypes ||
                          (inputedPremisesTypes && value)
                        ) {
                          resolve();
                        } else {
                          reject(column.requireIfSet.message);
                        }
                      });
                    },
                  },
                ]
              }
            >
              {input}
            </Form.Item>
          </Col>
          {column.filterFullWidth && <Col xs={0} sm={12} />}
        </>
      );
    });

  const settedFilters = [...filterInputs, ...customFilters];

  return (
    <>
      <Button
        className='button-secondary'
        onClick={() => setModalVisible(true)}
      >
        Filtry <Icon name='filtr' />
      </Button>
      <Modal
        title='Filtrowanie'
        centered
        visible={modalVisible}
        width={770}
        onCancel={() => setModalVisible(false)}
        footer={
          <Row
            gutter={20}
            style={{ paddingLeft: 8, paddingRight: 8 }}
            className='datagrid-filter-modal-button-wrapper'
          >
            <Col xs={12} sm={12} style={{ textAlign: 'left' }}>
              <Button type='danger' size='large' onClick={() => onReset()}>
                <Icon name='refresh' style={{ marginRight: '5px' }} /> Resetuj
              </Button>
            </Col>
            <Col xs={12} sm={12}>
              <Button type='primary' size='large' onClick={() => onFinish()}>
                Filtruj
              </Button>
            </Col>
          </Row>
        }
      >
        <Form
          form={form}
          name='filterDeveloper'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={8}>{settedFilters}</Row>
        </Form>
      </Modal>
    </>
  );
};

export default FilterModal;
