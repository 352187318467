import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, PageHeader, Tooltip, Dropdown, Menu, Modal } from 'antd';
import Icon from '../../components/Icon';
import Datagrid from '../../components/Datagrid';
import Spin from '../../components/Spin';
import messagesService from '../../services/messagesService';
import investmentsService from '../../services/investmentsService';
import MemberAssociationsService from '../../services/memberAssociationsService';
import moment from 'moment';

const { confirm } = Modal;

const RenderStatus = (status) => {
  if (status === 'SENT') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Wysłano'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'AWAITING') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Oczekuje'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'IN_PROGRESS') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'W trakcie wysyłki'}>
          <span className={'dot orange'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const getSendingMethod = (method) => {
  if (method === 'NOTIFICATION_AND_EMAIL') {
    return 'Przez system i e-mail';
  } else if (method === 'SMS') {
    return 'SMS';
  } else {
    return '';
  }
};

const getSendToType = (type) => {
  if (type === 'FLOOR') {
    return 'Wybrane piętra';
  } else if (type === 'PREMISES') {
    return 'Wybrane lokale';
  } else if (type === 'MEMBERS') {
    return 'Wybrani członkowie';
  } else if (type === 'ALL') {
    return 'Wszyscy';
  } else {
    return '';
  }
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = ({ userData }) => {
  const query = useQuery();
  const datagridRef = useRef();
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState();
  const [investment, setInvestment] = useState({});
  const [showTemplates, setShowTemplates] = useState(
    query.get('isTemplate') === 'true'
  );

  useEffect(() => {
    const investmentId = query.get('investmentId') || null;
    if (investmentId) {
      setId(investmentId);
    } else {
      const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
      if (choosenInvestmentId) {
        setId(JSON.parse(choosenInvestmentId));
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data.investment);
        setLoading(false);
      });
    } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
      const InvestmentsService = new MemberAssociationsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data);
        setLoading(false);
      });
    }
  }, [id, userData]);

  const columns = [
    ...(showTemplates
      ? [
          {
            title: 'Nazwa',
            dataIndex: 'templateName',
            visible: true,
            filterEnabled: true,
            filterFullWidth: true,
            key: 'templateName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
          },
        ]
      : [
          {
            title: 'Temat',
            dataIndex: 'title',
            visible: true,
            filterEnabled: true,
            key: 'title',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: 'Data wysyłki',
            dataIndex: 'scheduledSendDate',
            visible: true,
            key: 'scheduledSendDate',
            sorter: true,
            export: true,
            render: (text) => text?.replace('T', ', '),
            exportRender: (text) => text?.replace('T', ', '),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            visible: true,
            filterEnabled: true,
            filterType: 'select',
            filterSelectMultiple: true,
            filterOptions: [
              { value: 'SENT', label: 'Wysłano' },
              { value: 'AWAITING', label: 'Oczekuje' },
              { value: 'IN_PROGRESS', label: 'W trakcie wysyłki' },
            ],
            key: 'statuses',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text) => (text ? RenderStatus(text) : ''),
            exportRender: (text, record) => {
              if (record?.status) {
                if (record.status === 'SENT') {
                  return 'Wysłano';
                } else if (record.status === 'AWAITING') {
                  return 'Oczekuje';
                }
                if (record.status === 'IN_PROGRESS') {
                  return 'W trakcie wysyłki';
                }
              } else {
                return '';
              }
            },
          },
          {
            title: 'Data wysyłki od',
            dataIndex: 'sendDateFrom',
            visible: false,
            sorter: false,
            filterEnabled: true,
            filterType: 'date',
            key: 'sendDateFrom',
            export: false,
          },
          {
            title: 'Data wysyłki do',
            dataIndex: 'sendDateTo',
            visible: false,
            sorter: false,
            filterEnabled: true,
            filterType: 'date',
            key: 'sendDateTo',
            export: false,
          },
        ]),
    {
      title: 'Sposób wysłania',
      dataIndex: 'sendingMethod',
      visible: true,
      sorter: true,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: [
        { value: 'NOTIFICATION_AND_EMAIL', label: 'Przez system i e-mail' },
        { value: 'SMS', label: 'SMS' },
      ],
      key: 'sendingMethods',
      export: true,
      render: (text) => (text ? getSendingMethod(text) : ''),
      exportRender: (text, record) =>
        record?.sendingMethod ? getSendingMethod(record.sendingMethod) : '',
    },
    {
      title: 'Odbiorcy',
      dataIndex: 'sendToType',
      visible: true,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: [
        { value: 'FLOOR', label: 'Wybrane piętra' },
        { value: 'PREMISES', label: 'Wybrane lokale' },
        { value: 'MEMBERS', label: 'Wybrani członkowie' },
        { value: 'ALL', label: 'Wszyscy' },
      ],
      key: 'sendToTypes',
      sorter: true,
      export: true,
      render: (text) => (text ? getSendToType(text) : ''),
      exportRender: (text, record) =>
        record?.sendToType ? getSendToType(record.sendToType) : '',
    },
  ];

  if (
    userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') ||
    query.get('userId')
  ) {
    columns.push({
      title: 'Głos',
      dataIndex: 'vote',
      visible: true,
      filterEnabled: false,
      key: 'vote',
      sorter: true,
      export: true,
      render: (text, record) => {
        if (record?.vote === 'PRO') {
          return <span style={{ color: '#15D55E', fontWeight: 700 }}>ZA</span>;
        } else if (record?.vote === 'CON') {
          return (
            <span style={{ color: '#EC434C', fontWeight: 700 }}>PRZECIW</span>
          );
        } else if (record?.vote === 'ABSTAIN') {
          return (
            <span style={{ color: '#8FB3D1', fontWeight: 700 }}>
              WSTRZYMUJĘ SIĘ
            </span>
          );
        }
      },
      exportRender: (text, record) => {
        if (record?.vote === 'PRO') {
          return 'ZA';
        } else if (record?.vote === 'CON') {
          return 'PRZECIW';
        } else if (record?.vote === 'ABSTAIN') {
          return 'WSTRZYMUJĘ SIĘ';
        }
      },
    });
  }

  const menu = () => (
    <Menu>
      <Menu.Item
        key='1'
        icon={<Icon name='email' style={{ marginRight: '5px' }} />}
      >
        <Link
          to={`investment/${id}/messages/create`}
          className='premises-custom-create-link'
        >
          Wiadomość
        </Link>
      </Menu.Item>
      <Menu.Item
        key='2'
        icon={
          <Icon
            name='template'
            style={{ marginRight: '5px', color: '#587999' }}
          />
        }
      >
        <Link
          to={`investment/${id}/messages/create?isTemplate=true`}
          className='premises-custom-create-link'
        >
          Szablon
        </Link>
      </Menu.Item>
    </Menu>
  );

  const handleSendNow = (record) => {
    confirm({
      title: 'Ta wiadomość jest zaplanowana!',
      content:
        'Czy na pewno chcesz wysłać wiadomość przed zaplanowanym terminem?',
      okText: 'Wyślij teraz',
      okType: 'primary',
      cancelText: 'Nie wysyłaj',
      icon: false,
      width: 450,
      className: 'start-resolution-modal',
      cancelButtonProps: {
        className: 'ant-btn-dangerous',
        size: 'large',
      },
      okButtonProps: { size: 'large' },
      onOk() {
        const MessagesService = new messagesService(record.investmentId);
        MessagesService.updateFields(record.id, {
          scheduledSendDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        }).then(() => {
          datagridRef.current.refresh();
        });
      },
    });
  };

  const CustomCreate = () => {
    if (
      !(
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      )
    ) {
      return '';
    }

    return (
      <div>
        <Dropdown
          overlay={() => menu()}
          trigger='click'
          placement='bottomCenter'
          className='test'
        >
          <Button type='primary' style={{ minWidth: 105 }}>
            Nowa
            <Icon name='plus' style={{ marginLeft: 10 }} />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Wiadomości</span>;
    }

    return (
      <span>
        Wiadomości: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            ref={datagridRef}
            title={
              query.get('investmentId') ||
              query.get('investmentType') ||
              query.get('type') ||
              query.get('userId') ? (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  backIcon={<Icon name='arrow-simply-left' />}
                  onBack={() => window.history.back()}
                  title={<HeaderTitle />}
                />
              ) : (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  title={<HeaderTitle />}
                />
              )
            }
            exportTitle={'Wiadomości'}
            resource={`investment/${id}/messages`}
            dataProvider={new messagesService(id)}
            columns={columns}
            customCreate={<CustomCreate />}
            customShowAllMembers={
              <span>
                <Button
                  className={`button-secondary all-members-btn ${
                    !showTemplates && 'show-all-members-btn'
                  }`}
                  onClick={() => {
                    setShowTemplates(false);
                  }}
                >
                  Wiadomości
                </Button>
                <Button
                  className={`button-secondary all-members-btn ${
                    showTemplates && 'show-all-members-btn'
                  }`}
                  onClick={() => {
                    setShowTemplates(true);
                  }}
                >
                  Szablony
                </Button>
              </span>
            }
            filters={{
              isTemplate: showTemplates,
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            hideCreate
            hideReset
            hideSms
            hideEmail
            hideEdit
            hideDelete={
              !(
                userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
              )
            }
            customRowActions={[
              ...(showTemplates
                ? []
                : [
                    (record) => {
                      if (record.status === 'AWAITING') {
                        return (
                          <Tooltip title={'Wyślij teraz'}>
                            <Icon
                              name='send'
                              style={{ cursor: 'pointer', fontSize: 16 }}
                              onClick={() => handleSendNow(record)}
                            />
                          </Tooltip>
                        );
                      }
                    },
                  ]),
              (record) => {
                if (record.status === 'AWAITING') {
                  return (
                    <Link
                      to={`/investment/${record.investmentId}/messages/${record.id}/edit`}
                    >
                      <Button className='button-no-border'>
                        <Icon name='edit' />
                      </Button>
                    </Link>
                  );
                }
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default List;
