import BaseService from './baseService';
import httpService from '../helpers/httpService';

class TenantChangesAttachmentsService extends BaseService {
  constructor(investmentId, premisesId, tenantChangeGroupId, tenantChangeId) {
    super();
    const roles = localStorage.getItem('roles');
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${tenantChangeId}/attachment`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${tenantChangeId}/attachment`;
    }
  }

  async updateData(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }
}

export default TenantChangesAttachmentsService;
