import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Menu,
} from 'antd';

import moment from 'moment';
import PremisesServiceS from '../../../../services/premisesService';
import investmentsService from '../../../../services/investmentsService';
import memberInvestmentPremisesService from '../../../../services/memberInvestmentPremisesService';
import Icon from '../../../../components/Icon';
import deleteConfirm from '../../../../components/deleteConfirm';

import Input from '../../../../components/InputComponent';
import Tabs from '../../../../components/Tabs';
import MessagePopup from '../../../../components/MessagePopup';
import { Contacts } from '../components/Contacts';
import { Tenants } from '../components/Tenants';
// import { Faults } from '../grids/Faults';

const {
  Select,
  InputNumber,
  InputNumberV2,
  InputNumberInt,
  TextArea,
  DatePicker,
  Switch,
} = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const Edit = ({ premise }) => {
  const { id, premisesId } = useParams();

  const [premisesData, setPremisesData] = useState({});
  const [currentStatus, setCurrentStatus] = useState('');
  const [investment, setInvestment] = useState({});
  const [tenantModalVisible, setTenantModalVisible] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(premise);
  }, [premise]);

  const onFinish = () => {
    history.replace(`/memberCommunities/${id}/premises/${premisesId}/show`);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const HeaderTitle = () => {
    if (!premise.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{premise.name}</span>
      </span>
    );
  };

  return (
    <div className='form-create premises-edit'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey='1' tabPosition='left'>
                <TabPane tab='Najemcy' key='tenants'>
                  <Tenants investmentId={id} premisesId={premisesId} />
                </TabPane>
                <TabPane tab='Dodatkowe kontakty' key='additionalContacts'>
                  <Contacts investmentId={id} premisesId={premisesId} />
                </TabPane>
                {/* <TabPane tab='Usterki' key='faults'>
                  <Faults investmentId={id} premisesId={premisesId} />
                </TabPane> */}
              </Tabs>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col xs={11} sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col xs={11} sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};

export default Edit;
