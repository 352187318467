import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  PageHeader, Button, Row, Col, Card,
} from 'antd';
import developersService from '../../services/developersService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (customUserData) {
      setUserData(customUserData);
      setLoading(false);
    } else {
      developersService.getOne(id).then((data) => {
        setUserData(data);
        setLoading(false);
      });
    }
  }, [id, customUserData]);

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/developers')}
          backIcon={customTitle ? '' : <Icon name="arrow-simply-left" />}
          title={customTitle || 'Wróć'}
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            <Link key="2" to={customTitle ? '/myAccount/edit' : `/developers/${userData.id}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>,
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className="left">
                  <div className="logo">
                    <img src={`${baseUrl}${userData.avatar}`} alt={userData.name} />
                  </div>
                  <div className="name">
                    {userData.nameDeveloper}
                  </div>
                  <div className="buttons">
                    {userData.mobile && (
                    <div className="call">
                      <a href={`tel:${userData.mobile}`}>
                        <Button type="primary" size="large">
                          <Icon name="phone" />
                          Zadzwoń
                        </Button>
                      </a>
                    </div>
                    )}
                    <div className="write">
                      <a href={`mailto:${userData.email}`}>
                        <Button type="primary" size="large">
                          <Icon name="email" />
                          {' '}
                          Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className="right">
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="user" />
                        Informacje
                      </h2>
                    )}
                    className="informations"
                  >
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <div className="title">Dane firmowe:</div>
                        <div className="idNumber">
                          <div>
                            Nazwa firmy:
                            {' '}
                            {userData.name}
                          </div>
                          <div>
                            NIP:
                            {' '}
                            {userData.nip}
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className="title">Dane kontaktowe:</div>
                        <div className="email">{userData.email}</div>
                        <div className="phone">{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={24}>
                        <Address title="Adres firmy" address={userData.address} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24}>
                        <Address title="Adres korespondencyjny" address={userData.correspondenceAddress ? userData.correspondenceAddress : {}} />
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="access" />
                        Uprawnienia
                      </h2>
                    )}
                    className="access"
                  >
                    <h2>Zarządzanie nieruchomościami</h2>
                    <Row>
                      <Col xs={24} sm={6}>
                        Sprzedaż
                      </Col>
                      <Col sxs={24} smm={18} className="value">
                        {userData.roles.includes('ROLE_DEVELOPER') ? 'TAK' : 'NIE'}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={6}>
                        Najem
                      </Col>
                      <Col xs={24} sm={18} className="value">
                        {userData.roles.includes('ROLE_TENANT') ? 'TAK' : 'NIE'}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
