import httpService from '../helpers/httpService';

const massActionsService = {};

massActionsService.sendEmails = async (content, userIds) => {
  const roles = localStorage.getItem('roles');

  const promises = [];

  userIds.map((userId) => {
    let url = `/api/admin/user/${userId}/notification/email/custom`;

    if (roles?.includes('ROLE_COMMUNITY_ADMIN') || roles?.includes('ROLE_WORKER')) {
      url = `/api/community/user/${userId}/notification/email/custom`;
    }

    promises.push(httpService.post(url, { content: content }, true));
  });

  return Promise.all(promises);
};

massActionsService.sendSmses = async (content, userIds) => {
  const roles = localStorage.getItem('roles');

  const promises = [];

  userIds.map((userId) => {
    let url = `/api/admin/user/${userId}/notification/sms/custom`;

    if (roles?.includes('ROLE_COMMUNITY_ADMIN') || roles?.includes('ROLE_WORKER')) {
      url = `/api/community/user/${userId}/notification/sms/custom`;
    }

    promises.push(httpService.post(url, { content: content }, true));
  });

  return Promise.all(promises);
};

export default massActionsService;
