import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip, Button, message } from 'antd';
import MessagePopup from '../../components/MessagePopup';
import Icon from '../../components/Icon';
import Datagrid from '../../components/Datagrid';
import Spin from '../../components/Spin';
import housingSettlementsService from '../../services/housingSettlementsService';
import settlementTemplatesService from '../../services/settlementTemplatesService';
import premisesSettlementsService from '../../services/premisesSettlementsService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import { ChoosenAllSwitch } from './components/ChoosenAllSwitch';
import GenerateModal from './components/GenerateModal';
import housingAssociationsService from '../../services/housingAssociationsService';
import premisesService from '../../services/premisesService';
import ClientsService from '../../services/clientsService';
import moment from 'moment';

const RenderStatus = (status) => {
  if (status === 'TO_PAY') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const getRowClassName = (record, index) => {
  if (record.status === 'TO_PAY') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = ({ userData }) => {
  const history = useHistory();
  const query = useQuery();
  const datagridRef = useRef();
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [id, setId] = useState();
  const [settlementData, setSettlementData] = useState();
  const [generateModalVisible, setGenerateModalVisible] = useState(false);
  const [premises, setPremises] = useState([]);
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filterInvestmentId, setFilterInvestmentId] = useState();
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    ClientsService.getList({
      role: 'ROLE_COMMUNITY_MEMBER',
      size: 9999,
    }).then((data) => {
      setOwners(data.content);
    });
  }, []);

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.getList({
      types: ['HOUSING_ASSOCIATION', 'HOUSING_COMMUNITY'],
    }).then((data) => {
      setInvestments(data.content);
    });
  }, []);

  useEffect(() => {
    if (showAll) {
      if (filterInvestmentId) {
        const PremisesService = new premisesService(filterInvestmentId);
        PremisesService.getList().then((response) => {
          setInvestmentPremises(response.content);
          setPremises(response.content);
        });
      } else {
        setInvestmentPremises([]);
        setPremises([]);
      }
    } else {
      const PremisesService = new premisesService(id);
      PremisesService.getList().then((response) => {
        setInvestmentPremises(response.content);
        setPremises(response.content);
      });
    }

    setLoading(false);
  }, [id, filterInvestmentId, showAll]);

  useEffect(() => {
    const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
    if (choosenInvestmentId) {
      setId(JSON.parse(choosenInvestmentId));
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.getList()
      .then((response) => {
        setSettlementData(response);
      })
      .catch((err) => {});

    setLoading(false);
  }, [id]);

  const testGenerateSettlements = () => {
    setGenerateModalVisible(true);
  };

  const columns = [
    {
      title: 'Nazwa rozliczenia',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      filterFullWidth: !showAll,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    ...(showAll
      ? [
          {
            title: 'Nieruchomość',
            dataIndex: 'investmentIds',
            visible: true,
            key: 'investmentIds',
            sorter: false,
            filterEnabled: true,
            filterType: 'select',
            filterOptions: investments.map((investment) => ({
              value: investment.id,
              label: investment.name,
            })),
            onFilterChange: (e) => setFilterInvestmentId(e),
            export: true,
            render: (text, source) => {
              return source.investmentName;
            },
            exportRender: (text, source) => {
              return source.investmentName;
            },
          },
        ]
      : []),
    {
      title: 'Lokal',
      dataIndex: 'premises',
      visible: true,
      filterEnabled: false,
      key: 'premises',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
      exportRender: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
    },
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesType',
      visible: false,
      key: 'premisesType',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'RENT',
          label: 'Mieszkanie',
        },
        {
          value: 'STORAGE',
          label: 'Komórka lokatorska',
        },
        {
          value: 'PARKING',
          label: 'Miejsce postojowe',
        },
        {
          value: 'SERVICE',
          label: 'Lokal usługowy',
        },
      ],
      onFilterChange: (e) => {
        setPremises(
          investmentPremises.filter((premise) => {
            if (!e) {
              return true;
            } else {
              return premise.type === e;
            }
          })
        );
      },
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesIds',
      visible: false,
      key: 'premisesIds',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: premises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      export: false,
      requireIfSet: {
        field: 'premisesType',
        message: 'Jeśli wybrałeś typ lokalu wybierz lokale',
      },
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      visible: true,
      filterEnabled: false,
      key: 'createDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'PAID',
          label: (
            <span>
              <span className={`dot green`} style={{ marginRight: 8 }} />
              Zapłacone
            </span>
          ),
        },
        {
          value: 'PARTIALLY_PAID',
          label: (
            <span>
              <span className={`dot yellow`} style={{ marginRight: 8 }} />
              Częściowo zapłacone
            </span>
          ),
        },
        {
          value: 'TO_PAY',
          label: (
            <span>
              <span className={`dot red`} style={{ marginRight: 8 }} />
              Do zapłaty
            </span>
          ),
        },
      ],
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? RenderStatus(text) : ''),
      exportRender: (text) => {
        if (text) {
          if (text === 'TO_PAY') {
            return 'Do zapłaty';
          } else if (text === 'PARTIALLY_PAID') {
            return 'Częściowo zapłacone';
          }
          if (text === 'PAID') {
            return 'Zapłacone';
          }
        } else {
          return '';
        }
      },
    },
    // {
    //   title: 'Właściciel',
    //   dataIndex: 'owner',
    //   visible: true,
    //   filterEnabled: false,
    //   key: 'owner',
    //   sorter: false,
    //   export: true,
    //   render: (text, record) =>
    //     record.owner
    //       ? `${record.owner.firstName} ${record.owner.lastName}`
    //       : '',
    //   exportRender: (text, record) =>
    //     record.owner
    //       ? `${record.owner.firstName} ${record.owner.lastName}`
    //       : '',
    // },
    {
      title: 'Właściciel',
      dataIndex: 'ownerIds',
      visible: false,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: owners.map((owner) => ({
        value: owner.id,
        label: [owner.firstName, owner.lastName].filter((e) => !!e).join(' '),
      })),
      key: 'ownerIds',
      sorter: false,
      export: false,
    },
    {
      title: 'Data dodania od',
      dataIndex: 'createDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateFrom',
      export: false,
    },
    {
      title: 'Data dodania do',
      dataIndex: 'createDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'createDateTo',
      export: false,
    },
    {
      title: 'Termin płatności',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Termin płatności od',
      dataIndex: 'paymentDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateFrom',
      export: false,
    },
    {
      title: 'Termin płatności do',
      dataIndex: 'paymentDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'paymentDateTo',
      export: false,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paidDate',
      visible: false,
      filterEnabled: false,
      key: 'paidDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'price',
      visible: true,
      filterEnabled: false,
      key: 'price',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  const CustomCreate = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {userData.testAccount && (
          <Button
            key='99'
            type='primary'
            onClick={() => testGenerateSettlements()}
            style={{ marginRight: 8 }}
          >
            Generuj rozliczenia
            <Icon name='plus' style={{ marginLeft: 5 }} />
          </Button>
        )}

        <Button
          className='button-secondary import-button'
          onClick={() =>
            history.push(`/investment/${id}/housingSettlements/import`)
          }
          style={{ marginRight: 8 }}
        >
          <Icon name='download' style={{ marginRight: 5 }} />
          Importuj potwierdzenia
        </Button>
        <Button
          type='primary counters-edit-btn'
          onClick={() =>
            history.push(
              settlementData
                ? `/investment/${id}/housingSettlements/edit`
                : `/investment/${id}/housingSettlements/create`
            )
          }
        >
          {settlementData ? (
            <>
              <Icon name='edit' style={{ marginRight: 5 }} />
              Edytuj wzór
            </>
          ) : (
            <>
              <Icon name='plus' style={{ marginRight: 5 }} />
              Dodaj wzór
            </>
          )}
        </Button>
      </div>
    );
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          {userData.testAccount && (
            <GenerateModal
              isModalVisible={generateModalVisible}
              afterCancel={() => setGenerateModalVisible(false)}
              id={id}
            />
          )}
          <Datagrid
            ref={datagridRef}
            title='Lista rozliczeń'
            customBaseUrlKey={'housingSettlements'}
            resource={'housingSettlements'}
            dataProvider={housingSettlementsService}
            columns={columns}
            customCreate={!showAll && <CustomCreate />}
            customShowAllMembers={
              <ChoosenAllSwitch showAll={showAll} setShowAll={setShowAll} />
            }
            customRowActions={[
              (record) => {
                if (record.status !== 'PAID') {
                  return (
                    <Icon
                      style={{ cursor: 'pointer' }}
                      name='dolar'
                      className='settlement-transaction-icon'
                      onClick={() => {
                        const PremisesSettlementsService =
                          new premisesSettlementsService(
                            record.investmentId,
                            record.premisesId
                          );
                        const tempData = { ...record };

                        tempData.paid = tempData.price;
                        tempData.paidDate = moment().format('YYYY-MM-DD');

                        PremisesSettlementsService.update(
                          record.id,
                          tempData
                        ).then((response) => {
                          message.success(
                            <MessagePopup type={'success'}>
                              Opłacono
                            </MessagePopup>
                          );
                          datagridRef.current.refresh();
                        });
                      }}
                    />
                  );
                } else {
                  return null;
                }
              },
            ]}
            filters={{
              ...(!showAll && { investmentIds: id }),
              ...((query.get('type') || null) && { status: query.get('type') }),
              ...((query.get('investmentType') || null) && {
                investmentType: query.get('investmentType'),
              }),
              ...(query.get('this_month') === 'TRUE' && {
                createDateFrom: moment().startOf('month').format('YYYY-MM-DD'),
              }),
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            customRowClass={getRowClassName}
            hideCreate
            hideReset
            hideSms
            hideEmail
            hideDelete={!userData.testAccount}
          />
        </div>
      )}
    </div>
  );
};

export default List;
