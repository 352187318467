import React from 'react';
import { Form, Col } from 'antd';
import Datagrid from '../../components/Datagrid';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import workersService from '../../services/workersService';

const { Select } = Input;
const { Option } = Select;

const WorkerIcon = (props) => {
  if (props.record.isBroker) {
    return (
      <span className='broker-icon'>
        <Icon name='percent' />
      </span>
    );
  }

  return '';
};

const columns = [
  {
    dataIndex: 'icon',
    visible: true,
    filterEnabled: false,
    key: 'icon',
    sorter: false,
    width: 60,
    render: (text, record) => <WorkerIcon record={record} />,
    export: false,
  },
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    visible: true,
    filterEnabled: true,
    key: 'lastName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    visible: true,
    filterEnabled: true,
    key: 'firstName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    key: 'mobile',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`tel:${text}`}>
        {text}
      </a>
    ),
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    key: 'email',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <a className='datagrid-link' href={`mailto:${text}`}>
        {text}
      </a>
    ),
  },
];

const customFilters = [];
customFilters.push(
  <Col xs={24} sm={12} key='role'>
    <Form.Item name='role'>
      <Select placeholder='Pośrednik' allowClear size='large'>
        <Option value='ROLE_WORKER'>Nie</Option>
        <Option value='ROLE_WORKER_BROKER'>Tak</Option>
      </Select>
    </Form.Item>
  </Col>
);

const List = () => (
  <div>
    <Datagrid
      title='Lista pracowników'
      resource='workers'
      dataProvider={workersService}
      columns={columns}
      filters={{
        role: 'ROLE_WORKER',
      }}
      sort={{
        sortField: 'lastName',
        sortOrder: 'ascend',
      }}
      customFilters={customFilters}
    />
  </div>
);

export default List;
