import React, { useState, useEffect } from 'react';
import { PageHeader, Card } from 'antd';
import rodoService from '../../services/rodoService';
import Spin from '../../components/Spin';

const Rodo = () => {
  const [loading, setLoading] = useState(true);
  const [rodoContent, setRodoContent] = useState(null);
  useEffect(() => {
    rodoService.verify().then((data) => {
      setRodoContent(data.content);
      setLoading(false);
    });
  }, []);

  return loading ? (
    <div className='loading'>
      <Spin />
    </div>
  ) : (
    <PageHeader title={'Zgody'}>
      <Card style={{ marginTop: 20 }}>
        <div
          className='rodo-content'
          style={{ fontSize: 20, fontWeight: 600, marginTop: 30 }}
        >
          Klauzula informacyjna RODO
        </div>
        <div className='rodo-content'>{rodoContent}</div>
      </Card>
    </PageHeader>
  );
};

export default Rodo;
