import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import SmallList from '../../../../components/SmallList';

export const InfoBox = ({
  fault, faultGroup,
}) => {
  const workerFieldMapper = (worker) => {
    if (worker.nameDeveloper) {
      return worker.nameDeveloper;
    }

    return `${worker.firstName || ''} ${worker.lastName || ''}`;
  };
  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
      render: (text, record) => workerFieldMapper(fault.responsibleWorkersNames[record]),
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      render: (text, record) => (<span className="premises-broker-phone">{fault.responsibleWorkersNames[record].mobile}</span>),
    },
    {
      title: 'Pesel',
      dataIndex: 'percent',
      render: (text, record) => (fault.responsibleWorkersNames[record].identity ? fault.responsibleWorkersNames[record].identity.pesel : ''),
    },
  ];
  return (
    <div className="premises-show-box info-box">
      <h2>
        <Icon name="more-info" />
        Informacje
      </h2>
      <div className="content">
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Inwestycja:</Col>
          <Col xs={24} sm={10} className="value">
            {faultGroup.investmentName}
          </Col>
        </Row>
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Lokal:</Col>
          <Col xs={24} sm={10} className="value">{faultGroup.premisesName || 'Część wspólna'}</Col>
        </Row>
        {fault.addDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Data wystąpienia usterki:</Col>
            <Col xs={24} sm={10} className="value">{fault.addDate}</Col>
          </Row>
        )}
        {fault.fixDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Usunięto:</Col>
            <Col xs={24} sm={10} className="value">{fault.fixDate}</Col>
          </Row>
        )}
        {fault.responsibleWorkers && fault.responsibleWorkers.length !== 0 && (
        <div style={{ marginTop: '20px' }}>
          <h3>Osoba odpowiedzialna</h3>
          <div className="content">
            <SmallList data={fault.responsibleWorkers} columns={columns} showHeader={false} icon="worker" />
          </div>
        </div>
        )}
      </div>
    </div>
  );
};
