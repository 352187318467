import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import premisesSettlementsService from '../../../services/premisesSettlementsService';
import PermisesService from '../../../services/premisesService';
import moment from 'moment';
import { premiseTypeMap } from '../../../helpers';

const { Select, DatePicker, InputNumberInt, InputNumberV2, Switch } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const { id, premisesId, settlementId } = useParams();
  const [form] = Form.useForm();
  const [settlementData, setSettlementData] = useState({});
  const [premisesData, setPremisesData] = useState({});

  useEffect(() => {
    const PremisesSettlementsService = new premisesSettlementsService(
      id,
      premisesId
    );

    PremisesSettlementsService.getOne(settlementId).then((response) => {
      const tempData = {
        name: response.name,
        status: response.status,
        bankNumber: response.bankNumber,
        price: response.price,
        paid: response.paid,
        paymentDate: response.paymentDate,
        paidDate: response.paidDate,
      };

      if (response.createDate) {
        tempData.createDate = moment(response.createDate, 'YYYY-MM-DD');
      }

      if (response.paymentDate) {
        tempData.paymentDate = moment(response.paymentDate, 'YYYY-MM-DD');
      }

      if (response.paidDate) {
        tempData.paidDate = moment(response.paidDate, 'YYYY-MM-DD');
      }

      setSettlementData(tempData);
      form.setFieldsValue(tempData);
    });
  }, []);

  useEffect(() => {
    const PremisesService = new PermisesService(id);

    PremisesService.getOne(premisesId).then((response) => {
      const tempData = {
        premiseName: response.name,
        premiseType: premiseTypeMap(response.type),
      };

      setPremisesData(tempData);
      form.setFieldsValue(tempData);
    });
  }, []);

  const onFinish = (values) => {
    const tempValues = {
      name: values.name,
      status: values.status,
      bankNumber: values.bankNumber,
      price: values.price,
      paid: values.paid,
      paymentDate: values.paymentDate,
      paidDate: values.paidDate,
    };

    if (values.createDate) {
      tempValues.createDate = values.createDate.format('YYYY-MM-DD');
    }

    if (values.paymentDate) {
      tempValues.paymentDate = values.paymentDate.format('YYYY-MM-DD');
    }

    if (values.paidDate) {
      tempValues.paidDate = values.paidDate.format('YYYY-MM-DD');
    }

    const PremisesSettlementsService = new premisesSettlementsService(
      id,
      premisesId
    );

    PremisesSettlementsService.update(settlementId, tempValues)
      .then((response) => {
        history.goBack();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const HeaderTitle = () => {
    if (!settlementData.name) {
      return <span>Edytujesz</span>;
    }
    return (
      <span>
        Edytujesz:{' '}
        <span className='header-subtitle'>{settlementData.name}</span>
      </span>
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={<HeaderTitle />}
      >
        <Form
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ bankNumberIsDisabled: true }}
          autoComplete='off'
          form={form}
        >
          <Card>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={{ offset: 1, span: 22 }} lg={{ offset: 4, span: 16 }}>
                  <div className='form-section'>
                    <h2>Podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24}>
                        <Form.Item
                          name='name'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Nazwa rozliczenia' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='createDate'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <DatePicker
                            size='large'
                            placeholder='Data dodania'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          hidden
                          name='status'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <Select placeholder='Status' size='large'>
                            <Option value='TO_PAY'>
                              <span
                                className={'dot red'}
                                style={{ marginRight: 8 }}
                              />
                              Do zapłaty
                            </Option>
                            <Option value='PARTIALLY_PAID'>
                              <span
                                className={'dot yellow'}
                                style={{ marginRight: 8 }}
                              />
                              Częściowo zapłacone
                            </Option>
                            <Option value='PAID'>
                              <span
                                className={'dot green'}
                                style={{ marginRight: 8 }}
                              />
                              Zapłacone
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-section'>
                    <h2>Lokal</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='premiseType'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <Input
                            size='large'
                            placeholder='Typ lokalu'
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='premiseName'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <Input
                            size='large'
                            placeholder='Lokal'
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-section'>
                    <h2>Płatności</h2>

                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='paymentDate'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <DatePicker
                            size='large'
                            placeholder='Termin płatności'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='price'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić piętro',
                            },
                          ]}
                        >
                          <InputNumberV2
                            size='large'
                            placeholder='Razem do zapłaty'
                            addonAfter={'zł brutto'}
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item name='paidDate'>
                          <DatePicker
                            size='large'
                            placeholder='Data opłacenia'
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item name='paid'>
                          <InputNumberV2
                            size='large'
                            placeholder='Zapłacono'
                            addonAfter={'zł brutto'}
                            onChange={(e) => {
                              const tempPaid = parseFloat(e.target.value);

                              if (!tempPaid) {
                                form.setFieldValue('status', 'TO_PAY');
                              } else if (
                                tempPaid > 0 &&
                                tempPaid < settlementData.price
                              ) {
                                form.setFieldValue('status', 'PARTIALLY_PAID');
                              } else if (tempPaid >= settlementData.price) {
                                form.setFieldValue('status', 'PAID');
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='create-form-button'
                >
                  Zapisz
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
