import BaseService from './baseService';
import httpService from '../helpers/httpService';

class AgreementsService extends BaseService {
  constructor(investmentId, premisesId, agreementId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/agreement/${agreementId}/attachment`;

    if (investmentId == null && premisesId == null) {
      this.url = `/api/user/agreement/${agreementId}/attachment`;
    }
    const roles = localStorage.getItem('roles');

    if (roles && (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT'))) {
      this.url = `/api/user/premises/${premisesId}/agreement/${agreementId}/attachment`;
    }
  }

  async updateData(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }
}

export default AgreementsService;
