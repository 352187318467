import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import FaultsS from '../../services/faultsService';
import faultGroupService from '../../services/faultGroupService';
import notificationsService from '../../services/notificationsService';
import Icon from '../../components/Icon';

const SingleNotification = ({
  data,
  refresh,
  userData,
  refreshAll,
  hideNotifications,
}) => {
  const { id, isRead, type, referenceId, referenceIds = {} } = data;
  const investmentId = referenceIds?.investmentId || '';
  const faultId = referenceIds?.faultId || '';
  const faultGroupId = referenceIds?.faultGroupId || '';
  const [fault, setFault] = useState({});
  const [redirectUrl, setRedirectUrl] = useState();
  const history = useHistory();

  useEffect(() => {
    if (type === 'FAULT_DEVELOPER_VERIFICATION') {
      const FaultsService = new FaultsS(investmentId, faultGroupId);
      FaultsService.getOne(faultId).then((faultData) => {
        setFault(faultData);
      });
    }

    if (type === 'FAULT_CLIENT_VERIFICATION') {
      const FaultsService = new faultGroupService(investmentId);
      FaultsService.getOne(faultGroupId).then((faultData) => {
        setFault(faultData);
      });
    }
  }, [type, faultId, faultGroupId, investmentId]);

  let isReadClassname = '';
  if (!isRead) {
    isReadClassname = 'notification--unread';
  }

  let iconName = '';
  let iconStyle = {};

  switch (type) {
    case 'PAYMENT_NEW':
    case 'PAYMENT_EXPIRED':
    case 'TRANCHE_EXPIRED':
    case 'PAYMENT_REMINDER':
      iconName = 'payments';
      break;
    case 'FAULT_DEVELOPER_DECISION_NEGATIVE':
    case 'FAULT_DEVELOPER_DECISION_POSITIVE':
    case 'FAULT_CLIENT_DECISION_NEGATIVE':
    case 'FAULT_CLIENT_DECISION_POSITIVE':
    case 'FAULT_DEVELOPER_VERIFICATION':
    case 'FAULT_RESPONSIBLE_PERSON':
    case 'FAULT_GROUP_RESPONSIBLE_PERSON':
    case 'FAULT_CLIENT_VERIFICATION':
    case 'FAULT_NEW':
    case 'FAULT_REPORTED_RESOLVE_REMINDER':
      iconName = 'fault-single';
      break;
    case 'PREMISES_STATUS':
      iconName = 'apartments';
      break;
    case 'AGREEMENT_NEW':
    case 'AGREEMENT_END':
      iconName = 'file';
      break;
    default:
      iconName = '';
  }

  if (type.indexOf('EXPIRED') !== -1) {
    iconStyle = { color: 'red' };
  }

  const faultDecisionAction = (isCorrect) => {
    const FaultsService = new FaultsS(investmentId, faultGroupId);
    FaultsService.updateConfirmation(fault.id, {
      isCorrect,
    })
      .then(() => {
        FaultsService.getOne(fault.id).then((faultData) => {
          setFault(faultData);
          notificationsService
            .updateFields(data.id, {
              isButtonClicked: true,
            })
            .then(() => refresh());
        });
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
  };

  const faultGroupDecisionAction = (isConfirmed) => {
    const FaultsService = new faultGroupService(investmentId);
    FaultsService.updateConfirmation(faultGroupId, isConfirmed)
      .then(() => {
        FaultsService.getOne(faultGroupId).then((faultData) => {
          setFault(faultData);
          notificationsService
            .updateFields(data.id, {
              isButtonClicked: true,
            })
            .then(() => refresh());
        });
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
  };

  useEffect(() => {
    if (
      [
        'COMMUNITY_SETTLEMENT_PAYMENT_EXPIRED',
        'COMMUNITY_SETTLEMENT_OWNER_CREATE',
        'COMMUNITY_SETTLEMENT_TENANT_CREATE',
      ].includes(type)
    ) {
      if (referenceIds?.investmentId && referenceIds?.premisesId) {
        if (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN')) {
          setRedirectUrl(
            `/investment/${referenceIds.investmentId}/premises/${referenceIds.premisesId}/housingSettlements/${referenceId}/show`
          );
        } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
          setRedirectUrl(
            `/investment/${referenceIds.investmentId}/premises/${referenceIds.premisesId}/memberSettlements/${referenceId}/show`
          );
        } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER_TENANT')) {
          setRedirectUrl(
            `/investment/${referenceIds.investmentId}/premises/${referenceIds.premisesId}/tenantSettlements/${referenceId}/show`
          );
        }
      }
    } else if (type === 'COMMUNITY_SETTLEMENT_EXPIRED') {
      if (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN')) {
        setRedirectUrl(`/investment/${referenceId}/housingSettlements`);
      } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        setRedirectUrl(`/investment/${referenceId}/memberSettlements`);
      } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER_TENANT')) {
        setRedirectUrl(`/investment/${referenceId}/tenantSettlements`);
      }
    } else if (
      [
        'RESOLUTION_SUCCESSFUL_END',
        'RESOLUTION_UNSUCCESSFUL_END',
        'RESOLUTION_START',
        'RESOLUTION_REMINDER',
      ].includes(type)
    ) {
      if (referenceIds?.investmentId) {
        setRedirectUrl(
          `/investment/${referenceIds.investmentId}/resolutions/${referenceId}/show`
        );
      }
    } else if (type === 'DOCUMENT_NEW') {
      if (referenceIds?.investmentId) {
        setRedirectUrl(`documents?investmentId=${referenceIds.investmentId}`);
      }
    } else if (type === 'COMMUNITY_FAULT_DEVELOPER_VERIFY') {
      if (
        referenceIds?.faultGroupId &&
        referenceIds?.fault &&
        referenceIds.investmentId
      ) {
        setRedirectUrl(
          `/faultGroup/${referenceIds.investmentId}/faultGroup/${referenceIds.faultGroupId}/fault/${referenceIds.fault}/show`
        );
      }
    } else if (
      [
        'COMMUNITY_FAULT_ADMIN_VERIFY',
        'COMMUNITY_FAULT_VERIFY_CONFIRMATION_FOR_MEMBER_NEGATIVE',
        'COMMUNITY_FAULT_VERIFY_CONFIRMATION_FOR_MEMBER_POSITIVE',
      ].includes(type)
    ) {
      if (
        referenceIds?.faultGroupId &&
        referenceIds?.fault &&
        referenceIds.investmentId
      ) {
        setRedirectUrl(
          `/housingFaultGroup/${referenceIds.investmentId}/faultGroup/${referenceIds.faultGroupId}/fault/${referenceIds.fault}/show`
        );
      }
    } else if (type === 'PACKAGE_INCREASE_SMS_LIMIT') {
      setRedirectUrl('/myAccount/edit?package');
    }
  }, [type, userData]);

  const redirectHandler = useCallback(() => {
    notificationsService
      .updateFields(id, {
        isRead: true,
      })
      .then(() => {
        refreshAll();
        hideNotifications();
        history.push(redirectUrl);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
  }, [redirectUrl]);

  return (
    <div className={`notification ${isReadClassname}`} role='presentation'>
      <Row onClick={redirectHandler} style={{ cursor: 'pointer' }}>
        <Col xs={2}>
          <div className='icon-wrapper'>
            <Icon name={iconName} style={iconStyle} />
            {!iconName && redirectUrl && !isRead ? (
              <span className={`dot red`} style={{ width: 6, height: 6 }} />
            ) : null}
          </div>
        </Col>
        <Col xs={20}>
          <div className='content'>
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              className='description'
            />
            {type === 'FAULT_DEVELOPER_VERIFICATION' &&
              fault.isCorrect === null &&
              data.isButtonClicked === false && (
                <div className='fault-actions'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => faultDecisionAction(true)}
                    style={{ marginRight: '5px', minWidth: 0, minHeight: 0 }}
                  >
                    Potwierdź
                  </Button>
                  <Button
                    size='small'
                    className='button-secondary'
                    onClick={() => faultDecisionAction(false)}
                  >
                    Odrzuć
                  </Button>
                </div>
              )}
            {type === 'FAULT_CLIENT_VERIFICATION' &&
              fault.isConfirmed === null &&
              data.isButtonClicked === false && (
                <div className='fault-actions'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => faultGroupDecisionAction(true)}
                    style={{ marginRight: '5px' }}
                  >
                    Potwierdź
                  </Button>
                  <Button
                    size='small'
                    className='button-secondary'
                    onClick={() => faultGroupDecisionAction(false)}
                  >
                    Odrzuć
                  </Button>
                </div>
              )}
            <div className='time'>
              {moment(`${data.createDate}Z`)
                .locale('pl_PL')
                .startOf('minutes')
                .fromNow()}
            </div>
          </div>
        </Col>
        {redirectUrl && (
          <Col xs={2}>
            <div className='notifications-arrow-wrapper'>
              <Tooltip
                title={'Zobacz więcej'}
                placement={'bottomLeft'}
                showArrow={false}
              >
                <Icon
                  name='arrow-simply-right'
                  className='notifications-arrow'
                />
              </Tooltip>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SingleNotification;
