import React, { useEffect, useState } from 'react';
import { Button, Row, message, Modal } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import faultsService from '../../../../services/faultsService';
import memberAssociationsService from '../../../../services/memberAssociationsService';
import housingAssociationsService from '../../../../services/housingAssociationsService';
import MessagePopup from '../../../../components/MessagePopup';
import clientsService from '../../../../services/clientsService';

const { confirm } = Modal;

export const PassFault = ({ userData, faultGroup, fault, setFault }) => {
  const { investmentId, faultGroupId, faultId } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState();
  const [reporterData, setReporterData] = useState({});

  useEffect(() => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      const HousingAssociationsService = new housingAssociationsService();

      HousingAssociationsService.getDetails(investmentId).then((data) => {
        setInvestment(data?.investment);
      });

      if (fault?.reporterId) {
        clientsService.getOne(fault.reporterId).then((data) => {
          setReporterData(data);
        });
      }
    }

    if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
      const MemberAssociationsService = new memberAssociationsService();

      MemberAssociationsService.getDetails(investmentId).then((data) => {
        setInvestment(data);
      });
    }
  }, []);

  const passToDeveloperHandler = () => {
    if (investment?.developerId) {
      const tempData = { ...fault, isDeveloperNotified: true };

      const FaultsService = new faultsService(investmentId, faultGroupId);

      if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        FaultsService.update(faultId, tempData).then((faultData) =>
          setFault(faultData)
        );
      } else if (
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        FaultsService.updateFields(faultId, { isDeveloperNotified: true }).then(
          (faultData) => setFault(faultData)
        );
      }
    } else if (investment?.developer) {
      history.push(
        `/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${faultId}/faultTemplate`
      );
    } else {
      if (
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        confirm({
          title: 'Nie można przekazać usterki!',
          content:
            'Aby przekazywać usterki uzupełnij dane Dewelopera w edycji spółdzielni.',
          okText: 'Dodaj Dewelopera',
          okType: 'primary',
          cancelText: 'Anuluj',
          icon: false,
          width: 440,
          className: 'delete-confirm-modal pass-fault-modal',
          cancelButtonProps: {
            className: 'button-secondary',
            size: 'large',
          },
          okButtonProps: { size: 'large' },
          onOk() {
            if (investment?.type === 'HOUSING_ASSOCIATION') {
              history.push(
                `/housingAssociations/${investmentId}/edit?developer`
              );
            } else if (investment?.type === 'HOUSING_COMMUNITY') {
              history.push(
                `/housingCommunities/${investmentId}/edit?developer`
              );
            }
          },
        });
      } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        message.error(
          <MessagePopup type={'error'}>
            <div style={{ fontSize: 16 }}>
              Nie można przekazać usterki do Dewelopera.
            </div>
            <div style={{ fontSize: 16 }}>
              Skontaktuj się z Administratorem Nieruchomości!
            </div>
          </MessagePopup>
        );
      }
    }
  };

  const passToCommunityAdminHandler = () => {
    const tempData = { ...fault, isAdminNotified: true };

    const FaultsService = new faultsService(investmentId, faultGroupId);
    FaultsService.update(faultId, tempData).then((faultData) =>
      setFault(faultData)
    );
  };

  const acceptFaultHandler = () => {
    const FaultsService = new faultsService(investmentId, faultGroupId);

    FaultsService.updateFields(faultId, { isCorrect: true }).then((faultData) =>
      setFault(faultData)
    );
  };

  const denyFaultHandler = () => {
    const FaultsService = new faultsService(investmentId, faultGroupId);

    FaultsService.updateFields(faultId, { isCorrect: false }).then(
      (faultData) => setFault(faultData)
    );
  };

  if (
    userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') &&
    (fault?.status === 'VERIFICATION' || fault?.status === 'REPORTED')
  ) {
    return (
      <div className='premises-show-box pass-fault-box'>
        {faultGroup.premisesId ? (
          <>
            <div className='pass-fault-content'>
              {fault.isDeveloperNotified ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Usterka{' '}
                  </span>
                  <span style={{ marginRight: 5 }}>
                    oczekuje na weryfikację zasadności
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    przez Dewelopera. Termin potwierdzenia wynosi 14 dni.
                  </span>
                </Row>
              ) : (
                <Row gutter={20}>Należy przekazać usterkę do Dewelopera!</Row>
              )}
            </div>
            {fault.isDeveloperNotified ? (
              <Button className='passed-fault-button'>
                Przekazano do Dewelopera
              </Button>
            ) : (
              <Button
                className='pass-fault-button'
                onClick={passToDeveloperHandler}
              >
                Przekaż do Dewelopera
              </Button>
            )}
          </>
        ) : (
          <>
            <div className='pass-fault-content'>
              {fault.isDeveloperNotified ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Usterka{' '}
                  </span>
                  <span style={{ marginRight: 5 }}>
                    oczekuje na weryfikację zasadności
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    przez Dewelopera. Termin potwierdzenia wynosi 14 dni.
                  </span>
                </Row>
              ) : fault.isAdminNotified ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Usterka{' '}
                  </span>
                  <span style={{ marginRight: 5 }}>
                    oczekuje na weryfikację zasadności
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    przez Administratora Nieruchomości. Termin potwierdzenia
                    wynosi 14 dni.
                  </span>
                </Row>
              ) : (
                <Row gutter={20}>
                  Należy przekazać usterkę do Administratora Nieruchomości!
                </Row>
              )}
            </div>
            {fault.isDeveloperNotified ? (
              <Button className='passed-fault-button'>
                Przekazano do Dewelopera
              </Button>
            ) : fault.isAdminNotified ? (
              <Button className='passed-fault-button'>
                Przekazano do Administratora
              </Button>
            ) : (
              <Button
                className='pass-fault-button'
                onClick={passToCommunityAdminHandler}
              >
                Przekaż do Administratora
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  if (
    (userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
    (fault?.status === 'VERIFICATION' || fault?.status === 'REPORTED')
  ) {
    return (
      <div className='premises-show-box pass-fault-box'>
        {faultGroup.premisesId ? (
          <>
            <div className='pass-fault-content'>
              {fault.isDeveloperNotified ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Usterka{' '}
                  </span>
                  <span style={{ marginRight: 5 }}>
                    oczekuje na weryfikację zasadności
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    przez Dewelopera. Termin potwierdzenia wynosi 14 dni.
                  </span>
                </Row>
              ) : (
                <Row gutter={20}>Należy przekazać usterkę do Dewelopera!</Row>
              )}
            </div>
            {fault.isDeveloperNotified ? (
              <Button className='passed-fault-button'>
                Przekazano do Dewelopera
              </Button>
            ) : (
              <Button
                className='pass-fault-button'
                onClick={passToDeveloperHandler}
              >
                Przekaż do Dewelopera
              </Button>
            )}
          </>
        ) : (
          <>
            <div className='pass-fault-content'>
              {fault.isDeveloperNotified ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Usterka{' '}
                  </span>
                  <span style={{ marginRight: 5 }}>
                    oczekuje na weryfikację zasadności
                  </span>
                  <span style={{ fontWeight: 400 }}>
                    przez Dewelopera. Termin potwierdzenia wynosi 14 dni.
                  </span>
                </Row>
              ) : userData?.id === fault?.reporterId ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN') &&
                  fault?.reporterData?.roles?.includes(
                    'ROLE_COMMUNITY_ADMIN'
                  )) ? (
                <Row gutter={20}>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    Jeśli uważasz, że
                  </span>
                  <span style={{ marginRight: 5 }}>
                    usterką powinien zająć się Deweloper,
                  </span>

                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    przekaż ją.
                  </span>
                </Row>
              ) : (
                <Row gutter={20}>
                  <span style={{ marginRight: 5 }}>
                    {[reporterData.firstName, reporterData.lastName]
                      .filter((it) => !!it)
                      .join(' ')}
                  </span>
                  <span style={{ fontWeight: 400, marginRight: 5 }}>
                    dodał nową usterkę. Potwierdź lub odrzuć jej zasadność albo
                    przekaż do Dewelopera.
                  </span>
                </Row>
              )}
            </div>
            {fault.isDeveloperNotified ? (
              <Button className='passed-fault-button'>
                Przekazano do Dewelopera
              </Button>
            ) : (
              <>
                {userData?.id !== fault?.reporterId &&
                  !(
                    userData?.roles?.includes('ROLE_WORKER') &&
                    userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN') &&
                    fault?.reporterData?.roles?.includes('ROLE_COMMUNITY_ADMIN')
                  ) && (
                    <>
                      <Button
                        className='accept-fault-button'
                        onClick={acceptFaultHandler}
                      >
                        Potwierdź
                      </Button>
                      <Button
                        className='deny-fault-button'
                        onClick={denyFaultHandler}
                      >
                        Odrzuć
                      </Button>
                    </>
                  )}
                <Button
                  className='pass-fault-button'
                  onClick={passToDeveloperHandler}
                >
                  Przekaż do Dewelopera
                </Button>
              </>
            )}
          </>
        )}
      </div>
    );
  }

  return null;
};
