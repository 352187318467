const statusMap = {
  TO_FIX: 'Do naprawy',
  REJECTED: 'Odrzucona',
  DONE: 'Zakończona',
  REPORTED: 'Zgłoszona',
  VERIFICATION: 'Oczekuje na weryfikację',
};
const getStatusName = (status) => statusMap[status] || '';

export default getStatusName;
