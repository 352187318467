import React, { useState, useEffect } from 'react';
import { Row, Col, Upload, Button, Menu, message } from 'antd';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import resolutionsAttachmentsService from '../../../services/resolutionsAttachmentsService';
import MessagePopup from '../../../components/MessagePopup';
import ProgressModal from '../../../components/ProgressModal';

export const Attachments = ({ investmentId, resolutionId }) => {
  const [attachments, setAttachments] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  const attachmentService = new resolutionsAttachmentsService(
    investmentId,
    resolutionId
  );

  useEffect(() => {
    const ResolutionsAttachmentsService = new resolutionsAttachmentsService(
      investmentId,
      resolutionId
    );
    ResolutionsAttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [investmentId, resolutionId]);

  const refresh = () => {
    const AttachmentsService = attachmentService;
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  };

  const columns = [
    {
      dataIndex: 'filename',
      editable: true,
    },
  ];
  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('attachment', data.file);
    const AttachmentsService = attachmentService;

    setProgressModalVisible(true);

    AttachmentsService.create(formData, {
      onUploadProgress: (progressEvent) => {
        setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })
      .then((response) => {
        setProgressModalVisible(false);
        message.success(
          <MessagePopup type={'success'}>
            Pomyślnie dodano załącznik
          </MessagePopup>
        );

        if (response.id) {
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const deleteAction = (entityId) => {
    const AttachmentsService = attachmentService;
    AttachmentsService.delete(entityId)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
    };
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  const handleDownload = (fileId) => {
    const AttachmentsService = attachmentService;
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const updateAction = (entityId, data) => {
    const AttachmentsService = attachmentService;
    AttachmentsService.updateData(entityId, data)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const downloadAction = (
      <Menu.Item
        key='1'
        onClick={() => handleDownload(record.id)}
        icon={<Icon name='download' style={{ marginRight: '5px' }} />}
      >
        Pobierz
      </Menu.Item>
    );
    tempArray.push(downloadAction);
    return tempArray;
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            icon='file'
            data={attachments}
            columns={columns}
            title='Lista załączników'
            deleteAction={deleteAction}
            customAddButton={<CustomAddButton />}
            updateAction={updateAction}
            inlineEditLabel='Zmień nazwę'
            hideEdit
            customMenuActions={customMenuActions}
          />
        </Col>
      </Row>
    </div>
  );
};
