import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import resolutionsService from '../../../services/resolutionsService';

const VoteModal = ({
  isModalVisible,
  setShowModal,
  resolution,
  setResolution,
}) => {
  const vote = (voteType) => {
    const ResolutionsService = new resolutionsService(resolution.investmentId);
    ResolutionsService.vote(
      resolution.investmentId,
      resolution.id,
      voteType
    ).then(() => {
      ResolutionsService.getOne(resolution.id).then((resolutionData) => {
        setResolution(resolutionData);
      });
      onReset();
    });
  };

  const onReset = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        title={null}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={450}
        footer={null}
      >
        <Row gutter={20}>
          <Col xs={24}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 400,
                color: '#3B4C60',
                textAlign: 'center',
                marginTop: 40,
              }}
            >
              Oddajesz głos na uchwałę:
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: '#5353F4',
                textAlign: 'center',
              }}
            >
              {resolution.name}
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                fontSize: 14,
                fontWeight: 400,
                color: '#B1CADE',
                textAlign: 'center',
                marginTop: 10,
                maxWidth: 280,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              Zmiana Twojego wyboru nie będzie możliwa po oddaniu głosu!
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                marginTop: 30,
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
              }}
            >
              <Button
                type='primary'
                style={{
                  backgroundColor: '#ec434c',
                  border: 0,
                }}
                onClick={() => vote('CON')}
              >
                PRZECIW
              </Button>
              <Button type='primary' onClick={() => vote('PRO')}>
                ZA
              </Button>
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                textDecoration: 'underline',
                textAlign: 'center',
                cursor: 'pointer',
                marginTop: 25,
                marginBottom: 10,
              }}
              onClick={() => vote('ABSTAIN')}
            >
              Wstrzymuje się od głosu
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default VoteModal;
