/* eslint-disable class-methods-use-this */
import BaseService from './baseService';
import httpService from '../helpers/httpService';

class InvestmentsService extends BaseService {
  constructor(url = '/api/vendor/investment') {
    super();
    const roles = localStorage.getItem('roles');

    this.url = url;

    if (roles && (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT'))) {
      this.url = '/api/user/investment';
    }
  }

  async getPremises(id = null) {
    if (id) {
      return httpService.get(`/api/vendor/investment/${id}/statistics/premises`, null, true);
    }

    return httpService.get('/api/vendor/investment/statistics/premises', null, true);
  }

  async getPremisesSale(id = null) {
    if (id) {
      return httpService.get(`/api/vendor/investment/${id}/statistics/premises/sale`, null, true);
    }

    return httpService.get('/api/vendor/investment/statistics/premises/sale', null, true);
  }

  // eslint-disable-next-line class-methods-use-this
  async create(data) {
    return httpService.post('/api/vendor/investment', data, true, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async update(id, data) {
    return httpService.post(`/api/vendor/investment/${id}`, data, true, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async updateFields(id, data) {
    return httpService.patch(`/api/vendor/investment/${id}`, data, true);
  }

  async getDetails(id) {
    return httpService.get(`/api/vendor/investment/${id}/details`, null, true);
  }
}

export default InvestmentsService;
