import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useParams, Link } from 'react-router-dom';
import faultGroupService from '../../../../services/faultGroupService';
import Icon from '../../../../components/Icon';
import Price from '../../../../components/Price';
import SmallList from '../../../../components/SmallList';
import faultsStatusMap from '../../../../helpers/faultsStatusMap';

export const FaultsBox = ({ premise }) => {
  const { id, premisesId } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const DataService = new faultGroupService(
      null,
      `/community/investments/${id}/faultGroups`
    );

    DataService.getList({ investmentId: id, premisesName: premise.name }).then(
      (res) => {
        setData(res.content.filter((el) => el.premisesId === premisesId));
      }
    );
  }, [id, premisesId]);
  if (!data.length) {
    return '';
  }

  const Dot = ({ status }) => {
    let color = 'grey';
    if (status === 'DONE') {
      color = 'green';
    }

    if (status === 'ACTIVE') {
      color = 'red';
    }

    return (
      <Tooltip title={faultsStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <Link
          className='smalllist-inherit-link'
          to={`/housingFaultGroup/${id}/faultGroup/${record.id}/show`}
          style={{ whiteSpace: 'nowrap', color: 'inherit !important' }}
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Dot status={record.status} />,
    },
    {
      title: 'Zakończono',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      render: (text, record) => <Price value={record.amount} showEmpty />,
    },
  ];

  return (
    <div className='premises-show-box faults-box'>
      <h2>
        <Icon name='fault' />
        Usterki
      </h2>
      <div className='content'>
        <SmallList data={data} columns={columns} showHeader={false} />
      </div>
    </div>
  );
};
