import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Dropdown, Menu, Modal, message,
} from 'antd';
import Icon from '../../../../components/Icon';
import Empty from '../../../../components/Empty';
import RoomModal from '../modals/RoomModal';
import RoomsServiceS from '../../../../services/roomsService';

const { confirm } = Modal;

export const Rooms = (props) => {
  const [roomsModal, setRoomsModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [rooms, setRooms] = useState([]);
  const {
    investmentId, premisesId, type,
  } = props;

  useEffect(() => {
    const RoomsService = new RoomsServiceS(investmentId, premisesId);
    RoomsService.getList().then((roomData) => {
      setRooms(roomData);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const RoomsService = new RoomsServiceS(investmentId, premisesId);
    RoomsService.getList().then((roomData) => {
      setRooms(roomData);
    });
  };

  const addNewAction = (data) => {
    setModalData(data);
    setRoomsModal(true);
  };
  const afterSave = () => {
    setRoomsModal(false);
    setModalData({});
    refresh();
  };
  const afterCancel = () => {
    setRoomsModal(false);
    setModalData({});
  };

  const deleteAction = (entityId) => {
    const RoomsService = new RoomsServiceS(investmentId, premisesId);
    RoomsService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const showDeleteConfirm = (roomData) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        deleteAction(roomData.id);
      },
    });
  };
  const noSplit = rooms.filter((room) => room.noSplit === true).length > 0;

  const menu = (roomData) => (
    <Menu>
      <Menu.Item key="1" onClick={() => addNewAction(roomData)} icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        Edytuj
      </Menu.Item>
      <Menu.Item key="2" onClick={() => showDeleteConfirm(roomData)} style={{ color: '#EC434C' }} icon={<Icon name="delete" style={{ marginRight: '5px' }} />}>
        Usuń
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="rooms">
      <Row>
        <Col xs={12}>
          <h2>{type === 'RENT' ? 'Podział i opłaty' : 'Podział'}</h2>
        </Col>
        {!noSplit && (
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button className="button-secondary rooms-add-room" onClick={addNewAction} style={{ width: '45px', height: '45px' }}>
              <Icon name="plus" />
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={20}>
        {rooms.map((roomData) => (
          <Col xs={12} sm={6} key={roomData.id}>
            <div className="rooms-list-room">
              <div className="rooms-list-room-settings">
                <Dropdown overlay={() => menu(roomData)} trigger="click" placement="bottomCenter">
                  <Button className="button-no-border">
                    <Icon name="more-vertical" />
                  </Button>
                </Dropdown>
              </div>
              <div className="rooms-list-room-name">{roomData.name}</div>
              <div className="rooms-list-room-size">
                {roomData.size}
                {' '}
                m
                <sup>2</sup>
              </div>
            </div>
          </Col>
        ))}
        {rooms.length === 0 && (<Empty style={{ width: '100%' }} />)}
      </Row>
      {roomsModal && (
        <RoomModal
          data={modalData}
          isModalVisible={roomsModal}
          afterSave={afterSave}
          afterCancel={afterCancel}
          type={type}
        />
      )}
    </div>
  );
};
