import { Modal } from 'antd';

const ProgressModal = ({
  progress,
  isModalVisible,
  text = 'Trwa dodawanie pliku...',
}) => {
  return (
    <Modal
      closable={false}
      mask={false}
      centered
      visible={isModalVisible}
      width={410}
      footer={null}
    >
      <div
        style={{
          marginTop: 30,
          fontWeight: 700,
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            minWidth: 30,
            textAlign: 'right',
          }}
        >
          {progress.toFixed(0)} %
        </div>
      </div>
      <div
        style={{
          height: 6,
          marginLeft: 10,
          marginRight: 10,
          backgroundColor: '#f0f4f6',
          borderRadius: 3,
        }}
      >
        <div
          style={{
            height: 6,
            width: `${progress}%`,
            backgroundColor: '#15D55E',
            borderRadius: 3,
          }}
        ></div>
      </div>
      <div
        style={{
          textAlign: 'center',
          fontSize: 20,
          fontWeight: 600,
          marginTop: 20,
        }}
      >
        {text}
      </div>
      <div style={{ textAlign: 'center', color: '#b1cade', marginBottom: 15 }}>
        Nie zamykaj okna przeglądarki.
      </div>
    </Modal>
  );
};

export default ProgressModal;
