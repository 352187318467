export const renderUnit = (unit) => {
  if (unit === 'm3') {
    return (
      <span>
        m<sup>3</sup>
      </span>
    );
  } else {
    return unit;
  }
};
