import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const changeAccountService = {};

changeAccountService.getToken = (data) => {
  const url = '/api/login';
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + url, {
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        if (!response.data) {
          reject();
        }

        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

changeAccountService.getInfo = (data) => {
  const url = '/api/user';

  const options = { headers: { Authorization: `Bearer ${data.access_token}` } };

  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + url, options)
      .then((response) => {
        if (response.status === 204 || response.status === 209) {
          resolve(response);
        }
        if (!response.data) {
          reject(response);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default changeAccountService;
