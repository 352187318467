import React, { useState, useEffect } from 'react';
import {
  Row, Col, Menu, Table, Typography, Tooltip, message,
} from 'antd';
import { useHistory, Link } from 'react-router-dom';
import SmallList from '../../../../components/SmallList';
import Price from '../../../../components/Price';
import Icon from '../../../../components/Icon';
import tenantChangesService from '../../../../services/tenantChangeGroups';
import tenantChangesStatusMap from '../../../../helpers/tenantChangesStatusMap';

const { Text } = Typography;

const Dot = (props) => {
  const { status } = props;
  let color = 'grey';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

export const TenantChanges = (props) => {
  const history = useHistory();
  const [tenantChanges, setTenantChanges] = useState([]);
  const { investmentId, premisesId } = props;

  useEffect(() => {
    const TenantChangesService = new tenantChangesService(investmentId, premisesId);
    TenantChangesService.getList({ pagination: { pageSize: 1000 } }).then((data) => {
      setTenantChanges(data.content);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const TenantChangesService = new tenantChangesService(investmentId, premisesId);
    TenantChangesService.getList({ pagination: { pageSize: 1000 } }).then((data) => {
      setTenantChanges(data.content);
    });
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'changeDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const addNewAction = () => {
    history.push(`/tenantChangeGroup/create?premisesId=${premisesId}&investmentId=${investmentId}`);
  };

  const deleteAction = (entityId) => {
    const TenantChangesService = new tenantChangesService(investmentId, premisesId);
    TenantChangesService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const showAction = (
      <Menu.Item key="1" icon={<Icon name="eye-visible" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/tenantChangeGroup/${record.investmentId}/premises/${record.premisesId}/tenantChangeGroup/${record.id}/show`, state: 'createdFromInvestment' }}
          className="premises-custom-create-link"
        >
          Zobacz
        </Link>
      </Menu.Item>
    );
    tempArray.push(showAction);
    const editAction = (
      <Menu.Item key="3" icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/tenantChangeGroup/${record.investmentId}/premises/${record.premisesId}/tenantChangeGroup/${record.id}/edit`, state: 'createdFromInvestment' }}
          className="premises-custom-create-link"
        >
          Edytuj
        </Link>
      </Menu.Item>
    );
    tempArray.push(editAction);
    return tempArray;
  };

  const Summary = () => {
    if (tenantChanges.length === 0) {
      return '';
    }

    const total = tenantChanges.reduce((a, b) => a + (b.amount || 0), 0);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <Text style={{ color: '#C0D5E4' }}>Suma</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text style={{ fontWeight: 'bold' }}><Price value={total} showEmpty /></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div className="tenantChanges create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList
            data={tenantChanges}
            columns={columns}
            title="Lista zmian lokatorskich"
            icon="flat-changes"
            addAction={addNewAction}
            deleteAction={deleteAction}
            hideEdit
            customMenuActions={customMenuActions}
            summary={<Summary />}
          />
        </Col>
      </Row>
    </div>
  );
};
