import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import Icon from '../../../../components/Icon';

const SharesBox = ({ premise, investment = {} }) => {
  if (premise?.type === 'PARKING' || premise?.type === 'STORAGE') {
    return (
      <div
        className='premises-show-box info-box'
        style={{ backgroundColor: '#5353F4', color: '#fff', padding: 25 }}
      >
        <Row>
          <Col xs={24} sm={12}>
            <h2 style={{ color: '#A7A7FA', fontSize: 10 }}>
              <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
            </h2>
            <div className='content'>
              <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
                {premise?.participation?.percent
                  ?.toFixed(2)
                  .toString()
                  .replace('.', ',')}
                %
              </div>
              <div style={{ fontSize: 24, lineHeight: '32px' }}>
                <span style={{ fontWeight: 700 }}>
                  {premise?.totalSurface?.toFixed(2)}
                </span>
                {'  '}/ {premise?.participation?.investmentSize?.toFixed(2)}
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12} className='shares-box-right-col'>
            <h2 style={{ color: '#A7A7FA', fontSize: 10 }}>
              <span style={{ marginRight: 8 }}>
                Udział w Hali garażowej:{' '}
                {
                  investment?.garageHalls?.find(
                    (garageHall) => garageHall.id === premise.garageHallId
                  )?.name
                }
              </span>
            </h2>
            <div className='content'>
              <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
                {premise?.participation?.garageHallPercent
                  ?.toFixed(2)
                  .toString()
                  .replace('.', ',')}
                %
              </div>
              <div style={{ fontSize: 24, lineHeight: '32px' }}>
                <span style={{ fontWeight: 700 }}>
                  {premise?.totalSurface?.toFixed(2)}
                </span>
                {'  '}/{' '}
                {investment.garageHalls
                  .find((garageHall) => garageHall.id === premise.garageHallId)
                  ?.parkingAndStorageSizeSum?.toFixed(2)}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      className='premises-show-box info-box'
      style={{
        backgroundColor: '#5353F4',
        color: '#fff',
        padding: 25,
        paddingRight: 10,
      }}
    >
      <h2 style={{ color: '#A7A7FA', fontSize: 10, textAlign: 'right' }}>
        <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
        <Tooltip
          placement='bottomLeft'
          title={
            'Wyliczany na podstawie lokali, w których lokator jest właścicielem'
          }
          overlayInnerStyle={{
            fontSize: 10,
            fontWeight: 600,
            minHeight: 20,
          }}
          showArrow={false}
          trigger={'hover'}
        >
          <Icon
            name={'important'}
            style={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </h2>
      <div className='content'>
        <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
          {premise?.participation?.percent
            ?.toFixed(2)
            .toString()
            .replace('.', ',')}
          %
        </div>
        <div style={{ fontSize: 24, lineHeight: '32px' }}>
          <span style={{ fontWeight: 700 }}>
            {premise?.totalSurface?.toFixed(2)}
          </span>
          {'  '}/ {premise?.participation?.investmentSize?.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

export default SharesBox;
