import React, { useState, useEffect } from 'react';
import {
  Upload, Modal, Row, Col, Dropdown, Button, Menu, message,
} from 'antd';
import ImagesServiceS from '../../../../services/faultsImageService';
import Icon from '../../../../components/Icon';

const { confirm } = Modal;

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const Gallery = ({ investmentId, faultGroupId, faultId }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const ImageService = new ImagesServiceS(investmentId, faultGroupId, faultId);
    ImageService.getList().then((data) => {
      setImages(data);
    });
  }, [investmentId, faultGroupId, faultId]);

  const refresh = () => {
    const ImageService = new ImagesServiceS(investmentId, faultGroupId, faultId);
    ImageService.getList().then((data) => {
      setImages(data);
    });
  };

  const handleDelete = (fileId) => {
    const ImageService = new ImagesServiceS(investmentId, faultGroupId, faultId);
    ImageService.delete(fileId).then(() => {
      ImageService.getList().then((data) => {
        setImages(data);
      });
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      cancelButtonProps: { className: 'button-secondary' },
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDownload = (file) => {
    const a = document.createElement('a');
    a.href = `${baseUrl}${file.filename}`;
    a.download = file.filename;
    a.target = '_blank';
    a.click();
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 15MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDownload(record)} icon={<Icon name="download" style={{ marginRight: '5px' }} />}>
        Pobierz
      </Menu.Item>
      <Menu.Item key="2" onClick={() => showDeleteConfirm(record)} style={{ color: '#EC434C' }} icon={<Icon name="delete" style={{ marginRight: '5px' }} />}>
        Usuń
      </Menu.Item>
    </Menu>
  );

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('image', data.file);
    const ImagesService = new ImagesServiceS(investmentId, faultGroupId, faultId);
    ImagesService.create(formData).then((response) => {
      if (response.id) {
        refresh();
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  return (
    <div className="gallery create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <h2>Zdjęcia w galerii</h2>
          <div className="gallery-wrapper">
            <Row gutter={20}>
              {images.map((image) => (
                <Col xs={24} lg={12} xl={6}>
                  <div className="gallery-wrapper-image">
                    <img src={`${baseUrl}${image.filename}`} alt={image.filename} />
                    <div className="gallery-wrapper-actions">
                      <Dropdown overlay={() => menu(image)} trigger="click" placement="bottomCenter" className="gallery-wrapper-action">
                        <Button className="button-no-border">
                          <Icon name="more-vertical" />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
              ))}
              <Col xs={24} lg={12} xl={6}>
                <div className="gallery-wrapper-add">
                  <Upload
                    name="file"
                    customRequest={addNewAction}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                  >
                    <Button className="button-secondary">
                      <Icon name="plus" />
                    </Button>
                  </Upload>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
