import React from 'react';
import { Modal, Row, Col, Card, Button } from 'antd';
import Icon from '../../../components/Icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TransferInfoModal = (props) => {
  const { afterCancel, isModalVisible, settlementData } = props;

  return (
    <>
      <Modal
        title={'Dane do przelewu'}
        centered
        visible={isModalVisible}
        onCancel={() => afterCancel()}
        width={770}
        footer={
          <Row className='datagrid-filter-modal-button-wrapper'>
            <Col xs={11} style={{ textAlign: 'left' }}>
              <Button
                className='button-secondary'
                size='large'
                style={{ minWidth: 100, width: 100, marginLeft: 10 }}
                onClick={() => afterCancel()}
              >
                Anuluj
              </Button>
            </Col>
            <Col xs={11} offset={2}>
              <Button
                className='button-secondary'
                size='large'
                style={{ minWidth: 120, width: 120, marginRight: 10 }}
                onClick={() => window.print()}
              >
                <Icon name='download' style={{ marginRight: 5 }} />
                Drukuj
              </Button>
            </Col>
          </Row>
        }
      >
        <Card className='form-create-main-card'>
          <div className='settlement-transfer-info-wrapper'>
            <Row>
              <Col xs={24} style={{ marginBottom: 25 }}>
                <div className='settlement-recipient-info'>Dane odbiorcy:</div>
                <div className='settlement-recipient-value'>
                  {settlementData.companyName}{' '}
                  <span className='settlement-recipient-value-copy'>
                    <CopyToClipboard text={settlementData.companyName}>
                      <Icon name={'copy'} />
                    </CopyToClipboard>
                  </span>
                </div>
                <div className='settlement-recipient-value'>
                  {`ul. ${settlementData.address?.street} ${
                    settlementData.address?.number || ''
                  }${
                    settlementData.address?.local
                      ? `/${settlementData.address?.local}`
                      : ''
                  }`}
                </div>
                <div className='settlement-recipient-value'>
                  {`${settlementData.address?.zip} ${settlementData.address?.city}, ${settlementData.address?.country}`}
                </div>
              </Col>
              <Col xs={24} style={{ marginBottom: 25 }}>
                <Row style={{ marginBottom: 25 }}>
                  <Col>
                    <div className='settlement-recipient-info'>
                      Tytuł przelewu:
                    </div>
                    <div className='settlement-recipient-value'>
                      {settlementData.name}
                      <span className='settlement-recipient-value-copy'>
                        <CopyToClipboard text={settlementData.name}>
                          <Icon name={'copy'} />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className='settlement-recipient-info'>
                      Rachunek do wpłat:
                    </div>
                    <div className='settlement-recipient-value'>
                      {settlementData.bankNumber}
                      <span className='settlement-recipient-value-copy'>
                        <CopyToClipboard text={settlementData.bankNumber}>
                          <Icon name={'copy'} />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <div className='settlement-payment-info'>Do zapłaty:</div>
                <div className='settlement-payment-value'>
                  {settlementData.price
                    ? settlementData.price.toFixed(2).replace('.', ',')
                    : '0,00'}{' '}
                  zł
                  <span className='settlement-recipient-value-copy'>
                    <CopyToClipboard text={settlementData.price}>
                      <Icon name={'copy'} />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <div className='settlement-payment-info'>Termin płatności:</div>
                <div style={{ fontSize: 18, marginBottom: 30 }}>
                  {settlementData.paymentDate}
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default TransferInfoModal;
