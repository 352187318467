import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, Row, Col, message } from 'antd';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import housingAssociationsService from '../../../services/housingAssociationsService';
import generateId from '../../../helpers/generateId';

const { InputNumberV2 } = Input;

const ContactsInvestmentModal = (props) => {
  const { afterCancel, isModalVisible, investment, editingGarageHallId } =
    props;
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    if (editingGarageHallId) {
      const editingGarageHall = investment?.garageHalls?.find(
        (garageHall) => garageHall.id === editingGarageHallId
      );

      form.setFieldsValue(editingGarageHall);
    } else {
      form.resetFields();
    }
  }, [isModalVisible, editingGarageHallId]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingGarageHallId) {
          const editingGarageHallIdx = investment?.garageHalls?.findIndex(
            (garageHall) => garageHall.id === editingGarageHallId
          );

          const tempValues = [...investment?.garageHalls];
          tempValues[editingGarageHallIdx] = {
            ...investment?.garageHalls[editingGarageHallIdx],
            ...values,
          };

          const HousingAssociationsService = new housingAssociationsService();

          HousingAssociationsService.updateFields(id, {
            garageHalls: tempValues,
          })
            .then((response) => {
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type='error'>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          const tempValues = [...investment?.garageHalls];
          tempValues[investment?.garageHalls.length] = {
            ...values,
            id: generateId(),
          };

          const HousingAssociationsService = new housingAssociationsService();

          HousingAssociationsService.updateFields(id, {
            garageHalls: tempValues,
          })
            .then((response) => {
              afterCancel();
            })
            .catch((err) => {
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type='error'>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={
          editingGarageHallId
            ? 'Edytujesz podział Hali garażowej'
            : 'Dodaj podział Hali garażowej'
        }
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={410}
        footer={
          <Footer
            onReset={onReset}
            onFinish={onFinish}
            isNew={!editingGarageHallId}
          />
        }
      >
        <Form
          form={form}
          name='filterDeveloper'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size='large' placeholder='Nazwa' />
              </Form.Item>
              <Form.Item
                name='garageHallSize'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Powierzchnia całkowita'
                  addonAfter={
                    <>
                      m<sup>2</sup>
                    </>
                  }
                />
              </Form.Item>
              <Form.Item
                name='parkingAndStorageSizeSum'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2
                  size='large'
                  placeholder='Suma powierzchni MP i KL*'
                  addonAfter={
                    <>
                      m<sup>2</sup>
                    </>
                  }
                />
              </Form.Item>
              <div
                className='attachmentsNote'
                style={{ fontSize: '10px', color: '#B1CADE' }}
              >
                * Miejsc postojowych i Komórek lokatorskich.
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ContactsInvestmentModal;
