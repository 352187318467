import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, Form } from 'antd';
import Datagrid from '../../components/Datagrid';
import Icon from '../../components/Icon';
import tagsTypeMap from '../../helpers/tagsTypeMap';
import tagsService from '../../services/tagsService';
import Popup from './Popup';
import Input from '../../components/InputComponent';

const { Select } = Input;
const { Option } = Select;
const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: false,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Grupa',
    dataIndex: 'type',
    visible: true,
    filterEnabled: false,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => tagsTypeMap(record.type),
    exportRender: (text, record) => tagsTypeMap(record.type),
  },
];

const List = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const datagridRef = useRef();

  useEffect(() => {
    const roles = JSON.parse(localStorage.getItem('roles'));
    setUserRoles(roles);
    console.log(roles);
  }, []);

  const afterSave = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const runModal = (record) => {
    if (record?.id) {
      tagsService.getOne(record.id).then((response) => {
        setModalData(response);
        setShowModal(true);
      });
    } else {
      setModalData({});
      setShowModal(true);
    }
  };

  const customEdit = (record) => (
    <Button className='button-no-border' onClick={() => runModal(record)}>
      <Icon name='edit' />
    </Button>
  );

  const afterCancel = () => {
    setModalData({});
    setShowModal(false);
    datagridRef.current.refresh();
  };

  const CustomCreate = () => (
    <div style={{ marginBottom: 20 }}>
      <Button type='primary' onClick={() => runModal()}>
        Tag <Icon name='plus' style={{ marginLeft: 5 }} />
      </Button>
    </div>
  );

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key='name'>
      <Form.Item name='name'>
        <Input size='large' placeholder='Nazwa' />
      </Form.Item>
    </Col>
  );

  customFilters.push(
    <Col xs={24} sm={12} key='type'>
      <Form.Item name='type'>
        <Select placeholder='Grupa' allowClear size='large'>
          {!userRoles.includes('ROLE_COMMUNITY_ADMIN') &&
            !userRoles.includes('ROLE_COMMUNITY_MEMBER') && (
              <Option value='TENANT_CHANGE'>Zmiany lokatorskie</Option>
            )}
          <Option value='FAULT'>Usterki</Option>
          {!userRoles.includes('ROLE_COMMUNITY_ADMIN') &&
            !userRoles.includes('ROLE_COMMUNITY_MEMBER') && (
              <Option value='PREMISES'>Lokale</Option>
            )}
        </Select>
      </Form.Item>
    </Col>
  );

  return (
    <div className='show-layout'>
      <div>
        <Datagrid
          title='Lista tagów'
          resource='tags'
          dataProvider={tagsService}
          columns={columns}
          customRowActions={[customEdit]}
          ref={datagridRef}
          customCreate={<CustomCreate />}
          customFilters={customFilters}
          hideEdit
          hideShow
          hideReset
          hideSms
          hideEmail
        />
        <Popup
          data={modalData}
          isModalVisible={showModal}
          afterSave={afterSave}
          afterCancel={afterCancel}
          userRoles={userRoles}
        />
      </div>
    </div>
  );
};

export default List;
