import React from 'react';

import countries from 'i18n-iso-countries';
import Input from './InputComponent';
import { useEffect } from 'react';

countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));

const { Select } = Input;
const { Option } = Select;

const CountrySelect = (props) => {
  const { value, onChange } = props;
  const countryList = countries.getNames('pl', { select: 'official' });
  const countryOptions = Object.values(countryList).map((val, idx) => (
    <Option key={idx} value={val}>
      {val}
    </Option>
  ));

  return (
    <Select
      placeholder='Kraj'
      size='large'
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
    >
      {countryOptions}
    </Select>
  );
};

export default CountrySelect;
