import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import clientsService from '../../services/clientsService';
import userService from '../../services/userService';
import memberSettlementsService from '../../services/memberSettlementsService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';
import SharesBox from './components/SharesBox';
import BalanceBox from './components/BalanceBox';
import HousingSettlementsService from '../../services/housingSettlementsService';

const Alerts = ({ alerts }) => {
  if (alerts?.sms && alerts?.email) {
    return (
      <Col xs={24} sm={12}>
        <div className='title'>Alerty:</div>
        <div>SMS, E-mail</div>
      </Col>
    );
  } else if (alerts?.sms) {
    return (
      <Col xs={24} sm={12}>
        <div className='title'>Alerty:</div>
        <div>SMS</div>
      </Col>
    );
  } else if (alerts?.email) {
    return (
      <Col xs={24} sm={12}>
        <div className='title'>Alerty:</div>
        <div>E-mail</div>
      </Col>
    );
  }

  return null;
};

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { investmentId, userId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [currentUser, serCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [balance, setBalance] = useState(null);
  const [tenantsBalance, setTenantsBalance] = useState(null);

  useEffect(() => {
    if (customUserData) {
      setUserData(customUserData);

      memberSettlementsService.getBalance().then((response) => {
        if (response) {
          if (response?.balance !== null) {
            setBalance(response.balance);
          }
          if (response?.balanceTenants !== null) {
            setTenantsBalance(response.balanceTenants);
          }
        }
      });
    } else {
      clientsService.getOne(userId).then((data) => {
        setUserData(data);
      });

      HousingSettlementsService.getMemberBalance(userId).then((response) => {
        if (response?.balance !== null) {
          setBalance(response.balance);
        }
        // if (response.balanceTenants) {
        //   setTenantsBalance(response.balanceTenants);
        // }
      });
    }

    userService.getInfo().then((user) => {
      serCurrentUser(user);

      setLoading(false);
    });
  }, [userId, customUserData]);

  // const canShowMenu = (requiredPermission) => {
  //   if (currentUser.roles.includes('ROLE_WORKER')) {
  //     return currentUser.workerPermissions.includes(requiredPermission);
  //   }

  //   return true;
  // };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.goBack()}
          backIcon={customTitle ? '' : <Icon name='arrow-simply-left' />}
          title={customTitle || 'Wróć'}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            !customTitle && canEdit && (
              <Button
                key='2'
                type='primary'
                onClick={() => history.replace(`/members/${userData.id}/edit`)}
              >
                Edytuj <Icon name='edit' />
              </Button>
            ),
            customUserData && (
              <Link to={`/myAccount/edit`}>
                <Button key='2' type='primary'>
                  Edytuj <Icon name='edit' />
                </Button>
              </Link>
            ),
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className='left'>
                  <div className='logo'>
                    <img
                      src={`${baseUrl}${userData.avatar}`}
                      alt={userData.name}
                    />
                  </div>
                  <div className='name'>
                    {userData.firstName} {userData.lastName}
                  </div>
                  <div className='buttons'>
                    {userData.mobile && (
                      <div className='call'>
                        <a href={`tel:${userData}`}>
                          <Button type='primary' size='large'>
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>
                    )}
                    <div className='write'>
                      <a href={`mailto:${userData.email}`}>
                        <Button type='primary' size='large'>
                          <Icon name='email' /> Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className='right'>
                  {balance !== null ? (
                    <BalanceBox balance={balance} showPay={!!customUserData} />
                  ) : null}
                  {tenantsBalance !== null ? (
                    <BalanceBox
                      balance={tenantsBalance}
                      tenantBalance={true}
                      showPay={!!customUserData}
                    />
                  ) : null}

                  <SharesBox myAccView={!!customUserData} />

                  <div className='investments-show-right-actions'>
                    <Row gutter={20}>
                      <Col xs={24} sm={8}>
                        <Link
                          className='investments-show-right-actions-action'
                          to={
                            customUserData
                              ? '/memberPremises'
                              : `/investment/${investmentId}/members/${userId}/premises`
                          }
                        >
                          <Icon name='apartments' />
                          Lokale
                        </Link>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Link
                          className='investments-show-right-actions-action'
                          to={'#'}
                        >
                          <Icon name='fault' />
                          Usterki
                        </Link>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Link
                          className='investments-show-right-actions-action'
                          to={
                            customUserData
                              ? '/memberSettlements'
                              : `/members/${userId}/settlements`
                          }
                        >
                          <Icon name='payments' />
                          Rozliczenia
                        </Link>
                      </Col>

                      <Col xs={24} sm={8}>
                        <Link
                          className='investments-show-right-actions-action'
                          to={`/resolutions?userId=${userId}`}
                        >
                          <Icon name='uchwaly' />
                          Głosowania
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <Card
                    title={
                      <h2 className='show-layout-card-header'>
                        <Icon name='more-info' />
                        Informacje
                      </h2>
                    }
                    className='informations'
                  >
                    <Row>
                      {!userData.isCompany ? (
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Imię i nazwisko:</div>
                          <div>
                            {userData.firstName} {userData.lastName}
                          </div>
                        </Col>
                      ) : (
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Nazwa firmy:</div>
                          {userData.name}
                        </Col>
                      )}

                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        {userData.isCompany ? (
                          <Col xs={24} sm={12}>
                            <div className='title'>Osoba do kontaktu:</div>
                            <div>
                              {userData.firstName} {userData.lastName}
                            </div>
                          </Col>
                        ) : (
                          <div className='title'>Dane kontaktowe:</div>
                        )}
                        <div className='email'>{userData.email}</div>
                        <div className='phone'>{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <Address
                          title='Adres zamieszkania'
                          address={userData.address}
                        />
                      </Col>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <Address
                          title='Adres korespondencyjny'
                          address={
                            userData.correspondenceAddress
                              ? userData.correspondenceAddress
                              : {}
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Alerts alerts={userData.notifications} />
                      {userData.payments?.accountNumber && (
                        <Col xs={24} sm={12}>
                          <div className='title'>Numer rachunku:</div>
                          <div>{userData.payments?.accountNumber}</div>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
