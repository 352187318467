import BaseService from './baseService';

class InvestmentsSettlementsService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/settlement`;
  }
}

export default InvestmentsSettlementsService;
