import BaseService from './baseService';

class SettlementTemplatesCostsService extends BaseService {
  constructor(investmentId, groupId) {
    super();
    this.url = `/api/community/investment/${investmentId}/settlementTemplates/costsGroups/${groupId}/costs`;
  }
}

export default SettlementTemplatesCostsService;
