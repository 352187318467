import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Row, Col, Divider, Table, PageHeader, message } from 'antd';
import { Icon } from '../../../components';
import Spin from '../../../components/Spin';
import premisesSettlementsService from '../../../services/premisesSettlementsService';
import PermisesService from '../../../services/premisesService';
import HousingAssociationsService from '../../../services/housingAssociationsService';
import { premiseTypeMap } from '../../../helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment/moment';
import CopiedPopup from '../../../components/CopiedPopup';
import { SettlementPdfGenerator } from '../components/SettlementPdfGenerator';

const Status = ({ status }) => {
  if (status === 'PAID') {
    return (
      <span>
        <span className={`dot green`} style={{ marginRight: 8 }} />
        Zapłacone
      </span>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <span>
        <span className={`dot yellow`} style={{ marginRight: 8 }} />
        Częściowo zapłacone
      </span>
    );
  } else {
    return (
      <span>
        <span className={`dot red`} style={{ marginRight: 8 }} />
        Do zapłaty
      </span>
    );
  }
};

const Show = () => {
  const { id, premisesId, settlementId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [investmentData, setInvestmentData] = useState({});
  const [premisesData, setPremisesData] = useState({});
  const [settlementData, setSettlementData] = useState({});
  const [settlementCosts, setSettlementCosts] = useState([]);

  useEffect(() => {
    const investmentService = new HousingAssociationsService();

    const PrrmisesService = new PermisesService(id);

    const PremisesSettlementsService = new premisesSettlementsService(
      id,
      premisesId
    );

    const promises = [
      investmentService.getDetails(id).then((response) => {
        setInvestmentData(response?.investment);
      }),
      PrrmisesService.getOne(premisesId).then((response) => {
        setPremisesData(response);
      }),

      PremisesSettlementsService.getOne(settlementId).then((response) => {
        setSettlementData(response);
      }),

      PremisesSettlementsService.getCosts(settlementId).then((response) => {
        setSettlementCosts(response);
      }),
    ];

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: 'Pozycja',
      dataIndex: 'name',
    },
    {
      title: 'Ilość',
      dataIndex: 'quantity',
    },
    {
      title: 'Jednostka',
      dataIndex: 'unit',
      render: (text, record) => {
        if (text === 'm3') {
          return (
            <span>
              m<sup>3</sup>
            </span>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: '',
      dataIndex: 'multiply',
      render: () => 'x',
    },
    {
      title: 'Cena jednostkowa (brutto)',
      dataIndex: 'unitPrice',
      render: (text, record) =>
        text ? `${text.toFixed(2).replace('.', ',')} zł` : '',
    },
    {
      title: 'Wartość (brutto)',
      dataIndex: 'price',
      render: (text, record) =>
        text ? `${text.toFixed(2).replace('.', ',')} zł` : '',
    },
  ];

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            backIcon={<Icon name='arrow-simply-left' />}
            title={'Rozliczenia'}
            className='page-header-extra-2'
            extra={[
              <SettlementPdfGenerator
                key='1'
                investmentData={investmentData}
                premisesData={premisesData}
                settlementData={settlementData}
                settlementCosts={settlementCosts}
              />,

              <Button
                key='2'
                type='primary counters-edit-btn'
                onClick={() =>
                  history.push(
                    `/investment/${id}/premises/${premisesId}/housingSettlements/${settlementId}/edit`
                  )
                }
                icon={
                  settlementData ? <Icon name='edit' /> : <Icon name='plus' />
                }
                iconPosition={'end'}
              >
                {settlementData ? (
                  <span style={{ marginLeft: 5 }}>Edytuj</span>
                ) : (
                  <span style={{ marginLeft: 5 }}>Dodaj</span>
                )}
              </Button>,
            ]}
          >
            <div className='settlement-template-wrapper'>
              <div className='settlement-template-header'>
                <div className='settlement-template-title'>
                  <span className='settlement-template-period'>
                    {moment(settlementData.createDate)
                      .locale('pl_PL')
                      .format('MMMM YYYY')
                      .toUpperCase()}
                  </span>
                  <span className='settlement-template-issue-date'>
                    (wystawiono:{' '}
                    {settlementData.createDate
                      ? settlementData.createDate
                      : '-'}
                    )
                  </span>
                </div>
              </div>

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Nadawca</div>
                <Row>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <div className='settlement-recipient-info'>
                      Dane{' '}
                      {investmentData?.type === 'HOUSING_ASSOCIATION'
                        ? 'Spółdzielni'
                        : 'Wspólnoty'}
                      :
                    </div>
                    <div className='settlement-recipient-value'>
                      {settlementData.companyName}{' '}
                      <span className='settlement-recipient-value-copy'>
                        <CopyToClipboard
                          text={settlementData.companyName}
                          onCopy={() =>
                            message.success({
                              content: <CopiedPopup />,
                              duration: 1,
                              className: 'message-copied',
                            })
                          }
                        >
                          <Icon name={'copy'} />
                        </CopyToClipboard>
                      </span>
                    </div>
                    <div className='settlement-recipient-value'>
                      {`ul. ${settlementData.address?.street} ${
                        settlementData.address?.number || ''
                      }${
                        settlementData.address?.local
                          ? `/${settlementData.address?.local}`
                          : ''
                      }`}
                    </div>
                    <div className='settlement-recipient-value'>
                      {`${settlementData.address?.zip} ${settlementData.address?.city}, ${settlementData.address?.country}`}
                    </div>

                    <div
                      className='settlement-recipient-value'
                      style={{ marginTop: 15 }}
                    >
                      NIP: {investmentData.nip ?? '-'}
                    </div>
                    <div className='settlement-recipient-value'>
                      Regon: {investmentData.regon ?? '-'}
                    </div>
                  </Col>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Tytuł przelewu:
                        </div>
                        <div className='settlement-recipient-value'>
                          {settlementData.name}
                          <span className='settlement-recipient-value-copy'>
                            <CopyToClipboard
                              text={settlementData.name}
                              onCopy={() =>
                                message.success({
                                  content: <CopiedPopup />,
                                  duration: 1,
                                  className: 'message-copied',
                                })
                              }
                            >
                              <Icon name={'copy'} />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Rachunek do wpłat:
                        </div>
                        <div className='settlement-recipient-value'>
                          {settlementData.bankNumber}
                          <span className='settlement-recipient-value-copy'>
                            <CopyToClipboard
                              text={settlementData.bankNumber}
                              onCopy={() =>
                                message.success({
                                  content: <CopiedPopup />,
                                  duration: 1,
                                  className: 'message-copied',
                                })
                              }
                            >
                              <Icon name={'copy'} />
                            </CopyToClipboard>
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className='settlement-info-wrapper'>
                <div
                  className='settlement-info-title'
                  style={{ marginTop: 25 }}
                >
                  Płatność
                </div>
                <Row>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Razem do zapłaty:
                    </div>
                    <div
                      style={{
                        color: '#fff',
                        fontSize: 24,
                        fontWeight: 600,
                        marginBottom: 30,
                      }}
                    >
                      {settlementData.price
                        ? settlementData.price.toFixed(2).replace('.', ',')
                        : '0,00'}{' '}
                      zł
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>Zapłacono:</div>
                    <div
                      style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}
                    >
                      {settlementData.paid
                        ? settlementData.paid.toFixed(2).replace('.', ',')
                        : '0,00'}
                      zł
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Pozostało do zapłaty:
                    </div>
                    <div
                      style={{
                        color:
                          settlementData.price - settlementData.paid <= 0
                            ? '#15d55e'
                            : settlementData.paid
                            ? '#F9DF45'
                            : '#EC434C',
                        fontSize: 24,
                        marginBottom: 30,
                      }}
                    >
                      {settlementData.price
                        ? (settlementData.price - settlementData.paid)
                            .toFixed(2)
                            .replace('.', ',')
                            .replace('-', '+')
                        : '0,00'}{' '}
                      zł
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Termin płatności:
                    </div>
                    <div
                      style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}
                    >
                      {settlementData.paymentDate}
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>
                      Data opłacenia:
                    </div>
                    <div
                      style={{ color: '#fff', fontSize: 18, marginBottom: 30 }}
                    >
                      {settlementData.paidDate ? settlementData.paidDate : '-'}
                    </div>
                  </Col>
                  <Col xs={24} md={12} lg={8} xl={6}>
                    <div className='settlement-payment-info'>Status:</div>
                    <div
                      style={{ color: '#fff', fontSize: 24, marginBottom: 30 }}
                    >
                      <Status status={settlementData.status} />
                    </div>
                  </Col>
                </Row>
              </div>

              <Divider style={{ borderTop: '1px solid #B1CADE' }} />

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Odbiorca</div>
                <Row>
                  <Col xs={24} xl={12} style={{ marginBottom: 25 }}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Rozliczenie dla:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.firstName}{' '}
                          {premisesData.premisesOwner?.lastName}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`ul. ${premisesData.address?.street} ${
                            premisesData.address?.number || ''
                          }${
                            premisesData.address?.local
                              ? `/${premisesData.address?.local}`
                              : ''
                          }`}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`${premisesData.address?.zip} ${premisesData.address?.city}, ${premisesData.address?.country}`}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Dane kontaktowe:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.mobile}
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.premisesOwner?.email}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>Lokal:</div>
                        <div className='settlement-recipient-value'>
                          {premiseTypeMap(premisesData.type)}{' '}
                          {premisesData.name}
                        </div>
                        <div className='settlement-recipient-value'>
                          {`ul. ${premisesData.address?.street} ${
                            premisesData.address?.number || ''
                          }${
                            premisesData.address?.local
                              ? `/${premisesData.address?.local}`
                              : ''
                          }`}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 25 }}>
                      <Col>
                        <div className='settlement-recipient-info'>
                          Powierzchnia:
                        </div>
                        <div className='settlement-recipient-value'>
                          {premisesData.totalSurface} m<sup>2</sup>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <Divider style={{ borderTop: '1px solid #B1CADE' }} />

              <div className='settlement-info-wrapper'>
                <div className='settlement-info-title'>Tabela kosztów</div>
                <Table
                  className='settlement-costs-table'
                  columns={columns}
                  dataSource={settlementCosts}
                  pagination={false}
                  rowKey={'id'}
                />
              </div>

              <div className='settlement-summary'>
                <span className='settlement-summary-label'>Razem:</span>
                <span className='settlement-summary-value'>
                  {settlementData.price
                    ? settlementData.price.toFixed(2).replace('.', ',')
                    : '0,00'}{' '}
                  zł
                </span>
              </div>
            </div>
          </PageHeader>
        </div>
      )}
    </div>
  );
};

export default Show;
