import BaseService from './baseService';

class TenantChangesService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    const roles = localStorage.getItem('roles');

    if (investmentId || premisesId) {
      this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/tenantChangeGroup`;
      if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
        this.url = '/api/user/tenantChangeGroup';
      }
    } else {
      this.url = '/api/vendor/tenantChangeGroup';

      if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
        this.url = '/api/user/tenantChangeGroup';
      }
    }
  }
}

export default TenantChangesService;
