import BaseService from './baseService';
import httpService from '../helpers/httpService';

class ResolutionsAttachmentsService extends BaseService {
  constructor(investmentId, resolutionId) {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles?.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/investment/${investmentId}/resolutions/${resolutionId}/attachments`;
    }

    if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/investment/${investmentId}/resolutions/${resolutionId}/attachments`;
    }
  }

  async updateData(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }
}

export default ResolutionsAttachmentsService;
