import React, { useState } from 'react';
import {
  Row, Col, Form, Button,
} from 'antd';
import Input from '../../../components/InputComponent';
import Icon from '../../../components/Icon';
import tagsService from '../../../services/tagsService';
import Popup from './Popup';

const { SearchBox } = Input;

export const Tags = () => {
  const [showModal, setShowModal] = useState(false);

  const afterSave = () => {
    setShowModal(false);
  };

  const afterCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="attachments create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Tagi</h2>
            <Row gutter={20}>
              <Col xs={24}>
                <Form.Item
                  name="tags"
                >
                  <SearchBox
                    placeholder="Wybierz tag"
                    size="large"
                    resource={tagsService}
                    resourceField="name"
                    optionKey="name"
                    filters={{ type: 'TENANT_CHANGE' }}
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button type="primary" onClick={() => setShowModal(true)}>
                  Dodaj nowy tag
                  {' '}
                  <Icon name="plus" style={{ paddingLeft: '5px' }} />
                </Button>
              </Col>
              <Popup
                isModalVisible={showModal}
                afterSave={afterSave}
                afterCancel={afterCancel}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
