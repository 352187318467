import { message, Button } from 'antd';

const MessagePopup = ({ children, type, annotation }) => {
  const onClickHandler = () => {
    message.destroy();
  };

  return (
    <>
      <div className='message-wrapper'>
        <div className='message-title'>{children}</div>
        {annotation && <div className='message-annotation'>{annotation}</div>}
      </div>
      {type === 'success' && (
        <Button className='message-close-btn-success' onClick={onClickHandler}>
          Zamknij
        </Button>
      )}
      {type === 'error' && (
        <Button className='message-close-btn-error' onClick={onClickHandler}>
          Zamknij
        </Button>
      )}
    </>
  );
};

export default MessagePopup;
