/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { PageHeader, Button, Row, Col, Card, Form } from 'antd';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Input from '../../components/InputComponent';
import premisesService from '../../services/premisesService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import HousingAssociationsService from '../../services/housingAssociationsService';
import ownersService from '../../services/ownersService';
import moment from 'moment';

const { SearchBox, DatePicker, Switch } = Input;

const OwnerForm = forwardRef(({ premise }, ref) => {
  const [form] = Form.useForm();
  const [withoutExpireDate, setWithoutExpireDate] = useState(true);
  const [actualOwner, setActualOwner] = useState();
  const [allOwners, setAllOwners] = useState();

  useEffect(() => {
    if (premise?.premisesOwnerId) {
      setWithoutExpireDate(premise?.premisesOwnerWithoutExpireDate);
    }

    const initValues = {
      name: [premiseTypeMap(premise?.type), premise?.name]
        .filter((el) => !!el)
        .join(' '),
      userId: premise.premisesOwnerId,
      dateFrom:
        premise?.premisesOwnerDateFrom &&
        moment(premise?.premisesOwnerDateFrom),
      dateTo:
        premise?.premisesOwnerDateTo && moment(premise?.premisesOwnerDateTo),
      withoutExpireDate: premise.premisesOwnerWithoutExpireDate,
    };
    form.setFieldsValue(initValues);

    const OwnersService = new ownersService(premise.investmentId, premise.id);

    OwnersService.getList().then((data) => {
      setAllOwners(data);

      setActualOwner(
        data?.find(
          (el) =>
            el.userId === premise.premisesOwnerId &&
            el.dateFrom === premise.premisesOwnerDateFrom &&
            el.dateTo === premise.premisesOwnerDateTo &&
            el.withoutExpireDate === premise.premisesOwnerWithoutExpireDate
        )
      );
    });
  }, [premise]);

  useImperativeHandle(ref, () => ({
    async validateForm() {
      return form.validateFields().catch((err) => {
        throw err;
      });
    },
    async submitForm() {
      form.validateFields().then((values) => {
        if (values.userId && values.dateFrom) {
          const OwnersService = new ownersService(
            premise.investmentId,
            premise.id
          );

          const tempValues = {
            userId: values.userId,
            dateFrom: values.dateFrom.format('YYYY-MM-DD'),
          };

          if (withoutExpireDate) {
            tempValues.withoutExpireDate = true;
            tempValues.dateTo = null;
          } else {
            tempValues.dateTo = values.dateTo.format('YYYY-MM-DD');
            tempValues.withoutExpireDate = null;
          }

          if (
            !premise.premisesOwnerId ||
            premise.premisesOwnerId !== values.userId
          ) {
            return OwnersService.create(tempValues);
          } else {
            return OwnersService.update(actualOwner.id, tempValues);
          }
        }
      });
    },
  }));

  return (
    <Form
      name='create'
      className='create-form'
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      autoComplete='off'
      form={form}
    >
      <Card className='assignSingleCard' style={{ marginBottom: 10 }}>
        <Row gutter={20}>
          <Col xs={24} lg={12} xxl={6}>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Lokal' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={6}>
            <Form.Item
              name='userId'
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedUserId = form.getFieldValue('userId');
                      const inputedDateFrom = form.getFieldValue('dateFrom');
                      const inputedDateTo = form.getFieldValue('dateTo');

                      if (
                        !inputedUserId &&
                        (inputedDateFrom || inputedDateTo)
                      ) {
                        reject('Pole wymagane');
                      }
                      resolve();
                    });
                  },
                },
              ]}
            >
              <SearchBox
                optionValue={(el) => {
                  if (el.isCompany) {
                    return el.name;
                  }
                  return `${el.firstName} ${el.lastName}`;
                }}
                placeholder='Użytkownik'
                size='large'
                resource={new HousingAssociationsService()}
                methodName={'getTenantsList'}
                filters={{ role: 'ROLE_COMMUNITY_MEMBER', size: 9999 }}
                filterOption={(inputValue, option) => {
                  return (
                    option.children
                      ?.toLowerCase()
                      .indexOf(inputValue.toLowerCase()) !== -1
                  );
                }}
                showSearch={true}
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 20,
              }}
            >
              Aktualnie:
              <span style={{ fontWeight: 700, marginLeft: 5 }}>
                {premise.premisesOwnerId
                  ? [
                      premise.premisesOwner.firstName,
                      premise.premisesOwner.lastName,
                    ]
                      .filter((el) => !!el)
                      .join(' ')
                  : 'brak'}
              </span>
            </div>
          </Col>
          <Col xs={24} lg={12} xxl={6}>
            <Form.Item
              name='dateFrom'
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedUserId = form.getFieldValue('userId');
                      const inputedDateFrom = form.getFieldValue('dateFrom');
                      const inputedDateTo = form.getFieldValue('dateTo');

                      if (
                        !inputedDateFrom &&
                        (inputedUserId || inputedDateTo)
                      ) {
                        reject('Pole wymagane');
                      }
                      resolve();
                    });
                  },
                },
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedUserId = form.getFieldValue('userId');

                      for (let owner of allOwners) {
                        if (
                          owner.id === actualOwner?.id &&
                          actualOwner?.userId === inputedUserId
                        )
                          continue;
                        if (
                          value?.isSameOrAfter(owner.dateFrom, 'day') &&
                          (owner.withoutExpireDate ||
                            value?.isSameOrBefore(owner.dateTo, 'day'))
                        ) {
                          reject('Konflikt z innymi właścicielami lokalu');
                        }
                      }
                      resolve();
                    });
                  },
                },
              ]}
            >
              <DatePicker
                size='large'
                placeholder='Data od'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 20,
              }}
            >
              Ostatnio od:
              <span style={{ fontWeight: 700, marginLeft: 5 }}>
                {premise.premisesOwnerDateFrom ?? 'brak'}
              </span>
            </div>
          </Col>
          <Col xs={24} lg={12} xxl={6}>
            <Form.Item
              name='dateTo'
              rules={
                withoutExpireDate
                  ? [
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            const inputedUserId = form.getFieldValue('userId');

                            if (withoutExpireDate) {
                              for (let owner of allOwners) {
                                if (
                                  owner.id === actualOwner?.id &&
                                  actualOwner?.userId === inputedUserId
                                )
                                  continue;
                                if (owner.withoutExpireDate) {
                                  reject(
                                    'Istnieje już właściciel z datą do odwołania'
                                  );
                                }
                              }
                            }
                            resolve();
                          });
                        },
                      },
                    ]
                  : [
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            const inputedUserId = form.getFieldValue('userId');
                            const inputedDateFrom =
                              form.getFieldValue('dateFrom');
                            const inputedDateTo = form.getFieldValue('dateTo');

                            if (
                              !inputedDateTo &&
                              (inputedUserId || inputedDateFrom)
                            ) {
                              reject('Pole wymagane');
                            }
                            resolve();
                          });
                        },
                      },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (value) {
                              const inputedDateFrom =
                                form.getFieldValue('dateFrom');

                              if (value > inputedDateFrom) {
                                resolve();
                              } else {
                                reject('Data do musi być później niż data od');
                              }
                            }
                            resolve();
                          });
                        },
                      },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (value) {
                              const inputedUserId =
                                form.getFieldValue('userId');

                              for (let owner of allOwners) {
                                if (
                                  owner.id === actualOwner?.id &&
                                  actualOwner?.userId === inputedUserId
                                )
                                  continue;
                                if (
                                  value?.isSameOrAfter(owner.dateFrom, 'day') &&
                                  (owner.withoutExpireDate ||
                                    value?.isSameOrBefore(owner.dateTo, 'day'))
                                ) {
                                  reject(
                                    'Konflikt z innymi właścicielami lokalu'
                                  );
                                }
                              }
                            }
                            resolve();
                          });
                        },
                      },
                    ]
              }
            >
              <DatePicker
                size='large'
                placeholder='Data do'
                style={{ width: '100%' }}
                disabled={withoutExpireDate}
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 10,
              }}
            >
              Ostatnio do:
              <span style={{ fontWeight: 700, marginLeft: 5 }}>
                {premise.premisesOwnerDateTo
                  ? premise.premisesOwnerDateTo
                  : premise.premisesOwnerWithoutExpireDate
                  ? 'do odwołania'
                  : 'brak'}
              </span>
            </div>
            <Form.Item name='withoutExpireDate' valuePropName='checked'>
              <div style={{ textAlign: 'right', paddingRight: 10 }}>
                <span style={{ marginRight: 10 }}>Do odwołania</span>
                <Switch
                  checked={withoutExpireDate}
                  onChange={(checked) => {
                    setWithoutExpireDate(checked);

                    if (checked) {
                      form.resetFields(['dateTo']);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
});

const useQuery = () => new URLSearchParams(useLocation().search);

const AssignOwners = () => {
  const { investmentId } = useParams();
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [premises, setPremises] = useState([]);
  const itemsRefs = useRef(Array(query.getAll('premiseId').length));

  useEffect(() => {
    const premisesIds = query.getAll('premiseId');

    const PremisesService = new premisesService(investmentId);
    const premisesData = [];
    const promises = premisesIds.map((premiseId) =>
      PremisesService.getOne(premiseId).then((data) => {
        premisesData.push(data);
      })
    );

    Promise.all(promises).then(() => {
      setLoading(false);
      setPremises(premisesData);
    });
  }, [investmentId]);

  const handleSubmit = () => {
    const validatePromises = [];

    for (let itemRef of itemsRefs.current) {
      validatePromises.push(itemRef.validateForm());
    }

    Promise.all(validatePromises)
      .then(() => {
        const submitPromises = [];

        for (let itemRef of itemsRefs.current) {
          submitPromises.push(itemRef.submitForm());
        }

        Promise.all(submitPromises).then(() => {
          history.goBack();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Przypisz właściciela'
          className='page-header-extra-2'
        >
          <Card className='assignCard'>
            {premises.map((premise, idx) => (
              <OwnerForm
                key={idx}
                premise={premise}
                ref={(el) => {
                  itemsRefs.current[idx] = el;
                }}
              />
            ))}
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={() => window.history.back()}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Button
                type='primary'
                size='large'
                className='create-form-button'
                onClick={handleSubmit}
              >
                Zapisz
              </Button>
            </Col>
          </Row>
        </PageHeader>
      )}
    </div>
  );
};

export default AssignOwners;
