import React from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import moment from 'moment';
import tenantChangesService from '../../services/tenantChangeGroups';
import tranchesService from '../../services/tranchesService';
import Footer from '../../helpers/Footer';
import Input from '../../components/InputComponent';

const { DatePicker, PriceInput, Select } = Input;
const { Option } = Select;

const Edit = (props) => {
  const {
    data, afterSave, afterCancel, recordType, receipt,
  } = props;
  const [form] = Form.useForm();

  if (data?.id) {
    form.setFieldsValue({
      ...data,
      expirationDate: data.expirationDate ? moment(data.expirationDate) : null,
      paymentDate: data.paymentDate ? moment(data.paymentDate) : null,
    });
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values?.expirationDate) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            expirationDate: values.expirationDate.format('YYYY-MM-DD'),
          };
        }
        if (values?.paymentDate) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            paymentDate: values.paymentDate.format('YYYY-MM-DD'),
          };
        }
        let status;

        if (parseFloat(values.amount) === parseFloat(values.paid)) {
          status = 'PAID';
        } else if (parseFloat(values.paid) > 0) {
          status = 'PARTIALLY_PAID';
        } else {
          status = 'TO_PAY';
        }

        if (recordType === 'TENANT_CHANGE') {
          // eslint-disable-next-line no-param-reassign
          values.paymentStatus = status;
          const TenantChangesService = new tenantChangesService(
            receipt.investmentId, receipt.premisesId,
          );

          TenantChangesService.update(data.id, values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          // eslint-disable-next-line no-param-reassign
          values.status = status;
          const TranchesService = new tranchesService(receipt.investmentId, receipt.premisesId);

          TranchesService.update(data.id, values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? `Edytujesz: ${data.name}` : 'Nowy stan liczników';

  const { isModalVisible } = props;
  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="expirationDate"
              >
                <DatePicker size="large" placeholder="Termin płatności" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} hidden>
              <Form.Item
                name={recordType === 'TENANT_CHANGE' ? 'paymentStatus' : 'status'}
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select
                  placeholder="Status"
                  allowClear
                  size="large"
                >
                  <Option value="TO_PAY">
                    <span className="dot red" />
                    Do zapłaty
                  </Option>
                  <Option value="PARTIALLY_PAID">
                    <span className="dot yellow" />
                    Cześciowo zapłacone
                  </Option>
                  <Option value="PAID">
                    <span className="dot green" />
                    Zapłacone
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="paymentDate"
              >
                <DatePicker size="large" placeholder="Data opłacenia" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="paid"
              >
                <PriceInput size="large" placeholder="Zapłacono" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="amount"
              >
                <PriceInput size="large" placeholder="Kwota" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;
