import React from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import generateSettlementService from '../../../services/generateSettlementService';
import moment from 'moment';

const { DatePicker } = Input;

const GenerateModal = (props) => {
  const { afterCancel, isModalVisible, id } = props;
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const tempValues = {};

        tempValues.createDate = moment(values.createDate, 'YYYY-MM-DD').format(
          'YYYY-MM-DD'
        );

        generateSettlementService
          .generateSettlement(id, tempValues)
          .then((response) => {
            form.resetFields();
            afterCancel();
          })
          .catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type={'error'}>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
            if (err && err.status === 204) {
              form.resetFields();
              afterCancel();
            }
          });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={'Generuj rozliczenia'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={400}
        footer={
          <Footer
            onReset={onReset}
            onFinish={onFinish}
            customSendName={'Generuj'}
          />
        }
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row xs={24}>
            <Col xs={24} lg={24}>
              <Form.Item
                name='createDate'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker
                  size='large'
                  placeholder='Data utworzenia rozliczenia'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default GenerateModal;
