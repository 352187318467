import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import memberAssociationsService from '../../../../services/memberAssociationsService';
import Icon from '../../../../components/Icon';
import Spin from '../../../../components/Spin';

const SharesBox = ({ premisesView = false }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [participation, setParticipation] = useState(false);

  useEffect(() => {
    const MemberAssociationsService = new memberAssociationsService();
    MemberAssociationsService.getParticipation(id).then((data) => {
      setParticipation(data);
      setLoading(false);
    });
  }, []);

  return (
    <div
      className='premises-show-box info-box secondary-bg'
      style={{
        color: '#fff',
        padding: 25,
        paddingRight: 10,
        marginTop: premisesView ? 0 : 25,
      }}
    >
      <h2
        className='shares-tooltip-header'
        style={{ fontSize: 10, textAlign: 'right' }}
      >
        <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
        <Tooltip
          placement='bottomLeft'
          title={
            'Wyliczany na podstawie lokali, w których lokator jest właścicielem'
          }
          overlayInnerStyle={{
            fontSize: 10,
            fontWeight: 600,
            minHeight: 20,
          }}
          showArrow={false}
          trigger={'hover'}
        >
          <Icon
            name={'important'}
            style={{
              cursor: 'pointer',
            }}
          />
        </Tooltip>
      </h2>
      {!participation ? (
        <Spin />
      ) : (
        <div className='content'>
          <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
            {participation?.percent?.toFixed(2).toString().replace('.', ',')}%
          </div>
          <div style={{ fontSize: 24, lineHeight: '32px' }}>
            <span style={{ fontWeight: 700 }}>
              {participation?.premisesSize?.toFixed(2)}
            </span>
            {'  '}/ {participation?.investmentSize?.toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default SharesBox;
