import React from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';
import faultsService from '../../../../services/faultsService';

const { TextArea } = Input;

const DeveloperNoteModal = (props) => {
  const {
    data, afterSave, afterCancel, isCorrect, investmentId,
  } = props;
  const [form] = Form.useForm();

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const requsetBody = {
          ...values,
          isCorrect,
        };
        const FaultService = new faultsService(
          investmentId, data.groupId,
        );

        FaultService.updateConfirmation(data.id, requsetBody).then((response) => {
          afterSave(response);
        })
          .catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const { isModalVisible } = props;
  return (
    <>
      <Modal
        title="Dodaj notatkę"
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={false} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name="developerNote"
              >
                <TextArea size="large" placeholder="Opis" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DeveloperNoteModal;
