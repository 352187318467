import React from 'react';
import { Row, Col, Button } from 'antd';
import notificationsService from '../../services/notificationsService';
import Empty from '../../components/Empty';
import SingleNotification from './SingleNotification';

const Notifications = ({ data, refresh, hideNotifications, userData }) => {
  const markAllAsRead = () => {
    const promises = data
      .filter((entry) => !entry.isRead)
      .map((entry) =>
        notificationsService
          .updateFields(entry.id, {
            isRead: true,
          })
          .catch((err) => {
            if (err && err.message === 'demo') {
              // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          })
      );

    Promise.all(promises).then(() => refresh());
  };

  return (
    <div className='notifications-wrapper'>
      <div className='notifications'>
        {data.map((rowElem) => (
          <SingleNotification
            key={rowElem.id}
            data={rowElem}
            refresh={refresh}
            userData={userData}
            refreshAll={refresh}
            hideNotifications={hideNotifications}
          />
        ))}
        {data.length === 0 && (
          <div style={{ padding: '20px' }}>
            <Empty />
          </div>
        )}
      </div>
      <div className='actions'>
        <Row>
          <Col xs={11}>
            <Button
              size='large'
              className='button-secondary'
              onClick={() => markAllAsRead()}
            >
              Wszystko odczytane
            </Button>
          </Col>
          <Col offset={2} xs={11} style={{ textAlign: 'right' }}>
            <Button
              size='large'
              className='button-secondary'
              onClick={() => hideNotifications()}
            >
              Anuluj
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Notifications;
