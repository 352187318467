import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Upload, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import settlementsImportsService from '../../../services/settlementsImportsService';
import ImportsModal from './ImportsModal';
import ProgressModal from '../../../components/ProgressModal';

export const ImportsList = () => {
  const [imports, setImports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [importRecordData, setImportRecordData] = useState({});
  const { id } = useParams();
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    settlementsImportsService.getImports(id).then((data) => {
      console.log(data);
      setImports(data);
    });
  }, [id]);

  const refresh = () => {
    settlementsImportsService.getImports(id).then((data) => {
      setImports(data);
    });
  };

  const columns = [
    {
      title: 'Autor',
      dataIndex: 'author',
      editable: false,
    },
    {
      title: 'Ilość rekordów',
      dataIndex: 'records',
      editable: false,
    },
    {
      title: 'Waga',
      dataIndex: 'fileSize',
      editable: false,
      render: (text) => `${text} kB`,
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      editable: false,
    },
    {
      className: 'show-import-btn',
      render: (text, record) => (
        <Tooltip title={'Podgląd importu'}>
          <Icon
            className='show-import-icon'
            name='eye-visible'
            onClick={() => {
              setImportRecordData(record);
              setShowModal(true);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('importFile', data.file);
    formData.append('fileName', data.file.name);

    setProgressModalVisible(true);

    settlementsImportsService
      .import(formData, {
        onUploadProgress: (progressEvent) => {
          setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
        },
      })
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          message.success(
            <MessagePopup
              type='success'
              annotation={'Twoja baza danych została zaimportowana poprawnie'}
            >
              Import zakończony sukcesem
            </MessagePopup>
          );
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        } else if (err?.response?.status === 406) {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Przekroczono limit lokali dla obecnego pakietu'}
            >
              Limit lokali został przekroczony
            </MessagePopup>
          );
        } else {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Nie możemy zaimportować pliku'}
            >
              Import nie powiódł się
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return isLt15M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          onClick={() => {}}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <ImportsModal
            afterCancel={() => setShowModal(false)}
            showModal={showModal}
            importData={importRecordData}
          />
          <SmallList
            data={imports}
            columns={columns}
            title='Lista importów'
            customAddButton={<CustomAddButton />}
            hideEdit
            tableClassName={imports.length !== 0 && 'contacts-table'}
          />
          <div
            className='attachmentsNote'
            style={{ marginTop: '20px', fontSize: '10px', color: '#B1CADE' }}
          >
            Wymagany plik w standardzie MT940 wygenerowany przez bank.
          </div>
        </Col>
      </Row>
    </div>
  );
};
