import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import tenantChangesService from '../../services/tenantChangeGroups';
import investmentsService from '../../services/investmentsService';
import premisesService from '../../services/premisesService';
import workersService from '../../services/workersService';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';

const {
  DatePicker, Select, DebounceSelect,
} = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const premisesId = query.get('premisesId') || null;
  const investmentId = query.get('investmentId') || null;
  const [investments, setInvestments] = useState([]);
  const [premises, setPremises] = useState([]);
  const [workers, setWorkers] = useState([]);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: 'SALE',
    }).then((data) => {
      setInvestments(data.content);
    });

    if (premisesId && investmentId) {
      const PremisesService = new premisesService(investmentId);
      PremisesService.getList({ pagination: { pageSize: 1000 } }).then((premisesData) => {
        setPremises(premisesData.content);
      });
    }
  }, [investmentId, premisesId]);

  const onFinish = (values) => {
    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        addDate: values.addDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.changeDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        changeDate: values.changeDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.paymentExpiryDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        paymentExpiryDate: values.paymentExpiryDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.paymentDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        paymentDate: values.paymentDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.responsibleWorker) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        responsibleWorker: values.responsibleWorker[0],
      };
    }

    const TenantChangesService = new tenantChangesService(
      values.investmentId, values.premisesId, null,
    );
    TenantChangesService.create(values).then((response) => {
      if (response.id) {
        let createdState = '';
        if (premisesId && investmentId) {
          createdState = 'createdFromInvestment';
        }
        history.push(`/tenantChangeGroup/${response.investmentId}/premises/${response.premisesId}/tenantChangeGroupId/${response.id}/edit`, createdState);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInvestmentChange = (selectedId) => {
    const PremisesService = new premisesService(selectedId);
    PremisesService.getList({ pagination: { pageSize: 1000 } }).then((premisesData) => {
      form.setFieldsValue({ premisesId: null });
      setPremises(premisesData.content);
    });
  };

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const premisesOptions = premises.map((premise) => (
    <Option value={premise.id} key={premise.id}>{premise.name}</Option>
  ));

  const handleBack = () => {
    if (premisesId && investmentId) {
      return history.push(`/investments/${investmentId}/premises/${premisesId}/edit`);
    }

    return history.push('/tenantChangeGroup');
  };

  async function fetchUserList(username) {
    return workersService.getList(
      { role: 'ROLE_WORKER', pagination: { pageSize: 1000 } },
    ).then((clientsData) => clientsData.content)
      .then((clientsData) => {
        const filteredResults = clientsData.filter((entry) => {
          const { firstName = '', lastName } = entry;
          if (!username) {
            return true;
          }
          const firstNameCheck = firstName.toUpperCase().search(username.toUpperCase());
          const lastNameCheck = lastName.toUpperCase().search(username.toUpperCase());

          return firstNameCheck !== -1 || lastNameCheck !== -1;
        });
        return filteredResults;
      })
      .then((clientsData) => clientsData.map((client) => {
        const pesel = client.identity && client.identity.pesel ? `(${client.identity.pesel})` : '';
        return {
          label: `${client.firstName} ${client.lastName} ${pesel}`,
          value: client.id,
        };
      }));
  }

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => handleBack()}
        title="Dodaj zmianę lokatorską"
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          initialValues={{
            investmentId, premisesId, addDate: moment(), status: 'ACTIVE',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <div className="create-form-wrapper">
                <Row>
                  <Col xs={20} offset={2}>
                    <div className="form-section">
                      <h2>Lokal</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="investmentId"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Inwestycja"
                              allowClear
                              size="large"
                              onChange={(e) => onInvestmentChange(e)}
                            >
                              {investmentsOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="premisesId"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Lokal"
                              allowClear
                              size="large"
                            >
                              {premisesOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="form-section">
                      <h2>Zmiana lokatorska</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="name"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Input size="large" placeholder="Nazwa" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="status"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Status"
                              allowClear
                              size="large"
                            >
                              <Option value="ACTIVE">
                                <span className="dot red" />
                                Do zmiany
                              </Option>
                              <Option value="DONE">
                                <span className="dot green" />
                                Zmienione
                              </Option>
                              <Option value="REJECTED">
                                <span className="dot blue" />
                                Odrzucona
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="addDate"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <DatePicker size="large" placeholder="Data dodania" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="changeDate"
                          >
                            <DatePicker size="large" placeholder="Data wprowadzenia zmian" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="form-section">
                      <h2>Szczegóły</h2>
                      <Row gutter={20}>
                        <Col xs={24}>
                          <Form.Item
                            name="responsibleWorker"
                          >
                            <DebounceSelect
                              mode="multiple"
                              value={workers}
                              placeholder="Osoba odpowiedzialna"
                              size="large"
                              fetchOptions={fetchUserList}
                              onChange={(newValue) => {
                                setWorkers(newValue.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleBack}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
