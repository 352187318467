import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Upload,
  message,
  Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import housingCommunitiesService from '../../services/housingCommunitiesService';
import userService from '../../services/userService';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import MessagePopup from '../../components/MessagePopup';
import Spin from '../../components/Spin';
import ProgressModal from '../../components/ProgressModal';

const { DatePicker, Select } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [communityAdmin, setCommunityAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setCommunityAdmin(data);
      setLoading(false);
    });
  }, []);

  const onFinish = (values) => {
    const data = {
      ...values,
      type: 'HOUSING_COMMUNITY',
      createDate: values.createDate.format('YYYY-MM-DD'),
    };

    if (data.nip === '___-___-__-__') {
      data.nip = null;
    }
    if (data.regon === '_________') {
      data.regon = null;
    }

    data.isFaultsDisabled = !data.isFaultsActive;
    delete data.isFaultsActive;

    if (communityAdmin) {
      data.communityAdminId = communityAdmin.id;
    }

    const formData = new FormData();

    formData.append('investment', JSON.stringify(data));
    if (logo.length) {
      formData.append('logo', logo[0].originFileObj);
    }

    if (thumb.length) {
      formData.append('thumbnail', thumb[0].originFileObj);
    }

    const HousingCommunitiesService = new housingCommunitiesService();

    setProgressModalVisible(true);

    HousingCommunitiesService.create(formData, {
      onUploadProgress: (progressEvent) => {
        setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          <MessagePopup type={'success'}>
            Wspólnota została utworzona
          </MessagePopup>;
          history.replace(`/housingCommunities/${response.id}/show`);
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
        if (err?.response?.status === 406) {
          message.error(
            <MessagePopup type={'error'}>
              Przekroczono limit inwestycji dla obecnego pakietu
            </MessagePopup>
          );
        }
      });
  };

  const handleLogoChange = (info) => {
    setLogo(info.fileList);
  };
  const handleThumbChange = (info) => {
    setThumb(info.fileList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(
        <MessagePopup type={'error'}>
          Dozwolone są tylko pliki graficzne
        </MessagePopup>
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 2MB
        </MessagePopup>
      );
    }
    return !(isJpgOrPng && isLt2M);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const uploadButton = (
    <div className='upload-btn-wrapper'>
      <Icon name='upload' className='upload-img-icon' />
      <div style={{ marginTop: 8 }}>Przeciągnij lub dodaj plik</div>
    </div>
  );

  const handleCancel = () => {
    // history.push('/housingCommunities');
    history.goBack();
  };

  return (
    <div className='form-create'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
        text={'Trwa dodawanie plików...'}
      />
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          // onBack={() => history.push('/housingCommunities')}
          onBack={() => history.goBack()}
          title='Dodaj wspólnotę'
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              address: { country: 'Polska' },
              createDate: moment(),
              isFaultsActive: true,
            }}
            autoComplete='off'
          >
            <>
              <div className='create-form-wrapper'>
                <Row>
                  <Col xs={24} sm={{ span: 20, offset: 2 }}>
                    <div className='form-section'>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <h2>Logo wspólnoty</h2>
                          <Upload
                            listType='picture-card'
                            fileList={logo}
                            onChange={handleLogoChange}
                            beforeUpload={beforeUpload}
                            showUploadList={{
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              showDownloadIcon: false,
                            }}
                          >
                            {logo.length === 0 ? uploadButton : ''}
                          </Upload>
                          <span
                            style={{
                              marginTop: '20px',
                              fontSize: '12px',
                              color: '#C0D6E5',
                            }}
                          >
                            Zalecany format plików: kwadrat
                          </span>
                        </Col>
                        <Col xs={24} sm={12}>
                          <h2>Miniaturka</h2>
                          <Upload
                            listType='picture-card'
                            fileList={thumb}
                            onChange={handleThumbChange}
                            beforeUpload={beforeUpload}
                            showUploadList={{
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              showDownloadIcon: false,
                            }}
                          >
                            {thumb.length === 0 ? uploadButton : ''}
                          </Upload>
                          <span
                            style={{
                              marginTop: '20px',
                              fontSize: '12px',
                              color: '#C0D6E5',
                            }}
                          >
                            Zalecany format plików: kwadrat
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Dane podstawowe</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='name'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić nazwę',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Nazwa wspólnoty' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='createDate'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić datę powstania',
                              },
                            ]}
                          >
                            <DatePicker
                              size='large'
                              placeholder='Data powstania'
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='heatSource'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę wybrać źródło ciepła',
                              },
                            ]}
                          >
                            <Select placeholder='Źródło ciepła' size='large'>
                              <Option value='GAS'>Gaz</Option>
                              <Option value='SYSTEM'>Ciepło systemowe</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Adres</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'street']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Ulica' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item name={['address', 'number']}>
                            <Input size='large' placeholder='Numer' />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'city']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Miejscowość' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'zip']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input.MaskedInput
                              mask='00-000'
                              size='large'
                              placeholder='Kod pocztowy'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'country']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <CountrySelect />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Powierzchnia</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='size'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę podać powierzchnię całkowitą',
                              },
                            ]}
                          >
                            <Input.InputNumberV2
                              size='large'
                              placeholder='Powierzchnia całkowita'
                              addonAfter={
                                <>
                                  m<sup>2</sup>
                                </>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={0} sm={12} />
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Dane do rozliczeń</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item name='nip'>
                            <Input.MaskedInput
                              mask='000-000-00-00'
                              size='large'
                              placeholder='NIP'
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item name='regon'>
                            <Input.MaskedInput
                              mask='000000000'
                              size='large'
                              placeholder='Regon'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Zgłaszanie usterek</h2>
                      <Row gutter={20}>
                        <Col xs={24}>
                          <Form.Item
                            name='isFaultsActive'
                            label='Aktywne'
                            valuePropName='checked'
                          >
                            {/* <Switch size='large' /> */}
                            <Checkbox />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleCancel}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      Dodaj
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
