/* eslint-disable class-methods-use-this */
import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberAssociationsService extends BaseService {
  constructor(url = '/api/user/investment/community') {
    super();

    this.url = url;
  }

  async getDetails(id) {
    return httpService.get(`/api/user/investment/${id}/community`, null, true);
  }

  async getOne(id) {
    return httpService.get(`/api/user/investment/${id}/community`, null, true);
  }

  async getContacts(id) {
    return httpService.get(
      `/api/user/investment/${id}/community/contact`,
      null,
      true
    );
  }

  async getDeveloper(id) {
    return httpService.get(
      `/api/user/investment/${id}/community/developer`,
      null,
      true
    );
  }

  async getCommunityAdmin(id) {
    return httpService.get(
      `/api/user/investment/${id}/community/admin`,
      null,
      true
    );
  }

  async getParticipation(id) {
    return httpService.get(
      `/api/user/investment/${id}/community/premises/participation`,
      null,
      true
    );
  }

  async getWorkers(id) {
    return httpService.get(
      `/api/user/investment/${id}/community/workers`,
      null,
      true
    );
  }
}

export default MemberAssociationsService;
