import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultAttachmentsService extends BaseService {
  constructor(investmentId, faultGroupId, faultId) {
    super();
    const roles = localStorage.getItem('roles');

    this.url = `/api/vendor/investment/${investmentId}/faultGroup/${faultGroupId}/fault/${faultId}/attachment`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/faultGroup/${faultGroupId}/fault/${faultId}/attachment`;
    }

    if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/investments/${investmentId}/faultGroups/${faultGroupId}/faults/${faultId}/attachments`;
    }
  }

  async updateData(id, data) {
    return httpService.patch(`${this.url}/${id}`, data, true);
  }
}

export default FaultAttachmentsService;
