import BaseService from './baseService';
import httpService from '../helpers/httpService';

const CommunityAdminUserService = new BaseService('/api/admin/user');

CommunityAdminUserService.packageUpdate = async (id, data) => {
  return httpService.put(`/api/user/packages/${id}/limits`, data, true);
};

CommunityAdminUserService.changeSmsLimit = async (id) => {
  return httpService.patch(`/api/user/packages/${id}/limits/sms`, {}, true);
};

export default CommunityAdminUserService;
