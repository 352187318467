import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';

export const InfoBox = ({ fault, faultGroup }) => {
  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Informacje
      </h2>
      <div className='content'>
        <Row className='entry'>
          <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
            Inwestycja:
          </Col>
          <Col xs={24} sm={10} className='value'>
            {faultGroup?.investmentName}
          </Col>
        </Row>
        <Row className='entry' style={{ marginBottom: 20 }}>
          <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
            Lokal:
          </Col>
          <Col xs={24} sm={10} className='value'>
            {faultGroup?.premisesName || 'Część wspólna'}
          </Col>
        </Row>
        {fault?.addDate && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Data wystąpienia usterki:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {fault.addDate}
            </Col>
          </Row>
        )}
        {fault?.developerVerificationDate && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Potwierdzono zasadność:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {fault.developerVerificationDate}
            </Col>
          </Row>
        )}
        {fault?.fixDate && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Usunięto:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {fault.fixDate}
            </Col>
          </Row>
        )}
        {/* {fault?.responsibleWorkers &&
          fault?.responsibleWorkers?.length !== 0 && (
            <div style={{ marginTop: '20px' }}>
              <h3>Osoba odpowiedzialna</h3>
              <div className='content'>
                <SmallList
                  data={fault.responsibleWorkers}
                  columns={columns}
                  showHeader={false}
                  icon='worker'
                />
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
};
