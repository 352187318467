import React from 'react';
import { Modal, Table } from 'antd';
import Icon from '../../../components/Icon';

const columns = [
  {
    title: 'Tytuł przelewu',
    dataIndex: 'description',
    editable: false,
  },
  {
    title: 'Numer rachunku',
    dataIndex: 'accountNumber',
    editable: false,
  },
  {
    title: 'Data operacji',
    dataIndex: 'operationDate',
    editable: false,
  },
  {
    title: 'Kwota (brutto)',
    dataIndex: 'amount',
    editable: false,
    render: (text, record) => {
      return `${parseFloat(text).toFixed(2).replace('.', ',')} zł`;
    },
  },
  {
    title: 'Rozliczenie',
    dataIndex: 'valid',
    editable: false,
    render: (text, record) => {
      if (text === true) {
        return (
          <div style={{ textAlign: 'center' }}>
            <Icon name='checkbox' style={{ color: '#15D55E' }} />
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: 'center' }}>
            <Icon name='close' style={{ color: '#EC434C' }} />
          </div>
        );
      }
    },
  },
];

const ImportsModal = (props) => {
  const { afterCancel, showModal, importData } = props;

  const onReset = () => {
    afterCancel();
  };

  return (
    <>
      <Modal
        title={'Podgląd importu'}
        centered
        visible={showModal}
        onCancel={() => onReset()}
        width={970}
        footer={false}
        className='import-preview-modal'
      >
        <div className='import-preview-wrapper'>
          <Table
            dataSource={importData?.entries}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) => {
              if (!record?.valid) {
                return 'row-red';
              }
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImportsModal;
