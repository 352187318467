/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Grid } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';

import premiseTypeMap from '../../../helpers/premiseTypeMap';
import Price from '../../../components/Price';
import Icon from '../../../components/Icon';
import Empty from '../../../components/Empty';
import Spin from '../../../components/Spin';
import Dashboard from '../../../services/dashboardService';
import SettlementsGroups from '../../../services/settlementsGroupsService';
import agreementAttachmentsService from '../../../services/agreementAttachmentsService';
import userService from '../../../services/userService';

const { useBreakpoint } = Grid;

export const StatsWrapper = (props) => {
  const { title, children, icon, actions, headerLink, actionBtns } = props;
  return (
    <div className='dashboard-elem'>
      <StatsHeader
        title={title}
        icon={icon}
        actions={actions}
        headerLink={headerLink}
        actionBtns={actionBtns}
      />
      <div className='content'>{children}</div>
    </div>
  );
};

export const StatsHeader = (props) => {
  const { title, icon, actions, headerLink, actionBtns } = props;

  return (
    <div className='header'>
      <Row justify='space-between'>
        <Col xs={actionBtns ? 24 : 18} sm={actionBtns ? 8 : 12}>
          <h2 className='title'>
            <Icon name={icon} style={{ marginRight: '5px' }} />
            {headerLink ? (
              <Link to={headerLink} className='dashboard-link'>
                {title}
              </Link>
            ) : (
              title
            )}
          </h2>
        </Col>
        <Col xs={actionBtns ? 24 : 6} sm={actionBtns ? 16 : 12}>
          <div
            className={`actions ${
              actionBtns ? 'dashborad-header-action-btns-wrapper' : null
            }`}
          >
            {actions}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const StatsElem = (props) => {
  const history = useHistory();
  const {
    type = '',
    topHigher,
    topLower,
    bottomHigher,
    bottomLower,
    hideWhenEmpty,
    redirect,
    topBackgroundColor,
    topColor,
    bottomLowerColor,
  } = props;
  const showBottom = !!bottomHigher || !!bottomLower;
  const hideClassName = hideWhenEmpty && topHigher === 0 ? 'hide' : '';
  let onClickAction = () => {};
  let styles = {};
  let topStyles = {};
  let bottomLowerStyles = {};

  if (redirect) {
    onClickAction = () => {
      history.push(redirect);
    };
    styles = {
      cursor: 'pointer',
    };
  }

  if (topBackgroundColor) {
    topStyles.backgroundColor = topBackgroundColor;
  }
  if (topColor) {
    topStyles.color = topColor;
  }
  if (bottomLowerColor) {
    bottomLowerStyles = {
      color: bottomLowerColor,
    };
  }

  return (
    <div
      className={`content-elem ${type} ${hideClassName} ${
        redirect ? 'dashboard-link' : ''
      }`}
      role='presentation'
      onClick={() => onClickAction()}
      style={styles}
    >
      <div className='content-elem-top' style={topStyles}>
        <div className='content-elem-top-higher'>{topHigher}</div>
        <div className='content-elem-top-lower'>{topLower}</div>
      </div>
      {showBottom && (
        <div className='content-elem-bottom'>
          <div className='content-elem-bottom-higher'>{bottomHigher}</div>
          <div className='content-elem-bottom-lower'>
            <Price value={bottomLower} style={bottomLowerStyles} showEmpty />
          </div>
        </div>
      )}
    </div>
  );
};

export const StatsElemSaleCustom = (props) => {
  const history = useHistory();
  const {
    type = '',
    topHigher,
    topLower,
    bottomHigher,
    bottomLower,
    hideWhenEmpty,
    redirect,
    premisesData,
    defaultPremiseType = 'SALE',
  } = props;
  const showBottom = topHigher > 0;
  const hideClassName = hideWhenEmpty && topHigher === 0 ? 'hide' : '';
  let onClickAction = () => {};
  let styles = {};

  if (redirect) {
    onClickAction = () => {
      history.push(redirect);
    };
    styles = {
      cursor: 'pointer',
    };
  }

  const premiseRenderer = (key) =>
    !!premisesData[key] &&
    premisesData[key].count > 0 && (
      <Col md={6} sm={12} xs={24}>
        <div className='custom-premises-dashboard-stats-premises-top'>
          {premisesData[key].count}
        </div>
        <div className='custom-premises-dashboard-stats-premises-bottom'>
          {premiseTypeMap(key)}
        </div>
      </Col>
    );

  return (
    <div
      className={`content-elem custom-premises-dashboard-stats ${type} ${hideClassName} ${
        redirect ? 'dashboard-link' : ''
      }`}
      role='presentation'
      onClick={() => onClickAction()}
      style={styles}
    >
      <div className='content-elem-top'>
        <Row>
          <Col xs={24} sm={12}>
            <div className='content-elem-top-higher'>{topHigher}</div>
            <div className='content-elem-top-lower'>{topLower}</div>
          </Col>
          {bottomHigher && (
            <Col
              xs={24}
              sm={12}
              className='non-mobile-align-right'
              style={{ alignSelf: 'center' }}
            >
              <div className='content-elem-top-lower'>{bottomHigher}</div>
              <div
                className='content-elem-top-higher'
                style={{ fontSize: '16px' }}
              >
                <Price value={bottomLower} showEmpty />
              </div>
            </Col>
          )}
        </Row>
      </div>
      {showBottom && (
        <div className='content-elem-bottom'>
          <Row>
            {premiseRenderer(defaultPremiseType)}
            {premiseRenderer('SERVICE')}
            {premiseRenderer('PARKING')}
            {premiseRenderer('STORAGE')}
          </Row>
        </div>
      )}
    </div>
  );
};

export const StatsElemFull = (props) => {
  const {
    type = '',
    leftTop,
    leftBottom,
    middleTop,
    middleBottom,
    rightTop,
    rightBottom,
    href,
  } = props;
  return (
    <div className={`content-elem-full ${type}`}>
      <Row gutter={20}>
        <Col sm={8}>
          <div className='content-elem-full-left-top'>{leftTop}</div>
          <div className='content-elem-full-left-bottom'>{leftBottom}</div>
        </Col>
        <Col sm={7}>
          <div className='content-elem-full-middle-top'>{middleTop}</div>
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className='content-elem-full-middle-bottom'
            dangerouslySetInnerHTML={{ __html: middleBottom }}
          />
        </Col>
        <Col sm={7}>
          <div className='content-elem-full-right-top'>{rightTop}</div>
          {/* eslint-disable-next-line react/no-danger */}
          <div
            className='content-elem-full-right-bottom'
            dangerouslySetInnerHTML={{ __html: rightBottom }}
          />
        </Col>
        <Col sm={2}>
          <div className='content-elem-full-href'>
            <Link to={href}>
              <Icon name='arrow-simply-right' />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const StatsElemFullTwoColumns = (props) => {
  const {
    type = '',
    leftTop,
    leftBottom,
    middleTop,
    middleBottom,
    href,
  } = props;
  return (
    <div className={`content-elem-full ${type}`}>
      <Row gutter={20}>
        <Col sm={11}>
          <div className='content-elem-full-left-top'>{leftTop}</div>
          <div className='content-elem-full-left-bottom'>{leftBottom}</div>
        </Col>
        <Col sm={11}>
          <div className='content-elem-full-middle-top'>{middleTop}</div>
          <div
            className='content-elem-full-middle-bottom'
            dangerouslySetInnerHTML={{ __html: middleBottom }}
          />
        </Col>
        <Col sm={2}>
          <div className='content-elem-full-href'>
            <Link to={href}>
              <Icon name='arrow-simply-right' />
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export const RentFaultsComponent = ({ withHeader = true }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getRentFaults().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          {withHeader && (
            <StatsHeader
              title='Usterki'
              icon='fault'
              actions={[
                <Link key='faults2' to='faultGroup/create'>
                  <Button size='small'>
                    Dodaj nową
                    <Icon name='plus' />
                  </Button>
                </Link>,
              ]}
            />
          )}
          <Row gutter={20}>
            <Col xs={12}>
              <StatsElem
                redirect='/faultGroup?status=REPORTED'
                type='dashboard-elem-empty'
                topHigher={data.reportedCount}
                topLower='Zgłoszone'
              />
              <StatsElem
                redirect='/faultGroup?status=DONE'
                type='dashboard-elem-empty'
                topHigher={data.finishedCount}
                topLower='Zakończone'
              />
            </Col>
            <Col xs={12}>
              <StatsElem
                redirect='/faultGroup?status=TO_FIX'
                type='dashboard-elem-empty'
                topHigher={data.forRepairCount}
                topLower='Do naprawy'
              />
              <StatsElem
                redirect='/faultGroup?status=REJECTED'
                type='dashboard-elem-empty'
                topHigher={data.rejectedCount}
                topLower='Odrzucone'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const SaleFaultsComponent = ({ withHeader = true }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getSaleFaults().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          {withHeader && (
            <StatsHeader
              title='Usterki'
              icon='fault'
              actions={[
                <Link key='faultGroup/create' to='faultGroup/create'>
                  <Button size='small'>
                    Dodaj nową
                    <Icon name='plus' />
                  </Button>
                </Link>,
              ]}
            />
          )}
          <Row gutter={20}>
            <Col xs={12}>
              <StatsElem
                redirect='/faultGroup?status=REPORTED'
                type='dashboard-elem-empty'
                topHigher={data.reportedCount}
                topLower='Zgłoszone'
              />
              <StatsElem
                redirect='/faultGroup?status=DONE'
                type='dashboard-elem-empty'
                topHigher={data.finishedCount}
                topLower='Zakończone'
              />
            </Col>
            <Col xs={12}>
              <StatsElem
                redirect='/faultGroup?status=IN_PROGRESS'
                type='dashboard-elem-empty'
                topHigher={data.inProgressCount}
                topLower='Do naprawy'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const TenantChangesComponent = ({ withHeader = true }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getTenantChanges().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          {withHeader && (
            <StatsHeader
              title='Zmiany lokatorskie'
              icon='flat-changes'
              actions={[
                <Link
                  key='tenantChangeGroup/create'
                  to='tenantChangeGroup/create'
                >
                  <Button size='small'>
                    Dodaj nową
                    <Icon name='plus' />
                  </Button>
                </Link>,
              ]}
            />
          )}
          <Row gutter={20}>
            <Col xs={12}>
              <StatsElem
                type='dashboard-elem-empty'
                topHigher={data.changedCount}
                topLower='Zmienionych'
                bottomHigher='Na kwotę'
                bottomLower={data.changedValue}
                redirect='/tenantChangeGroup?status=ACTIVE'
              />
            </Col>
            <Col xs={12}>
              <StatsElem
                type='dashboard-elem-red'
                topHigher={data.forChangeCount}
                topLower='Do zmiany'
                bottomHigher='Na kwotę'
                bottomLower={data.forChangeValue}
                hideWhenEmpty
                redirect='/tenantChangeGroup?status=DONE'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const AgreementsComponent = ({ withHeader = true }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getAgreements().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          {withHeader && <StatsHeader title='Umowy' icon='file' />}
          <Row gutter={20}>
            <Col xs={12}>
              <StatsElem
                type='dashboard-elem-grey'
                topHigher={data.activeCount}
                topLower='Aktywnych'
              />
            </Col>
            <Col xs={12}>
              <StatsElem
                type='dashboard-elem-grey'
                topHigher={data.expiredCount}
                topLower='Wygasłych'
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const SalePremisesComponent = ({
  workerPermissions = [],
  isWorker = false,
}) => {
  const [saleData, setSaleData] = useState({});
  const [freeData, setFreeData] = useState({});
  const [saleStats, setSaleStats] = useState({});
  const [freeStats, setFreeStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getSalePremises().then((response) => {
      const saleTempData = {};
      const freeTempData = {};
      const saleTempStats = {
        total: 0,
        value: 0,
      };
      const freeTempStats = {
        total: 0,
        value: 0,
      };
      Object.keys(response).forEach((e) => {
        const element = response[e];
        const saleObject = {
          count: element.saleCount,
          value: element.saleValue,
        };
        saleTempData[e] = saleObject;
        saleTempStats.total += element.saleCount;
        saleTempStats.value += element.saleValue;
        const freeObject = {
          count: element.freeCount,
          value: element.freeValue,
        };
        freeTempData[e] = freeObject;
        freeTempStats.total += element.freeCount;
        freeTempStats.value += element.freeValue;
      });
      setSaleData(saleTempData);
      setFreeData(freeTempData);
      setSaleStats(saleTempStats);
      setFreeStats(freeTempStats);

      setLoading(false);
    });
  }, []);

  let removeFields = {};
  if (isWorker && !workerPermissions.includes('STATS_FULL')) {
    removeFields = {
      bottomHigher: false,
      bottomLower: false,
    };
  }
  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          <Col xs={12}>
            <StatsElemSaleCustom
              type='dashboard-elem-darkblue'
              topHigher={saleStats.total}
              topLower='Sprzedanych lokali'
              bottomHigher='Przychód z inwestycji (BP)'
              bottomLower={saleStats.value}
              premisesData={saleData}
              {...removeFields}
            />
          </Col>
          <Col xs={12}>
            <StatsElemSaleCustom
              type='dashboard-elem-darkblue'
              topHigher={freeStats.total}
              topLower='Wolnych lokali'
              bottomHigher='Minimalny przychód z inwestycji (MBP)'
              bottomLower={freeStats.value}
              premisesData={freeData}
              {...removeFields}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export const RentPremisesComponent = ({
  workerPermissions = [],
  isWorker = false,
}) => {
  const [rentData, setRentData] = useState({});
  const [freeData, setFreeData] = useState({});
  const [rentStats, setRentStats] = useState({});
  const [freeStats, setFreeStats] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getRentPremises().then((response) => {
      const rentTempData = {};
      const freeTempData = {};
      const rentTempStats = {
        total: 0,
        value: 0,
      };
      const freeTempStats = {
        total: 0,
        value: 0,
      };
      Object.keys(response).forEach((e) => {
        const element = response[e];
        const saleObject = {
          count: element.rentCount,
          value: element.rentValue,
        };
        rentTempData[e] = saleObject;
        rentTempStats.total += element.rentCount;
        rentTempStats.value += element.rentValue;
        const freeObject = {
          count: element.freeCount,
          value: element.freeValue,
        };
        freeTempData[e] = freeObject;
        freeTempStats.total += element.freeCount;
        freeTempStats.value += element.freeValue;
      });
      setRentData(rentTempData);
      setFreeData(freeTempData);
      setRentStats(rentTempStats);
      setFreeStats(freeTempStats);
      setLoading(false);
    });
  }, []);

  let removeFields = {};
  if (isWorker && !workerPermissions.includes('STATS_FULL')) {
    removeFields = {
      bottomHigher: false,
      bottomLower: false,
    };
  }
  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          <Col xs={12}>
            <StatsElemSaleCustom
              type='dashboard-elem-navy'
              topHigher={rentStats.total}
              topLower='Wynajmowanych lokali'
              bottomHigher='Miesięczny przychód'
              bottomLower={rentStats.value}
              premisesData={rentData}
              defaultPremiseType='RENT'
              {...removeFields}
            />
          </Col>
          <Col xs={12}>
            <StatsElemSaleCustom
              type='dashboard-elem-navy'
              topHigher={freeStats.total}
              topLower='Wolnych lokali'
              premisesData={freeData}
              defaultPremiseType='RENT'
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export const SettlementsComponent = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getSettlements().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          <Col xs={12}>
            <StatsElem
              type='dashboard-elem-blue'
              topHigher={data.settledCount}
              topLower='Rozliczonych'
              bottomHigher='Na kwotę'
              bottomLower={data.settledValue}
              redirect='/settlements'
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              type='dashboard-elem-red'
              topHigher={data.forSettleCount}
              topLower='Do rozliczenia'
              bottomHigher='Na kwotę'
              bottomLower={data.forSettleValue}
              hideWhenEmpty
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export const PaymentsComponent = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getPayments().then((response) => {
      setLoading(false);
      setData(response);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          <Col xs={12}>
            <StatsElem
              redirect='/payments?status=PAID'
              type='dashboard-elem-darkblue'
              topHigher={data.completedCount}
              topLower='Opłaconych'
              bottomHigher='Na kwotę'
              bottomLower={data.completedValue}
            />
          </Col>
          <Col xs={12}>
            <StatsElem
              redirect='/payments?status=NEW'
              type='dashboard-elem-red'
              topHigher={data.expiredCount}
              topLower='Zaległych'
              bottomHigher='Na kwotę'
              bottomLower={data.expiredValue}
              hideWhenEmpty
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export const PremisesListComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const DashboardService = new Dashboard();
    DashboardService.getPremisesList().then((response) => {
      setLoading(false);
      setData(response.content);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          {data.map((premise) => (
            <Col key={premise.id} sm={24}>
              <StatsElemFull
                type='dashboard-elem-full-navy'
                leftTop='Inwestycja'
                leftBottom='nazwa inwestycji'
                middleTop='Numer'
                middleBottom={premise.name}
                rightTop='Adres'
                rightBottom='Adres inwestycji'
                href={`investments/${premise.investmentId}/premises/${premise.id}/show`}
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export const AgreementsListComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    userService.getInfo().then((userDataResponse) => {
      setUserData(userDataResponse);
      const DashboardService = new Dashboard();
      DashboardService.getAgreementsList().then((response) => {
        setData(response.content);
        setLoading(false);
      });
    });
  }, []);

  const handleDownload = (investmentId, premisesId, agreementId, fileId) => {
    const AttachmentsService = new agreementAttachmentsService(
      investmentId,
      premisesId,
      agreementId
    );
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const Attachment = ({
    attachment,
    agreementId,
    premisesId,
    investmentId,
  }) => (
    <Col xs={24} sm={12} key={attachment.id}>
      <div className='attachment'>
        <Row>
          <Col xs={2} className='attachment-icon'>
            <Icon name='file' />
          </Col>
          <Col xs={16} className='attachment-name'>
            {attachment.filename}
          </Col>
          <Col xs={4} offset={2} className='attachment-actions'>
            <Button
              ghost
              className='button-no-border'
              onClick={() =>
                handleDownload(
                  investmentId,
                  premisesId,
                  agreementId,
                  attachment.id
                )
              }
            >
              <Icon name='download' />
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );

  const SingleAgreement = ({ agreement }) => {
    const [showBottom, setShowBottom] = useState(false);

    const screens = useBreakpoint();
    useEffect(() => {
      if (screens.xl === false) {
        setShowBottom(true);
      }
    }, [screens]);

    const isClient = userData?.roles?.includes('ROLE_CLIENT') || false;

    return (
      <Col key={agreement.id} sm={24}>
        <div className='custom-agreements-elem'>
          <Row gutter={20}>
            <Col xs={24} xl={10}>
              <div className='custom-agreements-elem-left'>
                <div className='custom-agreements-elem-top'>
                  {isClient ? (
                    <Row>
                      <Col xs={24} sm={10}>
                        <div className='label'>Inwestycja</div>
                        <div className='value'>{agreement.investmentName}</div>
                      </Col>
                      <Col xs={24} sm={8}>
                        <div className='label'>Typ</div>
                        <div className='value'>
                          {premiseTypeMap(agreement.premisesType)}
                        </div>
                      </Col>
                      <Col xs={24} sm={6}>
                        <div className='label'>Numer</div>
                        <div className='value'>{agreement.premisesName}</div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} sm={8}>
                        <div className='label'>Inwestycja</div>
                        <div className='value'>{agreement.investmentName}</div>
                      </Col>
                      <Col xs={12} sm={8}>
                        <div className='label'>Numer</div>
                        <div className='value'>{agreement.premisesName}</div>
                      </Col>
                      <Col xs={12} sm={8}>
                        <div className='label'>Pomieszczenie</div>
                        <div className='value'>{agreement.roomName}</div>
                      </Col>
                    </Row>
                  )}
                </div>
                {showBottom && (
                  <div className='custom-agreements-elem-bottom'>
                    <Row>
                      {agreement.investmentAddress && (
                        <Col xs={24} sm={8}>
                          <div className='label'>Adres</div>
                          <div className='value'>
                            {agreement.investmentAddress.street}{' '}
                            {agreement.investmentAddress.number}
                            {agreement.investmentAddress.local
                              ? `/${agreement.investmentAddress.local}`
                              : ''}
                            <br /> {agreement.investmentAddress.zip}{' '}
                            {agreement.investmentAddress.city}
                          </div>
                        </Col>
                      )}
                      {agreement.premisesAdministrator && (
                        <Col xs={24} sm={16}>
                          <div className='label'>Administrator</div>
                          <div className='value'>
                            {agreement.premisesAdministrator.firstName}{' '}
                            {agreement.premisesAdministrator.lastName}
                            <br />
                            {agreement.premisesAdministrator.phone}
                            <br />
                            {agreement.premisesAdministrator.email}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} xl={14}>
              <div className='custom-agreements-elem-right'>
                <div className='custom-agreements-elem-top'>
                  {isClient ? (
                    <Row>
                      <Col xs={12} sm={10}>
                        <div className='label'>Numer umowy</div>
                        <div className='value'>{agreement.agreementName}</div>
                      </Col>
                      <Col xs={12} sm={10}>
                        <div className='label'>Typ umowy</div>
                        <div className='value' style={{ fontSize: '12px' }}>
                          Rezerwacyjna
                        </div>
                      </Col>
                      <Col xs={0} xl={2}>
                        <div className='showMoreButton'>
                          {showBottom && (
                            <Icon
                              onClick={() => setShowBottom(!showBottom)}
                              name='arrow-simply-down'
                            />
                          )}
                          {!showBottom && (
                            <Icon
                              onClick={() => setShowBottom(!showBottom)}
                              name='arrow-simply-down'
                              className='rotate'
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} sm={8}>
                        <div className='label'>Koszty najmu</div>
                        <div className='value'>
                          <Price value={agreement.roomRentValue} showEmpty />
                        </div>
                      </Col>
                      <Col xs={12} sm={8}>
                        <div className='label'>Numer umowy</div>
                        <div className='value'>{agreement.agreementName}</div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <div className='label'>Okres trwania</div>
                        <div className='value' style={{ fontSize: '12px' }}>
                          {agreement.agreementDateFrom}
                          <br />
                          {agreement.agreementDateTo}
                        </div>
                      </Col>
                      <Col xs={0} xl={2}>
                        <div className='showMoreButton'>
                          {showBottom && (
                            <Icon
                              onClick={() => setShowBottom(!showBottom)}
                              name='arrow-simply-down'
                            />
                          )}
                          {!showBottom && (
                            <Icon
                              onClick={() => setShowBottom(!showBottom)}
                              name='arrow-simply-down'
                              className='rotate'
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
                {showBottom && (
                  <div className='custom-agreements-elem-bottom'>
                    {isClient ? (
                      <Row>
                        <Col xs={24} sm={10}>
                          <div className='label'>Typ umowy</div>
                          <div className='value'>Rezerwacyjna</div>
                        </Col>
                        {agreement.agreementDateFrom && (
                          <Col xs={24} sm={12}>
                            <div className='label'>Data zawarcia</div>
                            <div className='value'>
                              {agreement.agreementDateFrom}
                            </div>
                          </Col>
                        )}
                        {agreement.attachments.length !== 0 && (
                          <Col xs={24}>
                            <div className='custom-agreements-elem-bottom-attachments'>
                              <h2>Załączniki</h2>
                              <Row>
                                {agreement.attachments.map(
                                  (singleAttachment) => (
                                    <Attachment
                                      attachment={singleAttachment}
                                      agreementId={agreement.agreementId}
                                      investmentId={agreement.investmentId}
                                      premisesId={agreement.premisesId}
                                    />
                                  )
                                )}
                              </Row>
                            </div>
                          </Col>
                        )}
                      </Row>
                    ) : (
                      <Row>
                        <Col xs={24} sm={8}>
                          <div className='label'>
                            Czynsz{' '}
                            <span className='value'>
                              <Price value={agreement.roomRent} showEmpty />
                            </span>
                          </div>
                          <div className='label'>
                            Opłaty{' '}
                            <span className='value'>
                              <Price value={agreement.roomCharges} showEmpty />
                            </span>
                          </div>
                          <div className='label'>
                            Kaucja{' '}
                            <span className='value'>
                              <Price value={agreement.roomDeposit} showEmpty />
                            </span>
                          </div>
                        </Col>
                        {agreement.agreementPaymentDay && (
                          <Col xs={24} sm={16}>
                            <div className='label'>Dzień płatności</div>
                            <div className='value'>{`Do ${agreement.agreementPaymentDay} dnia każdego miesiąca`}</div>
                          </Col>
                        )}
                        {agreement.attachments.length !== 0 && (
                          <Col xs={24}>
                            <div className='custom-agreements-elem-bottom-attachments'>
                              <h2>Załączniki</h2>
                              <Row>
                                {agreement.attachments.map(
                                  (singleAttachment) => (
                                    <Attachment
                                      attachment={singleAttachment}
                                      agreementId={agreement.agreementId}
                                      investmentId={agreement.investmentId}
                                      premisesId={agreement.premisesId}
                                    />
                                  )
                                )}
                              </Row>
                            </div>
                          </Col>
                        )}
                      </Row>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          {data.map((agreement) => (
            <SingleAgreement agreement={agreement} />
          ))}
          {data.length === 0 && <Empty />}
        </Row>
      )}
    </>
  );
};

export const SettlementsListComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const SettlementsGroupsService = new SettlementsGroups(
      'user/settlement/group'
    );
    SettlementsGroupsService.getList({ size: 3 })
      .then((response) => {
        setData(response.content);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Row gutter={20}>
          {data.map((settlement) => (
            <Col key={settlement.id} sm={24}>
              <StatsElemFullTwoColumns
                type='dashboard-elem-full-black'
                leftTop='Data rozliczenia'
                leftBottom={settlement.createDate}
                middleTop='Suma'
                middleBottom={ReactDOMServer.renderToString(
                  <Price value={settlement.price} showEmpty />
                )}
                href={`settlements/${settlement.userId}/${settlement.id}/show`}
              />
            </Col>
          ))}
          {data.length === 0 && <Empty />}
        </Row>
      )}
    </>
  );
};

export const StatsElemParkingCustom = (props) => {
  const history = useHistory();
  const {
    type = '',
    topHigher,
    topLower,
    bottomHigher,
    redirect,
    parkingGarageCount,
    parkingGarageHallCount,
    parkingOutsideCount,
  } = props;
  let onClickAction = () => {};
  let styles = {};

  if (redirect) {
    onClickAction = () => {
      history.push(redirect);
    };
    styles = {
      cursor: 'pointer',
    };
  }

  return (
    <div
      className={`content-elem  ${type} ${redirect ? 'dashboard-link' : ''}`}
      role='presentation'
      onClick={() => onClickAction()}
      style={styles}
    >
      <div className='content-elem-top'>
        <Row>
          <Col xs={24} sm={12}>
            <div className='content-elem-top-higher'>{topHigher}</div>
            <div className='content-elem-top-lower'>{topLower}</div>
          </Col>
          {bottomHigher && (
            <Col xs={24} sm={12} style={{ alignSelf: 'center' }}>
              <Row gutter={20} className='custom-parking-row-info'>
                <Col style={{ color: '#B9C9DA' }}>
                  <div>Hala garażowa:</div>
                  <div>Garaż:</div>
                  <div>Na zewnątrz:</div>
                </Col>
                <Col style={{ fontWeight: 700, color: '#3B4C60' }}>
                  <div>{parkingGarageHallCount}</div>
                  <div>{parkingGarageCount}</div>
                  <div>{parkingOutsideCount}</div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export const StatsElemLeftRight = (props) => {
  const history = useHistory();
  const {
    type = '',
    topHigherLeft,
    topLowerLeft,
    topHigherRight,
    topLowerRight,
    bottomHigher,
    bottomLower,
    hideWhenEmpty,
    redirect,
    topBackgroundColor,
    topColor,
    bottomLowerColor,
  } = props;
  const showBottom = !!bottomHigher || !!bottomLower;
  const hideClassName = hideWhenEmpty && topHigher === 0 ? 'hide' : '';
  let onClickAction = () => {};
  let styles = {};
  let topStyles = {};
  let bottomLowerStyles = {};

  if (redirect) {
    onClickAction = () => {
      history.push(redirect);
    };
    styles = {
      cursor: 'pointer',
    };
  }

  if (topBackgroundColor) {
    topStyles.backgroundColor = topBackgroundColor;
  }
  if (topColor) {
    topStyles.color = topColor;
  }
  if (bottomLowerColor) {
    bottomLowerStyles = {
      color: bottomLowerColor,
    };
  }

  return (
    <div
      className={`content-elem ${type} ${hideClassName} ${
        redirect ? 'dashboard-link' : ''
      }`}
      role='presentation'
      onClick={() => onClickAction()}
      style={styles}
    >
      <div className='content-elem-top' style={topStyles}>
        <Row>
          <Col xs={12}>
            <div className='content-elem-top-higher'>{topHigherLeft}</div>
            <div className='content-elem-top-lower'>{topLowerLeft}</div>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <div className='content-elem-top-higher'>{topHigherRight}</div>
            <div className='content-elem-top-lower'>{topLowerRight}</div>
          </Col>
        </Row>
      </div>
      {showBottom && (
        <div className='content-elem-bottom'>
          <div className='content-elem-bottom-higher'>{bottomHigher}</div>
          <div className='content-elem-bottom-lower'>
            <Price value={bottomLower} style={bottomLowerStyles} showEmpty />
          </div>
        </div>
      )}
    </div>
  );
};
