import React, { useState, useEffect } from 'react';
import { Row, Col, Form, DatePicker, Tooltip } from 'antd';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { useLocation } from 'react-router-dom';

import StatsS from '../../../services/statsService';
import Spin from '../../../components/Spin';
import Icon from '../../../components/Icon';

const useQuery = () => new URLSearchParams(useLocation().search);

const getBackgroundColor = (date) =>
  moment(date).isBefore(moment())
    ? 'rgba(21,214,94,0.7)'
    : 'rgba(21, 213, 94, 0.1)';

const getBorderWidth = (date) => (moment(date).isBefore(moment()) ? 0 : 2);

const getBorderColor = (date) =>
  moment(date).isBefore(moment())
    ? 'rgba(21,214,94,1)'
    : 'rgba(21, 213, 94, 0.3)';

const getHoverBackgroundColor = (date) =>
  moment(date).isBefore(moment())
    ? 'rgba(21,214,94,1)'
    : 'rgba(21, 213, 94, 0.3)';

const TranchesGrowthChart = ({ investmentType }) => {
  const query = useQuery();
  const id = query.get('id') || null;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [chartConfig, setChartConfig] = useState({});
  const [splitIdx, setSplitIdx] = useState(0);
  const [paidSum, setPaidSum] = useState(0);
  const [toPaySum, setToPaySum] = useState(0);

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItems) => {
          if (tooltipItems[0].index >= splitIdx) {
            return `Przewidywany:\n\n${
              tooltipItems[0].label.charAt(0).toUpperCase() +
              tooltipItems[0].label.slice(1)
            }`;
          }

          return `${
            tooltipItems[0].label.charAt(0).toUpperCase() +
            tooltipItems[0].label.slice(1)
          }`;
        },
        label: (tooltipItems) => {
          if (
            tooltipItems.datasetIndex === 1 &&
            tooltipItems.index < splitIdx
          ) {
            return;
          }

          return `${tooltipItems.value} zł`;
        },
      },
    },
  };

  useEffect(() => {
    const StatsService = new StatsS(id);
    let endpoint = {};
    if (investmentType === 'RENT') {
      endpoint = StatsService.getPayment(id, {
        dateFrom: moment()
          .subtract(1, 'years')
          .startOf('month')
          .format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        investmentType,
      });
    } else {
      endpoint = StatsService.getTranche(id, {
        dateFrom: moment()
          .subtract(1, 'years')
          .startOf('month')
          .format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
        investmentType,
      });
    }

    endpoint.then((response) => {
      const tempResponse = Object.keys(response.paid).map((label) => ({
        date: label,
        value:
          response.paid[label] +
          response.partiallyPaid[label] +
          response.toPay[label],
      }));
      tempResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

      setPaidSum(
        Object.keys(response.paid).reduce(
          (acc, label) => acc + response.paid[label],
          0
        )
      );

      setToPaySum(
        Object.keys(response.toPay).reduce(
          (acc, label) => acc + response.toPay[label],
          0
        )
      );

      let tempSplitIdx = tempResponse.findIndex((item) =>
        moment(item.date).isAfter(moment())
      );
      if (tempSplitIdx < 0) {
        tempSplitIdx = tempResponse.length;
      }
      setSplitIdx(tempSplitIdx);

      const earningsChartConfig = {
        labels: tempResponse.map((label) => moment(label.date).format('MMMM')),
        datasets: [
          {
            backgroundColor: tempResponse.map((label) =>
              getBackgroundColor(label.date)
            ),
            borderWidth: tempResponse.map((label) =>
              getBorderWidth(label.date)
            ),
            borderColor: tempResponse.map((label) =>
              getBorderColor(label.date)
            ),
            hoverBackgroundColor: tempResponse.map((label) =>
              getHoverBackgroundColor(label.date)
            ),
            data: tempResponse.map((label) => label.value),
          },
        ],
      };

      setChartConfig(earningsChartConfig);
      setLoading(false);
    });
  }, [id, investmentType]);

  const handleDates = () => {
    const dateFrom =
      form.getFieldValue('dateFrom') || moment().subtract(1, 'years');
    const dateTo = form.getFieldValue('dateTo');
    const params = {
      investmentType,
    };
    if (dateFrom) {
      params.dateFrom = dateFrom.startOf('month').format('YYYY-MM-DD');
    }
    if (dateTo) {
      params.dateTo = dateTo.endOf('month').format('YYYY-MM-DD');
    } else if (dateFrom) {
      params.dateTo = moment().endOf('month').format('YYYY-MM-DD');
    }

    const StatsService = new StatsS(id);
    let endpoint = {};
    if (investmentType === 'RENT') {
      endpoint = StatsService.getPayment(id, params);
    } else {
      endpoint = StatsService.getTranche(id, params);
    }
    endpoint.then((response) => {
      const tempResponse = Object.keys(response.paid).map((label) => ({
        date: label,
        value:
          response.paid[label] +
          response.partiallyPaid[label] +
          response.toPay[label],
      }));
      tempResponse.sort((a, b) => new Date(a.date) - new Date(b.date));

      setPaidSum(
        Object.keys(response.paid).reduce(
          (acc, label) => acc + response.paid[label],
          0
        )
      );

      setToPaySum(
        Object.keys(response.toPay).reduce(
          (acc, label) => acc + response.toPay[label],
          0
        )
      );

      let tempSplitIdx = tempResponse.findIndex((item) =>
        moment(item.date).isAfter(moment())
      );
      if (tempSplitIdx < 0) {
        tempSplitIdx = tempResponse.length;
      }
      setSplitIdx(tempSplitIdx);

      const earningsChartConfig = {
        labels: tempResponse.map((label) => moment(label.date).format('MMMM')),
        datasets: [
          {
            backgroundColor: tempResponse.map((label) =>
              getBackgroundColor(label.date)
            ),
            borderWidth: tempResponse.map((label) =>
              getBorderWidth(label.date)
            ),
            borderColor: tempResponse.map((label) =>
              getBorderColor(label.date)
            ),
            hoverBackgroundColor: tempResponse.map((label) =>
              getHoverBackgroundColor(label.date)
            ),
            data: tempResponse.map((label) => label.value),
          },
        ],
      };

      setChartConfig(earningsChartConfig);
    });
  };

  return (
    <div className='stats-elem'>
      <div
        className='title'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h2>
          Wpływy{' '}
          <Tooltip
            title='Suma przychodów w danym miesiącu, na podstawie potwierdzonych płatności oraz przewidywanych przychodów na podstawie oczekujących wpływów'
            placement={'right'}
          >
            <Icon name='more-info' style={{ marginLeft: '5px' }} />
          </Tooltip>
        </h2>
        {!loading && (
          <div className='form'>
            <Form
              form={form}
              name='create'
              className='create-form'
              autoComplete='off'
              layout='inline'
            >
              <Row style={{ width: 450 }}>
                <Col xs={12} sm={12}>
                  <Form.Item
                    name='dateFrom'
                    label='Od'
                    required={false}
                    rules={[
                      { required: true, message: 'Pole wymagane' },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (!value) {
                              resolve();
                            }

                            const dateTo = form.getFieldValue('dateTo');

                            if (value.isSameOrAfter(dateTo, 'months')) {
                              reject('Wybierz wcześniejszy');
                            } else {
                              resolve();
                            }
                          });
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      className='investment-stats-datepicker'
                      defaultValue={moment()
                        .subtract(1, 'years')
                        .startOf('month')}
                      size='small'
                      placeholder='Od'
                      picker='month'
                      onChange={() => handleDates()}
                      format={(value) => {
                        const formattedDate = value.format('MMMM YYYY');
                        return (
                          formattedDate.charAt(0).toUpperCase() +
                          formattedDate.slice(1)
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Item
                    name='dateTo'
                    label='Do'
                    required={false}
                    rules={[
                      { required: true, message: 'Pole wymagane' },
                      {
                        validator(rule, value) {
                          return new Promise((resolve, reject) => {
                            if (!value) {
                              resolve();
                            }

                            const dateFrom = form.getFieldValue('dateFrom');

                            if (value.isSameOrBefore(dateFrom, 'months')) {
                              reject('Wybierz późniejszy');
                            } else {
                              resolve();
                            }
                          });
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      className='investment-stats-datepicker'
                      defaultValue={moment().endOf('month')}
                      size='small'
                      placeholder='Do'
                      picker='month'
                      onChange={() => handleDates()}
                      format={(value) => {
                        const formattedDate = value.format('MMMM YYYY');
                        return (
                          formattedDate.charAt(0).toUpperCase() +
                          formattedDate.slice(1)
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
      <div className='content'>
        {loading ? (
          <div className='loading'>
            <Spin />
          </div>
        ) : (
          <Row>
            <Col xs={24}>
              <Bar data={chartConfig} options={options} />
            </Col>
            <Col xs={24} sm={12}>
              <div style={{ textAlign: 'center', marginTop: 40, fontSize: 24 }}>
                {paidSum.toFixed(2)} zł
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 40,
                  fontSize: 24,
                  color: '#B1CADE',
                }}
              >
                {toPaySum.toFixed(2)} zł
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TranchesGrowthChart;
