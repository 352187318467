import React from 'react';
import { Tooltip } from 'antd';
import { Icon, Datagrid } from '../../components';
import clientsService from '../../services/clientsService';

const columns = [
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    visible: true,
    filterEnabled: true,
    key: 'lastName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    visible: true,
    filterEnabled: true,
    key: 'firstName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    key: 'mobile',
    filterEnabled: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`tel:${text}`}>{text}</a>,
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    key: 'email',
    filterEnabled: true,
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`mailto:${text}`}>{text}</a>,
  },
  {
    title: 'Usterki',
    dataIndex: 'faultsCount',
    visible: true,
    key: 'faultsCount',
    sorter: false,
  },
  {
    title: 'Ważność dowodu osobistego',
    dataIndex: ['identity', 'idExpireDate'],
    visible: false,
    key: 'idExpireDate',
    sorter: false,
    exportRender: (text, source) => source.identity.idExpireDate,
  },
];

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const isRodoAccepted = (record) => {
  if (!record.isRodoAccepted === true) {
    return '';
  }
  return (
    <Tooltip title="Klauzula potwierdzona">
      <Icon name="ok-circle" style={{ fontSize: '18px' }} />
    </Tooltip>
  );
};

const List = () => (
  <div>
    <Datagrid
      title="Lista najemców"
      resource="clientsTenants"
      dataProvider={clientsService}
      columns={columns}
      filters={{
        role: 'ROLE_CLIENT_TENANT',
      }}
      sort={{
        sortField: 'lastName',
        sortOrder: 'ascend',
      }}
      showAlert={showAlert}
      customRowActions={[isRodoAccepted]}
    />
  </div>
);

export default List;
