import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import tenantSettlementsService from '../../../services/tenantSettlementsService';
import tenantPremisesService from '../../../services/tenantPremisesService';
import memberTenantAssociationsService from '../../../services/memberTenantAssociationsService';
import Icon from '../../../components/Icon';
import { Spin } from '../../../components';
import { StatsWrapper } from './ViewComponents';
import { Empty } from '../../../components';
import { premiseTypeMap } from '../../../helpers';

const BalanceBox = ({ balance }) => {
  return (
    <div
      className={`balance-box ${
        balance !== 0 ? 'balance-box-red' : 'balance-box-green'
      }`}
      style={{ margin: 0 }}
    >
      <div className='content-wrapper'>
        <div className='value-wrapper'>
          {balance !== 0 && '-'}
          {parseFloat(balance).toFixed(2).replace('.', ',')} zł
        </div>
        <div className='title-wrapper'>Saldo</div>
      </div>
    </div>
  );
};

const PremisesBox = ({ premises, investment }) => {
  if (premises.length === 0) return <Empty />;

  return (
    <Row gutter={[0, 20]}>
      {premises.map((premise) => (
        <Col xs={24}>
          <div
            style={{
              display: 'flex',
              border: '2px solid #B9C9DA',
              borderRadius: 10,
              paddingTop: 20,
              paddingBottom: 20,
              paddingLeft: 30,
              paddingRight: 0,
            }}
          >
            <Row style={{ flex: 1 }}>
              <Col xs={24} lg={16}>
                <Row style={{ fontSize: 24, fontWeight: 700 }}>
                  {premiseTypeMap(premise.type)} {premise.name}
                </Row>
                <Row style={{ fontSize: 12 }}>
                  {premise.investmentName} ul.{' '}
                  {premise.investmentAddress.street}{' '}
                  {premise.investmentAddress.number}
                  {premise.investmentAddress.local
                    ? `/${premise.investmentAddress.local}`
                    : ''}
                  {', '}
                  {premise.investmentAddress.zip}{' '}
                  {premise.investmentAddress.city}
                </Row>
              </Col>
              <Col xs={24} lg={8} style={{ paddingTop: 10 }}>
                <Row style={{ display: 'flex' }}>
                  <Col style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}>
                    Powierzchnia:
                  </Col>
                  <Col
                    xs={12}
                    style={{ flex: 1, fontSize: 12, fontWeight: 700 }}
                  >
                    {premise.totalSurface} m<sup>2</sup>
                  </Col>
                </Row>
                <Row style={{ display: 'flex' }}>
                  <Col style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}>
                    Piętro:
                  </Col>
                  <Col style={{ flex: 1, fontSize: 12, fontWeight: 700 }}>
                    {premise.floor ?? '-'}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div
              style={{
                width: 30,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link
                to={
                  investment.type === 'HOUSING_ASSOCIATION'
                    ? `tenantAssociations/${investment.id}/premises/${premise.id}/show`
                    : `tenantCommunities/${investment.id}/premises/${premise.id}/show`
                }
              >
                <Icon name='arrow-simply-right' style={{ color: '#C0D6E5' }} />
              </Link>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

const CommunityMemberTenantDashboard = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  const [investment, setInvestment] = useState({});
  const [premises, setPremises] = useState([]);

  useEffect(() => {
    let promises = [];
    promises.push(
      tenantSettlementsService.getBalance().then((response) => {
        setBalance(response.balance);
      })
    );

    const choosenInvestmentId = JSON.parse(
      localStorage.getItem('choosenInvestmentId')
    );

    const TenantPremisesService = new tenantPremisesService(
      choosenInvestmentId
    );
    promises.push(
      TenantPremisesService.getList().then((response) => {
        setPremises(response.content);
      })
    );

    const MemberTenantAssociationsService =
      new memberTenantAssociationsService();
    promises.push(
      MemberTenantAssociationsService.getDetails(choosenInvestmentId).then(
        (response) => {
          setInvestment(response);
        }
      )
    );

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  const payTheBalance = () => {
    tenantSettlementsService.payBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/tenantSettlements/null/payment/${response.id}/show`
        );
      }
    });
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={20}>
            <Col xs={24}>
              <StatsWrapper
                title='Saldo'
                icon='payments'
                actions={
                  balance
                    ? [
                        <Button
                          size='small'
                          onClick={() => payTheBalance()}
                          style={{
                            backgroundColor: '#3B4C60',
                            color: '#fff',
                            paddingLeft: 7,
                            paddingRight: 12,
                            paddingTop: 2,
                          }}
                        >
                          <Icon name='payments' style={{ paddingRight: 5 }} />
                          Opłać saldo
                        </Button>,
                      ]
                    : null
                }
              >
                <BalanceBox balance={balance} />
              </StatsWrapper>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24}>
              <StatsWrapper
                title='Lokale'
                icon='apartments'
                actions={[
                  <Link to='tenantPremises'>
                    <Button size='small'>Pokaż</Button>
                  </Link>,
                ]}
              >
                <PremisesBox premises={premises} investment={investment} />
              </StatsWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CommunityMemberTenantDashboard;
