/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import memberInvestmentPremisesService from '../../../../services/memberInvestmentPremisesService';

export const TenantsBox = ({ investmentId, premisesId }) => {
  const [tenants, setTenants] = useState([]);

  useEffect(() => {
    const MemberInvestmentPremisesService = new memberInvestmentPremisesService(
      investmentId
    );

    MemberInvestmentPremisesService.getTenants(investmentId, premisesId).then(
      (data) => {
        const activeRents = data.filter((rent) => {
          if (rent.withoutExpireDate) {
            return true;
          }
          if (new Date(rent.dateTo) >= new Date()) {
            return true;
          }
          return false;
        });

        const uniqueUserIds = activeRents.reduce((acc, curr) => {
          if (!acc.find((item) => item.userId === curr.userId)) {
            acc.push(curr);
          }

          return acc;
        }, []);

        const tempData = uniqueUserIds.map((tenant) => ({
          firstName: tenant.firstName,
          lastName: tenant.lastName,
          mobile: tenant.mobile,
          email: tenant.email,
        }));

        setTenants(tempData);
      }
    );
  }, [investmentId, premisesId]);

  if (!tenants || tenants.length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='rental-managers' />
        Najemcy
      </h2>
      <div className='content'>
        {tenants.map((tenant) => (
          <Row gutter={20} style={{ marginBottom: 20 }}>
            <Col xs={24}>
              <div className='owner-box-content'>
                <div className='item'>
                  {tenant.firstName} {tenant.lastName}
                </div>
                <div className='item'>
                  <a
                    className='datagrid-link'
                    href={`tel:${tenant.mobile}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {tenant.mobile}
                  </a>
                </div>
                <div className='item'>
                  <a
                    className='datagrid-link'
                    href={`mailto:${tenant.email}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {tenant.email}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};
