import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { isIOS, isMobile } from 'react-device-detect';
import Icon from '../../components/Icon';
import packagesService from '../../services/packagesService';

const CommunityAdminPackage = ({ userData }) => {
  const [packageData, setPackageData] = useState({});
  const [premisesCount, setPremisesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (userData.packageId) {
      packagesService.getOne(userData.packageId).then((response) => {
        setPackageData(response);

        packagesService.getPremisesCommunityCount().then((response) => {
          if (response?.premisesCount) {
            setPremisesCount(response?.premisesCount);
          }
        });

        setLoading(false);
      });
    }

    if (userData.packageExpireDate) {
      const given = moment(userData.packageExpireDate, 'YYYY-MM-DD');
      const current = moment().startOf('day');

      const difference = moment.duration(given.diff(current)).asDays();
      if (difference <= 7) {
        setShowAlert(true);
      }
    }
  }, [userData.packageId, userData.packageExpireDate, location]);

  return (
    <>
      {!loading && (
        <CommunityAdminPackageButton
          packageData={packageData}
          showAlert={showAlert}
          premisesCount={premisesCount}
        />
      )}
    </>
  );
};

const CommunityAdminPackageButton = ({
  packageData,
  showAlert,
  premisesCount,
}) => (
  <Link to={showAlert ? '/myAccount' : '/myAccount/edit?package'}>
    <Button className='button-secondary header-package-button'>
      {showAlert && (
        <span
          className='header-package-title'
          style={{ color: '#EC434C', marginRight: '5px' }}
        >
          {isIOS && isMobile ? 'Mój plan' : 'Opłać plan:'}
        </span>
      )}
      <span className='header-package-title' style={{ fontWeight: 400 }}>
        Lokale:
      </span>
      <span className='header-package-button-actual'>{premisesCount}</span>
      <span className='header-package-button-limit'>
        {'/'}
        {packageData.limits.premises}
      </span>
      <Icon name='arrow-simply-right' />
    </Button>
  </Link>
);
export default CommunityAdminPackage;
