import BaseService from './baseService';
import httpService from '../helpers/httpService';

class PermisesService extends BaseService {
  constructor(investmentId, userId = null) {
    super();
    const roles = localStorage.getItem('roles');

    if (userId) {
      this.url = `/api/vendor/user/${userId}/premises`;

      if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
        this.url = `/api/vendor/user/${userId}/investment/${investmentId}/premises/community`;
      }
    } else if (investmentId) {
      this.url = `/api/vendor/investment/${investmentId}/premises`;

      if (
        roles.includes('ROLE_CLIENT_TENANT') ||
        roles.includes('ROLE_CLIENT')
      ) {
        this.url = '/api/user/premises';
      }
    } else {
      this.url = '/api/vendor/premises';

      if (
        roles.includes('ROLE_CLIENT_TENANT') ||
        roles.includes('ROLE_CLIENT')
      ) {
        this.url = '/api/user/premises';
      }
    }
  }

  async getAssignedUsers(premiseId) {
    return httpService.get(`${this.url}/${premiseId}/users`, null, true);
  }

  async getBalance(premiseId) {
    return httpService.get(`${this.url}/${premiseId}/balance`, null, true);
  }
}

export default PermisesService;
