import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Dropdown } from 'antd';
import { isIOS, isMobile } from 'react-device-detect';

import Icon from './Icon';

const PackageLimitRenderer = ({ value }) => {
  if (value === -1) {
    return '∞';
  } else if (value === 0) {
    return `${value}`;
  } else {
    return `max ${value}`;
  }
};

const Subscription = ({
  packageData,
  packageExpireDate,
  editUrl,
  onEditUrlClick,
  defaultPane,
  menu,
}) => {
  return (
    <div className='create-form-wrapper'>
      <div
        className='account-subscription'
        style={{ backgroundColor: '#262D37' }}
      >
        <div className='account-subscription-header'>
          <Row>
            <Col xs={24} sm={12} className='account-subscription-header-name'>
              Subskrypcja
            </Col>
            {!(isIOS && isMobile) && (
              <Col xs={24} sm={12} className='account-subscription-header-price'>
                <div className='account-subscription-header-price-value'>
                  {`${packageData?.price} zł`}
                </div>
                <div className='account-subscription-header-price-addnotation'>
                  Kwota netto + 23% VAT
                </div>
                <div className='account-subscription-header-price-paydate'>
                  Następna płatność: {packageExpireDate}
                </div>
              </Col>
            )}
          </Row>
        </div>

        <div className='account-subscription-details'>
          <Row>
            <Col xs={24} sm={12}>
              <div className='account-subscription-details-limits'>
                <div className='account-subscription-details-limits-single-investments'>
                  <Row>
                    <Col
                      className='account-subscription-details-limits-single-investments-label'
                      xs={16}
                    >
                      Ilość lokali mieszkalnych
                    </Col>
                    <Col
                      className='account-subscription-details-limits-single-investments-value'
                      xs={8}
                    >
                      <PackageLimitRenderer
                        value={packageData?.limits?.premises}
                      />
                    </Col>
                  </Row>
                </div>

                <>
                  <div className='account-subscription-details-limits-single-investments'>
                    <Row>
                      <Col
                        className='account-subscription-details-limits-single-label'
                        xs={16}
                      >
                        Lokale usługowe
                      </Col>
                      <Col
                        className='account-subscription-details-limits-single-value'
                        xs={8}
                      >
                        <PackageLimitRenderer
                          value={packageData?.limits?.service}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className='account-subscription-details-limits-single'>
                    <Row>
                      <Col
                        className='account-subscription-details-limits-single-label'
                        xs={16}
                      >
                        Miejsca postojowe
                      </Col>
                      <Col
                        className='account-subscription-details-limits-single-value'
                        xs={8}
                      >
                        <PackageLimitRenderer
                          value={packageData?.limits?.parking}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div className='account-subscription-details-limits-single'>
                    <Row>
                      <Col
                        className='account-subscription-details-limits-single-label'
                        xs={16}
                      >
                        Komórki lokatorskie
                      </Col>
                      <Col
                        className='account-subscription-details-limits-single-value'
                        xs={8}
                      >
                        <PackageLimitRenderer
                          value={packageData?.limits?.storage}
                        />
                      </Col>
                    </Row>
                  </div>
                </>
              </div>
            </Col>
          </Row>
          <Row align={'space-between'}>
            <Col style={{ marginRight: 10 }}>
              <div>
                <div className='account-subscription-details-limits-addnotation-wrapper'>
                  <div className='account-subscription-details-limits-addnotation-details'>
                    * Ilość lokali mieszkalnych we wszystkich inwestycjach
                  </div>
                  {!(isIOS && isMobile) && (
                    <div className='account-subscription-details-limits-addnotation-details'>
                      * Podane kwoty są kwotami netto + 23% VAT.
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {!(isIOS && isMobile) && (
              <Col className='subscription-actions-col'>
                <div className='account-subscription-details-actions'>
                  <Link
                    to={{
                      pathname: editUrl,
                      state: { defaultPane: defaultPane },
                    }}
                  >
                    <Button className='button-secondary' onClick={onEditUrlClick}>
                      Zmień plan
                    </Button>
                  </Link>
                  {menu && (
                    <Dropdown overlay={menu} placement='bottomCenter' arrow>
                      <Button type='danger' style={{ marginLeft: 10 }}>
                        Opłać
                        <Icon name='payments' style={{ marginLeft: 5 }} />
                      </Button>
                    </Dropdown>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
