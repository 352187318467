import BaseService from './baseService';

class DocumentsService extends BaseService {
  constructor(investmentId) {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles?.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/investments/${investmentId}/documents`;
    } else if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/investments/${investmentId}/documents`;
    }
  }
}

export default DocumentsService;
