import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import FaultsServiceS from '../../../services/faultsService';
import workersService from '../../../services/workersService';
import userService from '../../../services/userService';
import { Attachments } from './editComponents/Attachments';
import { Gallery } from './editComponents/Gallery';
import { Tags } from './editComponents/Tags';

import {
  Tabs, Input, Icon, deleteConfirm,
} from '../../../components';

const {
  DatePicker, Select, TextArea, SearchBox, PriceInput,
} = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit = () => {
  const { faultGroupId, faultId, investmentId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [fault, setFault] = useState({});
  const [disableWorkers, setDisableWorkers] = useState(false);
  const [showDeveloperNote, setShowDeveloperNote] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      const { roles, workerPermissions } = data;
      if (roles.includes('ROLE_DEVELOPER')) {
        setShowDeveloperNote(true);
      }
      if (!roles.includes('ROLE_WORKER')) {
        return;
      }

      setDisableWorkers(!workerPermissions.includes('WORKERS_FULL'));
    });

    const FaultsService = new FaultsServiceS(investmentId, faultGroupId);
    FaultsService.getOne(faultId).then((faultData) => {
      const tempData = {
        ...faultData,
        responsibleWorkers: faultData.responsibleWorkers ? faultData.responsibleWorkers : [],
        addDate: faultData.addDate ? moment(faultData.addDate) : null,
        fixDate: faultData.fixDate ? moment(faultData.fixDate) : null,
      };
      form.setFieldsValue(tempData);
      setFault(faultData);
    });
  }, [faultGroupId, faultId, investmentId, form]);

  const onFinish = (values) => {
    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        addDate: values.addDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.fixDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        fixDate: values.fixDate.format('YYYY-MM-DD'),
      };
    }
    if (!values?.responsibleWorker) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        responsibleWorker: null,
      };
    }
    const FaultsService = new FaultsServiceS(investmentId, faultGroupId);
    FaultsService.update(faultId, values).then((response) => {
      if (response.id) {
        history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const FaultsService = new FaultsServiceS(investmentId, faultGroupId);
    FaultsService.delete(faultId).then(() => history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/show`)).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const HeaderTitle = () => {
    if (!fault.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{fault.name}</span>
      </span>
    );
  };

  const handleBack = () => {
    const { location: { state } } = history;

    switch (state?.redirectFrom) {
      case 'FaultGroupEdit':
        return history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/edit`);
      case 'FaultGroupList':
        return history.push('/faultGroup');
      case 'ClientFaultGroupList':
        return history.push(`/clients/${state.userId}/faultGroup`);
      default:
        return history.push(`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${fault.id}/show`);
    }
  };

  const getUserOptionLabel = (e) => {
    const pesel = e.identity && e.identity.pesel ? `(${e.identity.pesel})` : '';
    return `${e.firstName} ${e.lastName} ${pesel}`;
  };

  const isClient = userData?.roles?.includes('ROLE_CLIENT') || userData?.roles?.includes('ROLE_CLIENT_TENANT') || false;
  const isDeveloper = userData?.roles?.includes('ROLE_DEVELOPER') || false;

  return (
    <div className="form-create faults-edit">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => handleBack()}
        title={<HeaderTitle />}
        extra={
          isDeveloper && [
            <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
              <Icon name="delete" style={{ marginRight: '5px' }} />
              {' '}
              Usuń
            </Button>,
          ]
        }
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Informacje" key="form">
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="form-section">
                          <h2>Podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Nazwa" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="addDate"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <DatePicker size="large" placeholder="Data wystąpienia usterki" style={{ width: '100%' }} />
                              </Form.Item>
                            </Col>
                            {!isClient && (
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="fixDate"
                              >
                                <DatePicker size="large" placeholder="Usunięto" style={{ width: '100%' }} />
                              </Form.Item>
                            </Col>
                            )}
                            {!isClient && (
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="amount"
                              >
                                <PriceInput size="large" placeholder="Koszty usunięcia usterki" />
                              </Form.Item>
                            </Col>
                            )}
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Szczegóły</h2>
                          <Row gutter={20}>
                            {!isClient && (
                            <Col xs={24}>
                              <Form.Item
                                name="responsibleWorkers"
                              >
                                <SearchBox
                                  placeholder="Osoba odpowiedzialna"
                                  size="large"
                                  resource={workersService}
                                  filters={{ role: 'ROLE_WORKER' }}
                                  resourceField="firstOrLastName"
                                  disabled={disableWorkers}
                                  optionValue={(e) => getUserOptionLabel(e)}
                                  mode="multiple"
                                />
                              </Form.Item>
                            </Col>
                            )}
                            <Col xs={24}>
                              <Form.Item
                                name="description"
                              >
                                <TextArea rows={4} placeholder="Opis" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        {showDeveloperNote && (
                          <div className="form-section">
                            <h2>Notatka od dewelopera</h2>
                            <Row gutter={20}>
                              <Col xs={24}>
                                <Form.Item
                                  name="developerNote"
                                >
                                  <TextArea rows={4} placeholder="Notatka od dewelopera" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Galeria" key="gallery">
                  <Gallery
                    investmentId={investmentId}
                    faultId={faultId}
                    faultGroupId={faultGroupId}
                  />
                </TabPane>
                <TabPane tab="Załączniki" key="attachments">
                  <Attachments
                    investmentId={investmentId}
                    faultId={faultId}
                    faultGroupId={faultGroupId}
                  />
                </TabPane>
                {!isClient && (
                  <TabPane tab="Tagi" key="tags">
                    <Tags
                      investmentId={investmentId}
                      faultId={faultId}
                      faultGroupId={faultGroupId}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleBack}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Edit;
