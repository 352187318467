import React, { useState } from 'react';
import {
  Button, Modal, Row, Col,
} from 'antd';
import { useHistory } from 'react-router-dom';

import userService from '../../services/userService';
import authService from '../../services/authService';

const RodoPopup = ({ rodoContent, userData }) => {
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(true);
  const onReset = () => {
    authService.logout();
    history.push('/login');
    setModalVisible(false);
  };

  const onFinish = () => {
    userService.update({ ...userData, isRodoAccepted: true }).then(() => {
      setModalVisible(false);
    });
  };

  return (
    <Modal
      centered
      visible={modalVisible}
      width={500}
      closable={false}
      footer={(
        <Row gutter={20} className="datagrid-filter-modal-button-wrapper">
          <Col xs={12} sm={12} style={{ textAlign: 'left' }}>
            <Button className="button-secondary" size="large" onClick={() => onReset()}>
              Odrzuć i wyloguj
            </Button>
          </Col>
          <Col xs={12} sm={12}>
            <Button type="primary" size="large" style={{ fontWeight: 'bold' }} onClick={() => onFinish()}>
              Akceptuje
            </Button>
          </Col>
        </Row>
        )}
    >
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>Klauzula informacyjna RODO</h2>
      <div style={{ textAlign: 'center' }}>{rodoContent.content}</div>
    </Modal>
  );
};

export default RodoPopup;
