import React from 'react';
import { PageHeader, Card } from 'antd';
import Icon from '../../components/Icon';
import { ImportsList } from './components/ImportsList';

const Import = () => {
  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title={'Importuj członków'}
      >
        <Card>
          <ImportsList />
        </Card>
      </PageHeader>
    </div>
  );
};
export default Import;
