import BaseService from './baseService';
import httpService from '../helpers/httpService';

class UserSettlementsService extends BaseService {
  constructor(userId) {
    super();
    if (userId) {
      this.url = `/api/vendor/user/${userId}/settlement`;
    } else {
      this.url = '/api/user/settlement';
    }
  }

  async getBalance() {
    return httpService.get(`${this.url}/balance`, null, true);
  }
}

export default UserSettlementsService;
