import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import Icon from '../../components/Icon';
import housingCommunitiesService from '../../services/housingCommunitiesService';
import userService from '../../services/userService';
import investmentHeatSourceMap from '../../helpers/investmentHeatSourceMap';
import Spin from '../../components/Spin';
import workersService from '../../services/workersService';
import { ContactsInvestmentBox } from './showComponents/ContactsInvestmentBox';
import { WorkersInvestmentBox } from '../housingAssociations/showComponents/WorkersInvestmentBox';

const Address = ({ title, address }) => (
  <>
    {!!address && (
      <div className='address'>
        <div className='title'>{title}:</div>
        <div className='place'>
          {`ul. ${address.street} ${address.number || ''}${
            address.local ? `/${address.local}` : ','
          }`}
        </div>
        <div className='city'>{`${address.zip} ${address.city}, ${address.country}`}</div>
      </div>
    )}
  </>
);

const Show = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [developer, setDeveloper] = useState(false);
  const [communityAdmin, setCommunityAdmin] = useState(false);
  const [premisesStats, setPremisesStats] = useState();
  const [premisesSizes, setPremisesSizes] = useState();
  const [userData, setUserData] = useState({});
  const [total, setTotal] = useState(false);
  const [totalSize, setTotalSize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const HousingCommunitiesService = new housingCommunitiesService();
    HousingCommunitiesService.getDetails(id).then((data) => {
      setInvestment(data.investment);
      setPremisesStats(data.premisesCount);
      setPremisesSizes(data.premisesSizes);
      if (Object.keys(data.premisesCount).length > 0) {
        const calculatedTotal =
          Object.values(data.premisesCount).reduce((a, b) => a + b) || 0;
        setTotal(calculatedTotal);
      }
      if (Object.keys(data.premisesSizes).length > 0) {
        const calculatedTotal =
          Object.values(data.premisesSizes).reduce((a, b) => a + b) || 0;
        setTotalSize(calculatedTotal);
      }
    });

    userService.getInfo().then((response) => {
      setUserData(response);
      if (response.roles.includes('ROLE_COMMUNITY_ADMIN')) {
        setCanEdit(true);
      }

      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (investment.developer) {
      setDeveloper(investment.developer);
    } else if (investment.developerId) {
      workersService.getOne(investment.developerId).then((data) => {
        setDeveloper(data);
      });
    }

    if (investment.communityAdminId) {
      workersService.getOne(investment.communityAdminId).then((data) => {
        setCommunityAdmin(data);
      });
    }
  }, [investment]);

  const canShowMenu = (requiredPermission) => {
    if (userData.roles.includes('ROLE_WORKER')) {
      return userData.workerPermissions.includes(requiredPermission);
    }

    return true;
  };

  return (
    <div className='show-layout investments-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          // onBack={() => history.push('/housingCommunities')}
          onBack={() => history.goBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title={investment.name}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            canEdit && (
              <Button
                key='2'
                type='primary'
                onClick={() =>
                  history.replace(`/housingCommunities/${investment.id}/edit`)
                }
              >
                Edytuj <Icon name='edit' />
              </Button>
            ),
          ]}
        >
          <Card className='investment-show-card'>
            <Row justify={'center'} gutter={[20, 32]}>
              <Col sm={24} xl={12} style={{ width: '90%' }}>
                <div className='left investments-show-left'>
                  <div className='logo'>
                    <img
                      src={`${baseUrl}${investment.logoFile}`}
                      alt={investment.name}
                      style={{ borderRadius: '50%' }}
                    />
                  </div>
                  <div className='investment-type-name'>Wspólnota</div>
                  <div className='name'>{investment.name}</div>
                  <div className='buttons'>
                    {communityAdmin && (
                      <div className='call'>
                        <a href={`tel:${communityAdmin.mobile}`}>
                          <Button type='primary' size='large'>
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>
                    )}
                    {communityAdmin && (
                      <div className='write'>
                        <a href={`mailto:${communityAdmin.email}`}>
                          <Button type='primary' size='large'>
                            <Icon name='email' /> Napisz
                          </Button>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={24} xl={12}>
                <div className='right investments-show-right'>
                  <Card
                    title={
                      <h2 className='show-layout-card-header'>
                        <Icon name='more-info' />
                        Informacje
                      </h2>
                    }
                    className='informations'
                  >
                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Nazwa:</div>
                        <div className='idNumber'>{investment.name}</div>
                      </Col>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Data powstania:</div>
                        <div className='idNumber'>{investment.createDate}</div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Powierzchnia całkowita:</div>
                        <div className='idNumber'>
                          {investment.size} m<sup>2</sup>
                        </div>
                      </Col>
                      {investment.heatSource && (
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Źródło ciepła:</div>
                          <div>
                            {investmentHeatSourceMap(investment.heatSource)}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <Address
                          title='Adres inwestycji'
                          address={investment.address}
                        />
                      </Col>
                      <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                        <div className='title'>Dane do rozliczeń:</div>
                        <div className='idNumber'>
                          NIP: {investment.nip ?? '-'}
                        </div>
                        <div className='idNumber'>
                          Regon: {investment.regon ?? '-'}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
                {total && (
                  <div className='right investments-show-right'>
                    <Card
                      title={
                        <h2 className='show-layout-card-header'>
                          <Icon name='flat-changes' />
                          Powierzchnia
                        </h2>
                      }
                      className='informations'
                    >
                      <Row>
                        <Col xs={24} sm={16} className='title'>
                          Całkowita:
                        </Col>
                        <Col xs={24} sm={8}>
                          {(investment.size || 0).toFixed(2)} m<sup>2</sup>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          color:
                            (
                              (investment?.garageHalls?.reduce(
                                (acc, garageHall) =>
                                  acc + garageHall.garageHallSize,
                                0
                              ) || 0) +
                              (premisesSizes.RENT || 0) +
                              (premisesSizes.SERVICE || 0)
                            ).toFixed(2) === investment.size?.toFixed(2)
                              ? '#15D55E'
                              : '#EC434C',
                        }}
                      >
                        <Col xs={24} sm={16}>
                          Suma dodanych lokali (w tym hala garażowa):
                        </Col>
                        <Col xs={24} sm={8}>
                          {(
                            (investment?.garageHalls?.reduce(
                              (acc, garageHall) =>
                                acc + garageHall.garageHallSize,
                              0
                            ) || 0) +
                            (premisesSizes.RENT || 0) +
                            (premisesSizes.SERVICE || 0)
                          ).toFixed(2)}{' '}
                          m<sup>2</sup>
                        </Col>
                      </Row>
                      <Card
                        className='total-surface-card'
                        style={{ marginTop: 30 }}
                      >
                        <Row className='total-surface-overview-card' sm={24}>
                          <Col xs={0} sm={12} />
                          <Col xs={0} sm={4} className='title'>
                            Ilość
                          </Col>
                          <Col xs={0} sm={8} className='title'>
                            Powierzchnia
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: 15 }}>
                          <Col xs={24} sm={12} className='title'>
                            Ilość wszystkich lokali:
                          </Col>
                          <Col xs={24} sm={4}>
                            {total}
                          </Col>
                          <Col xs={24} sm={8}>
                            {(totalSize || 0).toFixed(2)} m<sup>2</sup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={12} className='title'>
                            Mieszkania:
                          </Col>
                          <Col xs={24} sm={4}>
                            {premisesStats.RENT || 0}
                          </Col>
                          <Col xs={24} sm={8}>
                            {(premisesSizes.RENT || 0).toFixed(2)} m<sup>2</sup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={12} className='title'>
                            Lokale usługowe:
                          </Col>
                          <Col xs={24} sm={4}>
                            {premisesStats.SERVICE || 0}
                          </Col>
                          <Col xs={24} sm={8}>
                            {(premisesSizes.SERVICE || 0).toFixed(2)} m
                            <sup>2</sup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={12} className='title'>
                            Komórki lokatorskie:
                          </Col>
                          <Col xs={24} sm={4}>
                            {premisesStats.STORAGE || 0}
                          </Col>
                          <Col xs={24} sm={8}>
                            {(premisesSizes.STORAGE || 0).toFixed(2)} m
                            <sup>2</sup>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={12} className='title'>
                            Miejsca postojowe:
                          </Col>
                          <Col xs={24} sm={4}>
                            {premisesStats.PARKING || 0}
                          </Col>
                          <Col xs={24} sm={8}>
                            {(premisesSizes.PARKING || 0).toFixed(2)} m
                            <sup>2</sup>
                          </Col>
                        </Row>
                      </Card>

                      {investment?.garageHalls?.length ? (
                        <>
                          <div style={{ marginTop: 40, fontWeight: 700 }}>
                            Hala garażowa
                          </div>
                          {investment.garageHalls.map((garageHall) => (
                            <Card className='total-surface-card'>
                              <Row>
                                <Col xs={24} style={{ fontWeight: 700 }}>
                                  {garageHall.name}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={16} className='title'>
                                  Hala garażowa:
                                </Col>
                                <Col xs={24} sm={8}>
                                  {(garageHall.garageHallSize || 0).toFixed(2)}{' '}
                                  m<sup>2</sup>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={24} sm={16} className='title'>
                                  Komórki i miejsca postojowe w hali:
                                </Col>
                                <Col xs={24} sm={8}>
                                  {(
                                    garageHall.parkingAndStorageSizeSum || 0
                                  ).toFixed(2)}{' '}
                                  m<sup>2</sup>
                                </Col>
                              </Row>
                            </Card>
                          ))}
                        </>
                      ) : null}
                    </Card>
                  </div>
                )}
                {communityAdmin && (
                  <div className='right investments-show-right'>
                    <Card
                      title={
                        <h2 className='show-layout-card-header'>
                          <Icon name='administrator-nieruchomosci' />
                          Administrator nieruchomości
                        </h2>
                      }
                      className='informations'
                    >
                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Dane firmowe:</div>
                          <div className='idNumber'>{communityAdmin.name}</div>
                          <div className='idNumber'>
                            NIP: {communityAdmin.nip}
                          </div>
                        </Col>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Dane kontaktowe:</div>
                          <div className='idNumber'>
                            <a
                              className='show-link'
                              href={`mailto:${communityAdmin.email}`}
                            >
                              {communityAdmin.email}
                            </a>
                          </div>
                          <div className='idNumber'>
                            <a
                              className='show-link'
                              href={`tel:${communityAdmin.mobile}`}
                            >
                              {communityAdmin.mobile}
                            </a>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <Address
                            title='Adres siedziby'
                            address={communityAdmin.address}
                          />
                        </Col>
                        {communityAdmin.correspondenceAddress && (
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <Address
                              title='Adres korespondencyjny'
                              address={communityAdmin.correspondenceAddress}
                            />
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </div>
                )}
                {developer && (
                  <div className='right investments-show-right'>
                    <Card
                      title={
                        <h2 className='show-layout-card-header'>
                          <Icon name='developer' />
                          Deweloper
                        </h2>
                      }
                      className='informations'
                    >
                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Dane firmowe:</div>
                          <div className='idNumber'>{developer.name}</div>
                          <div className='idNumber'>NIP: {developer.nip}</div>
                        </Col>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <div className='title'>Dane kontaktowe:</div>
                          <div className='idNumber'>
                            <a
                              className='show-link'
                              href={`mailto:${developer.email}`}
                            >
                              {developer.email}
                            </a>
                          </div>
                          {developer.mobile && (
                            <div className='idNumber'>
                              <a
                                className='show-link'
                                href={`tel:${developer.mobile}`}
                              >
                                {developer.mobile}
                              </a>
                            </div>
                          )}
                          {developer.phone && (
                            <div className='idNumber'>
                              <a
                                className='show-link'
                                href={`tel:${developer.phone}`}
                              >
                                {developer.phone}
                              </a>
                            </div>
                          )}
                          {developer.website && (
                            <div className='idNumber'>{developer.website}</div>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                          <Address
                            title='Adres siedziby'
                            address={developer.address}
                          />
                        </Col>
                        {developer.correspondenceAddress && (
                          <Col xs={24} sm={12} style={{ marginBottom: '20px' }}>
                            <Address
                              title='Adres korespondencyjny'
                              address={developer.correspondenceAddress}
                            />
                          </Col>
                        )}
                      </Row>
                    </Card>
                  </div>
                )}

                <WorkersInvestmentBox investment={investment} />
                <ContactsInvestmentBox investmentId={id} />
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
