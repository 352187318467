import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Tooltip, Button, PageHeader } from 'antd';
import Icon from '../../components/Icon';
import Datagrid from '../../components/Datagrid';
import Spin from '../../components/Spin';
import resolutionsService from '../../services/resolutionsService';
import resolutionsDetailsService from '../../services/resolutionsDetailsService';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import permisesService from '../../services/premisesService';

const Details = () => {
  const { investmentId, resolutionId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [resolution, setResolution] = useState({});
  const [investmentPremises, setInvestmentPremises] = useState([]);

  useEffect(() => {
    const promises = [];

    const ResolutionsService = new resolutionsService(investmentId);
    promises.push(
      ResolutionsService.getOne(resolutionId).then((data) => {
        setResolution(data);
      })
    );

    const PremisesService = new permisesService(investmentId);
    promises.push(
      PremisesService.getList().then((response) => {
        setInvestmentPremises(response.content);
      })
    );

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: 'Członek',
      dataIndex: 'member',
      visible: true,
      filterEnabled: false,
      key: 'member',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <Link
            className='datagrid-link'
            to={`/investment/${investmentId}/members/${record?.userData?.id}/show`}
          >
            {[record?.userData?.firstName, record?.userData?.lastName]
              .filter((it) => !!it)
              .join(' ')}
          </Link>
        );
      },
    },
    {
      title: 'Nazwisko',
      dataIndex: 'lastName',
      visible: false,
      key: 'lastName',
      filterEnabled: true,
      export: false,
    },
    {
      title: 'Imię',
      dataIndex: 'firstName',
      visible: false,
      key: 'firstName',
      filterEnabled: true,
      export: false,
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      visible: true,
      key: 'mobile',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <a className='datagrid-link' href={`tel:${record?.userData?.mobile}`}>
          {record?.userData?.mobile}
        </a>
      ),
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      visible: true,
      key: 'email',
      sorter: true,
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <a className='datagrid-link' href={`mailto:${record?.userData?.email}`}>
          {record?.userData?.email}
        </a>
      ),
    },
    {
      title: 'Lokal',
      dataIndex: 'premises',
      visible: true,
      key: 'premisesIds',
      sorter: true,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: investmentPremises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      render: (text, record) => {
        if (record?.premises?.length !== 0) {
          const premises = record.premises.map(
            (premise) => `${premiseTypeMap(premise.type)} ${premise.name}`
          );

          const output = premises.join(', ');

          if (output.length > 35) {
            return (
              <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>
            );
          } else {
            return output;
          }
        }

        return '';
      },
    },
    {
      title: 'Udział',
      dataIndex: 'value',
      visible: true,
      key: 'value',
      sorter: true,
      filterEnabled: false,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: 'Głos',
      dataIndex: 'voteType',
      visible: true,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        { value: 'PRO', label: 'ZA' },
        { value: 'CON', label: 'PRZECIW' },
        { value: 'ABSTAIN', label: 'WSTRZYMUJĘ SIĘ' },
      ],
      key: 'voteType',
      sorter: true,
      export: true,
      render: (text, record) => {
        if (record?.voteType === 'PRO') {
          return <span style={{ color: '#15D55E', fontWeight: 700 }}>ZA</span>;
        } else if (record?.voteType === 'CON') {
          return (
            <span style={{ color: '#EC434C', fontWeight: 700 }}>PRZECIW</span>
          );
        } else if (record?.voteType === 'ABSTAIN') {
          return (
            <span style={{ color: '#8FB3D1', fontWeight: 700 }}>
              WSTRZYMUJĘ SIĘ
            </span>
          );
        }
      },
    },
  ];

  const HeaderTitle = () => {
    if (!resolution.name) {
      return <span>Szczegóły głosowania</span>;
    }
    return (
      <span>
        Szczegóły głosowania:{' '}
        <span className='header-subtitle'>{resolution.name}</span>
      </span>
    );
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div className='resolution-details-datagrid-wrapper'>
          <Datagrid
            title={
              <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                backIcon={<Icon name='arrow-simply-left' />}
                onBack={() => window.history.back()}
                title={<HeaderTitle />}
              />
            }
            customCreate={
              <div>
                <Button
                  key='1'
                  className='button-secondary import-button'
                  onClick={() => window.print()}
                  style={{ marginRight: 0, minWidth: 0, maxWidth: 48 }}
                >
                  <Icon name='print' style={{ paddingLeft: 0 }} />
                </Button>
              </div>
            }
            customBaseUrlKey={
              userData?.roles?.includes('ROLE_COMMUNITY_ADMIN')
                ? 'resolutions'
                : 'memberResolutions'
            }
            dataProvider={
              new resolutionsDetailsService(investmentId, resolutionId)
            }
            columns={columns}
            filters={{}}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            hideCreate
            hideReset
            hideSms
            hideEmail
            hideDelete
            hideEdit
            hideShow
            disableSelection
          />
        </div>
      )}
    </div>
  );
};

export default Details;
