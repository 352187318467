export const roleNameMap = {
  ROLE_COMMUNITY_ADMIN: 'Administrator nieruchomości',
  ROLE_COMMUNITY_MEMBER: 'Członek',
  ROLE_COMMUNITY_MEMBER_TENANT: 'Najemca',
  ROLE_DEVELOPER: 'Deweloper',
  ROLE_TENANT: 'Zarządca najmem',
  ROLE_CLIENT_TENANT: 'Najemca',
  ROLE_WORKER: 'Pracownik',
  ROLE_ADMIN: 'Admin',
};
