/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import attachmentsService from '../../../../services/attachmentsService';
import Icon from '../../../../components/Icon';
import Empty from '../../../../components/Empty';

export const AttachmentsBox = () => {
  const { id, premisesId } = useParams();
  const [attachments, setAttachments] = useState([]);
  useEffect(() => {
    const AttachmentsService = new attachmentsService(id, premisesId);
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [id, premisesId]);
  if (!attachments.length) {
    return '';
  }
  const handleDownload = (fileId) => {
    const AttachmentsService = new attachmentsService(id, premisesId);
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };
  const temp = attachments.map((attachment) => (
    <Col xs={24} sm={12} key={attachment.id}>
      <div className="attachment">
        <Row>
          <Col xs={2} className="attachment-icon">
            <Icon name="file" />
          </Col>
          <Col xs={16} className="attachment-name">
            {attachment.filename}
          </Col>
          <Col xs={4} offset={2} className="attachment-actions">
            <Button ghost className="button-no-border" onClick={() => handleDownload(attachment.id)}>
              <Icon name="download" />
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  ));
  if (temp.length === 0) {
    temp.push(
      <Col xs={24} sm={12} key={0}>
        <Empty />
      </Col>,
    );
  }
  return (
    <div className="premises-show-box attachments-box">
      <h2>
        <Icon name="folder" />
        Załączniki
      </h2>
      <div className="content">
        <Row gutter={20}>
          {temp}
        </Row>
      </div>
    </div>
  );
};
