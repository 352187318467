import React, { useState } from 'react';
import {
  Form, Row, Col,
} from 'antd';

import Input from '../../../../components/InputComponent';

const {
  PriceInput, Switch,
} = Input;

const PriceForm = ({ premisesData, form }) => {
  const [metersMode, setMetersMode] = useState(false);

  const switchMode = (e) => {
    setMetersMode(e);
  };

  // eslint-disable-next-line consistent-return
  const changeField = (changedField) => {
    const totalSurface = form.getFieldValue('totalSurface');
    if (!totalSurface) {
      return;
    }
    const fieldValue = form.getFieldValue(['price', changedField]);
    let fieldToChange = '';

    let newValue = 0;
    if (metersMode) {
      newValue = fieldValue * totalSurface;
    } else {
      newValue = fieldValue / totalSurface;
    }
    newValue = newValue.toFixed(2);
    switch (changedField) {
      case 'minimal':
        fieldToChange = 'minimalPerSquareMeter';
        break;
      case 'minimalPerSquareMeter':
        fieldToChange = 'minimal';
        break;
      case 'suggest':
        fieldToChange = 'suggestPerSquareMeter';
        break;
      case 'suggestPerSquareMeter':
        fieldToChange = 'suggest';
        break;
      case 'sell':
        fieldToChange = 'sellPerSquareMeter';
        break;
      case 'sellPerSquareMeter':
        fieldToChange = 'sell';
        break;
      default:
        return;
    }
    form.setFieldsValue({
      price: {
        [fieldToChange]: newValue,
      },
    });
  };

  return (
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          {(premisesData.type === 'SALE' || premisesData.type === 'SERVICE') && (
          <div className="form-section">
            <h2>Cena przed sprzedażą</h2>
            <div className="meter-mode-switch" style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Switch size="large" onChange={(e) => switchMode(e)} />
            </div>

            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'minimal']}>
                  <PriceInput size="large" placeholder="Cena minimalna" disabled={metersMode} onChange={() => changeField('minimal')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'minimalPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena minimalna za m2" disabled={!metersMode} onChange={() => changeField('minimalPerSquareMeter')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'suggest']}>
                  <PriceInput size="large" placeholder="Cena sugerowana" disabled={metersMode} onChange={() => changeField('suggest')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'suggestPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena sugerowana za m2" disabled={!metersMode} onChange={() => changeField('suggestPerSquareMeter')} />
                </Form.Item>
              </Col>
            </Row>
            <h2>Cena sprzedaży</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'sell']}>
                  <PriceInput size="large" placeholder="Cena sprzedaży" disabled={metersMode} onChange={() => changeField('sell')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'sellPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena sprzedaży za m2" disabled={!metersMode} onChange={() => changeField('sellPerSquareMeter')} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          )}
          {premisesData.type === 'STORAGE' && (
          <div className="form-section">
            <h2>Cena przed sprzedażą</h2>
            <div className="meter-mode-switch" style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Switch size="large" onChange={(e) => switchMode(e)} />
            </div>

            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'minimal']}>
                  <PriceInput size="large" placeholder="Cena minimalna" disabled={metersMode} onChange={() => changeField('minimal')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'minimalPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena minimalna za m2" disabled={!metersMode} onChange={() => changeField('minimalPerSquareMeter')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'suggest']}>
                  <PriceInput size="large" placeholder="Cena sugerowana" disabled={metersMode} onChange={() => changeField('suggest')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'suggestPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena sugerowana za m2" disabled={!metersMode} onChange={() => changeField('suggestPerSquareMeter')} />
                </Form.Item>
              </Col>
            </Row>
            <h2>Cena sprzedaży</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'sell']}>
                  <PriceInput size="large" placeholder="Cena sprzedaży" disabled={metersMode} onChange={() => changeField('sell')} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'sellPerSquareMeter']}>
                  <PriceInput size="large" placeholder="Cena sprzedaży za m2" disabled={!metersMode} onChange={() => changeField('sellPerSquareMeter')} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          )}
          {premisesData.type === 'PARKING' && (
          <div className="form-section">
            <h2>Cena przed sprzedażą</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'minimal']}>
                  <PriceInput size="large" placeholder="Cena minimalna" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'suggest']}>
                  <PriceInput size="large" placeholder="Cena sugerowana" />
                </Form.Item>
              </Col>
            </Row>
            <h2>Cena sprzedaży</h2>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name={['price', 'sell']}>
                  <PriceInput size="large" placeholder="Cena sprzedaży" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PriceForm;
