import React from 'react';
import { Button, Row, Col } from 'antd';

const Footer = ({
  onReset, onFinish, isNew, customSendName,
}) => {
  let sendName = 'Zapisz';

  if (isNew) {
    sendName = 'Dodaj';
  }

  if (customSendName) {
    sendName = customSendName;
  }
  return (
    <Row className="datagrid-filter-modal-button-wrapper">
      <Col xs={11} style={{ textAlign: 'left' }}>
        <Button className="button-secondary" size="large" onClick={() => onReset()}>
          Anuluj
        </Button>
      </Col>
      <Col xs={11} offset={2}>
        <Button type="primary" size="large" onClick={() => onFinish()}>
          {sendName}
        </Button>
      </Col>
    </Row>
  );
};

export default Footer;
