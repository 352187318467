import BaseService from './baseService';

class TranchesService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/tranche`;

    const roles = localStorage.getItem('roles');
    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/premises/${premisesId}/tranche`;
    }
  }
}

export default TranchesService;
