import BaseService from './baseService';

class MainCountersHistoryService extends BaseService {
  constructor(investmentId, mainCounterId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/mainCounters/${mainCounterId}/history`;
  }
}

export default MainCountersHistoryService;
