import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import Icon from '../../../components/Icon';
import { Empty } from '../../../components';
import { Spin } from '../../../components';
import memberDashboardService from '../../../services/memberDashboardService';
import memberSettlementsService from '../../../services/memberSettlementsService';
import { StatsWrapper } from './ViewComponents';
import { premiseTypeMap } from '../../../helpers';
import VoteModal from '../../resolutions/showComponents/VoteModal';

const BalanceBox = ({ balance }) => {
  if (balance.length === 0) return <Empty />;

  if (balance.length === 1) {
    return (
      <Row
        className={`balance-box ${
          balance[0].balance.balance !== 0
            ? 'balance-box-red'
            : 'balance-box-green'
        }`}
        style={{
          margin: 0,
          paddingTop: 0,
          paddingBottom: 10,
          borderRadius: 15,
        }}
      >
        <Col xs={24} sm={12} lg={7}>
          <div
            className='value-wrapper'
            style={{ marginTop: 15, fontSize: 24, fontWeight: 700 }}
          >
            {balance[0].balance.balanceTenants !== 0 && '-'}
            {parseFloat(balance[0].balance.balanceTenants)
              .toFixed(2)
              .replace('.', ',')}{' '}
            zł
          </div>
          <div className='title-wrapper' style={{ fontSize: 12 }}>
            Saldo najemców
          </div>
        </Col>
        <Col xs={24} sm={12} lg={7}>
          <div
            className='value-wrapper'
            style={{ marginTop: 15, fontSize: 24, fontWeight: 700 }}
          >
            {balance[0].balance.balance !== 0 && '-'}
            {parseFloat(balance[0].balance.balance)
              .toFixed(2)
              .replace('.', ',')}{' '}
            zł
          </div>
          <div className='title-wrapper' style={{ fontSize: 12 }}>
            Saldo
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 20]}>
      {balance.map((record) => (
        <Col xs={24}>
          <Row
            className={`balance-box ${
              record.balance.balance !== 0 || record.balance.balanceTenants
                ? 'balance-box-red'
                : 'balance-box-green'
            }`}
            style={{
              margin: 0,
              paddingTop: 0,
              paddingBottom: 10,
              borderRadius: 15,
            }}
          >
            <Col xs={24} lg={10} xl={7}>
              <div
                className='value-wrapper'
                style={{ marginTop: 15, fontSize: 24, fontWeight: 700 }}
              >
                {record.name}
              </div>
              <div className='title-wrapper' style={{ fontSize: 12 }}>
                {record.type === 'HOUSING_ASSOCIATION'
                  ? 'Spółdzielnia'
                  : 'Wspólnota'}
              </div>
            </Col>
            <Col xs={24} lg={7}>
              <div
                className='value-wrapper'
                style={{ marginTop: 15, fontSize: 24, fontWeight: 700 }}
              >
                {record.balance.balanceTenants !== 0 && '-'}
                {parseFloat(record.balance.balanceTenants)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                zł
              </div>
              <div className='title-wrapper' style={{ fontSize: 12 }}>
                Saldo najemców
              </div>
            </Col>
            <Col xs={24} lg={7}>
              <div
                className='value-wrapper'
                style={{ marginTop: 15, fontSize: 24, fontWeight: 700 }}
              >
                {record.balance.balance !== 0 && '-'}
                {parseFloat(record.balance.balance)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                zł
              </div>
              <div className='title-wrapper' style={{ fontSize: 12 }}>
                Saldo
              </div>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

const PremisesBox = ({ premises }) => {
  if (premises.length === 0) return <Empty />;

  if (premises.length === 1) {
    return (
      <Row gutter={[0, 20]}>
        {premises[0].premises.map((premise) => (
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                border: '2px solid #B9C9DA',
                borderRadius: 10,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 30,
                paddingRight: 0,
              }}
            >
              <Row style={{ flex: 1 }}>
                <Col xs={24} lg={14}>
                  <Row style={{ fontSize: 24, fontWeight: 700 }}>
                    {premiseTypeMap(premise.type)} {premise.name}
                  </Row>
                  <Row style={{ fontSize: 12 }}>
                    {premise.investmentName} ul. {premise.address.street}{' '}
                    {premise.address.number}
                    {premise.address.local ? `/${premise.address.local}` : ''}
                    {', '}
                    {premise.address.zip} {premise.address.city}
                  </Row>
                </Col>
                <Col xs={24} lg={5} style={{ paddingTop: 10 }}>
                  <Row style={{ display: 'flex' }}>
                    <Col style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}>
                      Powierzchnia:
                    </Col>
                    <Col
                      xs={12}
                      style={{ flex: 1, fontSize: 12, fontWeight: 700 }}
                    >
                      {premise.totalSurface} m<sup>2</sup>
                    </Col>
                  </Row>
                  <Row style={{ display: 'flex' }}>
                    <Col style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}>
                      Piętro:
                    </Col>
                    <Col style={{ flex: 1, fontSize: 12, fontWeight: 700 }}>
                      {premise.floor ?? '-'}
                    </Col>
                  </Row>
                </Col>
                {premise.type === 'RENT' && (
                  <Col xs={24} lg={5} style={{ paddingTop: 10 }}>
                    <Row style={{ display: 'flex' }}>
                      <Col
                        style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}
                      >
                        Lokatorzy:
                      </Col>
                      <Col
                        xs={12}
                        style={{ flex: 1, fontSize: 12, fontWeight: 700 }}
                      >
                        {premise.tenants}
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex' }}>
                      <Col
                        style={{ width: 120, fontSize: 12, color: '#B9C9DA' }}
                      >
                        Najemcy:
                      </Col>
                      <Col style={{ flex: 1, fontSize: 12, fontWeight: 700 }}>
                        {premise.tenantsSum ?? '-'}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <div
                style={{
                  width: 30,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link
                  to={
                    premises[0].type === 'HOUSING_ASSOCIATION'
                      ? `memberAssociations/${premises[0].id}/premises/${premise.id}/show`
                      : `memberCommunities/${premises[0].id}/premises/${premise.id}/show`
                  }
                >
                  <Icon
                    name='arrow-simply-right'
                    style={{ color: '#C0D6E5' }}
                  />
                </Link>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <Row gutter={[0, 20]}>
      {premises.map((investment) =>
        investment.premises.map((premise, idx) => (
          <Col xs={24}>
            <Row
              style={
                idx === 0
                  ? {
                      backgroundColor: '#3B4C60',
                      borderRadius: 10,
                      display: 'flex',
                    }
                  : null
              }
            >
              {idx === 0 ? (
                <Col
                  xs={24}
                  xxl={6}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}
                    >
                      {investment.name}
                    </div>
                    <div style={{ fontSize: 12, color: '#B9C9DA' }}>
                      {investment.type === 'HOUSING_ASSOCIATION'
                        ? 'Spółdzielnia'
                        : 'Wspólnota'}
                    </div>
                  </div>
                </Col>
              ) : (
                <Col xs={24} xxl={6} />
              )}
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  backgroundColor: '#F0F4F6',
                  border: '2px solid #B9C9DA',
                  borderRadius: 10,
                  paddingTop: 20,
                  paddingBottom: 20,
                  paddingLeft: 30,
                  paddingRight: 0,
                }}
              >
                <Row style={{ flex: 1 }}>
                  <Col xs={24} lg={12}>
                    <Row style={{ fontSize: 24, fontWeight: 700 }}>
                      {premiseTypeMap(premise.type)} {premise.name}
                    </Row>
                    <Row style={{ fontSize: 12 }}>
                      {premise.investmentName} ul. {premise.address.street}{' '}
                      {premise.address.number}
                      {premise.address.local ? `/${premise.address.local}` : ''}
                      {', '}
                      {premise.address.zip} {premise.address.city}
                    </Row>
                  </Col>
                  <Col xs={24} lg={6} style={{ paddingTop: 10 }}>
                    <Row style={{ display: 'flex' }}>
                      <Col
                        style={{ width: 80, fontSize: 12, color: '#B9C9DA' }}
                      >
                        Powierzchnia:
                      </Col>
                      <Col
                        xs={12}
                        style={{ flex: 1, fontSize: 12, fontWeight: 700 }}
                      >
                        {premise.totalSurface} m<sup>2</sup>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex' }}>
                      <Col
                        style={{ width: 80, fontSize: 12, color: '#B9C9DA' }}
                      >
                        Piętro:
                      </Col>
                      <Col style={{ flex: 1, fontSize: 12, fontWeight: 700 }}>
                        {premise.floor ?? '-'}
                      </Col>
                    </Row>
                  </Col>
                  {premise.type === 'RENT' && (
                    <Col xs={24} lg={6} style={{ paddingTop: 10 }}>
                      <Row style={{ display: 'flex' }}>
                        <Col
                          style={{ width: 80, fontSize: 12, color: '#B9C9DA' }}
                        >
                          Lokatorzy:
                        </Col>
                        <Col
                          xs={12}
                          style={{ flex: 1, fontSize: 12, fontWeight: 700 }}
                        >
                          {premise.tenants}
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex' }}>
                        <Col
                          style={{ width: 80, fontSize: 12, color: '#B9C9DA' }}
                        >
                          Najemcy:
                        </Col>
                        <Col style={{ flex: 1, fontSize: 12, fontWeight: 700 }}>
                          {premise.tenantsSum ?? '-'}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <div
                  style={{
                    width: 30,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    to={
                      investment.type === 'HOUSING_ASSOCIATION'
                        ? `memberAssociations/${investment.id}/premises/${premise.id}/show`
                        : `memberCommunities/${investment.id}/premises/${premise.id}/show`
                    }
                  >
                    <Icon
                      name='arrow-simply-right'
                      style={{ color: '#C0D6E5' }}
                    />
                  </Link>
                </div>
              </div>
            </Row>
          </Col>
        ))
      )}
    </Row>
  );
};

const ResolutionsBox = ({ resolutions, setResolutions }) => {
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [currResolution, setCurrResolution] = useState({});
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (resolutions.length === 0) {
      setIsEmpty(true);
    } else {
      if (
        resolutions.some((investment) => investment.resolutions.length !== 0)
      ) {
        setIsEmpty(false);
      } else {
        setIsEmpty(true);
      }
    }
  }, [resolutions]);

  useEffect(() => {
    if (currResolution?.vote) {
      const MemberDashboardService = new memberDashboardService();

      MemberDashboardService.getResolutions().then((response) => {
        const tempResolutions = response.map((investment) => ({
          ...investment,
          resolutions: investment.resolutions.filter(
            (el) => el.status === 'STARTED'
          ),
        }));

        setResolutions(tempResolutions);
      });
    }
  }, [currResolution]);

  const vote = (resolution) => {
    setCurrResolution(resolution);
    setShowVoteModal(true);
  };

  if (isEmpty) return <Empty />;

  if (resolutions.length === 1) {
    return (
      <Row gutter={[0, 20]}>
        <VoteModal
          isModalVisible={showVoteModal}
          setShowModal={setShowVoteModal}
          resolution={currResolution}
          setResolution={setCurrResolution}
        />
        {resolutions[0].resolutions.map((resolution) => (
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                border: '2px solid #B9C9DA',
                borderRadius: 10,
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 30,
                paddingRight: 0,
              }}
            >
              <Row style={{ flex: 1 }}>
                <Col xs={24} lg={12}>
                  <Row style={{ fontSize: 24, fontWeight: 700 }}>
                    <Tooltip title={'W toku'}>
                      <span
                        className={'dot green'}
                        style={{ marginRight: 10, marginTop: 8 }}
                      />
                    </Tooltip>
                    {resolution.name}
                  </Row>
                  <Row style={{ fontSize: 12 }}>
                    Głosowanie do: {resolution.endDate}
                  </Row>
                </Col>
                <Col xs={24} lg={12} className='dashboard-resolution-info'>
                  <Row gutter={[0, 15]}>
                    <Col xs={24} style={{ textAlign: 'right' }}>
                      {resolution?.vote ? (
                        <span
                          style={{
                            padding: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            backgroundColor: '#fff',
                            fontSize: 14,
                            fontWeight: 700,
                            borderRadius: 12,
                          }}
                        >
                          Twój głos:
                          {resolution?.vote === 'PRO' ? (
                            <span style={{ marginLeft: 5, color: '#15D55E' }}>
                              ZA
                            </span>
                          ) : resolution?.vote === 'CON' ? (
                            <span style={{ marginLeft: 5, color: '#EC434C' }}>
                              PRZECIW
                            </span>
                          ) : resolution?.vote === 'ABSTAIN' ? (
                            <span style={{ marginLeft: 5 }}>
                              WSTRZYMUJĘ SIĘ
                            </span>
                          ) : null}
                        </span>
                      ) : (
                        <Button
                          type='primary'
                          style={{
                            backgroundColor: '#ec434c',
                            border: 0,
                            minHeight: 32,
                            minWidth: 120,
                          }}
                          onClick={() => vote(resolution)}
                        >
                          Oddaj głos{' '}
                          <Icon name='vote' style={{ marginLeft: 5 }} />
                        </Button>
                      )}
                    </Col>
                    <Col xs={24} style={{ textAlign: 'right' }}>
                      <div>
                        <span style={{ color: '#15D55E', fontWeight: 700 }}>
                          ZA
                        </span>
                        <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                          {parseFloat(resolution?.votes?.proPercent).toFixed(0)}
                          % -
                        </span>
                        <span
                          style={{
                            color: '#EC434C',
                            fontWeight: 700,
                            marginLeft: 5,
                          }}
                        >
                          PRZECIW
                        </span>
                        <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                          {parseFloat(resolution?.votes?.conPercent).toFixed(0)}
                          % -
                        </span>
                        <span
                          style={{
                            color: '#8FB3D1',
                            fontWeight: 700,
                            marginLeft: 5,
                          }}
                        >
                          WSTRZYMANO
                        </span>
                        <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                          {parseFloat(
                            resolution?.votes?.abstainPercent
                          ).toFixed(0)}
                          %
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div
                style={{
                  width: 30,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Link
                  to={`investment/${resolutions[0].id}/resolutions/${resolution.id}/show`}
                >
                  <Icon
                    name='arrow-simply-right'
                    style={{ color: '#C0D6E5' }}
                  />
                </Link>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <Row gutter={[0, 20]}>
      <VoteModal
        isModalVisible={showVoteModal}
        setShowModal={setShowVoteModal}
        resolution={currResolution}
        setResolution={setCurrResolution}
      />
      {resolutions.map((investment) =>
        investment.resolutions.map((resolution, idx) => (
          <Col xs={24}>
            <Row
              style={
                idx === 0
                  ? {
                      backgroundColor: '#3B4C60',
                      borderRadius: 10,
                      display: 'flex',
                    }
                  : null
              }
            >
              {idx === 0 ? (
                <Col
                  xs={24}
                  xxl={6}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  <div style={{ paddingLeft: 20 }}>
                    <div
                      style={{ fontSize: 24, fontWeight: 700, color: '#fff' }}
                    >
                      {investment.name}
                    </div>
                    <div style={{ fontSize: 12, color: '#B9C9DA' }}>
                      {investment.type === 'HOUSING_ASSOCIATION'
                        ? 'Spółdzielnia'
                        : 'Wspólnota'}
                    </div>
                  </div>
                </Col>
              ) : (
                <Col xs={24} xxl={6} />
              )}
              <div className='resolution-wrapper'>
                <Row gutter={[0, 10]} style={{ flex: 1 }}>
                  <Col xs={24} lg={12}>
                    <Row style={{ fontSize: 24, fontWeight: 700 }}>
                      <Tooltip title={'W toku'}>
                        <span
                          className={'dot green'}
                          style={{ marginRight: 10, marginTop: 8 }}
                        />
                      </Tooltip>
                      {resolution.name}
                    </Row>
                    <Row style={{ fontSize: 12 }}>
                      Głosowanie do: {resolution.endDate}
                    </Row>
                  </Col>
                  <Col xs={24} lg={12} className='dashboard-resolution-info'>
                    <Row gutter={[0, 15]}>
                      <Col xs={24} className='dashboard-resolution-info-col'>
                        {resolution?.vote ? (
                          <span className='resolution-result'>
                            Twój głos:
                            {resolution?.vote === 'PRO' ? (
                              <span style={{ marginLeft: 5, color: '#15D55E' }}>
                                ZA
                              </span>
                            ) : resolution?.vote === 'CON' ? (
                              <span style={{ marginLeft: 5, color: '#EC434C' }}>
                                PRZECIW
                              </span>
                            ) : resolution?.vote === 'ABSTAIN' ? (
                              <span style={{ marginLeft: 5 }}>
                                WSTRZYMUJĘ SIĘ
                              </span>
                            ) : null}
                          </span>
                        ) : (
                          <Button
                            type='primary'
                            style={{
                              backgroundColor: '#ec434c',
                              border: 0,
                              minHeight: 32,
                              minWidth: 120,
                            }}
                            onClick={() => vote(resolution)}
                          >
                            Oddaj głos{' '}
                            <Icon name='vote' style={{ marginLeft: 5 }} />
                          </Button>
                        )}
                      </Col>
                      <Col xs={24} className='dashboard-resolution-info-col'>
                        <div>
                          <span style={{ color: '#15D55E', fontWeight: 700 }}>
                            ZA
                          </span>
                          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                            {parseFloat(resolution?.votes?.proPercent).toFixed(
                              0
                            )}
                            % -
                          </span>
                          <span
                            style={{
                              color: '#EC434C',
                              fontWeight: 700,
                              marginLeft: 5,
                            }}
                          >
                            PRZECIW
                          </span>
                          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                            {parseFloat(resolution?.votes?.conPercent).toFixed(
                              0
                            )}
                            % -
                          </span>
                          <span
                            style={{
                              color: '#8FB3D1',
                              fontWeight: 700,
                              marginLeft: 5,
                            }}
                          >
                            WSTRZYMANO
                          </span>
                          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
                            {parseFloat(
                              resolution?.votes?.abstainPercent
                            ).toFixed(0)}
                            %
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className='resolution-redirect-btn'>
                  <Link
                    to={`investment/${investment.id}/resolutions/${resolution.id}/show`}
                  >
                    <Icon
                      name='arrow-simply-right'
                      style={{ color: '#C0D6E5' }}
                    />
                  </Link>
                </div>
              </div>
            </Row>
          </Col>
        ))
      )}
    </Row>
  );
};

const CommunityMemberDashboard = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState([]);
  const [premises, setPremises] = useState([]);
  const [resolutions, setResolutions] = useState([]);

  useEffect(() => {
    const promises = [];
    const MemberDashboardService = new memberDashboardService();

    promises.push(
      MemberDashboardService.getBalance().then((response) => {
        setBalance(response);
      })
    );
    promises.push(
      MemberDashboardService.getPremises().then((response) => {
        setPremises(response);
      })
    );
    promises.push(
      MemberDashboardService.getResolutions().then((response) => {
        const tempResolutions = response.map((investment) => ({
          ...investment,
          resolutions: investment.resolutions.filter(
            (el) => el.status === 'STARTED'
          ),
        }));

        setResolutions(tempResolutions);
      })
    );

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, []);

  const payTheBalance = () => {
    memberSettlementsService.payBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/memberSettlements/null/payment/${response.id}/show`
        );
      }
    });
  };

  const payTenantsBalance = () => {
    memberSettlementsService.payTenantsBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/memberSettlements/null/payment/${response.id}/show?tenantsBalance`
        );
      }
    });
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={20}>
            <Col xs={24}>
              <StatsWrapper
                title='Saldo'
                icon='payments'
                actionBtns={true}
                actions={[
                  balance.some(
                    (record) => record.balance.balanceTenants !== 0
                  ) ? (
                    <Button
                      size='small'
                      onClick={() => payTenantsBalance()}
                      style={{
                        backgroundColor: '#3B4C60',
                        color: '#fff',
                        paddingLeft: 7,
                        paddingRight: 12,
                        paddingTop: 2,
                        marginBottom: 15,
                      }}
                    >
                      <Icon name='payments' style={{ paddingRight: 5 }} />
                      Opłać za najemcę
                    </Button>
                  ) : null,
                  balance.some((record) => record.balance.balance !== 0) ? (
                    <Button
                      size='small'
                      onClick={() => payTheBalance()}
                      style={{
                        backgroundColor: '#3B4C60',
                        color: '#fff',
                        paddingLeft: 7,
                        paddingRight: 12,
                        paddingTop: 2,
                        marginBottom: 15,
                      }}
                    >
                      <Icon name='payments' style={{ paddingRight: 5 }} />
                      Opłać saldo
                    </Button>
                  ) : null,
                ]}
              >
                <BalanceBox balance={balance} />
              </StatsWrapper>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24}>
              <StatsWrapper
                title='Lokale'
                icon='apartments'
                actions={[
                  <Link to='memberPremises'>
                    <Button size='small'>Pokaż</Button>
                  </Link>,
                ]}
              >
                <PremisesBox premises={premises} />
              </StatsWrapper>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24}>
              <StatsWrapper
                title='Uchwały'
                icon='uchwaly'
                actions={[
                  <Link to='resolutions'>
                    <Button size='small'>Pokaż</Button>
                  </Link>,
                ]}
              >
                <ResolutionsBox
                  resolutions={resolutions}
                  setResolutions={setResolutions}
                />
              </StatsWrapper>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CommunityMemberDashboard;
