import BaseService from './baseService';

class investmentContactService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/contact`;
  }
}

export default investmentContactService;
