import React from 'react';
import Icon from '../../../../components/Icon';

export const DescriptionBox = ({ fault, title = 'Opis', valueKey = 'description' }) => {
  if (!fault[valueKey]) {
    return '';
  }
  return (
    <div className="premises-show-box info-box description-box">
      <h2>
        <Icon name="description" />
        {title}
      </h2>
      <div className="content">
        {fault[valueKey]}
      </div>
    </div>
  );
};
