import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import mainCountersService from '../../../../services/mainCountersService';
import Icon from '../../../../components/Icon';
import Input from '../../../../components/InputComponent';
import MessagePopup from '../../../../components/MessagePopup';
import PermisesService from '../../../../services/premisesService';
import subCountersService from '../../../../services/subCountersService';

const { Select } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const { id, counterId } = useParams();
  const [form] = Form.useForm();
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [premises, setPremises] = useState([]);
  const [mainCounter, setMainCounter] = useState({});

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);

    MainCountersService.getOne(counterId).then((response) => {
      setMainCounter(response);
      form.setFieldValue('mainCounterNumber', response?.number);
    });
  }, []);

  useEffect(() => {
    const PremisesService = new PermisesService(id);

    PremisesService.getList({ size: 9999 }).then((response) => {
      setPremises(response.content);
      setInvestmentPremises(response.content);
    });
  }, []);

  const onFinish = (values) => {
    const tempData = {
      number: values.number,
      premisesId: values.premisesId,
    };

    const SubCountersService = new subCountersService(id, counterId);

    SubCountersService.create(tempData)
      .then((response) => {
        history.replace(`/investment/${id}/counters/${counterId}/subCounters`);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const filterPremises = (value) => {
    const filteredPremises = investmentPremises.filter(
      (premise) => premise.type === value
    );

    setPremises(filteredPremises);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    // history.push(`/investment/${id}/counters/${counterId}/subCounters`);
    history.goBack();
  };

  return (
    <div className='form-create'>
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        onBack={() => window.history.back()}
        title='Dodaj podlicznik'
      >
        <Form
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          form={form}
        >
          <Card>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={16} offset={4}>
                  <div className='form-section'>
                    <h2>Podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='number'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input size='large' placeholder='Numer podlicznika' />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='mainCounterNumber'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę uzupełnić nazwę',
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            size='large'
                            placeholder='Licznik głowny'
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className='form-section'>
                    <h2>Lokal</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item name='premiseType'>
                          <Select
                            placeholder='Typ lokalu'
                            size='large'
                            onSelect={(value) => filterPremises(value)}
                          >
                            <Option value='RENT'>Mieszkanie</Option>
                            <Option value='STORAGE'>Komórka lokatorska</Option>
                            <Option value='PARKING'>Miejsce postojowe</Option>
                            <Option value='SERVICE'>Lokal usługowy</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name='premisesId'
                          rules={[
                            {
                              required: true,
                              message: 'Proszę wybrać lokal',
                            },
                          ]}
                        >
                          <Select placeholder='Lokal' size='large' showSearch>
                            {premises.map((premise) => (
                              <Option value={premise.id} label={premise.name}>
                                {premise.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={handleCancel}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Form.Item>
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  className='create-form-button'
                >
                  Dodaj
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
