import React from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, Tooltip } from 'antd';
import { Datagrid } from '../../../components';
import Icon from '../../../components/Icon';
import HousingSettlementsService from '../../../services/housingSettlementsService';
import { premiseTypeMap } from '../../../helpers';

const RenderStatus = (status) => {
  if (status === 'TO_PAY') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Do zapłaty'}>
          <span className={'dot red'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PARTIALLY_PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Częściowo zapłacone'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'PAID') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zapłacone'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const getRowClassName = (record, index) => {
  if (record.status === 'TO_PAY') {
    return 'datagrid-row-red';
  } else if (record.status === 'PARTIALLY_PAID') {
    return 'datagrid-row-yellow';
  }

  return '';
};

const List = () => {
  const { id } = useParams();

  const columns = [
    {
      title: 'Nazwa rozliczenia',
      dataIndex: 'name',
      visible: true,
      filterEnabled: false,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Lokal',
      dataIndex: 'premises',
      visible: true,
      filterEnabled: false,
      key: 'premises',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
      exportRender: (text, record) =>
        `${premiseTypeMap(record.premisesType)} ${record.premisesName}`,
    },
    {
      title: 'Właściciel',
      dataIndex: 'owner',
      visible: true,
      filterEnabled: false,
      key: 'owner',
      sorter: false,
      export: true,
      render: (text, record) =>
        record.owner
          ? `${record.owner.firstName} ${record.owner.lastName}`
          : '',
      exportRender: (text, record) =>
        record.owner
          ? `${record.owner.firstName} ${record.owner.lastName}`
          : '',
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      visible: true,
      filterEnabled: false,
      key: 'createDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: false,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? RenderStatus(text) : ''),
      exportRender: (text) => {
        if (text) {
          if (text === 'TO_PAY') {
            return 'Do zapłaty';
          } else if (text === 'PARTIALLY_PAID') {
            return 'Częściowo zapłacone';
          }
          if (text === 'PAID') {
            return 'Zapłacone';
          }
        } else {
          return '';
        }
      },
    },
    {
      title: 'Termin płatności',
      dataIndex: 'paymentDate',
      visible: true,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data opłacenia',
      dataIndex: 'paymentDate',
      visible: false,
      filterEnabled: false,
      key: 'paymentDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Zapłacono',
      dataIndex: 'paid',
      visible: true,
      filterEnabled: false,
      key: 'paid',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
    {
      title: 'Kwota (brutto)',
      dataIndex: 'price',
      visible: true,
      filterEnabled: false,
      key: 'price',
      sorter: true,
      export: true,
      render: (text, record) =>
        text ? `${parseFloat(text).toFixed(2)} zł` : '',
    },
  ];

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            onBack={() => window.history.back()}
            backIcon={<Icon name='arrow-simply-left' />}
            title={'Rozliczenia'}
          />
        }
        exportTitle='Rozliczenia'
        dataProvider={HousingSettlementsService}
        columns={columns}
        filters={{
          ownerIds: id,
        }}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        customRowClass={getRowClassName}
        hideCreate
        hideDelete
        hideReset
        hideSms
        hideEmail
        hideFilters
        hideEdit
        hideShow
      />
    </div>
  );
};

export default List;
