import BaseService from './baseService';

class UserSettlementsGroupService extends BaseService {
  constructor(userId, isBroker = false) {
    super();
    this.url = `/api/vendor/user/${userId}/settlement/group`;
    if (isBroker) {
      this.url = '/api/user/settlement/group';
    }
  }
}

export default UserSettlementsGroupService;
