import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultGroupTagsService extends BaseService {
  constructor() {
    super();
    this.url = '/api/vendor/tags';
  }

  async getAllFaultGroups() {
    return httpService.get(`${this.url}/faultGroup`, null, true);
  }

  async getFaultGroupTags(investmentId, faultGroupId) {
    return httpService.get(`${this.url}/investment/${investmentId}/faultGroup/${faultGroupId}`, null, true);
  }
}

export default FaultGroupTagsService;
