import { message, Button } from 'antd';

const CopiedPopup = () => {
  const onClickHandler = () => {
    message.destroy();
  };

  return (
    <>
      <div className='message-wrapper'>
        <div className='message-title'>Skopiowano</div>
      </div>

      <Button className='message-close-btn-success' onClick={onClickHandler}>
        Zamknij
      </Button>
    </>
  );
};

export default CopiedPopup;
