import React, { useState } from 'react';
import { Form, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import authService from '../../services/authService';
import Input from '../../components/InputComponent';
import Icon from '../../components/Icon';

const { Text } = Typography;

const ResetPassword = () => {
  const [requestSent, setRequestSent] = useState(false);

  const onFinish = (values) => {
    if (authService.resetPassword(values)) {
      setRequestSent(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className="reset-password-form-wrapper">
        {!requestSent ? (
          <>
            <h2>Przypomnij hasło</h2>
            <Form
              name="resetPassword"
              className="reset-password-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your login!' }]}
              >
                <Input size="large" placeholder="Login" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" className="reset-password-form-button">
                  Resetuj hasło
                </Button>
              </Form.Item>
              <div className="reset-password-form-back-wrapper" style={{ textAlign: 'center', marginTop: '40px' }}>
                <Link key="2" to="/login">
                  <Button key="1" className="button-secondary">
                    <Icon name="arrow-simply-left" />
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>Wróć</span>
                  </Button>
                </Link>
              </div>
            </Form>
          </>
        ) : (
          <div className="reset-success">
            <p><Text type="success">Twoje hasło zostało zresetowane.</Text></p>
            <p><Text type="success">Sprawdź skrzynkę pocztową w celu dokończenia procesu odzyskiwania hasła.</Text></p>
            <Link to="/login">Wróć</Link>
          </div>
        )}
      </div>
    </>
  );
};
export default ResetPassword;
