import httpService from '../helpers/httpService';

const subcountersImportsService = {};

subcountersImportsService.getImports = async (investmentId, mainCounterId) => httpService.get(`/api/vendor/investment/${investmentId}/mainCounters/${mainCounterId}/subCounters/import`, null, true);

subcountersImportsService.import = async (data, config = {}, investmentId, mainCounterId) => httpService
  .post(
    `/api/vendor/investment/${investmentId}/mainCounters/${mainCounterId}/subCounters/import`,
    data,
    true,
    {
      'content-type': 'multipart/form-data',
    },
    config,
  );

export default subcountersImportsService;
