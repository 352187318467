import BaseService from './baseService';

class TenantChangesService extends BaseService {
  constructor(investmentId, premisesId, tenantChangeGroupId) {
    super();
    const roles = localStorage.getItem('roles');

    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/tenantChangeGroup/${tenantChangeGroupId}/tenantChange`;
    }
  }
}

export default TenantChangesService;
