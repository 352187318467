/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import premisesService from '../../../../services/premisesService';
import investmentsService from '../../../../services/investmentsService';
import userService from '../../../../services/userService';
import Icon from '../../../../components/Icon';
import Spin from '../../../../components/Spin';
import premiseTypeMap from '../../../../helpers/premiseTypeMap';
import { AttachmentsBox } from './../showComponents/AttachmentsBox';
import InfoBox from '../showComponents/InfoBox';
import SharesBox from '../showComponents/SharesBox';
import { OwnerBox } from '../showComponents/OwnerBox';
import { ContactsBox } from '../showComponents/ContactsBox';
import { CountersBox } from '../showComponents/CountersBox';
import { FaultsBox } from '../showComponents/FaultsBox';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Show = () => {
  const { id, premisesId } = useParams();
  const history = useHistory();
  const [premise, setPremise] = useState({});
  const [investment, setInvestment] = useState({});
  const [rooms, setRooms] = useState([]);
  const [costs, setCosts] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [canSeePrices, setCanSeePrices] = useState(true);

  useEffect(() => {
    const PremisesService = new premisesService(id);

    const promises = [];

    promises.push(
      PremisesService.getOne(premisesId).then((data) => {
        setPremise(data);
      })
    );

    const InvestmentsService = new investmentsService();
    promises.push(
      InvestmentsService.getOne(id).then((investmentData) => {
        setInvestment(investmentData);
      })
    );

    promises.push(
      userService.getInfo().then((data) => {
        setUserData(data);
        if (
          data.roles.includes('ROLE_WORKER') &&
          data.ownerRoles.includes('ROLE_DEVELOPER')
        ) {
          if (
            data.workerPermissions.includes('INVESTMENT_FULL') &&
            data.workerPermissions.includes('RECEIPTS_FULL')
          ) {
            setCanEdit(true);
            setCanSeePrices(true);
          } else if (data.workerPermissions.includes('INVESTMENT_FULL')) {
            setCanSeePrices(false);
            setCanEdit(false);
          } else if (data.workerPermissions.includes('RECEIPTS_FULL')) {
            setCanSeePrices(true);
            setCanEdit(false);
          } else {
            setCanSeePrices(false);
            setCanEdit(false);
          }
        } else if (
          data.roles.includes('ROLE_WORKER') &&
          data.ownerRoles.includes('ROLE_TENANT')
        ) {
          if (data.workerPermissions.includes('INVESTMENT_FULL')) {
            setCanEdit(true);
          } else {
            setCanEdit(false);
          }
          setCanSeePrices(true);
        } else if (data.roles.includes('ROLE_CLIENT')) {
          setCanEdit(false);
          setCanSeePrices(true);
        } else {
          setCanEdit(true);
          setCanSeePrices(true);
        }
      })
    );
    Promise.all(promises)
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [id, premisesId]);

  let priceInHeader = 0;
  let additionalClassName = '';
  const { price = {} } = premise;

  if (investment.type === 'SALE') {
    if (premise.status === 'FREE') {
      priceInHeader = price.suggest || 0;
    } else {
      priceInHeader = price.sell || 0;
      additionalClassName = 'sell';
    }
  } else {
    rooms.forEach(({ rent, charges }) => {
      priceInHeader += rent;
      priceInHeader += charges;
    });
  }

  let hasAnyPrice = true;

  if (premise && premise.price) {
    const nonEmptyPrices = Object.keys(premise.price)
      .filter((k) => premise.price[k] !== 0)
      .reduce((a, k) => ({ ...a, [k]: premise.price[k] }), {});
    hasAnyPrice = Object.keys(nonEmptyPrices).length > 0;
  }

  const showInfoOnLeft =
    (premise.status === 'FREE' && investment.type === 'RENT') || !hasAnyPrice;

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Wróć'
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            canEdit && (
              <Button
                key='3'
                type='primary'
                onClick={() =>
                  history.replace(
                    `/housingAssociations/${id}/premises/${premisesId}/edit`
                  )
                }
              >
                Edytuj <Icon name='edit' />
              </Button>
            ),
          ]}
        >
          <div className='premises-show'>
            <Row style={{ marginTop: '20px' }}>
              <Col>
                <div style={{ color: '#3B4C60', fontSize: 28 }}>
                  {premiseTypeMap(premise.type)} {premise.name}
                </div>
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
              <Col>
                <div style={{ color: '#3B4C60', fontSize: 14 }}>
                  ul. {premise.address.street} {premise.address.number}
                  {premise.address.local ? `/${premise.address.local}` : ''}
                  {', '}
                  {premise.address.zip} {premise.address.city}
                </div>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xs={24} sm={24} xl={12}>
                <SharesBox premise={premise} />
                <OwnerBox premise={premise} />
                <ContactsBox investmentId={id} premisesId={premisesId} />
                <FaultsBox investment={investment} premise={premise} />
                <AttachmentsBox premise={premise} investment={investment} />
              </Col>
              <Col xs={24} sm={24} xl={12}>
                <InfoBox premise={premise} />
                <CountersBox investmentId={id} premisesId={premisesId} />
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
