import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberInvestmentPremisesService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/user/investment/${investmentId}/community/premises`;
  }

  async getInvestment(investmentId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/community`,
      null,
      true
    );
  }

  async createContact(investmentId, premisesId, data) {
    return httpService.post(
      `/api/user/investment/${investmentId}/premises/${premisesId}/contact`,
      data,
      true
    );
  }

  async getContacts(investmentId, premisesId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/premises/${premisesId}/contact`,
      { sort: 'id,DESC' },
      true
    );
  }

  async deleteContact(investmentId, premisesId, contactId) {
    return httpService.delete(
      `/api/user/investment/${investmentId}/premises/${premisesId}/contact/${contactId}`,
      null,
      true
    );
  }

  async updateContact(investmentId, premisesId, contactId, data) {
    return httpService.put(
      `/api/user/investment/${investmentId}/premises/${premisesId}/contact/${contactId}`,
      data,
      true
    );
  }

  async getAttachments(investmentId, premisesId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/community/premises/${premisesId}/attachments`,
      null,
      true
    );
  }

  async getOneAttachment(investmentId, premisesId, attachmentId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/community/premises/${premisesId}/attachments/${attachmentId}`,
      null,
      true
    );
  }

  async changeTenantsCount(investmentId, premisesId, data) {
    return httpService.patch(
      `/api/user/investment/${investmentId}/community/premises/${premisesId}`,
      data,
      true
    );
  }

  async createTenant(investmentId, premisesId, data) {
    return httpService.post(
      `/api/user/investment/${investmentId}/premises/${premisesId}/community/tenants`,
      data,
      true
    );
  }

  async getTenants(investmentId, premisesId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/premises/${premisesId}/community/tenants`,
      null,
      true
    );
  }

  async deleteTenant(investmentId, premisesId, tenantId) {
    return httpService.delete(
      `/api/user/investment/${investmentId}/premises/${premisesId}/community/tenants/${tenantId}`,
      null,
      true
    );
  }

  async updateTenant(investmentId, premisesId, tenantId, data) {
    return httpService.put(
      `/api/user/investment/${investmentId}/premises/${premisesId}/community/tenants/${tenantId}`,
      data,
      true
    );
  }
}

export default MemberInvestmentPremisesService;
