import BaseService from './baseService';

class SettlementsGroupsService extends BaseService {
  constructor(path) {
    super();
    this.url = `/api/${path}`;
  }
}

export default SettlementsGroupsService;
