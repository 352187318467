/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button, Row, Col, Tooltip, Tag,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import premisesService from '../../services/premisesService';
import investmentsService from '../../services/investmentsService';
import workersService from '../../services/workersService';
import tenantChangeGroups from '../../services/tenantChangeGroups';
import tenantChangeService from '../../services/tenantChangeService';
import tenantChangeTagService from '../../services/tenantChangeTagService';
import tenantChangesFinanceStatusMap from '../../helpers/tenantChangesFinanceStatusMap';
import userService from '../../services/userService';
import Icon from '../../components/Icon';
import Price from '../../components/Price';
import SmallList from '../../components/SmallList';
import Datagrid from '../../components/Datagrid';
import tenantChangesStatusMap from '../../helpers/tenantChangesStatusMap';
import Spin from '../../components/Spin';

const Dot = (props) => {
  const { status } = props;
  let color = 'grey';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const InfoBox = ({
  tenantChange, investment, premise, worker,
}) => {
  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
      render: (text, record) => (`${record.firstName} ${record.lastName}`),
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      render: (text, record) => (<span className="premises-broker-phone">{record.mobile}</span>),
    },
    {
      title: 'Pesel',
      dataIndex: 'percent',
      render: (text, record) => (record.identity ? record.identity.pesel : ''),
    },
  ];

  return (
    <div className="premises-show-box info-box">
      <h2>
        <Icon name="more-info" />
        Informacje
      </h2>
      <div className="content">
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Inwestycja:</Col>
          <Col xs={24} sm={10} className="value">
            {investment.name}
          </Col>
        </Row>
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Lokal:</Col>
          <Col xs={24} sm={10} className="value">{premise.name}</Col>
        </Row>
        {tenantChange.addDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Dodano:</Col>
            <Col xs={24} sm={10} className="value">{tenantChange.addDate}</Col>
          </Row>
        )}

        {tenantChange.changeDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Zmienione:</Col>
            <Col xs={24} sm={10} className="value">{tenantChange.changeDate}</Col>
          </Row>
        )}

        {worker.id && (
          <div style={{ marginTop: '20px' }}>
            <h3>Osoba odpowiedzialna</h3>
            <div className="content">
              <SmallList data={[worker]} columns={columns} showHeader={false} icon="worker" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentStatusDot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'PAID') {
    color = 'green';
  }
  if (status === 'PARTIALLY_PAID') {
    color = 'yellow';
  }

  return (
    <span>
      <span className={`dot ${color}`} style={{ marginRight: '5px' }} />
      {tenantChangesFinanceStatusMap(status)}
    </span>
  );
};

const FinanceBox = ({ tenantChange }) => (
  <div className="premises-show-box info-box">
    <h2>
      <Icon name="payments" />
      Finanse
    </h2>
    <div className="content">
      {tenantChange.expirationDate && (
      <Row className="entry">
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Termin płatności:</Col>
        <Col xs={24} sm={10} className="value">{tenantChange.expirationDate}</Col>
      </Row>
      )}
      {tenantChange.paymentStatus && (
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Status:</Col>
        <Col xs={24} sm={10} className="value"><PaymentStatusDot status={tenantChange.paymentStatus} /></Col>
      </Row>
      )}
      <Row className="entry">
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Zapłacono:</Col>
        <Col xs={24} sm={10} className="value" style={{ fontWeight: 'bold' }}>
          <Price value={tenantChange.paid} showEmpty />
        </Col>
      </Row>

      {tenantChange.paymentDate && (
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Opłacono:</Col>
        <Col xs={24} sm={10} className="value">{tenantChange.paymentDate}</Col>
      </Row>
      )}
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Kwota:</Col>
        <Col xs={24} sm={10} className="value" style={{ fontWeight: 'bold' }}>
          <Price value={tenantChange.amount} showEmpty />
        </Col>
      </Row>
    </div>
  </div>
);

const TenantChanges = ({ canEdit }) => {
  const { investmentId, tenantChangeGroupId, premisesId } = useParams();

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => tenantChangesStatusMap(record.status),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'changeDate',
      visible: true,
      key: 'changeDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'paymentExpiryDate',
      visible: true,
      key: 'paymentExpiryDate',
      sorter: false,
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Opłacono',
      dataIndex: 'paymentDate',
      visible: true,
      key: 'paymentDate',
      sorter: false,
      render: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
      exportRender: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      summary: true,
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const customBaseUrl = (record) => `tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${record.id}`;

  const CustomCreate = () => {
    if (!canEdit) {
      return '';
    }

    return (
      <Link to={`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/create`}>
        <Button type="primary">
          Nowa
          {' '}
          <Icon name="plus" />
        </Button>
      </Link>
    );
  };

  return (
    <Datagrid
      title="Podział zmian"
      resource="tenantChange"
      dataProvider={new tenantChangeService(
        investmentId, premisesId, tenantChangeGroupId,
      )}
      columns={columns}
      directResponse
      hideColumns
      hideFilters
      canEdit={canEdit}
      hideEdit={!canEdit}
      disableSelection
      calculateBaseUrl={customBaseUrl}
      disablePagination
      customCreate={<CustomCreate />}
    />
  );
};

const Show = () => {
  const { investmentId, tenantChangeGroupId, premisesId } = useParams();
  const history = useHistory();
  const [tenantChange, setTenantChange] = useState({});
  const [investment, setInvestment] = useState({});
  const [premise, setPremise] = useState({});
  const [worker, setWorker] = useState({});
  const [userRoles, setUserRoles] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((userData) => {
      const { roles } = userData;
      setUserRoles(roles);
      const PremisesService = new premisesService(investmentId);

      const promises = [];

      promises.push(PremisesService.getOne(premisesId).then((data) => {
        setPremise(data);
      }));

      const InvestmentsService = new investmentsService();
      promises.push(InvestmentsService.getOne(investmentId).then((investmentData) => {
        setInvestment(investmentData);
      }));

      const TenantChangesService = new tenantChangeGroups(investmentId, premisesId, null);

      promises.push(TenantChangesService.getOne(tenantChangeGroupId).then((tenantChangeData) => {
        setTenantChange(tenantChangeData);
        if (tenantChangeData.responsibleWorker != null) {
          workersService.getOne(tenantChangeData.responsibleWorker).then((workerData) => {
            setWorker(workerData);
          });
        }
      }));

      Promise.all(promises).then(() => setLoading(false));
    });
  }, [tenantChangeGroupId, premisesId, investmentId]);

  const handleBack = () => {
    const { location } = history;
    if (location.state && location.state === 'createdFromInvestment') {
      return history.push(`/investments/${tenantChange.investmentId}/premises/${tenantChange.premisesId}/edit`);
    }

    return history.push('/tenantChangeGroup');
  };

  const TagFilters = () => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const TenantChangeTagService = new tenantChangeTagService();
      TenantChangeTagService.getTenantChangeGroupTags(investmentId, tenantChangeGroupId)
        .then((data) => {
          setTags(data);
        });
    }, []);

    return tags.map((tag) => (
      <Link to={`/tenantChangeGroup?tag=${tag}`}><Tag>{`#${tag}`}</Tag></Link>
    ));
  };

  const canEdit = !loading && !userRoles.includes('ROLE_CLIENT') && !userRoles.includes('ROLE_CLIENT_TENANT');
  return (
    <div className="show-layout tenantChanges-show">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name="arrow-simply-left" />}
          title="Wróć"
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            canEdit && (
            <Link key="2" to={`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>
            ),
            canEdit && (
            <Link key="3" to={`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/history`}>
              <Button className="button-secondary">
                Historia
                <Icon name="history-list" />
              </Button>
            </Link>
            ),
          ]}
        >
          <div className="premises-show">
            <div className="premises-show-header">
              <Row justify="space-between">
                <Col xs={24} sm={24}>
                  <h1>
                    {tenantChange.name}
                    <Dot {...tenantChange} />
                  </h1>
                </Col>
              </Row>
            </div>
            <Row gutter={20}>
              {!userRoles.includes('ROLE_CLIENT') && !userRoles.includes('ROLE_CLIENT_TENANT') && (
              <Col xs={24} className="tags-filter">
                <TagFilters />
              </Col>
              )}
              <Col xs={24} sm={12}>
                <InfoBox
                  tenantChange={tenantChange}
                  premise={premise}
                  investment={investment}
                  worker={worker}
                />
              </Col>
              <Col xs={24} sm={12}>
                <FinanceBox tenantChange={tenantChange} />
              </Col>
            </Row>
            <TenantChanges canEdit={canEdit} />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
