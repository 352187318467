/* eslint-disable class-methods-use-this */
import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberTenantAssociationsService extends BaseService {
  constructor(url = '/api/tenant/investment/community') {
    super();

    this.url = url;
  }

  async getDetails(id) {
    return httpService.get(
      `/api/tenant/investment/${id}/community`,
      null,
      true
    );
  }

  async getContacts(id) {
    return httpService.get(
      `/api/tenant/investment/${id}/community/contact`,
      { sort: 'id,DESC' },
      true
    );
  }

  async getDeveloper(id) {
    return httpService.get(
      `/api/tenant/investment/${id}/community/developer`,
      null,
      true
    );
  }

  async getCommunityAdmin(id) {
    return httpService.get(
      `/api/tenant/investment/${id}/community/admin`,
      null,
      true
    );
  }

  async getWorkers(id) {
    return httpService.get(
      `/api/tenant/investment/${id}/community/workers`,
      null,
      true
    );
  }
}

export default MemberTenantAssociationsService;
