import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import resolutionsService from '../../services/resolutionsService';
import housingAssociationsService from '../../services/housingAssociationsService';
import workersService from '../../services/workersService';
import { Icon, Spin, Input } from '../../components';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import PublicFileService from '../../services/publicFileService';

const { DatePicker, SearchBox } = Input;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const query = useQuery();
  const [form] = Form.useForm();
  const premisesId = query.get('premisesId') || null;
  const investmentId = query.get('investmentId') || null;
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setLoading(false);
    form.setFieldValue('investmentId', investmentId);
  }, []);

  const onFinish = (values) => {
    const tempValues = {
      name: values.name,
      investmentId: values.investmentId,
      responsibleWorkers: values.responsibleWorkers,
      status: 'AWAITING',
    };

    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.addDate = values.addDate.format('YYYY-MM-DD');
    }

    if (values?.startDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.startDate = values.startDate.format('YYYY-MM-DD');
      if (values.startDate.isSameOrBefore(moment(), 'days')) {
        tempValues.status = 'STARTED';
      }
    }

    if (values?.endDate) {
      // eslint-disable-next-line no-param-reassign
      tempValues.endDate = values.endDate.format('YYYY-MM-DD');
    }

    tempValues.description = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    const ResolutionsService = new resolutionsService(values.investmentId);
    ResolutionsService.create(tempValues)
      .then((response) => {
        if (response.id) {
          if (response.status === 'AWAITING') {
            history.replace(
              `/investment/${response.investmentId}/resolutions/${response.id}/edit`
            );
          } else {
            history.replace(
              `/investment/${response.investmentId}/resolutions/${response.id}/show`
            );
          }
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleBack = () => {
    return history.goBack();
  };

  const getUserOptionLabel = (e) => {
    const pesel = e.identity && e.identity.pesel ? `(${e.identity.pesel})` : '';
    return `${e.firstName} ${e.lastName} ${pesel}`;
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const uploadCallback = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const publicFilesService = new PublicFileService();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return publicFilesService
      .create(formData)
      .then((response) => ({
        data: {
          link: `${baseUrl}${response.file}`,
        },
      }))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const editorConfig = {
    image: { uploadCallback },
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => handleBack()}
          title='Dodaj uchwałę'
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            initialValues={{
              addDate: moment(),
              status: 'AWAITING',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <>
              <Card className='form-create-main-card'>
                <div className='create-form-wrapper'>
                  <Row>
                    <Col xs={20} offset={2}>
                      <div className='form-section'>
                        <h2>Dane podstawowe</h2>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='name'
                              rules={[
                                {
                                  required: true,
                                  message: 'Proszę uzupełnić nazwę',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Nazwa' />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='investmentId'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <SearchBox
                                placeholder='Spółdzielnia/Wspólnota'
                                size='large'
                                resource={new housingAssociationsService()}
                                filters={{
                                  types: [
                                    'HOUSING_ASSOCIATION',
                                    'HOUSING_COMMUNITY',
                                  ],
                                }}
                                resourceField='name'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className='form-section'>
                        <h2>Czas trwania</h2>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='addDate'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <DatePicker
                                size='large'
                                placeholder='Data dodania'
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={0} sm={12}>
                            {/* <Form.Item
                              name='status'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                              ]}
                            >
                              <Select placeholder='Status' size='large'>
                                <Option value='AWAITING'>
                                  <span className='dot yellow' />
                                  Oczekuje
                                </Option>
                                <Option value='STARTED'>
                                  <span className='dot green' />W toku
                                </Option>
                                <Option value='FINISHED'>
                                  <span className='dot grey' />
                                  Zakończona
                                </Option>
                              </Select>
                            </Form.Item> */}
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='startDate'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                                {
                                  validator(rule, value) {
                                    return new Promise((resolve, reject) => {
                                      if (!value) {
                                        resolve();
                                      }

                                      const inputedEndDate =
                                        form.getFieldValue('endDate');

                                      if (value?.isBefore(moment(), 'day')) {
                                        reject(
                                          'Data rozpoczęcia nie może być wcześniej niż dziś'
                                        );
                                      } else if (
                                        inputedEndDate &&
                                        value.isSameOrAfter(
                                          inputedEndDate,
                                          'days'
                                        )
                                      ) {
                                        reject(
                                          'Data rozpoczęcia musi nastąpić min. 1 dzień przed datą zakończenia'
                                        );
                                      } else {
                                        resolve();
                                      }
                                    });
                                  },
                                },
                              ]}
                            >
                              <DatePicker
                                size='large'
                                placeholder='Data rozpoczęcia'
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name='endDate'
                              rules={[
                                { required: true, message: 'Pole wymagane' },
                                {
                                  validator(rule, value) {
                                    return new Promise((resolve, reject) => {
                                      if (!value) {
                                        resolve();
                                      }

                                      const inputedStartDate =
                                        form.getFieldValue('startDate');

                                      if (!inputedStartDate) {
                                        resolve();
                                      }

                                      if (value?.isBefore(moment(), 'day')) {
                                        reject(
                                          'Data zakończenia nie może być wcześniej niż dziś'
                                        );
                                      } else if (
                                        value?.isSameOrBefore(
                                          inputedStartDate,
                                          'day'
                                        )
                                      ) {
                                        reject(
                                          'Data zakończenia musi nastąpić min. 1 dzień po dacie rozpoczęcia'
                                        );
                                      } else {
                                        resolve();
                                      }
                                    });
                                  },
                                },
                              ]}
                            >
                              <DatePicker
                                size='large'
                                placeholder='Data zakończenia'
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      <div className='form-section'>
                        <h2>Osoba odpowiedzialna</h2>
                        <Row gutter={20}>
                          <Col xs={24}>
                            <Form.Item name='responsibleWorkers'>
                              <SearchBox
                                placeholder='Użytkownik'
                                size='large'
                                resource={workersService}
                                filters={{ role: 'ROLE_WORKER' }}
                                resourceField='firstOrLastName'
                                optionValue={(e) => getUserOptionLabel(e)}
                                mode='multiple'
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <div className='form-section'>
                        <h2>Szczegóły</h2>
                        <Row gutter={20}>
                          <Col xs={24}>
                            <Editor
                              localization={{ locale: 'pl' }}
                              editorState={editorState}
                              wrapperClassName='demo-wrapper'
                              editorClassName='demo-editor'
                              toolbar={editorConfig}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
              <Divider />
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleBack}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      Dodaj
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
