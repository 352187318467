import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import Icon from '../../components/Icon';

import housingAssociationsService from '../../services/housingAssociationsService';
import housingCommunitiesService from '../../services/housingCommunitiesService';
import memberAssociationsService from '../../services/memberAssociationsService';
import memberTenantAssociationsService from '../../services/memberTenantAssociationsService';
import Spin from '../../components/Spin';

const InvestmentMenu = ({ userData }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [selectedInvestment, setSelectedInvestment] = useState();

  const [housingAssociations, setHousingAssociations] = useState([]);
  const [housingCommunities, setHousingCommunities] = useState([]);
  const [items, setItems] = useState();

  // const [housingAssociations, setHousingAssociations] = useState([]);
  // const [housingCommunities, setHousingCommunities] = useState([]);
  // const [items, setItems] = useState([
  //   ...(housingAssociations.length !== 0
  //     ? [
  //         {
  //           type: 'group',
  //           label: 'Spółdzielnie',
  //           children: housingAssociations,
  //         },
  //       ]
  //     : []),
  //   ...(housingCommunities.length !== 0
  //     ? [
  //         {
  //           type: 'group',
  //           label: 'Wspólnoty',
  //           children: housingCommunities,
  //         },
  //       ]
  //     : []),
  // ]);

  useEffect(() => {
    const promises = [];

    if (userData.roles.includes('ROLE_COMMUNITY_ADMIN')) {
      if (userData.communityAdminPermissions) {
        if (
          userData.communityAdminPermissions.includes('HOUSING_ASSOCIATION')
        ) {
          const HousingAssociationsService = new housingAssociationsService();
          promises.push(
            HousingAssociationsService.getList({
              types: 'HOUSING_ASSOCIATION',
              sortField: 'position',
              sortOrder: 'ascend',
            }).then((data) => {
              setHousingAssociations(data.content);
            })
          );
        }

        if (userData.communityAdminPermissions.includes('HOUSING_COMMUNITY')) {
          const HousingCommunitiesService = new housingCommunitiesService();
          promises.push(
            HousingCommunitiesService.getList({
              types: 'HOUSING_COMMUNITY',
              sortField: 'position',
              sortOrder: 'ascend',
            }).then((data) => {
              setHousingCommunities(data.content);
            })
          );
        }
      }
    } else if (userData.roles.includes('ROLE_COMMUNITY_MEMBER')) {
      const MemberAssociationsService = new memberAssociationsService();
      promises.push(
        MemberAssociationsService.getList({
          types: 'HOUSING_ASSOCIATION',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingAssociations(data.content);
        })
      );

      promises.push(
        MemberAssociationsService.getList({
          types: 'HOUSING_COMMUNITY',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingCommunities(data.content);
        })
      );
    } else if (userData.roles.includes('ROLE_COMMUNITY_MEMBER_TENANT')) {
      const MemberTenantAssociationsService =
        new memberTenantAssociationsService();

      promises.push(
        MemberTenantAssociationsService.getList({
          types: 'HOUSING_ASSOCIATION',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingAssociations(data.content);
        })
      );

      promises.push(
        MemberTenantAssociationsService.getList({
          types: 'HOUSING_COMMUNITY',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingCommunities(data.content);
        })
      );
    } else if (userData.roles.includes('ROLE_WORKER')) {
      const HousingAssociationsService = new housingAssociationsService();
      promises.push(
        HousingAssociationsService.getList({
          types: 'HOUSING_ASSOCIATION',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingAssociations(data.content);
        })
      );

      const HousingCommunitiesService = new housingCommunitiesService();
      promises.push(
        HousingCommunitiesService.getList({
          types: 'HOUSING_COMMUNITY',
          sortField: 'position',
          sortOrder: 'ascend',
        }).then((data) => {
          setHousingCommunities(data.content);
        })
      );
    }

    Promise.all(promises).then(() => {
      setLoading(false);
    });
  }, [location]);

  useEffect(() => {
    if (!loading && housingAssociations && housingCommunities) {
      setItems([
        ...(housingAssociations?.length !== 0
          ? [
              {
                type: 'group',
                label: 'Spółdzielnie',
                children: housingAssociations.map((el) => ({
                  label: el.name,
                  key: el.id,
                })),
              },
            ]
          : []),
        ...(housingCommunities?.length !== 0
          ? [
              {
                type: 'group',
                label: 'Wspólnoty',
                children: housingCommunities.map((el) => ({
                  label: el.name,
                  key: el.id,
                })),
              },
            ]
          : []),
      ]);

      const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');

      let isInvestmentOwner = false;

      if (choosenInvestmentId) {
        for (let inv of housingAssociations.concat(housingCommunities)) {
          if (inv.id === JSON.parse(choosenInvestmentId)) {
            isInvestmentOwner = true;
          }
        }

        if (isInvestmentOwner) {
          setSelectedInvestment(JSON.parse(choosenInvestmentId));
        }
      }

      if (!isInvestmentOwner) {
        if (housingAssociations.length !== 0) {
          setSelectedInvestment(housingAssociations[0].id);
          localStorage.setItem(
            'choosenInvestmentId',
            JSON.stringify(housingAssociations[0].id)
          );
        } else if (housingCommunities.length !== 0) {
          setSelectedInvestment(housingCommunities[0].id);
          localStorage.setItem(
            'choosenInvestmentId',
            JSON.stringify(housingCommunities[0].id)
          );
        }
      }
    }
  }, [loading]);

  const menu = () => (
    <Menu
      className='choose-investment-menu'
      mode='horizontal'
      items={items}
      selectedKeys={selectedInvestment}
      selectable={true}
      onSelect={(item) => {
        setSelectedInvestment(item.key);
        localStorage.setItem('choosenInvestmentId', JSON.stringify(item.key));
        window.location.reload(true);
      }}
    ></Menu>
  );

  return loading ? (
    <Button className='button-no-border header-options-button choose-investment-btn'>
      <Spin />
    </Button>
  ) : housingAssociations?.length === 0 &&
    housingCommunities?.length === 0 ? null : (
    <Dropdown
      trigger='click'
      overlay={menu}
      overlayStyle={{ width: 175 }}
      onOpenChange={() => {
        setMenuExpanded((curr) => !curr);
      }}
      className='choose-investment-dropdown'
    >
      <Button className='button-no-border header-options-button choose-investment-btn'>
        <span className='investment-name'>
          {housingAssociations?.find((inv) => inv.id === selectedInvestment)
            ?.name ||
            housingCommunities?.find((inv) => inv.id === selectedInvestment)
              ?.name || (
              <span style={{ color: '#c0d6e5' }}>Wybierz inwestycję</span>
            )}
        </span>

        {menuExpanded ? (
          <Icon name={'arrow-simply-top'} />
        ) : (
          <Icon name={'arrow-simply-down'} />
        )}
      </Button>
    </Dropdown>
  );
};

export default InvestmentMenu;
