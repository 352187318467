import React, { useEffect, useState } from 'react';
import { Col, Form } from 'antd';
import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import SettlementsGroupsService from '../../services/settlementsGroupsService';
import userService from '../../services/userService';
import Spin from '../../components/Spin';

const { DatePicker } = Input;
const columns = [
  {
    title: 'Nazwisko',
    dataIndex: 'brokerLastName',
    visible: true,
    key: 'brokerLastName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Imię',
    dataIndex: 'brokerFirstName',
    visible: true,
    key: 'brokerFirstName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Data rozliczenia',
    dataIndex: 'createDate',
    visible: true,
    key: 'createDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Suma',
    dataIndex: 'price',
    visible: true,
    key: 'price',
    sorter: false,
    summary: true,
    render: (text, record) => (<Price value={record.price} showEmpty />),
  },
];

const List = () => {
  const [loading, setLoading] = useState(true);
  const [serviceType, setServiceType] = useState('');

  const CustomCreate = () => ('');

  useEffect(() => {
    userService.getInfo().then((data) => {
      let type = 'user/settlement/group';
      const { roles } = data;

      if (roles.includes('ROLE_DEVELOPER')) {
        type = 'vendor/settlement/group';
      }
      setServiceType(type);
      setLoading(false);
    });
  }, []);
  const customFilters = [];

  customFilters.push(
    <Col xs={24} sm={12} key="createDate">
      <Form.Item
        name="createDate"
      >
        <DatePicker size="large" placeholder="Data rozliczenia" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );
  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <Datagrid
          title="Lista rozliczeń"
          resource="settlements"
          dataProvider={new SettlementsGroupsService(serviceType)}
          columns={columns}
          customCreate={<CustomCreate />}
          customBaseUrlKey="settlementGroups"
          hideEdit
          customFilters={customFilters}
        />
      )}
    </div>
  );
};

export default List;
