/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../components/Icon';
import memberTenantAssociationsService from '../../../services/memberTenantAssociationsService';

export const ContactsInvestmentBox = ({ investmentId }) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const MemberTenantAssociationsService = new memberTenantAssociationsService(
      investmentId
    );

    MemberTenantAssociationsService.getContacts(investmentId).then((data) => {
      setContacts(data);
    });
  }, [investmentId]);

  if (!contacts || contacts.length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='user' />
        Dodatkowe kontakty
      </h2>
      <div className='content'>
        {contacts.map((contact) => (
          <Row gutter={20} style={{ marginBottom: 20 }}>
            <Col xs={24}>
              <div className='owner-box-content'>
                <div className='item'>
                  {contact.firstName} {contact.lastName}
                </div>
                <div className='item'>
                  <a
                    className='datagrid-link'
                    href={`tel:${contact.phone}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {contact.phone}
                  </a>
                </div>
                <div className='item'>
                  <a
                    className='datagrid-link'
                    href={`mailto:${contact.email}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {contact.email}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};
