import React, { useEffect, useState } from 'react';
import {
  PageHeader, Button, Col, Form,
} from 'antd';
import { useParams, useHistory, Link } from 'react-router-dom';
import userSettlementsGroupService from '../../services/userSettlementsGroupService';
import workersService from '../../services/workersService';
import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';

const { DatePicker } = Input;
const columns = [
  {
    title: 'Data rozliczenia',
    dataIndex: 'createDate',
    visible: true,
    key: 'createDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Suma',
    dataIndex: 'price',
    visible: true,
    key: 'price',
    sorter: false,
    summary: true,
    render: (text, record) => (<Price value={record.price} showEmpty />),
  },
];

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const UserSettlementsGroupService = new userSettlementsGroupService(id);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    workersService.getOne(id).then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, [id]);

  const CustomCreate = () => (
    <Link to="settlements/history">
      <Button type="primary">
        Historia
        <Icon name="history-list" />
      </Button>
    </Link>
  );

  const customFilters = [];

  customFilters.push(
    <Col sm={12} key="createDate">
      <Form.Item
        name="createDate"
      >
        <DatePicker size="large" placeholder="Data rozliczenia" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Lista rozliczeń:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/workers/${id}/show`)}
          title={<HeaderTitle />}
        >
          <div className="premises-list">
            <Datagrid
              resource={`investments/${id}/premises`}
              dataProvider={UserSettlementsGroupService}
              columns={columns}
              customCreate={<CustomCreate />}
              customBaseUrlKey="workerSettlements"
              hideRowActions
              disableSelection
              customFilters={customFilters}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
