/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import userService from '../../../services/userService';
import housingAssociationsService from '../../../services/housingAssociationsService';
import memberAssociationsService from '../../../services/memberAssociationsService';
import { Icon, Spin } from '../../../components';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const FaultTemplate = () => {
  const { investmentId, faultGroupId, faultId } = useParams();
  const history = useHistory();
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [developer, setDeveloper] = useState();

  useEffect(() => {
    userService.getInfo().then((data) => {
      const { roles, ownerRoles } = data;
      setUserRoles(roles);

      if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
        const MemberAssociationsService = new memberAssociationsService();

        MemberAssociationsService.getDetails(investmentId).then((data) => {
          if (data?.developer) {
            setDeveloper(data.developer);
          }
        });
      }

      if (
        roles.includes('ROLE_COMMUNITY_ADMIN') ||
        (roles?.includes('ROLE_WORKER') &&
          ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        const HousingAssociationsService = new housingAssociationsService();

        HousingAssociationsService.getDetails(investmentId).then((data) => {
          if (data?.investment?.developer) {
            setDeveloper(data?.investment?.developer);
          }
        });
      }

      setLoading(false);
    });
  }, [faultId, investmentId, faultGroupId]);

  const handleBack = () => {
    const {
      location: { state },
    } = history;

    return history.goBack();
  };

  return (
    <div className='show-layout faults-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Przekaż usterkę do Dewelopera'
          className='page-header-extra-2'
        >
          <Card className='form-create-main-card'>
            <div className='create-form-wrapper'>
              <Row>
                <Col xs={24} className='housing-fault-template-wrapper'>
                  <div
                    style={{
                      color: '#ec434c',
                      backgroundColor: '#fdeced',
                      padding: 12,
                      paddingLeft: 18,
                      paddingRight: 18,
                      marginBottom: 20,
                      borderRadius: 10,
                      fontWeight: 700,
                      width: 'fit-content',
                    }}
                  >
                    <Icon name='important' style={{ marginRight: 5 }} />
                    Deweloper nie posiada konta w systemie Manage.
                  </div>

                  <div style={{ fontSize: 14, marginBottom: 40 }}>
                    Deweloper, który zarządzą Twoją spółdzielnią / wspólnotą nie
                    posiada konta w systemie Manage. Aby przekazać usterkę,
                    pobierz dokument z szablonem usterki z sekcji poniżej
                    “Szablon usterki” oraz zapoznaj się z notatką od
                    Administratora.
                  </div>
                  <Row gutter={20}>
                    <Col xs={24} xl={12}>
                      {developer && (
                        <div className='right investments-show-right'>
                          <Card
                            title={
                              <h2 className='show-layout-card-header'>
                                <Icon name='developer' />
                                Dane Dewelopera
                              </h2>
                            }
                            className='informations'
                          >
                            <Row>
                              <Col xs={24} style={{ marginBottom: '20px' }}>
                                <div className='title'>Dane firmowe:</div>
                                <div className='idNumber'>{developer.name}</div>
                                <div className='place'>
                                  {`ul. ${developer.address.street} ${
                                    developer.address.number || ''
                                  }${
                                    developer.address.local
                                      ? `/${developer.address.local}`
                                      : ','
                                  }`}
                                </div>
                                <div className='city'>{`${developer.address.zip} ${developer.address.city}, ${developer.address.country}`}</div>
                              </Col>
                              <Col xs={24} style={{ marginBottom: '20px' }}>
                                <div className='title'>Dane kontaktowe:</div>
                                <div className='idNumber'>
                                  <a
                                    className='show-link'
                                    href={`mailto:${developer.email}`}
                                  >
                                    {developer.email}
                                  </a>
                                </div>
                                {developer.mobile && (
                                  <div className='idNumber'>
                                    <a
                                      className='show-link'
                                      href={`tel:${developer.mobile}`}
                                    >
                                      {developer.mobile}
                                    </a>
                                  </div>
                                )}
                                {developer.phone && (
                                  <div className='idNumber'>
                                    <a
                                      className='show-link'
                                      href={`tel:${developer.phone}`}
                                    >
                                      {developer.phone}
                                    </a>
                                  </div>
                                )}
                                {developer.website && (
                                  <div className='idNumber'>
                                    {developer.website}
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      )}
                    </Col>
                    <Col xs={24} xl={12}>
                      <div className='right investments-show-right'>
                        <Card
                          title={
                            <h2 className='show-layout-card-header'>
                              <Icon name='file' />
                              Szablon usterki
                            </h2>
                          }
                          className='informations'
                        >
                          <div>
                            Tutaj treść notatki od Administratora. Lorem ipsum
                            dolor sit amet consectetur adipiscing elit Ut et
                            massa mi. Aliquam in hendrerit urna. Pellentesque
                            sit
                          </div>
                          {developer?.faultTemplateFile && (
                            <div>
                              <a
                                href={`${baseUrl}${developer.faultTemplateFile}`}
                                target='_blank'
                                download
                              >
                                <Button
                                  type='primary counters-edit-btn'
                                  style={{ fontWeight: 700, marginTop: 20 }}
                                >
                                  <Icon
                                    name='download'
                                    style={{ marginRight: 5 }}
                                  />
                                  Pobierz szablon usterki
                                </Button>
                              </a>
                            </div>
                          )}
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default FaultTemplate;
