import BaseService from './baseService';
import httpService from '../helpers/httpService';

class TenantPremisesService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/tenant/investment/${investmentId}/community/premises`;
  }

  async getInvestment(investmentId) {
    return httpService.get(
      `/api/tenant/investment/${investmentId}/community`,
      null,
      true
    );
  }

  async getContacts(investmentId, premisesId) {
    return httpService.get(
      `/api/tenant/investment/${investmentId}/premises/${premisesId}/contact`,
      null,
      true
    );
  }

  async getAttachments(investmentId, premisesId) {
    return httpService.get(
      `/api/tenant/investment/${investmentId}/community/premises/${premisesId}/attachments`,
      null,
      true
    );
  }

  async getOneAttachment(investmentId, premisesId, attachmentId) {
    return httpService.get(
      `/api/tenant/investment/${investmentId}/community/premises/${premisesId}/attachments/${attachmentId}`,
      null,
      true
    );
  }
}

export default TenantPremisesService;
