import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import PaymentsServiceS from '../../services/paymentsService';
import investmentsService from '../../services/investmentsService';
import PremisesServiceS from '../../services/premisesService';
import AgreementsServiceS from '../../services/agreementsService';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';

const {
  DatePicker, Select, TextArea, PriceInput,
} = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [investments, setInvestments] = useState([]);
  const [premises, setPremises] = useState([]);
  const [agreements, setAgreements] = useState([]);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: 'RENT',
      pagination: { pageSize: 1000 },
    }).then((data) => {
      setInvestments(data.content);
    });
  }, []);

  const onFinish = (values) => {
    if (values?.expirationDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        expirationDate: values.expirationDate.format('YYYY-MM-DD'),
      };
    }
    const PaymentsService = new PaymentsServiceS(
      values.investmentId, values.premisesId, values.agreementId, null,
    );
    PaymentsService.create(values).then((response) => {
      if (response.id) {
        history.push('/payments');
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onInvestmentChange = (investmentId) => {
    const PremisesService = new PremisesServiceS(investmentId);
    PremisesService.getList({ pagination: { pageSize: 1000 } }).then((premisesData) => {
      form.setFieldsValue({ premisesId: null, agreementId: null });
      setPremises(premisesData.content);
    });
  };

  const onPremiseChange = (premisesId) => {
    if (!premisesId) {
      setAgreements([]);
      form.setFieldsValue({ agreementId: null });
    } else {
      const formValues = form.getFieldsValue();
      if (formValues.investmentId) {
        const AgreementsService = new AgreementsServiceS(formValues.investmentId, premisesId);
        AgreementsService.getList().then((agreementsData) => {
          setAgreements(agreementsData);
        });
      }
    }
  };

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const premisesOptions = premises.map((premise) => (
    <Option value={premise.id} key={premise.id}>{premise.name}</Option>
  ));

  const agreementsOptions = agreements.map((client) => (
    <Option value={client.id} key={client.id}>{client.name}</Option>
  ));

  const handleCancel = () => {
    history.push('/payments');
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/payments')}
        title="Dodaj płatność"
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <div className="create-form-wrapper">
                <Row>
                  <Col xs={20} offset={2}>
                    <div className="form-section">
                      <h2>Nowa płatność</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="expirationDate"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <DatePicker size="large" placeholder="Termin płatności" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="status"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Status"
                              allowClear
                              size="large"
                            >
                              <Option value="NEW">
                                <span className="dot yellow" />
                                Nowa
                              </Option>
                              <Option value="PAID">
                                <span className="dot green" />
                                Opłacona
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="investmentId"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Inwestycja"
                              allowClear
                              size="large"
                              onChange={(e) => onInvestmentChange(e)}
                            >
                              {investmentsOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="premisesId"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Lokal"
                              allowClear
                              size="large"
                              onChange={(e) => onPremiseChange(e)}
                            >
                              {premisesOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="agreementId"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <Select
                              placeholder="Umowa"
                              allowClear
                              size="large"
                            >
                              {agreementsOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="amount"
                            rules={[{ required: true, message: 'Pole wymagane' }]}
                          >
                            <PriceInput size="large" placeholder="Kwota" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className="form-section">
                      <h2>Szczegóły</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={24}>
                          <Form.Item
                            name="description"
                          >
                            <TextArea rows={4} placeholder="Opis" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
