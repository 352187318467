import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  message,
  Card,
  Tooltip,
  Menu,
} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import investmentsService from '../../../services/investmentsService';
import settlementTemplatesService from '../../../services/settlementTemplatesService';
import settlementTemplatesGroupsService from '../../../services/settlementTemplatesGroupsService';
import Icon from '../../../components/Icon';
import Input from '../../../components/InputComponent';
import Spin from '../../../components/Spin';
import CountrySelect from '../../../components/CountrySelect';
import MessagePopup from '../../../components/MessagePopup';
import Tabs from '../../../components/Tabs';
import { deleteConfirm } from '../../../components';
import SmallList from '../../../components/SmallList';
import getRepeatabilityStatus from '../../../helpers/repeatabilityMap';
import premiseTypeMap from '../../../helpers/premiseTypeMap';

const { InputNumberInt, Switch } = Input;
const { Select } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const activeColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Typ lokalu',
    dataIndex: 'premisesTypes',
    editable: false,
    render: (text, record) => {
      const types = record?.premisesTypes?.map((premiseType) =>
        premiseTypeMap(premiseType)
      );
      const output = types?.join(', ');

      if (output?.length > 35) {
        return <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>;
      }

      return output;
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const awaitingColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const expiredColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Typ lokalu',
    dataIndex: 'premisesTypes',
    editable: false,
    render: (text, record) => {
      const types = record?.premisesTypes.map((premiseType) =>
        premiseTypeMap(premiseType)
      );
      const output = types.join(', ');

      if (output.length > 35) {
        return <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>;
      }

      return output;
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const activeIndividualColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Odbiorcy',
    dataIndex: 'premisesIds',
    editable: false,
    render: (text, record) => {
      const output = `Mieszkania: ${text?.length || '0'}`;

      if (output?.length > 35) {
        return <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>;
      }

      return output;
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const awaitingIndividualColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Odbiorcy',
    dataIndex: 'premisesIds',
    editable: false,
    render: (text, record) => {
      const output = `Mieszkania: ${text?.length || '0'}`;

      if (output?.length > 35) {
        return <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>;
      }

      return output;
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const expiredIndividualColumns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Odbiorcy',
    dataIndex: 'premisesIds',
    editable: false,
    render: (text, record) => {
      const output = `Mieszkania: ${text?.length || '0'}`;

      if (output?.length > 35) {
        return <Tooltip title={output}>{output.slice(0, 35) + '...'}</Tooltip>;
      }

      return output;
    },
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) =>
      record.withoutExpireDate ? (
        <div style={{ color: '#b1cade' }}>Do odwołania</div>
      ) : (
        record.dateTo
      ),
  },
  {
    title: 'Powtarzaj',
    dataIndex: 'repeatability',
    editable: false,
    render: (text, record) => getRepeatabilityStatus(text),
  },
];

const useQuery = () => new URLSearchParams(useLocation().search);

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [investment, setInvestment] = useState({});
  const [settlementData, setSettlementData] = useState();
  const [loading, setLoading] = useState(true);
  const [activeCostsGroups, setActiveCostsGroups] = useState([]);
  const [awaitingCostsGroups, setAwaitingCostsGroups] = useState([]);
  const [expiredCostsGroups, setExpiredCostsGroups] = useState([]);

  const [activeIndividualCostsGroups, setActiveIndividualCostsGroups] =
    useState([]);
  const [awaitingIndividualCostsGroups, setAwaitingIndividualCostsGroups] =
    useState([]);
  const [expiredIndividualCostsGroups, setExpiredIndividualCostsGroups] =
    useState([]);

  const query = useQuery();

  let defaultActiveTab = 'form';

  if (query.get('tab') != null) {
    defaultActiveTab = query.get('tab');
  }

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    const SettlementTemplatesService = new settlementTemplatesService(id);
    SettlementTemplatesService.getList().then((data) => {
      setSettlementData(data);
      form.setFieldsValue(data);
      setLoading(false);
    });
  }, [id]);

  const refresh = () => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);
    SettlementTemplatesGroupsService.getList({ type: 'GROUP' }).then((data) => {
      const todaysDate = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');

      setExpiredCostsGroups(
        data.filter(
          (item) =>
            !item.withoutExpireDate &&
            moment(new Date(item.dateTo), 'YYYY-MM-DD').format('YYYY-MM-DD') <
              todaysDate
        )
      );

      setActiveCostsGroups(
        data.filter(
          (item) =>
            (item.withoutExpireDate ||
              moment(new Date(item.dateTo), 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ) >= todaysDate) &&
            moment(new Date(item.dateFrom), 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            ) <= todaysDate
        )
      );

      setAwaitingCostsGroups(
        data.filter(
          (item) =>
            moment(new Date(item.dateFrom), 'YYYY-MM-DD').format('YYYY-MM-DD') >
            todaysDate
        )
      );

      setLoading(false);
    });
  };

  const refresh2 = () => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);
    SettlementTemplatesGroupsService.getList({ type: 'INDIVIDUAL' }).then(
      (data) => {
        const todaysDate = moment(new Date(), 'YYYY-MM-DD').format(
          'YYYY-MM-DD'
        );

        setExpiredIndividualCostsGroups(
          data.filter(
            (item) =>
              !item.withoutExpireDate &&
              moment(new Date(item.dateTo), 'YYYY-MM-DD').format('YYYY-MM-DD') <
                todaysDate
          )
        );

        setActiveIndividualCostsGroups(
          data.filter(
            (item) =>
              (item.withoutExpireDate ||
                moment(new Date(item.dateTo), 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                ) >= todaysDate) &&
              moment(new Date(item.dateFrom), 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ) <= todaysDate
          )
        );

        setAwaitingIndividualCostsGroups(
          data.filter(
            (item) =>
              moment(new Date(item.dateFrom), 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              ) > todaysDate
          )
        );

        setLoading(false);
      }
    );
  };

  useEffect(() => {
    refresh();
    refresh2();
  }, [id]);

  const onFinish = (values) => {
    const tempValues = {
      ...settlementData,
      ...values,
    };

    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.update(tempValues)
      .then((response) => {
        if (response.id) {
          history.replace(`/investment/${id}/housingSettlements`);
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.delete()
      .then(() => {
        history.replace(`/investment/${id}/housingSettlements`);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleCancel = () => {
    history.goBack();
  };

  const onFieldsChange = (fieldChanged) => {
    const fieldChangedNames = fieldChanged
      .map((field) => field.name)
      .reduce((a, b) => a.concat(b));
    if (fieldChangedNames.includes('investmentAddress')) {
      const investmentAddress = form.getFieldValue('investmentAddress');
      if (investmentAddress) {
        form.setFieldsValue({
          companyName: investment.name,
          address: investment.address,
        });
      } else {
        form.setFieldsValue({
          companyName: undefined,
          address: undefined,
        });
      }
    }
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Edytujesz</span>;
    }
    return (
      <span>
        Edytujesz:{' '}
        <span className='header-subtitle'>
          Rozliczenia
          {investment.name}
        </span>
      </span>
    );
  };

  const CustomAddButton = ({ status, individual = false }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button
      className='button-secondary'
      onClick={() => {
        {
          status
            ? history.replace(
                `/investment/${id}/housingSettlements/${
                  individual ? 'individualCostsGroups' : 'costsGroups'
                }/create?status=${status}`
              )
            : history.replace(
                `/investment/${id}/housingSettlements/${
                  individual ? 'individualCostsGroups' : 'costsGroups'
                }/create`
              );
        }
      }}
      style={{ width: '45px', height: '45px' }}
    >
      <Icon name='plus' />
    </Button>
  );

  const onRemove = (entityId) => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.delete(entityId)
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const onRemove2 = (entityId) => {
    const SettlementTemplatesGroupsService =
      new settlementTemplatesGroupsService(id);

    SettlementTemplatesGroupsService.delete(entityId)
      .then((response) => {
        refresh2();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => history.goBack()}
          title={<HeaderTitle />}
        >
          <Form
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            autoComplete='off'
            form={form}
          >
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey={defaultActiveTab} tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <h2>Status rozliczeń</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='active'
                                label='Aktywne'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <h2>Podstawowe</h2>
                              <Form.Item
                                name='settlementSendDay'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <InputNumberInt
                                  size='large'
                                  placeholder='Dzień wysyłania rozliczenia'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Płatności</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='bankNumber'
                                rules={[
                                  // { required: true, message: 'Pole wymagane' },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        getFieldValue('bankNumber').length !==
                                        26
                                      ) {
                                        return Promise.reject(
                                          'Rachunek bankowy składa się z 26 liczb!'
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumberInt
                                  size='large'
                                  placeholder='Rachunek do wpłat'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='bankTransferTitle'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <Input
                                  size='large'
                                  placeholder='Tytuł przelewu'
                                  addonAfter='_numer_lokalu_data'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='daysToPayment'
                                rules={[
                                  { required: true, message: 'Pole wymagane' },
                                ]}
                                style={{ marginBottom: 0 }}
                              >
                                <Select
                                  placeholder='Termin płatności'
                                  size='large'
                                >
                                  <Option value={7}>7 dni</Option>
                                  <Option value={14}>14 dni</Option>
                                  <Option value={21}>21 dni</Option>
                                  <Option value={28}>28 dni</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className='form-section'>
                          <h2>Adres</h2>
                          <div className='premise-investment-address'>
                            <Row gutter={20}>
                              <Col xs={12}>
                                Pobierz dane{' '}
                                {investment?.type === 'HOUSING_ASSOCIATION'
                                  ? 'spółdzielni'
                                  : investment?.type === 'HOUSING_COMMUNITY'
                                  ? 'wspólnoty'
                                  : 'inwestycji'}
                              </Col>
                              <Col xs={12} style={{ textAlign: 'right' }}>
                                <Form.Item
                                  name='investmentAddress'
                                  valuePropName='checked'
                                >
                                  <Switch />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='companyName'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Nazwa firmy' />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={12} />
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'number']}>
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item name={['address', 'local']}>
                                <Input size='large' placeholder='Lokal' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Koszty ogólne' key='costsGroups'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2} style={{ marginBottom: 0 }}>
                        <SmallList
                          data={activeCostsGroups}
                          columns={activeColumns}
                          title='Bieżące'
                          deleteAction={onRemove}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/costsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          customAddButton={<CustomAddButton status='ACTIVE' />}
                          hideEdit
                        />
                      </Col>

                      <Col
                        xs={20}
                        offset={2}
                        style={{ marginBottom: 0, marginTop: 30 }}
                      >
                        <SmallList
                          data={awaitingCostsGroups}
                          columns={awaitingColumns}
                          title='Oczekujące'
                          deleteAction={onRemove}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/costsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          customAddButton={
                            <CustomAddButton status='AWAITING' />
                          }
                          hideEdit
                        />
                      </Col>

                      <Col xs={20} offset={2} style={{ marginTop: 30 }}>
                        <SmallList
                          data={expiredCostsGroups}
                          columns={expiredColumns}
                          title='Archiwum'
                          deleteAction={onRemove}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/costsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          hideEdit
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab='Koszty indywidualne' key='individualCostsGroups'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2} style={{ marginBottom: 0 }}>
                        <SmallList
                          data={activeIndividualCostsGroups}
                          columns={activeIndividualColumns}
                          title='Bieżące'
                          deleteAction={onRemove2}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/individualCostsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          customAddButton={
                            <CustomAddButton
                              status='ACTIVE'
                              individual={true}
                            />
                          }
                          hideEdit
                        />
                      </Col>

                      <Col
                        xs={20}
                        offset={2}
                        style={{ marginBottom: 0, marginTop: 30 }}
                      >
                        <SmallList
                          data={awaitingIndividualCostsGroups}
                          columns={awaitingIndividualColumns}
                          title='Oczekujące'
                          deleteAction={onRemove2}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/individualCostsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          customAddButton={
                            <CustomAddButton
                              status='AWAITING'
                              individual={true}
                            />
                          }
                          hideEdit
                        />
                      </Col>

                      <Col xs={20} offset={2} style={{ marginTop: 30 }}>
                        <SmallList
                          data={expiredIndividualCostsGroups}
                          columns={expiredIndividualColumns}
                          title='Archiwum'
                          deleteAction={onRemove2}
                          customMenuActions={(record) => (
                            <Menu.Item
                              key='96'
                              onClick={() => {
                                history.replace(
                                  `/investment/${id}/housingSettlements/individualCostsGroups/${record.id}/edit`
                                );
                              }}
                              icon={
                                <Icon
                                  name='edit'
                                  style={{ marginRight: '5px' }}
                                />
                              }
                            >
                              Edytuj
                            </Menu.Item>
                          )}
                          hideEdit
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Edit;
