import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberSettlementsService extends BaseService {
  constructor() {
    super();
    this.url = `/api/user/community/settlements`;
  }

  async getBalance() {
    return httpService.get(
      `/api/user/community/settlements/balance`,
      null,
      true
    );
  }

  async getTenantBalance(tenantId) {
    return httpService.get(
      `/api/user/community/tenants/${tenantId}/settlements/balance`,
      null,
      true
    );
  }

  async payBalance() {
    return httpService.post(
      `/api/user/community/settlements/balance/payment`,
      null,
      true
    );
  }

  async payTenantsBalance() {
    return httpService.post(
      `/api/user/community/settlements/balance/tenants/payment`,
      null,
      true
    );
  }
}

export default new MemberSettlementsService();
