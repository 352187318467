/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button, Row, Col, Tooltip,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import premisesService from '../../../services/premisesService';
import investmentsService from '../../../services/investmentsService';
import workersService from '../../../services/workersService';
import tenantChangesService from '../../../services/tenantChangeService';
import userService from '../../../services/userService';
import tenantChangesAttachmentsService from '../../../services/tenantChangesAttachmentsService';
import tenantChangesFinanceStatusMap from '../../../helpers/tenantChangesFinanceStatusMap';
import Icon from '../../../components/Icon';
import Price from '../../../components/Price';
import SmallList from '../../../components/SmallList';
import Empty from '../../../components/Empty';
import tenantChangesStatusMap from '../../../helpers/tenantChangesStatusMap';
import Spin from '../../../components/Spin';

const Dot = (props) => {
  const { status } = props;
  let color = 'grey';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const InfoBox = ({
  tenantChange, investment, premise, worker,
}) => {
  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
      render: (text, record) => (`${record.firstName} ${record.lastName}`),
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      render: (text, record) => (<span className="premises-broker-phone">{record.mobile}</span>),
    },
    {
      title: 'Pesel',
      dataIndex: 'percent',
      render: (text, record) => (record.identity ? record.identity.pesel : ''),
    },
  ];

  return (
    <div className="premises-show-box info-box">
      <h2>
        <Icon name="more-info" />
        Informacje
      </h2>
      <div className="content">
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Inwestycja:</Col>
          <Col xs={24} sm={10} className="value">
            {investment.name}
          </Col>
        </Row>
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Lokal:</Col>
          <Col xs={24} sm={10} className="value">{premise.name}</Col>
        </Row>
        {tenantChange.addDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Dodano:</Col>
            <Col xs={24} sm={10} className="value">{tenantChange.addDate}</Col>
          </Row>
        )}

        {tenantChange.changeDate && (
          <Row className="entry">
            <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Zmienione:</Col>
            <Col xs={24} sm={10} className="value">{tenantChange.changeDate}</Col>
          </Row>
        )}

        {worker.id && (
          <div style={{ marginTop: '20px' }}>
            <h3>Osoba odpowiedzialna</h3>
            <div className="content">
              <SmallList data={[worker]} columns={columns} showHeader={false} icon="worker" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PaymentStatusDot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'PAID') {
    color = 'green';
  }
  if (status === 'PARTIALLY_PAID') {
    color = 'yellow';
  }

  return (
    <span>
      <span className={`dot ${color}`} style={{ marginRight: '5px' }} />
      {tenantChangesFinanceStatusMap(status)}
    </span>
  );
};

const FinanceBox = ({ tenantChange }) => (
  <div className="premises-show-box info-box">
    <h2>
      <Icon name="payments" />
      Finanse
    </h2>
    <div className="content">
      {tenantChange.expirationDate && (
      <Row className="entry">
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Termin płatności:</Col>
        <Col xs={24} sm={10} className="value">{tenantChange.expirationDate}</Col>
      </Row>
      )}
      {tenantChange.paymentStatus && (
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Status:</Col>
        <Col xs={24} sm={10} className="value"><PaymentStatusDot status={tenantChange.paymentStatus} /></Col>
      </Row>
      )}
      <Row className="entry">
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Zapłacono:</Col>
        <Col xs={24} sm={10} className="value" style={{ fontWeight: 'bold' }}>
          <Price value={tenantChange.paid} showEmpty />
        </Col>
      </Row>

      {tenantChange.paymentDate && (
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Opłacono:</Col>
        <Col xs={24} sm={10} className="value">{tenantChange.paymentDate}</Col>
      </Row>
      )}
      <Row className="entry" style={{ marginBottom: '20px' }}>
        <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Kwota:</Col>
        <Col xs={24} sm={10} className="value" style={{ fontWeight: 'bold' }}>
          <Price value={tenantChange.amount} showEmpty />
        </Col>
      </Row>
    </div>
  </div>
);

const DescriptionBox = ({ tenantChange }) => (
  <div className="premises-show-box info-box description-box">
    <h2>
      <Icon name="description" />
      Opis
    </h2>
    <div className="content">
      {tenantChange.description}
    </div>
  </div>
);

const AttachmentsBox = () => {
  const {
    tenantChangeGroupId, id, premisesId, investmentId,
  } = useParams();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, id,
    );
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [tenantChangeGroupId, investmentId, premisesId, id]);

  if (!attachments.length) {
    return '';
  }

  const handleDownload = (fileId) => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, id,
    );
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const temp = attachments.map((attachment) => (
    <Col xs={24} sm={12} key={attachment.id}>
      <div className="attachment">
        <Row>
          <Col xs={2} className="attachment-icon">
            <Icon name="file" />
          </Col>
          <Col xs={16} className="attachment-name">
            {attachment.filename}
          </Col>
          <Col xs={4} offset={2} className="attachment-actions">
            <Button ghost className="button-no-border" onClick={() => handleDownload(attachment.id)}>
              <Icon name="download" />
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  ));

  if (temp.length === 0) {
    temp.push(
      <Col xs={24} sm={12} key={0}>
        <Empty />
      </Col>,
    );
  }

  return (
    <div className="premises-show-box attachments-box">
      <h2>
        <Icon name="folder" />
        Załączniki
      </h2>
      <div className="content">
        <Row gutter={20}>
          {temp}
        </Row>
      </div>
    </div>
  );
};

const Show = () => {
  const {
    investmentId, id, premisesId, tenantChangeGroupId,
  } = useParams();
  const history = useHistory();
  const [tenantChange, setTenantChange] = useState({});
  const [investment, setInvestment] = useState({});
  const [premise, setPremise] = useState({});
  const [worker, setWorker] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((userDataResponse) => {
      setUserData(userDataResponse);
      const PremisesService = new premisesService(investmentId);

      PremisesService.getOne(premisesId).then((data) => {
        setPremise(data);
      });

      const InvestmentsService = new investmentsService();
      InvestmentsService.getOne(investmentId).then((investmentData) => {
        setInvestment(investmentData);
      });

      const TenantChangesService = new tenantChangesService(
        investmentId, premisesId, tenantChangeGroupId,
      );
      TenantChangesService.getOne(id).then((tenantChangeData) => {
        setTenantChange(tenantChangeData);
        if (tenantChangeData.responsibleWorker != null) {
          workersService.getOne(tenantChangeData.responsibleWorker).then((workerData) => {
            setWorker(workerData);
          });
        }
        setLoading(false);
      });
    });
  }, [id, premisesId, investmentId, tenantChangeGroupId]);

  const handleBack = () => history.push(`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/show`);

  const canEdit = !loading && !userData.roles.includes('ROLE_CLIENT') && !userData.roles.includes('ROLE_CLIENT_TENANT');

  return (
    <div className="show-layout tenantChanges-show">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name="arrow-simply-left" />}
          title="Wróć"
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            canEdit && (
            <Link key="2" to={`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${id}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>
            ),
          ]}
        >
          <div className="premises-show">
            <div className="premises-show-header">
              <Row justify="space-between">
                <Col xs={24} sm={24}>
                  <h1>
                    {tenantChange.name}
                    <Dot {...tenantChange} />
                  </h1>
                </Col>
              </Row>
            </div>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <InfoBox
                  tenantChange={tenantChange}
                  premise={premise}
                  investment={investment}
                  worker={worker}
                />
                <FinanceBox tenantChange={tenantChange} />
              </Col>
              <Col xs={24} sm={12}>
                <DescriptionBox tenantChange={tenantChange} />
                <AttachmentsBox />
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
