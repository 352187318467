import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import { Price } from '../../../../components';

export const CostsBox = ({
  fault,
}) => {
  if (!fault.amount) {
    return '';
  }

  return (
    <div className="premises-show-box info-box">
      <h2>
        <Icon name="payments" />
        Koszty
      </h2>
      <div className="content">
        <Row className="entry">
          <Col xs={24} sm={{ span: 8, offset: 1 }} className="label">Kwota:</Col>
          <Col xs={24} sm={10} className="value">
            <Price value={fault.amount} />
          </Col>
        </Row>
      </div>
    </div>
  );
};
