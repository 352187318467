import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const authService = {};

authService.login = (data) => {
  const url = '/api/login';
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + url, {
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        if (!response.data) {
          reject();
        }
        localStorage.setItem('isLogged', true);
        localStorage.setItem('userData', JSON.stringify(response.data));
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

authService.getUserData = () => {
  const userData = localStorage.getItem('userData');
  if (userData) {
    return JSON.parse(userData);
  }
  return false;
};

authService.resetPassword = (data) => {
  const url = '/api/resetPassword';
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + url, {
        username: data.username,
      })
      .then((response) => {
        if (!response.data) {
          reject();
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

authService.isLogged = () => localStorage.getItem('isLogged') || false;

authService.logout = () => new Promise((resolve) => {
  localStorage.removeItem('isLogged');
  localStorage.removeItem('userData');
  resolve();
});

export default authService;
