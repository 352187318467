import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Form, Col, Button, Tooltip, message,
} from 'antd';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import paymentsService from '../../services/paymentsService';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import paymentsStatusMap from '../../helpers/paymentsStatusMap';
import Spin from '../../components/Spin';

const { Select, DatePicker } = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const Dot = (props) => {
  let color = 'yellow';
  const { status } = props;
  if (status === 'PAID') {
    color = 'green';
  }

  if (status === 'FAIL') {
    color = 'red';
  }

  return (
    <Tooltip title={paymentsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const getDescription = (description) => {
  if (!description) {
    return '';
  }

  if (description.length < 50) {
    return description;
  }

  return `${description.slice(0, 50)}...`;
};

const List = ({ type }) => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [isClientTenant, setIsClientTenant] = useState(false);
  const [serviceType, setServiceType] = useState('');
  const [userData, setUserData] = useState('');
  const [investments, setInvestments] = useState([]);

  const columns = [
    {
      title: 'Identyfikator płatności',
      dataIndex: 'id',
      visible: true,
      key: 'id',
      filterEnabled: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Identyfikator Tpay',
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'createdDate',
      visible: true,
      key: 'createdDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      visible: true,
      key: 'expirationDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => paymentsStatusMap(record.status),
    },
    {
      title: 'Opis',
      dataIndex: 'description',
      visible: true,
      key: 'description',
      sorter: false,
      render: (text, record) => getDescription(record.description),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      summary: true,
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  if (!loading && !userData.roles.includes('ROLE_DEVELOPER') && type !== 'PACKAGE') {
    columns.splice(5, 0, {
      title: 'Numer',
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Link className="datagrid-link" to={`investments/${record.investmentId}/premises/${record.premisesId}/show`}>{text}</Link>,
    },
    {
      title: 'Umowa',
      dataIndex: 'agreementName',
      visible: true,
      key: 'agreementName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Inwestycja',
      dataIndex: 'investmentName',
      visible: true,
      key: 'investmentName',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    });
  }

  let filters = {
    type,
  };
  if (query.get('investmentId')) {
    filters = {
      investmentId: query.get('investmentId'),
    };
  }
  if (query.get('status')) {
    filters = {
      status: query.get('status'),
    };
  }

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      let endpointType = '/payment';
      const { roles } = data;

      if (roles.includes('ROLE_WORKER') || roles.includes('ROLE_TENANT') || roles.includes('ROLE_DEVELOPER')) {
        endpointType = '/vendor/payment';
      }
      setServiceType(endpointType);

      if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_DEVELOPER')) {
        setIsClientTenant(true);
      } else {
        const InvestmentsService = new investmentsService();
        InvestmentsService.getList({
          types: 'RENT',
          pageSize: 1000,
        }).then((response) => {
          setInvestments(response.content);
        });
      }
      setLoading(false);
    });
  }, []);

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="createDate">
      <Form.Item
        name="createDate"
      >
        <DatePicker size="large" placeholder="Data" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="NEW">
            <span className="dot yellow" />
            Nowa
          </Option>
          <Option value="PAID">
            <span className="dot green" />
            Opłacona
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  if (!loading && !userData.roles.includes('ROLE_DEVELOPER')) {
    customFilters.push(
      <Col xs={24} sm={12} key="investmentId">
        <Form.Item
          name="investmentId"
        >
          <Select
            placeholder="Inwestycja"
            allowClear
            size="large"
          >
            {investmentsOptions}
          </Select>
        </Form.Item>
      </Col>,
    );
  }

  let hideEdit = false;
  let hideShow = true;
  let disableMassSelect = false;
  let CustomCreate = () => (
    <Link to="/payments/create">
      <Button type="primary">
        Nowa
        {' '}
        <Icon name="plus" />
      </Button>
    </Link>
  );

  if (isClientTenant) {
    hideEdit = true;
    hideShow = false;
    disableMassSelect = true;
    CustomCreate = () => ('');
  }

  if (type === 'PACKAGE') {
    disableMassSelect = true;
    CustomCreate = () => ('');
  }

  const markAsPaid = (record) => {
    // eslint-disable-next-line max-len
    const PaymentService = new paymentsService(record.investmentId, record.premisesId, record.agreementId, null);

    PaymentService.getOne(record.id).then((response) => {
      const values = {
        ...response,
        paid: response.amount,
        status: 'PAID',
      };
      setLoading(true);
      PaymentService.update(record.id, values).then(() => {
        setLoading(false);
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
    });
  };

  const markAsPaidAction = (record) => {
    if (isClientTenant || record.status === 'PAID') {
      return '';
    }

    return (
      <Button className="button-no-border" onClick={() => markAsPaid(record)}>
        <Icon name="dolar" />
      </Button>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <Datagrid
          title="Lista płatności"
          resource={`payments${type}`}
          dataProvider={new paymentsService(null, null, null, serviceType)}
          columns={columns}
          hideEdit={hideEdit}
          hideCreate={hideEdit}
          hideShow={hideShow}
          customFilters={customFilters}
          filters={filters}
          dynamicFilters
          customCreate={<CustomCreate />}
          disableSelection={disableMassSelect}
          customRowActions={[markAsPaidAction]}
          customShowIcon="dolar"
        />
      )}
    </div>
  );
};

export default List;
