/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col, Tooltip, Alert } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import FaultsS from '../../../services/faultsService';
import faultGroupService from '../../../services/faultGroupService';
import userService from '../../../services/userService';
import faultsStatusMap from '../../../helpers/faultsStatusMap';
import { ImagesBox } from './showComponents/ImagesBox';
import { DescriptionBox } from './showComponents/DescriptionBox';
import { InfoBox } from './showComponents/InfoBox';
import DeveloperNoteModal from './showComponents/Modal';
import { AttachmentsBox } from './showComponents/AttachmentsBox';
import { CostsBox } from './showComponents/CostsBox';
import { Icon, Spin } from '../../../components';
import { PassFault } from './showComponents/PassFault';

const Dot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const Show = () => {
  const { investmentId, faultGroupId, faultId } = useParams();
  const history = useHistory();
  const [fault, setFault] = useState({});
  const [faultGroup, setFaultGroup] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      const FaultGroupService = new faultGroupService(investmentId);

      FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
        setFaultGroup(faultGroupData);
      });

      const FaultsService = new FaultsS(investmentId, faultGroupId);
      FaultsService.getOne(faultId).then((faultData) => {
        setFault(faultData);
        setLoading(false);
      });
    });
  }, [faultId, investmentId, faultGroupId]);

  const handleBack = () => {
    return history.goBack();
  };

  const WorkersBox = ({ fault }) => {
    if (!fault?.responsibleWorkersNames) {
      return null;
    }

    if (Object.keys(fault.responsibleWorkersNames).length === 0) {
      return null;
    }

    return (
      <div className='premises-show-box owner-box'>
        <h2>
          <Icon name='worker' />
          Osoba odpowiedzialna
        </h2>
        <div className='content'>
          {fault.responsibleWorkers.map((workerId) => {
            const worker = fault.responsibleWorkersNames[workerId];

            return (
              <Row gutter={20}>
                <Col xs={24}>
                  <div className='owner-box-content'>
                    <div className='item'>
                      {worker.firstName} {worker.lastName}
                    </div>
                    <div className='item'>
                      <a
                        className='datagrid-link'
                        href={`tel:${worker.mobile}`}
                        style={{ color: '#3B4C60' }}
                      >
                        {worker.mobile}
                      </a>
                    </div>
                    <div className='item'>
                      <a
                        className='datagrid-link'
                        href={`mailto:${worker.email}`}
                        style={{ color: '#3B4C60' }}
                      >
                        {worker.email}
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className='show-layout faults-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Wróć'
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            ...[
              fault &&
                (((userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                  (userData?.roles?.includes('ROLE_WORKER') &&
                    userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
                  !fault.isDeveloperNotified &&
                  fault?.status !== 'DONE' &&
                  (fault?.status === 'TO_FIX' ||
                    userData?.id === fault?.reporterId)) ||
                  (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') &&
                    !fault?.isDeveloperNotified &&
                    !fault?.isAdminNotified)) && (
                  <Link
                    key='2'
                    to={{
                      pathname: `/housingFaultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${faultId}/edit`,
                      state: { redirectFrom: 'FaultShow' },
                    }}
                  >
                    <Button type='primary'>
                      Edytuj <Icon name='edit' />
                    </Button>
                  </Link>
                ),
            ],
          ]}
        >
          <PassFault
            userData={userData}
            faultGroup={faultGroup}
            fault={fault}
            setFault={setFault}
          />
          <div className='premises-show'>
            <div className='premises-show-header'>
              <Row justify='space-between'>
                <Col sm={24}>
                  <h1>
                    {fault.name}
                    <Dot {...fault} />
                  </h1>
                </Col>
              </Row>
            </div>
            <div className='premises-show-slider'>
              <ImagesBox />
            </div>
            <Row gutter={20}>
              <Col xs={24} lg={12}>
                <InfoBox fault={fault} faultGroup={faultGroup} />
                <WorkersBox fault={fault} />
                <CostsBox fault={fault} />
              </Col>
              <Col xs={24} lg={12}>
                {fault.developerNote && (
                  <DescriptionBox
                    fault={fault}
                    valueKey='developerNote'
                    title='Notatka od dewelopera'
                  />
                )}
                <DescriptionBox fault={fault} />
                <AttachmentsBox />
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
