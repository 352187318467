/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  PageHeader, Button, Row, Col, Tooltip,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import premisesService from '../../../services/premisesService';
import investmentsService from '../../../services/investmentsService';
import roomsService from '../../../services/roomsService';
import userService from '../../../services/userService';
import Icon from '../../../components/Icon';
import Price from '../../../components/Price';
import Spin from '../../../components/Spin';
import premiseStatusMap from '../../../helpers/premiseStatusMap';
import AgreementsBox from './showComponents/AgreementsBox';
import PriceBox from './showComponents/PriceBox';
import { CostsBox } from './showComponents/CostsBox';
import InfoBox from './showComponents/InfoBox';
import { RoomBox } from './showComponents/RoomBox';
import { AttachmentsBox } from './showComponents/AttachmentsBox';
import { BrokersBox } from './showComponents/BrokersBox';
import { TranchesBox } from './showComponents/TranchesBox';
import { TenatChangesBox } from './showComponents/TenatChangesBox';
import { ImagesBox } from './showComponents/ImagesBox';
import { FaultsBox } from './showComponents/FaultsBox';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Dot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'FREE') {
    color = 'green';
  }
  if (status === 'PARTIAL_RENT') {
    color = 'halfred';
  }

  if (status === 'RESERVATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={premiseStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const Show = () => {
  const { id, premisesId } = useParams();
  const history = useHistory();
  const [premise, setPremise] = useState({});
  const [investment, setInvestment] = useState({});
  const [rooms, setRooms] = useState([]);
  const [costs, setCosts] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [canSeePrices, setCanSeePrices] = useState(true);

  useEffect(() => {
    const PremisesService = new premisesService(id);
    const DataService = new roomsService(id, premisesId);

    const promises = [];

    promises.push(PremisesService.getOne(premisesId).then((data) => {
      setPremise(data);
    }));

    promises.push(PremisesService.getBalance(premisesId).then((data) => {
      setCosts(data);
    }));

    const InvestmentsService = new investmentsService();
    promises.push(InvestmentsService.getOne(id).then((investmentData) => {
      setInvestment(investmentData);
    }));

    promises.push(DataService.getList().then((data) => {
      setRooms(data);
    }));
    promises.push(userService.getInfo().then((data) => {
      setUserData(data);
      if (data.roles.includes('ROLE_WORKER') && data.ownerRoles.includes('ROLE_DEVELOPER')) {
        if (data.workerPermissions.includes('INVESTMENT_FULL') && data.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanEdit(true);
          setCanSeePrices(true);
        } else if (data.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanSeePrices(false);
          setCanEdit(false);
        } else if (data.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanSeePrices(true);
          setCanEdit(false);
        } else {
          setCanSeePrices(false);
          setCanEdit(false);
        }
      } else if (data.roles.includes('ROLE_WORKER') && data.ownerRoles.includes('ROLE_TENANT')) {
        if (data.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
        setCanSeePrices(true);
      } else if (data.roles.includes('ROLE_CLIENT')) {
        setCanEdit(false);
        setCanSeePrices(true);
      } else {
        setCanEdit(true);
        setCanSeePrices(true);
      }
    }));
    Promise.all(promises).then(() => setLoading(false)).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, [id, premisesId]);

  let priceInHeader = 0;
  let additionalClassName = '';
  const { price = {} } = premise;

  if (investment.type === 'SALE') {
    if (premise.status === 'FREE') {
      priceInHeader = price.suggest || 0;
    } else {
      priceInHeader = price.sell || 0;
      additionalClassName = 'sell';
    }
  } else {
    rooms.forEach(({ rent, charges }) => {
      priceInHeader += rent;
      priceInHeader += charges;
    });
  }

  let hasAnyPrice = true;

  if (premise && premise.price) {
    const nonEmptyPrices = Object.keys(premise.price)
      .filter((k) => premise.price[k] !== 0)
      .reduce((a, k) => ({ ...a, [k]: premise.price[k] }), {});
    hasAnyPrice = Object.keys(nonEmptyPrices).length > 0;
  }

  const showInfoOnLeft = (premise.status === 'FREE' && investment.type === 'RENT') || !hasAnyPrice;

  const onBack = () => {
    const { location: { state } } = history;
    if (state?.redirectFrom === 'ClientPremisesList') {
      return history.push(`/clients/${state.clientId}/premises`);
    }

    if (state?.redirectFrom === 'ClientTenantPremisesList') {
      return history.push(`/clientsTenants/${state.clientId}/premises`);
    }

    if (userData.roles.includes('ROLE_CLIENT')) {
      return history.push('/premises');
    }

    return history.push(`/investments/${id}/premises`);
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => onBack()}
          backIcon={<Icon name="arrow-simply-left" />}
          title="Wróć"
          className="page-header-extra-2"
          extra={[
            <Button className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            canEdit && (
            <Link key="3" to={`/investments/${id}/premises/${premisesId}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>
            ),
          ]}
        >
          <div className="premises-show">
            <div className="premises-show-header">
              <Row justify="space-between">
                <Col xs={12} sm={12}>
                  <h1>
                    {premise.name}
                    <Dot {...premise} />
                  </h1>
                </Col>
                <Col xs={12} sm={12}>
                  <div className="premises-show-header-price">
                    {canSeePrices && (
                      <Price value={priceInHeader} additionalClassName={additionalClassName} />
                    )}
                    {(investment.type === 'RENT' && priceInHeader !== 0) && (
                    <span> / miesiąc</span>
                    )}
                  </div>
                </Col>
                <Col xs={24}>
                  {Object.keys(premise.address).length > 0 && (
                    <p className="premises-show-header-address">
                      {premise.address.street}
                      {' '}
                      {premise.address.number}
                      {premise.address.local ? `/${premise.address.local}` : ''}
                      ,
                      {' '}
                      {premise.address.zip}
                      {' '}
                      {premise.address.city}
                    </p>
                  )}
                </Col>
              </Row>
            </div>
            <div className="premises-show-slider">
              <ImagesBox />
            </div>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                {showInfoOnLeft && (
                <InfoBox rooms={rooms} premise={premise} investment={investment} />
                )}
                {investment.type === 'SALE' && canSeePrices && (
                <PriceBox premise={premise} investment={investment} />
                )}
                {investment.type === 'RENT' && (
                <CostsBox premise={premise} investment={investment} costs={costs} />
                )}
                <AgreementsBox premise={premise} investment={investment} rooms={rooms} />
                {investment.type === 'SALE' && canSeePrices && (
                <TranchesBox premise={premise} investment={investment} canEdit={canEdit} />
                )}
                {investment.type === 'SALE' && (
                <BrokersBox premise={premise} investment={investment} />
                )}
                <FaultsBox premise={premise} investment={investment} />
              </Col>
              <Col xs={24} sm={12}>
                {!showInfoOnLeft && (
                <InfoBox rooms={rooms} premise={premise} investment={investment} />
                )}
                <RoomBox rooms={rooms} premise={premise} investment={investment} />
                <AttachmentsBox premise={premise} investment={investment} />
                {investment.type === 'SALE' && (
                <TenatChangesBox premise={premise} investment={investment} />
                )}
              </Col>
            </Row>
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
