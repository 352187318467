import React, { useState, useEffect, useCallback } from 'react';
import {
  Form,
  Table,
  Button,
  Modal,
  Row,
  Col,
  Dropdown,
  Menu,
  Tooltip,
  message,
} from 'antd';
import { useHistory } from 'react-router-dom';

import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import changeAccountService from '../../services/changeAccountService';
import userService from '../../services/userService';
import authService from '../../services/authService';
import { roleNameMap } from '../../helpers/roleNameMap';
import MessagePopup from '../../components/MessagePopup';

const { Password2 } = Input;

const ChangeAccountModal = ({ changeAccVisible, setChangeAccVisible }) => {
  const [addAccStep, setAddAccStep] = useState(false);
  const [actualUserData, setActualUserData] = useState({});
  const [savedAccs, setSavedAccs] = useState([]);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    const tempSavedAccs = localStorage.getItem('savedAccs');

    if (tempSavedAccs) {
      setSavedAccs(JSON.parse(tempSavedAccs));
    }

    userService.getInfo().then((data) => {
      const loginData = authService.getUserData();
      setActualUserData({ ...data, ...loginData });
    });
  }, []);

  const addAccHandler = useCallback(
    () =>
      form
        .validateFields()
        .then((loginData) => changeAccountService.getToken(loginData))
        .then((tokenData) =>
          changeAccountService.getInfo(tokenData).then((userData) => ({
            ...tokenData,
            id: userData?.id,
            roles: userData?.roles,
            name:
              userData?.name ??
              [userData?.firstName, userData?.lastName]
                .filter((el) => !!el)
                .join(' '),
          }))
        )
        .then((data) => {
          if (
            data.id !== actualUserData.id &&
            savedAccs.every((savedAcc) => savedAcc.id !== data.id)
          ) {
            localStorage.setItem(
              'savedAccs',
              JSON.stringify([
                ...savedAccs.filter((savedAcc) => savedAcc.id !== data.id),
                data,
              ])
            );
            setSavedAccs((currSavedAccs) => [
              ...currSavedAccs.filter((savedAcc) => savedAcc.id !== data.id),
              data,
            ]);
            setAddAccStep(false);
          } else {
            message.error(
              <MessagePopup type='error'>
                Ten użytkownik jest już zalogowany
              </MessagePopup>
            );
          }

          form.resetFields();
        })
        .catch((err) => {
          form.setFields([
            {
              name: 'username',
              errors: [''],
            },
          ]);
          form.setFields([
            {
              name: 'password',
              errors: ['Niepoprawny login lub hasło'],
            },
          ]);
        }),
    [savedAccs]
  );

  const onFinishFailed = useCallback((errorInfo) => {
    console.log('Failed:', errorInfo);
  }, []);

  const removeAccHandler = useCallback(
    (record) => {
      localStorage.setItem(
        'savedAccs',
        JSON.stringify(
          savedAccs.filter((savedAcc) => savedAcc.id !== record.id)
        )
      );

      setSavedAccs((currAccs) =>
        currAccs.filter((currAcc) => currAcc.id !== record.id)
      );
    },
    [savedAccs]
  );

  const changeAccHandler = useCallback(
    (record) => {
      const actualSavedAccs = [
        ...savedAccs.filter((savedAcc) => savedAcc.id !== record.id),
        {
          access_token: actualUserData?.access_token,
          expires_in: actualUserData?.expires_in,
          refresh_token: actualUserData?.refresh_token,
          id: actualUserData?.id,
          roles: actualUserData?.roles,
          name:
            actualUserData?.name ??
            [actualUserData?.firstName, actualUserData?.lastName]
              .filter((el) => !!el)
              .join(' '),
        },
      ];

      console.log(actualSavedAccs);

      localStorage.setItem('savedAccs', JSON.stringify(actualSavedAccs));
      setSavedAccs(actualSavedAccs);

      localStorage.setItem(
        'userData',
        JSON.stringify({
          access_token: record?.access_token,
          expires_in: record?.expires_in,
          refresh_token: record?.refresh_token,
        })
      );
      setActualUserData(record);

      history.push('/dashboard');

      form.resetFields();
      setChangeAccVisible(false);

      window.location.reload(true);
    },
    [savedAccs, actualUserData]
  );

  const columns = [
    {
      key: 'delete',
      className: 'change-acc-delete-dropdown',
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key='99'
                onClick={() => removeAccHandler(record)}
                style={{ color: '#EC434C' }}
                icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
              >
                Usuń
              </Menu.Item>
            </Menu>
          }
          trigger='click'
          placement='bottom'
          className='smalllist-wrapper-row-action'
        >
          <Button className='button-no-border'>
            <Icon name='more-vertical' />
          </Button>
        </Dropdown>
      ),
    },
    {
      dataIndex: 'name',
      editable: false,
      render: (text, record) => <span style={{ fontWeight: 700 }}>{text}</span>,
    },
    {
      dataIndex: 'roles',
      editable: false,
      render: (text, record) => roleNameMap[text[0]],
    },
    {
      key: 'change-acc',
      className: 'change-acc-btn',
      render: (text, record) => (
        <Tooltip title={'Przełącz konto'} placement={'right'} showArrow={false}>
          <Button
            className='change-acc-btn-arrow'
            onClick={() => changeAccHandler(record)}
          >
            <Icon name='arrow-short-right' />
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      {addAccStep ? (
        <Modal
          centered
          visible={changeAccVisible}
          width={410}
          onCancel={() => {
            setChangeAccVisible(false);
            setAddAccStep(false);
            form.resetFields();
          }}
          footer={null}
        >
          <h2 style={{ marginTop: 20, marginBottom: 30, fontSize: 20 }}>
            Dodaj konto
          </h2>
          <Form
            form={form}
            onFinish={addAccHandler}
            onFinishFailed={onFinishFailed}
            name='create'
            className='create-form'
            autoComplete='off'
          >
            <Form.Item
              name='username'
              rules={[{ required: true, message: 'Pole wymagane' }]}
            >
              <Input size='large' placeholder='Login' />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Pole wymagane' }]}
            >
              <Password2 size='large' placeholder='Hasło' />
            </Form.Item>

            <Row gutter={20} className='datagrid-filter-modal-button-wrapper'>
              <Col xs={12} sm={12}>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={() => {
                    setAddAccStep(false);
                    form.resetFields();
                  }}
                >
                  Wróć
                </Button>
              </Col>
              <Col xs={12} sm={12}>
                <Button type='primary' size='large' htmlType='submit'>
                  Zaloguj się
                  <Icon name='access' style={{ marginLeft: '5px' }} />
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : (
        <Modal
          centered
          visible={changeAccVisible}
          width={490}
          onCancel={() => {
            setChangeAccVisible(false);
          }}
          footer={
            <Row gutter={20} className='datagrid-filter-modal-button-wrapper'>
              <Col xs={12} sm={12}>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={() => {
                    setChangeAccVisible(false);
                  }}
                >
                  Anuluj
                </Button>
              </Col>
              <Col xs={12} sm={12}>
                <Button
                  type='primary'
                  size='large'
                  onClick={() => {
                    setAddAccStep(true);
                  }}
                >
                  Dodaj
                  <Icon name='plus' style={{ marginLeft: '5px' }} />
                </Button>
              </Col>
            </Row>
          }
        >
          <h2 style={{ marginTop: 20, marginBottom: 30, fontSize: 20 }}>
            Przełącz konto
          </h2>
          <div className='smalllist-wrapper'>
            <Table
              columns={columns}
              dataSource={savedAccs}
              pagination={false}
              showHeader={false}
              className={'change-acc-table'}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ChangeAccountModal;
