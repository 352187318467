import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';
import HousingTenantsService from '../../../../services/housingTenantsService';
import memberInvestmentPremisesService from '../../../../services/memberInvestmentPremisesService';
import MessagePopup from '../../../../components/MessagePopup';

const { SearchBox, DatePicker, Switch } = Input;

const TenantsModal = (props) => {
  const { afterCancel, isModalVisible, refresh, editingRent } = props;
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const [withoutExpireDate, setWithoutExpireDate] = useState(false);

  useEffect(() => {
    if (editingRent) {
      const tempData = editingRent;
      if (editingRent.dateFrom) {
        tempData.dateFrom = moment(editingRent.dateFrom, 'YYYY-MM-DD');
      }
      if (editingRent.dateTo) {
        tempData.dateTo = moment(editingRent.dateTo, 'YYYY-MM-DD');
      }
      form.setFieldsValue(tempData);
    }
  }, [editingRent]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const data = { ...values };

        if (values.dateFrom) {
          data.dateFrom = values.dateFrom.format('YYYY-MM-DD');
        }
        if (values.dateTo) {
          data.dateTo = values.dateTo.format('YYYY-MM-DD');
        }

        const MemberInvestmentPremisesService =
          new memberInvestmentPremisesService(id);

        if (editingRent?.id) {
          MemberInvestmentPremisesService.updateTenant(
            id,
            premisesId,
            editingRent.id,
            data
          )
            .then((response) => {
              refresh();
              afterCancel();
            })
            .catch((err) => {
              if (err.response.status === 409) {
                message.error(
                  <MessagePopup
                    type={'error'}
                    annotation={'Najemca jest już przypisany do lokalu'}
                  >
                    Nie można przypisać najemcy
                  </MessagePopup>
                );
              }
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        } else {
          MemberInvestmentPremisesService.createTenant(id, premisesId, data)
            .then((response) => {
              refresh();
              afterCancel();
            })
            .catch((err) => {
              if (err.response.status === 409) {
                message.error(
                  <MessagePopup
                    type={'error'}
                    annotation={'Najemca jest już przypisany do lokalu'}
                  >
                    Nie można przypisać najemcy
                  </MessagePopup>
                );
              }
              if (err && err.message === 'demo') {
                message.error(
                  <MessagePopup type={'error'}>
                    Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                  </MessagePopup>
                );
              }
            });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={editingRent ? 'Edytuj najemcę' : 'Dodaj najemcę'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={
          <Footer
            onReset={onReset}
            onFinish={onFinish}
            isNew={!editingRent?.id}
          />
        }
      >
        <Form
          form={form}
          name='filterMember'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name='userId'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <SearchBox
                  optionValue={(el) => {
                    if (el.isCompany) {
                      return el.name;
                    }
                    return `${el.firstName} ${el.lastName}`;
                  }}
                  placeholder='Użytkownik'
                  size='large'
                  resource={HousingTenantsService}
                  methodName={'getList'}
                  filters={{ role: 'ROLE_COMMUNITY_MEMBER_TENANT' }}
                  filterOption={(inputValue, option) => {
                    return option.children.indexOf(inputValue) !== -1;
                  }}
                  showSearch={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name='dateFrom'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker
                  size='large'
                  placeholder='Data od'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name='dateTo'
                rules={
                  !withoutExpireDate && [
                    { required: true, message: 'Pole wymagane' },
                  ]
                }
              >
                <DatePicker
                  size='large'
                  placeholder='Data do'
                  style={{ width: '100%' }}
                  disabled={withoutExpireDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={0} sm={12} />
            <Col xs={24} sm={12}>
              <div
                style={{
                  paddingLeft: 24,
                  paddingRight: 24,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                Do odwołania
                <Form.Item name='withoutExpireDate' valuePropName='checked'>
                  <Switch
                    onChange={(checked) => {
                      setWithoutExpireDate(checked);

                      if (checked) {
                        form.resetFields(['dateTo']);
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TenantsModal;
