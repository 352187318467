import React, { useState, useEffect } from 'react';
import {
  Row, Col, Menu, Tooltip, message,
} from 'antd';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import AgreementModal from '../modals/AgreementModal';
import agreementsService from '../../../../services/agreementsService';
import roomsService from '../../../../services/roomsService';
import agreementStatusMap from '../../../../helpers/agreementStatusMap';
import agreementTypeMap from '../../../../helpers/agreementTypeMap';

export const Agreements = (props) => {
  const {
    investmentId, premisesId, type,
  } = props;
  const [agreementsModal, setAgreementsModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [agreements, setAgreements] = useState([]);

  const Dot = (record) => {
    let color = 'grey';
    if (record.status === 'ACTIVE') {
      color = 'green';
    }

    return (
      <Tooltip title={agreementStatusMap(record.status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  const ClientsRenderer = (record) => {
    const { clients } = record;
    if (clients.length === 0) {
      return '';
    }

    const surnames = clients.map((clientData) => clientData.lastName);
    return surnames.join(', ');
  };

  useEffect(() => {
    const AgreementsService = new agreementsService(investmentId, premisesId);
    if (type === 'RENT') {
      const RoomsService = new roomsService(investmentId, premisesId);
      AgreementsService.getList().then((data) => {
        const promises = data.map((agreement) => RoomsService.getOne(agreement.roomId));

        Promise.all(promises).then((results) => {
          const temp = data.map((agreement) => {
            const room = results.find((obj) => obj.id === agreement.roomId);
            return {
              ...agreement,
              roomName: room.name,
            };
          });
          setAgreements(temp);
        });
      });
    } else {
      AgreementsService.getList().then((data) => setAgreements(data));
    }
  }, [investmentId, premisesId, type]);

  const refresh = () => {
    const AgreementsService = new agreementsService(investmentId, premisesId);
    if (props.type === 'RENT') {
      const RoomsService = new roomsService(investmentId, premisesId);
      AgreementsService.getList().then((data) => {
        const promises = data.map((agreement) => RoomsService.getOne(agreement.roomId));

        Promise.all(promises).then((results) => {
          const temp = data.map((agreement) => {
            const room = results.find((obj) => obj.id === agreement.roomId);
            return {
              ...agreement,
              roomName: room.name,
            };
          });
          setAgreements(temp);
        });
      });
    } else {
      AgreementsService.getList().then((data) => setAgreements(data));
    }
  };

  let columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Pokój',
      dataIndex: 'roomName',
    },
    {
      title: 'Nazwisko',
      dataIndex: 'clients',
      render: (text, record) => (<ClientsRenderer clients={record.clientsData} />),
    },
    {
      title: 'Data od',
      dataIndex: 'dateFrom',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Data do',
      dataIndex: 'dateTo',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
  ];

  if (type === 'SALE') {
    columns = [
      {
        title: 'Nazwa',
        dataIndex: 'name',
      },
      {
        title: 'Typ umowy',
        dataIndex: 'type',
        render: (text, record) => agreementTypeMap(record.type),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => (<Dot status={record.status} />),
      },
      {
        title: 'Data zawarcia',
        dataIndex: 'dateFrom',
      },
    ];
  }

  const addNewAction = (data) => {
    setModalData(data);
    setAgreementsModal(true);
  };
  const afterSave = (data) => {
    setAgreementsModal(false);
    if (data) {
      setModalData(data);
      setAgreementsModal(true);
    } else {
      setModalData({});
    }
    refresh();
  };
  const afterCancel = () => {
    setAgreementsModal(false);
    setModalData({});
  };

  const deleteAction = (entityId) => {
    const AgreementsService = new agreementsService(investmentId, premisesId);
    AgreementsService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleGenerateAgreement = (record) => {
    const AgreementsService = new agreementsService(investmentId, premisesId);
    if (type === 'SALE') {
      AgreementsService.getNotaryPdf(record.id).then((pdfResponse) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(pdfResponse);
        a.download = `${record.id}.pdf`;
        a.click();
      }).catch(async (error) => {
        const errorMessage = JSON.parse(await error.response.data.text());
        message.error(`Nie można wygenerować umowy. Błędy: ${errorMessage.message}`);
      });
    } else {
      AgreementsService.getRentPdf(record.id).then((pdfResponse) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(pdfResponse);
        a.download = `${record.id}.pdf`;
        a.click();
      }).catch(async (error) => {
        const errorMessage = JSON.parse(await error.response.data.text());
        message.error(`Nie można wygenerować umowy. Błędy: ${errorMessage.message}`);
      });
    }
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    if ((type === 'RENT') || (type === 'SALE' && record.type === 'RESERVATION')) {
      const fileGenerateTitle = (type === 'RENT') ? 'Generuj umowę' : 'Generuj dane dla notariusza';
      const generateAction = (
        <Menu.Item key="1" onClick={() => handleGenerateAgreement(record)} icon={<Icon name="file-generate" style={{ marginRight: '5px' }} />}>
          {fileGenerateTitle}
        </Menu.Item>
      );
      tempArray.push(generateAction);
    }

    return tempArray;
  };

  return (
    <div className="agreements create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList
            icon="file"
            data={agreements}
            columns={columns}
            title="Lista zawartych umów"
            addAction={addNewAction}
            deleteAction={deleteAction}
            customMenuActions={customMenuActions}
          />
        </Col>
      </Row>
      {agreementsModal && (
        <AgreementModal
          isModalVisible={agreementsModal}
          afterSave={afterSave}
          afterCancel={afterCancel}
          data={modalData}
          type={type}
        />
      )}
    </div>
  );
};
