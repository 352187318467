/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Modal, Row, Col, Upload, Button, Dropdown, Menu, message,
  Input, Form,
} from 'antd';
import agreementAttachmentsService from '../../../../../services/agreementAttachmentsService';
import Empty from '../../../../../components/Empty';
import Icon from '../../../../../components/Icon';

const { confirm } = Modal;

const AgreementAttachments = (props) => {
  const { investmentId, premisesId, agreementId } = props;
  const [agreementAttachments, setAgreementAttachments] = useState([]);

  useEffect(() => {
    const AgreementAttachmentsService = new agreementAttachmentsService(
      investmentId, premisesId, agreementId,
    );
    AgreementAttachmentsService.getList().then((agreementAttachmentsData) => {
      setAgreementAttachments(agreementAttachmentsData);
    });
  }, [investmentId, premisesId, agreementId]);

  const addNewAction = (payload) => {
    const formData = new FormData();
    formData.append('attachment', payload.file);
    const AgreementAttachmentsService = new agreementAttachmentsService(
      investmentId, premisesId, agreementId,
    );

    AgreementAttachmentsService.create(formData).then((response) => {
      if (response.id) {
        AgreementAttachmentsService.getList().then((agreementAttachmentsData) => {
          setAgreementAttachments(agreementAttachmentsData);
        });
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
    };
    return (
      <Upload {...uploadProps}>
        <Button className="button-secondary" style={{ width: '45px', height: '45px' }}>
          <Icon name="plus" />
        </Button>
      </Upload>
    );
  };

  const handleDelete = (fileId) => {
    const AgreementAttachmentsService = new agreementAttachmentsService(
      investmentId, premisesId, agreementId,
    );
    AgreementAttachmentsService.delete(fileId).then(() => {
      AgreementAttachmentsService.getList().then((agreementAttachmentsData) => {
        setAgreementAttachments(agreementAttachmentsData);
      });
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      cancelButtonProps: { className: 'button-secondary' },
      onOk() {
        handleDelete(record.id);
      },
    });
  };

  const handleDownload = (fileId) => {
    const AgreementAttachmentsService = new agreementAttachmentsService(
      investmentId, premisesId, agreementId,
    );
    AgreementAttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const enableEditMode = (setEditMode) => {
    setEditMode(true);
  };

  const menu = (record, setEditMode) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleDownload(record.id)} icon={<Icon name="download" style={{ marginRight: '5px' }} />}>
        Pobierz
      </Menu.Item>
      <Menu.Item key="2" onClick={() => enableEditMode(setEditMode)} icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        Zmień nazwę
      </Menu.Item>
      <Menu.Item key="3" onClick={() => showDeleteConfirm(record)} style={{ color: '#EC434C' }} icon={<Icon name="delete" style={{ marginRight: '5px' }} />}>
        Usuń
      </Menu.Item>
    </Menu>
  );

  const Attachment = (attachment) => {
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(false);

    const updateAction = (fileId, data) => {
      const AgreementAttachmentsService = new agreementAttachmentsService(
        investmentId, premisesId, agreementId,
      );

      AgreementAttachmentsService.updateData(fileId, data)
        .then(() => {
          AgreementAttachmentsService.getList().then((agreementAttachmentsData) => {
            setAgreementAttachments(agreementAttachmentsData);
          });
        })
        .catch((err) => {
          if (err && err.message === 'demo') {
            message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
          }
        });
    };

    const handleSave = async (key) => {
      try {
        const row = await form.validateFields();
        updateAction(key, row);
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    };

    return (
      <Col xs={24} sm={12}>
        <Form form={form} component={false}>
          <div className="attachment" style={{ lineHeight: '32px' }}>
            <Row>
              <Col xs={2} className="attachment-icon">
                <Icon name="file" />
              </Col>
              <Col xs={18} className="attachment-name">
                {editMode ? (
                  <Form.Item
                    name="filename"
                    style={{
                      margin: 0,
                    }}
                  >
                    <Input placeholder={attachment.filename} />
                  </Form.Item>
                ) : (
                  attachment.filename
                )}
              </Col>
              <Col xs={4} className="attachment-actions">
                {editMode ? (
                  <span className="smalllist-wrapper-row-action">
                    <Button className="button-no-border edit-actions" onClick={() => setEditMode(false)}>
                      <Icon name="error-circle" style={{ color: '#EC434C', fontSize: '20px' }} />
                    </Button>
                    <Button className="button-no-border edit-actions" onClick={() => handleSave(attachment.id)}>
                      <Icon name="ok-circle" style={{ color: '#15D55E', fontSize: '20px' }} />
                    </Button>
                  </span>
                ) : (
                  <Dropdown overlay={() => menu(attachment, setEditMode)} trigger="click" placement="bottomCenter" className="attachment-action">
                    <Button className="button-no-border">
                      <Icon name="more-vertical" />
                    </Button>
                  </Dropdown>
                )}

              </Col>
            </Row>
          </div>
        </Form>
      </Col>
    );
  };

  return (
    <>
      <Row style={{ marginBottom: '20px' }}>
        <Col xs={16}>
          <h2>Umowa i dokumenty </h2>
        </Col>
        <Col xs={8} style={{ textAlign: 'right' }}>
          <CustomAddButton />
        </Col>
      </Row>
      <Row gutter={20}>
        {agreementAttachments.length === 0 && (
          <Col xs={24}>
            <Empty />
          </Col>
        )}
        {agreementAttachments.length !== 0 && agreementAttachments.map((singleAttachment) => (
          <Attachment {...singleAttachment} />
        ))}
      </Row>
      <div className="attachmentsNote" style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>
        Maksymalny rozmiar pliku: 15MB.
      </div>
    </>
  );
};

export default AgreementAttachments;
