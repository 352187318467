const statusMap = {
  ACTIVE: 'Aktywna',
  INACTIVE: 'Wygasła',
  SOLD: 'Sprzedane',
  RENT: 'Wynajęte',
  PARTIAL_RENT: 'Częściowo wynajęte',
};
const getStatusName = (status) => statusMap[status] || '';

export default getStatusName;
