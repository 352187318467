import React from 'react';

const Price = ({
  value, currency = 'PLN', additionalClassName = '', showEmpty = false, style = {},
}) => {
  if (!value && !showEmpty) {
    return '';
  }

  const formattedValue = new Intl.NumberFormat('pl-PL', { style: 'currency', currency }).format(value);

  return (
    <span className={additionalClassName} style={style}>{`${formattedValue}`}</span>
  );
};

export default Price;
