import React, { useEffect, useState } from 'react';
import {
  PageHeader, Tooltip, Col, Form,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import premisesService from '../../services/premisesService';
import clientsService from '../../services/clientsService';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';

import {
  Spin, Icon, Price, Datagrid, Input,
} from '../../components';

import premiseStatusMap from '../../helpers/premiseStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';

const { Select } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'red';
  const { status } = props;
  if (status === 'FREE') {
    color = 'green';
  }
  if (status === 'RESERVATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={premiseStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Inwestycja',
    dataIndex: 'investmentId',
    visible: true,
    filterEnabled: false,
    key: 'investmentId',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => record.investmentName,
  },
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: true,
    key: 'type',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.type),
    exportRender: (text, record) => premiseTypeMap(record.type),
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (`${record.totalSurface} m2`),
  },
  {
    title: 'Liczba pokoi',
    dataIndex: 'roomsCount',
    visible: true,
    key: 'roomsCount',
    sorter: false,
  },
  {
    title: 'Zmiany lokatorskie',
    dataIndex: 'tenantsChangesCount',
    visible: true,
    key: 'tenantsChangesCount',
    sorter: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => premiseStatusMap(record.status),
  },
];

const CustomCreate = () => ('');

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const PremisesService = new premisesService(null, id);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [investments, setInvestments] = useState([]);
  useEffect(() => {
    const InvestmentsService = new investmentsService();

    InvestmentsService.getList({
      types: 'SALE',
      pageSize: 1000,
    }).then((data) => {
      setInvestments(data.content);
    });
    let showPrices = false;
    clientsService.getOne(id).then((data) => {
      setUserData(data);
      userService.getInfo().then((user) => {
        if (user.roles.includes('ROLE_WORKER') && user.ownerRoles.includes('ROLE_DEVELOPER')) {
          if (user.workerPermissions.includes('INVESTMENT_FULL') && user.workerPermissions.includes('RECEIPTS_FULL')) {
            setCanEdit(true);
            showPrices = true;
          } else if (user.workerPermissions.includes('INVESTMENT_FULL')) {
            setCanEdit(false);
          } else if (user.workerPermissions.includes('RECEIPTS_FULL')) {
            showPrices = true;
            setCanEdit(false);
          } else {
            setCanEdit(false);
          }
        } else if (user.roles.includes('ROLE_WORKER') && user.ownerRoles.includes('ROLE_TENANT')) {
          if (user.workerPermissions.includes('INVESTMENT_FULL')) {
            setCanEdit(true);
          } else {
            setCanEdit(false);
          }
          showPrices = true;
        } else {
          setCanEdit(true);
          showPrices = true;
        }
        setLoading(false);

        if (showPrices) {
          columns.push({
            title: 'Cena minimalna (brutto)',
            dataIndex: 'priceMinimal',
            visible: true,
            key: 'priceMinimal',
            sorter: false,
            render: (text, record) => (<Price value={record.priceMinimal} />),
          },
          {
            title: 'Cena sprzedaży (brutto)',
            dataIndex: 'priceSell',
            visible: true,
            key: 'priceSell',
            sorter: false,
            render: (text, record) => (<Price value={record.priceSell} />),
          });
        }
      });
    });
  }, [id]);

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Lokale</span>;
    }
    return (
      <span>
        Lokale:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  const customFilters = [];
  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));
  customFilters.push(
    <Col xs={24} sm={12} key="investmentId">
      <Form.Item
        name="investmentId"
      >
        <Select
          placeholder="Inwestycja"
          allowClear
          size="large"
        >
          {investmentsOptions}
        </Select>
      </Form.Item>
    </Col>,
  );

  const redirectState = { redirectFrom: 'ClientPremisesList', clientId: id };
  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/clients/${id}/show`)}
          title={<HeaderTitle />}
        >
          <div className="premises-list">
            <Datagrid
              resource={`investments/${id}/premises`}
              dataProvider={PremisesService}
              columns={columns}
              customCreate={<CustomCreate />}
              customBaseUrlKey="clientTenantsPremises"
              customFilters={customFilters}
              canEdit={canEdit}
              hideEdit={!canEdit}
              sort={{
                sortField: 'name',
                sortOrder: 'ascend',
              }}
              redirectState={redirectState}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
