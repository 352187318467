import BaseService from './baseService';

class MemberMessagesService extends BaseService {
  constructor() {
    super();

    this.url = `/api/community/member/messages`;
  }
}

export default MemberMessagesService;
