import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import {
  StatsWrapper,
  PaymentsComponent,
  RentPremisesComponent,
  RentFaultsComponent,
  AgreementsComponent,
  SettlementsComponent,
  SaleFaultsComponent,
  SalePremisesComponent,
  TenantChangesComponent,
} from './ViewComponents';

const DeveloperTenantDashboard = () => (
  <Row gutter={20}>
    <Col xs={24} md={12}>
      <StatsWrapper title="Rozliczenia" icon="payments">
        <SettlementsComponent />
      </StatsWrapper>
      <StatsWrapper
        title="Sprzedaż"
        icon="dolar"
        actions={[
          <Link to="investments/stats">
            <Button size="small">
              Statystyki
              <Icon name="stats" />
            </Button>
          </Link>,
        ]}
      >
        <SalePremisesComponent />
        <SaleFaultsComponent />
        <TenantChangesComponent />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper
        title="Płatności"
        icon="card"
        actions={[
          <Link to="payments">
            <Button size="small">
              Historia
              <Icon name="history-list" />
            </Button>
          </Link>,
          <Link to="payments/create">
            <Button size="small">
              Dodaj nową
              <Icon name="plus" />
            </Button>
          </Link>,
        ]}
      >
        <PaymentsComponent />
      </StatsWrapper>
      <StatsWrapper
        title="Najem"
        icon="tenant"
        actions={[
          <Link to="investments/stats">
            <Button size="small">
              Statystyki
              <Icon name="stats" />
            </Button>
          </Link>,
        ]}
      >
        <RentPremisesComponent />
        <AgreementsComponent />
        <RentFaultsComponent />
      </StatsWrapper>
    </Col>
  </Row>
);

export default DeveloperTenantDashboard;
