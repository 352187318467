import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Menu, Tooltip } from 'antd';
import Icon from '../../../../../../components/Icon';
import SmallList from '../../../../../../components/SmallList';
import settlementTemplatesCostsService from '../../../../../../services/settlementTemplatesCostsService';
import CostsModal from './CostsModal';

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    editable: false,
  },
  {
    title: 'Jednostka',
    dataIndex: 'unit',
    editable: false,
    render: (text) => {
      if (text === 'm3') {
        return (
          <span>
            m<sup>3</sup>
          </span>
        );
      } else {
        return text;
      }
    },
  },
  {
    title: '',
    dataIndex: 'multiplier',
    editable: false,
    render: (text) => <span style={{ color: '#B1CADE' }}>x</span>,
  },
  {
    title: 'Kwota jednostkowa (brutto)',
    dataIndex: 'price',
    editable: false,
    render: (text) => `${parseFloat(text).toFixed(2).replace('.', ',')} zł`,
  },
];

export const CostsList = () => {
  const { id, groupId } = useParams();
  const [costsModalVisible, setCostsModalVisible] = useState(false);
  const [costs, setCosts] = useState([]);
  const [editingCost, setEditingCost] = useState();

  useEffect(() => {
    refresh();
  }, [id, groupId, costsModalVisible]);

  const refresh = () => {
    const SettlementTemplatesCostsService = new settlementTemplatesCostsService(
      id,
      groupId
    );
    SettlementTemplatesCostsService.getList().then((data) => {
      setCosts(data);
    });
  };

  const onRemove = (entityId) => {
    const SettlementTemplatesCostsService = new settlementTemplatesCostsService(
      id,
      groupId
    );

    SettlementTemplatesCostsService.delete(entityId)
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setCostsModalVisible(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  const duplicateCost = (record) => {
    const SettlementTemplatesCostsService = new settlementTemplatesCostsService(
      id,
      groupId
    );

    const tempValues = {
      isMainCounterDifference: record?.isMainCounterDifference,
      mainCounterId: record?.mainCounterId,
      name: record?.name,
      price: record?.price,
      settlementTemplateGroupId: record?.settlementTemplateGroupId,
      type: record?.type,
      unit: record?.unit,
    };

    SettlementTemplatesCostsService.create(tempValues)
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  return (
    <div className='attachments create-form-wrapper'>
      <CostsModal
        isModalVisible={costsModalVisible}
        afterCancel={() => {
          setCostsModalVisible(false);
          setEditingCost(null);
        }}
        editingCost={editingCost}
      />
      <Row>
        <Col xs={24}>
          <div style={{ marginBottom: 50 }}>
            <SmallList
              data={costs}
              columns={columns}
              title='Pozycje'
              deleteAction={onRemove}
              customAddButton={<CustomAddButton />}
              customMenuActions={(record) => (
                <Menu.Item
                  key='96'
                  onClick={() => {
                    setEditingCost(record);
                    setCostsModalVisible(true);
                  }}
                  icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
                >
                  Edytuj
                </Menu.Item>
              )}
              hideEdit
              customRowAction={{
                key: 'action',
                align: 'right',
                width: 76,
                render: (text, record) => (
                  <Tooltip title={'Duplikuj'}>
                    <Button
                      className='duplicate-button'
                      onClick={() => duplicateCost(record)}
                    >
                      <Icon name='copy' style={{ fontSize: 32 }} />
                    </Button>
                  </Tooltip>
                ),
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
