import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import { useParams } from 'react-router-dom';
import AgreementsServiceS from '../../../../services/agreementsService';
import RoomsServiceS from '../../../../services/roomsService';
import MetersServiceS from '../../../../services/metersService';
import clientsService from '../../../../services/clientsService';
import Footer from '../../../../helpers/Footer';
import AgreementAttachments from './components/AgreementAttachments';
import Input from '../../../../components/InputComponent';

const {
  DatePicker, Select, InputNumber, DebounceSelect,
} = Input;
const { Option } = Select;

const AgreementModal = (props) => {
  const { data, afterSave, afterCancel } = props;
  const [rooms, setRooms] = useState([]);
  const [meters, setMeters] = useState([]);
  const [clients, setClients] = useState([]);
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const AgreementService = new AgreementsServiceS(id, premisesId);
  const { isModalVisible, type } = props;

  useEffect(() => {
    if (type === 'RENT') {
      const RoomsService = new RoomsServiceS(id, premisesId);
      RoomsService.getList().then((roomData) => {
        setRooms(roomData.map((room) => (
          <Option key={room.id}>{room.name}</Option>
        )));
      });

      const MetersService = new MetersServiceS(id, premisesId);
      MetersService.getList().then((metersData) => {
        setMeters(metersData.map((meter) => (
          <Option key={meter.id}>{meter.date}</Option>
        )));
      });
    }
  }, [id, form, premisesId, type, data.id, data.clients]);

  if (data?.id) {
    const tempData = {
      ...data,
      dateTo: data.dateTo ? moment(data.dateTo) : null,
      dateFrom: data.dateFrom ? moment(data.dateFrom) : null,
    };
    form.setFieldsValue(tempData);
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values?.dateTo) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            dateTo: values.dateTo.format('YYYY-MM-DD'),
          };
        }
        if (values?.dateFrom) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            dateFrom: values.dateFrom.format('YYYY-MM-DD'),
          };
        }
        if (data?.id) {
          AgreementService.update(data.id, values).then(() => {
            afterSave();
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          AgreementService.create(values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  async function fetchUserList(username) {
    let clientType = 'ROLE_CLIENT';

    if (type === 'RENT') {
      clientType = 'ROLE_CLIENT_TENANT';
    }

    return clientsService.getList({
      role: clientType,
      pagination: { pageSize: 1000 },
    }).then((clientsData) => clientsData.content)
      .then((clientsData) => {
        const filteredResults = clientsData.filter((entry) => {
          const { firstName = '', lastName } = entry;
          if (!username) {
            return true;
          }
          const firstNameCheck = firstName.toUpperCase().search(username.toUpperCase());
          const lastNameCheck = lastName.toUpperCase().search(username.toUpperCase());

          return firstNameCheck !== -1 || lastNameCheck !== -1;
        });
        return filteredResults;
      })
      .then((clientsData) => clientsData.map((client) => {
        const pesel = client.identity && client.identity.pesel ? `(${client.identity.pesel})` : '';
        return {
          label: `${client.firstName} ${client.lastName} ${pesel}`,
          value: client.id,
        };
      }));
  }

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? `Edytujesz: ${data.name}` : 'Nowa umowa';

  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
          initialValues={{ dateFrom: moment() }}
        >
          <Row gutter={20}>
            <Col xs={24} sm={type === 'SALE' ? 12 : 24}>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Input size="large" placeholder="Nazwa" />
              </Form.Item>
            </Col>
            {type === 'SALE' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="type"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Select
                    placeholder="Typ umowy"
                    allowClear
                    size="large"
                  >
                    <Option value="RESERVATION">Rezerwacyjna</Option>
                    <Option value="CONTRACT">Deweloperska</Option>
                    <Option value="PRELIMINARY">Przedwstępna</Option>
                    <Option value="TRANSFER_ACT">Przeniesienie własności</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="status"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select
                  placeholder="Status"
                  allowClear
                  size="large"
                >
                  <Option value="ACTIVE">
                    <span className="dot green" />
                    Aktywna
                  </Option>
                  <Option value="INACTIVE">
                    <span className="dot grey" />
                    Wygasła
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {type === 'RENT' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="meterId"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Select
                    placeholder="Stan liczników"
                    allowClear
                    size="large"
                  >
                    {meters}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={12}>
              <Form.Item
                name="dateFrom"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker size="large" placeholder="Data od" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            {type === 'RENT' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="dateTo"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <DatePicker size="large" placeholder="Data do" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            )}
          </Row>
          {type === 'RENT' && (
            <>
              <h2>Płatności</h2>
              <Row gutter={20}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="paymentDay"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >
                    <InputNumber min={1} max={31} size="large" placeholder="Dzień generowania płatności" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="paymentExpirationDays"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >
                    <Select
                      placeholder="Termin płatności"
                      allowClear
                      size="large"
                    >
                      <Option value="7">7 dni</Option>
                      <Option value="14">14 dni</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <h2>{type === 'RENT' ? 'Najemcy' : 'Kupujący'}</h2>
          <Row gutter={20}>
            <Col xs={24} sm={type === 'SALE' ? 24 : 12}>
              <Form.Item
                name="clients"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DebounceSelect
                  mode="multiple"
                  value={clients}
                  placeholder="Uzytkownicy"
                  size="large"
                  fetchOptions={fetchUserList}
                  onChange={(newValue) => {
                    setClients(newValue.value);
                  }}
                />
              </Form.Item>
            </Col>
            {type === 'RENT' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="roomId"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <Select
                    placeholder="Pokój"
                    allowClear
                    size="large"
                  >
                    {rooms}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          {data.id && (
            <AgreementAttachments investmentId={id} premisesId={premisesId} agreementId={data.id} />
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AgreementModal;
