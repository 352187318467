import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  PageHeader, Button, Row, Col, Card,
} from 'antd';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import Price from '../../components/Price';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (customUserData) {
      setUserData(customUserData);
      setLoading(false);
    } else {
      clientsService.getOne(id).then((data) => {
        setUserData(data);
        setLoading(false);
      });
    }

    clientsService.getBalance(id).then((data) => {
      setBalance(data.balance);
    });
  }, [id, customUserData]);

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/clientsTenants')}
          backIcon={customTitle ? '' : <Icon name="arrow-simply-left" />}
          title={customTitle || 'Wróć'}
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            !customTitle && (
            <Link key="2" to={`/clientsTenants/${userData.id}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>
            ),
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className="left">
                  <div className="logo">
                    <img src={`${baseUrl}${userData.avatar}`} alt={userData.name} />
                  </div>
                  <div className="name">
                    {userData.firstName}
                    {' '}
                    {userData.lastName}
                  </div>
                  <div className="buttons">
                    {userData.mobile && (
                    <div className="call">
                      <a href={`tel:${userData.mobile}`}>
                        <Button type="primary" size="large">
                          <Icon name="phone" />
                          Zadzwoń
                        </Button>
                      </a>
                    </div>
                    )}
                    <div className="write">
                      <a href={`mailto:${userData.email}`}>
                        <Button type="primary" size="large">
                          <Icon name="email" />
                          {' '}
                          Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className="right">
                  <div className="premises-show-box costs-box" style={{ marginTop: '20px' }}>
                    <h2>
                      <Icon name="more-info" />
                      Saldo
                    </h2>
                    <div className="content">
                      <Row gutter={20}>
                        <Col xs={24}>
                          <div className="value"><Price value={balance} showEmpty /></div>
                          <div className="title">
                            <Row>
                              <Col xs={24} sm={12}>Saldo</Col>
                              <Col xs={24} sm={12} style={{ textAlign: 'right' }}>
                                <Link to={`/clientsTenants/${userData.id}/payments`}>
                                  <Button ghost size="small">Historia transakcji</Button>
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="user" />
                        Informacje
                      </h2>
                    )}
                    className="informations"
                  >
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <div className="title">Imię i nazwisko:</div>
                        <div>
                          {userData.firstName}
                          {' '}
                          {userData.lastName}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className="title">Dane kontaktowe:</div>
                        <div className="email">{userData.email}</div>
                        <div className="phone">{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <Address title="Adres zamieszkania" address={userData.address} />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Address title="Adres korespondencyjny" address={userData.correspondenceAddress ? userData.correspondenceAddress : {}} />
                      </Col>
                    </Row>
                  </Card>
                  <div className="investments-show-right-actions">
                    <Row gutter={20}>
                      <Col xs={24} sm={8}>
                        <Link className="investments-show-right-actions-action" to={customTitle ? 'agreements' : 'premises'}>
                          <Icon name="premises" />
                          Lokale
                        </Link>
                      </Col>
                      <Col xs={24} sm={8}>
                        <Link className="investments-show-right-actions-action" to="faults">
                          <Icon name="fault" />
                          Usterki
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
