import React from 'react';

const Address = ({ address }) => {
  if (!address) {
    return '';
  }
  return (
    <div className="address">
      {address.street}
      {' '}
      {address.number}
      {address.local ? `/${address.local}` : ''}
      <br />
      {' '}
      {address.zip}
      {' '}
      {address.city}
    </div>
  );
};

export default Address;
