import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import Icon from '../../../../components/Icon';
import parkingTypeMap from '../../../../helpers/parkingTypeMap';
import moment from 'moment';

const InfoBox = ({ premise, paymentsAccountNumber }) => {
  const tooltipText = 'Gwarancja wygasła. Nie możesz zgłaszać usterek';
  const guaranteeActive = moment(new Date(premise.guaranteeDate)).isSameOrAfter(
    new Date(),
    'day'
  );

  const guaranteeComponent = (
    <>
      {guaranteeActive ? (
        premise.guaranteeDate
      ) : (
        <div style={{ color: '#EC434C' }}>
          {premise.guaranteeDate}
          <Tooltip
            placement='bottomLeft'
            title={tooltipText}
            overlayInnerStyle={{
              fontSize: 10,
              fontWeight: 600,
              minHeight: 20,
            }}
            showArrow={false}
            trigger={'hover'}
          >
            <Icon
              name={'important'}
              style={{
                marginLeft: 5,
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </div>
      )}
    </>
  );

  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Informacje
      </h2>
      <div className='content'>
        <Row className='entry'>
          <Col xs={{ span: 8, offset: 1 }} className='label'>
            Powierzchnia:
          </Col>
          <Col xs={10} className='value'>
            {premise.totalSurface} m<sup>2</sup>
          </Col>
        </Row>
        <Row className='entry'>
          <Col xs={{ span: 8, offset: 1 }} className='label'>
            Piętro:
          </Col>
          <Col xs={10} className='value'>
            {premise.floor}
          </Col>
        </Row>
        {premise.type === 'PARKING' && (
          <Row className='entry'>
            <Col xs={{ span: 8, offset: 1 }} className='label'>
              Lokalizacja:
            </Col>
            <Col xs={10} className='value'>
              {parkingTypeMap(premise.parkingType)}
            </Col>
          </Row>
        )}

        {premise.type !== 'STORAGE' && premise.type !== 'PARKING' && (
          <Row className='entry'>
            <Col xs={{ span: 8, offset: 1 }} className='label'>
              Strona świata:
            </Col>
            <Col xs={10} className='value'>
              {premise.geographicDirections &&
                premise.geographicDirections.join(', ')}
            </Col>
          </Row>
        )}
        <Row className='entry' style={{ marginTop: '20px' }}>
          <Col xs={{ span: 8, offset: 1 }} className='label'>
            Data przekazania:
          </Col>
          <Col xs={10} className='value'>
            {premise.soldStatusDate}
          </Col>
          <Col xs={{ span: 8, offset: 1 }} className='label'>
            Gwarancja:
          </Col>
          <Col xs={10} className='value'>
            {guaranteeComponent}
          </Col>
        </Row>
        {premise.type === 'RENT' && (
          <Row className='entry' style={{ marginTop: '20px' }}>
            <Col xs={{ span: 8, offset: 1 }} className='label'>
              Lokatorzy:
            </Col>
            <Col xs={10} className='value'>
              {premise.tenantsCount}
            </Col>
          </Row>
        )}
        <Row className='entry' style={{ marginTop: '20px' }}>
          <Col xs={{ span: 8, offset: 1 }} className='label'>
            Adres:
          </Col>
          <Col xs={10} className='value'>
            <div>
              ul. {premise.address.street} {premise.address.number}
              {premise.address.local ? `/${premise.address.local}` : ''}
            </div>
            <div>
              {premise.address.zip} {premise.address.city}
            </div>
          </Col>
        </Row>
        {paymentsAccountNumber && (
          <Row className='entry' style={{ marginTop: '20px' }}>
            <Col xs={{ span: 8, offset: 1 }} className='label'>
              Numer rachunku:
            </Col>
            <Col xs={10} className='value'>
              {paymentsAccountNumber}
            </Col>
          </Row>
        )}
        {premise.comment && (
          <Row className='entry' style={{ marginTop: '20px' }}>
            <Col xs={{ span: 8, offset: 1 }} className='label'>
              Notatka:
            </Col>
            <Col xs={10} className='value' style={{ whiteSpace: 'pre-wrap' }}>
              {premise.comment}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default InfoBox;
