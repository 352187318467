import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultConfirmationService extends BaseService {
  constructor() {
    super();
    this.url = '/api/faults/confirm';
  }

  async confirm(hash) {
    return httpService.put(`${this.url}/${hash}`, null, false);
  }
}

export default FaultConfirmationService;
