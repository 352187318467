import React from 'react';

const Icon = ({
  name, className, onClickAction, ...rest
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <i role="presentation" className={`font-icon icon-${name} ${className}`} onClick={onClickAction} {...rest} />
);

export default Icon;
