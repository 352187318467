import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import investmentContactService from '../../../services/investmentContactService';
import ContactsInvestmentModal from './ContactsInvestmentModal';
import MessagePopup from '../../../components/MessagePopup';

export const ContactsInvestment = ({ investmentId }) => {
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingContact, setEditingContact] = useState();

  useEffect(() => {
    const InvestmentContactService = new investmentContactService(investmentId);
    InvestmentContactService.getList().then((data) => {
      setContacts(data);
    });
  }, [investmentId, showModal]);

  const refresh = () => {
    const InvestmentContactService = new investmentContactService(investmentId);
    InvestmentContactService.getList().then((data) => {
      setContacts(data);
    });
  };

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: ['firstName', 'lastName'],
      editable: false,
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
    },
    {
      title: 'Stanowisko',
      dataIndex: 'position',
      editable: true,
    },
    {
      title: 'Telefon',
      dataIndex: 'phone',
      editable: true,
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'email',
      editable: true,
    },
  ];

  const deleteAction = (entityId) => {
    const InvestmentContactService = new investmentContactService(investmentId);
    InvestmentContactService.delete(entityId)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const updateAction = (entityId, data) => {
    const contact = contacts.find((el) => el.id === entityId);

    const tempData = { ...contact };

    if (data.position) {
      tempData.position = data.position;
    }
    if (data.phone) {
      tempData.phone = data.phone;
    }
    if (data.email) {
      tempData.email = data.email;
    }

    const InvestmentContactService = new investmentContactService(investmentId);
    InvestmentContactService.update(entityId, tempData)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setShowModal(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ContactsInvestmentModal
        isModalVisible={showModal}
        afterCancel={() => {
          setShowModal(false);
          setEditingContact(null);
        }}
        editingContact={editingContact}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            tableClassName={contacts.length !== 0 && 'contacts-table'}
            data={contacts}
            columns={columns}
            title='Lista kontaktów'
            deleteAction={deleteAction}
            updateAction={updateAction}
            editingAction={(record) => {
              setEditingContact(record);
              setShowModal(true);
            }}
            customAddButton={<CustomAddButton />}
            hideEdit
          />
        </Col>
      </Row>
    </div>
  );
};
