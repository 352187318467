import React, { useState, useEffect } from 'react';
import {
  Row, Col, Upload, Button, Menu, message,
} from 'antd';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import tenantChangesAttachmentsService from '../../../../services/tenantChangesAttachmentsService';

export const Attachments = ({
  investmentId, premisesId, tenantChangeId, tenantChangeGroupId,
}) => {
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [tenantChangeGroupId, investmentId, premisesId, tenantChangeId]);

  const refresh = () => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  };

  const columns = [
    {
      dataIndex: 'filename',
      editable: true,
    },
  ];
  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('attachment', data.file);
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.create(formData).then((response) => {
      if (response.id) {
        refresh();
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const deleteAction = (entityId) => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
    };
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button className="button-secondary" style={{ width: '45px', height: '45px' }}>
          <Icon name="plus" />
        </Button>
      </Upload>
    );
  };

  const handleDownload = (fileId) => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const updateAction = (entityId, data) => {
    const AttachmentsService = new tenantChangesAttachmentsService(
      investmentId, premisesId, tenantChangeGroupId, tenantChangeId,
    );
    AttachmentsService.updateData(entityId, data).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const downloadAction = (
      <Menu.Item key="1" onClick={() => handleDownload(record.id)} icon={<Icon name="download" style={{ marginRight: '5px' }} />}>
        Pobierz
      </Menu.Item>
    );
    tempArray.push(downloadAction);
    return tempArray;
  };

  return (
    <div className="attachments create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            icon="file"
            data={attachments}
            columns={columns}
            title="Lista załączników"
            deleteAction={deleteAction}
            customAddButton={<CustomAddButton />}
            updateAction={updateAction}
            inlineEditLabel="Zmień nazwę"
            hideEdit
            customMenuActions={customMenuActions}
          />
        </Col>
      </Row>
    </div>
  );
};
