import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberPremisesCountersService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/user/investment/${investmentId}/premises/${premisesId}/mainCounters`;
  }

  async getSubCountersForPremises(investmentId, premisesId, counterId) {
    return httpService.get(
      `/api/user/investment/${investmentId}/premises/${premisesId}/mainCounters/${counterId}/subCounters`,
      null,
      true
    );
  }

  async getSubCounter(investmentId, premisesId, counterId, subCounterId) {
    return httpService.get(
        `/api/user/investment/${investmentId}/premises/${premisesId}/mainCounters/${counterId}/subCounters/${subCounterId}`,
        null,
        true
    );
  }
}

export default MemberPremisesCountersService;
