/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Row, Col, Tooltip, Tag, Alert } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import faultGroupService from '../../services/faultGroupService';
import faultsService from '../../services/faultsService';
import faultGroupTagsService from '../../services/faultGroupTagsService';
import userService from '../../services/userService';
import { Icon, Price, SmallList, Datagrid, Spin } from '../../components';
import faultsStatusMap from '../../helpers/faultsStatusMap';
import faultGroupStatusMap from '../../helpers/faultGroupStatusMap';
import TagsService from '../../services/tagsService';

const FaultDot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const FaultGroupDot = (props) => {
  const { status } = props;
  let color = 'blue';
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'IN_PROGRESS') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultGroupStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const InfoBox = ({ faultGroup, userRoles }) => {
  const workerFieldMapper = (worker) => {
    if (worker.nameDeveloper) {
      return worker.nameDeveloper;
    }

    return `${worker.firstName || ''} ${worker.lastName || ''}`;
  };

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
      render: (text, record) =>
        workerFieldMapper(faultGroup.responsibleWorkersNames[record]),
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
      render: (text, record) => (
        <span className='premises-broker-phone'>
          {faultGroup.responsibleWorkersNames[record].mobile}
        </span>
      ),
    },
    {
      title: 'Pesel',
      dataIndex: 'percent',
      render: (text, record) =>
        faultGroup.responsibleWorkersNames[record].identity
          ? faultGroup.responsibleWorkersNames[record].identity.pesel
          : '',
    },
  ];

  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Informacje
      </h2>
      <div className='content'>
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <Row className='entry'>
              <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                Inwestycja:
              </Col>
              <Col xs={24} sm={10} className='value'>
                {faultGroup.investmentName}
              </Col>
            </Row>
            <Row className='entry'>
              <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                Lokal:
              </Col>
              <Col xs={24} sm={10} className='value'>
                {faultGroup.premisesName || 'Część wspólna'}
              </Col>
            </Row>
            {faultGroup.addDate && (
              <Row className='entry'>
                <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                  Dodano:
                </Col>
                <Col xs={24} sm={10} className='value'>
                  {faultGroup.addDate}
                </Col>
              </Row>
            )}

            {faultGroup.fixDate && (
              <Row className='entry'>
                <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
                  Usunięto:
                </Col>
                <Col xs={24} sm={10} className='value'>
                  {faultGroup.fixDate}
                </Col>
              </Row>
            )}
          </Col>
          {!userRoles.includes('ROLE_CLIENT') && (
            <Col xs={24} sm={12}>
              {faultGroup.responsibleWorkers && (
                <div style={{ marginTop: '20px' }}>
                  <h3>Osoba odpowiedzialna</h3>
                  <div className='content'>
                    <SmallList
                      data={faultGroup.responsibleWorkers}
                      columns={columns}
                      showHeader={false}
                      icon='worker'
                    />
                  </div>
                </div>
              )}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

const Faults = (props) => {
  const { investmentId, faultGroupId } = useParams();
  const { userData, faultGroup } = props;
  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: false,
      render: (text, record) => <FaultDot status={record.status} />,
      exportRender: (text, record) => faultsStatusMap(record.status),
    },
    {
      title: 'Data wystąpienia usterki',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    {
      title: 'Zmieniono',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: false,
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
  ];

  const workerFieldMapper = (worker) => {
    if (worker.nameDeveloper) {
      return worker.nameDeveloper;
    }

    return `${worker.firstName || ''} ${worker.lastName || ''}`;
  };

  if (
    !userData?.roles?.includes('ROLE_CLIENT') &&
    !userData?.roles?.includes('ROLE_CLIENT_TENANT')
  ) {
    columns.push(
      {
        title: 'Osoba odpowiedzialna',
        dataIndex: 'responsibleWorkers',
        visible: true,
        key: 'responsibleWorkers',
        sorter: false,
        render: (text, record) => {
          if (!record.responsibleWorkers) {
            return '';
          }
          const workersMap = record.responsibleWorkers.map((worker) =>
            workerFieldMapper(faultGroup.responsibleWorkersNames[worker])
          );
          return workersMap.join(', ');
        },
      },
      {
        title: 'Kwota',
        dataIndex: 'amount',
        visible: true,
        key: 'amount',
        sorter: false,
        summary: true,
        render: (text, record) => <Price value={record.amount} showEmpty />,
      }
    );
  }

  const customBaseUrl = (record) =>
    `faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/${record.id}`;

  const CustomCreate = () => (
    <Link
      to={`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/fault/create`}
    >
      <Button type='primary'>
        Nowa <Icon name='plus' />
      </Button>
    </Link>
  );

  return (
    <Datagrid
      title='Lista usterek'
      resource='faultGroup'
      dataProvider={new faultsService(investmentId, faultGroupId)}
      columns={columns}
      directResponse
      hideColumns
      hideFilters
      disableSelection
      calculateBaseUrl={customBaseUrl}
      disablePagination
      customCreate={<CustomCreate />}
      hideEdit={userData?.roles?.includes('ROLE_DEVELOPER')}
      customRowActions={[
        (record) => {
          if (
            userData?.roles?.includes('ROLE_DEVELOPER') &&
            record?.status !== 'DONE' &&
            (record?.status === 'TO_FIX' || userData?.id === record.reporterId)
          ) {
            return (
              <Link
                to={`/faultGroup/${faultGroup.investmentId}/faultGroup/${faultGroup.id}/fault/${record.id}/edit`}
              >
                <Button className='button-no-border'>
                  <Icon name='edit' />
                </Button>
              </Link>
            );
          }

          return null;
        },
      ]}
    />
  );
};

const Show = () => {
  const { investmentId, faultGroupId } = useParams();
  const history = useHistory();
  const [faultGroup, setFaultGroup] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userService.getInfo().then((data) => {
      const promises = [];

      const FaultGroupService = new faultGroupService(investmentId, null);
      setUserData(data);

      promises.push(
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        })
      );

      Promise.all(promises).then(() => setLoading(false));
    });
  }, [faultGroupId, investmentId]);

  const handleBack = () => {
    const { location } = history;
    if (location.state && location.state === 'createdFromInvestment') {
      return history.push(
        `/investments/${faultGroup.investmentId}/premises/${faultGroup.premisesId}/edit`
      );
    }

    return history.push('/faultGroup');
  };

  const TagFilters = () => {
    const [tagsIds, setTagsIds] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const FaultGroupTagsService = new faultGroupTagsService();
      FaultGroupTagsService.getFaultGroupTags(investmentId, faultGroupId).then(
        (data) => {
          setTagsIds(data);
        }
      );
    }, []);

    useEffect(() => {
      if (tagsIds.length > 0) {
        const promises = [];
        const tempTags = [];
        for (let tagId of tagsIds) {
          promises.push(
            TagsService.getOne(tagId).then((data) => {
              tempTags.push(data);
            })
          );
        }

        Promise.all(promises).then(() => {
          setTags(tempTags);
        });
      }
    }, [tagsIds]);

    return tags.map((tag) => (
      <Link to={`/faultGroup?tag=${tag.id}`}>
        <Tag>{`#${tag.name}`}</Tag>
      </Link>
    ));
  };
  const canEdit =
    (!userData?.roles?.includes('ROLE_CLIENT_TENANT') &&
      faultGroup?.status !== 'DONE') ||
    false;

  let shoudRenderClientNotification = false;
  if (
    !loading &&
    (userData?.roles?.includes('ROLE_CLIENT_TENANT') ||
      userData?.roles?.includes('ROLE_CLIENT'))
  ) {
    if (faultGroup.isConfirmed === null && faultGroup.status === 'DONE') {
      shoudRenderClientNotification = true;
    }
  }

  const CustomAlert = () => {
    const FaultGroupService = new faultGroupService(investmentId, null);
    const confirm = () => {
      FaultGroupService.updateConfirmation(faultGroupId, true).then(() => {
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        });
      });
    };

    const cancel = () => {
      FaultGroupService.updateConfirmation(faultGroupId, false).then(() => {
        FaultGroupService.getOne(faultGroupId).then((faultGroupData) => {
          setFaultGroup(faultGroupData);
        });
      });
    };
    const ownerName = userData?.roles?.includes('ROLE_CLIENT_TENANT')
      ? 'Zarządca'
      : 'Deweloper';
    return (
      <Alert
        style={{ width: '100%' }}
        type='info'
        message={
          <>
            <span className='font-bold'>{ownerName}</span> zakończył te usterkę.
            Potwierdź odbiór aby zakończyć.
          </>
        }
        action={[
          <Button
            type='primary'
            className='confirm-fault-button'
            onClick={() => confirm()}
          >
            Potwierdzam odbiór
          </Button>,
          <Button
            className='button-secondary'
            type='text'
            onClick={() => cancel()}
          >
            Odrzuć
          </Button>,
        ]}
      />
    );
  };

  return (
    <div className='show-layout tenantChanges-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => handleBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Wróć'
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              onClick={() => window.print()}
            >
              Drukuj <Icon name='download' />
            </Button>,
            canEdit && (
              <Link
                key='2'
                to={`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/edit`}
              >
                <Button type='primary'>
                  Edytuj <Icon name='edit' />
                </Button>
              </Link>
            ),
            !userData?.roles?.includes('ROLE_CLIENT_TENANT') &&
              !userData?.roles?.includes('ROLE_CLIENT') && (
                <Link
                  key='2'
                  to={`/faultGroup/${investmentId}/faultGroup/${faultGroupId}/history`}
                >
                  <Button className='button-secondary'>
                    Historia
                    <Icon name='history-list' />
                  </Button>
                </Link>
              ),
          ]}
        >
          <div className='premises-show'>
            <div className='premises-show-header'>
              <Row justify='space-between'>
                <Col xs={24} sm={24}>
                  <h1>
                    {faultGroup.name}
                    <FaultGroupDot {...faultGroup} />
                  </h1>
                </Col>
              </Row>
            </div>
            <Row gutter={20}>
              {shoudRenderClientNotification && (
                <div className='faults-notification'>
                  <Col xs={24}>
                    <CustomAlert />
                  </Col>
                </div>
              )}
              {!userData?.roles?.includes('ROLE_CLIENT') &&
                !userData?.roles?.includes('ROLE_CLIENT_TENANT') && (
                  <Col xs={24} className='tags-filter'>
                    <TagFilters />
                  </Col>
                )}
              <Col xs={24}>
                <InfoBox faultGroup={faultGroup} userRoles={userData?.roles} />
              </Col>
            </Row>
            <Faults userData={userData} faultGroup={faultGroup} />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
