import BaseService from './baseService';

class SettlementTemplatesGroupsService extends BaseService {
  constructor(investmentId) {
    super();
    this.url = `/api/community/investment/${investmentId}/settlementTemplates/costsGroups`;
  }
}

export default SettlementTemplatesGroupsService;
