import React, { useState } from 'react';
import {
  Button, Card, Checkbox, Col, Divider, Form, PageHeader, Row, message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import clientsService from '../../services/clientsService';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import Tabs from '../../components/Tabs';
import IdentityConfirmForm from '../../components/IdentityConfirmForm';

const { InputNumber, Switch } = Input;
const { TabPane } = Tabs;

const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [showCorrespondence, setShowCorrespondence] = useState(false);

  const onFinish = (values) => {
    let data = {
      roles: ['ROLE_CLIENT'],
      ...values,
      password: Math.random().toString(36).substr(2, 10),
      username: Math.random().toString(36).substr(2, 10),
    };
    if (values?.identity?.idExpireDate) {
      data = {
        ...data,
        identity: {
          ...data.identity,
          idExpireDate: data.identity.idExpireDate.format('YYYY-MM-DD'),
        },
      };
    }
    clientsService.create(data).then((response) => {
      if (response.id) {
        history.push(`/clients/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
  };

  const handleCancel = () => {
    history.push('/clients');
  };

  const onFieldsChange = () => {
    let expireDate = form.getFieldValue(['identity', 'idExpireDate']);
    if (expireDate) {
      expireDate = new Date(expireDate);
      if (expireDate < new Date()) {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: ['Termin ważności wygasł!'],
          },
        ]);
      } else {
        form.setFields([
          {
            name: ['identity', 'idExpireDate'],
            errors: [],
          },
        ]);
      }
    }
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/clients')}
        title="Dodaj klienta"
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          initialValues={{ enabled: true, role: ['ROLE_CLIENT'], address: { country: 'Polska' } }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFieldsChange={onFieldsChange}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Informacje" key="form">
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="form-section">
                          <h2>Rodzaj konta</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name="isCompany"
                                label="Konto firmowe"
                              >
                                <Switch size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                        >
                          {({ getFieldValue }) => !getFieldValue('isCompany') && (
                          <>
                            <div className="form-section">
                              <h2>Dane klienta</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="firstName"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Imię" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="secondName"
                                  >
                                    <Input size="large" placeholder="Drugie imię (opcjonalne)" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Nazwisko" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </>
                          )}
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                        >
                          {({ getFieldValue }) => getFieldValue('isCompany') && (
                          <>
                            <div className="form-section">
                              <h2>Dane firmowe</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Nazwa firmy" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="nip"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input.MaskedInput mask="000-000-00-00" size="large" placeholder="NIP" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </>
                          )}
                        </Form.Item>
                        <div className="form-section">
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                          >
                            {({ getFieldValue }) => (getFieldValue('isCompany') ? (
                              <h2>Adres siedziby</h2>
                            ) : (
                              <h2>Adres zamieszkania</h2>
                            ))}
                          </Form.Item>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Ulica" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'number']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Numer" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={6}>
                              <Form.Item
                                name={['address', 'local']}
                              >
                                <Input size="large" placeholder="Mieszkanie" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Miejscowość" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input.MaskedInput mask="00-000" size="large" placeholder="Kod pocztowy" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Form.Item name="differentAddress" valuePropName="checked" noStyle>
                              <Checkbox
                                onChange={correspondenceShow}
                              >
                                Inny adres do korespondencji
                              </Checkbox>
                            </Form.Item>
                          </Form.Item>
                          {showCorrespondence && (
                            <>
                              <h2>Adres do korespondencji</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'street']}
                                  >
                                    <Input size="large" placeholder="Ulica" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'number']}
                                  >
                                    <Input size="large" placeholder="Numer" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={6}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'local']}
                                  >
                                    <Input size="large" placeholder="Mieszkanie" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'city']}
                                  >
                                    <Input size="large" placeholder="Miejscowość" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'zip']}
                                  >
                                    <Input.MaskedInput mask="00-000" size="large" placeholder="Kod pocztowy" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['correspondenceAddress', 'country']}
                                  >
                                    <CountrySelect />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                        <div className="form-section">
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                          >
                            {({ getFieldValue }) => (getFieldValue('isCompany') ? (
                              <h2>Osoba do kontaktu</h2>
                            ) : (
                              <h2>Dane kontaktowe</h2>
                            ))}
                          </Form.Item>
                          <Row gutter={20}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) => prev.isCompany !== current.isCompany}
                            >
                              {({ getFieldValue }) => getFieldValue('isCompany') && (
                              <>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="firstName"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Imię" />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name="lastName"
                                    rules={[{ required: true, message: 'Pole wymagane' }]}
                                  >
                                    <Input size="large" placeholder="Nazwisko" />
                                  </Form.Item>
                                </Col>
                              </>
                              )}
                            </Form.Item>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="email"
                                rules={[{ required: false, message: 'Proszę uzupełnić adres email' }, { type: 'email', message: 'Niepoprawny adres email' }]}
                              >
                                <Input size="large" placeholder="E-Mail" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="mobile"
                              >
                                <InputNumber size="large" placeholder="Telefon" className="hide-arrows" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item name="enabled" valuePropName="checked" noStyle>
                            <Checkbox>Aktywny</Checkbox>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Potwierdzenie tożsamości" key="identity" forceRender>
                  <IdentityConfirmForm />
                </TabPane>
              </Tabs>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
