import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../components/Icon';

export const InfoBox = ({ resolution, investmentData }) => {
  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Informacje
      </h2>
      <div className='content'>
        {investmentData?.name && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Inwestycja:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {investmentData.name}
            </Col>
          </Row>
        )}
        {resolution?.addDate && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Dodano:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {resolution?.addDate}
            </Col>
          </Row>
        )}
        {resolution?.startDate && (
          <Row className='entry' style={{ marginTop: 20 }}>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Data rozpoczęcia:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {resolution?.startDate}
            </Col>
          </Row>
        )}
        {resolution?.endDate && (
          <Row className='entry'>
            <Col xs={24} sm={{ span: 8, offset: 1 }} className='label'>
              Data zakończenia:
            </Col>
            <Col xs={24} sm={10} className='value'>
              {resolution?.endDate}
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
