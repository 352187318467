import React, { useEffect, useState } from 'react';
import { Form, Button, PageHeader, Row, Col, message, Card } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PremisesServiceS from '../../../../services/premisesService';
import investmentsService from '../../../../services/investmentsService';
import Icon from '../../../../components/Icon';
import Input from '../../../../components/InputComponent';
import Spin from '../../../../components/Spin';
import CountrySelect from '../../../../components/CountrySelect';
import premiseTypeMap from '../../../../helpers/premiseTypeMap';
import MessagePopup from '../../../../components/MessagePopup';

const { InputNumberV2, InputNumber, TextArea, DatePicker, Switch } = Input;
const { Select } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);
  const PremisesService = new PremisesServiceS(id);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((data) => {
      setInvestment(data);

      if (data.garageHalls.length === 1) {
        form.setFieldValue('garageHallId', data.garageHalls[0].id);
      }

      setLoading(false);
    });
  }, [id]);

  const onFinish = (values) => {
    if (values?.soldStatusDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        soldStatusDate: values.soldStatusDate.format('YYYY-MM-DD'),
      };
    }
    const data = {
      ...values,
      type: 'STORAGE',
    };

    PremisesService.create(data)
      .then((response) => {
        if (response.id) {
          history.replace(
            `/housingAssociations/${id}/premises/${response.id}/edit`
          );
        }
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
        if (err?.response?.status === 406) {
          message.error(
            <MessagePopup type='error'>
              Przekroczono limit lokali o typie: "{premiseTypeMap('SERVICE')}"
              dla obecnego pakietu
            </MessagePopup>
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    // history.push(`/housingAssociations/${id}/premises`);
    history.goBack();
  };

  const floorOptions = [];
  for (let index = -5; index <= 20; index += 1) {
    floorOptions.push(<Option value={index}>{index}</Option>);
  }

  const onFieldsChange = (fieldChanged) => {
    const fieldChangedNames = fieldChanged
      .map((field) => field.name)
      .reduce((a, b) => a.concat(b));
    if (fieldChangedNames.includes('investmentAddress')) {
      const investmentAddress = form.getFieldValue('investmentAddress');
      if (investmentAddress) {
        form.setFieldsValue({
          address: investment.address,
        });
      } else {
        form.setFieldsValue({
          address: undefined,
        });
      }
    }
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => window.history.back()}
          title='Dodaj komórkę lokatorską'
        >
          <Form
            name='create'
            className='create-form'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onFieldsChange}
            initialValues={{ address: { country: 'Polska' } }}
            autoComplete='off'
            form={form}
          >
            <Card>
              <div className='create-form-wrapper'>
                <Row>
                  <Col xs={20} offset={2}>
                    <div className='form-section'>
                      <h2>Dane podstawowe</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='name'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić nazwę',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Numer' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='floor'
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić piętro',
                              },
                            ]}
                          >
                            <Select placeholder='Piętro' size='large'>
                              {floorOptions}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='soldStatusDate'
                            rules={[
                              { required: true, message: 'Pole wymagane' },
                            ]}
                          >
                            <DatePicker
                              size='large'
                              placeholder='Data przekazania'
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Powierzchnia całkowita</h2>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='totalSurface'
                            rules={[
                              { required: true, message: 'Pole wymagane' },
                            ]}
                            style={{ marginBottom: 0 }}
                          >
                            <InputNumberV2
                              size='large'
                              placeholder='Powierzchnia'
                              addonAfter={
                                <>
                                  m<sup>2</sup>
                                </>
                              }
                            />
                          </Form.Item>
                          <div
                            style={{
                              fontSize: '12px',
                              color: '#C0D6E5',
                            }}
                          >
                            * Według aktu notarialnego.
                          </div>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name='garageHallId'
                            rules={[
                              {
                                required: true,
                                message: 'Pole wymagane',
                              },
                            ]}
                          >
                            <Select placeholder='Hala garażowa' size='large'>
                              {investment?.garageHalls?.map((garageHall) => (
                                <Option value={garageHall.id}>
                                  {garageHall.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Adres</h2>
                      <div className='premise-investment-address'>
                        <Row gutter={20}>
                          <Col xs={12}>Pobierz adres z inwestycji</Col>
                          <Col xs={12} style={{ textAlign: 'right' }}>
                            <Form.Item
                              name='investmentAddress'
                              valuePropName='checked'
                            >
                              <Switch />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'street']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Ulica' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={6}>
                          <Form.Item name={['address', 'number']}>
                            <Input size='large' placeholder='Numer' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={6}>
                          <Form.Item name={['address', 'local']}>
                            <Input size='large' placeholder='Lokal' />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'city']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input size='large' placeholder='Miejscowość' />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'zip']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <Input.MaskedInput
                              mask='00-000'
                              size='large'
                              placeholder='Kod pocztowy'
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={20}>
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name={['address', 'country']}
                            rules={[
                              {
                                required: true,
                                message: 'Proszę uzupełnić adres',
                              },
                            ]}
                          >
                            <CountrySelect />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                    <div className='form-section'>
                      <h2>Notatka</h2>
                      <Row gutter={20}>
                        <Col xs={24}>
                          <Form.Item name='comment'>
                            <TextArea rows={4} placeholder='Notatka' />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Dodaj
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
