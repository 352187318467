import React, { useEffect } from 'react';
import {
  Form, Row, Col, Button, message,
} from 'antd';
import moment from 'moment';
import packagesService from '../../services/packagesService';
import Input from '../InputComponent';

const { DatePicker, SearchBox } = Input;

const AdminPackageForm = ({ userData, filterType, resourceService }) => {
  const [packageForm] = Form.useForm();

  useEffect(() => {
    let data = {
      packageId: userData.packageId,
    };
    if (userData?.packageExpireDate) {
      // eslint-disable-next-line no-param-reassign
      data = {
        ...data,
        packageExpireDate: moment(userData.packageExpireDate),
      };
    }

    packageForm.setFieldsValue(data);
  }, [packageForm, userData]);

  const onPackageChangeFinish = (values) => {
    if (values?.packageExpireDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        packageExpireDate: values.packageExpireDate.format('YYYY-MM-DD'),
      };
    }
    resourceService.changePackage(userData.id, values.packageId, values.packageExpireDate)
      .then(() => {
        message.success('Zmiana pakietu zakończona pomyślnie');
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        } else {
          message.error('Wystąpił błąd podczas zmiany pakietu');
        }
      });
  };

  const onPackageChangeFinishFailed = () => {
    console.log('fail');
  };

  return (
    <Form
      form={packageForm}
      name="create"
      className="create-form"
      onFinish={onPackageChangeFinish}
      onFinishFailed={onPackageChangeFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Col xs={20} offset={2}>
          <div className="permissions">
            <div className="form-section">
              <h2>Wybrany pakiet</h2>
              <Row gutter={20}>
                <Col xs={12}>
                  <Form.Item
                    name="packageId"
                    rules={[{ required: true, message: 'Pole wymagane' }]}
                  >

                    <SearchBox
                      placeholder="Pakiet"
                      size="large"
                      resource={packagesService}
                      filters={{
                        type: filterType,
                      }}
                      resourceField="name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="packageExpireDate"
                  >
                    <DatePicker size="large" placeholder="Data ważności" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <div className="admin-save-package">
                  <Col xs={24}>
                    <Form.Item>
                      <Button type="primary" size="large" onClick={() => packageForm.submit()} className="create-form-button">
                        Zapisz
                      </Button>
                    </Form.Item>
                  </Col>
                </div>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminPackageForm;
