import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { PageHeader } from 'antd';
import Icon from '../../components/Icon';
import housingAssociationsService from '../../services/housingAssociationsService';
import Spin from '../../components/Spin';

const Address = ({ address }) => (
  <>
    {!!address && (
      <div className='address'>
        {`ul. ${address.street} ${address.number || ''}${
          address.local ? `/${address.local}` : ','
        } ${address.zip} ${address.city}`}
      </div>
    )}
  </>
);

const Tile = ({ name, icon, url }) => (
  <div className='tile-wrapper'>
    <Link to={url}>
      <div className='tile-content'>
        <div className='tile-icon'>
          <Icon name={icon} />
        </div>
        <div className='tile-name'>{name}</div>
      </div>
    </Link>
  </div>
);

const Options = ({ userData }) => {
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.getDetails(id).then((data) => {
      setInvestment(data.investment);
      setLoading(false);
    });
  }, [id]);

  return (
    <div className='show-layout investments-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          // onBack={() => history.push('/housingAssociations')}
          onBack={() => history.goBack()}
          backIcon={<Icon name='arrow-simply-left' />}
          title={'Wróć'}
          className='page-header-extra-2'
        >
          <div className='options-investment-name'>{investment.name}</div>
          <div className='options-investment-address'>
            <Address address={investment.address} />
          </div>

          <div className='tiles-wrapper'>
            <Tile
              name={'Informacje'}
              icon={'more-info'}
              url={`/housingAssociations/${id}/show`}
            />
            <Tile
              name={'Lokale'}
              icon={'apartments'}
              url={`/housingAssociations/${id}/premises`}
            />
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('CLIENTS_FULL'))) && (
              <Tile
                name={'Członkowie'}
                icon={'client'}
                url={`/housingAssociations/${id}/members`}
              />
            )}
            <Tile
              name={'Liczniki'}
              icon={'timer'}
              url={`/investment/${id}/counters`}
            />
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('RESOLUTIONS_FULL'))) && (
              <Tile
                name={'Uchwały'}
                icon={'uchwaly'}
                url={`/resolutions?investmentId=${id}`}
              />
            )}
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('FAULTS_FULL'))) && (
              <Tile
                name={'Usterki'}
                icon={'fault'}
                url={`/housingFaultGroup?investmentId=${id}`}
              />
            )}
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('PAYMENTS_FULL'))) && (
              <Tile
                name={'Rozliczenia'}
                icon={'payments'}
                url={`/investment/${id}/housingSettlements`}
              />
            )}
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('DOCUMENTS_FULL'))) && (
              <Tile
                name={'Dokumenty'}
                icon={'file'}
                url={`/documents?investmentId=${id}`}
              />
            )}
            {(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.workerPermissions?.includes('MESSAGES_FULL'))) && (
              <Tile
                name={'Wiadomości'}
                icon={'chat'}
                url={`/messages?investmentId=${id}`}
              />
            )}
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default Options;
