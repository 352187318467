import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import Icon from '../../../components/Icon';
import memberSettlementsService from '../../../services/memberSettlementsService';

const BalanceBox = ({ balance, tenantBalance = false, showPay = true }) => {
  const history = useHistory();
  const { userId } = useParams();

  const payTheBalance = () => {
    memberSettlementsService.payBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/memberSettlements/null/payment/${response.id}/show`
        );
      }
    });
  };

  const payTenantsBalance = () => {
    memberSettlementsService.payTenantsBalance().then((response) => {
      if (response.id) {
        history.push(
          `/investment/null/premises/null/memberSettlements/null/payment/${response.id}/show?tenantsBalance`
        );
      }
    });
  };

  return (
    <div
      className={`balance-box ${
        balance > 0 ? 'balance-box-red' : 'balance-box-green'
      }`}
    >
      <div className='content-wrapper'>
        <div className='value-wrapper'>
          {parseFloat(-balance).toFixed(2).replace('.', ',')} zł
        </div>
        <div className='title-wrapper'>
          {tenantBalance ? 'Saldo najemców' : 'Saldo'}
        </div>
      </div>
      <div className='buttons-wrapper'>
        {!tenantBalance && (
          <div>
            <Button
              className='history-button'
              onClick={() => {
                if (userId) {
                  history.push(`/members/${userId}/settlements`);
                } else {
                  history.push('/memberSettlements');
                }
              }}
            >
              Historia transakcji
            </Button>
          </div>
        )}
        {showPay && balance > 0 && (
          <div className='bottom-button-wrapper'>
            <Button
              className='balance-button'
              onClick={() =>
                tenantBalance ? payTenantsBalance() : payTheBalance()
              }
            >
              {tenantBalance ? 'Opłać za najemcę' : 'Opłać Saldo'}
              <Icon name='payments' style={{ marginLeft: 5 }} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BalanceBox;
