import React, { useState, useEffect, useRef } from 'react';
import { PageHeader, Dropdown, Button, Menu } from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import investmentsService from '../../../services/investmentsService';
import premisesService from '../../../services/premisesService';
import userService from '../../../services/userService';
import clientsService from '../../../services/clientsService';
import Icon from '../../../components/Icon';
import Datagrid from '../../../components/Datagrid';
import premiseTypeMap from '../../../helpers/premiseTypeMap';
import Spin from '../../../components/Spin';

const menu = () => (
  <Menu>
    <Menu.Item
      key='1'
      icon={<Icon name='apartments' style={{ marginRight: '5px' }} />}
    >
      <Link to={`premises/create/rent`} className='premises-custom-create-link'>
        Mieszkanie
      </Link>
    </Menu.Item>
    <Menu.Item
      key='2'
      icon={<Icon name='apartments-services' style={{ marginRight: '5px' }} />}
    >
      <Link
        to='premises/create/service'
        className='premises-custom-create-link'
      >
        Lokal usługowy
      </Link>
    </Menu.Item>
    <Menu.Item
      key='3'
      icon={<Icon name='parking' style={{ marginRight: '5px' }} />}
    >
      <Link
        to='premises/create/parking'
        className='premises-custom-create-link'
      >
        Miejsce postojowe
      </Link>
    </Menu.Item>
    <Menu.Item
      key='4'
      icon={<Icon name='storage-place' style={{ marginRight: '5px' }} />}
    >
      <Link
        to='premises/create/storage'
        className='premises-custom-create-link'
      >
        Komórka lokatorska
      </Link>
    </Menu.Item>
  </Menu>
);

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [members, setMembers] = useState([]);

  const PremisesService = new premisesService(id);

  const datagridRef = useRef();

  useEffect(() => {
    const queryFilters = {
      role: 'ROLE_COMMUNITY_MEMBER',
      investmentId: id,
      size: 9999,
    };

    clientsService.getList(queryFilters).then((data) => {
      setMembers(data.content);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getDetails(id).then((data) => {
      setInvestment(data.investment);
    });

    userService.getInfo().then((userData) => {
      setUserData(userData);
      setLoading(false);
    });
  }, []);

  const columns = [
    // {
    //   title: 'Lokal',
    //   dataIndex: 'name',
    //   visible: true,
    //   filterEnabled: false,
    //   key: 'premise',
    //   sorter: true,
    //   sortDirections: ['descend', 'ascend'],
    //   render: (text, record) => `${premiseTypeMap(record.type)} ${record.name}`,
    //   exportRender: (text, record) =>
    //     `${premiseTypeMap(record.type)} ${record.name}`,
    // },
    {
      title: 'Numer',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
    },
    {
      title: 'Typ',
      dataIndex: 'type',
      visible: true,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        { value: 'RENT', label: 'Mieszkanie' },
        { value: 'STORAGE', label: 'Komórka lokatorska' },
        { value: 'PARKING', label: 'Miejsce postojowe' },
        { value: 'SERVICE', label: 'Lokal usługowy' },
      ],
      key: 'type',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      export: true,
      render: (text, record) => premiseTypeMap(record.type),
      exportRender: (text, record) => premiseTypeMap(record.type),
    },
    {
      title: 'Właściciel',
      dataIndex: 'premisesOwnerName',
      visible: true,
      key: 'premisesOwnerName',
      filterEnabled: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Właściciel',
      dataIndex: 'premisesOwnerIds',
      visible: false,
      key: 'premisesOwnerIds',
      sorter: false,
      export: false,
      filterEnabled: true,
      filterType: 'select',
      filterSelectMultiple: true,
      filterOptions: members.map((member) => ({
        value: member.id,
        label: `${member.firstName} ${member.lastName}`,
      })),
    },
    {
      title: 'Telefon',
      dataIndex: 'premisesOwnerMobile',
      visible: true,
      key: 'premisesOwnerMobile',
      filterEnabled: true,
      render: (text, record) => (
        <a className='datagrid-link' href={`tel:${record.premisesOwnerMobile}`}>
          {record.premisesOwnerMobile}
        </a>
      ),
    },
    {
      title: 'Adres e-mail',
      dataIndex: 'premisesOwnerEmail',
      visible: true,
      key: 'premisesOwnerEmail',
      filterEnabled: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <a
          className='datagrid-link'
          href={`mailto:${record.premisesOwnerEmail}`}
        >
          {record.premisesOwnerEmail}
        </a>
      ),
    },
  ];

  const CustomCreate = (props) => {
    if (!canEdit) {
      return '';
    }

    const { investment: invest } = props;
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          className='button-secondary import-button'
          onClick={() =>
            history.push(`/housingAssociations/${id}/premises/import`)
          }
        >
          <span style={{ marginRight: 5 }}>Importuj</span>
          <Icon name='download' />
        </Button>
        <Dropdown
          overlay={() => menu(invest)}
          trigger='click'
          placement='bottomCenter'
          className='test'
        >
          <Button type='primary'>
            Dodaj <Icon name='plus' style={{ marginLeft: 10 }} />
          </Button>
        </Dropdown>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Lokale</span>;
    }
    return (
      <span>
        Lokale: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div className='premises-list'>
          <Datagrid
            title={
              <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                backIcon={<Icon name='arrow-simply-left' />}
                onBack={() => window.history.back()}
                title={<HeaderTitle />}
              />
            }
            exportTitle={'Lokale'}
            resource={`investments/${id}/premises`}
            dataProvider={PremisesService}
            columns={columns}
            customCreate={<CustomCreate investment={investment} />}
            hideEdit={!canEdit}
            hideReset={true}
            hideSms={true}
            hideEmail={true}
            showAssignOwners={true}
            showAddCountersReads={true}
            showSendPremisesMessage={true}
            showSendPremisesTemplate={true}
            canEdit={canEdit}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            ref={datagridRef}
            customBaseUrlKey={'housingAssociations'}
          />
        </div>
      )}
    </div>
  );
};

export default List;
