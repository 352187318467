import BaseService from './baseService';

class MessagesService extends BaseService {
  constructor(investmentId) {
    super();

    this.url = `/api/community/investment/${investmentId}/messages`;
  }
}

export default MessagesService;
