import React from 'react';
import {
  Tooltip, Col, Form,
} from 'antd';
import moment from 'moment';
import { Datagrid, Price, Input } from '../../components';
import { receivablesService, investmentsService } from '../../services';
import { receivablesStatusMap, premiseTypeMap } from '../../helpers';

const { Select, SearchBox, DatePicker } = Input;
const { Option } = Select;

const Dot = (props) => {
  const { status } = props;
  let color = 'red';
  if (status === 'PAID') {
    color = 'green';
  }
  if (status === 'PARTIALLY_PAID') {
    color = 'yellow';
  }

  return (
    <Tooltip title={receivablesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const calculateAmountToPay = (source) => source.amount - source.paid;

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ lokalu',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.premisesType),
    exportRender: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Termin płatności',
    dataIndex: 'paymentDate',
    visible: true,
    key: 'paymentDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => receivablesStatusMap(record.status),
  },
  {
    title: 'Do zapłaty (brutto)',
    dataIndex: 'paid',
    visible: true,
    key: 'paid',
    sorter: true,
    summary: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Price value={calculateAmountToPay(record)} />),
    exportRender: (text, record) => calculateAmountToPay(record),
  },
  {
    title: 'Data opłacenia',
    dataIndex: 'paymentDate',
    visible: true,
    key: 'paymentDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Kwota (brutto)',
    dataIndex: 'amount',
    visible: true,
    key: 'amount',
    sorter: true,
    summary: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Price value={record.amount} />),
  },
];

const customRowClass = (record) => {
  if (!record?.expirationDate) {
    return '';
  }

  if (record.status === 'PAID') {
    return '';
  }

  const expirationDate = moment(record.expirationDate);
  if (expirationDate.isBefore(moment(), 'day')) {
    return 'row-error-red';
  }
  return '';
};

const customFilters = [];
customFilters.push(
  <Col xs={24} sm={12} key="empty" />,
);
customFilters.push(
  <Col xs={24} sm={12} key="type">
    <Form.Item
      name="type"
    >
      <Select
        placeholder="Typ"
        allowClear
        size="large"
      >
        <Option value="TRANCHE">
          Transza
        </Option>
        <Option value="TENANT_CHANGE">
          Zmiana lokatorska
        </Option>
      </Select>
    </Form.Item>
  </Col>,
);

customFilters.push(
  <Col xs={24} sm={12} key="premiseType">
    <Form.Item
      name="premisesType"
    >
      <Select
        placeholder="Typ lokalu"
        allowClear
        size="large"
      >
        <Option value="SALE">Mieszkanie</Option>
        <Option value="STORAGE">Komórka lokatorska</Option>
        <Option value="PARKING">Miejsce postojowe</Option>
        <Option value="SERVICE">Lokal usługowy</Option>
      </Select>
    </Form.Item>
  </Col>,
);

customFilters.push(
  <Col xs={24} sm={12}>
    <Form.Item
      name="investmentId"
    >
      <SearchBox
        placeholder="Inwestycja"
        size="large"
        resource={new investmentsService()}
        filters={{
          types: 'SALE,RENT',
        }}
        resourceField="name"
      />
    </Form.Item>
  </Col>,
);

customFilters.push(
  <Col xs={24} sm={12} key="status">
    <Form.Item
      name="status"
    >
      <Select
        placeholder="Status"
        allowClear
        size="large"
      >
        <Option value="TO_PAY">
          <span className="dot red" />
          Do zapłaty
        </Option>
        <Option value="PARTIALLY_PAID">
          <span className="dot yellow" />
          Cześciowo zapłacone
        </Option>
        <Option value="PAID">
          <span className="dot green" />
          Zapłacone
        </Option>
      </Select>
    </Form.Item>
  </Col>,
);

customFilters.push(
  <Col xs={24} sm={12} key="expirationDateFrom">
    <Form.Item
      name="expirationDateFrom"
    >
      <DatePicker size="large" placeholder="Termin płatności od" style={{ width: '100%' }} />
    </Form.Item>
  </Col>,
);

customFilters.push(
  <Col xs={24} sm={12} key="expirationDateTo">
    <Form.Item
      name="expirationDateTo"
    >
      <DatePicker size="large" placeholder="Termin płatności do" style={{ width: '100%' }} />
    </Form.Item>
  </Col>,
);

const List = () => (
  <div>
    <Datagrid
      title="Płatności"
      resource="receivables"
      dataProvider={receivablesService}
      columns={columns}
      hideEdit
      hideShow
      hideCreate
      canEdit={false}
      customRowClass={customRowClass}
      customFilters={customFilters}
    />
  </div>
);

export default List;
