import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import {
  StatsWrapper,
  SettlementsComponent,
  SalePremisesComponent,
  SaleFaultsComponent,
  TenantChangesComponent,
} from './ViewComponents';

const DeveloperDashboard = () => (
  <Row gutter={20}>
    <Col xs={24}>
      <StatsWrapper
        title="Sprzedaż"
        icon="dolar"
        actions={[
          <Link key="investments/stats" to="investments/stats">
            <Button size="small">
              Statystyki
              <Icon name="stats" />
            </Button>
          </Link>,
        ]}
      >
        <SalePremisesComponent />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper
        title="Usterki"
        icon="fault"
        headerLink="faultGroup"
        actions={[
          <Link key="key" to="faultGroup/create">
            <Button size="small">
              Dodaj nową
              <Icon name="plus" />
            </Button>
          </Link>,
        ]}
      >
        <SaleFaultsComponent withHeader={false} />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper
        title="Zmiany lokatorskie"
        icon="flat-changes"
        actions={[
          <Link key="key" to="tenantChangeGroup/create">
            <Button size="small">
              Dodaj nową
              <Icon name="plus" />
            </Button>
          </Link>,
        ]}
      >
        <TenantChangesComponent withHeader={false} />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper title="Rozliczenia" icon="payments">
        <SettlementsComponent />
      </StatsWrapper>
    </Col>
  </Row>
);

export default DeveloperDashboard;
