import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import Price from '../../../../components/Price';

const PriceBox = ({ premise }) => {
  const { status, price, type } = premise;
  const nonEmptyPrices = Object.keys(price)
    .filter((k) => price[k] !== 0)
    .reduce((a, k) => ({ ...a, [k]: price[k] }), {});
  const hasAnyPrice = Object.keys(nonEmptyPrices).length > 0;

  if (!hasAnyPrice) {
    return '';
  }

  const SaleBox = () => (
    <>
      {status === 'FREE' ? (
        <Row gutter={20}>
          {price.minimal !== 0 && (
            <Col xs={24} sm={12}>
              <div className="title">Cena minimalna</div>
              <div className="value"><Price value={price.minimal} /></div>
            </Col>
          )}
          {price.suggest !== 0 && (
            <Col xs={24} sm={12}>
              <div className="title">Sugerowana cena</div>
              <div className="value"><Price value={price.suggest} /></div>
            </Col>
          )}
          {price.minimalPerSquareMeter !== 0 && (
            <Col xs={24} sm={12}>
              <div className="title">
                Cena minimalna za m
                <sup>2</sup>
              </div>
              <div className="value"><Price value={price.minimalPerSquareMeter} /></div>
            </Col>
          )}
          {price.suggestPerSquareMeter !== 0 && (
            <Col xs={24} sm={12}>
              <div className="title">
                Sugerowana cena za m
                <sup>2</sup>
              </div>
              <div className="value"><Price value={price.suggestPerSquareMeter} /></div>
            </Col>
          )}
        </Row>
      ) : (
        <Row gutter={20}>
          {price.sell !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">Cena sprzedaży</div>
            <div className="value"><Price value={price.sell} /></div>
          </Col>
          )}
          {price.sellPerSquareMeter !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">
              Cena za m
              <sup>2</sup>
            </div>
            <div className="value"><Price value={price.sellPerSquareMeter} /></div>
          </Col>
          )}
        </Row>
      )}
    </>
  );
  const StorageBox = () => (
    <>
      {status === 'FREE' ? (
        <Row gutter={20}>
          {price.suggest !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">Cena</div>
            <div className="value"><Price value={price.suggest} /></div>
          </Col>
          )}
          {price.suggestPerSquareMeter !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">
              Cena za m
              <sup>2</sup>
            </div>
            <div className="value"><Price value={price.suggestPerSquareMeter} /></div>
          </Col>
          )}
        </Row>
      ) : (
        <Row gutter={20}>
          {price.sell !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">Cena sprzedaży</div>
            <div className="value"><Price value={price.sell} /></div>
          </Col>
          )}
          {price.sellPerSquareMeter !== 0 && (
          <Col xs={24} sm={12}>
            <div className="title">
              Cena sprzedaży za m
              <sup>2</sup>
            </div>
            <div className="value"><Price value={price.sellPerSquareMeter} /></div>
          </Col>
          )}
        </Row>
      )}
    </>
  );
  const ParkingBox = () => (
    <>
      {status === 'FREE' ? (
        price.suggest !== 0 && (
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <div className="title">Cena</div>
              <div className="value"><Price value={price.suggest} /></div>
            </Col>
          </Row>
        )
      ) : (
        price.sell !== 0 && (
        <Row gutter={20}>
          <Col xs={24} sm={12}>
            <div className="title">Cena sprzedaży</div>
            <div className="value"><Price value={price.sell} /></div>
          </Col>
        </Row>
        )
      )}
    </>
  );
  return (
    <div className="premises-show-box price-box">
      <h2>
        <Icon name="more-info" />
        Cena
      </h2>
      <div className="content">
        {(type === 'SALE' || type === 'SERVICE') && (<SaleBox />)}
        {type === 'STORAGE' && (<StorageBox />)}
        {type === 'PARKING' && (<ParkingBox />)}
      </div>
    </div>
  );
};

export default PriceBox;
