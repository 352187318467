import React from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { Icon } from '../../../components';

import settlementTemplatesService from '../../../services/settlementTemplatesService';

const ActivateTemplate = ({ settlementData, setSettlementData }) => {
  const { id } = useParams();
  const history = useHistory();

  const activateTemplate = () => {
    const tempData = {
      ...settlementData,
      active: true,
    };

    const SettlementTemplatesService = new settlementTemplatesService(id);

    SettlementTemplatesService.update(tempData)
      .then((response) => {
        setSettlementData(response);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  return (
    <div className='activate-template-wrapper'>
      <div>
        <Icon name='error-circle' className='activate-template-icon' />
      </div>

      <div className='activate-template-info'>Brak aktywnych rozliczeń</div>
      <div>
        {settlementData ? (
          <Button
            className='button-secondary activate-btn'
            onClick={() => activateTemplate()}
          >
            Aktywuj
          </Button>
        ) : (
          <Link to={`/investment/${id}/housingSettlements/create`}>
            <Button className='button-secondary activate-btn'>Aktywuj</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default ActivateTemplate;
