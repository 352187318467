import React, { useEffect, useState } from 'react';
import {
  PageHeader, Col, Form,
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import investmentsSettlementsService from '../../services/investmentsSettlementsService';
import investmentsService from '../../services/investmentsService';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Spin from '../../components/Spin';

const { DatePicker } = Input;
const columns = [
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Cena sprzedaży',
    dataIndex: 'price',
    visible: true,
    key: 'price',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Price value={record.price} />),
  },
  {
    title: 'Prowizja',
    dataIndex: 'commision',
    visible: true,
    key: 'commision',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (`${record.commission} %`),
  },
  {
    title: 'Prowizja',
    dataIndex: 'commissionValue',
    visible: true,
    key: 'commissionValue',
    sorter: false,
    summary: true,
    render: (text, record) => (<Price value={record.commissionValue} />),
  },
];

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const InvestmentsSettlementsService = new investmentsSettlementsService(id);

  const [loading, setLoading] = useState(true);
  const [investment, setInvestment] = useState([]);
  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getOne(id).then((investmentData) => {
      setInvestment(investmentData);
      setLoading(false);
    });
  }, [id]);

  const CustomCreate = () => ('');

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="premisesName">
      <Form.Item
        name="premisesName"
      >
        <Input size="large" placeholder="Numer" />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="settleDate">
      <Form.Item
        name="settleDate"
      >
        <DatePicker size="large" placeholder="Data sprzedaży" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Rozliczenia</span>;
    }
    return (
      <span>
        Rozliczenia:
        {' '}
        <span className="header-subtitle">{investment.name}</span>
      </span>
    );
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/investments/${id}/show`)}
          title={<HeaderTitle />}
        >
          <div className="premises-list">
            <Datagrid
              resource={`investments/${id}/settlements`}
              dataProvider={InvestmentsSettlementsService}
              columns={columns}
              customCreate={<CustomCreate />}
              customBaseUrlKey="workerSettlements"
              hideRowActions
              customFilters={customFilters}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
