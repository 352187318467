import React from 'react';
import {
  Col, Row, Form,
} from 'antd';
import Input from './InputComponent';

const IdentityConfirmForm = () => (
  <div className="identity">
    <div className="create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <div className="form-section">
            <h2>Dane dowodu osobistego</h2>
            <Row gutter={20}>
              <Col xs={24}>
                <Form.Item
                  name={['identity', 'pesel']}
                >
                  <Input.MaskedInput mask="00000000000" size="large" placeholder="Pesel" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['identity', 'idNumber']}
                >
                  <Input.MaskedInput mask="aaa000000" size="large" placeholder="Numer dowodu osobistego" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['identity', 'idExpireDate']}
                >
                  <Input.DatePicker size="large" placeholder="Termin wazności" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['identity', 'fatherName']}
                >
                  <Input size="large" placeholder="Imię ojca" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['identity', 'motherName']}
                >
                  <Input size="large" placeholder="Imię matki" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default IdentityConfirmForm;
