import { Modal } from 'antd';

const { confirm } = Modal;

const deleteConfirm = (handleOk) => {
  confirm({
    title: 'Czy na pewno chcesz zmienić plan?',
    content: 'Po kliknięciu „Zmień plan”, przywrócenie poprzedniego pakietu nie będzie możliwe.',
    okText: 'Zmień plan',
    okType: 'primary',
    cancelText: 'Anuluj',
    icon: false,
    width: 320,
    className: 'delete-confirm-modal',
    cancelButtonProps: { className: 'button-secondary', size: 'large' },
    okButtonProps: { size: 'large' },
    onOk() {
      handleOk();
    },
  });
};

export default deleteConfirm;
