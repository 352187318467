import BaseService from './baseService';

class UserPaymentsService extends BaseService {
  constructor(userId) {
    super();
    this.url = `/api/vendor/user/${userId}/payment`;
  }
}

export default UserPaymentsService;
