import React from 'react';

const CustomEmptyComponent = () => (
  <div style={{
    textAlign: 'center', border: '1px dashed #C0D6E5', color: '#C0D6E5', padding: '30px', borderRadius: '20px', width: '100%',
  }}
  >
    <div>Pusto</div>
  </div>
);

export default CustomEmptyComponent;
