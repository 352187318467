import React, { useEffect, useState } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Card, Divider, message,
} from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import tenantChangesService from '../../../services/tenantChangeService';
import tenantChangeGroups from '../../../services/tenantChangeGroups';
import workersService from '../../../services/workersService';
import userService from '../../../services/userService';
import Icon from '../../../components/Icon';
import deleteConfirm from '../../../components/deleteConfirm';
import { Attachments } from './editComponents/Attachments';
import { Tags } from './editComponents/Tags';

import Input from '../../../components/InputComponent';
import Tabs from '../../../components/Tabs';

const {
  DatePicker, Select, TextArea, DebounceSelect, PriceInput,
} = Input;
const { TabPane } = Tabs;
const { Option } = Select;

const Edit = () => {
  const {
    id, premisesId, investmentId, tenantChangeGroupId,
  } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [workers, setWorkers] = useState([]);
  const [tenantChange, setTenantChange] = useState({});
  const [tenantChangeGroup, setTenantChangeGroup] = useState({});
  const [userData, setUserData] = useState({});
  const [disableWorkers, setDisableWorkers] = useState(false);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      const { roles, workerPermissions } = data;
      if (!roles.includes('ROLE_WORKER')) {
        return;
      }

      setDisableWorkers(!workerPermissions.includes('WORKERS_FULL'));
    });

    const TenantChangesGroupService = new tenantChangeGroups(investmentId, premisesId, null);
    TenantChangesGroupService.getOne(tenantChangeGroupId).then((tenantChangeGroupData) => {
      setTenantChangeGroup(tenantChangeGroupData);
    });

    const TenantChangesService = new tenantChangesService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangesService.getOne(id).then((tenantChangeData) => {
      const tempData = {
        ...tenantChangeData,
        addDate: tenantChangeData.addDate ? moment(tenantChangeData.addDate) : null,
        changeDate: tenantChangeData.changeDate ? moment(tenantChangeData.changeDate) : null,
        expirationDate: tenantChangeData.expirationDate
          ? moment(tenantChangeData.expirationDate) : null,
        paymentDate: tenantChangeData.paymentDate ? moment(tenantChangeData.paymentDate) : null,
      };
      form.setFieldsValue(tempData);
      setTenantChange(tenantChangeData);
    });
  }, [id, premisesId, investmentId, form, tenantChangeGroupId]);

  const onFinish = (values) => {
    if (values?.addDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        addDate: values.addDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.changeDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        changeDate: values.changeDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.expirationDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        expirationDate: values.expirationDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.paymentDate) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        paymentDate: values.paymentDate.format('YYYY-MM-DD'),
      };
    }
    if (values?.responsibleWorker && Array.isArray(values.responsibleWorker)) {
      // eslint-disable-next-line no-param-reassign
      values = {
        ...values,
        responsibleWorker: values.responsibleWorker[0],
      };
    }
    const TenantChangesService = new tenantChangesService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangesService.update(id, values).then((response) => {
      if (response.id) {
        history.push(`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleOk = () => {
    const TenantChangesService = new tenantChangesService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangesService.delete(id)
      .then(() => history.push(`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/edit`))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
  };

  const handleBack = () => window.history.back();

  const HeaderTitle = () => {
    if (!tenantChange.name) {
      return <span>Edycja</span>;
    }

    if (!tenantChangeGroup.name) {
      return <span>Edycja</span>;
    }

    return (
      <span>
        Edytujesz:
        {' '}
        <span className="header-subtitle">{tenantChange.name}</span>
        {' w '}
        <span className="header-subtitle">{tenantChangeGroup.name}</span>
      </span>
    );
  };

  async function fetchUserList(username) {
    return workersService.getList(
      { role: 'ROLE_WORKER', pagination: { pageSize: 1000 } },
    ).then((clientsData) => clientsData.content)
      .then((clientsData) => {
        const filteredResults = clientsData.filter((entry) => {
          const { firstName = '', lastName } = entry;
          if (!username) {
            return true;
          }
          const firstNameCheck = firstName.toUpperCase().search(username.toUpperCase());
          const lastNameCheck = lastName.toUpperCase().search(username.toUpperCase());

          return firstNameCheck !== -1 || lastNameCheck !== -1;
        });
        return filteredResults;
      })
      .then((clientsData) => clientsData.map((client) => {
        const pesel = client.identity && client.identity.pesel ? `(${client.identity.pesel})` : '';
        return {
          label: `${client.firstName} ${client.lastName} ${pesel}`,
          value: client.id,
        };
      }));
  }

  const isClient = userData?.roles?.includes('ROLE_CLIENT') || false;

  return (
    <div className="form-create tenantChanges-edit">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => handleBack()}
        title={<HeaderTitle />}
        extra={[
          <Button type="danger" onClick={() => deleteConfirm(handleOk)}>
            <Icon name="delete" style={{ marginRight: '5px' }} />
            {' '}
            Usuń
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <>
            <Card className="form-create-main-card">
              <Tabs defaultActiveKey="1" tabPosition="left">
                <TabPane tab="Informacje" key="form">
                  <div className="create-form-wrapper">
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className="form-section">
                          <h2>Zmiana lokatorska</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="name"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Input size="large" placeholder="Nazwa" />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="status"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <Select
                                  placeholder="Status"
                                  allowClear
                                  size="large"
                                >
                                  <Option value="ACTIVE">
                                    <span className="dot red" />
                                    Do zmiany
                                  </Option>
                                  <Option value="DONE">
                                    <span className="dot green" />
                                    Zmienione
                                  </Option>
                                  <Option value="REJECTED">
                                    <span className="dot blue" />
                                    Odrzucona
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="addDate"
                                rules={[{ required: true, message: 'Pole wymagane' }]}
                              >
                                <DatePicker size="large" placeholder="Data dodania" style={{ width: '100%' }} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name="changeDate"
                              >
                                <DatePicker size="large" placeholder="Data wprowadzenia zmian" style={{ width: '100%' }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className="form-section">
                          <h2>Szczegóły</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name="responsibleWorker"
                              >
                                <DebounceSelect
                                  mode="multiple"
                                  value={workers}
                                  disabled={disableWorkers}
                                  placeholder="Osoba odpowiedzialna"
                                  size="large"
                                  fetchOptions={fetchUserList}
                                  onChange={(newValue) => {
                                    setWorkers(newValue.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                name="description"
                              >
                                <TextArea rows={4} placeholder="Opis" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tab="Załączniki" key="attachments">
                  <Attachments
                    investmentId={investmentId}
                    premisesId={premisesId}
                    tenantChangeGroupId={tenantChangeGroupId}
                    tenantChangeId={id}
                  />
                </TabPane>
                <TabPane tab="Finanse" key="finance" forceRender>
                  <div className="finance">
                    <div className="create-form-wrapper">
                      <Row>
                        <Col xs={20} offset={2}>
                          <div className="form-section">
                            <h2>Lista finansów</h2>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="expirationDate"
                                >
                                  <DatePicker size="large" placeholder="Termin płatności" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="paymentStatus"
                                  rules={[{ required: true, message: 'Pole wymagane' }]}
                                >
                                  <Select
                                    placeholder="Status"
                                    allowClear
                                    size="large"
                                  >
                                    <Option value="TO_PAY">
                                      <span className="dot red" />
                                      Do zapłaty
                                    </Option>
                                    <Option value="PARTIALLY_PAID">
                                      <span className="dot yellow" />
                                      Cześciowo zapłacone
                                    </Option>
                                    <Option value="PAID">
                                      <span className="dot green" />
                                      Zapłacone
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="paymentDate"
                                >
                                  <DatePicker size="large" placeholder="Data opłacenia" style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="paid"
                                >
                                  <PriceInput size="large" placeholder="Zapłacono" />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name="amount"
                                >
                                  <PriceInput size="large" placeholder="Kwota" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </TabPane>
                {!isClient && (
                  <TabPane tab="Tagi" key="tags">
                    <Tags
                      investmentId={investmentId}
                      tenantChangeId={id}
                      tenantChangeGroupId={tenantChangeGroupId}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Card>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleBack}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Edit;
