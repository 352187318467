import React, { useState, useEffect } from 'react';
import { Row, Col, Upload, Button, Menu, message } from 'antd';
import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';

export const Attachments = ({
  documents = [],
  setDocuments,
  setDocument = false,
  edit = false,
}) => {
  const columns = [
    {
      dataIndex: 'filename',
      editable: edit ? true : false,
    },
  ];

  const deleteAction = (entityId) => {
    setDocuments([]);
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 64;
    if (!isLt2M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 64MB
        </MessagePopup>
      );
    }
    return isLt2M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      // customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
      fileList: documents,
      onChange: (info) => {
        setDocuments(info.fileList.map((el) => ({ ...el, filename: el.name })));
      },
    };
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          // onClick={addAction}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  const updateAction = (entityId, data) => {
    if (data.filename) {
      let tempFilename = data.filename;
      const oldExt = documents[0].filename.split('.').pop();
      const fileExt = data.filename.split('.').pop();

      if (fileExt !== oldExt) {
        tempFilename = `${data.filename}.${oldExt}`;
      }

      setDocument((prev) => ({ ...prev, filename: tempFilename }));
      setDocuments((prevList) =>
        prevList.map((el) => ({ ...el, filename: tempFilename }))
      );
    }
  };

  const handleDownload = (record) => {
    const a = document.createElement('a');
    a.href = `data:application/octet-stream;base64,${record.content}`;
    a.download = record.filename;
    a.click();
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const downloadAction = (
      <Menu.Item
        key='1'
        onClick={() => handleDownload(record)}
        icon={<Icon name='download' style={{ marginRight: '5px' }} />}
      >
        Pobierz
      </Menu.Item>
    );
    if (edit) {
      tempArray.push(downloadAction);
    }

    return tempArray;
  };

  return (
    <div className='attachments create-form-wrapper'>
      <Row>
        <Col xs={24}>
          <SmallList
            icon='file'
            data={documents}
            columns={columns}
            title='Dokumenty'
            inlineEditLabel='Zmień nazwę'
            customAddButton={
              documents.length === 0 ? <CustomAddButton /> : null
            }
            hideEdit
            hideDelete={edit}
            deleteAction={!edit ? deleteAction : null}
            updateAction={updateAction}
            customMenuActions={customMenuActions}
          />
          <div
            className='attachmentsNote'
            style={{ fontSize: '12px', color: '#C0D6E5' }}
          >
            Maksymalny rozmiar pliku: 64MB.
          </div>
        </Col>
      </Row>
    </div>
  );
};
