import BaseService from './baseService';

class SubCountersHistoryService extends BaseService {
  constructor(investmentId, mainCounterId, subCounterId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/mainCounters/${mainCounterId}/subCounters/${subCounterId}/history`;
  }
}

export default SubCountersHistoryService;
