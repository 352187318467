import React from 'react';
import { Tooltip } from 'antd';
import Icon from '../../../../components/Icon';

const SharesBox = ({ premise }) => (
  <div
    className='premises-show-box info-box secondary-bg'
    style={{ color: '#fff', padding: 25, paddingRight: 10 }}
  >
    <h2
      className='shares-tooltip-header'
      style={{ fontSize: 10, textAlign: 'right' }}
    >
      <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
      <Tooltip
        placement='bottomLeft'
        title={
          'Wyliczany na podstawie lokali, w których lokator jest właścicielem'
        }
        overlayInnerStyle={{
          fontSize: 10,
          fontWeight: 600,
          minHeight: 20,
        }}
        showArrow={false}
        trigger={'hover'}
      >
        <Icon
          name={'important'}
          style={{
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    </h2>
    <div className='content'>
      <div style={{ fontSize: 54, fontWeight: 700, marginTop: 40 }}>
        {premise?.participation?.percent
          ?.toFixed(2)
          .toString()
          .replace('.', ',')}
        %
      </div>
      <div style={{ fontSize: 24, lineHeight: '32px' }}>
        <span style={{ fontWeight: 700 }}>
          {premise?.totalSurface?.toFixed(2)}
        </span>
        {'  '}/ {premise?.participation?.investmentSize?.toFixed(2)}
      </div>
    </div>
  </div>
);

export default SharesBox;
