import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  PageHeader, Button, Row, Col, Card,
} from 'antd';
import packagesService from '../../services/packagesService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Price from '../../components/Price';
import packageTypeMap from '../../helpers/packageTypeMap';
import packageStatusMap from '../../helpers/packageStatusMap';

const LimitsRenderer = ({ value }) => {
  if (value === -1) {
    return '∞';
  }

  return value;
};

const Show = () => {
  const { id } = useParams();
  const history = useHistory();
  const [packageData, setPackageData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    packagesService.getOne(id).then((data) => {
      setPackageData(data);
      setLoading(false);
    });
  }, [id]);

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/packages')}
          backIcon={<Icon name="arrow-simply-left" />}
          title="Wróć"
          className="page-header-extra-2"
          extra={[
            <Button key="1" className="button-secondary" onClick={() => window.print()}>
              Drukuj
              {' '}
              <Icon name="download" />
            </Button>,
            <Link key="2" to={`/packages/${packageData.id}/edit`}>
              <Button type="primary">
                Edytuj
                {' '}
                <Icon name="edit" />
              </Button>
            </Link>,
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className="left">
                  <div className="name">
                    <span style={{ color: packageData.color ? packageData.color : 'inherit' }}>
                      {packageData.name}
                      {' '}
                      <sup>{packageData.type === 'RENT' ? 'N' : 'S'}</sup>
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className="right">
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="user" />
                        Informacje
                      </h2>
                    )}
                    className="informations"
                  >
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <div className="title">Nazwa:</div>
                        <div className="">
                          {packageData.name}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className="title">Status:</div>
                        <div className="">
                          {packageStatusMap(packageData.status)}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className="title">Typ:</div>
                        <div className="">
                          {packageTypeMap(packageData.type)}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className="title">Cena:</div>
                        <div className="">
                          <Price value={packageData.price} />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title={(
                      <h2 className="show-layout-card-header">
                        <Icon name="access" />
                        Ograniczenia
                      </h2>
                    )}
                    className="access"
                  >
                    {packageData.type === 'RENT' ? (
                      <Row>
                        <Col xs={24} sm={8}>
                          Mieszkania i lokale usługowe
                        </Col>
                        <Col sxs={24} sm={16} className="value">
                          <LimitsRenderer value={packageData.limits.premisesRent} />
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row>
                          <Col xs={24} sm={8}>
                            Inwestycje
                          </Col>
                          <Col sxs={24} sm={16} className="value">
                            <LimitsRenderer value={packageData.limits.investments} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={8}>
                            Mieszkania
                          </Col>
                          <Col sxs={24} sm={16} className="value">
                            <LimitsRenderer value={packageData.limits.premises} />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} sm={8}>
                            Lokale usługowe
                          </Col>
                          <Col sxs={24} sm={16} className="value">
                            <LimitsRenderer value={packageData.limits.service} />
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col xs={24} sm={8}>
                        Miejsca parkingowe
                      </Col>
                      <Col sxs={24} sm={16} className="value">
                        <LimitsRenderer value={packageData.limits.parking} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={8}>
                        Komórki lokatorskie
                      </Col>
                      <Col sxs={24} sm={16} className="value">
                        <LimitsRenderer value={packageData.limits.storage} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={8}>
                        Dostępny dla użytkowników
                      </Col>
                      <Col sxs={24} sm={16} className="value">
                        {packageData.availableForUser ? 'TAK' : 'NIE'}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
