import React, { useState, useEffect } from 'react';
import {
  Row, Col, Menu, message,
} from 'antd';
import { Link } from 'react-router-dom';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import BrokerModal from '../modals/BrokerModal';
import brokersService from '../../../../services/brokersService';
import workersService from '../../../../services/workersService';
import settlementsService from '../../../../services/settlementsService';

export const Brokers = ({ investmentId, premisesId }) => {
  const [brokersModal, setBrokersModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [brokers, setBrokers] = useState([]);

  useEffect(() => {
    const BrokersService = new brokersService(investmentId, premisesId);
    BrokersService.getList().then((data) => {
      const workerPromises = data.map((broker) => workersService.getOne(broker.workerId));

      Promise.all(workerPromises).then((results) => {
        const temp = data.map((broker) => {
          const worker = results.find((obj) => obj.id === broker.workerId);
          return {
            ...broker,
            name: `${worker.firstName} ${worker.lastName}`,
            mobile: worker.mobile,
          };
        });
        setBrokers(temp);
      });
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const BrokersService = new brokersService(investmentId, premisesId);
    BrokersService.getList().then((data) => {
      const workerPromises = data.map((broker) => workersService.getOne(broker.workerId));

      Promise.all(workerPromises).then((results) => {
        const temp = data.map((broker) => {
          const worker = results.find((obj) => obj.id === broker.workerId);
          return {
            ...broker,
            name: `${worker.firstName} ${worker.lastName}`,
            mobile: worker.mobile,
          };
        });
        setBrokers(temp);
      });
    });
  };

  const columns = [
    {
      title: 'Imię i nazwisko',
      dataIndex: 'name',
      render: (text, record) => (<UserRenderer record={record} />),
    },
    {
      title: 'Telefon',
      dataIndex: 'mobile',
    },
    {
      title: 'Procent zysku',
      dataIndex: 'percent',
      render: (text, record) => (`${record.percent} %`),
    },
  ];

  const UserRenderer = ({ record }) => {
    const url = `/workers/${record.id}/show`;
    return <Link className="smalllist-link" to={url}>{record.name}</Link>;
  };

  const addNewAction = (data) => {
    setModalData(data);
    setBrokersModal(true);
  };
  const afterSave = () => {
    setBrokersModal(false);
    setModalData({});
    refresh();
  };
  const afterCancel = () => {
    setBrokersModal(false);
    setModalData({});
  };
  const deleteAction = (entityId) => {
    const BrokersService = new brokersService(investmentId, premisesId);
    BrokersService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const handleSettlement = (brokerId) => {
    const SettlementsService = new settlementsService(investmentId, premisesId, brokerId);
    SettlementsService.create().then(() => {
      message.success('Rozliczenie zostało stworzone');
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      } else {
        message.error('Wystąpił błąd podczas tworzenia rozliczenia');
      }
    });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const settleAction = (
      <Menu.Item key="3" onClick={() => handleSettlement(record.id)} icon={<Icon name="dolar" style={{ marginRight: '5px' }} />}>
        Rozlicz
      </Menu.Item>
    );
    tempArray.push(settleAction);
    return tempArray;
  };

  return (
    <div className="brokers create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList
            icon="percent"
            data={brokers}
            columns={columns}
            title="Lista pośredników"
            addAction={addNewAction}
            deleteAction={deleteAction}
            customMenuActions={customMenuActions}
          />
        </Col>
      </Row>
      {brokersModal && (
        <BrokerModal
          data={modalData}
          isModalVisible={brokersModal}
          afterSave={afterSave}
          afterCancel={afterCancel}
        />
      )}
    </div>
  );
};
