import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberDashboardService extends BaseService {
  constructor() {
    super();
    this.url = '/api/community/member/dashboard';
  }

  async getBalance() {
    return httpService.get(`${this.url}/balance`, null, true);
  }

  async getPremises() {
    return httpService.get(`${this.url}/premises`, null, true);
  }

  async getResolutions() {
    return httpService.get(`${this.url}/resolutions`, null, true);
  }
}

export default MemberDashboardService;
