/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';
import Empty from '../../../../components/Empty';
import Price from '../../../../components/Price';

export const RoomBox = ({ investment, rooms, premise }) => {
  let title = 'Koszty najmu';
  let icon = 'payments';
  if (investment.type === 'SALE') {
    title = 'Podział';
    icon = 'dashboard';
  }
  let totalRent = 0;
  let totalCharges = 0;
  let totalDeposit = 0;
  if (investment.type === 'RENT') {
    rooms.forEach(({ rent, charges, deposit }) => {
      totalRent += rent;
      totalCharges += charges;
      totalDeposit += deposit;
    });
  }
  return (
    <div className="premises-show-box room-box">
      <h2>
        <Icon name={icon} />
        {title}
      </h2>
      <div className="content">
        <>
          {investment.type === 'RENT' && (
          <div className="room-box-costs">
            <Row className="entry">
              <Col className="label" xs={10} sm={{ span: 8, offset: 1 }}>
                Czynsz:
              </Col>
              <Col className="value" xs={14} sm={4}>
                <Price value={totalRent} showEmpty />
              </Col>
            </Row>
            <Row className="entry">
              <Col className="label" xs={10} sm={{ span: 8, offset: 1 }}>
                Opłaty:
              </Col>
              <Col className="value" xs={14} sm={4}>
                <Price value={totalCharges} showEmpty />
              </Col>
            </Row>
            <Row className="entry">
              <Col className="label" xs={10} sm={{ span: 8, offset: 1 }}>
                Kaucja:
              </Col>
              <Col className="value" xs={14} sm={4}>
                <Price value={totalDeposit} showEmpty />
              </Col>
            </Row>
          </div>
          )}
          <Row gutter={20}>
            {rooms.map((roomData) => (
              <Col xs={24} sm={8} key={roomData.id}>
                <div className="rooms-list-room">
                  <div className="rooms-list-room-name">{roomData.name}</div>
                  <div className="rooms-list-room-size">
                    {roomData.size}
                    {' '}
                    m
                    <sup>2</sup>
                  </div>
                  {premise.type === 'RENT' && (
                  <div className="rooms-list-room-costs">
                    <div className="rooms-list-room-costs-rent">
                      {roomData.rent}
                      {' '}
                      zł / miesiąc
                    </div>
                    <div className="rooms-list-room-costs-charges">
                      +
                      {' '}
                      {roomData.charges}
                      {' '}
                      zł / opłaty
                    </div>
                    <div className="rooms-list-room-costs-deposit">
                      Kaucja:
                      {' '}
                      {roomData.deposit}
                      {' '}
                      zł
                    </div>
                  </div>
                  )}
                </div>
              </Col>
            ))}
            {rooms.length === 0 && (<Empty />)}
          </Row>
        </>
      </div>
    </div>
  );
};
