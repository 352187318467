import BaseService from './baseService';
import httpService from '../helpers/httpService';

class FaultGroupTagsService extends BaseService {
  constructor() {
    super();
    this.url = '/api/vendor/tags';
  }

  async getAllTenantChangeGroups() {
    return httpService.get(`${this.url}/tenantChange`, null, true);
  }

  async getTenantChangeGroupTags(investmentId, tenantChangeGroupId) {
    return httpService.get(`${this.url}/investment/${investmentId}/tenantChangeGroup/${tenantChangeGroupId}`, null, true);
  }
}

export default FaultGroupTagsService;
