/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../../../../components/Icon';

export const OwnerBox = ({ premise }) => {
  if (!premise) {
    return null;
  }

  if (Object.keys(premise).length === 0) {
    return null;
  }

  if (!premise.premisesOwnerId) {
    return null;
  }

  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='client' />
        Właściciel
      </h2>
      <div className='content'>
        <Row gutter={20}>
          <Col xs={24}>
            <div className='owner-box-content'>
              <div className='item'>
                {premise.premisesOwner.firstName}{' '}
                {premise.premisesOwner.lastName}
              </div>
              <div className='item'>
                <a
                  className='datagrid-link'
                  href={`tel:${premise.premisesOwner.mobile}`}
                  style={{ color: '#3B4C60' }}
                >
                  {premise.premisesOwner.mobile}
                </a>
              </div>
              <div className='item'>
                <a
                  className='datagrid-link'
                  href={`mailto:${premise.premisesOwner.email}`}
                  style={{ color: '#3B4C60' }}
                >
                  {premise.premisesOwner.email}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
