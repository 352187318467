const statusMap = {
  FREE: 'Wolne',
  RESERVATION: 'Zarezerwowane',
  SOLD: 'Sprzedane',
  RENT: 'Wynajęte',
  PARTIAL_RENT: 'Częściowo wynajęte',
};
const getStatusName = (status) => statusMap[status] || '';

export default getStatusName;
