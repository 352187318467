import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import membersService from '../../../services/membersService';
import Icon from '../../../components/Icon';
import Spin from '../../../components/Spin';
import memberAssociationsService from '../../../services/memberAssociationsService';

const SharesBox = ({ premise, premisesView = false, myAccView = false }) => {
  const { investmentId, userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [participation, setParticipation] = useState(false);

  useEffect(() => {
    if (!myAccView) {
      membersService.getParticipation(investmentId, userId).then((data) => {
        setParticipation(data);
      });
      setLoading(false);
    } else {
      const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');

      if (choosenInvestmentId) {
        const id = JSON.parse(choosenInvestmentId);
        const MemberAssociationsService = new memberAssociationsService(id);

        MemberAssociationsService.getParticipation(id).then((data) => {
          setParticipation(data);
        });
        setLoading(false);
      }
    }
  }, []);

  return !participation ? null : (
    <div
      className='premises-show-box info-box secondary-bg'
      style={{
        color: '#fff',
        padding: 25,
        marginTop: premisesView ? 0 : 25,
      }}
    >
      <h2
        className='shares-tooltip-header'
        style={{ fontSize: 10, textAlign: 'right', marginBottom: 10 }}
      >
        <span style={{ marginRight: 8 }}>Udział w nieruchomości</span>
      </h2>
      {loading ? (
        <Spin />
      ) : (
        <div className='content'>
          <div style={{ fontSize: 32, fontWeight: 700, marginTop: 10 }}>
            {participation?.percent?.toFixed(2).toString().replace('.', ',')}%
          </div>
          <div style={{ fontSize: 18, lineHeight: '32px' }}>
            Udział:
            <span style={{ fontWeight: 700, marginLeft: 5 }}>
              {participation?.premisesSize?.toFixed(2)}
            </span>
            {'  '}/ {participation?.investmentSize?.toFixed(2)}
          </div>
        </div>
      )}
    </div>
  );
};

export default SharesBox;
