import React from 'react';

const Address = ({ title, address }) => {
  if (!address || !address.street) {
    return '';
  }

  return (
    <>
      {!!address && (
        <div className="address">
          <div className="title">
            {title}
            :
          </div>
          <div className="place">
            {`${address.street || ''} ${address.number || ''}${address.local ? `/${address.local}` : ''}`}
          </div>
          <div className="city">
            {`${address.zip || ''}, ${address.city || ''}`}
          </div>
        </div>
      )}
    </>
  );
};

export default Address;
