import React, { useState, useEffect } from 'react';
import {
  Row, Col, Table, Typography,
} from 'antd';

import SmallList from '../../../../components/SmallList';
import Price from '../../../../components/Price';
import roomsService from '../../../../services/roomsService';

const { Text } = Typography;

export const Costs = (props) => {
  const [rooms, setRooms] = useState([]);
  const { investmentId, premisesId } = props;

  useEffect(() => {
    const RoomsService = new roomsService(investmentId, premisesId);
    RoomsService.getList().then((data) => {
      setRooms(data);
    });
  }, [investmentId, premisesId]);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Czynsz',
      dataIndex: 'rent',
      render: (text, record) => (<Price value={record.rent} showEmpty />),
    },
    {
      title: 'Opłaty',
      dataIndex: 'charges',
      render: (text, record) => (<Price value={record.charges} showEmpty />),
    },
    {
      title: 'Kaucja',
      dataIndex: 'deposit',
      render: (text, record) => (<Price value={record.deposit} showEmpty />),
    },
  ];

  let totalRent = 0;
  let totalCharges = 0;
  let totalDeposit = 0;
  rooms.forEach(({ rent, charges, deposit }) => {
    totalRent += rent;
    totalCharges += charges;
    totalDeposit += deposit;
  });
  const Summary = () => (rooms.length > 0 ? (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          <Text style={{ color: '#C0D5E4' }}>Suma</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Text style={{ fontWeight: 'bold' }}><Price value={totalRent} showEmpty /></Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Text style={{ fontWeight: 'bold' }}><Price value={totalCharges} showEmpty /></Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Text style={{ fontWeight: 'bold' }}><Price value={totalDeposit} showEmpty /></Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  ) : (<></>));

  return (
    <div className="costs create-form-wrapper">
      <Row>
        <Col span={20} offset={2}>
          <SmallList data={rooms} columns={columns} summary={<Summary />} title="Opłaty miesięczne" icon="dolar" />
        </Col>
      </Row>
    </div>
  );
};
