import React from 'react';
import { Card, Col, Row } from 'antd';
import { renderUnit } from '../../../helpers/unitRender';
import { Icon } from '../../../components';
import { premiseTypeMap } from '../../../helpers';

const percentRender = (percent) => {
  const value = parseFloat(percent);
  if (value >= 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const Atribute = ({
  label,
  value = false,
  counterUnit = false,
  copy = false,
  additional = false,
}) => (
  <Col xs={24} lg={12} xl={8} style={{ paddingRight: 10 }}>
    <Row className='sub-counter-card-label-wrapper'>{label}:</Row>
    <Row className='sub-counter-card-value-wrapper'>
      {additional !== false && (
        <div
          style={{ color: additional >= 0 ? '#15D55E' : '#EC434C' }}
          className='sub-counter-card-additional'
        >
          {percentRender(additional)}
        </div>
      )}
      {counterUnit ? (
        <span>
          {value !== false ? `${parseFloat(value).toFixed(2)} ` : null}
          {renderUnit(counterUnit)}
        </span>
      ) : (
        <span
          className={`sub-counter-card-value ${
            copy
              ? 'sub-counter-card-value-wcopy'
              : 'sub-counter-card-value-nocopy'
          }`}
        >
          {value}
        </span>
      )}
      {copy ? (
        <span className='sub-counter-card-value-copy'>
          <Icon
            name={'copy'}
            onClickAction={() => {
              navigator.clipboard.writeText(value);
            }}
          />
        </span>
      ) : null}
    </Row>
  </Col>
);

export const SubCounterCard = ({
  name,
  number,
  premiseName,
  premiseType,
  countersValuesSum,
  countersPreviousValuesSumPercent,
  unit,
}) => {
  const premiseLongName =
    premiseType && premiseName
      ? `${premiseTypeMap(premiseType)} ${premiseName}`
      : null;

  return (
    <Card style={{ backgroundColor: '#3B4C60', borderRadius: 25, padding: 25 }}>
      <Row gutter={[0, 20]}>
        <Atribute label={'Nazwa licznika'} value={name} />
        <Atribute label={'Numer podlicznika'} value={number} copy={true} />
        <Atribute label={'Lokal'} value={premiseLongName} />
        <Col xs={0} lg={12} xl={0} />
        <Atribute
          label={'Stan licznika'}
          value={countersValuesSum ?? 0}
          counterUnit={unit}
          additional={countersPreviousValuesSumPercent ?? 0}
        />
        <Atribute label={'Opłaty naliczane od'} counterUnit={unit} />
      </Row>
    </Card>
  );
};
