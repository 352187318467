/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PageHeader, Button, Row, Col } from 'antd';
import { Link, useParams } from 'react-router-dom';
import Icon from '../../../../components/Icon';
import { AttachmentsBox } from './../showComponents/AttachmentsBox';
import InfoBox from '../showComponents/InfoBox';
import SharesBox from '../showComponents/SharesBox';
import { OwnerBox } from '../showComponents/OwnerBox';
import { ContactsBox } from '../showComponents/ContactsBox';
import premiseTypeMap from '../../../../helpers/premiseTypeMap';
import { TenantsBox } from '../showComponents/TenantsBox';
import { CountersBox } from '../showComponents/CountersBox';
import { FaultsBox } from '../showComponents/FaultsBox';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

const Show = ({ premise }) => {
  const { id, premisesId } = useParams();

  return (
    <div className='show-layout'>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        backIcon={<Icon name='arrow-simply-left' />}
        title='Wróć'
        className='page-header-extra-2'
        extra={[
          <Button
            key='1'
            className='button-secondary'
            style={{ minWidth: 0, maxWidth: 48 }}
            onClick={() => window.print()}
          >
            <Icon name='print' style={{ paddingLeft: 0 }} />
          </Button>,
          <Link
            key='3'
            to={`/memberAssociations/${id}/premises/${premisesId}/edit`}
          >
            <Button type='primary'>
              Edytuj <Icon name='edit' />
            </Button>
          </Link>,
        ]}
      >
        <div className='premises-show'>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <div style={{ color: '#3B4C60', fontSize: 28 }}>
                {premiseTypeMap(premise.type)} {premise.name}
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <div style={{ color: '#3B4C60', fontSize: 14 }}>
                ul. {premise.address.street} {premise.address.number}
                {premise.address.local ? `/${premise.address.local}` : ''}
                {', '}
                {premise.address.zip} {premise.address.city}
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={24} xl={12}>
              <SharesBox premise={premise} />
              <OwnerBox premise={premise} />
              <ContactsBox investmentId={id} premisesId={premisesId} />
              <TenantsBox investmentId={id} premisesId={premisesId} />
              <FaultsBox premise={premise} />
              <AttachmentsBox />
            </Col>
            <Col xs={24} sm={24} xl={12}>
              <InfoBox premise={premise} />
              <CountersBox investmentId={id} premisesId={premisesId} />
            </Col>
          </Row>
        </div>
      </PageHeader>
    </div>
  );
};

export default Show;
