import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { Icon, Datagrid } from '../../components';
import clientsService from '../../services/clientsService';
import userService from '../../services/userService';

const columns = [
  {
    title: 'Nazwisko',
    dataIndex: 'lastName',
    visible: true,
    filterEnabled: true,
    key: 'lastName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    exportRender: (text, source) => !source.isCompany && source.lastName,
    render: (text, source) => !source.isCompany && source.lastName,
  },
  {
    title: 'Imię',
    dataIndex: 'firstName',
    visible: true,
    filterEnabled: true,
    key: 'firstName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    exportRender: (text, source) => !source.isCompany && source.firstName,
    render: (text, source) => !source.isCompany && source.firstName,
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    filterEnabled: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    exportRender: (text, source) => source.isCompany && source.name,
    render: (text, source) => source.isCompany && source.name,
  },
  {
    title: 'Telefon',
    dataIndex: 'mobile',
    visible: true,
    key: 'mobile',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`tel:${text}`}>{text}</a>,
  },
  {
    title: 'Adres e-mail',
    dataIndex: 'email',
    visible: true,
    key: 'email',
    sorter: true,
    filterEnabled: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => <a className="datagrid-link" href={`mailto:${text}`}>{text}</a>,
  },
  {
    title: 'Usterki',
    dataIndex: 'faultsCount',
    visible: true,
    key: 'faultsCount',
    sorter: false,
  },
  {
    title: 'Ważność dowodu osobistego',
    dataIndex: ['identity', 'idExpireDate'],
    visible: false,
    key: 'idExpireDate',
    sorter: false,
    exportRender: (text, source) => source.identity.idExpireDate,
  },
];

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const List = () => {
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    userService.getInfo().then((userData) => {
      if (userData.roles.includes('ROLE_WORKER')) {
        if (userData.workerPermissions.includes('CLIENTS_FULL')) {
          setCanEdit(true);
        }
      } else {
        setCanEdit(true);
      }
    });
  }, []);

  const isRodoAccepted = (record) => {
    if (!record.isRodoAccepted === true) {
      return '';
    }
    return (
      <Tooltip title="Klauzula potwierdzona">
        <Icon name="ok-circle" style={{ fontSize: '18px' }} />
      </Tooltip>
    );
  };

  return (
    <div>
      <Datagrid
        title="Lista klientów"
        resource="clients"
        dataProvider={clientsService}
        columns={columns}
        filters={{
          role: 'ROLE_CLIENT',
        }}
        sort={{
          sortField: 'lastName',
          sortOrder: 'ascend',
        }}
        hideEdit={!canEdit}
        hideCreate={!canEdit}
        canEdit={canEdit}
        showAlert={showAlert}
        customRowActions={[isRodoAccepted]}
      />
    </div>
  );
};

export default List;
