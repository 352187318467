import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import workersService from '../../services/workersService';
import UserSettlements from '../../services/userSettlementsService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Address from '../../components/ShowAddressComponent';

const Notifications = ({ notifications }) => {
  if (notifications.sms && notifications.email) {
    return (
      <>
        <div className='title'>Alerty:</div>
        <div>SMS, E-mail</div>
      </>
    );
  } else if (notifications.sms) {
    return (
      <>
        <div className='title'>Alerty:</div>
        <div>SMS</div>
      </>
    );
  } else if (notifications.email) {
    return (
      <>
        <div className='title'>Alerty:</div>
        <div>E-mail</div>
      </>
    );
  }

  return null;
};

const Show = ({ customUserData, customTitle }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isBroker, setIsBroker] = useState(false);

  useEffect(() => {
    const promises = [];
    if (customUserData) {
      setUserData(customUserData);
    } else {
      promises.push(
        workersService.getOne(id).then((data) => {
          setUserData(data);
          const { roles } = data;
          if (roles && roles.includes('ROLE_WORKER_BROKER')) {
            setIsBroker(true);
          }
        })
      );
    }

    const UserSettlementsService = new UserSettlements(id);
    promises.push(
      UserSettlementsService.getBalance().then((balanceResponse) => {
        setBalance(balanceResponse.balance);
      })
    );

    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [id, customUserData]);

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.goBack()}
          backIcon={customTitle ? '' : <Icon name='arrow-simply-left' />}
          title={customTitle || 'Wróć'}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              style={{ minWidth: 0, maxWidth: 48 }}
              onClick={() => window.print()}
            >
              <Icon name='print' style={{ paddingLeft: 0 }} />
            </Button>,
            <Button
              key='2'
              type='primary'
              onClick={() =>
                history.replace(
                  customTitle
                    ? '/myAccount/edit'
                    : `/housingWorkers/${userData.id}/edit`
                )
              }
            >
              Edytuj <Icon name='edit' />
            </Button>,
          ]}
        >
          <Card>
            <Row>
              <Col xs={24} xl={12}>
                <div className='left'>
                  <div className='logo'>
                    <img
                      src={`${baseUrl}${userData.avatar}`}
                      alt={userData.name}
                    />
                  </div>
                  <div
                    className='name'
                    style={{
                      ...(userData.workerPosition && { marginBottom: 5 }),
                    }}
                  >
                    {userData.firstName} {userData.lastName}
                    {isBroker && (
                      <span
                        style={{ marginLeft: '5px' }}
                        className='broker-icon'
                      >
                        <Icon name='percent' />
                      </span>
                    )}
                  </div>
                  {userData.workerPosition && (
                    <div className='position'>{userData.workerPosition}</div>
                  )}
                  <div className='buttons'>
                    {userData.mobile && (
                      <div className='call'>
                        <a href={`tel:${userData.mobile}`}>
                          <Button type='primary' size='large'>
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>
                    )}
                    <div className='write'>
                      <a href={`mailto:${userData.email}`}>
                        <Button type='primary' size='large'>
                          <Icon name='email' /> Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={24} xl={12}>
                <div className='right'>
                  <Card
                    title={
                      <h2 className='show-layout-card-header'>
                        <Icon name='more-info' />
                        Informacje
                      </h2>
                    }
                    className='informations'
                  >
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <div className='title'>Imię i nazwisko:</div>
                        <div>
                          {userData.firstName} {userData.lastName}
                        </div>
                      </Col>
                      <Col xs={24} sm={12}>
                        <div className='title'>Dane kontaktowe:</div>
                        <div className='email'>{userData.email}</div>
                        <div className='phone'>{userData.mobile}</div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <Address
                          title='Adres zamieszkania'
                          address={userData.address}
                        />
                      </Col>
                      <Col xs={24} sm={12}>
                        <Address
                          title='Adres korespondencyjny'
                          address={
                            userData.correspondenceAddress
                              ? userData.correspondenceAddress
                              : {}
                          }
                        />
                      </Col>
                    </Row>
                    {(userData.notifications?.email ||
                      userData.notifications?.sms) && (
                      <Row style={{ marginBottom: '20px' }}>
                        <Col xs={24} sm={12}>
                          <Notifications
                            notifications={userData.notifications}
                          />
                        </Col>
                      </Row>
                    )}
                  </Card>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
