import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Tooltip, Button, PageHeader } from 'antd';
import Icon from '../../components/Icon';
import Datagrid from '../../components/Datagrid';
import Spin from '../../components/Spin';
import resolutionsListService from '../../services/resolutionsListService';
import { ChoosenAllSwitch } from './components/ChoosenAllSwitch';
import userService from '../../services/userService';
import investmentsService from '../../services/investmentsService';
import MemberAssociationsService from '../../services/memberAssociationsService';

const RenderStatus = (status) => {
  if (status === 'STARTED') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'W toku'}>
          <span className={'dot green'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'AWAITING') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Oczekuje'}>
          <span className={'dot yellow'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  } else if (status === 'FINISHED') {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <Tooltip title={'Zakończona'}>
          <span className={'dot grey'} style={{ marginLeft: 15 }} />
        </Tooltip>
      </div>
    );
  }
};

const renderVote = (record) => {
  if (record?.status === 'STARTED') {
    return (
      <span>
        <div>
          <span style={{ color: '#15D55E', fontWeight: 700 }}>ZA</span>
          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
            {parseFloat(record?.votes?.proPercent).toFixed(2)}% -
          </span>
          <span style={{ color: '#EC434C', fontWeight: 700, marginLeft: 5 }}>
            PRZECIW
          </span>
          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
            {parseFloat(record?.votes?.conPercent).toFixed(2)}%
          </span>
        </div>
        <div>
          <span style={{ color: '#8FB3D1', fontWeight: 700 }}>WSTRZYMANO</span>
          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
            {parseFloat(record?.votes?.abstainPercent).toFixed(2)}%
          </span>
        </div>
      </span>
    );
  } else if (record?.status === 'FINISHED') {
    if (record?.votes?.winningType === 'PRO') {
      return (
        <span>
          <span style={{ color: '#15D55E', fontWeight: 700 }}>ZA</span>
          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
            ({parseFloat(record?.votes?.winningValue).toFixed(2)}%)
          </span>
        </span>
      );
    } else if (record?.votes?.winningType === 'CON') {
      return (
        <span>
          <span style={{ color: '#EC434C', fontWeight: 700 }}>PRZECIW</span>
          <span style={{ color: '#8FB3D1', marginLeft: 2 }}>
            ({parseFloat(record?.votes?.winningValue).toFixed(2)}%)
          </span>
        </span>
      );
    } else {
      return <span style={{ color: '#8FB3D1' }}>NIEWAŻNE</span>;
    }
  }
};

const renderExportVote = (record) => {
  if (record?.status === 'W toku') {
    return `ZA ${parseFloat(record?.votes?.proPercent).toFixed(
      2
    )}% - PRZECIW ${parseFloat(record?.votes?.conPercent).toFixed(
      2
    )}% - WSTRZYMANO ${parseFloat(record?.votes?.abstainPercent).toFixed(2)}%`;
  } else if (record?.status === 'Zakończona') {
    if (record?.votes?.winningType === 'PRO') {
      return `ZA - ${parseFloat(record?.votes?.winningValue).toFixed(2)}%`;
    } else if (record?.votes?.winningType === 'CON') {
      return `PRZECIW - ${parseFloat(record?.votes?.winningValue).toFixed(2)}%`;
    } else {
      return `NIEWAŻNE`;
    }
  }
};

const getRowClassName = (record, index) => {
  if (record.status === 'STARTED') {
    return 'datagrid-row-green';
  }

  return '';
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = () => {
  const history = useHistory();
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [id, setId] = useState();
  const [investment, setInvestment] = useState({});
  const [userData, setUserData] = useState({});
  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
    });
  }, []);

  useEffect(() => {
    const investmentId = query.get('investmentId') || null;
    if (investmentId) {
      setId(investmentId);
    } else {
      const choosenInvestmentId = localStorage.getItem('choosenInvestmentId');
      if (choosenInvestmentId) {
        setId(JSON.parse(choosenInvestmentId));
      }
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      let InvestmentsService = new investmentsService();

      InvestmentsService.getList().then((data) => {
        setInvestments(data.content);
      });
    }

    if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
      const InvestmentsService = new MemberAssociationsService();

      InvestmentsService.getList({
        types: ['HOUSING_ASSOCIATION', 'HOUSING_COMMUNITY'],
      }).then((data) => {
        setInvestments(data.content);
      });
    }
  }, [userData]);

  useEffect(() => {
    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      const InvestmentsService = new investmentsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data.investment);
        setLoading(false);
      });
    } else if (userData?.roles?.includes('ROLE_COMMUNITY_MEMBER')) {
      const InvestmentsService = new MemberAssociationsService();
      InvestmentsService.getDetails(id).then((data) => {
        setInvestment(data);
        setLoading(false);
      });
    }
  }, [id, userData]);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      filterEnabled: true,
      filterFullWidth: !showAll,
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    ...(showAll
      ? [
          {
            title: 'Inwestycja',
            dataIndex: 'investmentIds',
            visible: false,
            key: 'investmentIds',
            sorter: false,
            filterEnabled: true,
            filterType: 'select',
            filterOptions: investments.map((investment) => ({
              value: investment.id,
              label: investment.name,
            })),
            export: false,
          },
        ]
      : []),
    ...(showAll
      ? [
          {
            title: 'Inwestycja',
            dataIndex: 'investmentName',
            visible: true,
            filterEnabled: false,
            key: 'investmentNameAll',
            export: true,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
          },
        ]
      : []),
    ...(query.get('investmentType')
      ? [
          {
            title:
              query.get('investmentType') === 'HOUSING_ASSOCIATION'
                ? 'Spółdzielnia'
                : 'Wspólnota',
            dataIndex: 'investmentName',
            visible: true,
            filterEnabled: false,
            key: 'investmentNameType',
            export: true,
            sorter: true,
            sortDirections: ['descend', 'ascend'],
          },
        ]
      : []),
    {
      title: 'Data dodania',
      dataIndex: 'addDate',
      visible: true,
      filterEnabled: false,
      key: 'addDateDisplay',
      sorter: true,
      export: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      filterEnabled: false,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (text ? RenderStatus(text) : ''),
      exportRender: (text) => {
        if (text) {
          if (text === 'STARTED') {
            return 'W toku';
          } else if (text === 'AWAITING') {
            return 'Oczekuje';
          }
          if (text === 'FINISHED') {
            return 'Zakończona';
          }
        } else {
          return '';
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'statuses',
      key: 'statuses',
      visible: false,
      export: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        {
          value: 'STARTED',
          label: (
            <span>
              <span className={`dot green`} style={{ marginRight: 8 }} />W toku
            </span>
          ),
        },
        {
          value: 'AWAITING',
          label: (
            <span>
              <span className={`dot yellow`} style={{ marginRight: 8 }} />
              Oczekuje
            </span>
          ),
        },
        {
          value: 'FINISHED',
          label: (
            <span>
              <span className={`dot grey`} style={{ marginRight: 8 }} />
              Zakończona
            </span>
          ),
        },
      ],
    },
    {
      title: 'Data dodania',
      dataIndex: 'addDate',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'addDate',
      export: false,
    },
    {
      title: 'Data rozpoczęcia',
      dataIndex: 'startDate',
      visible: true,
      filterEnabled: false,
      key: 'startDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data rozpoczęcia od',
      dataIndex: 'startDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'startDateFrom',
      export: false,
    },
    {
      title: 'Data rozpoczęcia do',
      dataIndex: 'startDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'startDateTo',
      export: false,
    },
    {
      title: 'Data zakończenia',
      dataIndex: 'endDate',
      visible: true,
      filterEnabled: false,
      key: 'endDate',
      sorter: true,
      export: true,
    },
    {
      title: 'Data zakończenia od',
      dataIndex: 'endDateFrom',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'endDateFrom',
      export: false,
    },
    {
      title: 'Data zakończenia do',
      dataIndex: 'endDateTo',
      visible: false,
      filterEnabled: true,
      filterType: 'date',
      key: 'endDateTo',
      export: false,
    },
    {
      title: 'Frekwencja',
      dataIndex: 'frequency',
      visible: true,
      filterEnabled: false,
      key: 'frequency',
      sorter: true,
      export: true,
      render: (text, record) =>
        record.status !== 'AWAITING'
          ? `${parseFloat(record?.votes?.frequency).toFixed(2)}%`
          : '',
      exportRender: (text, record) =>
        record.status !== 'AWAITING'
          ? `${parseFloat(record?.votes?.frequency).toFixed(2)}%`
          : '',
    },
    {
      title: 'Wyniki',
      dataIndex: 'votes',
      visible: true,
      filterEnabled: false,
      key: 'votes',
      sorter: true,
      export: true,
      render: (text, record) => renderVote(record),
      exportRender: (text, record) => renderExportVote(record),
    },
  ];

  if (
    userData?.roles?.includes('ROLE_COMMUNITY_MEMBER') ||
    query.get('userId')
  ) {
    columns.push({
      title: 'Głos',
      dataIndex: 'vote',
      visible: true,
      filterEnabled: false,
      key: 'vote',
      sorter: true,
      export: true,
      render: (text, record) => {
        if (record?.vote === 'PRO') {
          return <span style={{ color: '#15D55E', fontWeight: 700 }}>ZA</span>;
        } else if (record?.vote === 'CON') {
          return (
            <span style={{ color: '#EC434C', fontWeight: 700 }}>PRZECIW</span>
          );
        } else if (record?.vote === 'ABSTAIN') {
          return (
            <span style={{ color: '#8FB3D1', fontWeight: 700 }}>
              WSTRZYMUJĘ SIĘ
            </span>
          );
        }
      },
      exportRender: (text, record) => {
        if (record?.vote === 'PRO') {
          return 'ZA';
        } else if (record?.vote === 'CON') {
          return 'PRZECIW';
        } else if (record?.vote === 'ABSTAIN') {
          return 'WSTRZYMUJĘ SIĘ';
        }
      },
    });
  }

  const CustomCreate = ({ showAll }) => {
    if (
      !(
        userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (userData?.roles?.includes('ROLE_WORKER') &&
          userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      )
    ) {
      return '';
    }

    return (
      <div>
        <Button
          type='primary'
          onClick={() => {
            if (showAll) {
              return history.push(`/resolutions/create`);
            }

            return history.push(`/resolutions/create?investmentId=${id}`);
          }}
        >
          Uchwała
          <Icon name='plus' style={{ marginLeft: 10 }} />
        </Button>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (query.get('investmentType')) {
      return (
        <span>
          Uchwały:{' '}
          <span className='header-subtitle'>
            {query.get('investmentType') === 'HOUSING_ASSOCIATION'
              ? 'SPÓŁDZIELNIE'
              : 'WSPÓLNOTY'}
          </span>
        </span>
      );
    }

    if (!investment.name || showAll) {
      return <span>Uchwały</span>;
    }

    return (
      <span>
        Uchwały: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title={
              query.get('investmentId') ||
              query.get('investmentType') ||
              query.get('type') ||
              query.get('userId') ? (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  backIcon={<Icon name='arrow-simply-left' />}
                  onBack={() => window.history.back()}
                  title={<HeaderTitle />}
                />
              ) : (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  title={<HeaderTitle />}
                />
              )
            }
            exportTitle={'Uchwały'}
            resource='resolutions'
            customBaseUrlKey={
              userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
              (userData?.roles?.includes('ROLE_WORKER') &&
                userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
                ? 'resolutions'
                : 'memberResolutions'
            }
            dataProvider={new resolutionsListService()}
            columns={columns}
            customCreate={<CustomCreate showAll={showAll} />}
            customShowAllMembers={
              !query.get('investmentType') && (
                <ChoosenAllSwitch showAll={showAll} setShowAll={setShowAll} />
              )
            }
            filters={{
              ...(!showAll &&
                !query.get('investmentType') && { investmentIds: id }),
              ...((query.get('investmentType') || null) && {
                investmentType: query.get('investmentType'),
              }),
              ...((query.get('type') || null) && {
                statuses: query.get('type'),
              }),
              ...((query.get('userId') || null) && {
                userId: query.get('userId'),
              }),
            }}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            customRowClass={getRowClassName}
            hideCreate
            hideReset
            hideSms
            hideEmail
            hideEdit
            hideDelete={
              !(
                userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (userData?.roles?.includes('ROLE_WORKER') &&
                  userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
              )
            }
            customRowActions={[
              (record) => {
                if (
                  userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') &&
                  record.status === 'AWAITING'
                ) {
                  return (
                    <Link
                      to={`/investment/${record.investmentId}/resolutions/${record.id}/edit`}
                    >
                      <Button className='button-no-border'>
                        <Icon name='edit' />
                      </Button>
                    </Link>
                  );
                }
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default List;
