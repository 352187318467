import BaseService from './baseService';

class SettlementsService extends BaseService {
  constructor(investmentId, premisesId, brokerId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/broker/${brokerId}/settlement`;
  }
}

export default SettlementsService;
