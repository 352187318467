import BaseService from './baseService';
import httpService from '../helpers/httpService';

class MemberPremisesSettlementsService extends BaseService {
  constructor(investmentId, premisesId) {
    super();

    this.url = `/api/user/community/investment/${investmentId}/premises/${premisesId}/settlements`;
  }

  async getCosts(settlementId) {
    return httpService.get(`${this.url}/${settlementId}/costs`, null, true);
  }
}

export default MemberPremisesSettlementsService;
