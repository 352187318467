import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
import Datagrid from '../../../components/Datagrid';
import Icon from '../../../components/Icon';
import SharesBox from '../components/SharesBox';
import membersPremisesService from '../../../services/membersPremisesService';
import { premiseTypeMap } from '../../../helpers';
import investmentsService from '../../../services/investmentsService';

const columns = [
  {
    title: 'Lokal',
    dataIndex: 'name',
    visible: true,
    filterEnabled: false,
    key: 'premise',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => `${premiseTypeMap(record.type)} ${record.name}`,
    exportRender: (text, record) =>
      `${premiseTypeMap(record.type)} ${record.name}`,
  },
  {
    title: 'Numer',
    dataIndex: 'name',
    visible: false,
    filterEnabled: true,
    key: 'name',
    sorter: false,
    sortDirections: ['descend', 'ascend'],
    export: false,
  },
  {
    title: 'Typ',
    dataIndex: 'type',
    visible: false,
    filterEnabled: true,
    filterType: 'select',
    filterOptions: [
      { value: 'RENT', label: 'Mieszkanie' },
      { value: 'STORAGE', label: 'Komórka lokatorska' },
      { value: 'PARKING', label: 'Miejsce postojowe' },
      { value: 'SERVICE', label: 'Lokal usługowy' },
    ],
    key: 'type',
    sorter: false,
    sortDirections: ['descend', 'ascend'],
    export: false,
  },
  {
    title: 'Powierzchnia',
    dataIndex: 'totalSurface',
    visible: true,
    key: 'totalSurface',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span>
        {text} m<sup>2</sup>
      </span>
    ),
    exportRender: (text, record) => `${text} m2`,
  },
  {
    title: 'Powierzchnia od',
    dataIndex: 'totalSurfaceFrom',
    visible: false,
    key: 'totalSurfaceFrom',
    sorter: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
    export: false,
  },
  {
    title: 'Powierzchnia do',
    dataIndex: 'totalSurfaceTo',
    visible: false,
    key: 'totalSurfaceTo',
    sorter: false,
    filterEnabled: true,
    filterProps: {
      addonAfter: (
        <>
          m<sup>2</sup>
        </>
      ),
    },
    export: false,
  },
  {
    title: 'Udział',
    dataIndex: ['participation', 'percent'],
    visible: true,
    key: 'participationPercent',
    sorter: true,
    filterEnabled: false,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => `${text.toFixed(2)}%`,
    exportRender: (text, record) =>
      `${record.participation.percent.toFixed(2)}%`,
  },
];

const customFilters = [];

const List = () => {
  const { investmentId, userId } = useParams();
  const [baseUrlKey, setBaseUrlKey] = useState();

  const MembersPremisesService = new membersPremisesService(
    userId,
    investmentId
  );

  useEffect(() => {
    const InvestmentsService = new investmentsService();

    InvestmentsService.getOne(investmentId).then((data) => {
      if (data.type === 'HOUSING_ASSOCIATION') {
        setBaseUrlKey('housingAssociations');
      } else if (data.type === 'HOUSING_COMMUNITY') {
        setBaseUrlKey('housingCommunities');
      }
    });
  }, []);

  return (
    <div>
      <Datagrid
        title={
          <PageHeader
            style={{ padding: 0 }}
            ghost={false}
            backIcon={<Icon name='arrow-simply-left' />}
            onBack={() => window.history.back()}
            title={'Lokale'}
          />
        }
        exportTitle={'Lokale'}
        resource={`${baseUrlKey}/${investmentId}/premises`}
        customBaseUrlKey={baseUrlKey}
        dataProvider={MembersPremisesService}
        columns={columns}
        sort={{
          sortField: 'id',
          sortOrder: 'descend',
        }}
        // customFilters={customFilters}
        customCreate={
          <div style={{ width: '100%' }}>
            <SharesBox premisesView={true} />
          </div>
        }
        hideReset={true}
        hideSms={true}
        hideEmail={true}
      />
    </div>
  );
};

export default List;
