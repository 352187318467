import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tooltip, Button } from 'antd';
import Icon from '../../../components/Icon';

import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#15D55E', '#C0D6E5', '#EC434C'];

const renderCustomizedLabel = () => {
  return (
    <text
      x={100}
      y={100}
      fill={'#979797'}
      textAnchor={'middle'}
      dominantBaseline='central'
    >
      {`BRAK GŁOSÓW`}
    </text>
  );
};

export const ResultsBox = ({ resolution, userData }) => {
  const { investmentId, resolutionId } = useParams();

  const anyVote =
    resolution?.votes?.proCount ||
    resolution?.votes?.conCount ||
    resolution?.votes?.abstainCount;

  const data = anyVote
    ? [
        { name: 'ZA', value: resolution?.votes?.proPercent },
        { name: 'WSTRZYMANO', value: resolution?.votes?.abstainPercent },
        { name: 'PRZECIW', value: resolution?.votes?.conPercent },
      ]
    : [{ name: 'Brak głosów', value: 1 }];

  return (
    <div className='premises-show-box info-box'>
      <h2>
        <Icon name='more-info' />
        Wyniki
      </h2>
      {resolution?.vote ? (
        <div
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: '#F0F4F6',
            fontSize: 14,
            fontWeight: 700,
            borderRadius: 12,
          }}
        >
          Twój głos:
          {resolution?.vote === 'PRO' ? (
            <span style={{ marginLeft: 5, color: '#15D55E' }}>ZA</span>
          ) : resolution?.vote === 'CON' ? (
            <span style={{ marginLeft: 5, color: '#EC434C' }}>PRZECIW</span>
          ) : resolution?.vote === 'ABSTAIN' ? (
            <span style={{ marginLeft: 5 }}>WSTRZYMUJĘ SIĘ</span>
          ) : null}
        </div>
      ) : resolution.status === 'FINISHED' ? (
        <div
          style={{
            position: 'absolute',
            right: 20,
            top: 20,
            padding: 8,
            paddingLeft: 16,
            paddingRight: 16,
            backgroundColor: '#F0F4F6',
            fontSize: 14,
            fontWeight: 700,
            borderRadius: 12,
          }}
        >
          <span style={{ marginLeft: 5, color: '#EC434C' }}>
            Nie oddano głosu
          </span>
        </div>
      ) : null}

      <div className='content'>
        <div
          className='resolutions-piechart-content-wrapper'
          style={{ display: 'flex' }}
        >
          {anyVote ? (
            <div className='resolutions-piechart-pro-label'>
              <div>
                <div
                  style={{ fontSize: 16, fontWeight: 700, textAlign: 'center' }}
                >
                  ZA
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{ fontSize: 10, fontWeight: 700, lineHeight: 0.8 }}
                  >
                    {parseFloat(resolution?.votes?.proPercent).toFixed(2)}%
                  </span>
                  <span
                    style={{ fontSize: 10, color: '#8FB3D1', marginLeft: 2 }}
                  >
                    ({resolution?.votes?.proCount})
                  </span>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className='resolutions-piechart-wrapper'
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <PieChart width={200} height={200}>
              <Pie
                data={data}
                cx='50%'
                cy='50%'
                labelLine={false}
                outerRadius={100}
                startAngle={90}
                endAngle={450}
                fill='#8884d8'
                dataKey='value'
                isAnimationActive={false}
                paddingAngle={0}
                label={anyVote ? null : renderCustomizedLabel}
                blendStroke
              >
                {anyVote ? (
                  data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))
                ) : (
                  <Cell key={`cell-1`} fill={'#F0F4F6'} />
                )}
              </Pie>
            </PieChart>
          </div>
          {anyVote ? (
            <div className='resolutions-piechart-con-label'>
              <div>
                <div
                  style={{ fontSize: 16, fontWeight: 700, textAlign: 'center' }}
                >
                  PRZECIW
                </div>
                <div style={{ textAlign: 'center' }}>
                  <span
                    style={{ fontSize: 10, fontWeight: 700, lineHeight: 0.8 }}
                  >
                    {parseFloat(resolution?.votes?.conPercent).toFixed(2)}%
                  </span>
                  <span
                    style={{ fontSize: 10, color: '#8FB3D1', marginLeft: 2 }}
                  >
                    ({resolution?.votes?.conCount})
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {anyVote ? (
          <div className='resolutions-piechart-abstain-label'>
            <div
              style={{
                fontSize: 16,
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              WSTRZYMANO
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: 10, fontWeight: 700, lineHeight: 0.8 }}>
                {parseFloat(resolution?.votes?.abstainPercent).toFixed(2)}%
              </span>
              <span style={{ fontSize: 10, color: '#8FB3D1', marginLeft: 2 }}>
                ({resolution?.votes?.abstainCount})
              </span>
            </div>
          </div>
        ) : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 25,
          }}
        >
          <div className='text-secondary'>
            <span>Frekwencja udziału:</span>
            <span style={{ marginLeft: 5, fontWeight: 700 }}>
              {parseFloat(resolution?.votes?.frequency).toFixed(2)}%
            </span>
            <span style={{ marginLeft: 5 }}>
              <Tooltip title={'Wymagana min. 50% frekwencja'}>
                <Icon name='more-info' />
              </Tooltip>
            </span>
          </div>
          {userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
          (userData?.roles?.includes('ROLE_WORKER') &&
            userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN')) ? (
            <Link
              to={`/investment/${investmentId}/resolutions/${resolutionId}/details`}
            >
              <div
                className='resolution-details-btn'
                style={{
                  width: 85,
                  height: 24,
                  textAlign: 'center',
                  fontWeight: 700,
                  fontSize: 12,
                  paddingTop: 3,
                  borderRadius: 12,
                  minWidth: 0,
                  minHeight: 0,
                }}
              >
                Szczegóły
              </div>
            </Link>
          ) : null}
        </div>
      </div>
    </div>
  );
};
