import BaseService from './baseService';

class ResolutionsImageService extends BaseService {
  constructor(investmentId, resolutionId) {
    super();
    const roles = localStorage.getItem('roles');

    if (
      roles.includes('ROLE_COMMUNITY_ADMIN') ||
      roles?.includes('ROLE_WORKER')
    ) {
      this.url = `/api/community/investment/${investmentId}/resolutions/${resolutionId}/images`;
    }

    if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      this.url = `/api/community/member/investment/${investmentId}/resolutions/${resolutionId}/images`;
    }
  }
}

export default ResolutionsImageService;
