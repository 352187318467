import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import agreementsService from '../../../../services/agreementsService';
import agreementAttachmentsService from '../../../../services/agreementAttachmentsService';
import clientsService from '../../../../services/clientsService';
import Icon from '../../../../components/Icon';
import Empty from '../../../../components/Empty';
import SmallList from '../../../../components/SmallList';
import agreementTypeMap from '../../../../helpers/agreementTypeMap';

const AgreementsBox = ({ premise, investment, rooms }) => {
  const getUserLink = (userId) => {
    if (investment.type === 'SALE') {
      return `/clients/${userId}/show`;
    }
    return `/clientsTenants/${userId}/show`;
  };

  const clientsColumns = [
    {
      dataIndex: 'name',
      render: (text, record) => (
        <Link className="smalllist-inherit-link" to={getUserLink(record.id)} style={{ whiteSpace: 'nowrap', color: 'inherit !important' }}>
          {record.firstName}
          {' '}
          {record.lastName}
        </Link>
      ),
    },
    {
      dataIndex: ['identity', 'pesel'],
      fixed: 'right',
      width: 200,
      align: 'right',
    },
  ];

  const { id, premisesId } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const DataService = new agreementsService(id, premisesId);
    DataService.getList().then((response) => {
      const activeAgreements = response.filter((agreement) => agreement.status === 'ACTIVE');
      setData(activeAgreements);
    });
  }, [id, premisesId]);

  if (data.length === 0) {
    return '';
  }

  if (investment.type === 'SALE' && premise.status === 'FREE') {
    return '';
  }

  const AgreementBox = ({ agreement, investment, rooms }) => {
    const { id, premisesId } = useParams();
    const [attachments, setAttachments] = useState([]);
    const [clients, setClients] = useState([]);
    const [room, setRoom] = useState({});

    useEffect(() => {
      const DataService = new agreementAttachmentsService(id, premisesId, agreement.id);
      DataService.getList().then((response) => {
        setAttachments(response);
      });

      if (agreement.clients && agreement.clients.length > 0) {
        clientsService.getList({
          ids: agreement.clients.join(','),
          role: investment.type === 'SALE' ? 'ROLE_CLIENT' : 'ROLE_CLIENT_TENANT',
        }).then((clientsData) => {
          setClients(clientsData.content);
        });
      }

      if (agreement.roomId && rooms) {
        const foundRoom = rooms.find((r) => r.id === agreement.roomId) || {};
        setRoom(foundRoom);
      }
    }, [id, premisesId, agreement, investment.type, rooms]);

    const handleDownload = (fileId) => {
      const DataService = new agreementAttachmentsService(id, premisesId, agreement.id);
      DataService.getOne(fileId).then((response) => {
        const a = document.createElement('a');
        a.href = `data:application/octet-stream;base64,${response.content}`;
        a.download = response.filename;
        a.click();
      });
    };

    const temp = attachments.map((attachment) => (
      <Col sm={24} key={attachment.id}>
        <div className="attachment">
          <Row>
            <Col span={2} className="attachment-icon">
              <Icon name="file" />
            </Col>
            <Col span={16} className="attachment-name">
              {attachment.filename}
            </Col>
            <Col span={4} offset={2} className="attachment-actions">
              <Button ghost className="button-no-border" onClick={() => handleDownload(attachment.id)}>
                <Icon name="download" />
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    ));

    if (temp.length === 0) {
      temp.push(
        <Col sm={24} key={0}>
          <Empty />
        </Col>,
      );
    }

    return (
      <div className="single-agreement" key={agreement.id}>
        <Row className="single-agreement-header">
          <Col xs={18} sm={12}>
            <h3 className="single-agreement-name">{agreement.name}</h3>
          </Col>
          <Col xs={6} sm={12} className="single-agreement-header-info">
            {investment.type === 'RENT' && room.id && (
              <>
                <Icon name="dashboard" style={{ marginRight: '5px' }} />
                {room.name}
              </>
            )}
          </Col>
        </Row>
        {investment.type === 'SALE' && (
          <Row className="single-agreement-type">
            <Col xs={24} sm={6} className="label">
              Typ umowy:
            </Col>
            <Col xs={24} sm={18} className="value">
              {agreementTypeMap(agreement.type)}
            </Col>
          </Row>
        )}
        <Row className="single-agreement-date">
          <Col xs={24} sm={6} className="label">
            {investment.type === 'SALE' ? 'Data:' : 'Data rozpoczęcia:'}
          </Col>
          <Col xs={24} sm={18} className="value">
            {agreement.dateFrom}
          </Col>
        </Row>
        {investment.type === 'RENT' && (
          <Row className="single-agreement-date">
            <Col xs={24} sm={6} className="label">
              Data zakończenia:
            </Col>
            <Col xs={24} sm={18} className="value">
              {agreement.dateTo}
            </Col>
          </Row>
        )}
        {clients.length > 0 && (
          <div className="single-agreement-clients">
            <SmallList data={clients} columns={clientsColumns} showHeader={false} icon="tenant" title={investment.type === 'SALE' ? 'Klienci' : 'Najemcy'} />
          </div>
        )}
        {attachments.length > 0 && (
          <div className="single-agreement-attachments-attachment">
            <h2>Załączniki</h2>
            {temp}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="premises-show-box agreements-box">
      <h2>
        <Icon name="file" />
        Umowy
      </h2>
      <div className="content">
        {data.map((agreement) => (
          <AgreementBox agreement={agreement} investment={investment} rooms={rooms} />))}
      </div>
    </div>
  );
};

export default AgreementsBox;
