import { Button } from 'antd';

export const ChoosenAllSwitch = ({ showAll, setShowAll }) => {
  return (
    <span>
      <Button
        className={`button-secondary all-members-btn ${
          !showAll && 'show-all-members-btn'
        }`}
        onClick={() => {
          setShowAll(false);
        }}
      >
        Wybrane
      </Button>
      <Button
        className={`button-secondary all-members-btn ${
          showAll && 'show-all-members-btn'
        }`}
        onClick={() => {
          setShowAll(true);
        }}
      >
        Wszystkie
      </Button>
    </span>
  );
};
