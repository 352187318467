import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Checkbox,
  Upload,
  message,
  Dropdown,
  Menu,
  Modal,
} from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import housingAssociationsService from '../../services/housingAssociationsService';
import Icon from '../../components/Icon';
import deleteConfirm from '../../components/deleteConfirm';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';
import Tabs from '../../components/Tabs';
import { ContactsInvestment } from './editComponents/ContactsInvestment';
import MessagePopup from '../../components/MessagePopup';
import { WorkersInvestment } from './editComponents/WorkersInvestment';
import { GarageHallsInvestment } from './editComponents/GarageHallsInvestment';
import ProgressModal from '../../components/ProgressModal';

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const { DatePicker, Select, SearchBox, Mobile } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const useQuery = () => new URLSearchParams(useLocation().search);

const Edit = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery();
  const [logo, setLogo] = useState([]);
  const [thumb, setThumb] = useState([]);
  const [faultTemplate, setFaultTemplate] = useState([]);
  const [investment, setInvestment] = useState({});
  const [showDifferentDeveloper, setShowDifferentDeveloper] = useState(false);
  const [showCorrespondence, setShowCorrespondence] = useState(false);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  const faultTemplateRef = useRef();

  const [form] = Form.useForm();

  let defaultActiveTab = 'form';

  if (query.get('developer') != null) {
    defaultActiveTab = 'developer';
  } else if (query.get('workers') != null) {
    defaultActiveTab = 'workers';
  } else if (query.get('contact') != null) {
    defaultActiveTab = 'contact';
  }

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.getOne(id).then((data) => {
      const tempData = data;
      if (data.createDate) {
        tempData.createDate = moment(data.createDate, 'YYYY-MM-DD');
      }
      setInvestment(tempData);
      if (data.developer !== null) {
        setShowDifferentDeveloper(true);
        tempData.differentDeveloper = true;
        if (data.developer.correspondenceAddress !== null) {
          setShowCorrespondence(true);
          tempData.correspondenceShow = true;
        }
      }
      tempData.isFaultsActive = !tempData.isFaultsDisabled;
      form.setFieldsValue(tempData);
    });
  }, [form, id]);

  const onFinish = (values) => {
    const data = {
      ...investment,
      ...values,
    };

    if (data.nip === '___-___-__-__') {
      data.nip = null;
    }
    if (data.regon === '_________') {
      data.regon = null;
    }

    data.isFaultsDisabled = !data.isFaultsActive;
    delete data.isFaultsActive;

    if (values.createDate) {
      data.createDate = values.createDate.format('YYYY-MM-DD');
    } else {
      data.createDate = investment.createDate.format('YYYY-MM-DD');
    }

    delete data.id;
    delete data.userId;
    delete data.logoFile;
    delete data.thumbnailFile;
    delete data.differentDeveloper;
    delete data.correspondenceShow;

    if (showDifferentDeveloper) {
      delete data.developerId;
      if (!showCorrespondence) {
        delete data.developer.correspondenceAddress;
      }

      if (faultTemplate.length === 0) {
        data.developer = {
          ...data.developer,
          faultTemplateFile: investment.developer?.faultTemplateFile,
        };
      }
    } else {
      delete data.developer;
    }

    const formData = new FormData();

    formData.append('investment', JSON.stringify(data));
    if (logo.length) {
      formData.append('logo', logo[0].originFileObj);
    }

    if (thumb.length) {
      formData.append('thumbnail', thumb[0].originFileObj);
    }

    if (faultTemplate.length !== 0 && showDifferentDeveloper) {
      formData.append('faultTemplate', faultTemplate[0].originFileObj);
    }

    const HousingAssociationsService = new housingAssociationsService();

    setProgressModalVisible(true);

    HousingAssociationsService.update(id, formData, {
      onUploadProgress: (progressEvent) => {
        setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          message.success(
            <MessagePopup type={'success'}>Zapisano pomyślnie</MessagePopup>
          );
          history.replace(`/housingAssociations/${response.id}/show`);
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleLogoChange = (info) => {
    setLogo(info.fileList);
  };
  const handleThumbChange = (info) => {
    setThumb(info.fileList);
  };
  const handleFaultTemplateChange = (info) => {
    setFaultTemplate(info.fileList);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const uploadButton = (
    <div className='fault-template-empty'>
      <div>Pusto</div>
    </div>
  );

  const handleOk = () => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.delete(id)
      .then(() =>
        // history.push('/housingAssociations')
        history.goBack()
      )
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const handleCancel = () => {
    // history.push('/housingAssociations');
    history.replace(`/housingAssociations/${id}/show`);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(
        <MessagePopup type={'error'}>
          Dozwolone są tylko pliki graficzne
        </MessagePopup>
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 2MB
        </MessagePopup>
      );
    }
    return !(isJpgOrPng && isLt2M);
  };

  const beforeFaultTemplateUpload = (file) => {
    // const isPdf = file.type === 'application/pdf';
    // if (!isPdf) {
    //   message.error(
    //     <MessagePopup type={'error'}>Dozwolone są tylko pliki PDF</MessagePopup>
    //   );
    // }
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return !isLt15M;
  };

  const HeaderTitle = () => {
    if (!investment.name) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Edytujesz: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  const LogoRenderer = () => {
    if (investment.logoFile && logo.length === 0) {
      return (
        <img
          src={`${baseUrl}${investment.logoFile}`}
          alt={investment.name}
          className='ant-upload-list-item-thumbnail'
        />
      );
    }
    if (logo.length === 0) {
      return uploadButton;
    }

    return (
      <img
        src={`${logo[0]}`}
        alt={investment.name}
        className='ant-upload-list-item-thumbnail'
      />
    );
  };

  const ThumbRenderer = () => {
    if (investment.thumbnailFile && thumb.length === 0) {
      return (
        <img
          src={`${baseUrl}${investment.thumbnailFile}`}
          alt={investment.name}
          className='ant-upload-list-item-thumbnail'
        />
      );
    }
    if (thumb.length === 0) {
      return uploadButton;
    }

    return '';
  };

  const deleteTemplateFileAction = () => {
    setInvestment((currInvesment) => ({
      ...currInvesment,
      developer: {
        ...currInvesment.developer,
        faultTemplateFile: null,
      },
    }));

    setFaultTemplate([]);
  };

  const showDeleteTemplateConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        deleteTemplateFileAction();
      },
    });
  };

  const Options = () => (
    <Dropdown
      overlay={() => (
        <Menu>
          {investment.developer?.faultTemplateFile && (
            <Menu.Item
              key='1'
              icon={<Icon name='download' style={{ marginRight: '5px' }} />}
            >
              <a
                href={`${baseUrl}${investment.developer.faultTemplateFile}`}
                target='_blank'
                download
              >
                Pobierz
              </a>
            </Menu.Item>
          )}
          <Menu.Item
            key='2'
            style={{ color: '#EC434C' }}
            icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
            onClick={() => showDeleteTemplateConfirm()}
          >
            Usuń
          </Menu.Item>
        </Menu>
      )}
      trigger='click'
      placement='bottomCenter'
    >
      <Button>
        <Icon name='more-vertical' />
      </Button>
    </Dropdown>
  );

  const FaultTemplateRenderer = () => {
    if (investment.developer) {
      if (
        investment.developer.faultTemplateFile &&
        faultTemplate.length === 0
      ) {
        return (
          <div className='fault-template'>
            <div>Szablon-zgłaszania-usterki.pdf</div>
            <Options />
          </div>
        );
      }
      if (faultTemplate.length === 0) {
        return uploadButton;
      }
    }
    if (faultTemplate.length === 0) {
      return uploadButton;
    }

    return (
      <div className='fault-template'>
        <div>{faultTemplate[0].name}</div>
        <Options />
      </div>
    );
  };

  const differentDeveloperShow = (event) => {
    setShowDifferentDeveloper(event.target.checked);
    let developer = form.getFieldValue('developer');

    if (!developer) {
      developer = { address: {} };
      if (!developer.address.country) {
        developer.address.country = 'Polska';
      }
    }

    form.setFieldValue('developer', developer);
  };

  const correspondenceShow = (event) => {
    setShowCorrespondence(event.target.checked);
    let developer = form.getFieldValue('developer');

    if (!developer.correspondenceAddress) {
      developer.correspondenceAddress = { country: 'Polska' };
    }

    form.setFieldValue('developer', developer);
  };

  return (
    <div className='form-create'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
        text={'Trwa dodawanie plików...'}
      />
      <PageHeader
        ghost={false}
        backIcon={<Icon name='arrow-simply-left' />}
        // onBack={() => history.push('/housingAssociations')}
        onBack={() => history.goBack()}
        title={<HeaderTitle />}
      >
        <Form
          form={form}
          name='create'
          className='create-form'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <>
            <Card className='form-create-main-card'>
              <Tabs defaultActiveKey={defaultActiveTab} tabPosition='left'>
                <TabPane tab='Informacje' key='form'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <div className='form-section'>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <h2>Logo spółdzielni</h2>
                              <Upload
                                listType='picture-card'
                                fileList={logo}
                                onChange={handleLogoChange}
                                beforeUpload={beforeUpload}
                                showUploadList={{
                                  showPreviewIcon: false,
                                  showRemoveIcon: true,
                                  showDownloadIcon: false,
                                }}
                              >
                                <LogoRenderer />
                              </Upload>
                              <span
                                style={{
                                  marginTop: '20px',
                                  fontSize: '12px',
                                  color: '#C0D6E5',
                                }}
                              >
                                Kliknij aby zmienić obraz (min. 300x300px, JPG,
                                PNG)
                              </span>
                            </Col>
                            <Col xs={24} sm={12}>
                              <h2>Miniaturka</h2>
                              <Upload
                                listType='picture-card'
                                fileList={thumb}
                                onChange={handleThumbChange}
                                beforeUpload={beforeUpload}
                                showUploadList={{
                                  showPreviewIcon: false,
                                  showRemoveIcon: true,
                                  showDownloadIcon: false,
                                }}
                              >
                                <ThumbRenderer />
                              </Upload>
                              <span
                                style={{
                                  marginTop: '20px',
                                  fontSize: '12px',
                                  color: '#C0D6E5',
                                }}
                              >
                                Kliknij aby zmienić obraz (min. 300x300px, JPG,
                                PNG)
                              </span>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Dane podstawowe</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='name'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić nazwę',
                                  },
                                ]}
                              >
                                <Input
                                  size='large'
                                  placeholder='Nazwa spółdzielni'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='createDate'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić datę powstania',
                                  },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data powstania'
                                  style={{ width: '100%' }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='heatSource'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę wybrać źródło ciepła',
                                  },
                                ]}
                              >
                                <Select
                                  placeholder='Źródło ciepła'
                                  size='large'
                                >
                                  <Option value='GAS'>Gaz</Option>
                                  <Option value='SYSTEM'>
                                    Ciepło systemowe
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Adres</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'street']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Ulica' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item name={['address', 'number']}>
                                <Input size='large' placeholder='Numer' />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'city']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input size='large' placeholder='Miejscowość' />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'zip']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <Input.MaskedInput
                                  mask='00-000'
                                  size='large'
                                  placeholder='Kod pocztowy'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name={['address', 'country']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Proszę uzupełnić adres',
                                  },
                                ]}
                              >
                                <CountrySelect />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Dane do rozliczeń</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item name='nip'>
                                <Input.MaskedInput
                                  mask='000-000-00-00'
                                  size='large'
                                  placeholder='NIP'
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Form.Item name='regon'>
                                <Input.MaskedInput
                                  mask='000000000'
                                  size='large'
                                  placeholder='Regon'
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Powierzchnia</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='size'
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Proszę podać powierzchnię całkowitą',
                                  },
                                ]}
                              >
                                <Input.InputNumberV2
                                  size='large'
                                  placeholder='Powierzchnia całkowita'
                                  addonAfter={
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={0} sm={12} />
                          </Row>
                        </div>
                        <div className='form-section'>
                          <h2>Zgłaszanie usterek</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='isFaultsActive'
                                label='Aktywne'
                                valuePropName='checked'
                              >
                                <Checkbox />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>

                <TabPane tab='Hala garażowa' key='garageHalls'>
                  <GarageHallsInvestment setEditInvestment={setInvestment} />
                </TabPane>

                <TabPane tab='Deweloper' key='developer'>
                  <div className='create-form-wrapper'>
                    <Row>
                      <Col xs={20} offset={2}>
                        <h2>Deweloper spółdzielni</h2>
                        <Form.Item name='developerId'>
                          <SearchBox
                            disabled={showDifferentDeveloper}
                            placeholder='Deweloper'
                            optionKey={'name'}
                            size='large'
                            resource={new housingAssociationsService()}
                            methodName={'getUsersList'}
                            filters={{ role: 'ROLE_DEVELOPER' }}
                            filterOption={(inputValue, option) => {
                              return option.key.indexOf(inputValue) !== -1;
                            }}
                          />
                        </Form.Item>

                        <Form.Item>
                          <Form.Item
                            name='differentDeveloper'
                            valuePropName='checked'
                            noStyle
                          >
                            <Checkbox onChange={differentDeveloperShow}>
                              Deweloper bez konta
                            </Checkbox>
                          </Form.Item>
                        </Form.Item>
                        {showDifferentDeveloper && (
                          <>
                            <h2 style={{ marginTop: 60 }}>Dane dewelopera</h2>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'name']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input
                                    size='large'
                                    placeholder='Nazwa firmy'
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'nip']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input.MaskedInput
                                    mask='000-000-00-00'
                                    size='large'
                                    placeholder='NIP'
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <h2 style={{ marginTop: 60 }}>Adres siedziby</h2>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'address', 'street']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input size='large' placeholder='Ulica' />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={6}>
                                <Form.Item
                                  name={['developer', 'address', 'number']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input size='large' placeholder='Numer' />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={6}>
                                <Form.Item
                                  name={['developer', 'address', 'local']}
                                >
                                  <Input
                                    size='large'
                                    placeholder='Mieszkanie'
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'address', 'city']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input
                                    size='large'
                                    placeholder='Miejscowość'
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'address', 'zip']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <Input.MaskedInput
                                    mask='00-000'
                                    size='large'
                                    placeholder='Kod pocztowy'
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={12}>
                                <Form.Item
                                  name={['developer', 'address', 'country']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Pole wymagane',
                                    },
                                  ]}
                                >
                                  <CountrySelect />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Form.Item>
                              <Form.Item
                                name='correspondenceShow'
                                valuePropName='checked'
                                noStyle
                              >
                                <Checkbox onChange={correspondenceShow}>
                                  Inny adres korespondencji
                                </Checkbox>
                              </Form.Item>
                            </Form.Item>

                            {showCorrespondence && (
                              <>
                                {' '}
                                <h2 style={{ marginTop: 60 }}>
                                  Adres korespondencyjny
                                </h2>
                                <Row gutter={20}>
                                  <Col xs={24} sm={12}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'street',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <Input size='large' placeholder='Ulica' />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={6}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'number',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <Input size='large' placeholder='Numer' />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={6}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'local',
                                      ]}
                                    >
                                      <Input
                                        size='large'
                                        placeholder='Mieszkanie'
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={20}>
                                  <Col xs={24} sm={12}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'city',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <Input
                                        size='large'
                                        placeholder='Miejscowość'
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'zip',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <Input.MaskedInput
                                        mask='00-000'
                                        size='large'
                                        placeholder='Kod pocztowy'
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={20}>
                                  <Col xs={24} sm={12}>
                                    <Form.Item
                                      name={[
                                        'developer',
                                        'correspondenceAddress',
                                        'country',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Pole wymagane',
                                        },
                                      ]}
                                    >
                                      <CountrySelect />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            )}

                            <div className='form-section'>
                              <h2 style={{ marginTop: 60 }}>Dane kontaktowe</h2>
                              <Row gutter={20}>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['developer', 'email']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Proszę uzupełnić adres email',
                                      },
                                      {
                                        type: 'email',
                                        message: 'Niepoprawny adres email',
                                      },
                                    ]}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Adres e-mail'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['developer', 'phone']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Pole wymagane',
                                      },
                                    ]}
                                  >
                                    <Mobile
                                      size='large'
                                      placeholder='Telefon'
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                  <Form.Item
                                    name={['developer', 'website']}
                                    rules={[
                                      {
                                        required: false,
                                        message:
                                          'Proszę uzupełnić adres strony internetowej',
                                      },
                                      {
                                        type: 'url',
                                        message:
                                          'Niepoprawny adres strony internetowej',
                                      },
                                    ]}
                                  >
                                    <Input
                                      size='large'
                                      placeholder='Strona www'
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>

                            <div
                              className='form-section'
                              style={{ marginTop: 60 }}
                            >
                              <Row gutter={20}>
                                <Col xs={20}>
                                  <h2>Szablon zgłaszania usterki</h2>
                                </Col>
                                {faultTemplate.length === 0 &&
                                  !investment.developer?.faultTemplateFile && (
                                    <Col xs={4} style={{ textAlign: 'right' }}>
                                      <Button
                                        className='button-secondary'
                                        style={{ width: 48, height: 48 }}
                                        onClick={() => {
                                          faultTemplateRef.current.upload.uploader.onClick();
                                        }}
                                      >
                                        <Icon name={'plus'} />
                                      </Button>
                                    </Col>
                                  )}
                              </Row>
                              <Row style={{ marginTop: 20, width: '100%' }}>
                                <Col xs={24}>
                                  <Upload
                                    ref={faultTemplateRef}
                                    openFileDialogOnClick={false}
                                    xs={24}
                                    showUploadList={false}
                                    fileList={faultTemplate}
                                    onChange={handleFaultTemplateChange}
                                    beforeUpload={beforeFaultTemplateUpload}
                                  >
                                    <FaultTemplateRenderer />
                                  </Upload>
                                </Col>
                              </Row>
                              <Row>
                                <span
                                  style={{
                                    marginTop: '12px',
                                    fontSize: '12px',
                                    color: '#C0D6E5',
                                  }}
                                >
                                  Maksymalny rozmiar pliku: 15MB
                                </span>
                              </Row>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>{' '}
                  </div>
                </TabPane>

                <TabPane tab='Pracownicy' key='workers'>
                  <WorkersInvestment setEditInvestment={setInvestment} />
                </TabPane>

                <TabPane tab='Dodatkowe kontakty' key='contacts'>
                  <ContactsInvestment investmentId={id} />
                </TabPane>
              </Tabs>
              <Button
                className='delete-btn'
                type='danger'
                onClick={() => deleteConfirm(handleOk)}
              >
                <Icon name='delete' style={{ marginRight: '5px' }} /> Usuń
              </Button>
            </Card>
            <Row className='form-actions' justify='space-between'>
              <Col sm={4} className='form-actions-cancel-wrapper'>
                <Button
                  className='button-secondary'
                  size='large'
                  onClick={handleCancel}
                >
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className='form-actions-save-wrapper'>
                <Form.Item>
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    className='create-form-button'
                  >
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Edit;
