import BaseService from './baseService';

class RoomsService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    const roles = localStorage.getItem('roles');

    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/room`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/premises/${premisesId}/room`;
    }
  }
}

export default RoomsService;
