import BaseService from './baseService';

class OwnersService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/owners/history`;
  }
}

export default OwnersService;
