import BaseService from './baseService';

class HousingTenantSettlemetsService extends BaseService {
  constructor(tenantId) {
    super();

    this.url = `/api/community/tenants/${tenantId}/payments`;
  }
}

export default HousingTenantSettlemetsService;
