/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Dropdown, Menu, Modal, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import housingAssociationsService from '../../services/housingAssociationsService';
import userService from '../../services/userService';

import Icon from '../../components/Icon';
import Empty from '../../components/Empty';
import Spin from '../../components/Spin';
import MessagePopup from '../../components/MessagePopup';

const { confirm } = Modal;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const List = () => {
  const [investments, setInvestments] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const history = useHistory();

  useEffect(() => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.getList({
      types: 'HOUSING_ASSOCIATION',
      sortField: 'position',
      sortOrder: 'ascend',
    }).then((data) => {
      setInvestments(data.content);
    });

    userService.getInfo().then((response) => {
      setUserData(response);

      if (response.roles.includes('ROLE_COMMUNITY_ADMIN')) {
        setCanEdit(true);
      }

      setLoading(false);
    });
  }, []);

  const deleteAction = (id) => {
    const HousingAssociationsService = new housingAssociationsService();
    HousingAssociationsService.delete(id)
      .then(() => history.go(0))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Usunąć wybraną pozycje?',
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        deleteAction(record.id);
      },
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const items = reorder(investments, result.oldIndex, result.newIndex);

    setInvestments(items);
    items.forEach((item, key) => {
      const HousingAssociationsService = new housingAssociationsService();
      HousingAssociationsService.updateFields(item.id, { position: key });
    });
  };

  const DragHandle = SortableHandle(() => (
    <div className='investments-list-item-drag'>
      <Icon name='drag-n-drop' />
    </div>
  ));

  const SortableItem = SortableElement(({ value }) => {
    const { id, name, thumbnailFile, createYear } = value;
    return (
      <Col xs={24} sm={12} md={8} xl={6}>
        <div className='investments-list-item'>
          <div className='investments-list-item-logo'>
            <Link to={`/housingAssociations/${id}`}>
              <img src={`${baseUrl}${thumbnailFile}`} alt={name} />
            </Link>
            <DragHandle />
          </div>
          <div className='investments-list-item-menu'>
            <Row>
              <Col xs={20}>
                <Link
                  className='investments-list-item-menu-name'
                  to={`/housingAssociations/${id}`}
                >
                  <span>{name}</span>
                </Link>
                <div className='investments-list-item-menu-type'>
                  {createYear}
                </div>
              </Col>
              <Col xs={4} style={{ textAlign: 'right' }}>
                <Options record={value} />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    );
  });

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key='1'
        icon={<Icon name='more-info' style={{ marginRight: '5px' }} />}
      >
        <Link
          to={`/housingAssociations/${record.id}/show`}
          className='premises-custom-create-link'
        >
          Informacje
        </Link>
      </Menu.Item>
      {canEdit && (
        <Menu.Item
          key='2'
          icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
        >
          <Link
            to={`/housingAssociations/${record.id}/edit`}
            className='premises-custom-create-link'
          >
            Edytuj
          </Link>
        </Menu.Item>
      )}
      {canEdit && (
        <Menu.Item
          key='3'
          onClick={() => showDeleteConfirm(record)}
          style={{ color: '#EC434C' }}
          icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
        >
          Usuń
        </Menu.Item>
      )}
    </Menu>
  );

  const Options = ({ record }) => (
    <Dropdown
      overlay={() => menu(record)}
      trigger='click'
      placement='bottomCenter'
    >
      <Button className='button-no-border'>
        <Icon name='more-vertical' style={{ transform: 'rotate(45deg)' }} />
      </Button>
    </Dropdown>
  );

  const SortableList = SortableContainer(({ items }) => (
    <Row gutter={20}>
      {items.map((singleInvestment, index) => (
        <SortableItem
          key={`item-${singleInvestment.id}`}
          index={index}
          value={singleInvestment}
        />
      ))}
    </Row>
  ));

  return (
    <div className='investments-list-wrapper'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <Row justify='space-between' style={{ marginBottom: '20px' }}>
            <Col className='investments-list-name-wrapper'>
              <h1 style={{ fontSize: '24px', margin: 0 }}>Spółdzielnie</h1>
            </Col>
            <Col style={{ textAlign: 'right' }}>
              {canEdit && (
                <Link to='/housingAssociations/create'>
                  <Button type='primary'>
                    Dodaj <Icon name='plus' style={{ marginLeft: '5px' }} />
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
          <div className='investments-list'>
            {investments.length === 0 && <Empty width='100%' />}
            <SortableList
              items={investments}
              onSortEnd={onDragEnd}
              useDragHandle
              axis='xy'
            />
          </div>
        </>
      )}
    </div>
  );
};

export default List;
