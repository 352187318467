import React, { useEffect, useState } from 'react';
import { PageHeader, Tooltip } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import faultsService from '../../services/faultsService';
import clientsService from '../../services/clientsService';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';

import faultsStatusMap from '../../helpers/premiseStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  return (
    <Tooltip title={faultsStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const columns = [
  {
    title: 'Nazwa',
    dataIndex: 'name',
    visible: true,
    key: 'name',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: false,
  },
  {
    title: 'Typ',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: false,
    render: (text, record) => premiseTypeMap(record.premisesType),
    exportRender: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: false,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    visible: true,
    key: 'status',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Dot status={record.status} />),
    exportRender: (text, record) => faultsStatusMap(record.status),
  },
  {
    title: 'Dodano',
    dataIndex: 'createDate',
    visible: true,
    key: 'createDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Zakończono',
    dataIndex: 'doneDate',
    visible: true,
    key: 'doneDate',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text) => (
      <span style={{ whiteSpace: 'nowrap' }}>
        {text}
      </span>
    ),
  },
  {
    title: 'Kwota',
    dataIndex: 'price',
    visible: true,
    key: 'price',
    sorter: false,
    render: (text, record) => (<Price value={record.price} showEmpty />),
  },
];

const CustomCreate = () => ('');

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const FaultsService = new faultsService(null, null, null, id);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    clientsService.getOne(id).then((data) => {
      setUserData(data);
      setLoading(false);
    });
  }, [id]);

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Usterki</span>;
    }
    return (
      <span>
        Usterki:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/clientsTenants/${id}/show`)}
          title={<HeaderTitle />}
        >
          <div className="faults-list">
            <Datagrid
              resource={`investments/${id}/faults`}
              dataProvider={FaultsService}
              columns={columns}
              customCreate={<CustomCreate />}
              customBaseUrlKey="clientTenantsFaults"
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
