import React, { useState } from 'react';
import { PageHeader, Card, Row, Col, Radio } from 'antd';

const Theme = ({ name, theme, code, selectTheme }) => {
  const activeClassName = theme === code ? 'theme-active' : '';
  return (
    <div className={`theme theme-${code} ${activeClassName}`}>
      <div className='checkbox'>
        <Radio
          defaultChecked={theme === code}
          onChange={() => selectTheme(code)}
          checked={theme === code}
          size='large'
        >
          {name}
        </Radio>
      </div>
      <div className='colors'>
        <Row gutter={20}>
          <Col xs={8}>
            <div className='colors-box primary' />
          </Col>
          <Col xs={8}>
            <div className='colors-box secondary' />
          </Col>
          <Col xs={8}>
            <div className='colors-box text' />
          </Col>
        </Row>
      </div>
    </div>
  );
};
const ChangeView = () => {
  const currentTheme = localStorage.getItem('theme') || 'default';
  const [theme, setTheme] = useState(currentTheme);

  const selectTheme = (value) => {
    localStorage.setItem('theme', value);
    document.body.classList.remove('theme-default');
    document.body.classList.remove('theme-sky');
    document.body.classList.remove('theme-brick');
    document.body.classList.remove('theme-mech');
    document.body.classList.add(`theme-${value}`);
    setTheme(value);
  };
  return (
    <div>
      <PageHeader ghost={false} title='Dostosuj wygląd'>
        <Card>
          <div className='themes'>
            <div className='subtitle'>
              <h2>Kolorystyka panelu administracyjnego</h2>
            </div>
            <Row gutter={20}>
              <Col xs={24} md={12} xl={8}>
                <Theme
                  name='Manage (domyślny)'
                  code='default'
                  theme={theme}
                  selectTheme={selectTheme}
                />
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Theme
                  name='Bezchmurne niebo'
                  code='sky'
                  theme={theme}
                  selectTheme={selectTheme}
                />
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Theme
                  name='Gliniana cegła'
                  code='brick'
                  theme={theme}
                  selectTheme={selectTheme}
                />
              </Col>
              <Col xs={24} md={12} xl={8}>
                <Theme
                  name='Mech o poranku'
                  code='mech'
                  theme={theme}
                  selectTheme={selectTheme}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </PageHeader>
    </div>
  );
};

export default ChangeView;
