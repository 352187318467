/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { PageHeader, Button, Row, Col, Card, Form } from 'antd';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';
import Input from '../../components/InputComponent';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import { renderUnit } from '../../helpers/unitRender';
import massCountersService from '../../services/massCountersService';
import subCountersHistoryService from '../../services/subCountersHistoryService';
import moment from 'moment';

const { DatePicker, InputNumberV2 } = Input;

const CounterForm = forwardRef(({ subCounter }, ref) => {
  const [form] = Form.useForm();
  const { investmentId } = useParams();

  useEffect(() => {
    if (subCounter.subCounterId) {
      const initValues = {
        premiseName: [
          premiseTypeMap(subCounter.premisesType),
          subCounter.premisesName,
        ]
          .filter((el) => !!el)
          .join(' '),
        mainCounterName: subCounter.mainCounterName,
        subCounterName: subCounter.subCounterName,
        value: subCounter.subCounterLastValue
          ? subCounter.subCounterLastValue
          : null,
        readDate:
          subCounter.subCounterLastDate &&
          moment(subCounter.subCounterLastDate),
      };

      form.setFieldsValue(initValues);
    }
  }, [subCounter]);

  useImperativeHandle(ref, () => ({
    async validateForm() {
      return form.validateFields().catch((err) => {
        throw err;
      });
    },
    async submitForm() {
      return form.validateFields().then((values) => {
        if (
          values.value !== subCounter.subCounterLastValue &&
          values.readDate?.isAfter(subCounter.subCounterLastDate, 'day')
        ) {
          const tempValues = {
            value: values.value,
            readDate: values.readDate.format('YYYY-MM-DD'),
          };

          const SubCountersHistoryService = new subCountersHistoryService(
            investmentId,
            subCounter.mainCounterId,
            subCounter.subCounterId
          );

          SubCountersHistoryService.create(tempValues);
        }
      });
    },
  }));

  if (!subCounter.subCounterId) {
    return null;
  }

  return (
    <Form name='create' className='create-form' autoComplete='off' form={form}>
      <Card className='assignSingleCard' style={{ marginBottom: 10 }}>
        <Row gutter={20}>
          <Col xs={24} lg={12} xxl={6}>
            <Form.Item
              name='premiseName'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Lokal' disabled />
            </Form.Item>
          </Col>
          <Col xs={0} lg={12} xxl={0} />
          <Col xs={24} lg={12} xxl={5}>
            <Form.Item
              name='mainCounterName'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Licznik główny' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={5}>
            <Form.Item
              name='subCounterName'
              rules={[
                {
                  required: true,
                  message: 'Proszę uzupełnić nazwę',
                },
              ]}
            >
              <Input size='large' placeholder='Podlicznik' disabled />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xxl={4}>
            <Form.Item
              name='value'
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedReadDate = form.getFieldValue('readDate');
                      if (!value && inputedReadDate) {
                        reject('Pole wymagane');
                      }
                      resolve();
                    });
                  },
                },
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedReadDate = form.getFieldValue('readDate');

                      if (
                        !value ||
                        (parseFloat(value) === subCounter.subCounterLastValue &&
                          inputedReadDate?.isSame(
                            moment(subCounter.subCounterLastDate),
                            'day'
                          ))
                      ) {
                        resolve();
                      }

                      if (value <= subCounter.subCounterLastValue) {
                        reject('Odczyt musi być większy niż poprzedni');
                      }
                      resolve();
                    });
                  },
                },
              ]}
            >
              <InputNumberV2
                size='large'
                placeholder='Stan licznika'
                addonAfter={renderUnit(subCounter.mainCounterUnit)}
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 20,
              }}
            >
              Aktualnie:
              <span style={{ fontWeight: 700, marginLeft: 5 }}>
                {subCounter.subCounterLastValue ? (
                  <>
                    {subCounter.subCounterLastValue.toFixed(2)}{' '}
                    {renderUnit(subCounter.mainCounterUnit)}
                  </>
                ) : (
                  'brak'
                )}
              </span>
            </div>
          </Col>
          <Col xs={24} lg={12} xxl={4}>
            <Form.Item
              name='readDate'
              rules={[
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedReadValue = form.getFieldValue('value');
                      if (!value && inputedReadValue) {
                        reject('Pole wymagane');
                      }
                      resolve();
                    });
                  },
                },
                {
                  validator(rule, value) {
                    return new Promise((resolve, reject) => {
                      const inputedReadValue = form.getFieldValue('value');

                      if (
                        !value ||
                        (parseFloat(inputedReadValue) ===
                          subCounter.subCounterLastValue &&
                          value?.isSame(
                            moment(subCounter.subCounterLastDate),
                            'day'
                          ))
                      ) {
                        resolve();
                      }

                      if (
                        value?.isSameOrBefore(
                          moment(subCounter.subCounterLastDate),
                          'day'
                        )
                      ) {
                        reject('Data musi być późniejsza niż poprzednia');
                      }
                      resolve();
                    });
                  },
                },
              ]}
            >
              <DatePicker
                size='large'
                placeholder='Data od'
                style={{ width: '100%' }}
              />
            </Form.Item>
            <div
              style={{
                color: '#B1CADE',
                fontSize: 12,
                paddingLeft: 15,
                marginTop: -10,
                marginBottom: 20,
              }}
            >
              Ostatnio:
              <span style={{ fontWeight: 700, marginLeft: 5 }}>
                {subCounter.subCounterLastDate ?? 'brak'}
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
});

const PremiseCounters = forwardRef(({ countersData }, ref) => {
  const countersRefs = useRef(Array(countersData.length));

  useImperativeHandle(ref, () => ({
    async validateForm() {
      const validatePromises = [];

      for (let itemRef of countersRefs.current) {
        validatePromises.push(itemRef.validateForm());
      }

      return Promise.all(validatePromises);
    },
    submitForm() {
      const submitPromises = [];

      for (let itemRef of countersRefs.current) {
        submitPromises.push(itemRef.submitForm());
      }

      return Promise.all(submitPromises);
    },
  }));

  return (
    <>
      {countersData.length > 0 ? (
        countersData.map((subCounter, idx) => (
          <CounterForm
            subCounter={subCounter}
            ref={(el) => {
              countersRefs.current[idx] = el;
            }}
          />
        ))
      ) : (
        <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
          W WYBRANYCH LOKALACH NIE MA PODLICZNIKÓW
        </div>
      )}
    </>
  );
});

const useQuery = () => new URLSearchParams(useLocation().search);

const AssignOwners = () => {
  const { investmentId } = useParams();
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [countersData, setCountersData] = useState([]);
  const itemRef = useRef();

  useEffect(() => {
    const premisesIds = query.getAll('premiseId');

    massCountersService
      .getMassActionsCounters(investmentId, [premisesIds])
      .then((data) => {
        const tempData = data
          .map((premiseCountersData) => ({
            ...premiseCountersData,
            counters: premiseCountersData.counters.filter(
              (subCounter) => !!subCounter.subCounterId
            ),
          }))
          .filter(
            (premiseCountersData) => premiseCountersData.counters.length > 0
          );

        const tempData2 = tempData.map((premiseCountersData) =>
          premiseCountersData.counters.map((record) => ({
            ...record,
            premisesName: premiseCountersData.premisesName,
            premisesType: premiseCountersData.premisesType,
          }))
        );

        setCountersData(tempData2.reduce((a, b) => a.concat(b), []));
      });

    setLoading(false);
  }, [investmentId]);

  const handleSubmit = async () => {
    itemRef.current
      .validateForm()
      .then((values) => {
        itemRef.current.submitForm().then(() => {
          history.goBack();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='show-layout'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          backIcon={<Icon name='arrow-simply-left' />}
          title='Dodaj odczyt licznika'
          className='page-header-extra-2'
        >
          <Card className='assignCard'>
            <PremiseCounters countersData={countersData} ref={itemRef} />
          </Card>

          <Row className='form-actions' justify='space-between'>
            <Col sm={4} className='form-actions-cancel-wrapper'>
              <Button
                className='button-secondary'
                size='large'
                onClick={() => window.history.back()}
              >
                Anuluj
              </Button>
            </Col>
            <Col sm={4} className='form-actions-save-wrapper'>
              <Button
                type='primary'
                size='large'
                className='create-form-button'
                onClick={handleSubmit}
              >
                Zapisz
              </Button>
            </Col>
          </Row>
        </PageHeader>
      )}
    </div>
  );
};

export default AssignOwners;
