import React from 'react';
import './App.less';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoggedLayout from './layout/LoggedLayout';
import UnloggedLayout from './layout/UnloggedLayout';
import NotFound from './layout/NotFound';
import FaultConfirmation from './routes/faultConfirmation/FaultConfirmation';

const App = () => (
  <div className="App">
    <Router basename="/crm">
      <Switch>
        <Route path="/login">
          <UnloggedLayout />
        </Route>
        <Route path="/resetPassword">
          <UnloggedLayout />
        </Route>
        <Route path="/404">
          <NotFound />
        </Route>
        <Route path="/fault/faultConfirmation">
          <FaultConfirmation />
        </Route>
        <LoggedLayout />
      </Switch>
    </Router>
  </div>
);

export default App;
