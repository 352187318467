import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Row, Col, Card, Divider } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import PaymentsServiceS from '../../services/paymentsService';
import TenantSettlementsService from '../../services/tenantSettlementsService';
import tenantPremisesSettlementsService from '../../services/tenantPremisesSettlementsService';
import TPayS from '../../services/tpayService';
import Icon from '../../components/Icon';
import Spin from '../../components/Spin';

const Payments = () => {
  const { investmentId, premisesId, settlementId, paymentId } = useParams();

  const history = useHistory();
  const [methods, setMethods] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [settlementData, setSettlementData] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (
      investmentId !== 'null' &&
      premisesId !== 'null' &&
      settlementId !== 'null'
    ) {
      const TenantPremisesSettlementsService =
        new tenantPremisesSettlementsService(investmentId, premisesId);
      TenantPremisesSettlementsService.getOne(settlementId).then((response) => {
        setSettlementData(response);
        setLoading(false);
      });
    } else {
      TenantSettlementsService.getBalance().then((response) => {
        setBalance(response.balance);
        setLoading(false);
      });
    }
  }, [investmentId, premisesId, settlementId, paymentId]);

  useEffect(() => {
    const PaymentsService = new PaymentsServiceS(
      investmentId,
      premisesId,
      null,
      null
    );
    PaymentsService.getPaymentMethods().then((response) => {
      setMethods(response);
      setLoading(false);
    });
  }, [investmentId, premisesId, settlementId, paymentId]);

  const PaymentMethod = ({ method, selectHandle, currentId }) => (
    <Col xs={12} sm={8} lg={6} xl={4}>
      <div
        role='presentation'
        className={`method ${currentId === method.id ? 'active' : ''}`}
        onClick={() => {
          selectHandle(method.id);
        }}
      >
        <img src={method.img} alt={method.name} />
      </div>
    </Col>
  );

  const redirectToPaymentMethod = () => {
    const TpayService = new TPayS();
    TpayService.getTpayResponse(paymentId, selectedId)
      .then((response) => {
        if (response?.url) {
          window.location.href = response.url;
        }
      })
      .catch((err) => console.log(err));
  };

  const selectMethod = (value) => {
    setSelectedId(value);
  };

  const goBack = () => {
    return history.goBack();
  };

  const PaymentInfo = () => {
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: '#F0F4F6',
          color: '#3B4C60',
          padding: 30,
          borderRadius: 10,
          marginBottom: 25,
          fontStyle: 'Overpass',
          fontWeight: 14,
        }}
      >
        {investmentId !== 'null' &&
        premisesId !== 'null' &&
        settlementId !== 'null' ? (
          <div>
            Opłacasz rozliczenie {settlementData.name} -{' '}
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontWeight: 700 }}>
                {parseFloat(settlementData.price, 2).toFixed(2)} zł
              </span>
            </div>
          </div>
        ) : (
          <div>
            Opłacasz saldo -{' '}
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontWeight: 700 }}>
                {parseFloat(balance, 2).toFixed(2)} zł
              </span>
            </div>
          </div>
        )}

        {investmentId !== 'null' &&
          premisesId !== 'null' &&
          settlementId !== 'null' && (
            <div>
              Termin płatności:{' '}
              <span style={{ fontWeight: 700 }}>
                {settlementData.paymentDate}
              </span>
            </div>
          )}
      </div>
    );
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => goBack()}
          title='Wybierz metodę płatności'
        >
          <>
            <Card className='form-create-main-card'>
              <div className='methods'>
                <Row>
                  <PaymentInfo />
                </Row>
                <Row gutter={20}>
                  {Object.keys(methods).map((key) => (
                    <PaymentMethod
                      key={key}
                      method={methods[key]}
                      selectHandle={selectMethod}
                      currentId={selectedId}
                    />
                  ))}
                </Row>
                <Divider />

                <div
                  style={{
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className='tpay-logo-wrapper'>
                    <img
                      src='https://tpay.com/user/themes/tpay-trusted-payments/images/logo/TPAY_CMYK.svg'
                      style={{
                        height: '2.25rem',
                        transform: 'scale(2.6) translateX(5px)',
                      }}
                    />
                    <div
                      style={{
                        color: '#3ED36D',
                        fontSize: '.875rem',
                        lineHeight: '.8rem',
                        margin: '0.25rem 0 0 2.5rem',
                        textAlign: 'left',
                        width: 64,
                      }}
                    >
                      zaufane płatności
                    </div>
                  </div>
                  <Button
                    type='primary'
                    disabled={!selectedId}
                    size='large'
                    onClick={() => redirectToPaymentMethod()}
                    className='payment-redirect-payment-button'
                  >
                    Przejdź do płatności
                    <Icon name='access' style={{ marginLeft: 20 }} />
                  </Button>
                </div>
              </div>
            </Card>
          </>
        </PageHeader>
      )}
    </div>
  );
};
export default Payments;
