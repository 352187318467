import React, { useState, useEffect } from 'react';
import { Row, Col, Upload, Button, Menu, message } from 'antd';
import SmallList from '../../../../components/SmallList';
import Icon from '../../../../components/Icon';
import AttachmentsServiceS from '../../../../services/attachmentsService';
import MessagePopup from '../../../../components/MessagePopup';
import ProgressModal from '../../../../components/ProgressModal';

export const Attachments = ({ investmentId, premisesId, addAction }) => {
  const [attachments, setAttachments] = useState([]);
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );
    AttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  };

  const columns = [
    {
      dataIndex: 'filename',
      editable: true,
    },
  ];

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('attachment', data.file);
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );

    setProgressModalVisible(true);

    AttachmentsService.create(formData, {
      onUploadProgress: (progressEvent) => {
        setImportProgress((progressEvent.loaded / progressEvent.total) * 100);
      },
    })
      .then((response) => {
        setProgressModalVisible(false);
        message.success(
          <MessagePopup type={'success'}>
            Pomyślnie dodano załącznik
          </MessagePopup>
        );

        if (response.id) {
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const deleteAction = (entityId) => {
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );
    AttachmentsService.delete(entityId)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const updateAction = (entityId, data) => {
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );

    const tempData = {};
    const attachment = attachments.find((el) => (el.id = entityId));

    const oldExt = attachment.filename.split('.').pop();
    const fileExt = data.filename.split('.').pop();

    if (fileExt === oldExt) {
      tempData.filename = data.filename;
    } else {
      tempData.filename = `${data.filename}.${oldExt}`;
    }

    AttachmentsService.updateData(entityId, tempData)
      .then(() => refresh())
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 15;
    if (!isLt2M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return isLt2M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
    };
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          onClick={addAction}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  const handleDownload = (fileId) => {
    const AttachmentsService = new AttachmentsServiceS(
      investmentId,
      premisesId
    );
    AttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const downloadAction = (
      <Menu.Item
        key='1'
        onClick={() => handleDownload(record.id)}
        icon={<Icon name='download' style={{ marginRight: '5px' }} />}
      >
        Pobierz
      </Menu.Item>
    );
    tempArray.push(downloadAction);
    return tempArray;
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            icon='file'
            data={attachments}
            columns={columns}
            title='Lista załączników'
            deleteAction={deleteAction}
            updateAction={updateAction}
            inlineEditLabel='Zmień nazwę'
            customAddButton={<CustomAddButton />}
            hideEdit
            customMenuActions={customMenuActions}
          />
          <div
            className='attachmentsNote'
            style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}
          >
            Maksymalny rozmiar pliku: 15MB.
          </div>
        </Col>
      </Row>
    </div>
  );
};
