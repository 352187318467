import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import SmallList from '../../../../components/SmallList';
import MeterModal from '../modals/MeterModal';
import metersService from '../../../../services/metersService';

export const Meters = (props) => {
  const { investmentId, premisesId, heatSource } = props;
  const [metersModal, setMetersModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [meters, setMeters] = useState([]);

  useEffect(() => {
    const MetersService = new metersService(investmentId, premisesId);
    MetersService.getList().then((data) => {
      setMeters(data);
    });
  }, [investmentId, premisesId]);

  const refresh = () => {
    const MetersService = new metersService(investmentId, premisesId);
    MetersService.getList().then((data) => {
      setMeters(data);
    });
  };

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'CW (m3)',
      dataIndex: 'hotWater',
    },
    {
      title: 'ZW (m3)',
      dataIndex: 'coldWater',
    },
    {
      title: 'Prąd (kw)',
      dataIndex: 'electricity',
    },
  ];

  if (heatSource === 'GAS') {
    columns.push({
      title: 'Gaz (m3)',
      dataIndex: 'gas',
    });
  }

  if (heatSource === 'SYSTEM') {
    columns.push({
      title: 'Ciepło systemowe (GJ)',
      dataIndex: 'system',
    });
  }

  const addNewAction = (data) => {
    if (!heatSource) {
      message.error('Proszę uzupełnić pole "Źródło ogrzewania" w edycji inwestycji');
      return;
    }
    setModalData(data);
    setMetersModal(true);
  };
  const afterSave = () => {
    setMetersModal(false);
    setModalData({});
    refresh();
  };
  const afterCancel = () => {
    setMetersModal(false);
    setModalData({});
  };
  const deleteAction = (entityId) => {
    const MetersService = new metersService(props.investmentId, props.premisesId);
    MetersService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
    setModalData({});
  };
  return (
    <div className="meters">
      <SmallList icon="timer" data={meters} columns={columns} title="Stan liczników" addAction={addNewAction} deleteAction={deleteAction} />
      {metersModal && (
      <MeterModal
        data={modalData}
        isModalVisible={metersModal}
        afterSave={afterSave}
        afterCancel={afterCancel}
        heatSource={heatSource}
      />
      )}
    </div>
  );
};
