import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import {
  StatsWrapper,
  SalePremisesComponent,
  SaleFaultsComponent,
  TenantChangesComponent,
  RentPremisesComponent,
  RentFaultsComponent,
  AgreementsComponent,
} from './ViewComponents';

const Dashboard = ({ workerPermissions, ownerRoles = [] }) => {
  if (ownerRoles.includes('ROLE_DEVELOPER') && ownerRoles.includes('ROLE_TENANT')) {
    return <WorkerFullDashboard workerPermissions={workerPermissions} />;
  } if (ownerRoles.includes('ROLE_DEVELOPER')) {
    return <WorkerDeveloperDashboard workerPermissions={workerPermissions} />;
  }

  return <WorkerTenantDashboard workerPermissions={workerPermissions} />;
};
const WorkerFullDashboard = ({ workerPermissions }) => (
  <Row gutter={20}>
    <Col xs={24}>
      {(workerPermissions.includes('RECEIPTS_FULL') || workerPermissions.includes('FAULTS_FULL') || workerPermissions.includes('TENANTS_CHANGES_FULL')) && (
        <StatsWrapper title="Sprzedaż" icon="dolar">
          {workerPermissions.includes('RECEIPTS_FULL') && (
          <SalePremisesComponent workerPermissions={workerPermissions} isWorker />
          )}
          {workerPermissions.includes('FAULTS_FULL') && (
          <SaleFaultsComponent workerPermissions={workerPermissions} isWorker />
          )}
          {workerPermissions.includes('TENANTS_CHANGES_FULL') && (
          <TenantChangesComponent />
          )}
        </StatsWrapper>
      )}
    </Col>
    <Col xs={24}>
      {(workerPermissions.includes('INVESTMENT_FULL') || workerPermissions.includes('FAULTS_FULL')) && (
        <StatsWrapper title="Najem" icon="tenant">
          {workerPermissions.includes('INVESTMENT_FULL') && (
          <>
            <RentPremisesComponent workerPermissions={workerPermissions} isWorker />
            <AgreementsComponent />
          </>
          )}
          {workerPermissions.includes('FAULTS_FULL') && (
            <RentFaultsComponent />
          )}
        </StatsWrapper>
      )}
    </Col>
  </Row>
);

const WorkerDeveloperDashboard = ({ workerPermissions }) => (
  <Row gutter={20}>
    {workerPermissions.includes('RECEIPTS_FULL') && (
      <Col xs={24}>
        <StatsWrapper
          title="Sprzedaż"
          icon="dolar"
          actions={workerPermissions.includes('STATS_FULL') && [
            <Link key="investments/stats" to="investments/stats">
              <Button size="small">
                Statystyki
                <Icon name="stats" />
              </Button>
            </Link>,
          ]}
        >
          <SalePremisesComponent workerPermissions={workerPermissions} isWorker />
        </StatsWrapper>
      </Col>
    )}
    {workerPermissions.includes('FAULTS_FULL') && (
      <Col xs={24} md={12}>
        <StatsWrapper
          title="Usterki"
          icon="fault"
          actions={[
            <Link to="faultGroup/create">
              <Button size="small">
                Dodaj nową
                <Icon name="plus" />
              </Button>
            </Link>,
          ]}
        >
          <SaleFaultsComponent withHeader={false} />
        </StatsWrapper>
      </Col>
    )}
    {workerPermissions.includes('TENANTS_CHANGES_FULL') && (
      <Col xs={24} md={12}>
        <StatsWrapper
          title="Zmiany lokatorskie"
          icon="flat-changes"
          actions={[
            <Link to="tenantChanges/create">
              <Button size="small">
                Dodaj nową
                <Icon name="plus" />
              </Button>
            </Link>,
          ]}
        >
          <TenantChangesComponent withHeader={false} />
        </StatsWrapper>
      </Col>
    )}
  </Row>
);

const WorkerTenantDashboard = ({ workerPermissions }) => (
  <Row gutter={20}>
    {workerPermissions.includes('INVESTMENT_FULL') && (
      <>
        <Col xs={24} md={12}>
          <StatsWrapper title="Najem" icon="tenant">
            <RentPremisesComponent workerPermissions={workerPermissions} isWorker />
          </StatsWrapper>
        </Col>
        <Col xs={24} md={12}>
          <StatsWrapper title="Umowy" icon="file">
            <AgreementsComponent withHeader={false} />
          </StatsWrapper>
        </Col>
      </>
    )}
    {workerPermissions.includes('FAULTS_FULL') && (
      <Col xs={24} md={12}>
        <StatsWrapper
          title="Usterki"
          icon="fault"
          headerLink="faultGroup"
          actions={[
            <Link to="faultGroup/create">
              <Button size="small">
                Dodaj nową
                <Icon name="plus" />
              </Button>
            </Link>,
          ]}
        >
          <RentFaultsComponent withHeader={false} />
        </StatsWrapper>
      </Col>
    )}
  </Row>
);

export default Dashboard;
