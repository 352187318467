import React, { useState, useEffect } from 'react';
import {
  Tooltip, Modal, Row, Col, Button, message,
} from 'antd';
import moment from 'moment';
import { useParams, Link } from 'react-router-dom';
import tranchesService from '../../../../services/tranchesService';
import Icon from '../../../../components/Icon';
import Price from '../../../../components/Price';
import SmallList from '../../../../components/SmallList';
import tranchesStatusMap from '../../../../helpers/tranchesStatusMap';

export const TranchesBox = ({ premise, investment, canEdit }) => {
  const { id, premisesId } = useParams();
  const [showTrancheModal, setShowTrancheModal] = useState(false);
  const [modalData, setModalData] = useState('');

  const [data, setData] = useState([]);
  useEffect(() => {
    const DataService = new tranchesService(id, premisesId);
    DataService.getList().then((response) => {
      setData(response);
    });
  }, [id, premisesId]);

  const refresh = () => {
    const DataService = new tranchesService(id, premisesId);
    DataService.getList().then((response) => {
      setData(response);
    });
  };

  if (!data.length) {
    return '';
  }

  if (investment.type === 'SALE' && premise.status === 'FREE') {
    return '';
  }

  const Dot = ({ status }) => {
    let color = 'yellow';
    if (status === 'PAID') {
      color = 'green';
    }
    if (status === 'TO_PAY') {
      color = 'red';
    }
    return (
      <Tooltip title={tranchesStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  const AmountRenderer = (props) => {
    const { status, paid, amount } = props;
    if (status === 'PAID') {
      return (
        <Price showEmpty value={props.paid} />
      );
    }

    if (status === 'TO_PAY') {
      return (
        <Price showEmpty value={props.amount} />
      );
    }

    return (
      <>
        <Price showEmpty value={paid} />
        <span>/</span>
        <Price showEmpty value={amount} />
      </>
    );
  };

  const PaidButton = ({ record }) => {
    if (record.status === 'PAID' || !canEdit) {
      return '';
    }

    const markAsPaid = (tranche) => {
      const TranchesService = new tranchesService(id, premisesId);

      const values = {
        ...tranche,
        paid: tranche.amount,
        paymentDate: moment().format('YYYY-MM-DD'),
        status: 'PAID',
      };

      TranchesService.update(tranche.id, values).then(() => {
        setModalData(tranche.name);
        setShowTrancheModal(true);
      }).catch((err) => {
        if (err && err.message === 'demo') {
          message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
    };

    return (
      <Tooltip title="Oznacz jako zapłaconą">
        <Icon name="dolar" onClick={() => markAsPaid(record)} style={{ cursor: 'pointer', fontSize: '15px' }} />
      </Tooltip>
    );
  };

  const columns = [
    {
      dataIndex: 'name',
    },
    {
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      dataIndex: 'expirationDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      dataIndex: 'amount',
      align: 'right',
      // eslint-disable-next-line react/jsx-props-no-spreading
      render: (text, record) => <AmountRenderer {...record} />,
    },
    {
      dataIndex: 'action',
      align: 'right',
      // eslint-disable-next-line react/jsx-props-no-spreading
      render: (text, record) => (<PaidButton record={record} />),
    },
  ];

  const onReset = () => {
    refresh();
    setShowTrancheModal(false);
  };

  return (
    <div className="premises-show-box tranches-box">
      <h2>
        <Icon name="payments" />
        Harmonogram płatności
      </h2>
      <div className="content">
        <SmallList data={data} columns={columns} showHeader={false} />
      </div>
      <Modal
        centered
        visible={showTrancheModal}
        onCancel={() => onReset()}
        width={446}
        footer={null}
        closable={false}
      >
        <div className="paid-tranche">
          <div className="paid-tranche-header">{`"${modalData}" została oznaczona jako zapłacona`}</div>
          <div className="paid-tranche-info">W celu zmiany pozostałych informacji, przejdź do edycji lokalu w zakładce „Harmonogram płatności”.</div>
          <div className="paid-tranche-buttons">
            <Row gutter={20}>
              <Col xs={12}>
                <Button className="button-secondary" size="large" onClick={() => onReset()}>
                  Zamknij
                </Button>
              </Col>
              <Col xs={12}>
                <Link to={`/investments/${id}/premises/${premisesId}/edit`}>
                  <Button type="primary" size="large">
                    Przejdź do edycji
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
};
