import BaseService from './baseService';

class PublicFileService extends BaseService {
  constructor() {
    super();
    this.url = '/api/user/files/upload';
  }
}

export default PublicFileService;
