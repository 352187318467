import React, { useState, useEffect } from 'react';
import { Carousel } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useParams } from 'react-router-dom';
import imagesService from '../../../../services/faultsImageService';
import Spin from '../../../../components/Spin';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const ImagesBox = () => {
  const { faultId, faultGroupId, investmentId } = useParams();
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const ImagesService = new imagesService(
      investmentId,
      faultGroupId,
      faultId
    );
    ImagesService.getList().then((data) => {
      setImages(data);
      setLoading(false);
    });
  }, [investmentId, faultGroupId, faultId]);
  return (
    <div
      className='premises-gallery-slider'
      style={{ marginBottom: '40px', overflow: 'hidden' }}
    >
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <Carousel
          autoplay
          dots={false}
          arrows
          nextArrow={<RightOutlined />}
          prevArrow={<LeftOutlined />}
        >
          {images.map((image) => (
            <a
              href={`${baseUrl}${image.filename}`}
              className='premises-gallery-slider-slide-wrapper'
              target='_blank'
              rel='noreferrer'
            >
              <img src={`${baseUrl}${image.filename}`} alt={image.filename} />
            </a>
          ))}
        </Carousel>
      )}
    </div>
  );
};
