import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button, Menu } from 'antd';
import moment from 'moment';
import Icon from '../../../../components/Icon';
import SmallList from '../../../../components/SmallList';
import ownersService from '../../../../services/ownersService';
import { useState } from 'react';
import { useEffect } from 'react';
import OwnerModal from '../modals/OwnerModal';

const columns = [
  {
    title: 'Imię i nazwisko',
    dataIndex: ['userFirstName', 'userLastName'],
    editable: false,
    render: (text, record) => (
      <span>
        {record.userFirstName} {record.userLastName}
      </span>
    ),
  },
  {
    title: 'Data od',
    dataIndex: 'dateFrom',
    editable: false,
  },
  {
    title: 'Data do',
    dataIndex: 'dateTo',
    editable: false,
    render: (text, record) => {
      if (record.withoutExpireDate) {
        return <span style={{ color: '#B1CADE' }}>Do odwołania</span>;
      }

      return text;
    },
  },
];

export const OwnerList = () => {
  const { id, premisesId } = useParams();
  const [ownerModalVisible, setOwnerModalVisible] = useState(false);
  const [oldOwners, setOldOwners] = useState([]);
  const [currOwners, setCurrOwners] = useState([]);
  const [editingOwner, setEditingOwner] = useState();

  useEffect(() => {
    refresh();
  }, [id, premisesId, ownerModalVisible]);

  const refresh = () => {
    const OwnersService = new ownersService(id, premisesId);
    OwnersService.getList().then((data) => {
      setOldOwners(
        data.filter((owner) => {
          if (owner.withoutExpireDate) {
            return false;
          }

          if (
            moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD') >
            moment(new Date(owner.dateTo), 'YYYY-MM-DD').format('YYYY-MM-DD')
          ) {
            return true;
          }
          return false;
        })
      );

      setCurrOwners(
        data.filter((owner) => {
          if (owner.withoutExpireDate) {
            return true;
          }
          if (
            moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD') >
            moment(new Date(owner.dateTo), 'YYYY-MM-DD').format('YYYY-MM-DD')
          ) {
            return false;
          }
          return true;
        })
      );
    });
  };

  const onRemove = (entityId) => {
    const OwnersService = new ownersService(id, premisesId);

    OwnersService.delete(entityId)
      .then((response) => {
        refresh();
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type={'error'}>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        }
      });
  };

  const CustomAddButton = () => {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button
        className='button-secondary'
        onClick={() => {
          setOwnerModalVisible(true);
        }}
        style={{ width: '45px', height: '45px' }}
      >
        <Icon name='plus' />
      </Button>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <OwnerModal
        isModalVisible={ownerModalVisible}
        afterCancel={() => {
          setOwnerModalVisible(false);
          setEditingOwner(null);
        }}
        editingOwner={editingOwner}
      />
      <Row>
        <Col xs={20} offset={2}>
          <div style={{ marginBottom: 50 }}>
            <SmallList
              data={currOwners}
              columns={columns}
              title='Aktualny właściciel'
              deleteAction={onRemove}
              customAddButton={<CustomAddButton />}
              customMenuActions={(record) => (
                <Menu.Item
                  key='96'
                  onClick={() => {
                    setEditingOwner(record);
                    setOwnerModalVisible(true);
                  }}
                  icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
                >
                  Edytuj
                </Menu.Item>
              )}
              hideEdit
            />
          </div>
          <div>
            <SmallList
              tableClassName={'contacts-table white-table'}
              data={oldOwners}
              columns={columns}
              title='Archiwum'
              deleteAction={onRemove}
              customMenuActions={(record) => (
                <Menu.Item
                  key='96'
                  onClick={() => {
                    setEditingOwner(record);
                    setOwnerModalVisible(true);
                  }}
                  icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
                >
                  Edytuj
                </Menu.Item>
              )}
              hideEdit
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
