import React from 'react';
import { Row, Col } from 'antd';
import { AgreementsListComponent, StatsWrapper } from '../dashboard/components/ViewComponents';

const List = () => (
  <div style={{ padding: '16px 24px' }}>
    <div className="dashboard">
      <Row gutter={20}>
        <Col xs={24} sm={24}>
          <StatsWrapper title="Twoje umowy" icon="file">
            <AgreementsListComponent />
          </StatsWrapper>
        </Col>
      </Row>
    </div>
  </div>
);

export default List;
