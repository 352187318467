import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Card } from 'antd';
import Icon from '../../components/Icon';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import investmentHeatSourceMap from '../../helpers/investmentHeatSourceMap';
import Spin from '../../components/Spin';

const Address = ({ title, address }) => (
  <>
    {!!address && (
      <div className='address'>
        <div className='title'>{title}:</div>
        <div className='place'>
          {`${address.street} ${address.number || ''}${
            address.local ? `/${address.local}` : ''
          }`}
        </div>
        <div className='city'>{`${address.zip}, ${address.city}`}</div>
      </div>
    )}
  </>
);

const Show = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams();
  const history = useHistory();
  const [investment, setInvestment] = useState({});
  const [premisesStats, setPremisesStats] = useState({});
  const [userData, setUserData] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [canSeePrices, setCanSeePrices] = useState(false);
  const [canSeeStats, setCanSeeStats] = useState(false);

  useEffect(() => {
    const InvestmentsService = new investmentsService();
    InvestmentsService.getDetails(id).then((data) => {
      setInvestment(data.investment);
      setPremisesStats(data.premisesCount);
      if (Object.keys(data.premisesCount).length > 0) {
        const calculatedTotal =
          Object.values(data.premisesCount).reduce((a, b) => a + b) || 0;
        setTotal(calculatedTotal);
      }
    });

    userService.getInfo().then((response) => {
      setUserData(response);
      if (
        response.roles.includes('ROLE_WORKER') &&
        response.ownerRoles.includes('ROLE_DEVELOPER')
      ) {
        if (
          response.workerPermissions.includes('INVESTMENT_FULL') &&
          response.workerPermissions.includes('RECEIPTS_FULL')
        ) {
          setCanEdit(true);
          setCanSeePrices(true);
        } else if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanSeePrices(false);
          setCanEdit(false);
        } else if (response.workerPermissions.includes('RECEIPTS_FULL')) {
          setCanSeePrices(true);
          setCanEdit(false);
          if (response.workerPermissions.includes('STATS_FULL')) {
            setCanSeeStats(true);
          }
        } else {
          setCanSeePrices(false);
          setCanEdit(false);
        }
      } else if (
        response.roles.includes('ROLE_WORKER') &&
        response.ownerRoles.includes('ROLE_TENANT')
      ) {
        if (response.workerPermissions.includes('INVESTMENT_FULL')) {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
        if (response.workerPermissions.includes('STATS_FULL')) {
          setCanSeeStats(true);
        }
        setCanSeePrices(true);
      } else {
        setCanEdit(true);
        setCanSeePrices(true);
        setCanSeeStats(true);
      }
      setLoading(false);
    });
  }, [id]);

  const canShowMenu = (requiredPermission) => {
    if (userData.roles.includes('ROLE_WORKER')) {
      return userData.workerPermissions.includes(requiredPermission);
    }

    return true;
  };

  return (
    <div className='show-layout investments-show'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => history.push('/investments')}
          backIcon={<Icon name='arrow-simply-left' />}
          title={investment.name}
          className='page-header-extra-2'
          extra={[
            <Button
              key='1'
              className='button-secondary'
              onClick={() => window.print()}
            >
              Drukuj <Icon name='download' />
            </Button>,
            canEdit && (
              <Link key='2' to={`/investments/${investment.id}/edit`}>
                <Button type='primary'>
                  Edytuj <Icon name='edit' />
                </Button>
              </Link>
            ),
          ]}
        >
          <Card>
            <Row>
              <Col sm={24} xl={12}>
                <div className='left investments-show-left'>
                  <div className='logo'>
                    <img
                      src={`${baseUrl}${investment.logoFile}`}
                      alt={investment.name}
                    />
                  </div>
                  <div className='name'>{investment.name}</div>
                  <div className='buttons'>
                    {investment.phone && (
                      <div className='call'>
                        <a href={`tel:${investment.phone}`}>
                          <Button type='primary' size='large'>
                            <Icon name='phone' />
                            Zadzwoń
                          </Button>
                        </a>
                      </div>
                    )}
                    <div className='write'>
                      <a href={`mailto:${investment.email}`}>
                        <Button type='primary' size='large'>
                          <Icon name='email' /> Napisz
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={24} xl={12}>
                <div className='right investments-show-right'>
                  <Card
                    title={
                      <h2 className='show-layout-card-header'>
                        <Icon name='more-info' />
                        Informacje
                      </h2>
                    }
                    className='informations'
                  >
                    <Row style={{ marginBottom: '20px' }}>
                      <Col xs={24} sm={12}>
                        <div className='title'>Nazwa inwestycji:</div>
                        <div className='idNumber'>{investment.name}</div>
                      </Col>
                      <Col sm={12}>
                        <div className='title'>Rok powstania:</div>
                        <div className='idNumber'>{investment.createYear}</div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col sm={12}>
                        <Address
                          title='Adres inwestycji'
                          address={investment.address}
                        />
                      </Col>
                      {investment.administrator && (
                        <Col sm={12}>
                          <div className='title'>Administrator:</div>
                          <div className='email'>{`${investment.administrator.firstName} ${investment.administrator.lastName}`}</div>
                          <div className='email'>
                            {investment.administrator.email}
                          </div>
                          <div className='phone'>
                            {investment.administrator.phone}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col sm={24}>
                        <div className='title'>Ilość lokali:</div>
                        <div>{total}</div>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '20px' }}>
                      <Col sm={12}>
                        <div className='title'>Mieszkania:</div>
                        <div>{premisesStats[investment.type] || 0}</div>
                      </Col>
                      <Col sm={12}>
                        <div className='title'>Lokale usługowe:</div>
                        <div>{premisesStats.SERVICE || 0}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className='title'>Komórki lokatorskie:</div>
                        <div>{premisesStats.STORAGE || 0}</div>
                      </Col>
                      <Col sm={12}>
                        <div className='title'>Garaże:</div>
                        <div>{premisesStats.PARKING || 0}</div>
                      </Col>
                    </Row>
                    {investment.heatSource && (
                      <Row style={{ marginTop: '20px' }}>
                        <Col sm={12}>
                          <div className='title'>Źródło ciepła:</div>
                          <div>
                            {investmentHeatSourceMap(investment.heatSource)}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Card>
                  <div className='investments-show-right-actions'>
                    <Row gutter={20}>
                      {canSeePrices && canSeeStats && (
                        <Col xs={24} sm={8}>
                          <Link
                            className='investments-show-right-actions-action'
                            to={`/investments/stats?id=${investment.id}`}
                          >
                            <Icon name='stats' />
                            Statystyki
                          </Link>
                        </Col>
                      )}
                      <Col xs={24} sm={8}>
                        <Link
                          className='investments-show-right-actions-action'
                          to={`/investments/${investment.id}/premises`}
                        >
                          <Icon name='premises' />
                          Lista lokali
                        </Link>
                      </Col>
                      {investment.type === 'SALE' &&
                        canShowMenu('PAYMENTS_FULL') && (
                          <Col xs={24} sm={8}>
                            <Link
                              className='investments-show-right-actions-action'
                              to={`/investments/${investment.id}/settlements`}
                            >
                              <Icon name='percent' />
                              Rozliczenia pośredników
                            </Link>
                          </Col>
                        )}
                      {investment.type === 'RENT' && (
                        <Col xs={24} sm={8}>
                          <Link
                            className='investments-show-right-actions-action'
                            to={`/investments/${investment.id}/payments`}
                          >
                            <Icon name='payments' />
                            Operacje płatnicze
                          </Link>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </PageHeader>
      )}
    </div>
  );
};

export default Show;
