import React, { useEffect } from 'react';
import { Modal, Form, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import WorkersService from '../../../services/workersService';
import Footer from '../../../helpers/Footer';
import Input from '../../../components/InputComponent';
import MessagePopup from '../../../components/MessagePopup';
import { useState } from 'react';
import housingAssociationsService from '../../../services/housingAssociationsService';

const { Select } = Input;

const WorkersInvestmentModal = (props) => {
  const { afterCancel, isModalVisible, investment } = props;
  const [form] = Form.useForm();
  const { id } = useParams();
  const [availableWorkers, setAvailableWorkers] = useState([]);

  useEffect(() => {
    WorkersService.getList({
      role: 'ROLE_WORKER',
    }).then((data) => {
      if (investment?.workersIds) {
        const workers = data.content.filter(
          (worker) => !investment?.workersIds.includes(worker.id)
        );

        setAvailableWorkers(
          workers.map((worker) => ({
            label: `${worker.firstName} ${worker.lastName}`,
            value: worker.id,
          }))
        );
      } else {
        setAvailableWorkers(
          data.content.map((worker) => ({
            label: `${worker.firstName} ${worker.lastName}`,
            value: worker.id,
          }))
        );
      }
    });
  }, [isModalVisible]);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const tempValues = [...values.workersIds];

        if (investment?.workersIds) {
          tempValues.push(...investment.workersIds);
        }

        const HousingAssociationsService = new housingAssociationsService();

        HousingAssociationsService.updateFields(id, { workersIds: tempValues })
          .then((response) => {
            afterCancel();
          })
          .catch((err) => {
            if (err && err.message === 'demo') {
              message.error(
                <MessagePopup type='error'>
                  Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
                </MessagePopup>
              );
            }
          });

        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  return (
    <>
      <Modal
        title={'Dodaj pracownika'}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={410}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={true} />}
      >
        <Form
          form={form}
          name='filterDeveloper'
          className='filters-developer-form'
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24}>
              <Form.Item
                name='workersIds'
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <Select
                  size='large'
                  placeholder='Pracownik'
                  mode='multiple'
                  options={availableWorkers}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default WorkersInvestmentModal;
