import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Icon, Datagrid } from '../../../../components';
import Spin from '../../../../components/Spin';
import tenantPremisesCountersService from '../../../../services/tenantPremisesCountersService';
import tenantSubCountersHistoryService from '../../../../services/tenantSubCountersHistoryService';
import PermisesService from '../../../../services/premisesService';
import { renderUnit } from '../../../../helpers/unitRender';

import { SubCounterCard } from '../../components/SubCounterCard';

const percentRender = (percent) => {
  const value = parseFloat(percent);

  if (!value) {
    return `+0.00%`;
  } else if (value > 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const Show = () => {
  const history = useHistory();
  const { id, premisesId, counterId, subCounterId } = useParams();
  const [loading, setLoading] = useState(true);
  const [mainCounterData, setMainCounterData] = useState({});
  const [subCounterData, setSubCounterData] = useState({});

  const columns = [
    {
      title: <span>Stan licznika ({renderUnit(mainCounterData?.unit)})</span>,
      dataIndex: 'value',
      visible: true,
      filterEnabled: true,
      key: 'value',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>
          <div
            style={{
              position: 'absolute',
              top: 10,
              color:
                record.subCounterValueChangePercent < 0 ? '#EC434C' : '#15D55E',
              fontSize: 8,
              fontWeight: 700,
            }}
          >
            {percentRender(record.subCounterValueChangePercent)}
          </div>

          <div>
            {record.value ? parseFloat(record.value).toFixed(2) : '0.00'}{' '}
            {renderUnit(record.unit)}
          </div>
        </span>
      ),
    },
    {
      title: (
        <span>
          Różnica od ostatniego odczytu ({renderUnit(mainCounterData?.unit)})
        </span>
      ),
      dataIndex: 'subCounterValue',
      visible: true,
      filterEnabled: true,
      key: 'subCounterValue',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (text ? parseFloat(text).toFixed(2) : '-'),
    },
    {
      title: 'Data odczytu',
      dataIndex: 'readDate',
      visible: true,
      filterEnabled: false,
      key: 'readDate',
      sorter: false,
      render: (text, record) => (text ? text : '-'),
      exportRender: (text, record) => (text ? text : '-'),
    },
  ];

  useEffect(() => {
    const TenantPremisesCountersService = new tenantPremisesCountersService(
      id,
      premisesId
    );
    TenantPremisesCountersService.getOne(counterId).then((data) => {
      setMainCounterData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const TenantPremisesCountersService = new tenantPremisesCountersService(
      id,
      premisesId
    );
    TenantPremisesCountersService.getSubCounter(id, premisesId, counterId, subCounterId).then(
      (data) => {
        setSubCounterData(data);
        setLoading(false);
      }
    );
  }, []);

  const HeaderTitle = () => {
    if (!subCounterData.number) {
      return <span>Podlicznik</span>;
    }
    return (
      <span>
        Podlicznik:{' '}
        <span className='header-subtitle'>{subCounterData.number}</span>
      </span>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <PageHeader
              ghost={false}
              backIcon={<Icon name='arrow-simply-left' />}
              onBack={() => window.history.back()}
              title={<HeaderTitle />}
            >
              <SubCounterCard
                name={mainCounterData.name}
                number={subCounterData.number}
                premiseName={subCounterData.premisesName}
                premiseType={subCounterData.premisesType}
                countersValuesSum={subCounterData.countersValuesSum}
                countersPreviousValuesSumPercent={subCounterData.countersPreviousValuesSumPercent}
                unit={mainCounterData.unit}
              />
            </PageHeader>
          </div>
          <div className='subcounter-history-datagrid'>
            <Datagrid
              title={'Historia odczytów'}
              exportTitle={'Historia odczytów'}
              resource={`investment/${id}/counters/${counterId}/subCounters`}
              dataProvider={
                new tenantSubCountersHistoryService(id, premisesId, counterId)
              }
              columns={columns}
              sort={{
                sortField: 'id',
                sortOrder: 'descend',
              }}
              hideReset={true}
              hideSms={true}
              hideEmail={true}
              hideEdit
              hideShow
              hideCreate
              hideDelete
            />
          </div>
        </>
      )}
    </>
  );
};

export default Show;
