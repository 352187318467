import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message, Upload, Card } from 'antd';
import { useParams } from 'react-router-dom';
import SmallList from '../../../../../components/SmallList';
import Icon from '../../../../../components/Icon';
import MessagePopup from '../../../../../components/MessagePopup';
import subcountersImportsService from '../../../../../services/subcountersImportsService';
import ProgressModal from '../../../../../components/ProgressModal';

export const ImportsList = () => {
  const [imports, setImports] = useState([]);
  const { id, counterId } = useParams();
  const [importProgress, setImportProgress] = useState(0);
  const [progressModalVisible, setProgressModalVisible] = useState(false);

  useEffect(() => {
    subcountersImportsService.getImports(id, counterId).then((data) => {
      setImports(data);
    });
  }, [id]);

  const refresh = () => {
    subcountersImportsService.getImports(id, counterId).then((data) => {
      setImports(data);
    });
  };

  const columns = [
    {
      title: 'Autor',
      dataIndex: 'author',
      editable: false,
    },
    {
      title: 'Ilość rekordów',
      dataIndex: 'records',
      editable: false,
    },
    {
      title: 'Waga',
      dataIndex: 'fileSize',
      editable: false,
      render: (text) => `${text} kB`,
    },
    {
      title: 'Dodano',
      dataIndex: 'createDate',
      editable: false,
    },
  ];

  const addNewAction = (data) => {
    const formData = new FormData();
    formData.append('importFile', data.file);
    formData.append('fileName', data.file.name);

    setProgressModalVisible(true);

    subcountersImportsService
      .import(
        formData,
        {
          onUploadProgress: (progressEvent) => {
            setImportProgress(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        },
        id,
        counterId
      )
      .then((response) => {
        setProgressModalVisible(false);

        if (response.id) {
          message.success(
            <MessagePopup
              type='success'
              annotation={'Twoja baza danych została zaimportowana poprawnie'}
            >
              Import zakończony sukcesem
            </MessagePopup>
          );
          refresh();
        }
      })
      .catch((err) => {
        setProgressModalVisible(false);

        if (err && err.message === 'demo') {
          message.error(
            <MessagePopup type='error'>
              Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
            </MessagePopup>
          );
        } else {
          message.error(
            <MessagePopup
              type={'error'}
              annotation={'Nie możemy zaimportować pliku'}
            >
              Import nie powiódł się
            </MessagePopup>
          );
        }
      });
  };

  const beforeUpload = (file) => {
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isLt15M) {
      message.error(
        <MessagePopup type={'error'}>
          Plik musi być mniejszy niz 15MB
        </MessagePopup>
      );
    }
    return isLt15M;
  };

  const CustomAddButton = () => {
    const uploadProps = {
      name: 'file',
      customRequest: addNewAction,
      showUploadList: false,
      beforeUpload,
    };

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Upload {...uploadProps}>
        <Button
          className='button-secondary'
          onClick={() => {}}
          style={{ width: '45px', height: '45px' }}
        >
          <Icon name='plus' />
        </Button>
      </Upload>
    );
  };

  return (
    <div className='attachments create-form-wrapper'>
      <ProgressModal
        progress={importProgress}
        isModalVisible={progressModalVisible}
      />
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            data={imports}
            columns={columns}
            title='Lista importów'
            customAddButton={<CustomAddButton />}
            hideEdit
            tableClassName={imports.length !== 0 && 'contacts-table'}
          />
          <Card className='import-info-wrapper'>
            <Button className='button-secondary'>
              <a
                href='http://api.crm.brainstd.com/static/imports/licznikiImport.csv'
                target='_blank'
                rel='noreferrer'
                download
              >
                <Icon name='download' style={{ marginRight: 5 }} />
                Pobierz szablon do importu
              </a>
            </Button>

            <div style={{ marginTop: 40 }}>
              <h2>Jak przygotować plik do importu?</h2>

              <span>
                Poniżej znajdziesz szczegółowe informacje jak przygotować plik,
                który pozwoli Ci zaimportować członków do systemu Manage CRM.
              </span>

              <ol>
                <li>
                  Akceptowany format plików: <strong>*.csv.</strong>
                </li>
                <li>
                  Maksymalny rozmiar pliku: <strong>15MB</strong>.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie pusta - dana wartość w
                  systemie pozostanie nieuzupełniona.
                </li>
                <li>
                  Jeśli w pliku wybrana pozycja zostanie uzupełniona niezgodnie
                  z poniższymi wytycznymi - cały wiersz nie zostanie
                  zaimportowany do systemu.
                </li>
              </ol>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
