import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Button,
  PageHeader,
  Row,
  Col,
  Card,
  Divider,
  message,
  Switch,
  Table,
} from 'antd';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import premisesService from '../../services/premisesService';
import clientsService from '../../services/clientsService';
import messagesService from '../../services/messagesService';
import { Icon, Spin, Input } from '../../components';
import MessagePopup from '../../components/MessagePopup';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PublicFileService from '../../services/publicFileService';

import getPremiseType from '../../helpers/premiseTypeMap';

const { DatePicker, Select } = Input;
const { Option } = Select;

const useQuery = () => new URLSearchParams(useLocation().search);

const Create = () => {
  const history = useHistory();
  const query = useQuery();
  const { investmentId } = useParams();
  const [form] = Form.useForm();
  const isTemplate = query.get('isTemplate') === 'true' || null;
  const [loading, setLoading] = useState(true);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [nrOfCharacters, setNrOfCharacters] = useState(0);

  const [premisesList, setPremisesList] = useState([]);
  const [floorsList, setFloorsList] = useState([]);
  const [membersList, setMembersList] = useState([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  useEffect(() => {
    const PremisesService = new premisesService(investmentId);

    PremisesService.getList().then((data) => {
      const tempPremisesList = data?.content?.map((record) => ({
        id: record.id,
        premiseName: [getPremiseType(record.type), record.name]
          .filter((el) => !!el)
          .join(' '),
        floor: record.floor,
        owner: record.premisesOwnerName,
      }));

      setPremisesList(tempPremisesList);

      const tempFloorsList = data?.content?.reduce((acc, curr) => {
        const tempIdx = acc.findIndex((e) => e.floor === curr.floor);

        if (tempIdx > -1) {
          acc[tempIdx].premisesNames.push(
            [getPremiseType(curr.type), curr.name]
              .filter((el) => !!el)
              .join(' ')
          );

          acc[tempIdx].premisesIds.push(curr.id);
        } else {
          acc.push({
            floor: curr.floor,
            premisesNames: [
              [getPremiseType(curr.type), curr.name]
                .filter((el) => !!el)
                .join(' '),
            ],
            premisesIds: [curr.id],
          });
        }

        return acc;
      }, []);

      setFloorsList(tempFloorsList);
    });

    clientsService
      .getList({
        role: 'ROLE_COMMUNITY_MEMBER',
        investmentId: investmentId,
      })
      .then((data) => {
        const tempMembersList = data?.content?.map((record) => ({
          id: record.id,
          fullName: [record.lastName, record.firstName]
            .filter((el) => !!el)
            .join(' '),
          premisesNames: record.premisesOwnerList
            ?.map((premise) => [
              [getPremiseType(premise.type), premise.name]
                .filter((el) => !!el)
                .join(' '),
            ])
            .join(', '),
        }));

        setMembersList(tempMembersList);
      });

    const sendToType = query.get('sendToType');
    if (sendToType) {
      form.setFieldValue('sendToType', sendToType);

      const selectedIds = query.getAll('id');
      setSelectedRowIds(selectedIds);
    }

    setLoading(false);
  }, []);

  const clearSelectedRowIds = useCallback(() => {
    setSelectedRowIds([]);
  }, []);

  const selectChangeHandler = useCallback(
    (selectedRowKeys) => {
      setSelectedRowIds(selectedRowKeys);
    },
    [setSelectedRowIds]
  );

  const onFinish = (values) => {
    const tempValues = {
      sendToType: values.sendToType,
      sendingMethod: values.sendingMethod,
      title: values.title,
      isTemplate: Boolean(values.isTemplate || isTemplate),
      createMessage: true,
    };

    if (isTemplate || values.isTemplate) {
      tempValues.templateName = values.templateName;
    }

    if (isTemplate) {
      tempValues.createMessage = false;
    }

    if (values.sendingMethod === 'NOTIFICATION_AND_EMAIL') {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);

      const text = rawContentState.blocks[0].text.trim();
      if (!text) {
        message.error(
          <MessagePopup type='error'>Wiadomość nie może być pusta</MessagePopup>
        );
        return;
      }

      tempValues.content = draftToHtml(convertToRaw(contentState));
    } else if (values.sendingMethod === 'SMS') {
      if (!values.smsContent) {
        message.error(
          <MessagePopup type='error'>Wiadomość nie może być pusta</MessagePopup>
        );
        return;
      }

      tempValues.content = values.smsContent;
    }

    if (!(isTemplate || values.isTemplate)) {
      if (values.scheduledSendDate) {
        tempValues.scheduledSendDate = moment(values.scheduledSendDate).format(
          'YYYY-MM-DDTHH:mm:ss'
        );
      } else {
        tempValues.scheduledSendDate = moment().format('YYYY-MM-DDTHH:mm:ss');
      }
    }

    if (values.sendToType === 'PREMISES') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jeden lokal
          </MessagePopup>
        );
        return;
      }

      const selectedPremises = premisesList.filter((el) =>
        selectedRowIds.includes(el.id)
      );
      tempValues.sendToIds = selectedPremises.map((premise) => premise.id);
    } else if (values.sendToType === 'FLOOR') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jedno piętro
          </MessagePopup>
        );
        return;
      }

      const selectedFloors = floorsList.filter((el) =>
        selectedRowIds.includes(el.floor)
      );
      tempValues.sendToIds = selectedFloors.reduce(
        (acc, curr) => acc.concat(curr.premisesIds),
        []
      );
    } else if (values.sendToType === 'MEMBERS') {
      if (selectedRowIds.length === 0) {
        message.error(
          <MessagePopup type='error'>
            Musisz wybrać co najmniej jednego członka
          </MessagePopup>
        );
        return;
      }

      const selectedMembers = membersList.filter((el) =>
        selectedRowIds.includes(el.id)
      );
      tempValues.sendToIds = selectedMembers.map((member) => member.id);
    }

    const MessagesService = new messagesService(investmentId);
    MessagesService.create(tempValues)
      .then((response) => {
        history.replace(`/messages?investmentId=${investmentId}`);
        message.success(
          <MessagePopup type='success'>Zapisano pomyślnie</MessagePopup>
        );
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleBack = () => {
    return history.goBack();
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const uploadCallback = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const publicFilesService = new PublicFileService();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return publicFilesService
      .create(formData)
      .then((response) => ({
        data: {
          link: `${baseUrl}${response.file}`,
        },
      }))
      .catch((err) => {
        if (err && err.message === 'demo') {
          message.error(
            'Dodawanie, usuwanie oraz edycja zablokowana w trybie demo'
          );
        }
      });
  };

  const editorConfig = {
    image: { uploadCallback },
  };

  return (
    <div className='form-create'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name='arrow-simply-left' />}
          onBack={() => handleBack()}
          title={isTemplate ? 'Nowy szablon' : 'Nowa wiadomość'}
        >
          <Form
            form={form}
            name='create'
            className='create-form'
            initialValues={{
              addDate: moment(),
              status: 'AWAITING',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <>
              <Card className='form-create-main-card'>
                <div className='create-form-wrapper'>
                  <Row>
                    <Col xs={20} offset={2}>
                      {isTemplate && (
                        <div className='form-section'>
                          <h2>Szablon</h2>
                          <Form.Item name='templateName'>
                            <Input size='large' placeholder='Nazwa' />
                          </Form.Item>
                        </div>
                      )}

                      <div className='form-section'>
                        <Row gutter={20}>
                          <Col xs={24} sm={12}>
                            <h2>Odbiorcy</h2>
                            <Row gutter={20}>
                              <Col xs={24}>
                                <Form.Item
                                  name='sendToType'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Proszę wybrać odbiorców',
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder='Wyślij do'
                                    size='large'
                                    onChange={clearSelectedRowIds}
                                  >
                                    <Option value='ALL'>Wszystkich</Option>
                                    <Option value='PREMISES'>
                                      Wybrane lokale
                                    </Option>
                                    <Option value='FLOOR'>
                                      Wybrane piętra
                                    </Option>
                                    <Option value='MEMBERS'>
                                      Wybrani członkowie
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          {isTemplate ? (
                            <Col xs={24} sm={12}>
                              <h2>Wysyłka</h2>
                              <Row>
                                <Col xs={24}>
                                  <Form.Item
                                    name='sendingMethod'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Wybierz sposób wysyłania',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='Sposób wysyłania'
                                      size='large'
                                    >
                                      <Option value='NOTIFICATION_AND_EMAIL'>
                                        Przez system i e-mail
                                      </Option>
                                      <Option value='SMS'>SMS</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                        </Row>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.sendToType !== current.sendToType
                              }
                            >
                              {({ getFieldValue }) => {
                                const sendToType = getFieldValue('sendToType');
                                if (sendToType === 'PREMISES') {
                                  return (
                                    <div className='messages-select-ids-table-wrapper'>
                                      <Table
                                        dataSource={premisesList}
                                        rowKey={(record) => record.id}
                                        rowSelection={{
                                          selectedRowKeys: selectedRowIds,
                                          onChange: selectChangeHandler,
                                        }}
                                        columns={[
                                          {
                                            title: 'Lokal',
                                            dataIndex: 'premiseName',
                                            visible: true,
                                          },
                                          {
                                            title: 'Piętro',
                                            dataIndex: 'floor',
                                            visible: true,
                                            render: (text) =>
                                              text ? `Piętro ${text}` : '',
                                          },
                                          {
                                            title: 'Właściciel',
                                            dataIndex: 'owner',
                                            visible: true,
                                          },
                                        ]}
                                        pagination={false}
                                      />
                                    </div>
                                  );
                                } else if (sendToType === 'FLOOR') {
                                  return (
                                    <div className='messages-select-ids-table-wrapper'>
                                      <Table
                                        dataSource={floorsList}
                                        rowKey={(record) => record.floor}
                                        rowSelection={{
                                          selectedRowKeys: selectedRowIds,
                                          onChange: selectChangeHandler,
                                        }}
                                        columns={[
                                          {
                                            title: 'Piętro',
                                            dataIndex: 'floor',
                                            visible: true,
                                            render: (text) =>
                                              text ? `Piętro ${text}` : '',
                                          },
                                          {
                                            title: 'Lokale',
                                            dataIndex: 'premisesNames',
                                            visible: true,
                                            render: (text) => text.join(', '),
                                          },
                                        ]}
                                        pagination={false}
                                      />
                                    </div>
                                  );
                                } else if (sendToType === 'MEMBERS') {
                                  return (
                                    <div className='messages-select-ids-table-wrapper'>
                                      <Table
                                        dataSource={membersList}
                                        rowKey={(record) => record.id}
                                        rowSelection={{
                                          selectedRowKeys: selectedRowIds,
                                          onChange: selectChangeHandler,
                                        }}
                                        columns={[
                                          {
                                            title: 'Członkowie',
                                            dataIndex: 'fullName',
                                            visible: true,
                                          },
                                          {
                                            title: 'Lokal',
                                            dataIndex: 'premisesNames',
                                            visible: true,
                                          },
                                        ]}
                                        pagination={false}
                                      />
                                    </div>
                                  );
                                }
                              }}
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      {!isTemplate ? (
                        <div className='form-section'>
                          <h2>Wysyłka</h2>
                          <Row gutter={20}>
                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='sendingMethod'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Wybierz sposób wysyłania',
                                  },
                                ]}
                              >
                                <Select
                                  placeholder='Sposób wysyłania'
                                  size='large'
                                >
                                  <Option value='NOTIFICATION_AND_EMAIL'>
                                    Przez system i e-mail
                                  </Option>
                                  <Option value='SMS'>SMS</Option>
                                </Select>
                              </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                              <Form.Item
                                name='scheduledSendDate'
                                rules={[
                                  {
                                    validator(rule, value) {
                                      return new Promise((resolve, reject) => {
                                        if (!value) {
                                          resolve();
                                        }

                                        if (
                                          value?.isSameOrBefore(
                                            moment().subtract({ minutes: 15 })
                                          )
                                        ) {
                                          reject(
                                            'Czas wysłania musi być późniejszy niż aktualny'
                                          );
                                        } else {
                                          resolve();
                                        }
                                      });
                                    },
                                  },
                                ]}
                              >
                                <DatePicker
                                  size='large'
                                  placeholder='Data i godzina'
                                  style={{ width: '100%' }}
                                  format={'DD-MM-YYYY HH:mm:ss'}
                                  showTime={{ use12Hours: false }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      <div className='form-section'>
                        <h2>Wiadomość</h2>
                        <Row gutter={20}>
                          <Col xs={24}>
                            <Form.Item
                              name='title'
                              rules={[
                                {
                                  required: true,
                                  message: 'Wybierz temat wiadomości',
                                },
                              ]}
                            >
                              <Input size='large' placeholder='Temat' />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={20}>
                          <Col xs={24}>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, current) =>
                                prev.sendingMethod !== current.sendingMethod
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue('sendingMethod') === 'SMS' ? (
                                  <>
                                    <Form.Item name='smsContent'>
                                      <Input.TextArea
                                        maxLength={160}
                                        className='mass-actions-sms-textarea'
                                        placeholder='Treść wiadomości'
                                        onChange={(e) => {
                                          setNrOfCharacters(
                                            e.target.value.length
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                    <div className='mass-actions-sms-signs-counter'>
                                      * Pozostało znaków: {nrOfCharacters}/160.
                                    </div>
                                    <div
                                      className='mass-actions-sms-annotation'
                                      style={{ marginBottom: 0 }}
                                    >
                                      ** Wiadomość SMS zostanie wysłana tylko do
                                      użytkowników, którzy mają podany numer
                                      telefonu oraz wyrazili zgodę na
                                      otrzymywanie powiadomień.
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Editor
                                      localization={{ locale: 'pl' }}
                                      editorState={editorState}
                                      wrapperClassName='demo-wrapper'
                                      editorClassName='demo-editor'
                                      toolbar={editorConfig}
                                      onEditorStateChange={onEditorStateChange}
                                    />
                                    <div
                                      className='mass-actions-sms-annotation'
                                      style={{ marginTop: 20, marginBottom: 0 }}
                                    >
                                      * Wiadomość e-mail zostanie wysłana tylko
                                      do użytkowników, którzy mają podany adres
                                      e-mail oraz wyrazili zgodę na otrzymywanie
                                      powiadomień.
                                    </div>
                                  </>
                                )
                              }
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      {!isTemplate && (
                        <div className='form-section'>
                          <h2 style={{ marginTop: 50 }}>Szablon</h2>
                          <Row gutter={20}>
                            <Col xs={24}>
                              <Form.Item
                                name='isTemplate'
                                label='Zapisz jako szablon'
                                valuePropName='checked'
                              >
                                <Switch size='large' />
                              </Form.Item>
                            </Col>
                            <Col xs={24}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prev, current) =>
                                  prev.isTemplate !== current.isTemplate
                                }
                              >
                                {({ getFieldValue }) =>
                                  getFieldValue('isTemplate') && (
                                    <Form.Item name='templateName'>
                                      <Input size='large' placeholder='Nazwa' />
                                    </Form.Item>
                                  )
                                }
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Card>
              <Divider />
              <Row className='form-actions' justify='space-between'>
                <Col sm={4} className='form-actions-cancel-wrapper'>
                  <Button
                    className='button-secondary'
                    size='large'
                    onClick={handleBack}
                  >
                    Anuluj
                  </Button>
                </Col>
                <Col sm={4} className='form-actions-save-wrapper'>
                  <Form.Item>
                    <Button
                      type='primary'
                      size='large'
                      htmlType='submit'
                      className='create-form-button'
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, current) =>
                          prev.scheduledSendDate !== current.scheduledSendDate
                        }
                      >
                        {({ getFieldValue }) =>
                          isTemplate || getFieldValue('scheduledSendDate') ? (
                            'Zapisz'
                          ) : (
                            <>
                              <Icon name='email' style={{ marginRight: 5 }} />
                              Wyślij teraz
                            </>
                          )
                        }
                      </Form.Item>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form>
        </PageHeader>
      )}
    </div>
  );
};
export default Create;
