import React, { useEffect, useState, useRef } from 'react';
import { Col, Form, Tooltip, Tag, Button, PageHeader } from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import faultGroupService from '../../services/faultGroupService';
import investmentsService from '../../services/investmentsService';
import premisesService from '../../services/premisesService';
import memberInvestmentPremisesService from '../../services/memberInvestmentPremisesService';
import userService from '../../services/userService';
import faultGroupTagsService from '../../services/faultGroupTagsService';
import TagsService from '../../services/tagsService';
import faultGroupStatusMap from '../../helpers/faultGroupStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Spin from '../../components/Spin';
import ListExpanded from './ListExpanded';
import HousingAssociationsService from '../../services/housingAssociationsService';
import MemberAssociationsService from '../../services/memberAssociationsService';
import { SendReceivedSwitch } from './components/SendReceivedSwitch';

const { Select, DatePicker, PriceInput, InputNumberV2 } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'TO_FIX') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  if (status === 'REPORTED') {
    color = 'grey';
  }

  if (status === 'VERIFICATION') {
    color = 'yellow';
  }

  if (status === 'IN_PROGRESS') {
    color = 'yellow';
  }

  return (
    <Tooltip title={faultGroupStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = (props) => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [showReceived, setShowReceived] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [serviceType, setServiceType] = useState();
  const [investment, setInvestment] = useState({});
  const [premises, setPremises] = useState([]);
  const [filteredPremises, setFilteredPremises] = useState([]);
  const [userData, setUserData] = useState({});
  const [datagridFilters, setDatagridFilters] = useState({});
  const [investmentId, setInvestmentId] = useState();
  const [disablePremisesId, setDisablePremisesId] = useState(false);
  const datagridRef = useRef();
  const history = useHistory();
  const {
    customDataService,
    isCustomRendered = false,
    redirectState = { redirectFrom: 'FaultGroupList' },
  } = props;

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);

      const { roles, ownerRoles } = data;

      const urlInvestmentId = query.get('investmentId') || null;

      const choosenInvestmentId =
        urlInvestmentId ??
        JSON.parse(localStorage.getItem('choosenInvestmentId'));

      if (
        roles?.includes('ROLE_COMMUNITY_ADMIN') ||
        (roles?.includes('ROLE_WORKER') &&
          ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ) {
        const InvestmentsService = new HousingAssociationsService();
        InvestmentsService.getOne(choosenInvestmentId).then((data) => {
          setInvestment(data);
        });

        setServiceType('/community/faultGroups');

        setInvestmentId(choosenInvestmentId);

        const PremisesService = new premisesService(choosenInvestmentId);

        PremisesService.getList().then((data) => {
          setPremises(data.content);
          setFilteredPremises(data.content);
        });
      }

      if (roles?.includes('ROLE_COMMUNITY_MEMBER')) {
        const InvestmentsService = new MemberAssociationsService();
        InvestmentsService.getOne(choosenInvestmentId).then((data) => {
          setInvestment(data);
        });

        const MemberInvestmentPremisesService =
          new memberInvestmentPremisesService(choosenInvestmentId);

        MemberInvestmentPremisesService.getList().then((data) => {
          setPremises(data.content);
          setFilteredPremises(data.content);
        });
      }

      if (
        (roles?.includes('ROLE_COMMUNITY_ADMIN') ||
          (roles?.includes('ROLE_WORKER') &&
            ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
        query.get('type') === 'SEND'
      ) {
        setShowSend(true);
        setShowReceived(false);
      }
      if (
        (roles?.includes('ROLE_COMMUNITY_ADMIN') ||
          (roles?.includes('ROLE_WORKER') &&
            ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) &&
        query.get('type') === 'RECEIVED'
      ) {
        setShowSend(false);
        setShowReceived(true);
      }

      setLoading(false);
    });
  }, []);

  const workerFieldMapper = (worker) => {
    if (worker.nameDeveloper) {
      return worker.nameDeveloper;
    }

    return `${worker.firstName || ''} ${worker.lastName || ''}`;
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    ...(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
    (userData?.roles?.includes('ROLE_WORKER') &&
      userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ? [
          {
            title: 'Osoba odpowiedzialna',
            dataIndex: 'responsibleWorkers',
            visible: true,
            key: 'responsibleWorkers',
            sorter: false,
            render: (text, record) => {
              if (!record.responsibleWorkers) {
                return '';
              }
              const workersMap = record.responsibleWorkers.map((worker) =>
                workerFieldMapper(record.responsibleWorkersNames[worker])
              );
              return workersMap.join(', ');
            },
            exportRender: (text, record) => {
              if (!record.responsibleWorkers) {
                return '';
              }
              const workersMap = record.responsibleWorkers.map((worker) =>
                workerFieldMapper(record.responsibleWorkersNames[worker])
              );
              return workersMap.join(', ');
            },
          },
        ]
      : []),
    // {
    //   title: 'Typ',
    //   dataIndex: 'premisesType',
    //   visible: true,
    //   key: 'premisesType',
    //   sorter: false,
    //   render: (text, record) => premiseTypeMap(record.premisesType),
    //   exportRender: (text, record) => premiseTypeMap(record.premisesType),
    // },
    // {
    //   title: 'Numer',
    //   dataIndex: 'premisesName',
    //   visible: true,
    //   key: 'premisesName',
    //   sorter: false,
    //   render: (text, record) => <PremiseRenderer record={record} />,
    //   exportRender: (text, record) => <ExportPremiseRenderer record={record} />,
    // },
    {
      title: 'Lokal',
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: false,
      render: (text, record) => PremiseRenderer(record),
      exportRender: (text, record) => ExportPremiseRenderer(record),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <Dot status={record.status} />,
      exportRender: (text, record) => faultGroupStatusMap(record.status),
    },
    {
      title: 'Data dodania',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    {
      title: 'Usunięto',
      dataIndex: 'fixDate',
      visible: true,
      key: 'fixDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => <span style={{ whiteSpace: 'nowrap' }}>{text}</span>,
    },
    ...(userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
    (userData?.roles?.includes('ROLE_WORKER') &&
      userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
      ? [
          {
            title: 'Koszt (brutto)',
            dataIndex: 'amount',
            visible: true,
            key: 'amount',
            sorter: false,
            render: (text, record) => <Price value={record.amount} showEmpty />,
          },
        ]
      : []),
  ];

  const { roles, ownerRoles } = userData;

  const PremiseRenderer = (record) => {
    if (record.premisesId === null) {
      return 'Część wspólna';
    }
    const { workerPermissions } = userData;
    if (
      roles.includes('ROLE_WORKER') &&
      (!workerPermissions || !workerPermissions.includes('INVESTMENT_FULL'))
    ) {
      return record.premisesName;
    }
    let url = `housingAssociations/${record.investmentId}/premises/${record.premisesId}/show`;

    if (roles.includes('ROLE_COMMUNITY_MEMBER')) {
      url = `memberAssociations/${record.investmentId}/premises/${record.premisesId}/show`;
    }

    return (
      <Link className='datagrid-link' to={url}>
        {premiseTypeMap(record.premisesType)} {record.premisesName}
      </Link>
    );
  };

  const ExportPremiseRenderer = (record) => {
    if (record.premisesId === null) {
      return 'Część wspólna';
    }

    return [premiseTypeMap(record.premisesType), record.premisesName]
      .filter((it) => !!it)
      .join(' ');
  };

  const premisesOptions = filteredPremises.map((premise) => (
    <Option value={premise.id} key={premise.id}>
      {premise.name}
    </Option>
  ));

  const customFilters = [];

  customFilters.push(
    <Col xs={24} sm={12} key='status'>
      <Form.Item name='status'>
        <Select placeholder='Status' allowClear size='large'>
          <Option value='REPORTED'>
            <span className='dot grey' />
            Zgłoszona
          </Option>
          <Option value='DONE'>
            <span className='dot green' />
            Zakończona
          </Option>
          <Option value='IN_PROGRESS'>
            <span className='dot yellow' />W toku
          </Option>
        </Select>
      </Form.Item>
    </Col>
  );

  customFilters.push(
    <Col xs={24} sm={12} key='type'>
      <Form.Item name='type'>
        <Select
          placeholder='Typ'
          allowClear
          size='large'
          onChange={(it) => {
            if (!it) {
              setFilteredPremises(premises);
              setDisablePremisesId(false);
            } else {
              setFilteredPremises(premises.filter((el) => el.type === it));

              if (it === 'ADMINISTRATION') {
                setDisablePremisesId(true);
              } else {
                setDisablePremisesId(false);
              }
            }
          }}
        >
          <Option value={'ADMINISTRATION'} key={'ADMINISTRATION'}>
            Administracja
          </Option>
          <Option value={'RENT'} key={'RENT'}>
            Mieszkanie
          </Option>
          <Option value={'STORAGE'} key={'STORAGE'}>
            Komórka lokatorska
          </Option>
          <Option value={'PARKING'} key={'PARKING'}>
            Miejsce postojowe
          </Option>
          <Option value={'SERVICE'} key={'SERVICE'}>
            Lokal usługowy
          </Option>
        </Select>
      </Form.Item>
    </Col>
  );

  customFilters.push(
    <Col xs={24} sm={12} key='premisesId'>
      <Form.Item
        name='premisesId'
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              return new Promise((resolve, reject) => {
                const inputedPremisesTypes = getFieldValue('type');

                console.log(inputedPremisesTypes);

                if (
                  !inputedPremisesTypes ||
                  inputedPremisesTypes === 'ADMINISTRATION' ||
                  (inputedPremisesTypes && value)
                ) {
                  resolve();
                } else {
                  reject('Jeśli wybrałeś typ lokalu, wybierz lokal');
                }
              });
            },
          }),
        ]}
      >
        <Select
          placeholder='Lokal'
          allowClear
          size='large'
          disabled={disablePremisesId}
        >
          {premisesOptions}
        </Select>
      </Form.Item>
    </Col>
  );

  if (
    !loading &&
    !roles.includes('ROLE_CLIENT_TENANT') &&
    !roles.includes('ROLE_CLIENT')
  ) {
    customFilters.push(
      <Col xs={24} sm={12} key='addDateFrom'>
        <Form.Item name='addDateFrom'>
          <DatePicker
            size='large'
            placeholder='Data dodania od'
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );

    customFilters.push(
      <Col xs={24} sm={12} key='addDateTo'>
        <Form.Item name='addDateTo'>
          <DatePicker
            size='large'
            placeholder='Data dodania do'
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );

    customFilters.push(
      <Col xs={24} sm={12} key='fixDateFrom'>
        <Form.Item name='fixDateFrom'>
          <DatePicker
            size='large'
            placeholder='Data usunięcia od'
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );

    customFilters.push(
      <Col xs={24} sm={12} key='fixDateTo'>
        <Form.Item name='fixDateTo'>
          <DatePicker
            size='large'
            placeholder='Data usunięcia do'
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );

    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      customFilters.push(
        <Col xs={24} sm={12} key='amountFrom'>
          <Form.Item name='amountFrom'>
            <InputNumberV2
              size='large'
              placeholder='Kwota od'
              addonAfter='zł brutto'
            />
          </Form.Item>
        </Col>
      );
    }

    if (
      userData?.roles?.includes('ROLE_COMMUNITY_ADMIN') ||
      (userData?.roles?.includes('ROLE_WORKER') &&
        userData?.ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))
    ) {
      customFilters.push(
        <Col xs={24} sm={12} key='amountTo'>
          <Form.Item name='amountTo'>
            <InputNumberV2
              size='large'
              placeholder='Kwota do'
              addonAfter='zł brutto'
            />
          </Form.Item>
        </Col>
      );
    }
  }

  // let filters = {};
  // if (query.get('status')) {
  //   filters = {
  //     status: query.get('status'),
  //   };
  // }

  const customBaseUrl = (record) =>
    `housingFaultGroup/${record.investmentId}/faultGroup/${record.id}`;

  const TagFilters = () => {
    const [tagsIds, setTagsIds] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const FaultGroupTagsService = new faultGroupTagsService();
      FaultGroupTagsService.getAllFaultGroups().then((data) => {
        setTagsIds(data);
      });

      if (!loading && query.has('tag') && datagridRef) {
        const filterTag = query.get('tag');

        query.delete('tag');
        history.replace({
          search: query.toString(),
        });
        const filter = {
          tags: filterTag,
        };
        datagridRef.current.handleFilterChange(filter, true);
      }
    }, []);

    useEffect(() => {
      if (tagsIds.length > 0) {
        const promises = [];
        const tempTags = [];
        for (let tagId of tagsIds) {
          promises.push(
            TagsService.getOne(tagId).then((data) => {
              tempTags.push(data);
            })
          );
        }

        Promise.all(promises).then(() => {
          setTags(tempTags);
        });
      }
    }, [tagsIds]);

    const onTagClick = (tagId) => {
      const filter = {
        tags: tagId,
      };
      datagridRef.current.handleFilterChange(filter, true);
    };

    const getIsActiveTag = (tagId) => {
      if (datagridFilters?.tags === tagId) {
        return 'tag-active';
      }

      return '';
    };

    const renderTags = tags.map((tag) => (
      <div className='tags-filter'>
        <Tag
          className={getIsActiveTag(tag.id)}
          onClick={() => onTagClick(tag.id)}
        >{`#${tag.name}`}</Tag>
      </div>
    ));

    return renderTags;
  };

  // const afterFilterChange = () => {
  //   setDatagridFilters(datagridRef.current.state.filters);
  // };

  const CustomCreate = ({ disabled }) => {
    return (
      <div style={{ marginBottom: 20 }}>
        <Button
          type='primary'
          onClick={() => {
            if (!disabled) {
              return history.push(`/housingFaultGroup/create`);
            }
          }}
          style={
            disabled
              ? {
                  backgroundColor: '#ececf7',
                  borderColor: '#ececf7',
                  cursor: 'not-allowed',
                  color: 'rgba(83, 83, 244, 0.3)',
                  fontWeight: 700,
                }
              : {}
          }
        >
          Usterka
          <Icon name='plus' style={{ marginLeft: 10 }} />
        </Button>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (query.get('investmentType')) {
      return (
        <span>
          Usterki:{' '}
          <span className='header-subtitle'>
            {query.get('investmentType') === 'HOUSING_ASSOCIATION'
              ? 'SPÓŁDZIELNIE'
              : 'WSPÓLNOTY'}
          </span>
        </span>
      );
    }

    if (!investment.name) {
      return <span>Usterki</span>;
    }

    return (
      <span>
        Usterki: <span className='header-subtitle'>{investment.name}</span>
      </span>
    );
  };

  const canEdit = !roles?.includes('ROLE_CLIENT_TENANT') || false;
  // const dataProvider = customDataService || new faultGroupService();
  const dataProvider =
    customDataService || new faultGroupService(null, serviceType);

  return (
    <div className='faults-list'>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          {/* {!isCustomRendered && (
            <>
              <h1>Usterki</h1>
              <div className='tags-filter'>
                {!roles.includes('ROLE_CLIENT_TENANT') &&
                  !roles.includes('ROLE_CLIENT') && <TagFilters />}
              </div>
            </>
          )} */}
          <Datagrid
            title={
              query.get('investmentId') ||
              query.get('investmentType') ||
              query.get('type') ||
              query.get('userId') ? (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  backIcon={<Icon name='arrow-simply-left' />}
                  onBack={() => window.history.back()}
                  title={<HeaderTitle />}
                />
              ) : (
                <PageHeader
                  style={{ padding: 0 }}
                  ghost={false}
                  title={<HeaderTitle />}
                />
              )
            }
            exportTitle={'Usterki'}
            resource='housingFaultGroup'
            dataProvider={dataProvider}
            columns={columns}
            customFilters={customFilters}
            customShowAllMembers={
              (roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (roles?.includes('ROLE_WORKER') &&
                  ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) && (
                <SendReceivedSwitch
                  showSend={showSend}
                  setShowSend={setShowSend}
                  showReceived={showReceived}
                  setShowReceived={setShowReceived}
                />
              )
            }
            filters={{
              ...(!query.get('investmentType') &&
                investmentId && { investmentId: investmentId }),
              ...(query.get('investmentType') && {
                investmentType: query.get('investmentType'),
              }),
              ...(showSend && {
                isDeveloperNotified: true,
              }),
              ...(showReceived && {
                isAdminNotified: true,
              }),
              ...(query.get('status') && {
                status: query.get('status'),
              }),
            }}
            customCreate={
              <CustomCreate disabled={investment.isFaultsDisabled} />
            }
            customTags={
              (roles?.includes('ROLE_COMMUNITY_ADMIN') ||
                (roles?.includes('ROLE_WORKER') &&
                  ownerRoles?.includes('ROLE_COMMUNITY_ADMIN'))) && (
                <TagFilters />
              )
            }
            expandedRowRenderer={(record) => (
              <ListExpanded
                record={record}
                userData={userData}
                redirectState={redirectState}
              />
            )}
            calculateBaseUrl={customBaseUrl}
            ref={datagridRef}
            // afterFilterChange={afterFilterChange}
            // canEdit={canEdit}
            hideCreate={!canEdit}
            // hideEdit={!canEdit}
            customRowActions={[
              (record) => {
                if (record.status !== 'DONE') {
                  return (
                    <Link
                      to={`housingFaultGroup/${record.investmentId}/faultGroup/${record.id}/edit`}
                    >
                      <Button className='button-no-border'>
                        <Icon name='edit' />
                      </Button>
                    </Link>
                  );
                }
              },
            ]}
            hideEdit
            hideSms
            hideEmail
            hideReset
          />
        </div>
      )}
    </div>
  );
};

export default List;
