import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ShouldDisableSite = () => (
  <div className="account-expired-wrapper">
    <div className="account-expired-popup">
      <div>Twoje konto jest nieaktywne</div>
      <div style={{ marginTop: '20px' }}>
        <Link to="/login">
          <Button type="danger">
            Wyloguj
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default ShouldDisableSite;
