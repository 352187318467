import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Dropdown, Popover, Modal, Row, Col } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Notifications from './Notifications';
import Messages from './Messages';
import Package from './Package';
import userService from '../../services/userService';
import notificationsService from '../../services/notificationsService';
import memberMessagesService from '../../services/memberMessagesService';
import authService from '../../services/authService';
import Icon from '../../components/Icon';
import notificationFull from '../../static/images/notification-full.png';
import messagesFull from '../../static/images/email-message.png';
import CommunityAdminPackage from './CommunityAdminPackage';
import InvestmentMenu from './InvestmentMenu';
import ChangeAccountModal from './ChangeAccountModal';
import { isIOS, isMobile } from 'react-device-detect';

const { Header } = Layout;

const LoggedHeader = ({
  shouldShowMenu,
  userData,
  setUserData,
  collapsed,
  setCollapsed,
}) => {
  const history = useHistory();
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsIcon, setNotificationsIcon] =
    useState('notification-empty');
  const location = useLocation();
  const [changeAccVisible, setChangeAccVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesVisible, setMessagesVisible] = useState(false);
  const [messagesIcon, setMessagesIcon] = useState('messages-full');

  useEffect(() => {
    notificationsService
      .getList({
        sortField: 'createDate',
        sortOrder: 'descend',
        pagination: {
          pageSize: 30,
        },
      })
      .then((notificationsData) => {
        setNotifications(notificationsData.content);

        const notReaded = notificationsData.content.filter(
          (notificationData) => !notificationData.isRead
        );
        if (notReaded.length > 0) {
          setNotificationsIcon('notification-full');
        }
      })
      .catch((err) => {});

    const MemberMessagesService = new memberMessagesService();

    MemberMessagesService.getList({
      sortField: 'createDate',
      sortOrder: 'descend',
      pagination: {
        pageSize: 10,
      },
    })
      .then((messagesData) => {
        setMessages(messagesData.content);

        const notReaded = messagesData.content.filter(
          (messageData) => !messageData.isRead
        );
        if (notReaded.length > 0) {
          setMessagesIcon('messages-full');
        } else {
          setMessagesIcon('email');
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      notificationsService
        .getList({
          sortField: 'createDate',
          sortOrder: 'descend',
          pagination: {
            pageSize: 30,
          },
        })
        .then((notificationsData) => {
          setNotifications(notificationsData.content);

          const notReaded = notificationsData.content.filter(
            (notificationData) => !notificationData.isRead
          );
          if (notReaded.length > 0) {
            setNotificationsIcon('notification-full');
          }
        });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const logout = () => {
    authService.logout().then(() => {
      history.push('/login');
    });
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const refreshNotifications = () => {
    notificationsService
      .getList({
        sortField: 'createDate',
        sortOrder: 'descend',
        pagination: {
          pageSize: 10,
        },
      })
      .then((notificationsData) => {
        setNotifications(notificationsData.content);

        const notReaded = notificationsData.content.filter(
          (notificationData) => !notificationData.isRead
        );
        if (notReaded.length > 0) {
          setNotificationsIcon('notification-full');
        } else {
          setNotificationsIcon('notification-empty');
        }
      });
  };

  const refreshMessages = () => {
    const MemberMessagesService = new memberMessagesService();

    MemberMessagesService.getList({
      sortField: 'createDate',
      sortOrder: 'descend',
      pagination: {
        pageSize: 10,
      },
    }).then((messagesData) => {
      setMessages(messagesData.content);

      const notReaded = messagesData.content.filter(
        (messageData) => !messageData.isRead
      );
      if (notReaded.length > 0) {
        setMessagesIcon('messages-full');
      } else {
        setMessagesIcon('email');
      }
    });
  };

  const refreshUserData = () => {
    userService.getInfo().then((data) => {
      setUserData(data);
      localStorage.setItem('roles', JSON.stringify(data.roles));
    });
  };

  const menu = (roles = [], ownerRoles = []) => (
    <Menu style={{ paddingTop: 8, paddingBottom: 8, width: 140 }}>
      {!roles.includes('ROLE_ADMIN') && (
        <Menu.Item
          key='1'
          style={{
            color: '#587999',
            fontWeight: 600,
            paddingLeft: 20,
          }}
        >
          <Link to='/myAccount' className='premises-custom-create-link'>
            Moje konto
          </Link>
        </Menu.Item>
      )}
      {roles.includes('ROLE_ADMIN') && (
        <Menu.Item
          key='2'
          style={{
            color: '#587999',
            fontWeight: 600,
            paddingLeft: 20,
          }}
        >
          <Link to='/myAccount/edit' className='premises-custom-create-link'>
            Moje konto
          </Link>
        </Menu.Item>
      )}
      {roles.includes('ROLE_COMMUNITY_ADMIN') && (
        <Menu.Item
          key='3'
          style={{
            color: '#587999',
            fontWeight: 600,
            paddingLeft: 20,
          }}
        >
          {location.pathname.includes('/myAccount/edit') ? (
            <a
              href='/myAccount/edit?rodo'
              className='premises-custom-create-link'
            >
              Zgody RODO
            </a>
          ) : (
            <Link
              to='/myAccount/edit?rodo'
              className='premises-custom-create-link'
            >
              Zgody RODO
            </Link>
          )}
        </Menu.Item>
      )}
      {(roles.includes('ROLE_COMMUNITY_MEMBER') ||
        roles.includes('ROLE_COMMUNITY_MEMBER_TENANT') ||
        (roles.includes('ROLE_WORKER') &&
          ownerRoles.includes('ROLE_COMMUNITY_ADMIN'))) && (
        <Menu.Item
          key='4'
          style={{
            color: '#587999',
            fontWeight: 600,
            paddingLeft: 20,
          }}
        >
          <Link to='/rodo' className='premises-custom-create-link'>
            Zgody RODO
          </Link>
        </Menu.Item>
      )}

      <Menu.Item
        key='5'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
      >
        <Link to='/changeView' className='premises-custom-create-link'>
          Dostosuj wygląd
        </Link>
      </Menu.Item>
      {roles.includes('ROLE_COMMUNITY_ADMIN') && !(isIOS && isMobile) && (
        <Menu.Item
          key='6'
          style={{
            color: '#587999',
            fontWeight: 600,
            paddingLeft: 20,
          }}
        >
          {location.pathname.includes('/myAccount/edit') ? (
            <a
              href='/myAccount/edit?package'
              className='premises-custom-create-link'
            >
              Subskrypcja
            </a>
          ) : (
            <Link
              to='/myAccount/edit?package'
              className='premises-custom-create-link'
            >
              Subskrypcja
            </Link>
          )}
        </Menu.Item>
      )}
      <Menu.Item
        key='7'
        style={{
          color: '#587999',
          fontWeight: 600,
          paddingLeft: 20,
        }}
        onClick={() => {
          setChangeAccVisible(true);
        }}
      >
        Przełącz konto
      </Menu.Item>
      <Menu.Item
        key='8'
        style={{
          color: '#EC434C',
          fontWeight: 600,
          paddingLeft: 20,
        }}
        onClick={() => logout()}
      >
        Wyloguj
      </Menu.Item>
    </Menu>
  );

  const handleNotificationsVisibleChange = (visible) => {
    setNotificationsVisible(visible);
  };

  const hideNotifications = () => {
    setNotificationsVisible(false);
  };

  const handleMessagesVisibleChange = (visible) => {
    setMessagesVisible(visible);
  };

  const hideMessages = () => {
    setMessagesVisible(false);
  };

  return (
    <>
      <ChangeAccountModal
        changeAccVisible={changeAccVisible}
        setChangeAccVisible={setChangeAccVisible}
      />

      <Header className='site-layout-background site-layout-header'>
        <Icon
          name='burger-menu'
          className='trigger custom-menu-trigger-action'
          onClickAction={toggle}
        />
        <div className='site-layout-header-actions'>
          {shouldShowMenu(['ROLE_DEVELOPER', 'ROLE_TENANT']) && (
            <Package userData={userData} refreshUserData={refreshUserData} />
          )}
          {shouldShowMenu(['ROLE_COMMUNITY_ADMIN']) && (
            <>
              <InvestmentMenu userData={userData} />
              <CommunityAdminPackage
                userData={userData}
                refreshUserData={refreshUserData}
              />
            </>
          )}
          {shouldShowMenu(['ROLE_COMMUNITY_MEMBER']) && (
            <InvestmentMenu userData={userData} />
          )}
          {shouldShowMenu(['ROLE_COMMUNITY_MEMBER_TENANT']) && (
            <InvestmentMenu userData={userData} />
          )}
          {shouldShowMenu(['ROLE_WORKER'], null, 'ROLE_COMMUNITY_ADMIN') && (
            <InvestmentMenu userData={userData} />
          )}
          {shouldShowMenu(['ROLE_COMMUNITY_MEMBER']) && (
            <Popover
              placement='bottom'
              overlayClassName='notifications-popover'
              content={
                <Messages
                  data={messages}
                  refresh={refreshMessages}
                  hideMessages={hideMessages}
                  userData={userData}
                />
              }
              trigger='click'
              visible={messagesVisible}
              onVisibleChange={handleMessagesVisibleChange}
            >
              <Button className='button-no-border header-notifications-button'>
                {messagesIcon === 'email' ? (
                  <Icon name={messagesIcon} />
                ) : (
                  <img
                    src={messagesFull}
                    alt='notifications'
                    className='notifications-active-icon'
                  />
                )}
              </Button>
            </Popover>
          )}
          <Popover
            placement='bottom'
            overlayClassName='notifications-popover'
            content={
              <Notifications
                data={notifications}
                refresh={refreshNotifications}
                hideNotifications={hideNotifications}
                userData={userData}
              />
            }
            trigger='click'
            visible={notificationsVisible}
            onVisibleChange={handleNotificationsVisibleChange}
          >
            <Button className='button-no-border header-notifications-button'>
              {notificationsIcon === 'notification-empty' ? (
                <Icon name={notificationsIcon} />
              ) : (
                <img
                  src={notificationFull}
                  alt='notifications'
                  className='notifications-active-icon'
                />
              )}
            </Button>
          </Popover>
          <Dropdown
            overlay={menu(userData.roles, userData.ownerRoles)}
            overlayClassName='header-menu-wrapper'
            trigger='click'
            placement='bottomLeft'
          >
            <Button className='button-no-border header-options-button'>
              <Icon name='user' />
            </Button>
          </Dropdown>
        </div>
      </Header>
    </>
  );
};

export default LoggedHeader;
