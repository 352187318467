export function generateRandomPassword(length) {
  let password;

  const smallLetters = 'abcdefghijklmnopqrstuvwxyz';
  const capitalLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';
  const chars = smallLetters + capitalLetters + digits;

  while (true) {
    let smallLetter = false;
    let capitalLetter = false;
    let digit = false;

    password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars.charAt(randomIndex);
    }

    for (let i = 0; i < length; i++) {
      if (smallLetters.includes(password[i])) smallLetter = true;
      if (capitalLetters.includes(password[i])) capitalLetter = true;
      if (digits.includes(password[i])) digit = true;
    }

    if (smallLetter && capitalLetter && digit) break;
  }

  return password;
}
