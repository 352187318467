import BaseService from './baseService';
import httpService from '../helpers/httpService';

class AgreementsService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    const roles = localStorage.getItem('roles');

    this.url = `/api/vendor/investment/${investmentId}/premises/${premisesId}/agreement`;

    if (roles.includes('ROLE_CLIENT_TENANT') || roles.includes('ROLE_CLIENT')) {
      this.url = `/api/user/premises/${premisesId}/agreement`;
    }
  }

  async getRentPdf(id) {
    return httpService.get(`${this.url}/${id}/pdf/rent`, null, true, false, { responseType: 'blob' });
  }

  async getNotaryPdf(id) {
    return httpService.get(`${this.url}/${id}/pdf/notary`, null, true, false, { responseType: 'blob' });
  }
}

export default AgreementsService;
