import BaseService from './baseService';
import httpService from "../helpers/httpService";

class PremisesCountersService extends BaseService {
  constructor(investmentId, premisesId) {
    super();
    this.url = `/api/vendor/investment/${investmentId}/mainCounters/premises/${premisesId}`;
  }

  async getSubCountersForPremises(investmentId, counterId, premisesId) {
    return httpService.get(
        `/api/vendor/investment/${investmentId}/mainCounters/${counterId}/subCounters`,
        { premisesId },
        true
    );
  }

}

export default PremisesCountersService;
