import axios from 'axios';
import authService from '../services/authService';

const httpService = {};

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const isDemo = process.env.REACT_APP_DEMO_MODE === 'true';

const getAuthHeaders = (auth) => {
  if (!auth) {
    return {};
  }

  const userData = authService.getUserData();

  if (!userData) {
    return {};
  }

  return {
    headers: { Authorization: `Bearer ${userData.access_token}` },
  };
};

const isDemoMode = () => {
  let roles = localStorage.getItem('roles');

  if (roles) {
    roles = JSON.parse(roles);
  } else {
    roles = [];
  }

  if (roles.includes('ROLE_ADMIN')) {
    return false;
  }

  return isDemo;
};

httpService.get = (
  url,
  queryParams = {},
  auth = false,
  externalRequest = false,
  axiosOptions = {}
) => {
  let options = getAuthHeaders(auth);

  const tempParams = {};

  for (let key in queryParams) {
    if (Array.isArray(queryParams[key])) {
      tempParams[key] = queryParams[key].join(',');
    } else {
      tempParams[key] = queryParams[key];
    }
  }

  options.params = tempParams;
  // options.params = queryParams;

  options = {
    ...options,
    ...axiosOptions,
  };
  let requestUrl = baseUrl + url;
  if (externalRequest) {
    requestUrl = url;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(requestUrl, options)
      .then((response) => {
        if (response.status === 204 || response.status === 209) {
          resolve(response);
        }
        if (!response.data) {
          reject(response);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpService.delete = (url, queryParams, auth = false) => {
  if (isDemoMode()) {
    return Promise.reject(new Error('demo'));
  }
  const authHeaders = getAuthHeaders(auth);
  return new Promise((resolve, reject) => {
    axios
      .delete(baseUrl + url, authHeaders)
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
        reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpService.post = (url, data, auth, headers = {}, config = {}) => {
  if (isDemoMode()) {
    return Promise.reject(new Error('demo'));
  }
  const authHeaders = getAuthHeaders(auth);
  const finalHeaders = {
    headers: {
      ...authHeaders.headers,
      ...headers.headers,
    },
    ...config,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + url, data, finalHeaders)
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
        if (!response.data) {
          reject(response);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpService.patch = (url, data, auth, headers = {}) => {
  if (isDemoMode()) {
    return Promise.reject(new Error('demo'));
  }
  const authHeaders = getAuthHeaders(auth);
  const finalHeaders = {
    headers: {
      ...authHeaders.headers,
      ...headers.headers,
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .patch(baseUrl + url, data, finalHeaders)
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
        if (!response.data) {
          reject(response);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpService.put = (url, data, auth) => {
  if (isDemoMode()) {
    return Promise.reject(new Error('demo'));
  }
  const authHeaders = getAuthHeaders(auth);
  return new Promise((resolve, reject) => {
    axios
      .put(baseUrl + url, data, authHeaders)
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        }
        if (!response.data) {
          reject(response);
        }
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default httpService;
