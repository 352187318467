import React, { useState, useEffect } from 'react';
import {
  Row, Col, message, Tooltip, Menu, Table, Typography,
} from 'antd';
import { useHistory, Link } from 'react-router-dom';

import SmallList from '../../../components/SmallList';
import Icon from '../../../components/Icon';
import Price from '../../../components/Price';
import tenantChangesStatusMap from '../../../helpers/tenantChangesStatusMap';
import tenantChangeService from '../../../services/tenantChangeService';

const { Text } = Typography;

const TenantChanges = ({ investmentId, premisesId, tenantChangeGroupId }) => {
  const [tenantChanges, setTenantChanges] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const TenantChangeService = new tenantChangeService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangeService.getList().then((data) => {
      setTenantChanges(data);
    });
  }, [investmentId, premisesId, tenantChangeGroupId]);

  const refresh = () => {
    const TenantChangeService = new tenantChangeService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangeService.getList().then((data) => {
      setTenantChanges(data);
    });
  };

  const Dot = (props) => {
    let color = 'grey';
    const { status } = props;
    if (status === 'DONE') {
      color = 'green';
    }

    if (status === 'ACTIVE') {
      color = 'red';
    }

    if (status === 'REJECTED') {
      color = 'blue';
    }

    return (
      <Tooltip title={tenantChangesStatusMap(status)}>
        <span className={`dot ${color}`} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (<Dot status={record.status} />),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'changeDate',
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const deleteAction = (entityId) => {
    const TenantChangeService = new tenantChangeService(
      investmentId, premisesId, tenantChangeGroupId,
    );
    TenantChangeService.delete(entityId).then(() => refresh()).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
    });
  };

  const addNewAction = () => {
    history.push(`/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/create`);
  };

  const customMenuActions = (record) => {
    const tempArray = [];
    const showAction = (
      <Menu.Item key="1" icon={<Icon name="eye-visible" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${record.id}/show` }}
          className="premises-custom-create-link"
        >
          Zobacz
        </Link>
      </Menu.Item>
    );
    tempArray.push(showAction);
    const editAction = (
      <Menu.Item key="3" icon={<Icon name="edit" style={{ marginRight: '5px' }} />}>
        <Link
          to={{ pathname: `/tenantChangeGroup/${investmentId}/premises/${premisesId}/tenantChangeGroup/${tenantChangeGroupId}/tenantChange/${record.id}/edit` }}
          className="premises-custom-create-link"
        >
          Edytuj
        </Link>
      </Menu.Item>
    );
    tempArray.push(editAction);
    return tempArray;
  };

  const Summary = () => {
    if (tenantChanges.length === 0) {
      return '';
    }

    const total = tenantChanges.reduce((a, b) => a + (b.amount || 0), 0);
    return (
      <>
        <Table.Summary.Row>
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell />
          <Table.Summary.Cell>
            <Text style={{ color: '#C0D5E4' }}>Suma</Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <Text style={{ fontWeight: 'bold' }}><Price value={total} showEmpty /></Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  return (
    <div className="attachments create-form-wrapper">
      <Row>
        <Col xs={20} offset={2}>
          <SmallList
            icon="flat-changes"
            data={tenantChanges}
            columns={columns}
            title="Podział zmian"
            customMenuActions={customMenuActions}
            hideEdit
            deleteAction={deleteAction}
            addAction={addNewAction}
            summary={<Summary />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TenantChanges;
