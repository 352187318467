/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import resolutionsAttachmentsService from '../../../services/resolutionsAttachmentsService';

import Icon from '../../../components/Icon';
import Empty from '../../../components/Empty';

export const AttachmentsBox = () => {
  const { investmentId, resolutionId } = useParams();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const ResolutionsAttachmentsService = new resolutionsAttachmentsService(
      investmentId,
      resolutionId
    );
    ResolutionsAttachmentsService.getList().then((data) => {
      setAttachments(data);
    });
  }, [investmentId, resolutionId]);

  if (!attachments.length) {
    return '';
  }

  const handleDownload = (fileId) => {
    const ResolutionsAttachmentsService = new resolutionsAttachmentsService(
      investmentId,
      resolutionId
    );
    ResolutionsAttachmentsService.getOne(fileId).then((response) => {
      const a = document.createElement('a');
      a.href = `data:application/octet-stream;base64,${response.content}`;
      a.download = response.filename;
      a.click();
    });
  };

  const temp = attachments.map((attachment) => (
    <Col xs={24} sm={12} key={attachment.id}>
      <div className='attachment'>
        <Row>
          <Col xs={1}></Col>
          <Col xs={17} className='attachment-name' style={{ fontWeight: 600 }}>
            {attachment.filename}
          </Col>
          <Col xs={4} offset={2} className='attachment-actions'>
            <Button
              ghost
              className='button-no-border'
              onClick={() => handleDownload(attachment.id)}
            >
              <Icon name='download' style={{ color: '#3B4C60' }} />
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  ));
  if (temp.length === 0) {
    temp.push(
      <Col xs={24} sm={12} key={0}>
        <Empty />
      </Col>
    );
  }
  return (
    <div className='premises-show-box attachments-box'>
      <h2>
        <Icon name='folder' />
        Załączniki
      </h2>
      <div className='content'>
        <Row gutter={20}>{temp}</Row>
      </div>
    </div>
  );
};
