import httpService from '../helpers/httpService';

const membersService = {};

membersService.getParticipation = async (investmentId, userId) =>
  httpService.get(
    `/api/vendor/investment/${investmentId}/user/${userId}/premises/participation`,
    null,
    true
  );

export default membersService;
