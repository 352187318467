import React, { useState, useEffect } from 'react';
import {
  Form, Button, PageHeader, Row, Col, Divider, Upload, message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusOutlined } from '@ant-design/icons';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import Icon from '../../components/Icon';
import Input from '../../components/InputComponent';
import CountrySelect from '../../components/CountrySelect';

const { DatePicker, Select, InputNumber } = Input;
const { Option } = Select;

const Create = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [logo, setLogo] = useState([]);
  const [showTypeSelect, setShowTypeSelect] = useState(false);
  const [thumb, setThumb] = useState([]);

  const onFinish = (values) => {
    const data = {
      ...values,
      createYear: values.createYear.format('YYYY'),
    };
    const formData = new FormData();

    formData.append('investment', JSON.stringify(data));
    if (logo.length) {
      formData.append('logo', logo[0].originFileObj);
    }

    if (thumb.length) {
      formData.append('thumbnail', thumb[0].originFileObj);
    }

    const InvestmentsService = new investmentsService();
    InvestmentsService.create(formData).then((response) => {
      if (response.id) {
        history.push(`/investments/${response.id}/show`);
      }
    }).catch((err) => {
      if (err && err.message === 'demo') {
        message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
      }
      if (err?.response?.status === 406) {
        message.error('Przekroczono limit inwestycji dla obecnego pakietu');
      }
    });
  };

  useEffect(() => {
    userService.getInfo().then((data) => {
      if (data.roles.indexOf('ROLE_DEVELOPER') !== -1 && data.roles.indexOf('ROLE_TENANT') !== -1) {
        setShowTypeSelect(true);
        form.setFieldsValue({
          type: null,
        });
      } else {
        let type = '';
        if (data.roles.indexOf('ROLE_DEVELOPER') !== -1) {
          type = 'SALE';
        } else {
          type = 'RENT';
        }

        if (data.roles.includes('ROLE_WORKER')) {
          if (data.ownerRoles.indexOf('ROLE_DEVELOPER') !== -1) {
            type = 'SALE';
          } else {
            type = 'RENT';
          }
        }
        form.setFieldsValue({
          type,
        });
      }
    });
  });

  const handleLogoChange = (info) => {
    setLogo(info.fileList);
  };
  const handleThumbChange = (info) => {
    setThumb(info.fileList);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dozwolone są tylko pliki graficzne');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Plik musi być mniejszy niz 2MB');
    }
    return isJpgOrPng && isLt2M;
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Dodaj plik</div>
    </div>
  );

  const handleCancel = () => {
    history.push('/investments');
  };

  return (
    <div className="form-create">
      <PageHeader
        ghost={false}
        backIcon={<Icon name="arrow-simply-left" />}
        onBack={() => history.push('/investments')}
        title="Dodaj inwestycje"
      >
        <Form
          form={form}
          name="create"
          className="create-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{ address: { country: 'Polska' }, createYear: moment() }}
          autoComplete="off"
        >
          <>
            <div className="create-form-wrapper">
              <Row>
                <Col xs={24} sm={{ span: 20, offset: 2 }}>
                  <div className="form-section">
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <h2>Logo inwestycji</h2>
                        <Upload
                          listType="picture-card"
                          fileList={logo}
                          onChange={handleLogoChange}
                          beforeUpload={beforeUpload}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          { logo.length === 0 ? (uploadButton) : ('')}
                        </Upload>
                        <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                      </Col>
                      <Col xs={24} sm={12}>
                        <h2>Miniaturka</h2>
                        <Upload
                          listType="picture-card"
                          fileList={thumb}
                          onChange={handleThumbChange}
                          beforeUpload={beforeUpload}
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                        >
                          { thumb.length === 0 ? (uploadButton) : ('')}
                        </Upload>
                        <span style={{ marginTop: '20px', fontSize: '12px', color: '#C0D6E5' }}>Zalecany format plików: kwadrat</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Dane podstawowe</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="name"
                          rules={[{ required: true, message: 'Proszę uzupełnić nazwę' }]}
                        >
                          <Input size="large" placeholder="Nazwa inwestycji" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="createYear"
                          rules={[{ required: true, message: 'Proszę uzupełnić rok powstania' }]}
                        >
                          <DatePicker
                            size="large"
                            placeholder="Rok powstania"
                            style={{ width: '100%' }}
                            picker="year"
                          />
                        </Form.Item>
                      </Col>
                      {showTypeSelect ? (
                        <Col xs={24} sm={12}>
                          <Form.Item
                            name="type"
                            rules={[{ required: true, message: 'Proszę wybrać typ inwestycji' }]}
                          >
                            <Select
                              placeholder="Typ inwestycji"
                              size="large"
                            >
                              <Option value="SALE">Sprzedaż</Option>
                              <Option value="RENT">Wynajem</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      ) : (
                        <Form.Item
                          name="type"
                          hidden
                        >
                          <Input />
                        </Form.Item>
                      )}
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Adres inwestycji</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'street']}
                          rules={[{ required: true, message: 'Proszę uzupełnić adres' }]}
                        >
                          <Input size="large" placeholder="Ulica" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'number']}
                        >
                          <Input size="large" placeholder="Numer" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'city']}
                          rules={[{ required: true, message: 'Proszę uzupełnić adres' }]}
                        >
                          <Input size="large" placeholder="Miejscowość" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'zip']}
                          rules={[{ required: true, message: 'Proszę uzupełnić adres' }]}
                        >
                          <Input.MaskedInput mask="00-000" size="large" placeholder="Kod pocztowy" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['address', 'country']}
                          rules={[{ required: true, message: 'Proszę uzupełnić adres' }]}
                        >
                          <CountrySelect />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Administrator</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'firstName']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Imię" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'lastName']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Nazwisko" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'phone']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <InputNumber size="large" placeholder="Telefon" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name={['administrator', 'email']}
                          rules={[{ required: true, message: 'Pole wymagane' }]}
                        >
                          <Input size="large" placeholder="Adres e-mail" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-section">
                    <h2>Źródło ciepła</h2>
                    <Row gutter={20}>
                      <Col xs={24} sm={12}>
                        <Form.Item
                          name="heatSource"
                          rules={[{ required: true, message: 'Proszę wybrać źródło ciepła' }]}
                        >
                          <Select
                            placeholder="Źródło ciepła"
                            size="large"
                          >
                            <Option value="GAS">Gaz</Option>
                            <Option value="SYSTEM">Ciepło systemowe</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <Divider />
            <Row className="form-actions" justify="space-between">
              <Col sm={4} className="form-actions-cancel-wrapper">
                <Button className="button-secondary" size="large" onClick={handleCancel}>
                  Anuluj
                </Button>
              </Col>
              <Col sm={4} className="form-actions-save-wrapper">
                <Form.Item>
                  <Button type="primary" size="large" htmlType="submit" className="create-form-button">
                    Zapisz
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </PageHeader>
    </div>
  );
};
export default Create;
