import httpService from '../helpers/httpService';

const generateSettlementService = {};

generateSettlementService.generateSettlement = async (investmentId, data) =>
  httpService.post(
    `/api/community/investment/${investmentId}/settlementGenerator`,
    data,
    true
  );

export default generateSettlementService;
