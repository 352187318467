import React, { useState } from 'react';
import { Result } from 'antd';
import { useLocation } from 'react-router-dom';
import FaultConfirmationService from '../../services/faultConfirmationService';
import { Spin } from '../../components';

const useQuery = () => new URLSearchParams(useLocation().search);

const FaultConfirmation = () => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [successState, setSuccessState] = useState(false);

  if (query.has('confirmHash')) {
    const hash = query.get('confirmHash');

    if (hash) {
      const FaultService = new FaultConfirmationService();
      FaultService.confirm(hash).then(
        () => {
          setLoading(false);
          setSuccessState(true);
        },
      ).catch(
        () => {
          setLoading(false);
        },
      );
    }
  }
  return (
    <div
      className="fault-confirmation"
      style={{
        height: '100vh', width: '50%', margin: '0 auto 0', textAlign: 'center',
      }}
    >
      {loading && <Spin />}
      {!loading && (
        successState ? (
          <Result
            status="success"
            title="Potwierdzono wykonanie napraw"
          />
        ) : (
          <Result
            status="error"
            title="Niepoprawny adres URL"
          />
        )
      )}
    </div>
  );
};

export default FaultConfirmation;
