import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import {
  StatsWrapper, PaymentsComponent, RentPremisesComponent, RentFaultsComponent, AgreementsComponent,
} from './ViewComponents';

const TenantDashboard = () => (
  <Row gutter={20}>
    <Col xs={24}>
      <StatsWrapper
        title="Najem"
        icon="tenant"
        actions={[
          <Link to="investments/stats">
            <Button size="small">
              Statystyki
              <Icon name="stats" />
            </Button>
          </Link>,
        ]}
      >
        <RentPremisesComponent />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper
        title="Płatności"
        icon="card"
        actions={[
          <Link to="payments">
            <Button size="small">
              Historia
              <Icon name="history-list" />
            </Button>
          </Link>,
          <Link to="payments/create">
            <Button size="small">
              Dodaj nową
              <Icon name="plus" />
            </Button>
          </Link>,
        ]}
      >
        <PaymentsComponent />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper title="Umowy" icon="file">
        <AgreementsComponent withHeader={false} />
      </StatsWrapper>
    </Col>
    <Col xs={24} md={12}>
      <StatsWrapper
        title="Usterki"
        icon="fault"
        headerLink="faultGroup"
        actions={[
          <Link to="faultGroup/create">
            <Button size="small">
              Dodaj nową
              <Icon name="plus" />
            </Button>
          </Link>,
        ]}
      >
        <RentFaultsComponent withHeader={false} />
      </StatsWrapper>
    </Col>
  </Row>
);

export default TenantDashboard;
