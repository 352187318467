/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import Icon from '../../../../components/Icon';
import memberPremisesCountersService from '../../../../services/memberPremisesCountersService';
import SmallList from '../../../../components/SmallList';
import { renderUnit } from '../../../../helpers/unitRender';

const percentRender = (percent) => {
  const value = parseFloat(percent);

  if (!value) {
    return '+0.00%';
  }
  if (value > 0) {
    return `+${value.toFixed(2)}%`;
  }
  return `${value.toFixed(2)}%`;
};

export const CountersBox = ({ investmentId, premisesId }) => {
  const [counters, setCounters] = useState([]);

  const columns = [
    {
      title: 'Nazwa licznika',
      dataIndex: 'name',
      editable: false,
      render: (text, record) => (
          <span>
          <div>
            {record.mainCounterName}
            {' '}
            (
            {record.number}
            )
          </div>
        </span>
      ),
    },
    {
      title: 'Stan licznika',
      dataIndex: 'countersValuesSum',
      editable: false,
      render: (text, record) => (
        <span>
          <div
            style={{
              position: 'absolute',
              top: 10,
              color:
                record.countersPreviousValuesSumPercent < 0 ? '#EC434C' : '#15D55E',
              fontSize: 8,
              fontWeight: 700,
            }}
          >
            {percentRender(record.countersPreviousValuesSumPercent)}
          </div>

          <div>
            {record.countersValuesSum
              ? parseFloat(record.countersValuesSum).toFixed(2)
              : '0.00'}{' '}
            {renderUnit(record.unit)}
          </div>
        </span>
      ),
    },
    {
      title: 'Ostatnie zużycie',
      dataIndex: 'countersPreviousValuesSum',
      editable: false,
      render: (text, record) => (
        <div>
          {record.countersPreviousValuesSum
            ? parseFloat(record.countersPreviousValuesSum).toFixed(2)
            : '0.00'}{' '}
          {renderUnit(record.unit)}
        </div>
      ),
    },
    {
      render: (text, record) => (
        <Link
          to={`/memberInvestment/${investmentId}/premises/${premisesId}/counters/${record.mainCounterId}/subCounters/${record.id}/show`}
        >
          <Button style={{ border: 0 }}>
            <Icon name='arrow-simply-right' />
          </Button>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const MemberPremisesCountersService = new memberPremisesCountersService(
      investmentId,
      premisesId,
    );

    MemberPremisesCountersService.getList().then(async (data) => {
      setCounters([]);
      if (data?.content) {
        for (const counter of data.content) {
          await MemberPremisesCountersService.getSubCountersForPremises(investmentId, premisesId, counter.id).then((response) => {
            if (response?.content) {
              response.content.forEach((subCounter) => {
                setCounters((curr) => [
                  ...curr,
                  { ...subCounter },
                ]);
              });
            }
          });
        }
      }
    });
  }, [investmentId, premisesId]);
  if (!counters || counters.length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box attachments-box premise-counters-show'>
      <h2 style={{ marginBottom: 0 }}>
        <Icon name='timer' />
        Stan liczników
      </h2>
      <SmallList data={counters} columns={columns} />
    </div>
  );
};
