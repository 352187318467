/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import Icon from '../../../components/Icon';
import MessagePopup from '../../../components/MessagePopup';
import memberTenantAssociationsService from '../../../services/memberTenantAssociationsService';

export const WorkersInvestmentBox = ({ investmentId }) => {
  const [investmentWorkers, setInvestmentWorkers] = useState([]);

  useEffect(() => {
    if (investmentId) {
      const MemberTenantAssociationsService =
        new memberTenantAssociationsService();

      MemberTenantAssociationsService.getWorkers(investmentId)
        .then((response) => {
          setInvestmentWorkers(response);
        })
        .catch((err) => {
          if (err && err.message === 'demo') {
            message.error(
              <MessagePopup type='error'>
                Dodawanie, usuwanie oraz edycja zablokowana w trybie demo
              </MessagePopup>
            );
          }
        });
    }
  }, [investmentId]);

  if (!investmentWorkers || investmentWorkers.length === 0) {
    return null;
  }

  return (
    <div className='premises-show-box owner-box'>
      <h2>
        <Icon name='worker' />
        Pracownicy
      </h2>
      <div className='content'>
        {investmentWorkers.map((worker) => (
          <Row gutter={20} style={{ marginBottom: 20 }}>
            <Col xs={24}>
              <div className='owner-box-content'>
                <div className='item'>
                  {worker.firstName} {worker.lastName}
                </div>
                <div className='item'>{worker.workerPosition}</div>
                <div className='item'>
                  <a
                    className='datagrid-link'
                    href={`tel:${worker.mobile}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {worker.mobile}
                  </a>
                </div>
                <div className='item' style={{ flex: 2 }}>
                  <a
                    className='datagrid-link'
                    href={`mailto:${worker.email}`}
                    style={{ color: '#3B4C60' }}
                  >
                    {worker.email}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
};
