// RENT, SALE, STORAGE, PARKING, SERVICE
const typeMap = {
  RENT: 'Mieszkanie',
  SALE: 'Mieszkanie',
  STORAGE: 'Komórka lokatorska',
  PARKING: 'Miejsce postojowe',
  SERVICE: 'Lokal usługowy',
};
const getTypeName = (type) => typeMap[type] || '';

export default getTypeName;
