import React, { useEffect, useState, useRef } from 'react';
import {
  Col, Form, Tooltip, Button, Tag,
} from 'antd';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Datagrid from '../../components/Datagrid';
import Input from '../../components/InputComponent';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import tenantChangesService from '../../services/tenantChangeGroups';
import tenantChangeTagService from '../../services/tenantChangeTagService';
import investmentsService from '../../services/investmentsService';
import userService from '../../services/userService';
import tenantChangesStatusMap from '../../helpers/tenantChangesStatusMap';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Spin from '../../components/Spin';
import ListExpanded from './ListExpanded';

const { Select, DatePicker } = Input;
const { Option } = Select;

const Dot = (props) => {
  let color = 'grey';
  const { status } = props;
  if (status === 'DONE') {
    color = 'green';
  }

  if (status === 'ACTIVE') {
    color = 'red';
  }

  if (status === 'REJECTED') {
    color = 'blue';
  }

  return (
    <Tooltip title={tenantChangesStatusMap(status)}>
      <span className={`dot ${color}`} />
    </Tooltip>
  );
};

const useQuery = () => new URLSearchParams(useLocation().search);

const List = () => {
  const query = useQuery();
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  const [userData, setUserData] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [datagridFilters, setDatagridFilters] = useState({});
  const datagridRef = useRef();
  const history = useHistory();

  useEffect(() => {
    userService.getInfo().then((data) => {
      setUserData(data);
      if (data.roles.includes('ROLE_CLIENT')) {
        setCanEdit(false);
      }
      setLoading(false);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: 'SALE',
      pageSize: 1000,
    }).then((data) => {
      setInvestments(data.content);
    });
  }, []);

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      visible: true,
      key: 'name',
      filterEnabled: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Typ',
      dataIndex: 'premisesType',
      visible: true,
      key: 'premisesType',
      sorter: false,
      render: (text, record) => premiseTypeMap(record.premisesType),
      exportRender: (text, record) => premiseTypeMap(record.premisesType),
    },
    {
      title: 'Numer',
      dataIndex: 'premisesName',
      visible: true,
      key: 'premisesName',
      sorter: false,
      render: (text, record) => (<PremiseRenderer record={record} />),
    },
    {
      title: 'Inwestycja',
      dataIndex: 'investmentName',
      visible: true,
      key: 'investmentName',
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      visible: true,
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (<Dot status={record.status} />),
      exportRender: (text, record) => tenantChangesStatusMap(record.status),
    },
    {
      title: 'Dodano',
      dataIndex: 'addDate',
      visible: true,
      key: 'addDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Zmieniono',
      dataIndex: 'changeDate',
      visible: true,
      key: 'changeDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Termin płatności',
      dataIndex: 'expirationDate',
      visible: true,
      key: 'expirationDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => (
        <span style={{ whiteSpace: 'nowrap' }}>
          {text}
        </span>
      ),
    },
    {
      title: 'Opłacono',
      dataIndex: 'paymentDate',
      visible: true,
      key: 'paymentDate',
      sorter: false,
      render: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
      exportRender: (text, record) => (record.paymentDate ? 'Tak' : 'Nie'),
    },
    {
      title: 'Kwota',
      dataIndex: 'amount',
      visible: true,
      key: 'amount',
      sorter: false,
      render: (text, record) => (<Price value={record.amount} showEmpty />),
    },
  ];

  const PremiseRenderer = ({ record }) => {
    const { workerPermissions, roles } = userData;
    if (roles.includes('ROLE_WORKER') && (!workerPermissions || !workerPermissions.includes('INVESTMENT_FULL'))) {
      return record.premisesName;
    }

    const url = `investments/${record.investmentId}/premises/${record.premisesId}/show`;
    return <Link className="datagrid-link" to={url}>{record.premisesName}</Link>;
  };

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.id} key={investment.id}>{investment.name}</Option>
  ));

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="investmentId">
      <Form.Item
        name="investmentId"
      >
        <Select
          placeholder="Inwestycja"
          allowClear
          size="large"
        >
          {investmentsOptions}
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="premisesName">
      <Form.Item
        name="premisesName"
      >
        <Input size="large" placeholder="Numer" />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="status">
      <Form.Item
        name="status"
      >
        <Select
          placeholder="Status"
          allowClear
          size="large"
        >
          <Option value="ACTIVE">
            <span className="dot red" />
            Do zmiany
          </Option>
          <Option value="DONE">
            <span className="dot green" />
            Zmienione
          </Option>
          <Option value="REJECTED">
            <span className="dot blue" />
            Odrzucona
          </Option>
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="expirationDateFrom">
      <Form.Item
        name="expirationDateFrom"
      >
        <DatePicker size="large" placeholder="Termin płatności od" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="expirationDateTo">
      <Form.Item
        name="expirationDateTo"
      >
        <DatePicker size="large" placeholder="Termin płatności do" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  let filters = {};
  if (query.get('status')) {
    filters = {
      status: query.get('status'),
    };
  }

  const customBaseUrl = (record) => `tenantChangeGroup/${record.investmentId}/premises/${record.premisesId}/tenantChangeGroup/${record.id}`;

  const CustomCreate = () => {
    if (userData.roles.includes('ROLE_CLIENT') || userData.roles.includes('ROLE_CLIENT_TENANT')) {
      return '';
    }

    return (
      <Link to="/tenantChangeGroup/create">
        <Button type="primary" style={{marginBottom: 20}}>
          Nowy
          {' '}
          <Icon name="plus" />
        </Button>
      </Link>
    );
  };

  const TagFilters = () => {
    const [tags, setTags] = useState([]);

    useEffect(() => {
      const TenantChangeTagService = new tenantChangeTagService();
      TenantChangeTagService.getAllTenantChangeGroups().then((data) => {
        setTags(data);
      });

      if (!loading && query.has('tag') && datagridRef) {
        const filterTag = query.get('tag');

        query.delete('tag');
        history.replace({
          search: query.toString(),
        });
        const filter = {
          tags: filterTag,
        };
        datagridRef.current.handleFilterChange(filter, true);
      }
    }, []);

    const onTagClick = (tag) => {
      const filter = {
        tags: tag,
      };
      datagridRef.current.handleFilterChange(filter, true);
    };

    const getIsActiveTag = (tag) => {
      if (datagridFilters?.tags === tag) {
        return 'tag-active';
      }

      return '';
    };

    const renderTags = tags.map((tag) => (
      <Tag className={getIsActiveTag(tag)} onClick={() => onTagClick(tag)}>{`#${tag}`}</Tag>
    ));

    return renderTags;
  };

  const afterFilterChange = () => {
    setDatagridFilters(datagridRef.current.state.filters);
  };

  const { roles } = userData;

  return (
    <div>
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <div>
          <Datagrid
            resource="tenantChangeGroup"
            dataProvider={new tenantChangesService(null, null, null)}
            title={'Lista zmian lokatorskich'}
            customCreate={<CustomCreate />}
            customTags={
              !roles.includes('ROLE_CLIENT_TENANT') && 
              !roles.includes('ROLE_CLIENT') && (
                <div className="tags-filter">
                  <TagFilters />
                </div>
              )
            }
            canEdit={canEdit}
            hideEdit={!canEdit}
            columns={columns}
            customFilters={customFilters}
            filters={filters}
            expandedRowRenderer={(record) => <ListExpanded record={record} canEdit={canEdit} />}
            calculateBaseUrl={customBaseUrl}
            ref={datagridRef}
            afterFilterChange={afterFilterChange}
            hideReset={true}
            hideSms={true}
            hideEmail={true}
          />
        </div>
      )}
    </div>
  );
};

export default List;
