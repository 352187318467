import React, { useEffect, useState } from 'react';
import { PageHeader, Button } from 'antd';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Icon, Datagrid } from '../../../../components';
import mainCountersService from '../../../../services/mainCountersService';
import subCountersService from '../../../../services/subCountersService';

import { renderUnit } from '../../../../helpers/unitRender';
import { premiseTypeMap } from '../../../../helpers';

import Spin from '../../../../components/Spin';
import { MainCounterCard } from '../../components/MainCounterCard';
import PermisesService from '../../../../services/premisesService';

const showAlert = (resource) => {
  let expireDate = resource?.identity?.idExpireDate;
  if (!expireDate) {
    return false;
  }

  expireDate = new Date(expireDate);
  if (expireDate < new Date()) {
    return true;
  }

  return false;
};

const percentRender = (percent) => {
  const value = parseFloat(percent);

  if (!value) {
    return `+0.00%`;
  } else if (value > 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const List = () => {
  const history = useHistory();
  const { id, counterId } = useParams();
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mainCounterData, setMainCounterData] = useState();
  const [investmentPremises, setInvestmentPremises] = useState([]);
  const [premises, setPremises] = useState([]);

  const columns = [
    {
      title: 'Numer podlicznika',
      dataIndex: 'number',
      visible: true,
      filterEnabled: true,
      filterFullWidth: true,
      key: 'number',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Typ lokalu',
      dataIndex: 'premisesType',
      visible: true,
      key: 'premisesType',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      filterOptions: [
        { value: 'RENT', label: 'Mieszkanie' },
        { value: 'STORAGE', label: 'Komórka lokatorska' },
        { value: 'PARKING', label: 'Miejsce postojowe' },
        { value: 'SERVICE', label: 'Lokal usługowy' },
      ],
      render: (text, record) => premiseTypeMap(record.premisesType),
      exportRender: (text, record) => premiseTypeMap(record.premisesType),
      onFilterChange: (e) => {
        setPremises(
          investmentPremises.filter((premise) =>
            e ? premise.type === e : true
          )
        );
      },
      export: true,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesId',
      visible: false,
      key: 'premisesId',
      sorter: false,
      filterEnabled: true,
      filterType: 'select',
      showSearch: true,
      filterOptions: premises.map((premise) => ({
        value: premise.id,
        label: premise.name,
      })),
      export: false,
    },
    {
      title: 'Lokal',
      dataIndex: 'premisesName',
      visible: true,
      filterEnabled: false,
      key: 'premisesName',
      sorter: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <span>Suma odczytów ({renderUnit(mainCounterData?.unit)})</span>,
      exportTitle: `Suma odczytów (${mainCounterData?.unit})`,
      dataIndex: 'countersValuesSum',
      visible: true,
      filterEnabled: false,
      key: 'countersValuesSum',
      sorter: false,
      render: (text, record) => (
        <span>
          <div
            style={{
              position: 'absolute',
              top: 15,
              color:
                record.countersPreviousValuesSumPercent < 0
                  ? '#EC434C'
                  : '#15D55E',
              fontSize: 8,
              fontWeight: 700,
            }}
          >
            {percentRender(record.countersPreviousValuesSumPercent)}
          </div>

          <div>
            {parseFloat(record.countersValuesSum).toFixed(2)}{' '}
            {renderUnit(record.unit)}
          </div>
        </span>
      ),
      exportRender: (text, record) =>
        parseFloat(record.countersValuesSum).toFixed(2),
    },
    {
      title: 'Data ostatniego odczytu',
      dataIndex: 'lastReadDate',
      visible: true,
      filterEnabled: false,
      key: 'lastReadDate',
      sorter: false,
      render: (text, record) => (text ? text : '-'),
      exportRender: (text, record) => (text ? text : '-'),
    },
  ];

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);
    MainCountersService.getOne(counterId).then((data) => {
      setMainCounterData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const PremisesService = new PermisesService(id);

    PremisesService.getList({ size: 9999 }).then((response) => {
      setPremises(response.content);
      setInvestmentPremises(response.content);
    });
  }, []);

  const CustomAddButton = () => {
    return (
      <div>
        <Button
          className='button-secondary import-button'
          onClick={() => {
            return history.push(
              `/investment/${id}/counters/${counterId}/subCounters/import`
            );
          }}
        >
          <span style={{ marginRight: 5 }}>Importuj</span>
          <Icon name='download' />
        </Button>

        <Button
          type='primary'
          onClick={() =>
            history.push(
              `/investment/${id}/counters/${counterId}/subCounters/create`
            )
          }
          style={{ marginBottom: 20 }}
        >
          Dodaj podlicznik <Icon name='plus' style={{ marginLeft: 10 }} />
        </Button>
      </div>
    );
  };

  const HeaderTitle = () => {
    if (!mainCounterData.number) {
      return <span>Podliczniki</span>;
    }
    return (
      <span>
        Podliczniki:{' '}
        <span className='header-subtitle'>{mainCounterData.number}</span>
      </span>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <div>
          <Datagrid
            title={
              <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                backIcon={<Icon name='arrow-simply-left' />}
                onBack={() => window.history.back()}
                title={<HeaderTitle />}
              />
            }
            exportTitle={'Podliczniki'}
            resource={`investment/${id}/counters/${counterId}/subCounters`}
            dataProvider={new subCountersService(id, counterId)}
            columns={columns}
            sort={{
              sortField: 'id',
              sortOrder: 'descend',
            }}
            showAlert={showAlert}
            hideReset={true}
            hideSms={true}
            hideEmail={true}
            customCreate={<CustomAddButton />}
          />
        </div>
      )}
    </>
  );
};

export default List;
