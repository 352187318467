import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import memberMessagesService from '../../services/memberMessagesService';
import Icon from '../../components/Icon';

const SingleMessage = ({ data, refreshAll, hideMessages }) => {
  const [redirectUrl, setRedirectUrl] = useState();
  const history = useHistory();

  useEffect(() => {
    setRedirectUrl(`/messages/${data?.id}/show`);
  }, [data]);

  let isReadClassname = '';
  if (!data?.isRead) {
    isReadClassname = 'notification--unread';
  }

  let iconName = '';
  let iconStyle = {};

  const redirectHandler = useCallback(() => {
    const MemberMessagesService = new memberMessagesService();

    MemberMessagesService.updateFields(data.id, {
      isRead: true,
    })
      .then(() => {
        refreshAll();
        hideMessages();
        history.push(redirectUrl);
      })
      .catch((err) => {
        if (err && err.message === 'demo') {
          // message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
        }
      });
  }, [redirectUrl]);

  return (
    <div className={`notification ${isReadClassname}`} role='presentation'>
      <Row onClick={redirectHandler} style={{ cursor: 'pointer' }}>
        <Col xs={2}>
          <div className='icon-wrapper'>
            <Icon name={iconName} style={iconStyle} />
            {!iconName && redirectUrl && !data?.isRead ? (
              <span className={`dot red`} style={{ width: 6, height: 6 }} />
            ) : null}
          </div>
        </Col>
        <Col xs={20}>
          <div className='content'>
            <div className='description'>{data?.title}</div>

            <div className='time'>
              {moment(`${data.createDate}`)
                .locale('pl_PL')
                .startOf('minutes')
                .fromNow()}
            </div>
          </div>
        </Col>
        {redirectUrl && (
          <Col xs={2}>
            <div className='notifications-arrow-wrapper'>
              <Tooltip
                title={'Zobacz wiadomość'}
                placement={'bottomLeft'}
                showArrow={false}
              >
                <Icon
                  name='arrow-simply-right'
                  className='notifications-arrow'
                />
              </Tooltip>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SingleMessage;
