import React, { useEffect, useState } from 'react';
import { PageHeader, Col, Form } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import userSettlementsService from '../../services/userSettlementsService';
import workersService from '../../services/workersService';
import investmentsService from '../../services/investmentsService';
import Input from '../../components/InputComponent';
import Datagrid from '../../components/Datagrid';
import Price from '../../components/Price';
import Icon from '../../components/Icon';
import premiseTypeMap from '../../helpers/premiseTypeMap';
import Spin from '../../components/Spin';

const { Select, DatePicker } = Input;
const { Option } = Select;

const columns = [
  {
    title: 'Numer',
    dataIndex: 'premisesName',
    visible: true,
    key: 'premisesName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Typ',
    dataIndex: 'premisesType',
    visible: true,
    key: 'premisesType',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => premiseTypeMap(record.premisesType),
    exportRender: (text, record) => premiseTypeMap(record.premisesType),
  },
  {
    title: 'Inwestycja',
    dataIndex: 'investmentName',
    visible: true,
    key: 'investmentName',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Cena sprzedaży',
    dataIndex: 'price',
    visible: true,
    key: 'price',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (<Price value={record.price} />),
  },
  {
    title: 'Prowizja',
    dataIndex: 'commision',
    visible: true,
    key: 'commision',
    sorter: true,
    sortDirections: ['descend', 'ascend'],
    render: (text, record) => (`${record.commission} %`),
    exportRender: (text, record) => (`${record.commission} %`),
  },
  {
    title: 'Prowizja',
    dataIndex: 'commissionValue',
    visible: true,
    key: 'commissionValue',
    sorter: false,
    summary: true,
    render: (text, record) => (<Price value={record.commissionValue} />),
  },
];

const List = () => {
  const { id } = useParams();
  const history = useHistory();
  const UserSettlementsService = new userSettlementsService(id);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [investments, setInvestments] = useState([]);
  useEffect(() => {
    workersService.getOne(id).then((data) => {
      setUserData(data);
      setLoading(false);
    });

    const InvestmentsService = new investmentsService();
    InvestmentsService.getList({
      types: 'SALE',
      pageSize: 1000,
    }).then((data) => {
      setInvestments(data.content);
    });
  }, [id]);

  const CustomCreate = () => ('');

  const investmentsOptions = investments.map((investment) => (
    <Option value={investment.name} key={investment.id}>{investment.name}</Option>
  ));

  const customFilters = [];
  customFilters.push(
    <Col xs={24} sm={12} key="investmentName">
      <Form.Item
        name="investmentName"
      >
        <Select
          placeholder="Inwestycja"
          allowClear
          size="large"
        >
          {investmentsOptions}
        </Select>
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="premisesName">
      <Form.Item
        name="premisesName"
      >
        <Input size="large" placeholder="Numer" />
      </Form.Item>
    </Col>,
  );

  customFilters.push(
    <Col xs={24} sm={12} key="settleDate">
      <Form.Item
        name="settleDate"
      >
        <DatePicker size="large" placeholder="Data sprzedaży" style={{ width: '100%' }} />
      </Form.Item>
    </Col>,
  );

  const HeaderTitle = () => {
    if (!userData.firstName) {
      return <span>Edycja</span>;
    }
    return (
      <span>
        Historia rozliczeń:
        {' '}
        <span className="header-subtitle">
          {userData.firstName}
          {' '}
          {userData.lastName}
        </span>
      </span>
    );
  };

  return (
    <div className="show-layout">
      {loading ? (
        <div className="loading"><Spin /></div>
      ) : (
        <PageHeader
          ghost={false}
          backIcon={<Icon name="arrow-simply-left" />}
          onBack={() => history.push(`/workers/${id}/settlementGroups`)}
          title={<HeaderTitle />}
        >
          <div className="premises-list">
            <Datagrid
              resource={`investments/${id}/premises`}
              dataProvider={UserSettlementsService}
              columns={columns}
              customCreate={<CustomCreate />}
              customBaseUrlKey="workerSettlements"
              hideRowActions
              disableSelection
              filters={{
                status: 'SETTLED',
              }}
              customFilters={customFilters}
            />
          </div>
        </PageHeader>
      )}
    </div>
  );
};

export default List;
