import React from 'react';
import {
  Modal, Form, Row, Col, message,
} from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import MetersServiceS from '../../../../services/metersService';
import Footer from '../../../../helpers/Footer';
import Input from '../../../../components/InputComponent';

const { DatePicker, InputNumberV2 } = Input;

const MeterModal = (props) => {
  const {
    data, afterSave, afterCancel, heatSource,
  } = props;
  const [form] = Form.useForm();
  const { id, premisesId } = useParams();
  const MetersService = new MetersServiceS(id, premisesId);

  if (data?.id) {
    form.setFieldsValue({
      ...data,
      date: data.date ? moment(data.date) : null,
    });
  }

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (values?.dateTo) {
          // eslint-disable-next-line no-param-reassign
          values = {
            ...values,
            date: values.date.format('YYYY-MM-DD'),
          };
        }
        if (data?.id) {
          MetersService.update(data.id, values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        } else {
          MetersService.create(values).then((response) => {
            afterSave(response);
          }).catch((err) => {
            if (err && err.message === 'demo') {
              message.error('Dodawanie, usuwanie oraz edycja zablokowana w trybie demo');
            }
          });
        }
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onReset = () => {
    form.resetFields();
    afterCancel();
  };

  const title = data?.id ? `Edytujesz: ${data.date}` : 'Nowy stan liczników';

  const { isModalVisible } = props;
  return (
    <>
      <Modal
        title={title}
        centered
        visible={isModalVisible}
        onCancel={() => onReset()}
        width={770}
        footer={<Footer onReset={onReset} onFinish={onFinish} isNew={!data?.id} />}
      >
        <Form
          form={form}
          name="filterDeveloper"
          className="filters-developer-form"
          onFinish={onFinish}
          initialValues={{ date: moment() }}
        >
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <DatePicker size="large" placeholder="Data" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="hotWater"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2 size="large" placeholder="Ciepła woda (CW)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="coldWater"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2 size="large" placeholder="Zimna woda (CW)" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="electricity"
                rules={[{ required: true, message: 'Pole wymagane' }]}
              >
                <InputNumberV2 size="large" placeholder="Prąd (kw)" />
              </Form.Item>
            </Col>
            {heatSource === 'GAS' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="gas"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <InputNumberV2 size="large" placeholder="Gaz (m3)" />
                </Form.Item>
              </Col>
            )}
            {heatSource === 'SYSTEM' && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name="system"
                  rules={[{ required: true, message: 'Pole wymagane' }]}
                >
                  <InputNumberV2 size="large" placeholder="Ciepło systemowe (GJ)" />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default MeterModal;
