const fieldMap = {
  premisesName: 'Lokal',
  name: 'Nazwa',
  status: 'Status',
  responsibleWorker: 'Osoba odpowiedzialna',
  responsibleWorkers: 'Osoba odpowiedzialna',
  tags: 'Tagi',
  fixDate: 'Data usunięcia',
  addDate: 'Data dodania',
  filename: 'Załączniki',
  amount: 'Kwota',
  isCorrect: 'Potwierdzenie',
  developerNote: 'Notatka dewelopera',
};
const getFieldName = (field) => fieldMap[field] || field;

export default getFieldName;
