import BaseService from './baseService';
import httpService from '../helpers/httpService';

class PackagePriceService extends BaseService {
  constructor(packageId) {
    super();
    this.url = `/api/package/${packageId}`;
  }

  async getPackagePrices() {
    return httpService.get(`${this.url}/payment`, {}, true);
  }

  async createPayment(data) {
    return httpService.post(`${this.url}/payment`, data, true);
  }
}

export default PackagePriceService;
