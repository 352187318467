import React from 'react';
import { Input, Form } from 'antd';

const EditableCell = ({
  editing,
  dataIndex,
  record,
  children,
  ...restProps
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <td {...restProps}>
    {editing ? (
      <Form.Item
        name={dataIndex}
        style={{
          margin: 0,
        }}
      >
        <Input placeholder={record[dataIndex]} />
      </Form.Item>
    ) : (
      children
    )}
  </td>
);

export default EditableCell;
