import React from 'react';
import { Card, Col, Row } from 'antd';
import { renderUnit } from '../../../helpers/unitRender';
import { Icon } from '../../../components';

const percentRender = (percent) => {
  const value = parseFloat(percent);
  if (value >= 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const Atribute = ({
  label,
  value = false,
  counterUnit = false,
  copy = false,
  additional = false,
}) => (
  <Col xs={24} lg={12} xl={8} style={{ paddingRight: 10 }}>
    <Row className='main-counter-card-label-wrapper'>{label}:</Row>
    <Row className='main-counter-card-value-wrapper'>
      {additional !== false && (
        <div
          style={{ color: additional >= 0 ? '#15D55E' : '#EC434C' }}
          className='main-counter-card-additional'
        >
          {percentRender(additional)}
        </div>
      )}
      {counterUnit ? (
        <span>
          {value !== false ? parseFloat(value).toFixed(2) : ''}{' '}
          {renderUnit(counterUnit)}
        </span>
      ) : (
        <span
          className={`main-counter-card-value ${
            copy
              ? 'main-counter-card-value-wcopy'
              : 'main-counter-card-value-nocopy'
          }`}
        >
          {value}
        </span>
      )}
      {copy ? (
        <span className='main-counter-card-value-copy'>
          <Icon
            name={'copy'}
            onClickAction={() => {
              navigator.clipboard.writeText(value);
            }}
          />
        </span>
      ) : null}
    </Row>
  </Col>
);

export const MainCounterCard = ({ mainCounterData }) => {
  if (!mainCounterData) {
    return null;
  }

  return (
    <Card style={{ backgroundColor: '#3B4C60', borderRadius: 25, padding: 25 }}>
      <Row gutter={[0, 20]}>
        <Atribute label={'Nazwa licznika'} value={mainCounterData.name} />
        <Atribute
          label={'Numer licznika'}
          value={mainCounterData.number}
          copy={true}
        />
        <Col xs={0} xl={8} />
        <Atribute
          label={'Stan licznika'}
          value={mainCounterData.mainCounterValue ?? 0}
          counterUnit={mainCounterData.unit}
          additional={mainCounterData.mainCounterPercentChange ?? 0}
        />
        <Atribute
          label={'Suma podliczników'}
          value={mainCounterData.subCountersValue ?? 0}
          counterUnit={mainCounterData.unit}
          additional={mainCounterData.subCountersPercentChange ?? 0}
        />
        <Atribute
          label={'Opłaty naliczane od'}
          counterUnit={mainCounterData.unit}
        />
      </Row>
    </Card>
  );
};
