import React from 'react';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon';
import {
  StatsWrapper, PaymentsComponent, RentFaultsComponent, AgreementsListComponent,
} from './ViewComponents';

const ClientTenantDashboard = () => (
  <>
    <Row gutter={20}>
      <Col xs={24}>
        <StatsWrapper title="Twoje umowy" icon="file">
          <AgreementsListComponent />
        </StatsWrapper>
      </Col>
    </Row>
    <Row gutter={20}>
      <Col xs={24} md={12}>
        <StatsWrapper title="Usterki" icon="fault" headerLink="faultGroup">
          <RentFaultsComponent withHeader={false} />
        </StatsWrapper>
      </Col>
      <Col xs={24} md={12}>
        <StatsWrapper
          title="Płatności"
          icon="card"
          actions={[
            <Link to="payments">
              <Button size="small">
                Historia
                <Icon name="history-list" />
              </Button>
            </Link>,
          ]}
        >
          <PaymentsComponent />
        </StatsWrapper>
      </Col>
    </Row>
  </>
);

export default ClientTenantDashboard;
